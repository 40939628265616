import { format } from "date-fns"

export const FORMAT_TIME = "hh:mm aaa"

function getCountdownTimes(gameStartTime, now) {
  let time = 0
  let startTime = 0
  if (gameStartTime) {
    const timeDiff = gameStartTime - now
    if (timeDiff > 0) {
      time = timeDiff
    }
    startTime = format(gameStartTime, FORMAT_TIME)
  }
  return { time, startTime }
}

export default {
  getCountdownTimes
}

export class Timer {
  static get hours() {
    return [...Array(24).keys()].map(String)
  }

  static get minutes() {
    return [...Array(60).keys()]
      .map(String)
      .map(minute => (minute.length === 1 ? `0${minute}` : minute))
  }
}
