import { computed } from "@vue/composition-api"

import useStore from "./useStore"

export default function useDominantSpeaker() {
  const { store } = useStore()
  const id = computed<string | undefined>(
    () => store.state.twilio.dominantSpeakerId ?? undefined
  )
  return {
    id
  }
}
