var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _c("p", { staticClass: "login-form__title" }, [
        _vm._v(" " + _vm._s(_vm.enterText) + " "),
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.onSignin.apply(null, arguments)
            },
          },
        },
        [
          _c("p", [_vm._v("Email")]),
          _c("v-text-field", {
            attrs: {
              type: "text",
              rules: [_vm.rules.required, _vm.rules.email],
              required: "",
              placeholder: "Email",
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
          _c("v-flex", { attrs: { "d-flex": "" } }, [
            _c("p", [_vm._v("Password")]),
            _c(
              "div",
              {
                staticClass: "form__secondary-button text-xs-right",
                on: {
                  click: function ($event) {
                    return _vm.setForgotPassword()
                  },
                },
              },
              [_vm._v(" Forgot password? ")]
            ),
          ]),
          _c("v-text-field", {
            attrs: {
              type: "password",
              rules: [_vm.rules.required],
              required: "",
              placeholder: "********",
            },
            model: {
              value: _vm.password,
              callback: function ($$v) {
                _vm.password = $$v
              },
              expression: "password",
            },
          }),
          _c(
            "div",
            { staticClass: "login-form__action-container" },
            [
              _c("input", {
                staticClass: "login-form__submit",
                attrs: {
                  type: "submit",
                  disabled: !_vm.isFormValid,
                  value: "Log in",
                },
              }),
              _c("div", { staticClass: "login-form__or" }, [_vm._v("OR")]),
              _c("GoogleButton", {
                staticClass: "login-form__google-button",
                on: { click: _vm.signInWithGoogle },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "terms-and-conditions mt-2" }, [
            _vm._v(" By logging in you agree to our "),
            _c(
              "a",
              {
                attrs: { href: _vm.privacyLink, target: "_blank" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [_vm._v("Terms & Conditions")]
            ),
            _vm._v(". "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }