import axios from "./axios.service"
import Session from "@shared/Session"
import { store } from "@/store/index"

const sendEmergencyTicket = args => {
  return axios({
    method: "post",
    url: `/utils/emergency`,
    data: { ...args }
  })
}

const fetchTwilioToken = async ({ userID: identity }) => {
  const data = await axios({
    method: "post",
    url: `/token`,
    data: { identity }
  })

  return data?.token
}

const getSignedURL = async ({ fileName, token }) => {
  const data = await axios({
    method: "post",
    url: `media/getSignedURL?token=${token}`,
    data: { fileName }
  })

  return data?.data
}

const upload = async ({ fileName, token, blob, axiosConfig }) => {
  const data = await axios({
    method: "post",
    url: `media/upload/${fileName}?token=${token}`,
    blob,
    ...axiosConfig,
    headers: { "Content-Type": blob.type }
  })

  return data?.data
}

const downloadScreenshot = async ({ orgID, gameID, token }) => {
  const data = await axios({
    method: "post",
    url: `media/downloadScreenshot?token=${token}`,
    data: {
      orgID,
      gameID
    }
  })

  return data
}

const downloadMissonMedia = async ({
  orgID,
  gameID,
  missionId = null,
  token
}) => {
  const data = await axios({
    method: "post",
    url: `media/downloadMissionMedia?token=${token}`,
    data: { orgID, gameID, missionId }
  })

  return data
}

const fetchCustomerMissions = async ({ gameID, clientID }) => {
  try {
    const key = Session.getKey(store.getters["auth/clientID"])
    const headers = {}
    if (key) headers["x-gogame-db"] = key
    const token = store.getters["auth/token"]
    await axios({
      method: "post",
      url: `custom-missions?token=${token}`,
      data: { gameID, clientID },
    })
  } catch (e) {
    console.log(e)
  }
}

const submitDrawingImage = async ({ drawingId, sessionId, token }) => {
  const url = `media/submit-drawin-image?token=${token}`
  const data = { drawingId, sessionId }
  const key = Session.getKey(store.getters["auth/clientID"])
  const headers = {}
  if (key) headers["x-gogame-db"] = key
  const res = await axios.post(url, data, { headers })
  return res.data
}

const copyClient = async ({ orgID, id: clientID, token, ...rest }) => {
  const data = await axios({
    method: "post",
    url: `client/copy?token=${token}`,
    data: { orgID, id: clientID, ...rest }
  })

  return data
}

export {
  fetchTwilioToken,
  getSignedURL,
  downloadScreenshot,
  upload,
  fetchCustomerMissions,
  downloadMissonMedia,
  submitDrawingImage,
  copyClient,
  sendEmergencyTicket
}
