<template>
  <div class="mode-mapper" :class="classes">
    <transition name="mode-mapper__transition" mode="out-in">
      <div
        v-if="placeholderText"
        class="mode-mapper__placeholder"
        :class="{ 'mode-mapper__placeholder--enlarged': enlargedUI }"
      >
        <div class="mode-mapper__placeholder-text">
          <ResizableText :message="placeholderText" />
        </div>
      </div>
      <MeetingSmartGrid
        v-else-if="pinnedTeamID"
        :key="pinnedTeamID"
        :users="pinnedUsers"
        class="mode-mapper__pinned-users"
      />
      <JeopardAI v-if="behavior === Mission.JeopardAI && mode === Mode.Play" />
      <MusicalKeys
        v-else-if="behavior === Mission.MusicalKeys && mode === Mode.Play"
      />
      <SocialVideo
        v-else-if="
          hasSubmitted && isHuddleVideoTeamMission && layout === Layout.PLAY
        "
      />
      <UnconditionalPositiveRegard
        v-else-if="
          behavior === Mission.UnconditionalPositiveRegard &&
          layout === Layout.PLAY
        "
      />
      <DiscussionGroup
        v-else-if="
          behavior === Mission.DiscussionGroup && layout === Layout.PLAY
        "
      />
      <TelephonePictionary
        v-else-if="
          behavior === Mission.TelephonePictionary && layout === Layout.PLAY
        "
      />
      <WordCloud
        v-else-if="behavior === Mission.WordCloud && layout === Layout.PLAY"
      />
      <SpiralDrawing
        v-else-if="behavior === Mission.SpiralDrawing && mode === Mode.Play"
        :mission="mission"
      />
      <PlayMode
        :mode="modeWithPresenterCondtion"
        v-else-if="layout === Layout.PLAY"
      />
      <SlidesWrapper v-else-if="layout === Layout.SOCIAL && isSlides" />
      <TwoTruthsReveal
        v-else-if="layout === Layout.SOCIAL && isTwoTruthsReveal"
      />
      <Award
        v-else-if="layout === Layout.SOCIAL && (isAward || isPhotoAward)"
      />
      <SocialVideo v-else-if="layout === Layout.SOCIAL && isVideo" />
      <SocialTeamUsers
        :key="mission.id"
        :mode="modeWithOverrides"
        v-else-if="isSocialTeamUsers"
      />
      <ImageMatch v-else-if="layout === Layout.SOCIAL && isMatchGame" />
      <FactMatch v-else-if="layout === Layout.SOCIAL && isFactMatch" />
      <OrderTheCardsGame
        v-else-if="layout === Layout.SOCIAL && isOrderTheCardsGame"
      />

      <TheaterVoting v-else-if="isTheaterVoting" />
      <VotingMode v-else-if="isVoting" />
      <MeetingMode
        v-else-if="layout === Layout.MEETING"
        :mode="modeWithOverrides"
      />
    </transition>

    <ModeMapperHostContainer
      v-if="isHostMediaVisible"
      :mission="mission"
      :mode="modeWithOverrides"
      :height="height"
      :hasSubmittedVideoTeam="!!hasSubmitted"
    >
      <template #default="{ rounded, badgeHidden }">
        <HostMedia
          :rounded="rounded"
          :hide-badge="badgeHidden"
          :mode="modeWithOverrides"
          :overrrideSource="overrrideSource"
          @ended="onHostMediaEnded"
        />
      </template>
    </ModeMapperHostContainer>

    <ModeMapperTips
      v-if="
        !!mission &&
        $_TipJarMixin_isTipJarVisible &&
        $_TipJarMixin_isGameOver &&
        !isMeeting
      "
    />

    <ResizeObserver @notify="onResize" />
  </div>
</template>

<script>
import _ from "lodash"
import { mapGetters } from "vuex"
import { ResizeObserver } from "vue-resize"

import TipJarMixin from "@/mixins/TipJarMixin"
import User from "@shared/User"
import Mission from "@shared/enums/Mission"
import Mode from "@shared/enums/Mode"
import Team from "@shared/Team"
import useEnlargedUI from "@/use/useEnlargedUI"
import RaffleConsumer from "@/modules/raffle/core/consumer"

import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import HostMedia from "@/components/GroupTeams/Common/HostMedia.vue"
import ModeMapperHostContainer from "./ModeMapperHostContainer.vue"
import ModeMapperTips from "./ModeMapperTips.vue"
import { GameType } from "@/entities/GameType"
import HandMovementController from "@/components/GroupTeams/Common/Games/HandMovement/HandMovementController.js"

const isHostIncompatibleMode = mode =>
  mode === Mode.Meeting || mode === Mode.Theater

export const Layout = {
  SOCIAL: Mode.Social,
  VOTING: Mode.Voting,
  PLAY: Mode.Play,
  MEETING: Mode.Meeting
}

export default RaffleConsumer.extend({
  name: "ModeMapper",
  mixins: [TipJarMixin],
  components: {
    JeopardAI: () =>
      import("@/components/GroupTeams/Common/Games/JeopardAI/JeopardAI.vue"),
    MusicalKeys: () =>
      import(
        "@/components/GroupTeams/Common/Games/MusicalKeys/MusicalKeys.vue"
      ),
    SpiralDrawing: () =>
      import(
        "@/components/GroupTeams/Common/Games/SpiralDrawing/SpiralDrawing.vue"
      ),
    DiscussionGroup: () =>
      import(
        "@/components/GroupTeams/Common/Games/DiscussionGroup/DiscussionGroup.vue"
      ),
    SocialTeamUsers: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/TeamUsers"),
    FactMatch: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/FactMatch"),
    ImageMatch: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/ImageMatch"),
    SlidesWrapper: () =>
      import("@/components/GroupTeams/Common/Games/Slide/SlideWrapper.vue"),
    TwoTruthsReveal: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/TwoTruthsReveal"),
    Award: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/Award"),
    SocialVideo: () =>
      import("@/components/GroupTeams/Common/GameModes/Social/SocialVideo"),
    OrderTheCardsGame: () =>
      import(
        "@/components/GroupTeams/Common/GameModes/Social/OrderTheCards.vue"
      ),
    TheaterVoting: () =>
      import(
        "@/components/GroupTeams/Common/GameModes/TheaterVoting/TheaterVoting"
      ),
    MeetingSmartGrid: () =>
      import("@/components/GroupTeams/Common/GameModes/MeetingSmartGrid"),
    PlayMode: () => import("@/components/GroupTeams/Common/GameModes/PlayMode"),
    VotingMode: () =>
      import("@/components/GroupTeams/Common/GameModes/Voting/VotingMode"),
    MeetingMode: () =>
      import("@/components/GroupTeams/Common/GameModes/MeetingMode"),
    TelephonePictionary: () =>
      import(
        "@/components/GroupTeams/Common/Games/TelephonePictionary/TelephonePictionary.vue"
      ),
    WordCloud: () =>
      import("@/components/GroupTeams/Common/Games/WordCloud/WordCloud.vue"),
    UnconditionalPositiveRegard: () =>
      import(
        "@/components/GroupTeams/Common/Games/UnconditionalPositiveRegard/UnconditionalPositiveRegard.vue"
      ),
    ResizableText,
    HostMedia,
    ModeMapperHostContainer,
    ModeMapperTips,
    ResizeObserver
  },
  props: {
    mode: {
      type: String,
      required: true
    }
  },
  setup() {
    const { enlargedUI } = useEnlargedUI()
    return { enlargedUI }
  },
  data() {
    return {
      Mode,
      Mission,
      height: 0,
      prevOverrideSource: null,
      Layout
    }
  },
  computed: {
    ...mapGetters({
      game: "actualGame",
      mission: "getCurrentMission",
      onlineUsers: "onlineUsersArray",
      missionID: "currentMission",
      teams: "chats"
    }),
    ...mapGetters("auth", ["isHost", "user", "client"]),
    ...mapGetters("twilio", [
      "users",
      "screenshareVideoTrack",
      "screenshareAudioTrack"
    ]),
    ...mapGetters(["gameHost", "gameStatus", "missionPlaysArray"]),
    ...mapGetters("ScreenCapture", ["isSharingScreen"]),
    isFactMatch() {
      return this.behavior === Mission.FactMatch
    },
    isVideo() {
      return this.behavior === Mission.Video
    },
    isMatchGame() {
      return this.behavior === Mission.MatchGame
    },
    isOrderTheCardsGame() {
      return this.behavior === Mission.OrderTheCards
    },
    isSlides() {
      return this.behavior === Mission.Slides
    },
    isTwoTruthsReveal() {
      return this.behavior === Mission.TwoTruthsReveal
    },
    isPhotoAward() {
      return [Mission.TakePhoto, Mission.Photo, Mission.PhotoBooth].includes(
        this.behavior
      )
    },
    isAward() {
      return this.behavior === Mission.Award
    },
    isSocialTeamUsers() {
      return (
        this.layout === Layout.SOCIAL &&
        !this.isOrderTheCardsGame &&
        !this.isMatchGame &&
        !this.isFactMatch &&
        !this.isTwoTruthsReveal &&
        !this.isVideo
      )
    },
    isPresenter() {
      return User.isPresenter(this.user)
    },
    isPlayableMode() {
      return (
        this.mode === Mode.Play ||
        this.mode === Mode.Social ||
        this.mode === Mode.Huddle
      )
    },
    placeholderText() {
      if (
        this.isPresenter &&
        this.mode === Mode.Play &&
        this.behavior === Mission.PhotoBooth
      )
        return "Add some props and take a photo!"

      if (
        this.isPresenter &&
        this.mode === Mode.Huddle &&
        !this.isFactMatchLikeMission
      )
        return "Huddle Mode"

      if (
        this.isPlayableMode &&
        this.behavior === Mission.FactMatch &&
        !this.canPlayFactMatch
      )
        return "Sorry, there is not enough data to play this mission"

      if (
        this.isPlayableMode &&
        this.behavior === Mission.OrderTheCards &&
        !this.canPlayOrderTheCards
      )
        return "Sorry, there is not enough data to play this mission"

      return null
    },
    modeWithPresenterCondtion() {
      const isSubmitMode = mode => mode === Mode.Play || mode === Mode.Huddle

      const isPresenterMissionOveride = missionType =>
        missionType === Mission.TeamName ||
        missionType === Mission.FreeForm ||
        missionType === Mission.Text

      if (
        this.isPresenter &&
        isPresenterMissionOveride(this.behavior) &&
        isSubmitMode(this.modeWithOverrides)
      )
        return Mode.Explain

      return this.modeWithOverrides
    },
    pinnedTeamID() {
      if (User.isMobile(this.user) || this.behavior === Mission.QuestionCards) {
        return null
      }
      return this.game?.pinnedTeamID
    },
    pinnedUsers() {
      const pinnedTeamID = this.pinnedTeamID
      return pinnedTeamID
        ? this.onlineUsers.filter(
            user => user.teamID === pinnedTeamID && !User.isPresenter(user)
          )
        : []
    },
    layout() {
      if (this.isFactMatchHuddle) return Layout.SOCIAL
      if (this.isFactMatchResults) return null
      switch (this.modeWithOverrides) {
        case Mode.Explain:
        case Mode.Play:
        case Mode.Huddle:
        case Mode.Results:
          return Layout.PLAY
        case Mode.Social:
          return Layout.SOCIAL
        case Mode.Voting:
          return Layout.VOTING
        case Mode.Meeting:
        case Mode.Theater:
          return Layout.MEETING
        default:
          return null
      }
    },
    isVoting() {
      return this.layout === Layout.VOTING
    },
    isTheaterVoting() {
      return this.isVoting && this.mission.theaterVoting
    },
    modeWithOverrides() {
      if (this.behavior === Mission.PriceIsRight && this.mode === Mode.Results)
        return Mode.Social

      if (
        this.mode === Mode.Huddle &&
        this.isPresenter &&
        !this.isFactMatchLikeMission
      )
        return Mode.Play

      return this.mode
    },
    behavior() {
      return this.mission?.behavior
    },
    isHandMovement() {
      return this.behavior === Mission.HandMovement
    },
    isPdf() {
      return this.behavior === Mission.PDF
    },
    isGameOver() {
      return (
        !!this.mission && String(this.mission.name).indexOf("Game Over") > -1
      )
    },
    teamsList() {
      return Team.normalize(this.teams).filter(
        team => !Team.isSpecialPurpose(team)
      )
    },
    canPlayOrderTheCards() {
      const teams = this.teamsList
      if (!teams?.length) return false
      const cards = Array.isArray(this.mission?.orderCardsBottom)
        ? this.mission?.orderCardsBottom
        : []
      if (cards.length < 2) return false
      return teams.every(({ otherTeamFacts }) => {
        if (!Array.isArray(otherTeamFacts)) return false
        const count = otherTeamFacts.reduce(
          (acc, { usersCanSee }) => acc + Object.keys(usersCanSee ?? {}).length,
          0
        )
        return count > 1
      })
    },
    canPlayFactMatch() {
      return this.teamsList.every(
        ({ otherTeamFacts }) =>
          Array.isArray(otherTeamFacts) && otherTeamFacts.length > 1
      )
    },
    isFactMatchLikeMission() {
      return [
        Mission.FactMatch,
        Mission.MatchGame,
        Mission.OrderTheCards
      ].includes(this.behavior)
    },
    isFactMatchHuddle() {
      return (
        this.isFactMatchLikeMission && this.modeWithOverrides === Mode.Huddle
      )
    },
    isFactMatchResults() {
      return (
        (this.isFactMatchLikeMission || this.behavior === Mission.VideoTeam) &&
        this.modeWithOverrides === Mode.Results
      )
    },
    isTheaterMode() {
      return this.modeWithOverrides === Mode.Theater
    },
    hostID() {
      return this.gameHost?.id
    },
    isHostVisible() {
      if (this.overrrideSource) return true

      const user = this.gameHost
      if (!this.$store.getters.isUserAlive(user)) return false
      if (User.isSelectingTeam(user)) return true
      if (User.isPlaying(user)) return true

      return false
    },
    overrrideSource() {
      if (!this.game?.autopilot) return null
      const { mission, modeWithOverrides: mode } = this
      const data = mission?.["hostless-video"]?.[mode]
      const url = data?.url
      if (!url) return null
      const mirrored = !!data?.flipped
      const source = { mission: mission?.id, mode, url, mirrored }
      if (_.isEqual(source, this.prevOverrideSource)) return null
      return source
    },
    isHostMediaVisible() {
      if (
        this.game?.roomGroupId &&
        !this.isSocialVideoMission &&
        (!isHostIncompatibleMode(this.modeWithOverrides) ||
          (this.modeWithOverrides === Mode.Theater &&
            this.game?.autopilot &&
            !this.$store.getters.gameStatus?.selectedMeetingUsers)) &&
        !this.isTheaterVoting
      )
        return true

      return Boolean(
        this.mission &&
          !this.$store.getters["auth/isIRLSession"] &&
          !this.pinnedTeamID &&
          !this.$_TipJarMixin_isTipJarVisible &&
          !this.isSocialVideoMission &&
          (!isHostIncompatibleMode(this.modeWithOverrides) ||
            (this.modeWithOverrides === Mode.Theater &&
              this.game?.autopilot &&
              !this.$store.getters.gameStatus?.selectedMeetingUsers)) &&
          !this.isTheaterVoting &&
          !this.isHostInDiscussionGroup &&
          this.isHostVisible
      )
    },
    isHostInDiscussionGroup() {
      return (
        this.teams[this.gameHost?.teamID] != null &&
        this.behavior === Mission.DiscussionGroup &&
        this.gameHost?.teamID === this.user?.teamID
      )
    },
    isGreenRoom() {
      return this.game?.gameType === GameType.GreenRoom
    },
    isGreenMeeting() {
      return this.isGreenRoom && this.modeWithOverrides === Mode.Meeting
    },
    isMeeting() {
      return this.modeWithOverrides === Mode.Meeting
    },
    classes() {
      return {
        "mode-mapper--full-width": this.fullWidth,
        "mode-mapper--with-left-padding": this.hasLeftPadding
      }
    },
    raffleVisible() {
      return this.getRaffleVisible(this.game.gameType, this.modeWithOverrides)
    },
    hasLeftPadding() {
      return (
        this.raffleVisible &&
        this.modeWithOverrides !== Mode.Social &&
        this.behavior !== Mission.WhiteElephant
      )
    },
    fullWidth() {
      return (
        this.isSocialTeamUsers ||
        this.isGreenMeeting ||
        this.isTheaterMode ||
        this.canShowScreenShare ||
        this.isTheaterVoting ||
        this.isMeeting
      )
    },
    isSocialVideoMission() {
      return (
        this.mission?.behavior === Mission.Video &&
        this.modeWithOverrides === Mode.Social
      )
    },
    isHuddleVideoTeamMission() {
      return this.mission?.behavior === Mission.VideoTeam
    },
    sharingScreenOn() {
      return !!this.screenshareVideoTrack?.mediaStreamTrack
    },
    sharingAudioOn() {
      return !!this.screenshareAudioTrack?.mediaStreamTrack
    },
    canShowScreenShare() {
      return this.sharingScreenOn || this.isSharingScreen || this.sharingAudioOn
    },
    team() {
      return this.teams?.[
        this.$store.getters["group/canViewerSelectGlobalTeam"]
          ? this.$store.getters["group/globalTeamID"]
          : this.user.teamID
      ]
    },
    hasSubmitted() {
      const videos = this.team?.teamVideos || {}

      const isUserSubmitted = user => Boolean(videos[user?.id]?.correct)

      if (!this.isHost) return isUserSubmitted(this.user)

      const globalTeamID = this.$store.getters["group/globalTeamID"]

      return this.onlineUsers.some(
        user => user.teamID === globalTeamID && isUserSubmitted(user)
      )
    }
  },
  watch: {
    mission: {
      handler(mission) {
        if (!mission) {
          return
        }
        if (mission.photo) this.cacheImage(mission.photo)
        if (mission.instructionsPhoto)
          this.cacheImage(mission.instructionsPhoto)
        if (
          mission.behavior === Mission.PhotoBooth &&
          Array.isArray(mission.photoboothImages)
        ) {
          mission.photoboothImages.forEach(this.cacheImage)
        } else if (
          mission.behavior === Mission.Represent &&
          Array.isArray(mission.representImages)
        ) {
          mission.representImages.forEach(this.cacheImage)
        }
      },
      immediate: true
    },
    isHandMovement: {
      handler(isHandMovement) {
        if (isHandMovement) {
          if (!HandMovementController.state.initialized) {
            console.log("Hand movement initializing...")
            HandMovementController.initializeModel()
          }
        } else {
          HandMovementController.destroyModel()
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$el.offsetHeight
    })
  },
  methods: {
    onHostMediaEnded(data) {
      this.prevOverrideSource = data
    },
    onResize: _.debounce(function ({ height }) {
      this.height = height
    }, 500),
    cacheImage(src) {
      if (!src) return
      const img = new Image()
      img.src = src
    }
  }
})
</script>

<style lang="scss">
@import "@/modules/raffle/styles/variables/_variables.scss";

.mode-mapper {
  $block: &;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 200px;
  text-align: center;
  max-width: 82vw;
  margin: 0 auto;

  &__placeholder {
    display: flex;
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    margin-top: 25px;
    margin-bottom: 25px;

    &--enlarged {
      max-width: 100%;
    }
  }

  &__placeholder-text {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;

    background: radial-gradient(
      137.87% 137.87% at 49.24% 9.52%,
      #2c2c2c 0%,
      #000000 100%
    );

    padding: 16px;
    color: rgba(255, 255, 255);
    font-size: 30px;
    font-weight: bold;
    display: flex;
    flex: 1;
    justify-content: center;
    margin-left: 8px;
    margin-right: 8px;
  }

  &__pinned-users {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__transition {
    transform: translateX(0);
    opacity: 1;
    &-enter-active,
    &-leave-active {
      transition: all ease-in-out 0.8s;
    }
    &-enter {
      transform: translateX(300px);
      opacity: 0;
    }
    &-leave-to {
      transform: translateX(-300px);
      opacity: 0;
    }
  }

  ::-webkit-scrollbar-track {
    background: $color-primary-dark;
  }

  &--full-width {
    max-width: 100%;
  }

  &--with-left-padding {
    padding-left: $raffle-width;
  }
}
</style>
