import type { Module } from "vuex"

import TwilioRoomStream from "@/services/stream.service/Room"
import StreamingService from "@/services/stream.service/Game"

import MutationTypes from "./modules/TwilioStream/mutation-types"
import ActionTypes from "./modules/TwilioStream/action-types"

enum Status {
  WORKING
}

enum StreamType {
  PARTICIPANTS_ONLY,
  SCREEN
}

const state = {
  status: null as Status,
  room: null as any
}

export type State = typeof state

const TwilioStream: Module<State, unknown> = {
  namespaced: true,
  state: {
    status: null,
    room: null
  },
  mutations: {
    [MutationTypes.UPDATE_STATUS](state, payload) {
      state.status = payload
    },
    [MutationTypes.UPDATE_ROOM](state, payload) {
      state.room = payload
    }
  },
  actions: {
    [ActionTypes.STOP_STREAM]({ state, commit }) {
      state.room?.stop()
      commit(MutationTypes.UPDATE_ROOM, null)
    },
    async [ActionTypes.TOGGLE_STREAM](
      { commit, state, rootState },
      { $confirm, $info }
    ) {
      if (state.status === Status.WORKING) return 0

      commit(MutationTypes.UPDATE_STATUS, Status.WORKING)

      try {
        state.room?.stop()

        const { gameID, game } = rootState as any

        if (game.stream) {
          await StreamingService.stop(gameID)
        } else {
          let streamType: StreamType

          try {
            if ($confirm)
              await $confirm({
                title: "Would you like to stream your screen?",
                message: ""
              })
            streamType = StreamType.SCREEN
          } catch (e) {
            streamType = StreamType.PARTICIPANTS_ONLY
          }

          if (streamType === StreamType.PARTICIPANTS_ONLY) {
            await StreamingService.start(gameID)
          } else if (streamType === StreamType.SCREEN) {
            const room = new TwilioRoomStream()
            commit(MutationTypes.UPDATE_ROOM, room)
            room.once("ended", () => StreamingService.stop(gameID))
            const { id: roomID } = await room.start()
            await StreamingService.start(gameID, roomID)
          }
        }
      } catch (e) {
        if (String(e?.message).includes("Cannot read properties")) {
          await $info(`To start broadcast check "Share audio" checkbox`)
        } else if (e.message) {
          await $info(e.message)
        }
        console.error(e)
      }

      commit(MutationTypes.UPDATE_STATUS, null)
    }
  },
  getters: {
    working(state) {
      return state.status === Status.WORKING
    }
  }
}

export default TwilioStream
