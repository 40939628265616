var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "container",
      staticClass: "resizable-text-container",
      class: { "resizable-text-container--scrollbar": _vm.scrollbar },
    },
    [
      _c("h1", {
        ref: "text",
        staticClass: "resizable-text-container__resizable-text",
        style: { fontSize: _vm.fontSize },
        domProps: { innerHTML: _vm._s(_vm.localText) },
      }),
      _vm.update ? _c("ResizeObserver", { on: { notify: _vm.fit } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }