<template>
  <div class="weve-loading">
    <div class="weve-loading__container">
      <img class="weve-loading__logo" :src="logoUrl" />
      <div class="mt-4" v-if="loading">
        <v-progress-linear color="blue" indeterminate />
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
const LOGO_URL = require("@/assets/weve-full-toolbar.svg")

export default {
  name: "WeveLoading",
  data() {
    return {
      logoUrl: LOGO_URL
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss">
.weve-loading {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  // background-color: #16111a;
  align-items: center;
  &__container {
    max-width: 40%;
    width: 600px;
    position: relative;
    top: 8px;
  }
  &__logo {
    width: 100%;
    height: auto;
  }
}
</style>
