var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rtb-image-uploader" },
    [
      _c("form-control-label", { attrs: { for: _vm.inputId } }, [
        _vm._v(_vm._s(_vm.label) + ":"),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "aspect-ratio",
              rawName: "v-aspect-ratio",
              value: _vm.aspectRatio,
              expression: "aspectRatio",
            },
          ],
          staticClass: "rtb-image-uploader__media",
        },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "rtb-image-uploader__spinner" },
                [_c("rtb-spinner")],
                1
              )
            : [
                _vm.value
                  ? _c("img", { attrs: { src: _vm.value } })
                  : _c("svg-icon", {
                      staticClass: "rtb-image-uploader__icon",
                      attrs: { name: "image" },
                    }),
              ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "rtb-image-uploader__controls" },
        [
          _c("text-input", {
            attrs: { id: _vm.inputId, disabled: _vm.loading },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          }),
          _c(
            "input-button",
            {
              attrs: { title: "Upload", disabled: _vm.loading },
              on: { click: _vm.triggerInput },
            },
            [_c("svg-icon", { attrs: { name: "file-upload-regular" } })],
            1
          ),
          _vm.value
            ? _c(
                "input-button",
                {
                  attrs: { title: "Clear", disabled: _vm.loading },
                  on: { click: _vm.onClear },
                },
                [_c("svg-icon", { attrs: { name: "trash-regular" } })],
                1
              )
            : _vm._e(),
          _vm.removeable
            ? _c(
                "input-button",
                {
                  attrs: {
                    color: "danger",
                    title: "Remove",
                    disabled: _vm.loading,
                  },
                  on: { click: _vm.onRemove },
                },
                [_c("svg-icon", { attrs: { name: "times-regular" } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.error
        ? _c("form-control-error", [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
      _c("input", {
        ref: "input",
        staticClass: "rtb-image-uploader__input",
        attrs: { type: "file", accept: _vm.accept },
        on: {
          change: _vm.changeHandler,
          click: function ($event) {
            return $event.stopImmediatePropagation()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }