export interface AudioEntry {
  id: string
  key?: string
  name: string
  source: string
  volume: number
  color?: string
  keypress?: string
  overlay?: boolean
}

export enum AudioCollection {
  User = "user",
  Game = "game",
  Client = "client"
}
