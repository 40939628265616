<template>
  <div class="host-mini-topbar" :class="{ 'pb-0': isMeetingMode }">
    <v-btn
      v-if="!isMeetingMode"
      class="host-mini-topbar__btn"
      flat
      dark
      @click="$router.push(`/pickteams/${urlID}`)"
    >
      <v-icon dark>people_alt</v-icon>
    </v-btn>
    <AudioDrawerToggle class="host-mini-topbar__music-btn" />
  </div>
</template>
<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { Mode } from "@shared/enums"

import AudioDrawerToggle from "@/components/drawers/audio/AudioDrawerToggle"

export default Vue.extend({
  name: "HostMiniTopBar",
  components: {
    AudioDrawerToggle
  },
  computed: {
    ...mapGetters(["urlID"]),
    ...mapGetters({
      currentMode: "getCurrentMode"
    }),
    ...mapGetters("auth", ["isModerator"]),
    isMeetingMode() {
      return this.currentMode === Mode.Meeting
    }
  }
})
</script>

<style lang="scss">
.host-mini-topbar {
  position: fixed;
  z-index: 101;
  padding: 8px;
  top: 0;
  right: 0;
  background-color: #292932;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__btn {
    padding: 0;
    margin: 0;
    min-width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  &__music-btn {
    height: 40px;
    margin-top: -7px;
    padding-right: 15px;
  }
}
</style>
