import Vue, { VueConstructor } from "vue"

const ConfirmMixin = (
  Vue as VueConstructor<
    Vue & {
      resolve: (value?: unknown) => void
      reject: (reason?: unknown) => void
    }
  >
).extend({
  name: "ConfirmMixin",
  inheritAttrs: false,
  data() {
    return {
      value: false
    }
  },
  watch: {
    value(value) {
      if (!value) {
        this.reject()
      }
    }
  },
  methods: {
    confirm() {
      this.value = true
      // in case we need to wrap something with the handler
      typeof this.exec === "function" && this.exec()
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    accept() {
      typeof this.exec === "function" && this.exec()
      this.resolve()
      this.value = false
    },
    decline() {
      this.reject()
      this.value = false
    }
  }
})

export default ConfirmMixin
