var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
      _c("path", {
        attrs: {
          d: "M6.16 9.22l-.01-.26V6.98 3c.01-1.6 1.17-2.81 2.84-3a3.07 3.07 0 013.26 2.44l.01.08c.13.58 0 1.01-.47 1.46C10.49 5.2 9.2 6.46 7.94 7.7L6.5 9.11a1.6 1.6 0 00-.16.2l-.07.1a.49.49 0 00-.1-.06v-.13zM1.32 17c-.57 0-.9-.48-.69-.89.07-.13.18-.25.3-.35C5.98 10.8 11.06 5.85 16.14.89c.14-.13.29-.25.46-.32.26-.1.5-.02.68.21.18.23.2.47.03.7-.07.12-.17.21-.26.3L12 6.73 1.78 16.7c-.12.11-.27.2-.37.25l-.09.05zM10.89 16.74h-1v-1.06-1c0-.22.05-.3.28-.35 1.14-.2 2.14-.7 2.97-1.49 1.45-1.39 1.77-3.14 1.64-5.02-.04-.44-.4-.68-.81-.58-.3.07-.46.3-.48.64l-.01.63c0 .45 0 .9-.1 1.34a4.23 4.23 0 01-6.02 2.9c-.1-.05-.25-.07-.3-.03l-.62.53-.3.26c.73.45 1.4.72 2.13.83.29.05.33.16.32.39-.01.55-.02 1.1 0 1.66.02.3-.08.37-.38.37a88.5 88.5 0 00-2.64-.01c-.4 0-.67.24-.68.6-.02.36.25.62.65.65h7.17c.11 0 .23 0 .34-.02a.62.62 0 00.42-.93c-.14-.25-.38-.3-.66-.3H10.9z",
        },
      }),
      _c("path", {
        attrs: {
          d: "M12.3 7.6v.2c.05 1.27.1 2.46-.92 3.41a3.17 3.17 0 01-3.44.57l1.41-1.36 2.95-2.81zM4.02 10.87c.05.15.1.3.13.45l.15.03.15-.17c.1-.13.2-.26.33-.37.33-.26.4-.52.32-.95C5 9.42 5 8.96 5 8.5l-.03-.65c-.02-.39-.29-.64-.64-.64a.61.61 0 00-.64.64v.22c0 .56-.02 1.11.06 1.66.04.38.16.75.27 1.13z",
        },
      }),
    ]),
    _c("defs", [
      _c("clipPath", { attrs: { id: "clip0" } }, [
        _c("path", { attrs: { d: "M0 0h18v18H0z" } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }