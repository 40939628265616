var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fullscreen-toggle",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.toggle.apply(null, arguments)
        },
      },
    },
    [
      !_vm.$slots.icon
        ? _c("SvgIcon", { attrs: { name: "frame", width: "20", height: "20" } })
        : _vm._t("icon"),
      _vm.isFullscreen
        ? _c("div", { staticClass: "rtb-right-toolbar__list-item-title" }, [
            _vm._v(" Exit Full Screen "),
          ])
        : _c("div", { staticClass: "rtb-right-toolbar__list-item-title" }, [
            _vm._v("Go Full Screen"),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }