var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.state.open
    ? _c(
        "RtbControlButton",
        {
          staticClass: "audio-drawer-button",
          attrs: { sm: "" },
          on: { click: _vm.close },
        },
        [
          _c(
            "v-icon",
            { key: "close", staticStyle: { margin: "-4px 0", color: "white" } },
            [_vm._v("close")]
          ),
        ],
        1
      )
    : _c(
        "RtbControlButton",
        {
          staticClass: "audio-drawer-button",
          attrs: { sm: "" },
          on: { click: _vm.open },
        },
        [
          _c("svg-icon", {
            staticClass: "mr-1",
            attrs: { name: "chevron-left-regular", size: "small" },
          }),
          _c("v-icon", { attrs: { dark: "" } }, [_vm._v("music_note")]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }