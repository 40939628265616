import type { Module } from "vuex"
// import { firebaseAction } from "vuexfire"
import { groupBy, isNil } from "lodash"

import type { User } from "@/types/user"

import { db } from "../firebase"

import GetterTypes from "./modules/UserSearch/getter-types"
import ActionTypes from "./modules/UserSearch/action-types"

import { Role } from "@/helpers"
import { UserSearchMutationTypes } from "./modules/UserSearch/mutation-types"

const state = {
  users: [] as User[]
}

export type State = typeof state

let subscribersCount = 0
let usersFetchInterval = null

const FETCH_TIME_INTERVAL = 10000

const UserSearch: Module<State, unknown> = {
  namespaced: true,
  state: {
    users: []
  },
  mutations: {
    [UserSearchMutationTypes.UPDATE_USERS](state, { users }) {
      state.users = users || []
    }
  },
  actions: {
    async [ActionTypes.SUBSCRIBE_TO_USERS]({ dispatch }) {
      if (subscribersCount === 0) {
        subscribersCount++
        if (usersFetchInterval) clearInterval(usersFetchInterval)
        await dispatch(ActionTypes.FETCH_USERS)
        usersFetchInterval = setInterval(async () => {
          await dispatch(ActionTypes.FETCH_USERS)
        }, FETCH_TIME_INTERVAL)
        return
      }
      subscribersCount++
    },
    [ActionTypes.UNSUBSCRIBE_FROM_USERS]({ commit }) {
      subscribersCount--
      // If no subscribers left we completely unsubscribe and rest a state
      if (subscribersCount <= 0) {
        clearInterval(usersFetchInterval)
        usersFetchInterval = null
        commit(UserSearchMutationTypes.UPDATE_USERS, { users: [] })
      }
    },
    async [ActionTypes.FETCH_USERS]({ commit, rootGetters }) {
      const clientID = rootGetters["auth/clientID"]
      const snapshot = await db
        // @ts-ignore TODO
        .auxiliary()
        .ref(`org/1/users`)
        .orderByChild("clientID")
        .equalTo(clientID)
        .limitToFirst(5000)
        .once("value")
      const value = snapshot.val() || {}
      const users = []

      for (const key in value) {
        const user = value[key]
        user.id = key
        users.push(user)
      }

      commit(UserSearchMutationTypes.UPDATE_USERS, { users })
    }
  },
  getters: {
    [GetterTypes.ONLINE_USERS](state, _, __, rootGetters) {
      return state.users.filter(rootGetters.isUserAlive)
    },
    [GetterTypes.ONLINE_USERS_GROUPED_BY_GAME_ID](_, getters) {
      return groupBy(
        getters[GetterTypes.ONLINE_USERS].filter(
          user => user.gameID !== undefined
        ),
        "gameID"
      )
    },
    [GetterTypes.GET_USERS_BY_TEAM_ID]:
      (state, _, __, rootGetters) => (teamID: string) => {
        const isUserAlive = rootGetters.isUserAlive
        return state.users.filter(
          user => user.teamID === teamID && isUserAlive(user)
        )
      },
    [GetterTypes.GET_HOSTS_BY_GAME_ID]: state => (gameID: string) => {
      return state.users.filter(
        user => user.gameID === gameID && user.role === Role.Host
      )
    }
  }
}

export default UserSearch
