/** @type import('vue').PluginObject<never> */
import semver from "semver"

export const VersionPlugin = {
  install(Vue) {
    Vue.prototype.$app = {
      version: process.env.VUE_APP_VERSION,
      isGreaterThan(version) {
        return semver.valid(version) && semver.gt(this.version, version)
      },
      isSmallerThan(version) {
        return semver.valid(version) && semver.lt(this.version, version)
      }
    }
  }
}
