import type { PropType, VNode } from "vue"
import Vue from "vue"
import type { WithEvents } from "vue-typed-emit"

import type { UserCardEvents } from "./UserCard.types"
import { UserCardColor, UserCardState } from "./UserCard.types"
import StarIcon from "./icons/StarIcon"
import CogIcon from "./icons/CogIcon"
import MicrophoneOffIcon from "./icons/MicrophoneOffIcon"
import MicrophoneIcon from "./icons/MicrophoneIcon"
import UserCardIcon from "./UserCardIcon"
import UserCardButton from "./UserCardButton"

// ignore `style-resources-loader`
import "!vue-style-loader!css-loader!sass-loader!./UserCard.scss"

export default (Vue as WithEvents<UserCardEvents>).extend({
  name: "UserCard",
  props: {
    color: {
      type: String as PropType<UserCardColor>,
      default: UserCardColor.Default
    },
    badge: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    current: {
      type: Boolean,
      default: false
    },
    points: {
      type: Number,
      default: undefined
    },
    submitted: {
      type: Boolean,
      default: false
    },
    onboarding: {
      type: Boolean,
      default: false
    },
    states: {
      type: Set as PropType<Set<UserCardState>>,
      default: () => new Set()
    },
    mutable: {
      type: Boolean,
      default: false
    },
    configurable: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    muted(): boolean {
      return this.states.has(UserCardState.Muted)
    },
    removeScribe() {
      return this.$store.getters.game?.removeScribe
    },
    scribeLabel() {
      return (
        this.$store.getters.game?.scribeLabel ||
        this.$store.state.auth.client?.scribeLabel ||
        "Scribe"
      )
    }
  },
  methods: {
    toggleMute() {
      this.$emit("update:muted", this.muted ? false : true)
    },
    configure() {
      this.$emit("configure")
    }
  },
  render(h): VNode {
    const states = []

    if (this.states.size > 0 && !this.badge) {
      for (const state of this.states) {
        states.push(
          h("li", { key: state }, [h(UserCardIcon, { props: { icon: state } })])
        )
      }
    }

    return h(
      "div",
      {
        staticClass: "weve-user-card",
        class: {
          [`weve-user-card--color--${this.color}`]:
            this.color !== UserCardColor.Default,
          "weve-user-card--current": this.current,
          "weve-user-card--submitted": this.submitted,
          "weve-user-card--rounded": this.rounded
        },
        on: { ...this.$listeners }
      },
      [
        h("div", { staticClass: "weve-user-card__inner" }, [
          h("div", { staticClass: "weve-user-card__holder" }, [
            states.length > 0
              ? h("ul", { staticClass: "weve-user-card__states" }, states)
              : null,
            this.onboarding ||
            this.mutable ||
            this.configurable ||
            this.$slots.actions
              ? h("div", { staticClass: "weve-user-card__overlay" }, [
                  this.onboarding
                    ? h(
                        "span",
                        { staticClass: "weve-user-card__overlay-text" },
                        "Onboarding"
                      )
                    : [
                        this.mutable
                          ? h(
                              UserCardButton,
                              {
                                attrs: {
                                  "aria-label": this.muted ? "Unmute" : "Mute"
                                },
                                on: {
                                  click: this.toggleMute
                                }
                              },
                              [
                                this.muted
                                  ? h(MicrophoneOffIcon)
                                  : h(MicrophoneIcon)
                              ]
                            )
                          : null,
                        this.$slots.actions
                      ],
                  this.configurable
                    ? h(
                        UserCardButton,
                        {
                          attrs: {
                            "aria-label": "Settings"
                          },

                          on: {
                            click: this.configure
                          }
                        },
                        [h(CogIcon)]
                      )
                    : null
                ])
              : null,
            this.$slots.default
          ]),
          this.points !== undefined
            ? h("span", { staticClass: "weve-user-card__points" }, this.points)
            : null,
          this.badge
            ? h("span", { staticClass: "weve-user-card__badge" }, [h(StarIcon)])
            : null,
          this.badge && !this.removeScribe
            ? h(
                "div",
                { staticClass: "weve-user-card__label" },
                this.scribeLabel
              )
            : null,
          this.name
            ? h("span", { staticClass: "weve-user-card__name" }, [
                this.name,
                this.$slots.namePostfix
              ])
            : undefined
        ])
      ]
    )
  }
})
