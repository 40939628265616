






























import Vue from "vue"
import { Chrome } from "vue-color"

import TextInput from "../TextInput/TextInput.vue"
import InputButton from "../InputButton/InputButton.vue"
import ConstrastPreview from "../ContrastPreview/ContrastPreview.vue"

export default Vue.extend({
  name: "RTBColorPicker",
  inheritAttrs: false,
  components: {
    "color-picker": Chrome,
    TextInput,
    InputButton,
    ConstrastPreview
  },
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      menu: false
    }
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set({ hex }: { hex: string }) {
        this.$emit("input", hex)
      }
    }
  }
})
