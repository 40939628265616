var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVisible
    ? _c("GameCountdown", {
        staticClass: "pill-countdown",
        class: { "pill-countdown--endClose": _vm.endClose },
        attrs: { endTime: _vm.endTime },
        on: { progress: _vm.progress },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }