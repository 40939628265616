var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.className,
      on: {
        dblclick: _vm.select,
        click: [
          function ($event) {
            if (
              $event.ctrlKey ||
              $event.shiftKey ||
              $event.altKey ||
              $event.metaKey
            ) {
              return null
            }
            return _vm.toggleAudio.apply(null, arguments)
          },
          function ($event) {
            if (!$event.altKey) {
              return null
            }
            return _vm.toggleModerator.apply(null, arguments)
          },
        ],
      },
    },
    [
      _c(
        "div",
        { staticClass: "host-media__inner" },
        [
          _vm.overrrideSource && !_vm.localOverrrideSource
            ? _c("VideoComponent", {
                key:
                  _vm.overrrideSource.mission +
                  "-" +
                  _vm.overrrideSource.mode +
                  "-hostless-video-transition",
                staticClass: "host-media__media-placeholder host-media__media",
                attrs: {
                  autoplay: "",
                  loop: "",
                  src: require("@/assets/video-transition-1.mp4"),
                },
              })
            : !!_vm.localOverrrideSource
            ? _c("SmartMedia", {
                key:
                  _vm.localOverrrideSource.mission +
                  "-" +
                  _vm.localOverrrideSource.mode +
                  "-hostless-video",
                ref: "video-1",
                staticClass: "host-media__media-placeholder host-media__media",
                class:
                  ((_obj = { rounded: _vm.rounded }),
                  (_obj["--mirrored"] = _vm.localOverrrideSource.mirrored),
                  _obj),
                attrs: {
                  src: _vm.localOverrrideSource.url,
                  paused: false,
                  autoplay: "",
                },
                on: { ended: _vm.onHostlessSourceEnded },
              })
            : _vm.user && _vm.user.id
            ? _c("UserMedia", {
                key: _vm.user.id + "-user-media",
                staticClass: "host-media__media",
                attrs: {
                  identity: _vm.user.id,
                  imageUrl: _vm.user.image,
                  userRole: _vm.user.role,
                  disable: _vm.hostMediaDisabled,
                },
              })
            : _vm.viewerId !== _vm.userId && _vm.playbackUrl != null
            ? _c("ChimeStream", { attrs: { playbackUrl: _vm.playbackUrl } })
            : _vm._e(),
          _vm.overlayText
            ? _c("div", { staticClass: "host-media__overlay-text" }, [
                _vm._v(" " + _vm._s(_vm.overlayText) + " "),
              ])
            : _vm.text
            ? _c("div", { staticClass: "host-media__text" }, [
                _vm._v(" " + _vm._s(_vm.text) + " "),
              ])
            : _vm._e(),
          _vm.isStreamingAvailable
            ? _c("v-switch", {
                staticClass: "shrink",
                staticStyle: {
                  position: "absolute",
                  top: "0px",
                  margin: "0px",
                  left: "50%",
                  transform: "translateX(-50%)",
                },
                attrs: { color: "#8680FF", disabled: _vm.working },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.streaming,
                  callback: function ($$v) {
                    _vm.streaming = $$v
                  },
                  expression: "streaming",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.user && _vm.user.muted
        ? _c(
            "IconBase",
            {
              staticClass: "host-media__mute",
              attrs: {
                iconName: "muted",
                size: "14",
                iconColor: "rgb(147, 145, 145)",
              },
            },
            [_c("IconMute")],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }