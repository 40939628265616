import _ from "lodash"
import firebase from "firebase/compat/app"
import "firebase/compat/database"
import isUrl from "is-url"
import Cookies from "js-cookie"

import { db } from "@/firebase"
import { Role, Navigation, Firebase as FirebaseHelper } from "@/helpers"
import { EmailVerificationError } from "@/helpers/exceptions"

import AuthService from "@/services/auth.service"
import { getCurrentUser, signOut } from "@/services/auth.service"
import HubSpot from "@/services/hubspot.service"
import User from "@shared/User"
import { isIRLHost } from "@shared/helpers/isIRL"
import Page from "@/router/Page"

import {
  getToken,
  signInWithEmailAndPassword,
  signUpWithEmailAndPassword,
  sendEmailVerification,
  sendResetPassword,
  googleSingIn,
  reauth,
  signInAnonymously,
  signUpSomeoneElse
} from "@/services/auth.service"
import UserService, {
  fetchUser,
  updateUser,
  getOnlineUsersCountByClientID
} from "@/services/user.service"
import {
  canUserJoinGame,
  getGameMatchingData,
  fetchOneGame,
  fetchGame,
  fetchGamesByClientID
} from "@/services/game.service"
import { fetchClient } from "@/services/client.service"
import Session from "@shared/Session"
import Answer from "@shared/Answer"
import normalizeEmail from "@shared/helpers/normalize-email"
import MissionType from "@shared/enums/Mission"

export const MODULE_NAME = "auth"

export const GetterTypes = {
  IS_ANON: "IS_ANON",
  IS_SHARD_SESSSION: "IS_SHARD_SESSION"
}

export const ActionTypes = {
  INITIALIZE_USER: "INITIALIZE_USER",
  CHECK_CLIENT_CAPACITY: "CHECK_CLIENT_CAPACITY",
  ADD_LOGIN_TO_PLAY: "ADD_LOGIN_TO_PLAY"
}

const MODERATED_ROUTES = ["game", "pickteams"]

const REAUTH_INTERVAL = 1000 * 60 * 10

let interval = null
let clientsSubscriptionRef = null

const maybeSingUp = async (email, password) => {
  try {
    console.log("SIGN IN")
    const { user } = await signInWithEmailAndPassword({ email, password })
    return user
  } catch (e) {
    console.log("SIGN UP")
    const { user } = await signUpWithEmailAndPassword({ email, password })
    return user
  }
}

const getUserIdByReauth = async () => {
  try {
    await reauth()
    const userID = getCurrentUser()?.uid
    if (!userID) throw new Error("User ID error")
    const user = await fetchUser({ userID })
    if (!user) throw new Error(`User ${userID} is undefined`)
    console.log(`Anonymous user ${userID} reauthenticated successfully`)
    return userID
  } catch (e) {
    console.log(e)
  }

  await signOut()
  const data = await signInAnonymously()
  const userID = data?.user?.uid
  if (!userID) throw new Error("Cannot authorize anonymously")
  console.log(`New anonymous user ${userID} created successfully`)
  return userID
}

const getUserObject = ({ user, audit, vip = null }) => {
  let { role, teamID, selected } = user
  const isHost = role === Role.Host

  if (isHost) {
    teamID = 0
    selected = false
  } else if (audit === false && user.role === Role.Audit) {
    role = Role.Player
  } else if (audit === true || user.role === Role.Audit) {
    teamID = 0
    selected = false
    role = Role.Audit
  } else if (user.role === Role.Spectator) {
    role = Role.Spectator
    teamID = 0
    selected = false
  } else {
    role = Role.Player
  }

  const args = {}

  if (user.identifier) args.identifier = user.identifier

  if (user.conference) args.conference = user.conference

  return {
    id: user.id,
    name: user.name || "",
    username: user.name || "",
    image: user.image || null,
    firstname: user.firstname,
    lastname: user.lastname,
    orgID: user.orgID,
    originOrgID: user.originOrgID || user.orgID,
    role: role,
    muted: false,
    super: !!user.super,
    gameID: user.gameID || null,
    clientID: user.clientID,
    teamID: teamID || 0,
    selected: !!selected,
    speaker: !!user.speaker,
    loginTimestamp: firebase.database.ServerValue.TIMESTAMP,
    noGameTimestamp: user.noGameTimestamp ?? 0,
    verificationRequired: !!user.verificationRequired,
    anonymous: !!user.anonymous,
    speechToText:
      "boolean" === typeof user.speechToText ? user.speechToText : null,
    vip,
    ...args
  }
}

export const MutationTypes = {
  UPDATE_CLIENT: "UPDATE_CLIENT"
}

const ONBOARDING_KEY = "onboarding"
const CAMERA_ENABLED_KEY = "weve-last-known-onbaording"
const ANON_AUTHORIZATION_KEY = "authorization"

const AuthModule = {
  namespaced: true,
  state: {
    onboarding: Cookies.get(ONBOARDING_KEY),
    isAnonAuthorization: Cookies.get(ANON_AUTHORIZATION_KEY),
    user: {},
    token: null,
    status: null,
    error: null,
    initialized: false,
    initializing: false,
    clientID: null,
    client: null,
    now: Date.now(),
    camera: localStorage[CAMERA_ENABLED_KEY]
  },
  mutations: {
    UPDATE_CAMERA_ENABLED(state, payload) {
      const value = Boolean(payload)
      localStorage.setItem(CAMERA_ENABLED_KEY, value)
      state.camera = value
    },
    UPDATE_LAST_KNOWN_ONBOARDING(state, payload) {
      const value = payload === true ? "true" : "false"
      Cookies.set(ONBOARDING_KEY, value, {
        expires: state.user?.anonymous ? 3 : 7
      })
      state.onboarding = value
    },
    UPDATE_USER(state, payload) {
      state.user = { ...payload }
    },
    UPDATE_CLIENT_ID(state, { clientID }) {
      state.clientID = clientID
    },
    UPDATE_USER_FIREBASE_TOKEN(state, payload) {
      state.token = payload
    },
    UPDATE_ERROR(state, { message }) {
      state.error = message
    },
    UPDATE_INITIALIZED_STATUS(state, { status }) {
      state.initialized = status
    },
    UPDATE_INITIALIZING_STATUS(state, { status }) {
      state.initializing = status
    },
    UPDATE_STATUS(state, { status }) {
      state.status = status
    },
    [MutationTypes.UPDATE_CLIENT](state, { client }) {
      state.client = client
    },
    UPDATE_ANON_AUTHORIZATION(state, payload) {
      const value = payload === true ? "true" : "false"
      Cookies.set(ANON_AUTHORIZATION_KEY, payload, { expires: 365 })
      state.isAnonAuthorization = value
    }
  },
  actions: {
    async subscribeToClient({ commit }, { clientID }) {
      clientsSubscriptionRef?.off("value")
      commit("UPDATE_CLIENT_ID", { clientID })
      clientsSubscriptionRef = db.auxiliary(clientID).ref(`clients/${clientID}`)
      await new Promise(resolve => {
        clientsSubscriptionRef.on("value", snapshot => {
          commit(MutationTypes.UPDATE_CLIENT, {
            client: { ...snapshot.val(), id: clientID }
          })
          resolve()
        })
      })
    },
    async updateError({ commit }, { message }) {
      commit("UPDATE_ERROR", { message })
      commit("UPDATE_STATUS", { status: null })
    },
    async signUserUp({ commit, dispatch }, payload) {
      try {
        const {
          firstname,
          lastname,
          username,
          image,
          email,
          password,
          gameID,
          clientID,
          audit,
          vip,
          trusted,
          orgID,
          identifier
        } = payload

        if (!trusted) {
          commit("UPDATE_ERROR", { message: null })
          commit("UPDATE_STATUS", { status: "loading" })
        }

        if (!clientID && !trusted)
          throw new Error("Cannot create a user without a client ID")

        let user

        if (trusted) {
          const { user: firebaseUser } = await signUpSomeoneElse({
            email,
            password
          })
          user = firebaseUser
        } else {
          const { user: firebaseUser } = await signUpWithEmailAndPassword({
            email,
            password
          })
          user = firebaseUser
        }

        // We don't need to wait a response of it
        if (!trusted) sendEmailVerification(window.location.href)

        const { uid: userID } = user
        const emailVerified = trusted ? true : !!user.emailVerified

        if (!userID) throw new Error("Cannot create a user without an ID")

        await dispatch("createUser", {
          firstname,
          lastname,
          userID,
          image,
          email,
          gameID,
          clientID,
          username,
          verificationRequired: !emailVerified,
          orgID: orgID || null
        })

        if (!trusted) {
          await dispatch(ActionTypes.INITIALIZE_USER, {
            clientID,
            gameID,
            userID,
            audit,
            vip,
            identifier
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        }

        return userID
      } catch (e) {
        console.error(e)
        const status =
          e instanceof EmailVerificationError ? "email_not_verified" : "error"
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status })
      }
    },
    async resendEmailVerification({ commit }) {
      sendEmailVerification(window.location.href)
      commit("UPDATE_ERROR", {
        message: "Verification link was sent to your email"
      })
      commit("UPDATE_STATUS", { status: "error" })
    },
    async forgotPass({ commit }, { email }) {
      commit("UPDATE_ERROR", { message: null })
      try {
        await sendResetPassword({ email, url: window.location.href })
        commit("UPDATE_STATUS", { status: "complete" })
      } catch (e) {
        console.error(e)
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
      }
    },
    async createUser(_, payload) {
      const {
        email,
        username,
        image,
        firstname,
        lastname,
        customInputType,
        customInput,
        gameID,
        clientID,
        userID,
        verificationRequired,
        orgID: argOrgID,
        conference
      } = payload

      const orgID = argOrgID || (await fetchClient(clientID))?.orgID || null

      const user = {
        username,
        image,
        firstname,
        lastname,
        customInputType,
        customInput,
        conference,
        gameID,
        clientID,
        verificationRequired
      }

      const newUser = getUserObject({
        user: { ...user, gameID, orgID, id: userID }
      })

      const promises = [db.ref(`org/1/users/${userID}`).update(newUser)]

      if (email) {
        const promise = db.ref(`users/private/${userID}`).update({ email })
        promises.push(promise)
      }

      if (email) {
        HubSpot.identify({ ...newUser, email: normalizeEmail(email, clientID) })
      } else if (
        customInputType === "text" &&
        customInput &&
        HubSpot.EMAIL_PATTERN.test(String(customInput))
      ) {
        HubSpot.identify({ ...newUser, email: customInput })
      }

      return Promise.all(promises)
    },
    async [ActionTypes.INITIALIZE_USER](
      { commit, dispatch, getters, rootGetters },
      payload
    ) {
      const {
        userID,
        audit,
        vip,
        clientID: urlClientID,
        gameID: urlGameID,
        emailVerified,
        identifier,
        anonymous
      } = payload

      if (!userID) throw new Error(`Undefined user ID`)

      // find user by ID in firebase
      const user = await fetchUser({ userID })
      // get out of here
      if (!user) throw new Error(`Cannot find user ${userID}`)

      if (user.verificationRequired && !emailVerified) {
        throw new EmailVerificationError("Email is not verified.")
      }
      // map
      const {
        clientID: currentClientID,
        gameID: currentGameID,
        orgID: currentOrgID
      } = user

      if (user.role === Role.Host && !urlClientID) {
        window.location.href = "/content"
        return
      }

      if (!currentClientID && !urlClientID)
        throw new Error("You need a client ID to login")

      let session

      if (user.role !== Role.Host) {
        const clientID = urlClientID || currentClientID
        if (clientID) {
          commit("UPDATE_CLIENT_ID", { clientID })
          const client = await fetchClient(clientID)
          session = client
          if (client) {
            if (client.redirectURL) {
              if (user.clientID !== clientID) {
                const snapshot = await db
                  .auxiliary()
                  .ref(`org/1/users`)
                  .orderByChild("clientID")
                  .equalTo(clientID)
                  .once("value")
                const value = snapshot.val() || {}
                const now = Date.now()
                const nOfUsersOnline = Object.values(value).reduce(
                  (acc, val) => {
                    if (User.isAlive(val, now)) return acc + 1
                    return acc
                  },
                  0
                )
                // Initialize game from redirect url
                if (nOfUsersOnline >= client.maxCapacity) {
                  // Get only id part from the url
                  const urlPartGameID = client.redirectURL
                    .replace(/^[:/\w]*\//, "")
                    .replace(/\?[\w=\d]+$/, "")
                  // TODO(Andrew): reliable approach to detect Firebase key
                  if (FirebaseHelper.isFirebaseKey(urlPartGameID)) {
                    return await dispatch(ActionTypes.INITIALIZE_USER, {
                      userID,
                      audit,
                      vip,
                      emailVerified,
                      identifier,
                      anonymous,
                      ...Navigation.parseUrlID(urlPartGameID)
                    })
                  } else if (isUrl(client.redirectURL)) {
                    window.location = client.redirectURL
                  } else {
                    throw new Error("Invalid redirect URL")
                  }
                }
              }
            }
            // Check Capacity
            await dispatch(ActionTypes.CHECK_CLIENT_CAPACITY, {
              orgID: currentOrgID,
              client,
              clientID,
              gameID: currentGameID
            })
            const email =
              normalizeEmail(getCurrentUser().email, clientID) || null
            await dispatch(ActionTypes.ADD_LOGIN_TO_PLAY, {
              clientID,
              gameID: urlGameID || currentGameID || null,
              userID,
              firstName: user.firstname,
              lastName: user.lastname,
              role: user.role || null,
              teamID: user.teamID || null,
              email
            })

            // Show an error if the client doesn't contain any game
            const game = await fetchOneGame(client.orgID, clientID)
            if (!game) {
              throw new Error(
                "We are almost done putting the finishing touches " +
                  "on your game - it will be ready soon! " +
                  "In the meantime, we recommend you start your " +
                  "pre-game warm-up routine and begin composing a victory speech..."
              )
            }
          }
        }
      }

      let orgID = currentOrgID
      let clientID = currentClientID

      commit("UPDATE_CLIENT_ID", { clientID: urlClientID || currentClientID })

      if (
        session?.password &&
        session.password !== payload.password &&
        !User.isHost(user)
      )
        throw new Error("You are not authorized.")

      // null by default
      let gameID = null
      let matchingData = null
      let proposedGameID = null
      let isEmailMatchingCase = false
      let hasPreGame = false

      if (urlClientID) {
        const {
          orgID: clientOrgID,
          tournament,
          gameID: rootGameID
        } = await fetchClient(urlClientID)
        hasPreGame = !!tournament
        // fetch client games
        const games =
          (await fetchGamesByClientID(clientOrgID, urlClientID)) || {}
        if (rootGameID) games[rootGameID] = true

        // override defaults
        clientID = urlClientID
        orgID = clientOrgID

        if (tournament && !getters.isHost) {
          isEmailMatchingCase = true
        }

        if (urlGameID) {
          if (!games[urlGameID]) throw new Error("Incorrect game ID")
          proposedGameID = urlGameID
        } else if (tournament && games[currentGameID]) {
          proposedGameID = currentGameID
        } else if (!tournament || Object.keys(games).length === 1) {
          proposedGameID = Object.keys(games)[0]
        }
        // If email matching is not expected - assign game ID immediately
        if (!isEmailMatchingCase && proposedGameID) {
          gameID = proposedGameID
        }
      } else {
        const {
          orgID: clientOrgID,
          gameID: rootGameID,
          tournament
        } = await fetchClient(clientID)
        hasPreGame = !!tournament
        // fetch client games
        const games = (await fetchGamesByClientID(clientOrgID, clientID)) || {}

        if (rootGameID) games[rootGameID] = true

        orgID = clientOrgID
        // if user's gameID is not found in his current client
        // reset it to whatever we get from client
        if (currentGameID && !games[currentGameID]) {
          console.error(`Game ${currentGameID} not found in ${clientID}`)
          if (Object.keys(games).length === 1) {
            gameID = Object.keys(games)[0]
          }
        } else if (currentGameID) {
          gameID = currentGameID
        }
      }

      // get an object with some default values and team ID/role conditions
      const newUser = getUserObject({
        user: {
          ...user,
          id: userID,
          gameID,
          orgID,
          clientID,
          identifier,
          anonymous
        },
        audit,
        vip
      })

      // got a new role here
      const { role } = newUser

      if (gameID) {
        // yes or no
        const message = await canUserJoinGame({ role, userID, gameID, orgID })
        // get out of here
        if (message) throw new Error(message)
      }

      const updateToken = async () => {
        const token = await getToken()
        commit("UPDATE_USER_FIREBASE_TOKEN", token)
      }

      db.auxiliary()
        .ref(`org/1/users/${userID}`)
        .on("value", snapshot => commit("UPDATE_USER", snapshot.val()))

      commit("UPDATE_USER", newUser)

      // user is getting new client ID assigned here
      await Promise.all([updateToken(), updateUser({ userID, obj: newUser })])

      if (isEmailMatchingCase && !gameID) {
        // Check game played only if the current client the same as requested
        if (currentClientID === clientID) {
          await dispatch(
            "pregame/fetchUserPlayedGames",
            {
              clientID,
              userID
            },
            { root: true }
          )
        }
        const userGames = rootGetters["pregame/userPlayedGames"]

        matchingData = await getGameMatchingData({
          orgID,
          clientID,
          userPlayedGames: userGames
        })

        if (role === Role.Player) {
          const client = await fetchClient(clientID)
          if (matchingData) {
            gameID = matchingData.gameID
          } else if (client.csvEmailMatchRequired) {
            throw new Error(
              "We can not find that email on our list. Please try again, or contact your organizer"
            )
          }
        }
      }

      if (!gameID) {
        gameID = proposedGameID
        // TODO: do we need to call canUserJoinGame?
      }

      interval = setInterval(updateToken, REAUTH_INTERVAL)

      if (gameID) {
        try {
          // "force" means no need to check game capacity
          const teamID = matchingData?.teamID
          await dispatch("initializeToGame", {
            gameID,
            clientID,
            teamID,
            force: true
          })
        } catch (e) {
          if (hasPreGame) {
            console.warn(e)
            if (role === Role.Player && hasPreGame) {
              const clientOnlineUserRef = db
                .auxiliary()
                .ref(`client/${clientID}/usersPlayingGames/${userID}`)
              clientOnlineUserRef.onDisconnect().set(null)
              await clientOnlineUserRef.set(0)
            }
            await dispatch("setSession", { orgID, clientID })
          } else {
            throw e
          }
        }
      } else {
        if (role === Role.Player && hasPreGame) {
          const clientOnlineUserRef = db
            .auxiliary()
            .ref(`client/${clientID}/usersPlayingGames/${userID}`)
          clientOnlineUserRef.onDisconnect().set(null)
          await clientOnlineUserRef.set(0)
        }
        await dispatch("setSession", { orgID, clientID })
      }
    },

    async [ActionTypes.ADD_LOGIN_TO_PLAY](
      _,
      { clientID, userID, firstName, lastName, email, role, gameID, teamID }
    ) {
      const id = `${userID}${gameID}`
      // transaction to make sure we create the record only once
      await db
        .auxiliary(clientID)
        .ref(`/client/${clientID}/play/${id}`)
        .transaction(value => {
          if (value) return
          return new Answer({
            userID,
            clientID,
            gameID,
            teamID,
            firstName,
            lastName,
            email,
            role,
            behavior: MissionType.Login,
            id
          })
        })
    },
    deinitializeGame({ state, commit }) {
      const { user, clientID } = state
      const { id: userID } = user || {}

      commit("UPDATE_INITIALIZED_STATUS", { status: false })

      return Promise.all([
        db
          .auxiliary()
          .ref(`client/${clientID}/usersPlayingGames/${userID}`)
          .set(0),
        db.ref(`org/1/users/${userID}/gameID`).set(null)
      ])
    },
    async initializeToGame(
      { state, commit, dispatch, getters },
      { gameID, clientID, teamID, force }
    ) {
      if (!gameID) throw new Error(`Room ID error`)
      if (!clientID) throw new Error(`Session ID error`)
      const { user } = state
      const { id: userID, role, clientID: userClientID } = user
      await dispatch("subscribeToClient", { clientID })

      const { client, hasPreGame } = getters
      const { orgID } = client

      if (!orgID) throw new Error("Invalid data")

      // we don't want users to enter any deleted games and disabled (staged)
      // expo games

      const game = await fetchGame({ orgID, gameID })

      if (!game) {
        throw new Error(`Cannot find game ${gameID} in organization ${orgID}`)
      } else {
        const { deletedTimestamp, endTimestamp } = game
        if (role !== Role.Host) {
          if (deletedTimestamp) {
            throw new Error(`Game ${gameID} has been archived.`)
          } else if (hasPreGame && endTimestamp) {
            throw new Error(`Game ${gameID} initilization is forbidden.`)
          }
        } else {
          if (deletedTimestamp) {
            console.warn("Recovering and edned game...")
            await db
              .auxiliary()
              .ref(`org/${orgID}/games/${gameID}/deletedTimestamp`)
              .set(null)
          }
        }
      }

      if (!force) {
        const message = await canUserJoinGame({ role, userID, gameID, orgID })
        if (message) throw new Error(message)
      }

      if (role === Role.Player && hasPreGame) {
        const currentClientOnlineUserRef = db
          .auxiliary()
          .ref(`client/${userClientID}/usersPlayingGames/${userID}`)

        await currentClientOnlineUserRef.set(null)
        currentClientOnlineUserRef.onDisconnect().cancel()

        const newClientOnlineUserRef = db
          .auxiliary()
          .ref(`client/${clientID}/usersPlayingGames/${userID}`)

        newClientOnlineUserRef.onDisconnect().set(null)
        await newClientOnlineUserRef.set(gameID)
      }

      commit("UPDATE_INITIALIZED_STATUS", { status: false })
      commit("UPDATE_INITIALIZING_STATUS", { status: true })

      try {
        const obj = { gameID, orgID, clientID, noGameTimestamp: 0 }

        if (teamID) UserService.updateTeamId({ id: userID }, teamID)

        await updateUser({
          userID,
          obj
        })

        await dispatch("setSession", { orgID, clientID })
        await dispatch("subscribeToGame", { orgID, gameID })
      } catch (e) {
        commit("UPDATE_INITIALIZED_STATUS", { status: true })
        commit("UPDATE_INITIALIZING_STATUS", { status: false })
        throw e
      }

      commit("UPDATE_INITIALIZED_STATUS", { status: true })
      commit("UPDATE_INITIALIZING_STATUS", { status: false })
    },
    async setSession({ dispatch }, { orgID, clientID }) {
      await dispatch("subscribeToClient", { clientID })
      await dispatch("setOrgID", orgID, { root: true })
    },
    async subscribeToGame({ dispatch }, { orgID, gameID }) {
      await dispatch("GameUsers/subscribeToRoomUsers", gameID, { root: true })
      await Promise.all([
        dispatch("subscribeToTheGameID", { gameID, orgID }, { root: true }),
        dispatch("subscribeToChats", { gameID, orgID }, { root: true })
      ])
      await Promise.all([
        dispatch("subscribeToGameStatus", null, { root: true }),
        dispatch("subscribeToPlays", null, { root: true })
      ])
    },
    async signUserIn({ commit, dispatch }, payload) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      commit("UPDATE_USER_FIREBASE_TOKEN", null)
      commit("UPDATE_USER", {})
      try {
        const { email, password, gameID, clientID, audit, vip, identifier } =
          payload
        const { user } = await signInWithEmailAndPassword({ email, password })
        const { uid: userID, emailVerified } = user
        await dispatch(ActionTypes.INITIALIZE_USER, {
          clientID,
          gameID,
          userID,
          audit,
          vip,
          emailVerified,
          identifier
        })
        commit("UPDATE_STATUS", { status: "authorized" })
      } catch (e) {
        console.error(e)
        const status =
          e instanceof EmailVerificationError ? "email_not_verified" : "error"
        commit("UPDATE_STATUS", { status })
        commit("UPDATE_ERROR", e)
      }
    },
    async signGoogleUserIn({ commit, dispatch }, payload) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      try {
        const { gameID, clientID, audit, vip, identifier } = payload
        const { user } = await googleSingIn()
        const {
          displayName: username,
          uid: userID,
          photoURL: image,
          email
        } = user
        const [firstname, lastname] = username.split(" ")
        try {
          await dispatch(ActionTypes.INITIALIZE_USER, {
            gameID,
            clientID,
            userID,
            audit,
            vip,
            identifier
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        } catch (e) {
          console.log(e)
          console.log(`Cannot find Google user ${userID}. Creating a new one`)
          if (!clientID)
            throw new Error("Cannot create a user without a client ID")
          const user = {
            firstname,
            lastname,
            userID,
            image,
            email,
            gameID,
            clientID,
            username
          }
          await dispatch("createUser", user)
          await dispatch(ActionTypes.INITIALIZE_USER, {
            gameID,
            clientID,
            userID,
            audit,
            vip,
            identifier
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        }
      } catch (e) {
        console.error(e)
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
      }
    },
    async signUserInAnonymouslyWithPassword({ commit, dispatch }, payload) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      const {
        gameID,
        clientID,
        audit,
        vip,
        firstname,
        lastname,
        password,
        identifier
      } = payload
      try {
        if (!password) throw new Error("No password is given")
        const userID = await getUserIdByReauth()
        try {
          await dispatch(ActionTypes.INITIALIZE_USER, {
            clientID,
            gameID,
            userID,
            audit,
            vip,
            identifier,
            anonymous: true,
            password
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        } catch (e) {
          console.log(e)
          console.log(`Cannot find Google user ${userID}. Creating a new one`)
          if (!clientID)
            throw new Error("Cannot create a user without a client ID")
          const user = {
            firstname,
            lastname,
            userID,
            image: "",
            gameID,
            clientID,
            username: `${firstname} ${lastname}`
          }
          await dispatch("createUser", user)
          await dispatch(ActionTypes.INITIALIZE_USER, {
            gameID,
            clientID,
            userID,
            audit,
            vip,
            identifier,
            anonymous: true
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        }
      } catch (e) {
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
      }
    },
    async signUserInAnonymously({ commit, dispatch }, payload) {
      commit("UPDATE_ERROR", { message: null })
      commit("UPDATE_STATUS", { status: "loading" })
      const {
        gameID,
        clientID,
        audit,
        vip,
        firstname,
        lastname,
        customInputType,
        customInput,
        image,
        identifier,
        conference
      } = payload
      try {
        const userID = await getUserIdByReauth()
        try {
          await dispatch(ActionTypes.INITIALIZE_USER, {
            clientID,
            gameID,
            userID,
            audit,
            vip,
            image,
            identifier,
            anonymous: true
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        } catch (e) {
          console.log(e)
          console.log(`Cannot find Google user ${userID}. Creating a new one`)
          if (!clientID)
            throw new Error("Cannot create a user without a client ID")
          const user = {
            firstname,
            lastname,
            customInputType,
            customInput,
            identifier,
            userID,
            image,
            gameID,
            clientID,
            username: `${firstname} ${lastname}`,
            conference
          }

          await dispatch("createUser", user)

          await dispatch(ActionTypes.INITIALIZE_USER, {
            gameID,
            clientID,
            userID,
            audit,
            vip,
            identifier,
            anonymous: true
          })

          commit("UPDATE_STATUS", { status: "authorized" })
        }
      } catch (e) {
        commit("UPDATE_ERROR", e)
        commit("UPDATE_STATUS", { status: "error" })
      }
    },
    /**
     * Sign In users with email first name and last name
     * These users are stored in format {ClientID}:{email},
     * and it works only for specific ClientID
     *
     * @param commit
     * @param dispatch
     * @param payload
     * @return {Promise<void>}
     */
    async signUserInWithEmail({ commit, dispatch }, payload) {
      try {
        commit("UPDATE_ERROR", { message: null })
        commit("UPDATE_STATUS", { status: "loading" })
        commit("UPDATE_USER_FIREBASE_TOKEN", null)
        commit("UPDATE_USER", {})

        const {
          firstname,
          lastname,
          email,
          identifier,
          clientID,
          gameID,
          orgID,
          audit,
          vip
        } = payload

        if (!firstname || !lastname)
          throw "First Name and Last Name fields are required"

        const { uid: userID } = await maybeSingUp(email, email)
        console.log("MAYBE USER", userID)

        const user = await fetchUser({ userID })

        const isNewUser = !user || !user.orgID || !user.clientID
        if (isNewUser) {
          // will update
          await dispatch("createUser", {
            firstname,
            lastname,
            userID,
            email,
            gameID,
            clientID,
            verificationRequired: false,
            orgID
          })
        }

        await dispatch(ActionTypes.INITIALIZE_USER, {
          clientID,
          gameID,
          userID,
          identifier,
          audit,
          vip,
          emailVerified: true // Don't check verification
        })
        commit("UPDATE_STATUS", { status: "authorized" })
      } catch (e) {
        commit("UPDATE_STATUS", { status: "error" })
        commit("UPDATE_ERROR", e)
      }
    },
    async signUserOut({ commit, state, getters }) {
      commit("UPDATE_STATUS", { status: "loading" })
      const { clientID, user } = state
      const { hasPreGame } = getters
      const { id: userID, role } = user

      if (role === Role.Player && hasPreGame) {
        const ref = db
          .auxiliary()
          .ref(`client/${clientID}/usersPlayingGames/${userID}`)
        await ref.set(null)
        ref.onDisconnect().cancel()
      }

      clearInterval(interval)

      commit("UPDATE_INITIALIZED_STATUS", { status: false })
      commit("UPDATE_STATUS", { status: null })
      commit("UPDATE_USER_FIREBASE_TOKEN", null)
      commit("UPDATE_USER", {})
    },
    async signInWithCustomToken({ commit, dispatch }, payload) {
      await AuthService.signInWithCustomToken(payload)
      const user = await getCurrentUser()
      await dispatch(ActionTypes.INITIALIZE_USER, { userID: user.uid })
      commit("UPDATE_STATUS", { status: "authorized" })
    },
    async reauthenticate({ commit, dispatch }, payload = {}) {
      commit("UPDATE_STATUS", { status: "loading" })
      try {
        const data = await reauth()
        const { uid: userID, anonymous } = data
        if (!userID) throw new Error("Cannot re authorize without a user ID")
        try {
          await dispatch(ActionTypes.INITIALIZE_USER, {
            userID,
            anonymous,
            ...payload
          })
          commit("UPDATE_STATUS", { status: "authorized" })
        } catch (e) {
          const status =
            e instanceof EmailVerificationError ? "email_not_verified" : "error"
          commit("UPDATE_STATUS", { status })
          console.error(e)
        }
      } catch (e) {
        commit("UPDATE_STATUS", { status: null })
        console.warn(e)
      }
    },
    /**
     * @param {} _
     * @param { { clientID: string } } payload
     * @throws
     * @returns {Promise<boolean>}
     */
    async [ActionTypes.CHECK_CLIENT_CAPACITY](
      _,
      { orgID, client, clientID, gameID }
    ) {
      if (client !== null) {
        const count = await getOnlineUsersCountByClientID(clientID)
        const maxCapacity = parseInt(client.maxCapacity) || 999999

        if (count >= maxCapacity) {
          // Maybe<Game>
          let game
          if (gameID) {
            game = await this.$services
              .get("game")
              .then(service => service.getGameByID(orgID, gameID))
          }

          throw new Error(
            game?.gameFullText || "The game is full. Try again later"
          )
        } else {
          return true
        }
      } else {
        return false
      }
    },
    updateFirebaseToken({ commit }, token) {
      commit("UPDATE_USER_FIREBASE_TOKEN", token)
    }
  },
  getters: {
    isAnonAuthorization(state) {
      return !state.isAnonAuthorization || state.isAnonAuthorization === "true"
    },
    camera(state) {
      return state.camera == null ? true : Boolean(state.camera)
    },
    isUserOnboarded(state) {
      return state.onboarding === "true"
    },
    isSuper(state, { isHost }) {
      return state.user && isHost ? !!state.user.super : false
    },
    isModerator: (state, _, __, { moderatorID }) =>
      state.user && state.user.id === moderatorID,
    isHost(state, { role, isModerator }, { route }) {
      return (
        role === Role.Host ||
        (isModerator && MODERATED_ROUTES.includes(route?.name))
      )
    },
    isPlayer(state, { role }, { livechat: { roomID } }) {
      return roomID && role === Role.Spectator ? true : role === Role.Player
    },
    isAudit(state, { role }) {
      return role === Role.Audit
    },
    isSpectator(state, { role }, { livechat: { roomID } }) {
      return roomID ? false : role === Role.Spectator
    },
    client: state => state.client,
    restriction(state, { client }) {
      return client && client.restriction ? client.restriction : 0
    },
    accessOverride(state, getters, rootState, { game }) {
      return !!game && !!(game.deactivate || game.ondeck)
    },
    access(
      state,
      { isHost, isAudit, restriction, accessOverride },
      rootState,
      rootGetters
    ) {
      const code = parseInt(restriction)
      const userGames = rootGetters["pregame/userPlayedGames"]
      // n of games played
      const n = Object.keys(userGames || {}).length
      // set FREE ROAM if a host or their assigned game is ended, deleted
      // or disabled
      if (isHost || isAudit || accessOverride) {
        return 0
      } else if (code === 3) {
        // enabled TRAINING CAMP mode to enforce the first game as PLEDGED
        // and let the following games to be played as STICKY
        return n > 0 ? 1 : 2
      } else if (code === 4) {
        return n > 0 ? 0 : 2
      } else {
        return restriction
      }
    },
    volume(_, getters, rootState, rootGetters) {
      if (rootState.route?.name === Page.LOGIN) return 1

      const viewer = getters.user

      if (getters.isHostIRL && User.isPresenter(viewer))
        return rootGetters.gameHost.volume

      // if all users are IRL then no sfx
      if (getters.isIRLSession && !User.isPresenter(viewer)) return 0

      // if IRL mobile user or IRL host then no sfx
      if (
        getters.isHybridRoom &&
        (User.isMobile(viewer) || isIRLHost(viewer, rootGetters.game))
      )
        return 0

      // if IRL presenter and there is an IRL speaker, then no sfx
      const DEFAULT_LOCATION = "default"
      const viewerLocation = viewer?.identifier ?? DEFAULT_LOCATION
      const isUserInViewerLocation = user => {
        return (user?.identifier ?? DEFAULT_LOCATION) === viewerLocation
      }

      const isUserViewer = user => user.id === viewer.id

      let predicate

      if (rootGetters["group/hasHybridLocations"])
        predicate = user => !isUserViewer(user) && isUserInViewerLocation(user)
      else predicate = user => !isUserViewer(user)

      if (
        getters.isHybridRoom &&
        User.isPresenter(viewer) &&
        rootState.IRLSpeakers.some(predicate)
      )
        return 0

      return isNaN(viewer?.volume) ? 0.5 : Math.min(1, viewer.volume)
    },
    status(state) {
      return state.status
    },
    error(state) {
      return state.error
    },
    clientID(state) {
      return state.clientID
    },
    user(state) {
      return state.user
    },
    hasPreGame(_, { client }) {
      return Boolean(client?.tournament)
    },
    initialized(state, { authorized }) {
      return authorized && state.initialized
    },
    initializing(state) {
      return state.initializing
    },
    authorized(state) {
      return state.token && state.user && state.status === "authorized"
    },
    token(state) {
      return state.token
    },
    role(state) {
      return state.user ? state.user.role : null
    },
    viewerHasHeadphones(state) {
      return state.user?.headphones
    },
    isIRLSession(_, getters) {
      return getters.isHostIRL && getters.hasIRLUsers
    },
    hasIRLUsers(_, getters, __, rootGetters) {
      return getters.isHybridRoom && Boolean(rootGetters.game?.allUsersIRL)
    },
    isHostIRL(_, getters, __, rootGetters) {
      return getters.isHybridRoom && Boolean(rootGetters.game?.hostIRL)
    },
    isHybridRoom(state, __, ___, rootGetters) {
      return Boolean(
        state.client?.mobileHybrid || rootGetters.game?.mobileHybrid
      )
    },
    [GetterTypes.IS_ANON]({ user }) {
      return User.isAnon(user)
    },
    [GetterTypes.IS_SHARD_SESSSION]({ clientID }) {
      return Boolean(Session.getKey(clientID))
    }
  }
}

export default AuthModule
