import { store } from "@/store"

export default class Slide {
  static async updatePage(gameID: string, slideIndex: number): Promise<void> {
    await store.dispatch("updateGameStatusAny", {
      gameID: gameID,
      slidesIndex: slideIndex
    })
  }

  static hasPdf(mission): Boolean {
    return Boolean(document && /\.pdf$/.test(mission?.documentUrl))
  }
}
