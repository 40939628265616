import Loopback from "./Loopback"
import Vue from "vue"
import { store } from "@/store"

const loopback = new Loopback()

export const state = Vue.observable({ ready: false })

let context
let destination
let initilaized = false

// we need to listen for a user triggered even to unlock autoplay
export function init() {
  if (initilaized) return
  initilaized = true
  // AudioContext must be initialized after user action
  context = new AudioContext()
  destination = context.createMediaStreamDestination()

  loopback.init(destination.stream).then(stream => {
    const el = document.createElement("audio")

    // watch user volume prop in DB and reactively update the global audio tag
    store.watch(
      () => store.getters["auth/volume"],
      value => {
        const number = isNaN(value) || value < 0 ? 1 : value
        const volume = Math.round(number * 100) / 100
        el.volume = volume
      },
      { immediate: true }
    )

    if (el.setSinkId)
      store.watch(
        () => store.state.twilio.speaker,
        value => {
          if (!value) return null
          el.setSinkId(value)
        },
        { immediate: true }
      )

    el.crossOrigin = "anonymous"
    document.body.appendChild(el)
    el.srcObject = stream
    el.play()

    state.ready = true
  })
}

export const stream = (el, volume = 1, autoplay = true) => {
  if (!context) return 0
  if (!destination) return 0
  const source = context.createMediaElementSource(el)
  el.volume = volume
  if (autoplay) el.play()
  source.connect(destination)
  // do we have to destroy anything here?
}
