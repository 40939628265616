var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "popup-overlay",
      style: { visibility: _vm.isOpen ? "visible" : "hidden" },
      attrs: { "d-flex": "", "justify-center": "", "align-center": "" },
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.$emit("onClickOutside")
        },
      },
    },
    [
      _c("transition", { attrs: { name: "popup" } }, [
        _vm.isOpen
          ? _c(
              "div",
              {
                staticClass: "popup-content",
                class:
                  ((_obj = {}),
                  (_obj["popup-content--" + _vm.size] = _vm.size),
                  _obj),
              },
              [
                _vm.closable
                  ? _c(
                      "div",
                      {
                        staticClass: "close-symbol",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$emit("onClose")
                          },
                        },
                      },
                      [
                        _c("SvgIcon", {
                          attrs: {
                            name: "times-regular",
                            width: "18",
                            height: "18",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("h3", { staticClass: "popup-header" }, [
                  _vm._v(
                    " Enter your information below to get a free month with 1 Open Weve and 5 Auto Weve experiences. "
                  ),
                ]),
                _c("strong", [_vm._v("Open Weve")]),
                _vm._v(
                  " is a room like this where you load in the mini games and is always open.Great for weekly allhands or rituals. "
                ),
                _c("br"),
                _c("br"),
                _c("strong", [_vm._v("Auto Weves")]),
                _vm._v(
                  " are 30 minute games from our library that can be run one time. "
                ),
                _c("br"),
                _c("br"),
                _c(
                  "div",
                  {
                    staticClass: "popup-text",
                    staticStyle: { height: "400px" },
                  },
                  [_c("div", { attrs: { id: "hubspotForm" } })]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }