module.exports = [
  "extension",
  "favorite",
  "brightness_2",
  "tag_faces",
  "eco",
  "face",
  "brightness_1",
  "flash_on",
  "filter_vintage",
  "fitness_center",
  "school"
]
