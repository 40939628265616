var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      staticClass: "popup-overlay",
      style: { visibility: _vm.isOpen ? "visible" : "hidden" },
      attrs: { "d-flex": "", "justify-center": "", "align-center": "" },
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.$emit("onClickOutside")
        },
      },
    },
    [
      _c("transition", { attrs: { name: "popup" } }, [
        _vm.isOpen
          ? _c(
              "div",
              {
                staticClass: "popup-content",
                class:
                  ((_obj = {}),
                  (_obj["popup-content--" + _vm.size] = _vm.size),
                  _obj),
              },
              [
                _vm.closable
                  ? _c(
                      "div",
                      {
                        staticClass: "close-symbol",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$emit("onClose")
                          },
                        },
                      },
                      [
                        _c("SvgIcon", {
                          attrs: {
                            name: "times-regular",
                            width: "18",
                            height: "18",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                !!_vm.header
                  ? _c("h3", { staticClass: "popup-header" }, [
                      _vm._v(" " + _vm._s(_vm.header) + " "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "popup-text" },
                  [_vm._t("default")],
                  2
                ),
                _vm.mode
                  ? _c(
                      "div",
                      { staticClass: "popup__controls" },
                      [
                        _vm.mode === "yes-no"
                          ? [
                              _c(
                                "rtb-button-next",
                                {
                                  staticClass: "mx-4",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onResponse({ status: "yes" })
                                    },
                                  },
                                },
                                [_vm._v(" YES ")]
                              ),
                              _c(
                                "rtb-button-next",
                                {
                                  staticClass: "mx-4",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onResponse({ status: "no" })
                                    },
                                  },
                                },
                                [_vm._v(" NO ")]
                              ),
                            ]
                          : _vm.mode === "okay"
                          ? _c(
                              "rtb-button-next",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.onResponse({ status: "okay" })
                                  },
                                },
                              },
                              [_vm._v(" OK ")]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }