<template>
  <TwilioRoom
    v-if="!error && roomId && userId && constraints"
    :key="`${roomId}-${userId}-${constraints.audio}-${constraints.video}-${constraints.type}-${hasVideoTrackOverride}`"
    :roomId="roomId"
    :userId="userId"
    :audio="constraints.audio"
    :video="constraints.video"
    :videoTrack="videoTrackOverride"
    :constraints="constraints"
    @onUsers="onUsers"
    @onDominantSpeakerIdChanged="onDominantSpeakerId"
    @onError="onError"
  />
</template>

<script>
import TwilioRoom from "./TwilioRoom.vue"
import { UnknownError } from "@/services/twilio.service"
import User from "@shared/User"
import {
  VideoConstraintsType,
  VideoConstraints,
  TOKEN_STORAGE_KEY_PREFIX
} from "@/store/TwilioModule"
import Page from "@/router/Page"

const ConnectionType = {
  LIVECHAT: "livechat",
  ROOM: "room"
}

const MAX_N_OF_ERRORS = 16

export default {
  name: "TwilioManger",
  components: {
    TwilioRoom
  },
  data() {
    return {
      error: null,
      count: 0,
      timeout: null
    }
  },
  watch: {
    async error(e) {
      if (!e) return

      console.error(e)

      clearTimeout(this.timeout)

      if (e instanceof UnknownError && this.$route?.name === Page.LOBBY) {
        await this.$store.dispatch("livechat/end")
        this.error = null
        return
      }

      this.count = this.count + 1

      // if too many errors
      if (this.count > MAX_N_OF_ERRORS) {
        // remove all tokens
        Object.keys(localStorage)
          .filter(key => String(key).includes(TOKEN_STORAGE_KEY_PREFIX))
          .forEach(key => localStorage.removeItem(key))
        // reset onboarding
        this.$store.commit("auth/UPDATE_LAST_KNOWN_ONBOARDING", false)
        // message
        await this.$info(e.message)

        let path

        const gameId = this.$store.getters.gameID
        const sessionId = this.$store.getters["auth/clientID"]

        if (gameId && sessionId) {
          path = `/login/${sessionId}${gameId}?auth=0`
        } else if (sessionId) {
          path = `/login/${sessionId}?auth=0`
        } else {
          path = `/login?auth=0`
        }

        // redirect to login
        this.$router.replace(path)
        this.$router.go()

        return
      }

      // if a duplicate identity error, show the prompt and hold it there
      if (e.message.toLowerCase().includes("duplicate")) {
        await this.$info(e.message)
      } else {
        this.$toast(e.message)
      }

      this.$toast("Trying to reconnect...")

      this.timeout = setTimeout(() => {
        this.error = null
      }, 5000)
    }
  },
  methods: {
    onUsers(users) {
      this.$store.commit("twilio/UPDATE_USERS", users)
    },
    onDominantSpeakerId(id) {
      console.log("onDominantSpeakerId === manager")
      this.$store.commit("twilio/UPDATE_DOMINANT_SPEAKER_ID", id)
    },
    onError(e) {
      this.error = e
    }
  },
  computed: {
    room() {
      return this.$store.getters.game
    },
    isViewerHostLike() {
      return this.$store.getters["group/isViewerHostLike"]
    },
    isVideoDisabledThroughUrl() {
      return this.$store.getters["auth/camera"] === false
    },
    isVideoDisabledInRoomSettings() {
      return this.room?.videoDisabled === true
    },
    videoTrackOverride() {
      return this.$store.state.twilio.videoTrackOverride
    },
    hasVideoTrackOverride() {
      return Boolean(this.videoTrackOverride)
    },
    type() {
      return this.connection?.type
    },
    roomId() {
      return this.connection?.roomId
    },
    connection() {
      if (this.$store.getters["auth/isIRLSession"]) return null

      const route = this.$route?.name
      const roomId = this.$store.getters.gameID

      if ((route === Page.ROOM || route === Page.SELECT_TEAM) && roomId)
        return { roomId, type: ConnectionType.ROOM }

      const chatRoomId = this.$store.state.livechat.roomID

      if (route === Page.LOBBY && chatRoomId)
        return {
          roomId: chatRoomId,
          type: ConnectionType.LIVECHAT
        }

      return null
    },
    // TODO - make sure constraints have deviceId
    constraints() {
      if (this.isHighlightedUser && this.type === ConnectionType.ROOM)
        return {
          ...VideoConstraints[VideoConstraintsType.CONFERENCE],
          type: VideoConstraintsType.CONFERENCE,
          video:
            !this.isVideoDisabledInRoomSettings &&
            !this.isVideoDisabledThroughUrl &&
            User.isJoinable(this.user),
          audio: User.isJoinable(this.user)
        }

      if (this.type === ConnectionType.ROOM)
        return {
          ...VideoConstraints[VideoConstraintsType.USER],
          type: VideoConstraintsType.USER,
          video:
            (!this.isVideoDisabledInRoomSettings || User.isHost(this.user)) &&
            !this.isVideoDisabledThroughUrl &&
            User.isJoinable(this.user),
          audio: User.isJoinable(this.user)
        }

      if (this.type === ConnectionType.LIVECHAT)
        return {
          ...VideoConstraints[VideoConstraintsType.LIVECHAT],
          type: VideoConstraintsType.LIVECHAT,
          video: !this.isVideoDisabledThroughUrl,
          audio: true
        }

      return null
    },
    isHighlightedUser() {
      return User.isConference(this.user)
    },
    user() {
      return this.$store.getters["auth/user"]
    },
    userId() {
      return this.user?.id
    }
  }
}
</script>
