<template>
  <div
    :class="className"
    @dblclick="select"
    @click.exact="toggleAudio"
    @click.alt="toggleModerator"
  >
    <div class="host-media__inner">
      <VideoComponent
        v-if="overrrideSource && !localOverrrideSource"
        autoplay
        loop
        :key="`${overrrideSource.mission}-${overrrideSource.mode}-hostless-video-transition`"
        :src="require('@/assets/video-transition-1.mp4')"
        class="host-media__media-placeholder host-media__media"
      />
      <SmartMedia
        v-else-if="!!localOverrrideSource"
        :key="`${localOverrrideSource.mission}-${localOverrrideSource.mode}-hostless-video`"
        :src="localOverrrideSource.url"
        :class="{ rounded, ['--mirrored']: localOverrrideSource.mirrored }"
        :paused="false"
        class="host-media__media-placeholder host-media__media"
        ref="video-1"
        autoplay
        @ended="onHostlessSourceEnded"
      />
      <UserMedia
        v-else-if="user && user.id"
        :key="`${user.id}-user-media`"
        :identity="user.id"
        :imageUrl="user.image"
        :userRole="user.role"
        :disable="hostMediaDisabled"
        class="host-media__media"
      />
      <ChimeStream
        v-else-if="viewerId !== userId && playbackUrl != null"
        :playbackUrl="playbackUrl"
      />
      <div class="host-media__overlay-text" v-if="overlayText">
        {{ overlayText }}
      </div>
      <div class="host-media__text" v-else-if="text">
        {{ text }}
      </div>

      <v-switch
        v-if="isStreamingAvailable"
        color="#8680FF"
        v-model="streaming"
        class="shrink"
        @click.native.stop
        :disabled="working"
        style="
          position: absolute;
          top: 0px;
          margin: 0px;
          left: 50%;
          transform: translateX(-50%);
        "
      />
    </div>
    <IconBase
      v-if="user && user.muted"
      iconName="muted"
      size="14"
      iconColor="rgb(147, 145, 145)"
      class="host-media__mute"
    >
      <IconMute />
    </IconBase>
  </div>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import { computed, ref, onMounted, watch } from "@vue/composition-api"

import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"
import SmartMedia from "@/modules/smart-media/components/SmartMedia"
import IconBase from "@/components/Icon/IconBase"
import IconMute from "@/components/Icon/IconMute"
import Mode from "@shared/enums/Mode"
import UserSoundService from "@/services/user-sound.service"
import User from "@shared/User"
import { isIRLUser } from "@shared/helpers/isIRL"
import useChimeRoomStream from "@/Chime/use/useChimeRoomStream"
import useStore from "@/use/useStore"

export default Vue.extend({
  name: "HostMedia",
  components: {
    SmartMedia,
    UserMedia,
    IconBase,
    IconMute,
    ChimeStream: () => import("@/Chime/ChimeStream.vue"),
    VideoComponent: () =>
      import("@/components/ui/VideoComponent/VideoComponent.vue")
  },
  setup() {
    const { store } = useStore()
    const {
      working,
      available: isStreamingAvailable,
      streaming,
      playbackUrl
    } = useChimeRoomStream(store.getters.game?.roomGroupId)

    const viewer = computed(() => store.state.auth.user)
    const viewerId = computed(() => viewer.value?.id)
    const user = computed(() => store.getters.gameHost)
    const userId = computed(() => user.value?.id)

    return {
      working,
      playbackUrl,
      viewerId,
      user,
      userId,
      streaming,
      isStreamingAvailable
    }
  },
  data() {
    return {
      localOverrrideSource: null,
      timer: null
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  props: {
    rounded: {
      type: Boolean,
      default: false
    },
    hideBadge: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: null
    },
    overrrideSource: {
      type: Object,
      default: null
    }
  },
  methods: {
    ...mapActions(["updateUser", "setGameModeratorID"]),
    ...mapActions("Games", ["updateGameAny"]),
    onHostlessSourceEnded() {
      clearTimeout(this.timer)
      this.localOverrrideSource = null
      this.timer = setTimeout(
        () => this.$emit("ended", this.overrrideSource),
        1000
      )
    },
    toggleModerator() {
      if (!this.isViewerHostLike) return
      this.setGameModeratorID(null)
    },
    select() {
      if (!this.isViewerHostLike) return
      this.updateUser({
        userID: this.user.id,
        obj: { selected: !this.user.selected }
      })
    },
    toggleAudio() {
      if (!this.isViewerHostLike) return
      UserSoundService.toggle(this.user)
    }
  },
  watch: {
    overrrideSource: {
      handler(value) {
        if (!value) return 0
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.localOverrrideSource = value
        }, 1000)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("auth", ["client"]),
    ...mapGetters({ teams: "chats", game: "game" }),
    streamId() {
      return this.game?.roomGroupId
    },
    overlayText() {
      if (this.overrrideSource) return null
      if (!this.user) return null
      if (!this.isAutoMuted) return null
      if (!this.globalTeam?.name) return null

      return `${User.getShortenedName(this.user)} is talking to ${
        this.globalTeam.name
      }`
    },
    text() {
      if (this.overrrideSource) return null
      if (this.streaming) return null
      if (!this.user) return "Offline"

      return User.getShortenedName(this.user)
    },
    isViewerHostLike() {
      return this.$store.getters["group/isViewerHostLike"]
    },
    isThemed() {
      return !!(this.client?.themeID || this.game?.themeID)
    },
    isHostMuted() {
      if (isIRLUser(this.user, this.game)) {
        const user = this.$store.getters.onlineUsersArray.find(User.isPresenter)
        if (user == null) return true
        return this.$store.getters.isUserMuted(user)
      }

      return this.$store.getters.isUserMuted(this.user)
    },
    isAutoMuted() {
      return (
        !this.isViewerHostLike &&
        this.$store.getters["group/isUserMutedByRoomLogic"](this.user) &&
        !this.user?.muted
      )
    },
    globalTeam() {
      if (!this.teams) return null
      if (!this.$store.getters["group/globalTeamID"]) return null
      return this.teams[this.$store.getters["group/globalTeamID"]]
    },
    className() {
      return [
        "host-media",
        {
          "host-media--rounded": this.rounded,
          "host-media--muted": this.isHostMuted,
          "host-media--themed": this.isThemed
        }
      ]
    },
    hostMediaDisabled() {
      return this.user?.selected && this.rounded && this.mode === Mode.Social
    }
  }
})
</script>

<style lang="scss">
.host-media {
  $block: &;
  height: 100%;
  border: 4px solid $primary_accent_color;
  box-sizing: border-box;
  border-radius: 16px;
  transition: border-radius 0.75s 0.25s;
  transform: translateZ(0);
  will-change: border-radius;

  &__temp-btn {
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    top: 0;
    opacity: 0.5;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }

  &--themed {
    background-color: $primary_accent_color;
    background-image: none;
  }

  &__inner {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    transition: border-radius 0.75s 0.25s;
    will-change: border-radius;
    background-color: #000;
    transform: translateZ(0);

    > video {
      border-radius: inherit;
      &.--mirrored {
        transform: scaleX(1) !important;
      }
    }

    .scribe-indication {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      pointer-events: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;
      pointer-events: auto;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0) 90%
      );
      text-shadow: 1px 1px 2px rgba($color-black, 0.7);
      transition: all 0.5s ease;
      border-radius: 50%;
      padding-top: 3px;
      padding-bottom: 3px;
      color: $color-white;
      white-space: nowrap;
      font-size: 20px;
    }
  }

  &__media-placeholder {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
    transition: border-radius 0.75s 0.25s;
    transform: translateZ(0);
    will-change: border-radius;
    &.flipped {
      transform: translateZ(0) scaleX(1);
    }
  }

  &__media {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    overflow: hidden;
    // GPU acceleration, please don't remove it
    transform: translateZ(0);
    transition: border-radius 0.75s 0.25s;
    will-change: border-radius;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__overlay-text {
    position: absolute;
    font-size: 14px;
    line-height: 1.286;
    width: 100%;
    height: 100%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    display: flex;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
    padding: 8px;
  }

  &__text {
    position: absolute;
    font-size: 14px;
    line-height: 1.286;
    color: #fff;
    bottom: 10px;
    width: 72%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  &__mute {
    @include absolute($top: 4px, $left: 4px);
    cursor: pointer;
    .v-btn--icon {
      padding: auto !important;
    }
  }

  &__headmax {
    $headmax: &;
    @include absolute($top: 8px, $right: 10px);
    font-size: 14px;
    color: $color-white !important;
    height: 12px !important;
    width: 12px !important;
    &.--active {
      @extend #{$headmax};
      color: $color_primary_accent !important;
    }
    &:hover {
      @extend #{$headmax};
      color: $color_primary_accent !important;
    }
    &.v-btn {
      height: 0px;
      margin: 0px;
    }
    &.v-btn--icon {
      width: inherit;
    }
  }

  &--rounded,
  &--rounded--bottom {
    border-radius: 50%;

    #{$block}__media,
    #{$block}__media-placeholder,
    #{$block}__inner {
      border-radius: 50%;
    }
  }

  &--muted {
    border-color: $color-tertiary-dark;
    background-color: $color-tertiary-dark;
  }
}
</style>
