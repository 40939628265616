/**
 * HostPosition
 * @readonly
 * @enum {string} HostPosition
 */
export const HostPosition = {
  Default: "default",
  Minimized: "minimized",
  Rouned: "rounded",
  RondedBottom: "rounded-bottom",
  RondedBottom2: "rounded-bottom-2"
}
