import _ from "lodash"
import { createLocalTracks } from "twilio-video"

import { fetchTwilioToken } from "@/services/api.service"
import Cookies from "js-cookie"

export const MutationTypes = {
  SET_CAMERA: "SET_CAMERA",
  SET_CAMERAS: "SET_CAMERAS",
  SET_MICROPHONE: "SET_MICROPHONE",
  SET_MICROPHONES: "SET_MICROPHONES",
  SET_SPEAKER: "SET_SPEAKER",
  SET_SPEAKERS: "SET_SPEAKERS"
}

export const ActionTypes = {
  SET_CAMERA: "SET_CAMERA",
  SET_MICROPHONE: "SET_MICROPHONE",
  SET_SPEAKER: "SET_SPEAKER",
  CREATE_LOCAL_VIDEO_TRACK: "CREATE_LOCAL_VIDEO_TRACK",
  CREATE_LOCAL_AUDIO_TRACK: "CREATE_LOCAL_AUDIO_TRACK",
  UPDATE_LOCAL_VIDEO_TRACK: "UPDATE_LOCAL_VIDEO_TRACK",
  UPDATE_LOCAL_AUDIO_TRACK: "UPDATE_LOCAL_AUDIO_TRACK",
  GET_TOKEN: "GET_TOKEN"
}

const DeviceKey = {
  CAMERA: "camera",
  MICROPHONE: "microphone",
  SPEAKER: "speaker"
}

export const TOKEN_STORAGE_KEY_PREFIX = "twilio-token"

// 24 hrs
const TOKEN_EXPIRATION_TIME = 1000 * 60 * 60 * 24
const TOKEN_EXPIRATION_OFFSET = parseInt(TOKEN_EXPIRATION_TIME / 20)

export const SCREEN_CHANNEL_IDENTIFIER = "screenshare-channel"

export const VideoConstraintsType = {
  USER: "user",
  CONFERENCE: "conference",
  LIVECHAT: "livechat",
  RECORDING: "recording"
}

export const VideoConstraints = {
  [VideoConstraintsType.USER]: {
    width: 256,
    height: 256
  },
  [VideoConstraintsType.CONFERENCE]: {
    width: 256,
    aspectRatio: 1.777777778
  },
  [VideoConstraintsType.RECORDING]: {
    width: 320,
    height: 320
  },
  [VideoConstraintsType.LIVECHAT]: {
    aspectRatio: 1.333
  }
}

function isDeviceValid(device) {
  return device.deviceId !== ""
}

const DeviceKind = {
  VideoInput: "videoinput",
  AudioInput: "audioinput",
  AudioOutput: "audiooutput"
}

const TwilioModule = {
  namespaced: true,
  state: {
    users: {},
    cameras: [],
    microphones: [],
    speakers: [],
    dominantSpeakerId: null,
    camera: Cookies.get(DeviceKey.CAMERA),
    microphone: Cookies.get(DeviceKey.MICROPHONE),
    speaker: Cookies.get(DeviceKey.SPEAKER),
    isPermissionGranted: false,
    videoTrackOverride: null
  },
  mutations: {
    UPDATE_VIDEO_TRACK_OVERRIDE(state, payload) {
      state.videoTrackOverride = payload
    },
    SET_IS_PERMISSION_GRANTED(state, payload) {
      state.isPermissionGranted = payload
    },
    UPDATE_USERS(state, payload) {
      if (payload == null) {
        state.users = {}
        return
      }

      state.users = Object.keys(payload).reduce((acc, val) => {
        if (payload[val].audio) {
          if (!acc[val]) acc[val] = {}
          acc[val].audioTrack = payload[val].audio
        }
        if (payload[val].video) {
          if (!acc[val]) acc[val] = {}
          acc[val].videoTrack = payload[val].video
        }
        return acc
      }, {})
    },
    UPDATE_DOMINANT_SPEAKER_ID(state, payload) {
      state.dominantSpeakerId = payload
    },
    [MutationTypes.SET_CAMERA](state, payload) {
      Cookies.set(DeviceKey.CAMERA, payload, { expires: 7 })
      state.camera = payload
    },
    [MutationTypes.SET_MICROPHONE](state, payload) {
      Cookies.set(DeviceKey.MICROPHONE, payload, { expires: 7 })
      state.microphone = payload
    },
    [MutationTypes.SET_SPEAKER](state, payload) {
      Cookies.set(DeviceKey.SPEAKER, payload, { expires: 7 })
      state.speaker = payload
    },
    [MutationTypes.SET_CAMERAS](state, payload) {
      state.cameras = payload
    },
    [MutationTypes.SET_MICROPHONES](state, payload) {
      state.microphones = payload
    },
    [MutationTypes.SET_SPEAKERS](state, payload) {
      state.speakers = payload
    }
  },
  actions: {
    async tryToEnumerateDevices({ state, commit, rootGetters }) {
      const user = rootGetters["auth/user"]
      const userId = user?.id

      const audioTrack = state.users[userId]?.audioTrack
      const videoTrack = state.users[userId]?.videoTrack

      const config = { audio: !audioTrack, video: !videoTrack }

      // without this navigator.mediaDevices.enumerateDevices() returns devices without identifiers
      // at the same time we don't want to take over the only available user media stream on a
      // mobile device
      if (config.video || config.audio) {
        const stream = await navigator.mediaDevices.getUserMedia(config)
        stream.getTracks().forEach(track => track.stop())
      }

      const devices = await navigator.mediaDevices.enumerateDevices()

      const filtered = devices.filter(isDeviceValid)

      const cameras = filtered
        .filter(device => device.kind === DeviceKind.VideoInput)
        .sort(camera => {
          const label = camera?.label.toLowerCase()
          return label.includes("virtual") ||
            // Infrared cameras
            label.includes(" ir ") ||
            label.includes("back")
            ? 1
            : -1
        })

      const microphones = filtered.filter(
        device => device.kind === DeviceKind.AudioInput
      )
      const speakers = filtered.filter(
        device => device.kind === DeviceKind.AudioOutput
      )

      const [c] = cameras
      const [m] = microphones
      const [s] = speakers

      commit(MutationTypes.SET_SPEAKERS, speakers)
      commit(MutationTypes.SET_CAMERAS, cameras)
      commit(MutationTypes.SET_MICROPHONES, microphones)

      const isDeviceIdAvailable = deviceId =>
        filtered.some(device => device.deviceId === deviceId)

      if (c && !isDeviceIdAvailable(state.camera))
        commit(MutationTypes.SET_CAMERA, c.deviceId)

      if (m && !isDeviceIdAvailable(state.microphone))
        commit(MutationTypes.SET_MICROPHONE, m.deviceId)

      if (s && !isDeviceIdAvailable(state.speaker))
        commit(MutationTypes.SET_SPEAKER, s.deviceId)
    },
    async [ActionTypes.GET_TOKEN]({ rootState }, identity) {
      const key = `${TOKEN_STORAGE_KEY_PREFIX}:${process.env.VUE_APP_MODE}:${identity}`
      const string = localStorage.getItem(key)
      const decoded = JSON.parse(atob(string || "") || "{}")
      const { refreshAt, data: cachedToken, org: cachedOrg } = decoded || {}
      const now = Date.now()
      const org = rootState.orgID

      if (
        refreshAt > now + TOKEN_EXPIRATION_OFFSET &&
        cachedToken &&
        cachedOrg === org
      )
        return cachedToken

      console.log("Opps. The Twilio token is expired.")

      const token = await fetchTwilioToken({ userID: identity })

      const obj = { refreshAt: now + TOKEN_EXPIRATION_TIME, data: token, org }
      const encoded = btoa(JSON.stringify(obj))
      localStorage.setItem(key, encoded)
      return token
    },
    [ActionTypes.SET_CAMERA]({ commit }, payload) {
      commit(MutationTypes.SET_CAMERA, payload)
    },
    [ActionTypes.SET_MICROPHONE]({ commit }, payload) {
      commit(MutationTypes.SET_MICROPHONE, payload)
    },
    [ActionTypes.SET_SPEAKER]({ commit }, payload) {
      commit(MutationTypes.SET_SPEAKER, payload)
    },
    // TODO - do a better job with conditional constraints
    async [ActionTypes.CREATE_LOCAL_VIDEO_TRACK](
      { state },
      payload = VideoConstraints[VideoConstraintsType.USER]
    ) {
      const constraints = {
        video: { ...payload }
      }

      if (state.camera !== null && constraints.video.deviceId === undefined) {
        constraints.video.deviceId = state.camera
      }

      const [track] = await createLocalTracks(constraints)

      return track
    },
    async [ActionTypes.CREATE_LOCAL_AUDIO_TRACK]({ state }) {
      const constraints = {}

      if (state.microphone !== null) {
        constraints.audio = {
          deviceId: state.microphone
        }
      } else {
        constraints.audio = true
      }

      const [track] = await createLocalTracks(constraints)

      return track
    },
    [ActionTypes.UPDATE_LOCAL_AUDIO_TRACK]({ state, rootGetters }) {
      const user = rootGetters["auth/user"]
      const userId = user?.id
      if (!userId) return
      const audioDeviceId = state.microphone
      if (!audioDeviceId) return
      const audioTrack = state.users[userId]?.audioTrack
      if (!audioTrack) return
      const constraints = { deviceId: { exact: audioDeviceId } }
      audioTrack.restart(constraints)
    },
    [ActionTypes.UPDATE_LOCAL_VIDEO_TRACK]({ state, rootGetters }) {
      const user = rootGetters["auth/user"]
      const userId = user?.id
      if (!userId) return
      const videoDeviceId = state.camera
      if (!videoDeviceId) return
      const videoTrack = state.users[userId]?.videoTrack
      if (!videoTrack) return
      videoTrack.stop()
      const constraints = {
        ...VideoConstraints[VideoConstraintsType.USER],
        deviceId: { exact: videoDeviceId }
      }
      return videoTrack.restart(constraints)
    }
  },
  getters: {
    token: state => state.token,
    users: state => state.users,
    dominantSpeakerId: state => state.dominantSpeakerId,
    screenshareVideoTrack: state =>
      state.users?.[SCREEN_CHANNEL_IDENTIFIER]?.videoTrack,
    screenshareAudioTrack: state =>
      state.users?.[SCREEN_CHANNEL_IDENTIFIER]?.audioTrack
  }
}

export default TwilioModule
