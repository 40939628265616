import { v4 as uuidv4 } from "uuid"
import type { PluginObject } from "vue"

export type Item = {
  id: string
  priority: number
  action(): Promise<any>
}

class Queue {
  private static _items: Item[] = []
  private static async deq() {
    const [item] = this._items.sort((a, b) => b.priority - a.priority)
    if (item === undefined) return
    try {
      await item.action()
    } finally {
      this.kill(item.id)
    }
  }
  static enq({
    priority = 0,
    action
  }: {
    priority?: number
    action(): Promise<any> // should always return a promise
  }): Item {
    const item: Item = { id: uuidv4(), priority, action }
    this._items.push(item)
    if (this._items.length === 1) this.deq()
    return item
  }
  static kill(id: string) {
    const index = this._items.findIndex(item => item.id === id)
    if (index > -1) {
      this._items.splice(index, 1)
      if (this._items.length) this.deq()
    }
  }
}

const QueuePlugin: PluginObject<never> = {
  install(Vue) {
    Vue.prototype.$queue = Queue
  }
}

export default QueuePlugin
