
import Vue, { PropType } from "vue"
import { mergeData } from "vue-functional-data-merge"

import { colors } from "./types"

export default Vue.extend({
  name: "RTBPillButton",
  functional: true,
  props: {
    color: {
      type: String as PropType<typeof colors[number]>,
      default: "default" as const,
      validator: val => colors.includes(val)
    }
  },
  render(h, { props, children, data }) {
    return h(
      "button",
      mergeData(data, {
        attrs: { type: "button" },
        class: `rtb-pill-button--color--${props.color}`,
        staticClass: "rtb-pill-button"
      }),
      children
    )
  }
})
