const { v4: uuidv4 } = require("uuid")
const Collection = require("./Collection")

const State = {
  Hidden: "hidden",
  Result: "results"
}

class Team extends Collection {
  constructor(args = {}) {
    const uuid = uuidv4()
    return {
      id: uuid,
      totalScore: 0,
      show: true,
      name: `New Team - ${uuid}`,
      active: true,
      flippedState: State.Hidden,
      ...args
    }
  }
  static isActive(team) {
    return team && team.show === true && team.deleted !== true
  }
  static isSelectable(team) {
    return Team.isActive(team) && !Team.isSpecialPurpose(team)
  }
  static isSpecialPurpose(team) {
    return Boolean(team && team.specialPurpose)
  }
  static show(team) {
    team.flippedState = State.Result
  }
  static isHidden(team) {
    return Boolean(team && flippedState === State.Hidden)
  }
  static hide(team) {
    team.flippedState = State.Hidden
  }
  static sortByScore(a, b) {
    return (parseInt(a.totalScore) || 0) - (parseInt(b.totalScore) || 0)
  }
  static sort(a, b) {
    return String(a.id).localeCompare(b.id)
  }
  static normalize(obj = {}) {
    return Object.entries(obj)
      .filter(
        ([id, obj]) => obj && id !== "undefined" && id !== "0" && id !== 0
      )
      .map(([id, obj]) => {
        obj.id = id
        return obj
      })
  }
}

Team.STATE = State

module.exports = Team
