import EventEmitter from "events"

const AUDIO_CONSTRAINTS = {
  sampleSize: 16,
  echoCancellation: false
}

const VIDEO_CONSTRAINTS = {
  frameRate: 30,
  width: { max: 1920 }
}

// TODO
// merge Composer and Room into one class Stream

class Composer extends EventEmitter {
  constructor() {
    super()
    this._system = null
    this._user = null
    this._videoTrack = null
    this._audioTrack = null
    return this
  }
  async allocate() {
    this._system = await navigator.mediaDevices.getDisplayMedia({
      video: VIDEO_CONSTRAINTS,
      audio: AUDIO_CONSTRAINTS,
      preferCurrentTab: true
    })
    this._user = await navigator.mediaDevices.getUserMedia({
      audio: AUDIO_CONSTRAINTS
    })
  }
  stop() {
    this._system?.getTracks().forEach(track => track?.stop())
    this._user?.getTracks().forEach(track => track?.stop())
    this.videoTrack?.stop()
    this.audioTrack?.stop()
  }
  getTracks() {
    return [this._videoTrack, this._audioTrack]
  }
  start() {
    const audioContext = new AudioContext()
    const dest = audioContext.createMediaStreamDestination()

    const [systemVideoTrack] = this._system.getVideoTracks()
    const [systemAudioTrack] = this._system.getAudioTracks()
    const [userAudioTrack] = this._user.getAudioTracks()

    const unwatch = () => {
      userAudioTrack.removeEventListener("ended", unwatch)
      systemVideoTrack.removeEventListener("ended", unwatch)
      systemAudioTrack.removeEventListener("ended", unwatch)
      this.emit("ended")
    }

    userAudioTrack.addEventListener("ended", unwatch)
    systemVideoTrack.addEventListener("ended", unwatch)
    systemAudioTrack.addEventListener("ended", unwatch)

    const audioSource1 = audioContext.createMediaStreamSource(this._user)
    audioSource1.connect(dest)

    const systemAudioStream = new MediaStream()
    systemAudioStream.addTrack(systemAudioTrack)

    const audioSource2 = audioContext.createMediaStreamSource(systemAudioStream)

    audioSource2.connect(dest)

    const [audioTrack] = dest.stream.getAudioTracks()

    this._videoTrack = systemVideoTrack
    this._audioTrack = audioTrack

    return this
  }
}

export default Composer
