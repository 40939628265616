import { db } from "@/firebase"
import Collection from "@shared/Collection"
import { Role } from "../helpers"
import { getGameUsersRef } from "./user.service"
import { getCurrentUser } from "./auth.service"
import { MailMatcher } from "@/services/mail.matching"
import normalizeEmail from "@shared/helpers/normalize-email"
import instance from "./axios.service"
import { BreakoutType } from "@shared/enums"
import User from "@shared/User"

const DEFAULT_MAX_PLAYERS_PER_GAME = 24

const fetchGameUsers = async ({ gameID }) => {
  const snapshot = await getGameUsersRef({ gameID }).once("value")
  return snapshot.val()
}

const fetchOrgs = async () => {
  const snapshot = await db.ref("orgs").once("value")
  return snapshot.val()
}

const updateGameWith = (gameID, orgID, obj) => {
  return db.auxiliary().ref(`org/${orgID}/games/${gameID}`).update(obj)
}

const updateTeamWith = (gameID, orgID, teamID, obj) => {
  return db
    .auxiliary()
    .ref(`org/${orgID}/game/${gameID}/teams/${teamID}`)
    .update(obj)
}

const fetchGames = async ({ orgID, clientID }) => {
  let ref = null
  if (clientID) {
    ref = db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("clientID")
      .equalTo(clientID)
  } else {
    ref = db.auxiliary().ref(`org/${orgID}/games`)
  }

  const snapshot = await ref.once("value")
  return snapshot.val()
}

const fetchGamesByClientID = async (orgID, clientID) => {
  const snapshot = await db
    .auxiliary()
    .ref(`org/${orgID}/games`)
    .orderByChild("clientID")
    .equalTo(clientID)
    .once("value")

  return snapshot.val()
}

const fetchOneGame = async (orgID, clientID) => {
  const ref = db.auxiliary().ref(`org/${orgID}/games`)
  const query = clientID
    ? ref.orderByChild("clientID").equalTo(clientID).limitToLast(1)
    : ref.limitToLast(1)
  const snapshot = await query.once("value")
  const value = snapshot.val() || {}
  const entry = Object.entries(value)[0]
  if (!entry) return null
  const [id, game] = entry
  game.id = id
  return game
}

const fetchGameObject = async ({ orgID, gameID }) => {
  const snapshot = await db
    .auxiliary()
    .ref(`org/${orgID}/game/${gameID}`)
    .once("value")
  return snapshot.val()
}

const fetchGame = async ({ orgID, gameID }) => {
  const snapshot = await db
    .auxiliary()
    .ref(`org/${orgID}/games/${gameID}`)
    .once("value")

  return snapshot.val()
}

const fetchDetailedGameObject = async ({ orgID, gameID }) => {
  const snapshot = await db
    .auxiliary()
    .ref(`org/${orgID}/game/${gameID}`)
    .once("value")

  return snapshot.val()
}

const fetchGameTeamsObject = async ({ orgID, gameID }) => {
  const snapshot = await db
    .auxiliary()
    .ref(`org/${orgID}/game/${gameID}/teams`)
    .once("value")

  return snapshot.val()
}
/**
 *
 * @param orgID
 * @param gameID
 * @return {Promise<any>}
 */
const fetchGameTeams = async ({ orgID, gameID }) => {
  const snapshot = await db
    .auxiliary()
    .ref(`org/${orgID}/game/${gameID}/teams`)
    .once("value")
  return snapshot.val()
}

const canUserJoinGame = async ({ userID, gameID, orgID, role }) => {
  if (!gameID)
    throw new Error("Cannot verify a user without a destination game ID")
  if (!orgID) throw new Error("Cannot verify a user without an organization ID")
  if (!userID) throw new Error("Cannot verify a user without an ID")
  if (!role) throw new Error("Cannot verify a user without permissions")

  if (role === Role.Audit) return null
  if (role === Role.Spectator) return null

  const [game, users] = await Promise.all([
    fetchGame({ orgID, gameID }),
    fetchGameUsers({ gameID })
  ])

  if (!game)
    throw new Error(
      `Cannot find a game with ID ${gameID} in organization ${orgID}`
    )

  const values = Collection.normalize(users || {})

  if (role === Role.Host) {
    const [host] = values
      .filter(
        user =>
          user &&
          user.gameID === gameID &&
          User.isAlive(user) &&
          user.role === Role.Host
      )
      .sort(
        (a, b) =>
          (parseInt(b.loginTimestamp) || 0) - (parseInt(a.loginTimestamp) || 0)
      )

    // if no host online, you are good to go
    if (!host) return null

    const { firstname, lastname, id } = host

    if (id === userID) {
      console.log("you are the same user")
      return null
    }

    const message = `You are about to break into ${firstname} ${lastname}'s game. You sure you want to do that?'`

    // ask about a conflict
    const answer = window.confirm(message)

    if (answer) {
      return null
    } else {
      throw new Error("Aborted")
    }
  }

  const now = Date.now()

  const online = values.filter(user => {
    return (
      user.gameID === gameID &&
      User.isAlive(user, now) &&
      User.isPlayer(user) &&
      user.id !== userID
    )
  })

  const nOfGamePlayersOnline = online.length
  const maxPlayersPerGame = game.players || DEFAULT_MAX_PLAYERS_PER_GAME

  if (nOfGamePlayersOnline >= maxPlayersPerGame) {
    return `The game is full. Only ${maxPlayersPerGame} player(s) allowed`
  } else {
    return null
  }
}

const fetchGamesByRunStatus = async ({ orgID, value }) => {
  const snapshot = await db
    .auxiliary()
    .ref(`org/${orgID}/games`)
    .orderByChild("runStatus")
    .equalTo(value)
    .once("value")

  return snapshot.val()
}

const fetchMissions = async ({ orgID, gameID }) => {
  const snapshot = await db
    .auxiliary()
    .ref(`org/${orgID}/game/${gameID}/missions`)
    .once("value")
  return snapshot.val()
}

const isGamePlayedByUser = ({ originalGameID, theKey, userPlayedGames }) => {
  const gameID = originalGameID || theKey
  return !!userPlayedGames && !!userPlayedGames[gameID]
}

const getGameMatchingData = async ({ orgID, clientID, userPlayedGames }) => {
  const user = getCurrentUser()
  if (!user) return null
  const email = user.email
  if (!email) return null
  const userEmail = normalizeEmail(email, clientID)
  const games = await fetchGames({ orgID, clientID })
  const filteredGames = Object.values(games || {}).filter(
    item =>
      !item.deletedTimestamp &&
      !item.endTimestamp &&
      !isGamePlayedByUser({ ...item, userPlayedGames })
  )
  const matcher = new MailMatcher(clientID)
  return await matcher.getFirstMatching(userEmail, filteredGames)
}

const overrideGameTeams = async (array, gameID, orgID) => {
  // array: [{ id: ..., name: ... }, { id: ..., name: ... }, { id: ..., name: ... }]
  const { teams } = await fetchGameObject({ orgID, gameID })
  const update = Object.values(teams)
    .map((team, i) => ({ ...team, id: array[i].id, name: array[i].name }))
    .reduce((acc, val) => {
      acc[val.id] = val
      return acc
    }, {})

  await db.auxiliary().ref(`org/${orgID}/game/${gameID}/teams`).set(update)
}

const deleteTeamFromGame = (teamID, gameID, orgID) => {
  if (!teamID) throw new Error("Cannot delete a team without an ID")
  return db
    .auxiliary()
    .ref(`org/${orgID}/game/${gameID}/teams/${teamID}`)
    .remove()
}

const syncScoreWithBreadcrumb = (orgID, clientID, gameID) => {
  return instance({
    method: "post",
    url: `/game/${gameID}/breadcrumb/sync`,
    headers: { "x-gogame-org": orgID },
    data: {
      clientID
    }
  })
}

class UsersActions {
  static breakoutByTeam(reqObj) {
    const { orgID, gameID, size } = reqObj
    return instance({
      method: "post",
      url: `/game/${gameID}/users/${BreakoutType.BY_SIZE}`,
      headers: { "x-gogame-org": orgID },
      data: { payload: { size } }
    })
  }
  static breakoutByPoll(reqObj) {
    const { orgID, gameID, missionID } = reqObj
    return instance({
      method: "post",
      url: `/game/${gameID}/users/${BreakoutType.BY_POLL}`,
      headers: { "x-gogame-org": orgID },
      data: { payload: { missionID } }
    })
  }
  static breakoutSelfSelect(reqObj) {
    const { orgID, gameID } = reqObj
    return instance({
      method: "post",
      url: `/game/${gameID}/users/${BreakoutType.SELF_SELECT}`,
      headers: { "x-gogame-org": orgID }
    })
  }
  static breakoutUndo(reqObj) {
    const { orgID, gameID } = reqObj
    return instance({
      method: "post",
      url: `/game/${gameID}/users/${BreakoutType.UNDO}`,
      headers: { "x-gogame-org": orgID }
    })
  }
}

export {
  fetchOrgs,
  canUserJoinGame,
  fetchGame,
  fetchGames,
  fetchGamesByRunStatus,
  fetchGameUsers,
  fetchGameObject,
  fetchMissions,
  fetchGameTeams,
  getGameMatchingData,
  isGamePlayedByUser,
  overrideGameTeams,
  fetchDetailedGameObject,
  fetchGameTeamsObject,
  deleteTeamFromGame,
  fetchOneGame,
  updateGameWith,
  updateTeamWith,
  fetchGamesByClientID,
  syncScoreWithBreadcrumb,
  UsersActions
}
