import { computed, ref, watch } from "@vue/composition-api"
import shuffle from "knuth-shuffle-seeded"
import _ from "lodash"
import User from "@shared/User"
import { User as UserEntity } from "@/types/user"

import useStore from "@/use/useStore"
import { useViewer } from "@/use"

type UserAnswer = {
  round: number
  userId: string
  value: string
  teamId: string
  timestamp: number
}

const state = ref()
export const visibleUserIds = ref([])
const STEPS_PER_ROUND = 3
export default function useMusicalKeys() {
  const { store } = useStore()
  const { viewer } = useViewer()
  const step = computed(() => state.value?.step || 0)
  const round = computed(() => Math.floor(step.value / STEPS_PER_ROUND) || 0)
  const isScribe = computed(() => store.getters.isScribe)
  const mission = computed(() => store.getters.getCurrentMission)
  const audio = computed(() => mission.value.audio)

  const isFirstRoundStep = computed(() => step.value % STEPS_PER_ROUND === 0)
  const isMiddleStep = computed(
    () => !isFirstRoundStep.value && !isLastRoundStep.value
  )
  const isLastRoundStep = computed(
    () => (step.value + 1) % STEPS_PER_ROUND === 0 && step.value !== 0
  )
  const playing = computed(() => isFirstRoundStep.value || false)
  const textHidden = computed(() => isFirstRoundStep.value)
  const showLoosers = computed(() => isLastRoundStep.value)
  const isFieldDisabled = computed(
    () => isFirstRoundStep.value || isLastRoundStep.value || hasAnswered.value
  )
  const isGridVisible = computed(
    () =>
      !isFirstRoundStep.value &&
      ((!isScribe.value && usersToShow.value.length) ||
        hasAnswered.value ||
        isLastRoundStep.value)
  )

  const currentText = computed(() => {
    const timestamp = store.getters.gameStatus.missionStarted

    const { answers = [] } = mission.value
    const index = round.value % answers.length
    const suffled = shuffle([...answers], timestamp)
    return isMiddleStep.value ? suffled[index].trim() : "**********"
  })

  const players = computed<UserEntity[]>(() =>
    store.getters.onlineUsersArray.filter(user => User.isSelectable(user))
  )

  const scribes = computed<UserEntity[]>(() =>
    players.value.filter(user => User.isScribe(user))
  )

  const usersToShow = computed(() => {
    if (isFirstRoundStep.value) {
      return []
    }

    const timestampsById = Object.values(roundAnswers.value || {}).reduce(
      (acc, answer) => {
        acc[answer.userId] = answer.timestamp
        return acc
      },
      {}
    )

    const getTimestamp = user => timestampsById[user.id] || Date.now()

    const sorted = (
      isMiddleStep.value ? answeredUsers.value : scribes.value
    ).sort((a, b) => getTimestamp(a) - getTimestamp(b))

    return sorted
  })
  const answeredUsers = computed(() =>
    scribes.value.filter(user => isAnswered(user))
  )

  const userAnswers = computed<UserAnswer[]>(
    () => Object.values(state.value?.answers || {}) || []
  )

  const roundAnswers = computed(() =>
    userAnswers.value?.filter(answer => answer.round === round.value)
  )

  const answeredUserIds = computed<string[]>(() => {
    const ids: string[] = _(roundAnswers.value || {})
      .sortBy("timestamp")
      .map(({ userId }) => userId)
      .value()

    if (!isLastRoundStep.value || ids.length === 1) {
      return ids
    }

    const notAnswered = scribes.value.filter(user => !ids.includes(user.id))

    const loosersNeeded =
      round.value < 2 ? Math.floor(players.value.length / 4) : 1

    const nOfNotAnswered = notAnswered.length
    if (nOfNotAnswered < loosersNeeded) {
      return ids.slice(0, -(loosersNeeded - nOfNotAnswered))
    } else {
      return ids
    }
  })

  const hasAnswered = computed(() => isAnswered(viewer.value))

  function isAnswered(user) {
    return answeredUserIds.value.includes(user.id)
  }

  function updateState(snapshot) {
    state.value = snapshot.val()
  }

  watch(
    usersToShow,
    value => {
      visibleUserIds.value = value.map(item => item.id)
    },
    { immediate: true }
  )

  return {
    playing,
    state,
    updateState,
    isAnswered,
    isFirstRoundStep,
    isLastRoundStep,
    usersToShow,
    isGridVisible,
    isFieldDisabled,
    answeredUserIds,
    roundAnswers,
    hasAnswered,
    showLoosers,
    step,
    round,
    isScribe,
    currentText,
    textHidden,
    audio
  }
}
