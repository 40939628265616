export interface User {
  id: string
  orgID: string
  clientID: string
  teamID?: string
  role: UserRole
  firstname: string
  lastname: string
  image: string
  path?: string
  gameID?: string
  deleted?: boolean
}

export interface Host extends User {
  role: UserRole.Host
  venmo?: string
  paypal?: string
}

export enum UserRole {
  Player = "player",
  Host = "facilitator",
  Audit = "audit",
  Spectator = "spectator",
  Super = "super"
}
