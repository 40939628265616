import type Vue from "vue"
import type { ThisTypedComponentOptionsWithRecordProps } from "vue/types/options"

class TaggableMixinFactory {
  static create(tag = "div") {
    const IdentifiableMixin: ThisTypedComponentOptionsWithRecordProps<
      Vue,
      {},
      {},
      {},
      {
        tag: string
      }
    > = {
      props: {
        tag: {
          type: String,
          default: tag
        }
      }
    }

    return IdentifiableMixin
  }
}

export default TaggableMixinFactory
