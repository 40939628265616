<template>
  <WeveDialog
    dialog-class="pick-teams-dialog"
    v-model="state.open"
    title="Edit Teams"
  >
    <TeamManager
      :navigation="false"
      :externalGameID="gameID"
      :externalUsers="users"
    />
  </WeveDialog>
</template>

<script>
import Vue from "vue"

import TeamManager from "@/components/TeamManager"
import { WeveDialog } from "@weve/ui"

export const state = Vue.observable({
  open: false
})

export default Vue.extend({
  name: "PickTeamsDialog",
  components: {
    WeveDialog,
    TeamManager
  },
  props: {
    gameID: {
      type: String,
      default: undefined
    },
    users: {
      type: Array,
      default: undefined
    }
  },
  data() {
    return {
      state
    }
  }
})
</script>

<style lang="scss">
.pick-teams-dialog {
  font-family: "Sofia Pro", serif;
  width: 85%;
  max-width: initial;
  height: 85%;
  margin: auto;
  padding: 3.75rem;
  display: flex;
  .modal-header {
    border-bottom: none;
  }
  .modal-content {
    height: 100%;
  }
  .modal-body {
    padding: 0;
  }
}
</style>
