import { db } from "@/firebase"
import { getCurrentUser } from "@/services/auth.service"
import Answer from "@shared/Answer"
import normalizeEmail from "@shared/helpers/normalize-email"
import firebase from "firebase/compat/app"
import _ from "lodash"
import * as moment from "moment"

const serialize = string => String(string).toLowerCase().trim()

// it's an on demand piece of data
// since only categories need it we can call it as a function
const isMultipleChoiceCompleted = ({
  missionAllAnswers,
  missionCorrectAnswer
}) => {
  if (!Array.isArray(missionCorrectAnswer))
    throw new Error("Categories answer must be an array")
  // return true if all correct asnwers can be found in the user
  // given answers
  return missionCorrectAnswer.every(correct =>
    missionAllAnswers.some(answer => serialize(answer) === correct)
  )
}

let giphySubscription = null
let giphySubscriptionRef = null

let playsSubscription = null
let playsSubscriptionRef = null

const MAX_NUM_OF_MISSION_TRIES = 9999
const TEAM_MISSION_MODE = "team"
const INDIVIDUAL_MISSION_MODE = "individual"
const CATEGORIES_MISSION_TYPE = "Categories"

const PlayModule = {
  state: {
    score: 1,
    totalComplete: 0,
    play: {},
    plays: [],
    votes: {},
    giphies: {},
    lipdub: {}
  },
  mutations: {
    SET_PLAYS(state, payload) {
      state.plays = payload
    },
    setVotes(state, payload) {
      state.votes = payload
    },
    setGiphy(state, payload) {
      state.giphies = payload
    },
    setTeamScore(state, payload) {
      state.team.totalScore = payload
    }
  },
  getters: {
    score(state) {
      return state.score
    },
    plays(state) {
      return state.plays
    },
    missionPlays(state, getters) {
      const mission = getters.getCurrentMission || {}

      // console.log(`mission`, mission)
      // console.log(`state.plays`, state.plays)

      return state.plays.filter(
        play =>
          play?.missionID === mission.id ||
          (mission?.activityId && play?.activityId === mission.activityId)
      )
    },
    missionPlaysArray(state, getters) {
      return getters.missionPlays.filter(play => !play.hint)
    },
    missionHintsArray(state, getters) {
      return getters.missionPlays.filter(play => play.hint)
    },
    missionPlaysArrayAll(state, getters) {
      return getters.missionPlays
    },
    allCorrectMissionPlays(state, { missionPlaysArray }) {
      return missionPlaysArray.filter(({ result }) => result)
    },
    nOfAllCorrectMissionPlays(state, { allCorrectMissionPlays }) {
      return allCorrectMissionPlays.length
    },
    missionTeamPlaysArray(state, getters, rootState) {
      const user = getters.user
      const plays = getters.missionPlaysArray
      const { teamID } = user
      return plays.filter(obj => obj.teamID === teamID)
    },
    isAuditor(state, getters, rootState) {
      if (rootState.livechat.roomID) return false
      if (getters.user.rule === "auditor") return true
    },
    missionUserPlaysArray(state, getters) {
      const user = getters.user
      const plays = getters.missionTeamPlaysArray
      const { id: userID } = user
      // make sure the userID match the current user
      return plays.filter(obj => obj.userID === userID)
    },
    missionPlayType(state, getters) {
      const mission = getters.getCurrentMission
      if (!mission) return TEAM_MISSION_MODE
      if (!mission.playType) return TEAM_MISSION_MODE
      const { playType } = mission
      const string = serialize(playType)
      if (string.indexOf(INDIVIDUAL_MISSION_MODE) > -1) {
        return INDIVIDUAL_MISSION_MODE
      } else {
        return TEAM_MISSION_MODE
      }
    },
    isUnlimitedMission(state, { nOfMissionTries }) {
      return nOfMissionTries >= MAX_NUM_OF_MISSION_TRIES
    },
    nOfMissionTries(state, getters) {
      const numOfTries = getters.getCurrentMission?.numOfTries
      // let's make it always an int
      const max = MAX_NUM_OF_MISSION_TRIES
      const num = numOfTries === "Unlimited" ? max : parseInt(numOfTries)
      return isNaN(num) ? 1 : num
    },
    missionFail(state, getters) {
      const { missionSuccess, missionAnswers, nOfMissionTries } = getters
      // doesn't matter what comes next
      if (missionSuccess) return false
      // if n of answers hits the max n of tries
      return nOfMissionTries <= missionAnswers.length
    },
    missionFailByTeam(state, getters) {
      const { missionAnswersByTeam, nOfMissionTries, missionSuccessByTeam } =
        getters
      return teamID => {
        if (missionSuccessByTeam(teamID)) return false
        return nOfMissionTries <= missionAnswersByTeam(teamID).length
      }
    },
    missionSuccess(state, { missionSuccessfulPlays }) {
      return missionSuccessfulPlays.length > 0
    },
    missionSuccessByTeam(_, getters) {
      return teamID => getters.missionSuccessfulPlaysByTeam(teamID).length > 0
    },
    missionSuccessfulPlays(_, getters) {
      const { missionPlayType, missionTeamPlaysArray, missionUserPlaysArray } =
        getters
      if (missionPlayType === TEAM_MISSION_MODE) {
        return missionTeamPlaysArray.filter(({ result }) => result)
      } else if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
        return missionUserPlaysArray.filter(({ result }) => result)
      } else {
        return []
      }
    },
    missionSuccessfulPlaysByTeam(_, getters, rootState, rootGetters) {
      const { missionPlayType, getTeamplays, missionPlaysArray } = getters
      const { onlineUsersArray: onlineUsers } = rootGetters
      return teamID => {
        if (missionPlayType === TEAM_MISSION_MODE) {
          return getTeamplays(teamID).filter(({ result }) => result)
        } else if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
          const onlineTeamUsers = onlineUsers
            .filter(({ selected, teamID: id }) => selected && id === teamID)
            .map(({ id }) => id)
          return missionPlaysArray.filter(
            ({ result, teamID: tID, userID }) =>
              result && tID === teamID && onlineTeamUsers.includes(userID)
          )
        } else {
          return []
        }
      }
    },
    missionCompletedPlays(state, getters) {
      const { missionPlayType, missionTeamPlaysArray, missionUserPlaysArray } =
        getters
      if (missionPlayType === TEAM_MISSION_MODE) {
        return missionTeamPlaysArray.filter(({ completed }) => completed)
      } else if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
        return missionUserPlaysArray.filter(({ completed }) => completed)
      } else {
        return []
      }
    },
    missionCompletedPlaysByTeam(state, getters, rootState, rootGetters) {
      const { missionPlayType, getTeamplays, missionPlaysArray } = getters
      const { onlineUsersArray: onlineUsers } = rootGetters
      return teamID => {
        if (missionPlayType === TEAM_MISSION_MODE) {
          return getTeamplays(teamID).filter(({ completed }) => completed)
        } else if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
          const onlineTeamUsers = onlineUsers
            .filter(({ selected, teamID: id }) => selected && id === teamID)
            .map(({ id }) => id)
          return missionPlaysArray.filter(
            ({ completed, teamID: tID, userID }) =>
              completed && tID === teamID && onlineTeamUsers.includes(userID)
          )
        } else {
          return []
        }
      }
    },
    anyTeamMissionCompletedPlays(state, { missionPlaysArray }) {
      return missionPlaysArray.filter(({ completed }) => completed)
    },
    missionCorrectAnswer(state, getters) {
      const answer = getters.getCurrentMission?.answer
      if (typeof answer === "string") {
        if (answer.indexOf(";") > -1) {
          const array = answer.split(";")
          return array.map(string => serialize(string))
        } else if (answer.indexOf(",") > -1) {
          const array = answer.split(",")
          return array.map(string => serialize(string))
        } else {
          return serialize(answer)
        }
      } else {
        return answer
      }
    },
    missionAnswers(state, getters) {
      const { missionPlayType, missionTeamPlaysArray, missionUserPlaysArray } =
        getters
      const reducer = (acc, val) => {
        const { answer } = val
        // just in case handle array AND any other type
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }
      // fork it here just for the sake of
      // optimization
      if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
        return missionUserPlaysArray.reduce(reducer, [])
      } else {
        return missionTeamPlaysArray.reduce(reducer, [])
      }
    },
    missionAnswersByTeam(state, getters, rootState, rootGetters) {
      const { missionPlayType, getTeamplays, missionPlaysArray } = getters
      const { onlineUsersArray: onlineUsers } = rootGetters
      const reducer = (acc, val) => {
        const { answer } = val
        // just in case handle array AND any other type
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }
      return teamID => {
        if (missionPlayType === INDIVIDUAL_MISSION_MODE) {
          const onlineTeamUsers = onlineUsers
            .filter(({ selected, teamID: id }) => selected && id === teamID)
            .map(({ id }) => id)
          const teamUsersplays = missionPlaysArray.filter(
            ({ completed, teamID: tID, userID }) =>
              completed && tID === teamID && onlineTeamUsers.includes(userID)
          )
          return teamUsersplays.reduce(reducer, [])
        } else {
          return getTeamplays(teamID).reduce(reducer, [])
        }
      }
    },
    // join all user given answers into one array
    missionAllAnswers(state, { missionPlaysArray }) {
      const reducer = (acc, val) => {
        const { answer } = val
        // just in case handle array AND any other type
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }
      return missionPlaysArray.reduce(reducer, [])
    },
    nOfIncorrectMissionAnswers(state, getters) {
      const {
        getCurrentMission,
        missionSuccess,
        missionAnswers,
        missionSuccessfulPlays
      } = getters
      const behavior = getCurrentMission?.behavior
      // subtract a correct answer
      if (missionSuccess) {
        if (behavior === CATEGORIES_MISSION_TYPE) {
          missionAnswers.length - missionSuccessfulPlays.length
        } else {
          return missionAnswers.length - 1
        }
      } else {
        return missionAnswers.length
      }
    },
    missionCompleted(state, getters) {
      const {
        getCurrentMission,
        missionFail,
        missionSuccess,
        missionCompletedPlays,
        missionAllAnswers,
        missionCorrectAnswer
      } = getters
      const behavior = getCurrentMission?.behavior
      // exclusively for categories
      if (behavior === CATEGORIES_MISSION_TYPE) {
        // let's don't compute isMultipleChoiceCompleted
        // but rather call it only for "Categories"
        const obj = { missionCorrectAnswer, missionAllAnswers }
        return missionFail || isMultipleChoiceCompleted(obj)
      } else if (missionCompletedPlays.length) {
        // completed prop is enforing the mission status
        return true
      } else {
        return missionFail || missionSuccess
      }
    },
    play(state) {
      return state.play
    },
    votes(state) {
      return state.votes
    },
    giphies(state) {
      return state.giphies
    },
    getTeamplays(state, getters) {
      const { missionPlaysArray, getCurrentMission } = getters
      return teamID => {
        if (getCurrentMission?.behavior == "Two Truths Reveal") {
          return missionPlaysArray.filter(
            play =>
              play.teamID == teamID &&
              gameStatus.twoTruthsID == play.twoTruthsID
          )
        } else {
          return missionPlaysArray.filter(play => play.teamID == teamID)
        }
      }
    },
    getUserMissionUserPlaysArray(_, getters) {
      const plays = getters.missionPlaysArray
      return userID => {
        return plays.filter(obj => obj.userID === userID)
      }
    },
    isAllTeamOutOfTries(state, getters, rootState, rootGetters) {
      const { isUnlimitedMission, nOfMissionTries, getTeamplays } = getters
      return teamIDs => {
        if (isUnlimitedMission) return false
        return teamIDs.every(
          teamID =>
            getTeamplays(teamID).reduce((acc, val) => {
              const { answer } = val
              if (Array.isArray(answer)) {
                return acc.concat(answer)
              } else {
                acc.push(answer)
                return acc
              }
            }, []).length >= nOfMissionTries
        )
      }
    },
    missionCompleteForAllTeams(state, getters, rootState, rootGetters) {
      const { chats: teams } = rootGetters
      const teamIDs = Object.keys(teams || {}).filter(
        key => key !== "0" && key !== 0 && key !== "undefined"
      )
      return teamIDs.every(teamID => {
        const {
          getCurrentMission,
          missionFailByTeam,
          missionSuccessByTeam,
          missionCompletedPlaysByTeam,
          missionAllAnswers,
          missionCorrectAnswer
        } = getters
        const behavior = getCurrentMission?.behavior
        // exclusively for categories
        if (behavior === CATEGORIES_MISSION_TYPE) {
          // let's not compute isMultipleChoiceCompleted
          // but rather call it only for "Categories"
          const obj = { missionCorrectAnswer, missionAllAnswers }
          return missionFailByTeam(teamID) || isMultipleChoiceCompleted(obj)
        } else if (missionCompletedPlaysByTeam(teamID).length) {
          // completed prop is enforing the mission status
          return true
        } else {
          return missionFailByTeam(teamID) || missionSuccessByTeam(teamID)
        }
      })
    },
    missionSuccessForOneTeam(state, getters, rootState, rootGetters) {
      const { chats: teams } = rootGetters
      const teamIDs = Object.keys(teams || {}).filter(
        key => key !== "0" && key !== 0 && key !== "undefined"
      )
      const { missionSuccessByTeam } = getters
      for (var i in teamIDs) {
        if (missionSuccessByTeam(teamIDs[i])) return true
      }
      return false
    },
    anyTeamMissionSuccessfulPlays(state, getters, rootState, rootGetters) {
      const { chats: teams } = rootGetters
      const { missionSuccessfulPlaysByTeam } = getters
      const teamIDs = Object.keys(teams || {})
      return teamIDs.some(
        teamID => missionSuccessfulPlaysByTeam(teamID).length > 0
      )
    }
  },
  actions: {
    async addPlay({ rootState, rootGetters }, payload) {
      const { orgID, gameID } = rootState
      const mission = rootGetters.getCurrentMission
      const activityId = mission?.activityId || null
      const { game } = rootGetters
      const { clientID } = game
      const play = { ...payload, gameID, clientID, activityId }

      if (play.email === undefined || play.email === null)
        play.email = normalizeEmail(getCurrentUser().email, clientID) || null

      const update = {}
      const path = `org/${orgID}/game/${gameID}/play`
      const id = play.id || db.auxiliary().ref(path).push().key

      update[`${path}/${id}`] = play
      update[`client/${clientID}/play/${id}`] = play

      await db.auxiliary().ref().update(update)
    },
    addGiphy({ rootState: { orgID, gameID } }, payload) {
      if (payload.id) {
        return db
          .auxiliary()
          .ref(`org/${orgID}/game/${gameID}/giphies/${payload.id}`)
          .update(payload)
      } else {
        return db
          .auxiliary()
          .ref(`org/${orgID}/game/${gameID}/giphies`)
          .push(payload)
      }
    },
    addVote({ rootState: { orgID, gameID } }, payload) {
      console.log("orgID, gameID, payload", { orgID, gameID, payload })

      const ref = db.auxiliary().ref(`org/${orgID}/game/${gameID}/votes`)

      if (payload.id) return ref.child(payload.id).update(payload)

      return ref.push(payload)
    },
    removeBuzz({ rootState: { orgID, gameID } }) {
      return db.auxiliary().ref(`org/${orgID}/game/${gameID}/buzz`).remove()
    },
    addBuzz({ rootState: { orgID, gameID }, rootGetters }, payload) {
      const mission = rootGetters.getCurrentMission
      return db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/buzz/${mission?.id}/users`)
        .push({
          ...payload,
          timestamp: firebase.database.ServerValue.TIMESTAMP
        })
    },
    async setScore({ rootGetters }, payload) {
      const { game } = rootGetters
      const { score, userID } = payload
      const { clientID } = game
      const userScoreRef = db
        .auxiliary()
        .ref(`client/${clientID}/userscore/${userID}`)
      return userScoreRef.transaction(
        userScore => (userScore || 0) + (score || 0)
      )
    },
    setTeamScore({ rootState: { orgID, gameID } }, payload) {
      const { teamID, teamScore } = payload
      const newScore = parseInt(teamScore)
      if (isNaN(newScore)) return console.error("Score error")
      // update team score
      return db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/teams/${teamID}`)
        .update({ totalScore: newScore })
    },
    deletePlay({ rootState: { orgID, gameID } }, playID) {
      return db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/play/${playID}`)
        .remove()
    },
    async subscribeToPlays({ commit, rootState: { orgID, gameID } }) {
      commit("SET_PLAYS", [])

      playsSubscriptionRef?.off("value", playsSubscription)

      playsSubscriptionRef = db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/play`)

      playsSubscription = playsSubscriptionRef.on("value", snapshot =>
        commit("SET_PLAYS", Answer.normalize(snapshot.val() || {}))
      )
    },
    unsubscribeFromPlays() {
      playsSubscriptionRef?.off()
      playsSubscriptionRef = null
    },
    deleteGiphy({ commit, rootState }) {
      db.auxiliary()
        .ref(
          "org/" + rootState.orgID + "/game/" + rootState.gameID + "/giphies/"
        )
        .remove()
      commit("setGiphy", null)
    },
    subscribeToGiphy({ commit, rootState: { orgID, gameID } }) {
      if (giphySubscriptionRef)
        giphySubscriptionRef.off("value", giphySubscription)

      giphySubscriptionRef = db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/giphies`)

      return new Promise(resolve => {
        giphySubscription = giphySubscriptionRef.on("value", snapshot => {
          commit("setGiphy", snapshot.val())
          resolve()
        })
      })
    },
    async addFactMatchPlay({ rootGetters, rootState, dispatch }, payload) {
      const { score, answer } = payload
      const results = answer.filter(obj => obj.result)

      const {
        chats: teams,
        getCurrentMission: currentMission,
        currentMission: currentMissionID
      } = rootGetters
      const user = rootGetters["auth/user"]
      const clientID = rootGetters["auth/clientID"]

      const teamID = rootGetters["group/canViewerSelectGlobalTeam"]
        ? rootGetters["group/globalTeamID"]
        : user?.teamID

      const sTime = new Date()
      const teamScore = teams?.[teamID]?.totalScore ?? 0

      // create answer
      const userPlay = {
        behavior: currentMission.behavior,
        correct: "Fact Match",
        mission: currentMission.name,
        answer: answer,
        missionID: currentMissionID,
        points: parseInt(currentMission?.points ?? 0),
        score: score,
        result: results?.length > 0,
        show: true,
        completed: true,
        teamID: teamID,
        time: moment(sTime).unix(),
        userID: user?.id,
        username: user?.name,
        firstName: user?.firstname
      }

      await dispatch("addPlay", userPlay)
      console.log("HERE's THE PLAY WE ARE SET", userPlay)
      console.log("HERE's THE CLIENTID", clientID)
      const factScorePayload = {
        userID: user?.id,
        teamID: teamID,
        teamScore: teamScore + score,
        totalScore: score,
        score,
        clientID
      }

      await Promise.all([
        dispatch("setScore", factScorePayload),
        dispatch("setTeamScore", factScorePayload)
      ])
    }
  }
}

export default PlayModule
