import type Vue from "vue"
import type {
  PropType,
  ThisTypedComponentOptionsWithRecordProps
} from "vue/types/options"
import shortid from "shortid"

const IdentifiableMixin: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  {},
  {},
  {},
  {
    id: string
    label: string
    hideLabel: boolean
    labelSize: "small" | "default"
  }
> = {
  props: {
    id: {
      type: String,
      default() {
        return shortid.generate()
      }
    },
    label: {
      type: String as PropType<string | undefined>
    },
    hideLabel: {
      type: Boolean,
      default: false
    },
    labelSize: {
      type: String as PropType<"small" | "default">,
      default: "default" as const
    }
  }
}

export default IdentifiableMixin
