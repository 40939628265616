var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "host-mini-topbar", class: { "pb-0": _vm.isMeetingMode } },
    [
      !_vm.isMeetingMode
        ? _c(
            "v-btn",
            {
              staticClass: "host-mini-topbar__btn",
              attrs: { flat: "", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.$router.push("/pickteams/" + _vm.urlID)
                },
              },
            },
            [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("people_alt")])],
            1
          )
        : _vm._e(),
      _c("AudioDrawerToggle", { staticClass: "host-mini-topbar__music-btn" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }