import isUrl from "is-url"
import { extend } from "vee-validate"
import {
  required,
  email,
  min,
  max,
  numeric,
  min_value,
  max_value
} from "vee-validate/dist/rules"
import { messages } from "vee-validate/dist/locale/en.json"

// TODO(Andrew): DRY?
extend("required", { ...required, message: messages.required })
extend("email", { ...email, message: messages.email })
extend("password", {
  params: ["target"],
  validate(value, { target }: { target: any }) {
    console.log(arguments)
    return value === target
  },
  message: "Password confirmation does not match"
})
extend("min", { ...min, message: messages.min })
extend("max", { ...max, message: messages.max })
extend("numeric", { ...numeric, message: messages.numeric })
extend("min_value", { ...min_value, message: messages.min_value })
extend("max_value", { ...max_value, message: messages.max_value })

extend("url", {
  validate(value) {
    return isUrl(value)
  },
  message: "The {_field_} is not a valid URL"
})

extend("hex", {
  validate(value) {
    return /^#[0-9a-fA-F]{6}$/.test(value)
  },
  message: "The {_field_} is not a valid HEX color"
})
