<template>
  <LoginBadge class="powered-by">
    <span class="powered-by__powered-by">Powered by</span>
    <img
      :src="require('@/assets/login/weve-logo-full.svg')"
      alt="Weve Logo"
      class="powered-by__weve-logo ml-3"
    />
  </LoginBadge>
</template>

<script>
import LoginBadge from "./LoginBadge.vue"
export default {
  name: "PoweredBy",
  components: { LoginBadge }
}
</script>

<style lang="scss">
.powered-by {
  display: flex;
  align-items: center;

  &__weve-logo {
    max-width: 100px;
  }

  &__remote-logo {
    margin-top: -10px;
    margin-bottom: -10px;
    max-width: 50px;
  }
}
</style>
