var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "UserCard",
    _vm._g(
      _vm._b(
        {
          staticClass: "app-user-card",
          attrs: {
            color: _vm.color,
            name: _vm.$attrs.name !== undefined ? _vm.$attrs.name : _vm.name,
            current: _vm.current,
            submitted: _vm.submitted,
            states: _vm.states,
            badge:
              _vm.$attrs.badge != null
                ? _vm.$attrs.badge
                : _vm.selected || _vm.jeopardai,
            points: _vm.points,
            onboarding: _vm.onboarding,
            mutable: _vm.canMute,
            configurable: _vm.configurable,
          },
          on: { "update:muted": _vm.toggleMuted, configure: _vm.configure },
          scopedSlots: _vm._u(
            [
              {
                key: "actions",
                fn: function () {
                  return [_vm._t("actions")]
                },
                proxy: true,
              },
              {
                key: "namePostfix",
                fn: function () {
                  return [_vm._t("namePostfix")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "UserCard",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._t("default"),
      !_vm.mediaHidden
        ? _c("UserMedia", {
            staticClass: "app-user-card__media",
            attrs: {
              identity: _vm.user.id,
              imageUrl: _vm.imageUrl,
              videoTrack: _vm.videoTrack,
              disable: _vm.mediaDisabled,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }