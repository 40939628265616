import Vue from "vue"
import * as handTrack from "handtrackjs"

const state = Vue.observable({
  initializing: false,
  initialized: false,
  destroyed: false
})

const modelParams = {
  flipHorizontal: true, // flip e.g for video
  imageScaleFactor: 1,
  maxNumBoxes: 2, // maximum number of boxes to detect
  iouThreshold: 0.5, // ioU threshold for non-max suppression
  scoreThreshold: 0.6 // confidence threshold for predictions.
}

const modelObj = {
  modelParams,
  model: null
}

async function initializeModel() {
  if (modelObj.model) {
    console.log("Model Initialized from cache!")
    return modelObj.model
  }

  if (state.initializing || state.initialized) {
    destroyModel()
  }

  state.initializing = true
  state.destroyed = false

  console.log("initializing model ...")

  let model
  try {
    model = await handTrack.load(modelParams)
    modelObj.model = model
    state.initialized = true
  } catch (e) {
    state.initializing = false
    state.initialized = false
    console.log(e)
  }
  console.log("New Model Initialized!")
  return model
}

async function destroyModel() {
  modelObj.model && modelObj.model?.dispose()
  modelObj.model = null
  state.destroyed = true
  state.initializing = state.initialized = false
}

function loadVideo(video, stream) {
  return new Promise(function (resolve) {
    if (!video || !stream) {
      console.warn("invalid parameters")
      resolve(false)
    }
    video.srcObject = stream
    video.onloadedmetadata = () => {
      console.log("video loaded")
      video.play()
      resolve(true)
    }
  })
}

function loadSvg() {
  return new Promise(resolve => {
    const image = new Image()
    image.src = require("./images/purple-hand.svg")
    image.onload = () => {
      console.log("svg loaded")
      resolve(image)
    }
  })
}

export default {
  state,
  modelObj,
  destroyModel,
  initializeModel,
  loadVideo,
  loadSvg
}
