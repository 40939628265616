var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      d: "M11.763 8.139v-5.3A2.835 2.835 0 008.924 0a2.82 2.82 0 00-2.801 2.508l5.64 5.63zm3.786.378H13.94c0 .55-.095 1.07-.255 1.552l1.201 1.202c.417-.833.663-1.77.663-2.754zM.407 2.148L1.74.814l15.852 15.852L16.259 18l-3.975-3.975a6.748 6.748 0 01-2.413.852v3.104H7.978v-3.104C4.874 14.413 2.3 11.744 2.3 8.517h1.609c0 2.84 2.403 4.827 5.015 4.827.767 0 1.514-.18 2.186-.492L9.54 11.28a2.608 2.608 0 01-.616.075 2.835 2.835 0 01-2.839-2.839v-.69L.407 2.147z",
      "clip-rule": "evenodd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }