import useStore from "@/use/useStore"
import Mission from "@shared/Mission"
import User from "@shared/User"
import { computed, ComputedRef } from "@vue/composition-api"
import { chain } from "lodash"
import _ from "lodash"

export interface Answer {
  url?: string
  drawerID?: string
  drawerTeamID?: string
  text?: string
  writerID?: string
  writerTeamID?: string
}

export default function useTelephonePictionary() {
  const MAX_STEP = 5
  const { store } = useStore()

  const roomId = computed(() => store.state.gameID)

  const viewer = computed(() => store.getters["auth/user"])
  const isHost = computed(() => store.getters["auth/isHost"])
  const isScribe = computed(() => store.getters.isScribe)

  const viewerTeamId = computed(() => {
    if (store.getters["group/canViewerSelectGlobalTeam"]) {
      return store.getters["group/globalTeamID"]
    } else {
      return viewer.value?.teamID
    }
  })

  const mission = computed(() => store.getters.getCurrentMission)
  const isTeamPlay = computed(() => Mission.isTeamPlay(mission.value))
  const missionStep = computed(() => {
    return store.getters.gameStatus.telephonePictionary?.[mission.value.id] || 0
  })
  const isLastStep = computed(() => missionStep.value > MAX_STEP)

  const creatorID = computed(() =>
    isTeamPlay.value ? viewerTeamId.value : viewer.value.id
  )

  const sessionId = computed(() => `${creatorID.value}-${missionStep.value}`)

  const activityId = computed(() => `${roomId.value}${mission.value.id}`)

  const isWriting = computed(() => missionStep.value % 2 === 0)
  const isDrawing = computed(() => missionStep.value !== 0 && !isWriting.value)

  const missionPlays = computed(() =>
    chain(store.getters.missionPlays)
      .sortBy(isTeamPlay.value ? "teamID" : "userID")
      .value()
  )

  const scribes = computed(() => {
    const { moderatorID } = store.getters
    return chain(store.getters.onlineUsersArray)
      .filter(
        user =>
          !User.isSpecialPurposeUser(user) &&
          User.isScribe(user) &&
          User.isPlayer(user) &&
          User.isPlaying(user) &&
          user.id !== moderatorID
      )
      .value()
  })

  const scribesById = computed(() =>
    scribes.value.reduce((acc, scribe) => {
      acc[scribe.id] = scribe
      return acc
    }, {})
  )

  const currentPlay = computed(() => {
    const plays = missionPlays.value
    const step = missionStep.value

    const initialIndex = plays.findIndex(isScribePlay)

    if (initialIndex === -1) {
      return false
    }

    return plays[(initialIndex + step) % plays.length]
  })

  function isScribePlay(play) {
    if (isTeamPlay.value) {
      return play.teamID === viewerTeamId.value
    } else {
      return play.userID === viewer.value?.id
    }
  }

  const answers = computed(() => currentPlay.value?.answer || [])
  const currentAnswer = computed(() => answers.value[missionStep.value] || {})
  const prevAnswer = computed(
    () => answers.value[missionStep.value - 1] || false
  )

  const fakePlays = computed(() =>
    missionPlays.value.map(play => {
      const currentStep = missionStep.value

      const currentAnswer = play.answer[currentStep]

      return { userID: currentAnswer?.userID }
    })
  )

  return {
    isLastStep,
    MAX_STEP,
    scribes,
    viewer,
    roomId,
    isHost,
    mission,
    isScribe,
    creatorID,
    isWriting,
    isDrawing,
    prevAnswer,
    sessionId,
    fakePlays,
    activityId,
    isTeamPlay,
    currentPlay,
    missionStep,
    scribesById,
    missionPlays,
    currentAnswer
  }
}
