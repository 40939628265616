




import Vue from "vue"
import { computed } from "@vue/composition-api"

import useMissions from "@/use/useMissions"

import MatchGameResult from "./MatchGameResult.vue"

import MissionCollection from "@shared/MissionCollection"

export default Vue.extend({
  name: "ImageMatchResult",
  components: { MatchGameResult },
  props: {
    teamID: String
  },
  setup() {
    const { missions } = useMissions()
    const array = computed(() => MissionCollection.normalize(missions.value))
    return { missions: array }
  },
  computed: {
    mission() {
      return this.missions.find(mission => mission.id === this.play.missionID)
    },
    play() {
      return this.$store.getters["missionPlaysArray"].find(
        ({ teamID }) => teamID === this.teamID
      )
    },
    answers() {
      return Array.isArray(this.play?.answer) ? this.play.answer : []
    },
    images() {
      return Object.values(this.mission?.representImages || {})
    },
    options() {
      return Object.values(this.mission?.representImagesPair || {})
    },
    items() {
      const images = this.images
      const answers = this.answers.sort((a, b) => a.index - b.index)
      return this.options.filter(Boolean).map((image, index) => ({
        question: images[index],
        answer: image,
        correct: !!answers[index]?.result
      }))
    }
  }
})
