<template>
  <div class="game-countdown" :class="{ 'game-countdown--host': isHost }">
    <div d-flex @click="toggleInput">
      <countdown
        ref="countdown"
        class="game-countdown__time"
        :class="{
          medium: endGameSeconds >= 60 && endGameSeconds < 599,
          small: endGameSeconds >= 3600 || endGameSeconds > 599
        }"
        :interval="1000"
        :time="endTime"
        v-on="$listeners"
        v-slot="{ hours, minutes, seconds, totalSeconds }"
      >
        <span
          :class="{
            late: totalSeconds === 0,
            alert: totalSeconds <= 10 && totalSeconds > 0
          }"
        >
          <template v-if="hours"> {{ hours }}:</template>
          <template v-if="minutes">
            {{ 10 > minutes ? `${minutes}` : minutes }}:</template
          >{{ 10 > seconds ? `0${seconds}` : seconds }}
        </span>
      </countdown>
    </div>
    <div class="game-countdown__input" v-if="showInput">
      <v-text-field
        v-model="additionalSeconds"
        solo
        placeholder="+ secs"
        hide-details
        @keyup.enter="updateTimerSeconds"
        ref="additionalSeconds"
        @blur="handleInputBlur"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "GameCountdown",
  props: {
    endTime: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      showInput: false,
      additionalSeconds: null,
      firstTime: false,
      isPlayingSound: false
    }
  },
  watch: {
    showInput(value) {
      if (value) {
        setTimeout(() => {
          this.$refs?.additionalSeconds?.focus()
        }, 300)
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters(["gameStatus"]),

    endGameSeconds() {
      return this.endTime / 1000
    }
  },
  methods: {
    start() {
      console.log("Starting countdown from " + this.endTime)
      this.$refs.countdown.start()
    },
    reset() {
      this.$refs.timer.classList.remove("activated")
      this.$refs.timer.classList.remove("late")
    },
    toggleInput() {
      if (!this.isHost) return
      this.showInput = !this.showInput
    },
    async updateTimerSeconds() {
      const milliSeconds = parseInt(this.additionalSeconds) * 1000
      if (milliSeconds) {
        const gameEndTime = parseInt(this.gameStatus.endTime) || 0
        const endTime = gameEndTime - Date.now()
        const time = endTime > 0 ? endTime : 0
        await this.$store.dispatch("updateGameStatusAny", {
          endTime: Date.now() + time + milliSeconds
        })
      }
      this.showInput = false
      this.additionalSeconds = null
    },
    handleInputBlur() {
      setTimeout(() => {
        this.showInput = false
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.game-countdown {
  display: flex;
  position: relative;
  $block: &;

  &__time {
    text-align: left;
    color: #00e599;

    min-width: 100px;
    padding: 2px 10px 2px;
    border-radius: 8px;

    #{$block}--host & {
      cursor: pointer;
    }

    .alert {
      animation-duration: 1.25s;
      animation-iteration-count: infinite;
      animation-name: alert;
    }

    .late {
      color: $color-wrong;
      animation-duration: 1.2s;
      animation-name: late;
      animation-fill-mode: forwards;
    }
  }

  &__input {
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 14px;
    border-radius: 2px;

    .v-text-field.v-text-field--solo .v-input__control {
      min-height: 35%;
      padding: 0;
      height: 22px;
      max-height: 30px;
      width: 74px;
      margin-top: 3px;
      .v-input__slot {
        padding: 0 5px;
      }
    }
  }

  @keyframes alert {
    0% {
      transform: scale(1);
      color: $color-grey-light1;
    }
    50% {
      transform: scale(1.05);
      color: $color-wrong;
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes late {
    0% {
      color: $color-wrong;
    }
    42% {
      color: $color-wrong;
    }
    49% {
      color: $color-white;
    }
    56% {
      color: $color-wrong;
    }
    100% {
      color: $color-wrong;
    }
  }
}
</style>
