<template>
  <div class="fullscreen-toggle" @click.prevent="toggle">
    <SvgIcon name="frame" width="20" height="20" v-if="!$slots.icon" />
    <slot name="icon" v-else />
    <div v-if="isFullscreen" class="rtb-right-toolbar__list-item-title">
      Exit Full Screen
    </div>
    <div v-else class="rtb-right-toolbar__list-item-title">Go Full Screen</div>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "FullscreenToggle",

  data() {
    return {
      isFullscreen: false
    }
  },
  props: ["toggleFullSrceen"],
  mounted() {
    document.addEventListener("fullscreenchange", this.onFullscreenChange)
    this.onFullscreenChange()
  },
  beforeDestroy() {
    document.removeEventListener("fullscreenchange", this.onFullscreenChange)
  },
  methods: {
    toggle() {
      if (document.fullscreenEnabled) {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        } else {
          document.body.requestFullscreen().catch(e => alert(e.message))
        }
        // this.$refs.fullscreen.blur()
      } else {
        alert("Sorry, seems like your browser doesn't support fullscreen mode")
      }
    },
    onFullscreenChange() {
      this.isFullscreen = window.innerHeight == screen.height
      // TODO figure what's wrong here
      this.toggleFullSrceen &&
        typeof this.toggleFullSrceen === "function" &&
        this.toggleFullSrceen(this.isFullscreen)
    }
  }
})
</script>

<style lang="scss">
.fullscreen-toggle {
  display: flex;
  align-items: center;
  flex: 1;
}
</style>
