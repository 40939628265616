










import { directive } from "v-aspect-ratio"
import { defineComponent, toRefs, computed } from "@vue/composition-api"

import User from "@shared/User"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"

import MonitorIcon from "./icons/MonitorIcon"

export default defineComponent({
  name: "PickTeamsUserCard",
  directives: {
    "aspect-ratio": directive
  },
  components: {
    AppUserCard,
    MonitorIcon
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { user } = toRefs(props)
    const conference = computed(() => User.isConference(user.value))
    const ratio = computed(() => (conference.value ? "2:1" : "1:1"))
    return {
      ratio
    }
  }
})
