

































import Vue from "vue"
import { mapGetters } from "vuex"
import truncate from "@/helpers/truncate"

export default Vue.extend({
  name: "FactCard",
  filters: {
    truncate(value, limit) {
      return truncate(value, limit)
    }
  },
  props: {
    selected: {
      type: Boolean,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: undefined
    },
    isImage: {
      type: Boolean,
      default: undefined
    },
    image: {
      type: String,
      default: undefined
    },
    firstName: {
      type: String,
      default: undefined
    },
    correct: {
      type: String,
      default: undefined
    }
  },
  computed: {
    ...mapGetters("Mobile", ["mobile"])
  }
})
