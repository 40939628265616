import User from "@shared/User"

export const NotificationType = {
  SKIP_VIDEO: "skipVideo",
  START_ACTIVITY_SET: "startActivitySet",
  REMOVE_ACTIVITY_SET: "removeActivitySet",
  START_GAME: "startGame",
  BECOME_HOST: "becomeHost",
  LEAVE_HOST: "leaveHost",
  MOVED_TO_MEETING_MODE: "movedToMeetingMode",
  MOVED_TO_PLAY_MODE: "movedToPlayMode",
  TURNED_ON_AUTOPILOT: "turnedOnAutopilot",
  TURNED_OFF_AUTOPILOT: "turnedOffAutopilot",
  MUTED_BY_USER: "mutedByUser",
  UNMUTED_BY_USER: "unmutedByUser",
  UNMUTED_BY_YOU: "unmutedByYou",
  MUTED_BY_YOU: "mutedByYou",
  PINNED_BY_USER: "pinnedByUser",
  UNPINNED_BY_USER: "unpinnedByUser",
  QUESTION_BY_USER: "questionByUser"
}

export const NotificationScope = {
  LOCAL: "local",
  GLOBAL: "global"
}

// TODO
// replace addText with an array of arguments
// use $1 $2 and so on to add arguments to the template messages
// move template messages out of this function body
function getNotificationMessage(args) {
  const {
    notificationBy = {},
    notificationTo = {},
    addText = "",
    type = "",
    local = false
  } = args

  const userNameBy = User.getShortenedName(notificationBy)
  const userNameTo = User.getShortenedName(notificationTo)

  const messages = {
    [NotificationType.SKIP_VIDEO]: `${userNameBy} has skipped the video`,

    [NotificationType.START_ACTIVITY_SET]: `${userNameBy} just started the ${addText} Activity Set`,
    [NotificationType.REMOVE_ACTIVITY_SET]: `${userNameBy} just removed ${addText} Activity set`,

    [NotificationType.START_GAME]: `${userNameBy} started the game`,

    [NotificationType.BECOME_HOST]: `${userNameBy} has become the host`,
    [NotificationType.LEAVE_HOST]: `${userNameBy} is no longer hosting`,

    [NotificationType.MOVED_TO_MEETING_MODE]: `${userNameBy}  moved to Meeting Mode`,
    [NotificationType.MOVED_TO_PLAY_MODE]: `${userNameBy} moved to Play Mode`,

    [NotificationType.TURNED_ON_AUTOPILOT]: `${userNameBy} turned on Auto Pilot`,
    [NotificationType.TURNED_OFF_AUTOPILOT]: `${userNameBy} turned off Auto Pilot`,

    [NotificationType.MUTED_BY_USER]: `You have been muted by ${userNameBy}`,
    [NotificationType.UNMUTED_BY_USER]: `You have been unmuted by ${userNameBy}`,

    [NotificationType.MUTED_BY_YOU]: `You muted ${userNameTo}`,
    [NotificationType.UNMUTED_BY_YOU]: `You unmuted ${userNameTo}`,

    [NotificationType.PINNED_BY_USER]: `${userNameBy} pinned ${addText}'s video`,
    [NotificationType.UNPINNED_BY_USER]: `${userNameBy} unpinned ${addText}'s video`,

    [NotificationType.QUESTION_BY_USER]: `${userNameBy} from ${addText}`
  }

  if (local) {
    return messages[type]
  } else {
    return Object.entries(messages).reduce((acc, [key, message]) => {
      acc[key] = {
        message,
        timestamp: Date.now(),
        notificationBy: notificationBy?.id,
        notificationTo: notificationTo?.id || null
      }
      return acc
    }, {})[type]
  }
}

export const NotificationMixin = {
  methods: {
    // TODO
    // simplify input
    async $_NotificationMixin_send({
      notificationTo,
      addText,
      type,
      scope,
      message
    }) {
      const user = this.$store.getters.user
      const isHost = this.$store.getters["auth/isHost"]
      const isModerator = this.$store.getters["auth/isModerator"]

      if (isHost && !isModerator) return

      if (scope === NotificationScope.LOCAL) {
        this.$toast(
          message ||
            getNotificationMessage({
              local: true,
              type,
              notificationBy: user,
              ...(notificationTo && { notificationTo }),
              ...(addText && { addText })
            }),
          { autoHideDelay: 5000 }
        )
      } else if (scope === NotificationScope.GLOBAL) {
        await this.$store.dispatch("updateGameStatusAny", {
          notification: getNotificationMessage({
            type,
            notificationBy: user,
            ...(notificationTo && { notificationTo }),
            ...(addText && { addText })
          })
        })
      }
    }
  }
}
