var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fact-match-player",
      class: { "fact-match-player--muted": _vm.isMuted },
    },
    [
      _c(
        "v-flex",
        { staticClass: "fact-match-player__cnt" },
        [
          _c(
            "RatioContainer",
            { staticClass: "fact-match-player__ratio-container" },
            [
              !!_vm.teamIcon
                ? _c(
                    "v-icon",
                    {
                      staticClass: "fact-match-player__team-icon",
                      attrs: { dark: "" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.teamIcon || "radio_button_unchecked") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.imageOnly
                ? _c("UserImage", { attrs: { imageUrl: _vm.imageURL } })
                : [
                    _c("UserMedia", {
                      attrs: { identity: _vm.userID, imageUrl: _vm.imageURL },
                    }),
                    _c(
                      "div",
                      { staticClass: "fact-match-player__name" },
                      [_c("UserName", { attrs: { firstname: _vm.firstname } })],
                      1
                    ),
                  ],
              _vm.isHost
                ? _c("div", { staticClass: "fact-match-player__controls" }, [
                    _c(
                      "div",
                      {
                        staticClass: "fact-match-player__control",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            ) {
                              return null
                            }
                            return _vm.toggleAudio.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("SvgIcon", {
                          attrs: {
                            name: _vm.isMuted ? "mute-mic" : "mic",
                            title: _vm.isMuted ? "umnute" : "mute",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }