var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WeveDialog",
    {
      attrs: { "dialog-class": "pick-teams-dialog", title: "Edit Teams" },
      model: {
        value: _vm.state.open,
        callback: function ($$v) {
          _vm.$set(_vm.state, "open", $$v)
        },
        expression: "state.open",
      },
    },
    [
      _c("TeamManager", {
        attrs: {
          navigation: false,
          externalGameID: _vm.gameID,
          externalUsers: _vm.users,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }