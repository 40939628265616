var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.ready
      ? _c(
          "div",
          { staticClass: "form-loader-wrapper" },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", color: "#F5665D", size: "80" },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.mode === "login"
              ? _c("EmailForm", {
                  attrs: {
                    id: _vm.id,
                    enterLoginText: _vm.session && _vm.session.enterLoginText,
                    setForgotPassword: function (newMode) {
                      _vm.mode = "forgot-password"
                    },
                  },
                })
              : _vm.mode === "anonymous"
              ? _c("AnonymousForm", {
                  attrs: {
                    id: _vm.id,
                    customInputType: _vm.customInputType,
                    customInputOptions: _vm.customInputOptions,
                    customInputLabel: _vm.customInputLabel,
                    enterLoginText: _vm.session && _vm.session.enterLoginText,
                    conferenceLogin: _vm.session && _vm.session.conferenceLogin,
                  },
                })
              : _vm.mode === _vm.modes.EmailAndNames
              ? _c("EmailCSVForm", {
                  attrs: { id: _vm.id, client: _vm.session },
                })
              : _vm.mode === "forgot-password"
              ? _c("ForgotPassword", {
                  attrs: {
                    setLogin: function () {
                      _vm.mode = "login"
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }