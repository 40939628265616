
import Vue from "vue"
import type { WithRefs } from "vue-typed-refs"
import shortid from "shortid"

import SvgIcon from "../../base/SvgIcon.vue"

type Refs = {
  input: HTMLInputElement
}

export default (Vue as WithRefs<Refs>).extend({
  name: "RTBCheckbox",
  inheritAttrs: false,
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
    checked: {
      type: Boolean
    },
    id: {
      type: String,
      default() {
        return shortid.generate()
      }
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    },
    hideLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputListeners(): Record<string, Function | Function[]> {
      return {
        ...this.$listeners,
        change: (event: InputEvent) => {
          this.$emit("change", (event.target as HTMLInputElement).checked)
        }
      }
    }
  },
  render(h) {
    return h(
      "div",
      {
        class: { "rtb-checkbox--disabled": this.disabled },
        staticClass: "rtb-checkbox"
      },
      [
        h("input", {
          attrs: {
            ...this.$attrs,
            id: this.id,
            type: "checkbox",
            disabled: this.disabled
          },
          domProps: {
            checked: this.checked
          },
          staticClass: "rtb-checkbox__input",
          on: this.inputListeners
        }),
        h(
          "label",
          { attrs: { for: this.id }, staticClass: "rtb-checkbox__label" },
          [
            h("span", { staticClass: "rtb-checkbox__imitator" }, [
              h(SvgIcon, { props: { name: "check-regular" } })
            ]),
            h(
              "span",
              {
                class: { "sr-only": this.hideLabel },
                staticClass: "rtb-checkbox__label-text"
              },
              this.label
            )
          ]
        ),
        this.$slots.help
      ]
    )
  }
})
