import { cloneDeep } from "lodash"

const CHAT_CONTEXTS = {
  LOBBY: "lobby-chat",
  LOBBY_HOST: "lobby-host-chat",
  GAME: "game-chat",
  TEAM: "team-chat",
  STAFF: "lobby-staff-chat"
}

const LAZYLOAD_MESSAGE_LIMIT = 20
const CHAT_MESSAGES_FETCH_LIMIT = 40
const INPUT_HEIGHT = 47

const ALL_CHAT_CONTEXT = cloneDeep([
  {
    name: "Team",
    value: CHAT_CONTEXTS.TEAM,
    pill: "",
    subscription: null,
    unsubscribe: null,
    ref: null
  },
  {
    name: "Room",
    value: CHAT_CONTEXTS.GAME,
    pill: "",
    subscription: null,
    unsubscribe: null,
    ref: null
  },
  {
    name: "Lobby",
    value: CHAT_CONTEXTS.LOBBY,
    pill: "",
    subscription: null,
    unsubscribe: null,
    ref: null
  },
  {
    name: "Staff",
    value: CHAT_CONTEXTS.STAFF,
    pill: "",
    subscription: null,
    unsubscribe: null,
    ref: null
  }
])

export {
  CHAT_CONTEXTS,
  LAZYLOAD_MESSAGE_LIMIT,
  CHAT_MESSAGES_FETCH_LIMIT,
  INPUT_HEIGHT,
  ALL_CHAT_CONTEXT
}
