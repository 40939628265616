<template>
  <ToolbarDropdown
    vIcon="mood"
    title="React"
    :value="value"
    @input="onInput($event)"
    class="reactions"
  >
    <div
      class="reactions__list"
      v-for="(group, i) in reactions"
      :key="`${i}-group`"
    >
      <div class="reactions__item" v-for="icon in group" :key="icon">
        <RtbControlButton
          class="reactions__item-btn reactions__item-btn--square"
          :unfocusable="false"
          sm
          @click="react(icon)"
          @click.shift="react(icon, true)"
        >
          <SvgIcon folder="reactions" :name="icon" width="24" height="24" />
        </RtbControlButton>
      </div>
    </div>

    <RtbControlButton
      v-if="!isLobbyPage"
      sm
      class="reactions__item-btn mx-auto"
      :unfocusable="false"
      @click="toggleRaisedHand"
    >
      <SvgIcon
        folder="reactions"
        class="mr-2"
        :name="isRisedHand ? 'lower-hand' : 'raise-hand'"
        width="30"
        height="30"
      />
      {{ isRisedHand ? "Lower " : "Raise " }}
      hand
    </RtbControlButton>
  </ToolbarDropdown>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import sleep from "@shared/helpers/sleep"
import ToolbarDropdown from "../ToolbarDropdown.vue"
import { RtbControlButton } from "@/components/ui"
import reactions from "./reactions"

export default Vue.extend({
  name: "ReactionsMenu",
  components: { ToolbarDropdown, RtbControlButton },
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: [String, Boolean],
      default: false
    }
  },
  data() {
    return {
      reactions,
      timeoutID: null
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "clientID", "client"]),
    ...mapGetters(["gameID", "orgID"]),
    ...mapGetters({
      currentMissionID: "currentMission"
    }),
    isLobbyPage() {
      return ["pregame", "lobby"].includes(this.$route.name)
    },
    isRisedHand() {
      return this.user?.raisehand?.missionID === this.currentMissionID
    }
  },
  methods: {
    async react(name, foo) {
      if (this.isLobbyPage) {
        if (foo) {
          for (let i = 0; i < 30; i++) {
            await sleep(500)
            const reactionPayload = {
              name,
              timestamp: Date.now()
            }
            await this.reactToStream(reactionPayload)
          }
        } else {
          const reactionPayload = {
            name,
            timestamp: Date.now()
          }
          await this.reactToStream(reactionPayload)
        }
      } else {
        await this.reactToItself({
          reaction: {
            name,
            timestamp: Date.now()
          }
        })
      }
    },
    reactToItself(obj) {
      return db.auxiliary().ref(`org/1/users/${this.user?.id}`).update(obj)
    },
    reactToStream(reaction = null) {
      const user = this.user
      const path = `client/${this.clientID}/_calls/rooms/${this.client?.stream?.roomID}/reaction`
      const obj = {
        user: {
          reaction,
          id: user.id,
          firstname: user.firstname || "",
          lastname: user.lastname || "",
          image: user.image || null
        }
      }
      return db.auxiliary().ref(path).push(obj)
    },
    toggleRaisedHand() {
      this.reactToItself({
        raisehand: !this.isRisedHand
          ? { missionID: this.currentMissionID, timestamp: Date.now() }
          : null
      })
    },
    onInput($event) {
      this.$emit("input", $event)
    }
  }
})
</script>

<style lang="scss" scoped>
.reactions {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 12px;
    padding-top: 12px;
    justify-content: center;
  }

  &__item {
    padding-left: 8px;
    padding-right: 8px;
  }

  &__item-btn {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    white-space: nowrap;

    &--square {
      padding: 0;
      width: 32px;
    }

    &:hover {
      background-color: #586475;
    }

    &:active {
      background-color: #798294;
    }
  }
}
</style>
