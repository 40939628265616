export enum Module {
  CATEGORIES = "categories",
  QUESTIONS = "questions",
  ANSWERS = "answers",
  FINALE = "finale",
  RESULTS = "results",
  WRAPUP = "wrapup"
}

export enum Mode {
  INFO = "info",
  SUBMIT = "submit",
  RESULT = "result"
}

export enum UserStatus {
  CORRECT = "correct",
  WRONG = "wrong",
  PENDING = "pending",
  ACTIVE = "active",
  WAITING = "waiting"
}

export enum AnswersMode {
  FINALE = "finale",
  NORMAL = "normal"
}

export enum JobType {
  UPDATE_CATEGORY_QUESTIONS = "update-category-questions",
  UPDATE_CATEGORY_QUESTION = "update-category-question"
}

export enum JobStatus {
  FAILURE = "failure",
  SUCCESS = "success"
}
