
import Vue, { PropType } from "vue"
import { mergeData } from "vue-functional-data-merge"

import { gaps } from "./types"

export default Vue.extend({
  name: "Row",
  functional: true,
  props: {
    gap: {
      type: String as PropType<typeof gaps[number]>,
      default: "default"
    },
    tag: {
      type: String,
      default: "div"
    }
  },
  render(h, { props, children, data }) {
    return h(
      props.tag,
      mergeData(data, {
        class: { [`rtb-row--gap--${props.gap}`]: props.gap },
        staticClass: "rtb-row"
      }),
      children
    )
  }
})
