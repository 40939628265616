import TipJar from "@shared/enums/TipJar"
import { isTipJarEnabled } from "@/modules/tipjar/util"
import User from "@shared/User"
import Mode from "@shared/enums/Mode"
import Mission from "@shared/enums/Mission"

const TIP_JAR_MODES = [Mode.Over, Mode.Voting, Mode.Social, Mode.Meeting]

export default {
  data() {
    return {
      TipJarMixin_isGameHostOffline: false
    }
  },
  computed: {
    $_TipJarMixin_isGameOver() {
      const mission = this.$store.getters.getCurrentMission
      return Boolean(
        (mission && String(mission.name).indexOf("Game Over") > -1) ||
          mission?.behavior === Mission.GameOver
      )
    },
    $_TipJarMixin_isGameHostOnline() {
      const user = this.$store.getters.gameHost
      return Boolean(
        this.$store.getters.isUserAlive(user) &&
          (User.isPlaying(user) || User.isSelectingTeam(user))
      )
    },
    $_TipJarMixin_shouldTipBeVisible() {
      const game = this.$store.getters.game

      if (game.showTipJar === TipJar.ON) {
        return true
      } else if (
        game.showTipJar === TipJar.AUTO &&
        this.TipJarMixin_isGameHostOffline
      ) {
        return true
      }
      return false
    },
    $_TipJarMixin_isTipJarVisible() {
      const mode = this.$store.getters.getCurrentMode
      const game = this.$store.getters.game
      const client = this.$store.getters["auth/client"]
      const gameHost = this.$store.getters.gameHost
      return Boolean(
        isTipJarEnabled(client, game, gameHost) &&
          this.$_TipJarMixin_shouldTipBeVisible &&
          TIP_JAR_MODES.includes(mode)
      )
    }
  },
  watch: {
    $_TipJarMixin_isGameHostOnline: {
      handler(value) {
        if (!value) {
          this._timeout = setTimeout(() => {
            this.TipJarMixin_isGameHostOffline = true
          }, 10000)
        } else {
          this.TipJarMixin_isGameHostOffline = false
          clearTimeout(this._timeout)
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    clearTimeout(this._timeout)
  }
}
