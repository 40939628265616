enum GetterTypes {
  THEMES = "THEMES",
  STYLES = "STYLES",
  IMAGES = "IMAGES",
  META = "META",
  NORMALIZED_THEMES = "NORMALIZED_THEMES",
  __CURRENT_THEME__ = "__CURRENT_THEME__"
}

export default GetterTypes
