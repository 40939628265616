import { computed } from "@vue/composition-api"
import useStore from "./useStore"

export default function useEnlargedUI() {
  const { store } = useStore()
  const isViewerPresenter = computed(
    () => store.getters["group/isViewerPresenter"]
  )
  const enlargedUI = computed(
    () => store.getters.gameStatus?.enlargedUI && isViewerPresenter.value
  )

  return { enlargedUI, isViewerPresenter }
}
