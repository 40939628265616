<template>
  <v-flex d-flex class="im-result">
    <transition-group
      name="fact-match-result-transition"
      tag="div"
      class="im-result__row flex d-flex justify-center"
    >
      <div
        class="im-result__column"
        v-for="(item, index) in items"
        :key="`fact-match-player-${index}`"
      >
        <MatchGameCard :imageURL="item.question" />
        <div v-if="textify" style="position: relative">
          <FactCard :correct="item.answer" />
          <SvgIcon
            class="im-result__icon"
            size="xl"
            style="top: -30px"
            :name="`match-${item.correct ? 'correct' : 'wrong'}`"
          />
        </div>
        <MatchGameCard v-else :imageURL="item.answer" :bordered="false">
          <SvgIcon
            class="im-result__icon"
            size="xl"
            :name="`match-${item.correct ? 'correct' : 'wrong'}`"
          />
        </MatchGameCard>
      </div>
    </transition-group>
  </v-flex>
</template>

<script>
import FactMatchPlayer from "@/components/GroupTeams/Common/Player/FactMatchPlayer.vue"
import FactCard from "./GameModes/Social/Parts/FactCard.vue"

import { toRefs, computed } from "@vue/composition-api"

export default {
  name: "MatchGameResult",
  components: {
    MatchGameCard: () =>
      import("@/components/GroupTeams/Common/Player/MatchGameCard.vue"),
    FactMatchPlayer,
    FactCard
  },
  props: {
    items: Array
  },
  setup(props) {
    const { items } = toRefs(props)

    function isValidHttpUrl(string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }
      return url.protocol === "http:" || url.protocol === "https:"
    }

    const textify = computed(() => {
      return !isValidHttpUrl(items.value[0]?.answer)
    })

    return { textify }
  }
}
</script>

<style lang="scss">
.im-result {
  margin-top: 4px;
  margin-bottom: 16px;
  width: 100%;
  justify-content: center;

  &__row {
    max-width: 1100px;
  }

  &__column {
    width: (100% / 6);
    max-width: 20vh;
    display: flex;
    flex-direction: column;
    opacity: 1;
    transform: scale(1);
    transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.25s;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @keyframes fact-result {
      from {
        width: 0px;
        height: 0px;
        opacity: 1;
        animation-timing-function: ease;
      }
      30% {
        width: 115px;
        height: 115px;
        opacity: 0.5;
        animation-timing-function: ease-out;
      }
      to {
        width: 50px;
        height: 50px;
        opacity: 1;
      }
    }
    animation: fact-result 1.5s ease 0s 1 normal both;
  }

  .fact-match-result-transition-enter-active {
    transition: all 0.5s ease 0s;
  }

  .fact-match-result-transition-leave-active {
    transition: all 0.5s ease 0.6s;
    .im-result__column {
      transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    }
  }

  .fact-match-result-transition-enter,
  .fact-match-result-transition-leave-to {
    min-width: 0 !important;
    max-width: 0 !important;
    margin: 0 !important;
    .im-result__column {
      opacity: 0;
      transform: scale(0.3);
    }
  }

  .fact-match-result-transition-enter-active {
    transition: all 0.5s ease 0s;
  }

  .fact-match-result-transition-leave-active {
    transition: all 0.5s ease 0.6s;
    .im-result__column {
      transition: all 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    }
  }

  .fact-match-result-transition-enter,
  .fact-match-result-transition-leave-to {
    min-width: 0 !important;
    max-width: 0 !important;
    margin: 0 !important;
    .im-result__column {
      opacity: 0;
      transform: scale(0.3);
    }
  }
}
</style>
