<template>
  <div
    v-if="!!team"
    class="team-tag"
    :class="computedClass"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div
      class="team-tag__icon mr-2"
      :class="{
        ['team-tag__icon--special-purpose']: isSpecialPurpose
      }"
    >
      <template v-if="isViewerHostLike && pinnable">
        <template v-if="hovered || isPinnedTeam">
          <SvgIcon
            v-if="isPinnedTeam"
            width="16"
            height="16"
            name="unpin"
            @click="unpin()"
            class="team-tag__pin-icon team-tag__pin-icon--unpin"
          />
          <SvgIcon
            v-else
            width="16"
            height="16"
            name="pin"
            @click="pin()"
            class="team-tag__pin-icon team-tag__pin-icon--pin"
          />
        </template>
        <v-icon style="cursor: pointer" @click.exact="resetMissionTeam()">
          {{ team.icon }}
        </v-icon>
      </template>
      <template v-else>
        <v-icon>
          {{ team.icon }}
        </v-icon>
      </template>
    </div>

    <template v-if="isTeamSelectable">
      <div
        class="team-tag__title mr-2"
        :class="{
          ['team-tag__title--special-purpose']: isSpecialPurpose
        }"
        style="cursor: pointer"
        @click="select()"
      >
        {{ team.name }}
      </div>
    </template>

    <template v-else>
      <div
        class="team-tag__title mr-2"
        :class="{
          ['team-tag__title--special-purpose']: isSpecialPurpose
        }"
      >
        {{ team.name }}
      </div>
    </template>

    <transition name="team-tag__scale-in-transition" mode="out-in">
      <div
        class="team-tag__tries"
        :key="`strike-${nOfStrikes}`"
        v-if="!!nOfStrikes"
      >
        <SvgIcon
          v-for="strike in strikes"
          :key="strike.key"
          :name="strike.name"
          :style="strike.color ? `color: ${strike.color}` : null"
          width="13"
          height="13"
        />
      </div>
    </transition>

    <template v-if="!isSpecialPurpose && score">
      <div
        class="team-tag__points"
        v-if="isViewerHostLike"
        @click.exact="increaseScore()"
        @click.alt="decreaseScore()"
        @click.ctrl="decreaseScore()"
        @click.shift="toggleFineTunePoints()"
      >
        {{ teamScore }}<span class="team-tag__points__pts">PTS</span>
      </div>
      <template v-if="fineTunePoints">
        <span
          class="team-tag__minus team-tag__control"
          @click="decreaseScore(1)"
        />

        <span
          class="team-tag__plus team-tag__control"
          @click="increaseScore(1)"
        />
      </template>
    </template>

    <span
      class="team-tag__submitted"
      v-if="submittedIndictor && isTeamSubmitted"
    >
      <StarIcon />
    </span>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import { computed } from "@vue/composition-api"
import MissionType from "@shared/enums/Mission"
import Mode from "@shared/enums/Mode"
import User from "@shared/User"
import Mission from "@shared/Mission"
import Team from "@shared/Team"
import TeamService from "@/services/team.service"
import SvgIcon from "@/components/base/SvgIcon.vue"
import useCategories from "@/components/GroupTeams/Common/Games/Categories/useCategories"
import useStore from "@/use/useStore"
import StarIcon from "@/components/UserCard/icons/StarIcon"

export default {
  name: "TeamTag",
  components: {
    SvgIcon,
    StarIcon
  },
  props: {
    score: {
      type: Boolean,
      default: true
    },
    pinnable: {
      type: Boolean,
      default: true
    },
    team: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      default: Mode.Info
    },
    submittedIndictor: {
      type: Boolean
    }
  },
  setup() {
    const { store } = useStore()
    const mission = computed(() => store.getters.getCurrentMission)

    const { answers: cateroiesAnswers } = useCategories(mission)
    return {
      cateroiesAnswers
    }
  },
  data() {
    return {
      hovered: false,
      fineTunePoints: false,
      colorOverride: null,
      timeout: null
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission",
      missionID: "currentMission",
      playType: "missionPlayType",
      isUnlimited: "isUnlimitedMission"
    }),
    ...mapGetters([
      "nOfMissionTries",
      "missionPlaysArray",
      "missionHintsArray",
      "onlineUsersGroupedByTeam",
      "game",
      "styles",
      "gameStatus"
    ]),
    isViewerHostLike() {
      return this.$store.getters["group/isViewerHostLike"]
    },
    isSpecialPurpose() {
      return Team.isSpecialPurpose(this.team)
    },
    isPinnedTeam() {
      return this.game?.pinnedTeamID === this.team?.id
    },
    nOfStrikes() {
      return this.strikes.length
    },
    strikes() {
      const numOfTries = parseInt(this.nOfMissionTries) || 0

      if (
        numOfTries < 2 ||
        this.isUnlimited ||
        this.mode === Mode.Welcome ||
        this.behavior === MissionType.Info
      )
        return []

      const array = []
      const nOfIncorrectPlays = Math.min(numOfTries, this.nOfIncorrectPlays)
      const missionID = this.missionID
      const teamID = this.team?.id

      let i
      let j = 0
      const wrongColor = this.wrongStrikeColor

      // so if any incorrect set them as red
      for (i = 0; i < nOfIncorrectPlays; i++, j++)
        array.push({
          color: wrongColor,
          key: `${j}-${missionID}-${teamID}-${0}`,
          missionID,
          name: "team-tag-1"
        })

      let diff

      if (this.isCorrect) {
        j++
        diff = numOfTries - nOfIncorrectPlays - 1
        array.push({
          key: `${j}-${missionID}-${teamID}-${2}`,
          missionID,
          name: "team-tag-2"
        })
      } else {
        diff = numOfTries - nOfIncorrectPlays
      }

      // any remaining get the default grey
      for (i = 0; i < diff; i++, j++)
        array.push({
          key: `${j}-${missionID}-${teamID}-${1}`,
          missionID,
          name: "team-tag-0"
        })

      return array
    },
    teamScore() {
      return parseInt(this.team?.totalScore) || 0
    },
    behavior() {
      return this.mission?.behavior
    },
    viewer() {
      return this.$store.state.auth.user
    },
    users() {
      const onlineUsersGroupedByTeam = this.onlineUsersGroupedByTeam || {}
      return onlineUsersGroupedByTeam[this.team?.id] || []
    },
    selectedUsers() {
      return this.users.filter(({ selected }) => selected)
    },
    nOfSelectedUsers() {
      return this.selectedUsers.length
    },
    answers() {
      if (this.isUnlimited) return []
      return this.plays.reduce((acc, val) => {
        const { answer } = val
        if (Array.isArray(answer)) {
          return acc.concat(answer)
        } else {
          acc.push(answer)
          return acc
        }
      }, [])
    },
    isCompleted() {
      return this.isOutOfTries || this.isMissionCorrect
    },
    isOutOfTries() {
      return this.answers.length >= this.nOfMissionTries
    },
    plays() {
      const teamID = this.team?.id
      if (this.mission?.behavior == MissionType.TwoTruthsReveal) {
        const twoTruthsID = this.gameStatus.twoTruthsID
        return this.missionPlaysArray.filter(
          play => play.teamID === teamID && twoTruthsID == play.twoTruthsID
        )
      }

      return this.missionPlaysArray.filter(play => play.teamID === teamID)
    },
    hints() {
      const teamID = this.team?.id
      return this.missionHintsArray.filter(play => play.teamID === teamID)
    },
    nOfPlays() {
      return this.plays.length
    },
    isTeamSubmitted() {
      return this.nOfSelectedUsers && this.nOfPlays >= this.nOfSelectedUsers
    },
    nOfExpectedPlays() {
      if (this.isCategories) return this.cateroiesAnswers.length

      return this.nOfSelectedUsers
    },
    isMissionCorrect() {
      if (this.isPaused) {
        return false
      }

      if (this.isFactMatch) {
        return this.nOfPlays > 0
      }

      if (this.isIndividualPictionary) {
        return this.nOfCorrectPlays > 0
      }

      if (this.nOfSelectedUsers > 1) {
        return (
          this.nOfCorrectPlays > 0 &&
          this.nOfCorrectPlays === this.nOfExpectedPlays
        )
      }

      if (this.isCategories) {
        return this.nOfCorrectPlays === this.nOfExpectedPlays
      }

      return this.nOfCorrectPlays > 0
    },
    correctPlays() {
      return this.plays.filter(({ result }) => result)
    },
    nOfCorrectPlays() {
      return this.correctPlays.length
    },
    isIndividualPictionary() {
      return (
        this.behavior === MissionType.DrawingPictionary &&
        !Mission.isTeamPlay(this.mission)
      )
    },
    isFactMatch() {
      return [
        MissionType.FactMatch,
        MissionType.MatchGame,
        MissionType.OrderTheCards
      ].includes(this.behavior)
    },
    isCategories() {
      return this.behavior === MissionType.Categories
    },
    nOfIncorrectPlays() {
      if (
        this.isPaused ||
        this.isFactMatch ||
        [
          MissionType.TelephonePictionary,
          MissionType.WordCloud,
          MissionType.QuestionCards
        ].includes(this.behavior)
      ) {
        return 0
      }

      return this.plays.reduce((acc, val) => {
        const { result, answer } = val
        if (Array.isArray(answer)) {
          if (result) {
            return acc + answer.length - 1
          } else {
            return acc + answer.length
          }
        } else {
          if (result) {
            return acc
          } else {
            return acc + 1
          }
        }
      }, 0)
    },
    isPaused() {
      return (
        this.$store.getters["auth/isHybridRoom"] &&
        Mission.isPausable(this.mission) &&
        this.gameStatus?.paused !== true
      )
    },
    isCorrect() {
      return this.isMissionCorrect && this.isCompleted
    },
    isWrong() {
      if (this.isPaused) return false

      return (
        !this.isUnlimited &&
        this.isOutOfTries &&
        !this.isMissionCorrect &&
        this.nOfExpectedPlays > 0 &&
        (this.nOfCorrectPlays === this.nOfExpectedPlays ||
          this.nOfIncorrectPlays >= this.nOfExpectedPlays)
      )
    },
    isTeamSelectable() {
      if (
        this.behavior === MissionType.DiscussionGroup &&
        this.mode === Mode.Play &&
        this.isViewerHostLike &&
        this.viewer.teamID != null
      )
        return false

      return this.$store.getters["group/canViewerSelectGlobalTeam"]
    },
    wrongStrikeColor() {
      return !this.variant && this.$theme.get("--wrong-color")
    },
    variant() {
      if (
        this.behavior === MissionType.FamilyFeud ||
        this.mission.karaokePowerpoint ||
        this.mission.rightWrongIndicationOff
      ) {
        return
      }

      if (this.colorOverride) {
        return this.colorOverride
      }

      if (this.isMissionCorrect) {
        return "success"
      }

      if (this.isWrong) {
        return "danger"
      }

      return null
    },
    computedClass() {
      return {
        [`team-tag--${this.variant}`]: !!this.variant,
        [`team-tag--usable`]: this.isTeamSelectable,
        [`team-tag--small`]: this.team?.name?.length > 15
      }
    },
    isPresenter() {
      return User.isPresenter(this.viewer)
    }
  },
  watch: {
    missionID() {
      this.fineTunePoints = false
    },
    nOfIncorrectPlays(newValue, oldValue) {
      if (newValue === 0) return 0
      if (newValue <= oldValue) return 0
      if (this.isCorrect) return 0
      this.colorOverride = "danger"
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.colorOverride = null
      }, 800)
    },
    nOfCorrectPlays(newValue, oldValue) {
      if (
        this.isPresenter ||
        newValue === 0 ||
        newValue <= oldValue ||
        this.isCorrect
      )
        return 0

      this.colorOverride = "success"
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.colorOverride = null
      }, 800)
    }
  },
  methods: {
    ...mapActions("group", ["updateCurrentGlobalTeam"]),
    onMouseEnter() {
      this.hovered = true
    },
    onMouseLeave() {
      this.hovered = false
    },
    pin() {
      TeamService.pin(this.team?.id)
    },
    unpin() {
      TeamService.unpin()
    },
    select() {
      this.updateCurrentGlobalTeam(this.team?.id)
    },
    toggleFineTunePoints() {
      this.fineTunePoints = !this.fineTunePoints
    },
    resetMissionTeam() {
      const plays = [...this.plays, ...this.hints]
      for (const play of plays) {
        play && play.id && this.$store.dispatch("deletePlay", play.id)
      }
    },
    increaseScore(amount) {
      if (this.isViewerHostLike) {
        var num = this.team.totalScore + parseInt(this.mission.points / 2)
        if (amount) num = this.team.totalScore + amount
        if (num > 1000000) return
        if (isNaN(num)) return

        this.$store.dispatch("setTeamScore", {
          teamScore: num,
          teamID: this.team.id
        })
      }
    },
    decreaseScore(amount) {
      if (this.isViewerHostLike) {
        var num = this.team.totalScore - parseInt(this.mission.points / 2)
        if (amount) num = this.team.totalScore - amount
        if (num < 0) return
        if (isNaN(num)) return

        this.$store.dispatch("setTeamScore", {
          teamScore: num,
          teamID: this.team.id
        })
      }
    }
  }
}
</script>

<style lang="scss">
.team-tag {
  $root: &;

  background-color: currentColor;
  color: #292932;
  display: flex;
  z-index: 10;
  position: relative;
  font-size: 18px;
  border-radius: 20px;
  // overflow: hidden;
  transition: all 0.3s;
  align-items: center;
  min-height: 32px;
  user-select: none;
  padding: 8px 18px;

  &__submitted {
    background-color: $primary_accent_color;
    color: $color-white;
    position: absolute;
    top: 50%;
    left: calc(100% + 4px);
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    justify-content: center;

    svg {
      fill: $color-white;
      width: 16px;
      height: 16px;
    }
  }

  &__pin-icon {
    color: #fff;
    margin-right: 8px;
    cursor: pointer;
    &--unpin {
      position: relative;
      left: 1px;
    }
  }

  &--usable:hover {
    filter: brightness(1.5);
  }

  &--success {
    color: $correct_color;
  }

  &--danger {
    color: $wrong_color;
  }

  &__title {
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    max-width: 190px;
    color: #fff;
    font-weight: 900;
    margin-bottom: 2px;
    line-height: 100%;

    &--special-purpose {
      color: $secondary_accent_color;
    }
  }

  &--small {
    #{$root}__title {
      font-size: 70%;
      margin-bottom: 0;
      line-height: 110%;
      word-break: break-word;
    }
  }

  &__points {
    color: #fff;
    cursor: pointer;
    font-weight: 900;
    margin-bottom: 2px;
    &__pts {
      top: -0.65em;
      line-height: 0;
      position: relative;
      font-size: 60%;
    }
  }
  &__tries {
    transform: translateZ(0);
    display: flex;
    max-width: 100px;
    box-sizing: border-box;
    color: #fff;
    & > :not(:last-child) {
      margin-right: 5px;
    }
    & > :last-child {
      margin-right: 8px;
    }
  }
  &__icon {
    display: flex;
    i {
      color: #fff !important;
      font-size: 110%;
    }

    &--special-purpose {
      i {
        color: $secondary_accent_color !important;
      }
    }
  }
  &__scale-in-transition {
    &-enter-active {
      transition: all ease 0.6s;
      max-width: 0px;
    }
    &-leave-active {
      transition: all ease 0.6s;
      max-width: 100;
    }
    &-enter-to {
      opacity: 1;
      max-width: 100px;
    }
    &-leave-to {
      opacity: 0;
      max-width: 0px;
    }
  }

  &__control {
    @include box(16px);
    border-radius: 50%;
    background-color: $color-white;
    position: relative;
    cursor: pointer;

    &::after,
    &::before {
      content: "";
      display: block;
      width: 10px;
      height: 2px;
      background-color: currentColor;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__minus {
    color: $color-wrong;
    margin-right: 5px;
    margin-left: 8px;
  }

  &__plus {
    color: $color-correct;

    &::before {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
</style>
