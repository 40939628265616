
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"
import mixins from "vue-typed-mixins"

import { TaggableMixinFactory } from "../mixins"

export default Vue.extend(TaggableMixinFactory.create()).extend({
  name: "FormControl",
  functional: true,
  render(h, { props, children, data }) {
    // TODO(Andrew):
    return h(
      // @ts-expect-error
      props.tag,
      mergeData(data, { staticClass: "rtb-form-control" }),
      children
    )
  }
})
