


















import Vue from "vue"
import { stream, state } from "../index"
import isVideo from "is-video"

export default Vue.extend({
  name: "Media",
  props: {
    volume: Number,
    paused: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isVideo
    }
  },
  computed: {
    isSmartMediaReady() {
      return Boolean(state.ready)
    }
  },
  mounted() {
    this.$watch(
      "isSmartMediaReady",
      value => {
        if (!value) return 0
        this.$nextTick(() => {
          const $el = this.$el
          stream($el, this.volume, !this.paused)
          this.$emit("ready", $el)
          this.$watch(
            "paused",
            value => {
              if (value) {
                $el.pause()
              } else {
                $el.play()
              }
            },
            { immediate: true }
          )
        })
      },
      { immediate: true }
    )
  },
  watch: {
    volume(value) {
      this.$el.volume = value
    }
  }
})
