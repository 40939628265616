var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-hover", {
    attrs: {
      disabled: _vm.settingsBar || _vm.audioDrawer,
      "open-delay": _vm.OPEN_DELAY,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var hover = ref.hover
            return _c(
              "v-navigation-drawer",
              {
                staticClass: "rtb-right-toolbar",
                class: {
                  "rtb-right-toolbar--is-open": _vm.mobile || hover,
                  "rtb-right-toolbar--with-mini-topbar":
                    _vm.isMeeting && (_vm.isHost || _vm.isDIYGame),
                  "rtb-right-toolbar--with-mini-topbar-auditor":
                    _vm.isMeeting && _vm.isModerator,
                },
                attrs: {
                  fixed: _vm.fixed,
                  absolute: _vm.absolute,
                  floating: "",
                  right: !_vm.left,
                  left: _vm.left,
                  height: _vm.height,
                  dark: "",
                  "mini-variant": _vm.mobile ? false : !hover,
                  "mini-variant-width": _vm.miniVariantWidth,
                  "hide-overlay": "",
                  width: _vm.width,
                  permanent: !_vm.mobile,
                  temporary: _vm.mobile,
                },
                model: {
                  value: _vm.isOpen,
                  callback: function ($$v) {
                    _vm.isOpen = $$v
                  },
                  expression: "isOpen",
                },
              },
              [
                _c("div", { staticClass: "rtb-right-toolbar__cnt" }, [
                  _c(
                    "div",
                    {
                      staticClass: "rtb-right-toolbar__top",
                      style: _vm.isMeeting && { "margin-top": "20px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "rtb-right-toolbar__logo",
                          class: { "is-open": _vm.mobile || hover },
                        },
                        [
                          _vm.mobile || !hover
                            ? _c(
                                "div",
                                {
                                  staticClass: "rtb-right-toolbar__logo-small",
                                  class: {
                                    "rtb-right-toolbar__logo-small--custom":
                                      _vm.customLogo,
                                  },
                                },
                                [
                                  _c("img", {
                                    key: "sm",
                                    attrs: {
                                      src: _vm.customLogo || _vm.weveLogo,
                                      alt: "Logo",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          hover && !_vm.mobile
                            ? _c(
                                "div",
                                {
                                  staticClass: "rtb-right-toolbar__logo-large",
                                  class: {
                                    "rtb-right-toolbar__logo-large--custom":
                                      _vm.customLogoLarge,
                                  },
                                },
                                [
                                  _c("img", {
                                    key: "lg",
                                    attrs: {
                                      src:
                                        _vm.customLogoLarge || _vm.weveFullLogo,
                                      alt: "Logo",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      !_vm.isHost
                        ? _c(
                            "h1",
                            { staticClass: "rtb-right-toolbar__title" },
                            [_vm._t("title")],
                            2
                          )
                        : _vm._e(),
                      !_vm.isModerated
                        ? _c(
                            "div",
                            { staticClass: "rtb-right-toolbar__countdown" },
                            [_vm._t("countdown")],
                            2
                          )
                        : _vm._e(),
                      !!_vm.isModerated && _vm.isGamePage
                        ? _c(
                            "WeveTooltip",
                            {
                              attrs: {
                                variant: "light",
                                placement: "left",
                                triggers: "manual",
                                closeable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var attrs = ref.attrs
                                      return [
                                        !_vm.mobile
                                          ? _c(
                                              "MeetToggle",
                                              _vm._b(
                                                {
                                                  attrs: {
                                                    size: hover ? "sm" : "xs",
                                                  },
                                                },
                                                "MeetToggle",
                                                attrs,
                                                false
                                              )
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.gameHints.ModeToggle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.gameHints, "ModeToggle", $$v)
                                },
                                expression: "gameHints.ModeToggle",
                              },
                            },
                            [
                              _c("div", { staticClass: "text-sm-left" }, [
                                _c("b", { staticClass: "text-base" }, [
                                  _vm._v("Meeting Mode"),
                                ]),
                                _c("p", { staticClass: "mb-0 mt-1 text-sm" }, [
                                  _vm._v(
                                    " Switch between Meeting and play mode from this toolbar toggle "
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.isAutopilotOn
                        ? _c(
                            "div",
                            { staticClass: "rtb-right-toolbar__autopilot" },
                            [
                              hover
                                ? _c("SvgIcon", {
                                    staticStyle: { "margin-left": "18px" },
                                    attrs: {
                                      name: "autopilot-on-full",
                                      width: "200",
                                      height: "30",
                                    },
                                  })
                                : _c("SvgIcon", {
                                    attrs: {
                                      name: "autopilot-on",
                                      width: "30",
                                      height: "30",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "rtb-right-toolbar__mid" }, [
                    _c(
                      "div",
                      { staticClass: "rtb-right-toolbar__list" },
                      [
                        !_vm.mobile
                          ? [
                              !_vm.isLobby &&
                              (_vm.client.escapeRoom || _vm.game.helpBell)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-cnt",
                                          class: {
                                            "rtb-right-toolbar__list-item-cnt--green":
                                              _vm.isHelpRequested,
                                          },
                                          on: { click: _vm.toggleHelpButton },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rtb-right-toolbar__list-item-icon",
                                            },
                                            [
                                              _c("SvgIcon", {
                                                attrs: {
                                                  name: "bell",
                                                  width: "20",
                                                  height: "20",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rtb-right-toolbar__list-item-title",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.isHelpRequested
                                                      ? _vm.game.helpRequestedBy
                                                      : "Request Help"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.isLobby && !_vm.isMeeting
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item",
                                    },
                                    [
                                      _vm.isHost || _vm.isDIYGame
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rtb-right-toolbar__list-item-cnt",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleAutopilot()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rtb-right-toolbar__list-item-icon",
                                                },
                                                [
                                                  _c("SvgIcon", {
                                                    staticClass:
                                                      "rtb-right-toolbar__list-item-icon",
                                                    attrs: {
                                                      name: _vm.isAutopilotOn
                                                        ? "pause-circle"
                                                        : "play-circle",
                                                      width: "20",
                                                      height: "20",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rtb-right-toolbar__list-item-title",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.isAutopilotOn
                                                          ? "Pause Auto-Pilot"
                                                          : "Start Auto-Pilot"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isCopyLinkVisible
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item",
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            left: !_vm.left,
                                            right: _vm.left,
                                            "open-delay": 450,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "rtb-right-toolbar__list-item-cnt",
                                                          on: {
                                                            click:
                                                              _vm.copyLinkToGame,
                                                            mouseover:
                                                              function (
                                                                $event
                                                              ) {
                                                                _vm.isReadyToCopy = true
                                                              },
                                                            mouseleave:
                                                              _vm.copyLinkLeave,
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "rtb-right-toolbar__list-item-icon",
                                                          },
                                                          [
                                                            _c("SvgIcon", {
                                                              attrs: {
                                                                name: "copy-link",
                                                                width: "24",
                                                                height: "24",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "rtb-right-toolbar__list-item-title",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Copy Room Link "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.isCopyLinkTTVisible,
                                            callback: function ($$v) {
                                              _vm.isCopyLinkTTVisible = $$v
                                            },
                                            expression: "isCopyLinkTTVisible",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              class: _vm.left
                                                ? "right"
                                                : "left",
                                            },
                                            [
                                              _vm.isReadyToCopy &&
                                              _vm.isLinkCopied
                                                ? [_vm._v(" Link copied ")]
                                                : [
                                                    _vm._v(
                                                      " Copy link to invite other players "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "rtb-right-toolbar__list-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-cnt",
                                    },
                                    [
                                      _c(
                                        "UserVolumeSlider",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__volum_sfx",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rtb-right-toolbar__list-item-title",
                                              staticStyle: {
                                                "margin-left": "0",
                                                "margin-right": "3px",
                                              },
                                            },
                                            [_vm._v(" SFX ")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.isReactionsMenuVisible
                                ? _c("ReactionsMenu", {
                                    model: {
                                      value: _vm.currentMenu,
                                      callback: function ($$v) {
                                        _vm.currentMenu = $$v
                                      },
                                      expression: "currentMenu",
                                    },
                                  })
                                : _vm._e(),
                              !!_vm.isModerated && _vm.isPlayer
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-cnt",
                                      on: { click: _vm.openEditTeamsDialog },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-icon",
                                        },
                                        [
                                          _c("SvgIcon", {
                                            attrs: {
                                              name: "recent-actors-outlined",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-title",
                                        },
                                        [_vm._v("Edit teams")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "rtb-right-toolbar__list-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-cnt",
                                      class: {
                                        "rtb-right-toolbar__list-item-cnt--danger":
                                          _vm.isUserMuted,
                                      },
                                      on: { click: _vm.toggleUserMute },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-icon",
                                        },
                                        [
                                          _c("SvgIcon", {
                                            attrs: {
                                              name: _vm.isUserMuted
                                                ? "muted"
                                                : "microphone",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-title",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isUserMuted
                                                  ? "Unmute"
                                                  : "Mute"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm.canShowShareScreen
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-cnt",
                                          class: {
                                            "rtb-right-toolbar__list-item-cnt--danger":
                                              _vm.isSharingScreen,
                                          },
                                          on: {
                                            click: _vm.onSetIsSharingScreen,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rtb-right-toolbar__list-item-icon",
                                            },
                                            [
                                              _c("SvgIcon", {
                                                attrs: {
                                                  name: _vm.isSharingScreen
                                                    ? "sharescreenstop"
                                                    : "sharescreen",
                                                  width: "20",
                                                  height: "20",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rtb-right-toolbar__list-item-title",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.isSharingScreen
                                                      ? "Stop Share Screen"
                                                      : "Share Screen"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isRemoveHostAvailable
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-cnt rtb-right-toolbar__list-item-cnt--danger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.setGameModeratorID(null)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-icon",
                                        },
                                        [
                                          _c("SvgIcon", {
                                            attrs: {
                                              name: "become-host-off",
                                              color: "danger",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-title",
                                        },
                                        [_vm._v(" Remove Host ")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                        _vm._t("additional-items"),
                        !_vm.mobile
                          ? [
                              _c(
                                "div",
                                { staticClass: "rtb-right-toolbar__list-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-cnt",
                                      on: {
                                        click: function ($event) {
                                          _vm.mediaSettings = true
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-icon",
                                        },
                                        [
                                          _c("SvgIcon", {
                                            staticClass:
                                              "rtb-right-toolbar__list-item-icon",
                                            attrs: {
                                              name: "audio-video-source",
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-title",
                                        },
                                        [_vm._v(" Video & Sound ")]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "ToolbarDropdown",
                                {
                                  attrs: {
                                    svgIcon: "gear-3",
                                    title: "General Settings",
                                  },
                                  model: {
                                    value: _vm.currentMenu,
                                    callback: function ($$v) {
                                      _vm.currentMenu = $$v
                                    },
                                    expression: "currentMenu",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-cnt",
                                          class: {
                                            "rtb-right-toolbar__list-item-cnt--danger":
                                              _vm.isFullScreen,
                                          },
                                        },
                                        [
                                          _c("FullscreenToggle", {
                                            attrs: {
                                              toggleFullSrceen: function (
                                                flag
                                              ) {
                                                return _vm.toggleFullSrceen(
                                                  flag
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "icon",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "rtb-right-toolbar__list-item-icon",
                                                        },
                                                        [
                                                          _c("SvgIcon", {
                                                            attrs: {
                                                              name: _vm.isFullScreen
                                                                ? "fullscreen-exit"
                                                                : "fullscreen",
                                                              width: "20",
                                                              height: "20",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item",
                                    },
                                    [
                                      !_vm.isMeeting
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                left: !_vm.left,
                                                right: _vm.left,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "div",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "rtb-right-toolbar__list-item-cnt",
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c("SvgIcon", {
                                                              staticClass:
                                                                "rtb-right-toolbar__list-item-icon",
                                                              attrs: {
                                                                name: _vm.isPotatoUser
                                                                  ? "slow-mode-off"
                                                                  : "slow-mode",
                                                                width: "20",
                                                                height: "20",
                                                              },
                                                            }),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "rtb-right-toolbar__list-item-title",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Slow Mode "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "rtb-right-toolbar__list-item-action",
                                                              },
                                                              [
                                                                _c("v-switch", {
                                                                  attrs: {
                                                                    "hide-details":
                                                                      "",
                                                                    "input-value":
                                                                      _vm.isPotatoUser,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.toggleUserPotato,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  class: _vm.left
                                                    ? "right"
                                                    : "left",
                                                },
                                                [
                                                  _vm._v(
                                                    " Slow mode is for poor wifi connections. "
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " It will hide videos of players on other teams "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.isSpeechToTextEnabled
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "rtb-right-toolbar__list-item-cnt",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rtb-right-toolbar__list-item-title",
                                                },
                                                [_vm._v(" Closed Captions ")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "rtb-right-toolbar__list-item-action",
                                                },
                                                [
                                                  _c("v-switch", {
                                                    attrs: {
                                                      "hide-details": "",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.toggleUserSpeechToText,
                                                      callback: function ($$v) {
                                                        _vm.toggleUserSpeechToText =
                                                          $$v
                                                      },
                                                      expression:
                                                        "toggleUserSpeechToText",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._t("settings"),
                                  _vm._t("logout"),
                                ],
                                2
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "rtb-right-toolbar__bottom" },
                    [
                      _vm._t("bottom", null, { hover: hover }),
                      _vm._t("lobby", null, {
                        hover: hover,
                        currentMenu: _vm.currentMenu,
                        setCurrentMenu: _vm.setCurrentMenu,
                      }),
                    ],
                    2
                  ),
                ]),
                _c("AVDialog", {
                  model: {
                    value: _vm.mediaSettings,
                    callback: function ($$v) {
                      _vm.mediaSettings = $$v
                    },
                    expression: "mediaSettings",
                  },
                }),
              ],
              1
            )
          },
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.visibility,
      callback: function ($$v) {
        _vm.visibility = $$v
      },
      expression: "visibility",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }