<template>
  <RtbToggleButton v-model="value" :items="options" :size="size" />
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import Mode from "@shared/enums/Mode"
import HubSpot from "@/services/hubspot.service"
import { RtbToggleButton } from "@/components/ui"
import Role from "@shared/enums/Role"
import AudioOption from "@shared/enums/AudioOverride"
import {
  NotificationMixin,
  NotificationType,
  NotificationScope
} from "@/mixins/NotificationMixin"

export default Vue.extend({
  name: "MeetToggle",
  components: {
    RtbToggleButton
  },
  mixins: [NotificationMixin],
  props: {
    size: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      options: [
        {
          name: Mode.Play,
          value: Mode.Play
        },
        {
          name: "Meet",
          value: Mode.Meeting
        }
      ]
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    value: {
      get() {
        const mode = this.$store.getters.getCurrentMode
        return [Mode.Play, Mode.Meeting].includes(mode) ? mode : Mode.Play
      },
      set(value) {
        const game = this.$store.getters.game
        const orgID = this.$store.state.orgID
        const gameID = this.$store.state.gameID
        const user = this.$store.getters["auth/user"]

        const data = {
          [`game/${gameID}/gameStatus/currentMode`]: value
        }

        if (value === Mode.Meeting) {
          data[`game/${gameID}/gameStatus/audioOption`] =
            AudioOption.EVERYONE_CAN_HEAR_EACH_OTHER
          data[`game/${gameID}/gameStatus/audioOptionLock`] = true
        } else {
          data[`game/${gameID}/gameStatus/audioOptionLock`] = false
        }

        // turn off autopilot if in meeting
        if (game.autopilot && value === Mode.Meeting) {
          data[`games/${gameID}/autopilot`] = false
        }

        // if Open Weve
        if (
          game.moderated &&
          game.diy &&
          value !== Mode.Meeting &&
          user?.role !== Role.HOST
        ) {
          setTimeout(() => {
            this.$snackbar({
              message:
                "To start playing turn on auto pilot on the right toolbar.",
              timeout: 7000,
              offset: 48
            })
          }, 2000)
        }

        // notify Hub Spot
        if (value === Mode.Meeting) {
          HubSpot.push([
            "trackCustomBehavioralEvent",
            {
              name: "pe2803895_app_weve_com___meet_mode"
            }
          ])
        }

        db.auxiliary().ref(`org/${orgID}`).update(data)

        if (!this.isHost) {
          this.$_NotificationMixin_send({
            type:
              value === Mode.Meeting
                ? NotificationType.MOVED_TO_MEETING_MODE
                : NotificationType.MOVED_TO_PLAY_MODE,
            scope: NotificationScope.GLOBAL
          })
        }
      }
    }
  }
})
</script>
