import axios from "./axios.service"

class PingService {
  static fetchTimestamp() {
    return axios({
      method: "get",
      url: `/ping`
    })
  }
}

export default PingService
