var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ring-stars-animation",
      class: {
        "ring-stars-animation--animated": _vm.animated,
        "ring-stars-animation--circle": _vm.circle,
        "ring-stars-animation--starred": _vm.starred,
      },
    },
    [
      _c("StarExplosion", {
        ref: "stars",
        staticClass: "star-explosion",
        attrs: { radius: _vm.radius, shape: _vm.circle ? "circle" : "square" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }