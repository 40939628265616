var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "weve-app" },
    [
      _vm.showUserSettings
        ? _c("UserEditor", { key: _vm.userIdToUpdate })
        : _vm._e(),
      _vm.authorized && _vm.isHost
        ? [
            _vm.isDashboardPage
              ? _c("LegacyToolbar")
              : _vm.isRoomPage
              ? _c("HostToolBar", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isToolbarVisible,
                      expression: "isToolbarVisible",
                    },
                  ],
                })
              : _vm._e(),
          ]
        : _vm._e(),
      _c("v-content", [_c("router-view", { key: _vm.path })], 1),
      _vm.isSessionSettings || _vm.isLobbyPage ? _c("LobbyMusic") : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "no-click-animation": "", width: "60%" },
          model: {
            value: _vm.showScreenCaptureDialogComputed,
            callback: function ($$v) {
              _vm.showScreenCaptureDialogComputed = $$v
            },
            expression: "showScreenCaptureDialogComputed",
          },
        },
        [
          !!_vm.showScreenCaptureDialog
            ? _c("ScreenCaptureIntro", {
                on: {
                  close: function ($event) {
                    return _vm.updateShowScreenCaptureDialog(false)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "no-click-animation": "", width: "60%" },
          model: {
            value: _vm.getShowScreenShareDialog,
            callback: function ($$v) {
              _vm.getShowScreenShareDialog = $$v
            },
            expression: "getShowScreenShareDialog",
          },
        },
        [
          !!_vm.getShowScreenShareDialog
            ? _c("ScreenShareIntro", {
                on: {
                  close: function ($event) {
                    _vm.getShowScreenShareDialog = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("HubspotChat", {
        attrs: {
          position: _vm.hubspotChatPosition,
          canShowHubspotChat: _vm.canShowHubspotChat,
        },
      }),
      _vm.isActivityDrawerAvailable ? _c("ActivityDrawer") : _vm._e(),
      _vm.isSmartMediaAvailable ? _c("SmartMedia") : _vm._e(),
      _c("TwilioManager"),
      !_vm.isDevelopmentMode ? _c("VersionObserver") : _vm._e(),
      _c("MobileObserver"),
      _vm.isHybridRoom && (_vm.mobile || _vm.isPresenter)
        ? _c("NoSleepObserver")
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }