<template>
  <canvas class="star-explosion__canvas" ref="canvas" />
</template>

<script>
import { TweenMax, Power3, Back } from "gsap/TweenMax"
const STAR_1_URL = require("../../../assets/star-image-1.png")
const STAR_2_URL = require("../../../assets/star-image-2.png")
export default {
  name: "StarExplosion",
  props: {
    nOfStar1: {
      default: 6,
      type: Number
    },
    nOfStar2: {
      default: 12,
      type: Number
    },
    speed: {
      default: 0.5,
      type: Number
    },
    maxStar1Size: {
      default: 20,
      type: Number
    },
    maxStar2Size: {
      default: 12,
      type: Number
    },
    radius: {
      default: 300,
      type: Number
    },
    shape: {
      default: "circle",
      type: String
    }
  },
  data() {
    return {
      canvas: null,
      canvasCtx: null,
      images: [],
      objectsToDraw: [],
      playing: false
    }
  },
  methods: {
    canvasLoop() {
      const canvasCtx = this.canvasCtx
      const images = this.images
      canvasCtx.clearRect(0, 0, this.size, this.size)
      canvasCtx.save()
      this.objectsToDraw.forEach(obj => {
        canvasCtx.save()
        canvasCtx.globalAlpha = obj.opacity
        const size = obj.size * obj.scale
        const offset = size / 2
        canvasCtx.drawImage(
          images[obj.type - 1],
          obj.x - offset,
          obj.y - offset,
          size,
          size
        )
        canvasCtx.restore()
      })
      canvasCtx.restore()
    },
    onUpdate() {
      this.objectsToDraw.forEach(obj => {
        TweenMax.killTweensOf(obj)
        obj.opacity = 0
      })
      try {
        TweenMax.ticker.removeEventListener("tick", this.canvasLoop)
      } catch (e) {
        console.log(e.message)
      }
      // update the sizing
      this.playing = false
      this.canvas.width = this.size
      this.canvas.height = this.size
      this.canvasCtx = this.canvas.getContext("2d")
    },
    getSquarePoint({ size, area }) {
      const functions = [
        () => {
          return {
            x: this.getRandomInt(size, area - size),
            y: size
          }
        },
        () => {
          return {
            x: this.getRandomInt(size, area - size),
            y: area - size
          }
        },
        () => {
          return {
            x: size,
            y: this.getRandomInt(size, area - size)
          }
        },
        () => {
          return {
            x: area - size,
            y: this.getRandomInt(size, area - size)
          }
        }
      ]
      return functions[this.getRandomInt(0, functions.length - 1)]()
    },
    getCirclePoint({ radius, angle, size }) {
      return {
        x: radius + Math.cos(angle) * (radius - size),
        y: radius + Math.sin(angle) * (radius - size)
      }
    },
    getPoint({ shape, size, angle, area, radius }) {
      if (shape === "square") {
        return this.getSquarePoint({ size, area })
      } else {
        return this.getCirclePoint({ radius, angle, size })
      }
    },
    setInitialState() {
      const shape = this.shape
      const radius = this.radius
      const innerRadius = this.innerRadius
      const maxStar1Size = this.maxStar1Size
      const maxStar2Size = this.maxStar2Size
      const getRandomInt = this.getRandomInt
      this.objectsToDraw.forEach(obj => {
        obj.scale = 1
        obj.opacity = 1
        const size = obj.type === 1 ? maxStar1Size : maxStar2Size
        obj.size = getRandomInt(size / 2, size)
        if (shape === "square") {
          obj.x = radius
          obj.y = radius
        } else {
          obj.angle = Math.random() * Math.PI * 2
          const size = obj.size * obj.scale
          obj.x = radius + Math.cos(obj.angle) * (innerRadius - size)
          obj.y = radius + Math.sin(obj.angle) * (innerRadius - size)
        }
      })
    },
    init() {
      let i
      for (i = 0; i < this.nOfStar2; i++) this.objectsToDraw.push({ type: 2 })
      for (i = 0; i < this.nOfStar1; i++) this.objectsToDraw.push({ type: 1 })
    },
    animate() {
      if (this.playing) return
      this.playing = true
      TweenMax.ticker.addEventListener("tick", this.canvasLoop)
      // set base values
      this.setInitialState()
      const objectsToDraw = this.objectsToDraw
      const speed = this.speed
      const shape = this.shape
      const radius = this.radius
      const diameter = radius * 2
      const getRandomFloat = this.getRandomFloat
      const maxDelay = 2 * speed
      const firstTweenDuration = 1.5 * speed
      const lastTweenDelay = 1.25 * speed
      for (const obj of objectsToDraw) {
        const { x, y } = this.getPoint({
          shape,
          area: diameter,
          size: obj.size * obj.scale,
          angle: obj.angle,
          radius: radius
        })
        const delay = getRandomFloat(0, maxDelay)
        setTimeout(() => {
          TweenMax.to(obj, firstTweenDuration, {
            x,
            y,
            scale: 1.5,
            overwrite: false,
            ease: Back.easeOut.config(0.8)
          })
          TweenMax.to(obj, 0.25, {
            scale: 1.3,
            overwrite: false,
            opacity: 0,
            ease: Power3.easeInOut
          }).delay(lastTweenDelay)
        }, delay * speed * 1000)
      }
      const duration = (1 * speed + 2 * speed) * 1000
      // plus a little offset just in case
      setTimeout(() => {
        TweenMax.ticker.removeEventListener("tick", this.canvasLoop)
        // enforce cleaning in case of a lag
        this.canvasCtx.clearRect(0, 0, this.size, this.size)
        this.playing = false
      }, duration + 500)
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    getRandomFloat(min, max) {
      return (Math.random() * (min - max) + max).toFixed(4)
    },
    getImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        const onLoad = () => {
          img.removeEventListener("load", onLoad)
          img.removeEventListener("error", onError)
          resolve(img)
        }
        const onError = e => {
          img.removeEventListener("load", onLoad)
          img.removeEventListener("error", onError)
          reject(e)
        }
        img.addEventListener("load", onLoad)
        img.addEventListener("error", onError)
        img.src = url
      })
    }
  },
  computed: {
    innerRadius() {
      return this.radius * 0.5
    },
    size() {
      return this.radius * 2
    }
  },
  watch: {
    radius() {
      this.onUpdate()
    },
    shape() {
      this.onUpdate()
    }
  },
  async mounted() {
    this.canvas = this.$refs.canvas
    this.canvas.width = this.size
    this.canvas.height = this.size
    this.canvasCtx = this.canvas.getContext("2d")

    this.images = await Promise.all([
      this.getImage(STAR_1_URL),
      this.getImage(STAR_2_URL)
    ])

    this.init()
    this.setInitialState()
    this.$emit("ready")

    // we don't want stars all messed up after switching browser tabs
    window.addEventListener("blur", this.onUpdate)
  },
  beforeDestroy() {
    try {
      TweenMax.ticker.removeEventListener("tick", this.canvasLoop)
    } catch (e) {
      console.log(e.message)
    }
    try {
      window.removeEventListener("blur", this.onUpdate)
    } catch (e) {
      console.log(e.message)
    }
  }
}
</script>
<style>
.star-explosion__canvas {
  /* perf buff */
  transform: translateZ(0);
}
</style>
