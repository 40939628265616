import { sortBy, get } from "lodash"
import type { GetterTree } from "vuex"

import { Firebase } from "@/helpers"

import { ImagesToClientMap } from "../constants"

import type { State } from "./state"
import GetterTypes from "./getter-types"

import { ThemeScope } from "./constants"

const getters: GetterTree<State, any> = {
  [GetterTypes.THEMES](_, __, rootState) {
    return rootState.theOrg?.themes || null
  },
  [GetterTypes.NORMALIZED_THEMES](_, getters) {
    const themes = getters[GetterTypes.THEMES]
    return themes
      ? sortBy(Firebase.normalizeSnapshotToArray(themes), "name")
      : []
  },
  [GetterTypes.STYLES](state, getters, rootState) {
    const theme = getters[GetterTypes.__CURRENT_THEME__]

    if (state.scope === ThemeScope.GAME) {
      const { game } = rootState

      if (game && game.styles && !game.themeID) {
        return game.styles
      }
    }

    if (!theme) {
      const { client } = rootState.auth
      const styles = client?.styles
      if (styles) return styles
    }

    return theme ? theme.styles : null
  },
  [GetterTypes.IMAGES](state, getters, rootState) {
    return imageName => {
      if (state.scope === ThemeScope.GAME) {
        const { game } = rootState

        const imageKey = get(ImagesToClientMap, imageName, imageName)

        const gameImageUrl = get(game, imageKey, false)

        if (gameImageUrl) return gameImageUrl
      }

      const theme = getters[GetterTypes.__CURRENT_THEME__]

      if (theme && theme.images[imageName]) {
        return theme.images[imageName]
      }

      const { client } = rootState.auth

      if (client && client[ImagesToClientMap[imageName]]) {
        return client[ImagesToClientMap[imageName]]
      }

      const themes = getters[GetterTypes.THEMES]
      const clientTheme = themes?.[client?.themeID]

      if (clientTheme && clientTheme.images[imageName]) {
        return clientTheme.images[imageName]
      }
    }
  },
  [GetterTypes.META](_, getters, rootState) {
    return key => {
      const theme = getters[GetterTypes.__CURRENT_THEME__]
      if (theme && theme.meta[key]) {
        return theme.meta[key]
      }
      const { client } = rootState.auth
      if (client) {
        return client[key]
      }
    }
  },
  [GetterTypes.__CURRENT_THEME__](state, getters, rootState) {
    if (state.theme) return state.theme
    const themes = getters[GetterTypes.THEMES]
    if (themes === null) return null

    /** @type {string} */
    let themeID

    if (state.scope === ThemeScope.GAME) {
      const { game } = rootState

      if (game) {
        themeID = game.themeID
      }
    }

    if (themeID === undefined) {
      const { client } = rootState.auth

      if (client) {
        themeID = client.themeID
      }

      if (themeID === undefined) {
        const { theOrg: org } = rootState
        themeID = org.themeID
      }
    }

    if (themeID) {
      const theme = themes[themeID]
      if (theme) {
        return theme
      }
    }

    return null
  }
}

export default getters
