import type { ActionTree } from "vuex"

import { ActionTypes as RootActionTypes } from "@/store"

import type { Theme } from "../types"
import type { State } from "./state"
import ActionTypes from "./action-types"

const actions: ActionTree<State, any> = {
  [ActionTypes.UPDATE_ORG_THEME](
    { dispatch },
    payload: { orgID: string; themeID: string | undefined; value: Theme }
  ) {
    return this.$services.get("theme").then(service => {
      let { themeID } = payload
      if (themeID === undefined) {
        themeID = service.getNewThemeID(payload.orgID)
      }

      return service
        .updateOrgTheme(payload.orgID, themeID, payload.value)
        .then(theme => {
          dispatch(RootActionTypes.GET_CURRENT_ORG, undefined, { root: true })
          return theme
        })
    })
  },
  [ActionTypes.DELETE_ORG_THEME](
    { dispatch },
    { orgID, themeID }: { orgID: string; themeID: string }
  ) {
    return this.$services.get("theme").then(service =>
      service.deleteOrgTheme(orgID, themeID).then(() => {
        dispatch(RootActionTypes.GET_CURRENT_ORG, undefined, { root: true })
      })
    )
  },
  [ActionTypes.SET_ORG_THEME](
    { dispatch },
    { orgID, themeID }: { orgID: string; themeID: string }
  ) {
    return this.$services.get("theme").then(service =>
      service.setOrgTheme(orgID, themeID).then(() => {
        dispatch(RootActionTypes.GET_CURRENT_ORG, undefined, { root: true })
      })
    )
  }
}

export default actions
