export const ICONS = {
  1: "grade",
  2: "favorite",
  3: "brightness_2",
  4: "tag_faces",
  5: "eco",
  6: "face",
  7: "brightness_1",
  8: "flash_on",
  9: "filter_vintage",
  10: "fitness_center",
  11: "school"
}

export class Team {
  /**
   *
   * @param db
   * @param orgID
   * @param gameID
   * @return {*}
   */
  static getTeamsRef(db, { orgID, gameID }) {
    return db.ref(`org/${orgID}/game/${gameID}/teams`)
  }

  /**
   *
   * @param ref
   * @param num
   * @param defaults
   * @return {{$key: {}}}
   */
  static getNewTeams(ref, num, defaults = {}) {
    const teams = {}
    for (let i = 1; i <= num; i++) {
      teams[ref.push().key] = this.getNewTeam(i, defaults)
    }
    return teams
  }

  /**
   *
   * @param teamNum
   * @return {*}
   */
  static getIcon(teamNum) {
    if (teamNum in ICONS) return ICONS[teamNum]
    return ICONS[1]
  }
  static getNewTeam(num, defaults = {}) {
    return {
      name: `Team ${num}`,
      icon: this.getIcon(num),
      active: true,
      show: true,
      totalScore: 0,
      otherTeamFacts: [],
      flippedState: "hidden",
      ...defaults
    }
  }
}
