const Environment = {
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production"
}

const PRODUCTION_BASE = "https://app.weve.co"
const STAGING_BASE = "https://app-staging.weve.co"
const DEVELOPMENT_BASE = "https://rtb-dev2000.firebaseapp.com"
const API_URI = process.env.VUE_APP_API_FUNCTION || "api"

// TODO - use env files to define API url
const getBaseUrl = () => {
  if (process.env.VUE_APP_API) return `${process.env.VUE_APP_API}/${API_URI}/`
  else if (process.env.VUE_APP_MODE === Environment.PRODUCTION)
    return `${PRODUCTION_BASE}/${API_URI}/`
  else if (process.env.VUE_APP_MODE === Environment.STAGING)
    return `${STAGING_BASE}/${API_URI}/`
  else if (process.env.VUE_APP_MODE === Environment.DEVELOPMENT)
    return `${DEVELOPMENT_BASE}/${API_URI}/`
  else if (process.env.NODE_ENV === Environment.PRODUCTION)
    return `${PRODUCTION_BASE}/${API_URI}/`
  else if (process.env.NODE_ENV === Environment.STAGING)
    return `${STAGING_BASE}/${API_URI}/`
  else return `${DEVELOPMENT_BASE}/${API_URI}/`
}

const BASE_URL = getBaseUrl()

export default BASE_URL
