var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isVideo(_vm.$attrs.src) && _vm.isSmartMediaReady
    ? _c(
        "video",
        _vm._g(
          _vm._b(
            {
              attrs: {
                crossorigin: "anonymous",
                controlslist: "nodownload",
                disablePictureInPicture: "",
              },
            },
            "video",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    : _vm.isSmartMediaReady
    ? _c(
        "audio",
        _vm._g(
          _vm._b(
            { attrs: { crossorigin: "anonymous", controlslist: "nodownload" } },
            "audio",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }