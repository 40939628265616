import { computed } from "@vue/composition-api"

export default function useCategories(mission) {
  const answers = computed(() => {
    const value = String(mission.value?.answer)

    if (value.includes(";")) return value.split(";").filter(Boolean)

    if (value.includes(",")) return value.split(",").filter(Boolean)

    return [value]
  })

  return {
    answers
  }
}
