

















import Vue from "vue"
import isVideo from "is-video"

export default Vue.extend({
  name: "VideoComponent",
  props: {
    types: {
      type: Array,
      default: () => ["webm", "mp4"]
    },
    src: {
      type: String,
      default: undefined
    }
  },
  computed: {
    sources() {
      if (!isVideo(this.src)) return []

      const path = this.src.replace(/[^.]*$/, "")

      return this.types.map(type => ({
        src: path + type,
        type: "video/" + type
      }))
    }
  }
})
