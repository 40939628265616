<template>
  <audio preload="none" ref="audio" @canplay="$emit('ready')" />
</template>

<script>
export default {
  name: "UserAudio",
  props: {
    track: {
      type: Object,
      required: true
    },
    volume: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      unwatch: null
    }
  },
  computed: {
    speaker() {
      return this.$store.state.twilio.speaker
    }
  },
  mounted() {
    if (this.$refs.audio?.setSinkId)
      this.$watch(
        "speaker",
        value => {
          if (!value) return
          this.$refs.audio.setSinkId(value)
        },
        { immediate: true }
      )

    this.track.attach(this.$refs.audio)
    this.unwatch = this.$watch(
      "volume",
      value => {
        this.$refs.audio.volume = value
      },
      { immediate: true }
    )
  },
  beforeDestroy() {
    this.unwatch && this.unwatch()
    this.track.detach(this.$refs.audio)
    this.$refs.audio && this.$kill(this.$refs.audio)
  }
}
</script>
<style scoped>
audio {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
