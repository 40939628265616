import { db } from "@/firebase"
import { store } from "@/store"

export default class {
  static toggle(user) {
    return db
      .auxiliary()
      .ref(`org/1/users/${user?.id}`)
      .transaction(user => ({
        ...user,
        muted: user.permanentMuted ? false : !user.muted,
        permanentMuted: null
      }))
  }
  static mute(user) {
    return db.auxiliary().ref(`org/1/users/${user?.id}/muted`).set(true)
  }
  static unmute(user) {
    return db.auxiliary().ref(`org/1/users/${user?.id}/muted`).set(false)
  }
  static makeSpeaker(user) {
    const orgId = store.getters.orgID
    const roomId = store.getters.gameID
    const ref = db
      .auxiliary()
      .ref(`org/${orgId}/games/${roomId}/speakers/${user?.id}`)
    ref.onDisconnect().remove()
    ref.set(true)
  }
  static revokeSpeaker(user) {
    const orgId = store.getters.orgID
    const roomId = store.getters.gameID
    const ref = db
      .auxiliary()
      .ref(`org/${orgId}/games/${roomId}/speakers/${user?.id}`)
    ref.remove()
    ref.onDisconnect().cancel()
  }
}
