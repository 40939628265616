var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-form" },
    [
      _c("p", { staticClass: "login-form__title" }, [
        _vm._v(" " + _vm._s(_vm.enterText) + " "),
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.signInUserAnonymously.apply(null, arguments)
            },
          },
        },
        [
          _vm.conferenceLogin
            ? _c("v-checkbox", {
                staticClass: "anonymous-form__conference-checkbox",
                attrs: {
                  color: "success",
                  "hide-details": "",
                  label: "Multiple players at one computer",
                },
                model: {
                  value: _vm.conference,
                  callback: function ($$v) {
                    _vm.conference = $$v
                  },
                  expression: "conference",
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              class: { "two-col-form": _vm.customInputType && !_vm.conference },
            },
            [
              _c(
                "div",
                {
                  class: {
                    "two-col-input": _vm.customInputType && !_vm.conference,
                  },
                },
                [
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.conference ? "Conference Name" : "First name")
                    ),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      type: "text",
                      required: "",
                      rules: [_vm.rules.required],
                      placeholder: _vm.conference ? "Name" : "Jane",
                      id: "anonymouse-login-form-first-name-input",
                    },
                    model: {
                      value: _vm.firstname,
                      callback: function ($$v) {
                        _vm.firstname = $$v
                      },
                      expression: "firstname",
                    },
                  }),
                ],
                1
              ),
              !_vm.conference
                ? _c(
                    "div",
                    { class: { "two-col-input": _vm.customInputType } },
                    [
                      _c("p", [_vm._v("Last name")]),
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          required: "",
                          rules: [_vm.rules.required],
                          placeholder: "Smith",
                          id: "anonymouse-login-form-last-name-input",
                        },
                        model: {
                          value: _vm.lastname,
                          callback: function ($$v) {
                            _vm.lastname = $$v
                          },
                          expression: "lastname",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm.customInputLabelComputed
            ? _c("p", [_vm._v(_vm._s(_vm.customInputLabelComputed))])
            : _vm._e(),
          _vm.hasCustomInputSelect
            ? _c("v-select", {
                attrs: {
                  items: _vm.customInputOptions,
                  dense: "",
                  "single-line": "",
                  "loader-height": "40",
                  color: "#413d3c",
                  required: "",
                  rules: [_vm.rules.required],
                },
                model: {
                  value: _vm.customInput,
                  callback: function ($$v) {
                    _vm.customInput = $$v
                  },
                  expression: "customInput",
                },
              })
            : _vm._e(),
          _vm.customInputType === "text"
            ? _c("v-text-field", {
                attrs: {
                  type: "text",
                  required: "",
                  rules: [_vm.rules.required],
                  placeholder: _vm.customInputLabel,
                },
                model: {
                  value: _vm.customInput,
                  callback: function ($$v) {
                    _vm.customInput = $$v
                  },
                  expression: "customInput",
                },
              })
            : _vm._e(),
          _vm.customInputType === "password"
            ? _c("v-text-field", {
                attrs: {
                  type: "password",
                  required: "",
                  rules: [_vm.rules.required, _vm.rules.min],
                  placeholder: "******",
                },
                model: {
                  value: _vm.customInput,
                  callback: function ($$v) {
                    _vm.customInput = $$v
                  },
                  expression: "customInput",
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "submit-wrap flex d-flex" }, [
            _c(
              "button",
              {
                staticClass: "login-form__submit",
                attrs: {
                  type: "submit",
                  disabled: !_vm.isFormValid,
                  value: "Log in",
                  id: "anonymouse-login-form-login-btn",
                },
              },
              [
                _vm.$_LoginMixin_audit
                  ? _c("SvgIcon", {
                      staticClass: "login-form__submit-icon",
                      attrs: { width: "24", height: "24", name: "binoculars" },
                    })
                  : _vm._e(),
                _vm._v(" Log in "),
                _vm.$_LoginMixin_audit ? [_vm._v(" as Auditor ")] : _vm._e(),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "terms-and-conditions mt-3" }, [
            _vm._v(" By logging in you agree to our "),
            _c(
              "a",
              {
                attrs: { href: _vm.privacyLink, target: "_blank" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [_vm._v("Terms & Conditions")]
            ),
            _vm._v(". "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }