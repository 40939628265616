
import Vue, { PropType } from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "RTBFormControlLabel",
  functional: true,
  props: {
    size: {
      type: String as PropType<"small" | "default">,
      default: "default"
    }
  },
  render(h, { props, children, data }) {
    return h(
      "label",
      mergeData(data, {
        class: {
          [`rtb-form-control-label--size--${props.size}`]:
            props.size !== "default"
        },
        staticClass: "rtb-form-control-label"
      }),
      children
    )
  }
})
