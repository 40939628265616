import { debounce } from "lodash"
import Vue from "vue"

const windowObservable = Vue.observable({
  width: window.innerWidth,
  height: window.innerHeight
})

const orientationObservable = Vue.observable({
  isLandscape: false,
  isPortrait: true
})

export const onWindowResize = debounce(() => {
  const isLandscape =
    window.orientation != null
      ? window.orientation != 0
      : window.innerWidth > window.innerHeight

  windowObservable.width = window.innerWidth
  windowObservable.height = window.innerHeight
  orientationObservable.isLandscape = isLandscape
  orientationObservable.isPortrait = !isLandscape
}, 500)

export const Window = {
  install(Vue) {
    Vue.prototype.$window = windowObservable
    Vue.prototype.$orientation = orientationObservable

    window.addEventListener("orientationchange", onWindowResize)
    window.addEventListener("resize", onWindowResize)
  }
}
