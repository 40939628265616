import type { PropType } from "vue"
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "UserCardButton",
  functional: true,
  render(h, { children, data }) {
    return h(
      "button",
      mergeData(data, {
        attrs: { type: "button" },
        staticClass: "weve-user-card__button",
        on: {
          focus: (e: any) => e.target.blur()
        }
      }),
      children
    )
  }
})
