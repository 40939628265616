// @ts-nocheck
import type { Module } from "vuex"
import { firebaseAction } from "vuexfire"
import { db } from "@/firebase"

import * as moment from "moment"

import type { Game } from "@/types/game"

import { fetchGames } from "../services/game.service.js"

import ActionTypes from "@/store/modules/PreGame/action-types"

let tournamentsSubscription = null
let tournamentsSubscriptionCallback = null

let godsHandSubscriptionRef = null
let godsHandSubscription = null

export const MutationTypes = {
  ADD_TO_FILTERS: "ADD_TO_FILTERS",
  REMOVE_FROM_FILTERS: "REMOVE_FROM_FILTERS"
}

export const Filters = {
  Staging: "staging",
  Round: "round",
  Deleted: "deleted",
  Ended: "ended"
}

let SUBSCRIBERS_COUNT = 0

const state = {
  usersToMap: {},
  games: [] as Game[],
  tournaments: {},
  selectedGameID: null,
  userPlayedGames: {},
  userUnlockedGames: {},
  filters: {
    [Filters.Staging]: true,
    [Filters.Deleted]: true,
    [Filters.Ended]: true
  },
  selectedKeynote: {
    paused: null,
    startedAt: null,
    currentTime: null
  },
  chatTabActive: false
}

export type State = typeof state

const PreGame: Module<State, unknown> = {
  namespaced: true,
  state,
  mutations: {
    [MutationTypes.ADD_TO_FILTERS](state, { key, value = true }) {
      state.filters = { ...state.filters, [key]: value }
    },
    [MutationTypes.REMOVE_FROM_FILTERS](state, { key }) {
      const filters = { ...state.filters }
      delete filters[key]
      state.filters = filters
    },
    UPDATE_USERS_TO_MAP(state, { game }) {
      state.usersToMap = game
    },
    UPDATE_SELECTED_GAME_ID(state, { gameID }) {
      state.selectedGameID = gameID
    },
    UPDATE_USER_PLAYED_GAMES(state, payload) {
      state.userPlayedGames = payload || {}
    },
    UPDATE_USER_UNLOCKED_GAMES(state, payload) {
      state.userUnlockedGames = payload || {}
    },
    UPDATE_TOURNAMENTS(state, payload) {
      console.log("TOURNAMENTS ARE UPDATED", payload)
      state.tournaments = payload
    },
    UPDATE_CHAT_TAB_ACTIVE(state, payload) {
      state.chatTabActive = payload
    },
    SET_SELECTED_KEYNOTE(state, payload) {
      state.selectedKeynote = { ...state.selectedKeynote, ...payload }
    }
  },
  actions: {
    [ActionTypes.ADD_TO_FILTERS]({ commit }, payload) {
      commit(MutationTypes.ADD_TO_FILTERS, payload)
    },
    [ActionTypes.REMOVE_FROM_FILTERS]({ commit }, payload) {
      commit(MutationTypes.REMOVE_FROM_FILTERS, payload)
    },
    updateSelectedGameID({ commit }, { gameID }) {
      commit("UPDATE_SELECTED_GAME_ID", { gameID })
    },
    subscribeToGodsHand({ commit, rootGetters }) {
      const clientID = rootGetters["auth/clientID"]
      const { id: userID } = rootGetters["auth/user"] || {}
      if (godsHandSubscriptionRef)
        godsHandSubscriptionRef.off("value", godsHandSubscription)
      commit("UPDATE_USERS_TO_MAP", { users: {} })
      godsHandSubscriptionRef = db
        .auxiliary()
        .ref(`client/${clientID}/playersToMap/${userID}`)
      godsHandSubscription = godsHandSubscriptionRef.on("value", snapshot => {
        commit("UPDATE_USERS_TO_MAP", { game: snapshot.val() || null })
      })
    },
    async unsubscribeFromGodsHand({ commit }) {
      if (godsHandSubscriptionRef)
        godsHandSubscriptionRef.off("value", godsHandSubscription)
      commit("UPDATE_USERS_TO_MAP", { users: {} })
    },
    [ActionTypes.SUBSBSCRIBE_TO_GAMES]: firebaseAction(
      ({ bindFirebaseRef, rootGetters }) => {
        if (SUBSCRIBERS_COUNT === 0) {
          SUBSCRIBERS_COUNT++
          const clientID = rootGetters["auth/clientID"]
          const { orgID } = rootGetters
          return bindFirebaseRef(
            "games",
            db
              .auxiliary()
              .ref(`org/${orgID}/games`)
              .orderByChild("clientID")
              .equalTo(clientID)
          )
        }
        SUBSCRIBERS_COUNT++
      }
    ),
    [ActionTypes.UNSUBSCRIBE_FROM_GAMES]: firebaseAction(
      ({ unbindFirebaseRef }) => {
        SUBSCRIBERS_COUNT--
        // If no subscribers left we completely unsubscribe and rest a state
        if (SUBSCRIBERS_COUNT <= 0) {
          return unbindFirebaseRef("games", true)
        }
      }
    ),
    async subscribeTournaments({ commit }, { clientID }) {
      if (tournamentsSubscription)
        tournamentsSubscription.off("value", tournamentsSubscriptionCallback)

      tournamentsSubscription = db
        .auxiliary()
        .ref(`/client/${clientID}/tournaments`)

      return new Promise(resolve => {
        tournamentsSubscriptionCallback = tournamentsSubscription.on(
          "value",
          snapshot => {
            commit("UPDATE_TOURNAMENTS", snapshot.val())
            resolve()
          }
        )
      })
    },
    async unsubscribeTournaments() {
      if (tournamentsSubscription)
        tournamentsSubscription.off("value", tournamentsSubscriptionCallback)
    },
    async fetchGames({ commit }, { orgID, clientID }) {
      const games = await fetchGames({ orgID, clientID })
      commit("UPDATE_GAMES", { games })
    },
    async userUnlockedGame(_, { clientID, userID, gameID }) {
      await db
        .auxiliary()
        .ref(`client/${clientID}/usersUnlockedGames/${userID}/${gameID}`)
        .set(moment().unix())
    },
    async pushRoomIdToUserSessionHistory(_, { clientID, userID, gameID }) {
      await db
        .auxiliary()
        .ref(`client/${clientID}/history/${userID}/${gameID}`)
        .set(moment().unix())
    },
    async fetchUserPlayedGames({ commit }, { clientID, userID }) {
      const snapshot = await db
        .auxiliary()
        .ref(`client/${clientID}/history/${userID}`)
        .once("value")
      commit("UPDATE_USER_PLAYED_GAMES", snapshot.val())
    },
    async fetchUserUnlockedGames({ commit }, { clientID, userID }) {
      const snapshot = await db
        .auxiliary()
        .ref(`client/${clientID}/usersUnlockedGames/${userID}`)
        .once("value")
      console.log("FETCHING USER UNLOCKED GAMES", snapshot.val())
      commit("UPDATE_USER_UNLOCKED_GAMES", snapshot.val())
    },
    updateChatTabActive({ commit }, payload) {
      commit("UPDATE_CHAT_TAB_ACTIVE", payload)
    },
    updateSelectedKeynote({ commit }, payload) {
      commit("SET_SELECTED_KEYNOTE", payload)
    }
  },
  getters: {
    filters: state => state.filters,
    selectedGameID(state) {
      return state.selectedGameID
    },
    usersToMap(state) {
      return state.usersToMap
    },
    games(state) {
      return state.games
    },
    access(state, getters, rootState, rootGetters) {
      return rootGetters["auth/access"]
    },
    userPlayedGames(state) {
      return state.userPlayedGames
    },
    userUnlockedGames(state) {
      return state.userUnlockedGames
    },
    tournaments(state) {
      return state.tournaments
    },
    chatTabActive: state => state.chatTabActive,
    selectedKeynote: state => state.selectedKeynote
  }
}

export default PreGame
