var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn-toggle",
    {
      staticClass: "rtb-button-group",
      class:
        ((_obj = {}), (_obj["rtb-button-group--" + _vm.size] = _vm.size), _obj),
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v
        },
        expression: "selected",
      },
    },
    [
      _vm._l(_vm.items, function (item) {
        return _c(
          "v-btn",
          {
            key: item.value || item.name,
            style: _vm.buttonStyle,
            attrs: {
              flat: "",
              value: item.value || item.name,
              disabled: _vm.disabled || _vm.value === (item.value || item.name),
            },
          },
          [
            item.icon
              ? _c("v-icon", [_vm._v(" " + _vm._s(item.icon))])
              : _vm._e(),
            item.svgIcon
              ? _c("SvgIcon", {
                  attrs: { size: item.iconSize, name: item.svgIcon },
                })
              : _vm._e(),
            item.pill
              ? _c("Pill", { staticClass: "rtb-button-group__pill" }, [
                  _vm._v(" " + _vm._s(item.pill) + " "),
                ])
              : _vm._e(),
            _vm.showName
              ? _c("span", [_vm._v(" " + _vm._s(item.name) + " ")])
              : _vm._e(),
          ],
          1
        )
      }),
      _vm.numberOfSelectedItem !== false
        ? _c("div", {
            staticClass: "rtb-button-group__slider",
            style: _vm.sliderStyle,
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }