import type Vue from "vue"
import type { ThisTypedComponentOptionsWithRecordProps } from "vue/types/options"

type Value = string | number | undefined

// TODO(Andrew|someone):
type NameMeCorrectly =
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLSelectElement

const InputMixin: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  { localValue: Value },
  {},
  { listeners: Record<string, Function | Function[]> },
  { value: Value; disabled: boolean; readonly: boolean; lazy: boolean }
> = {
  props: {
    value: {
      type: [String, Number]
    },
    disabled: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: this.value
    }
  },
  computed: {
    listeners(): Record<string, Function | Function[]> {
      return {
        ...this.$listeners,
        input: (event: InputEvent) => {
          const value = (event.target as NameMeCorrectly).value
          if (!this.lazy) {
            this.$emit("input", value)
          } else {
            this.localValue = value
          }
        },
        change: (event: InputEvent) => {
          const value = (event.target as NameMeCorrectly).value
          this.$emit("input", value)
          this.$emit("change", value)
        }
      }
    }
  },
  watch: {
    value(value: Value) {
      this.localValue = value
    }
  }
}

export default InputMixin
