var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          d: "M11.842 8.526A2.838 2.838 0 019 11.368a2.838 2.838 0 01-2.842-2.842V2.842A2.838 2.838 0 019 0a2.838 2.838 0 012.842 2.842v5.684zM9 13.263a4.739 4.739 0 004.737-4.737h1.895c0 3.345-2.473 6.092-5.685 6.556V18H8.053v-2.918c-3.212-.464-5.685-3.212-5.685-6.556h1.895A4.739 4.739 0 009 13.263z",
          "clip-rule": "evenodd",
        },
      }),
    ]),
    _c("defs", [
      _c("clipPath", { attrs: { id: "clip0" } }, [
        _c("path", { attrs: { fill: "white", d: "M0 0h18v18H0z" } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }