var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "volume-slider" },
    [
      _vm.$slots.icon
        ? _vm._t("icon")
        : _c(
            "div",
            { staticClass: "volume-slider__icon" },
            [
              _vm.volume > _vm.min
                ? _c("SvgIcon", {
                    attrs: { name: "speaker", width: "20", height: "20" },
                    on: {
                      click: function ($event) {
                        return _vm.update(_vm.min, true)
                      },
                    },
                  })
                : _c("SvgIcon", {
                    attrs: { name: "speaker-mute", width: "18", height: "18" },
                    on: {
                      click: function ($event) {
                        return _vm.update(_vm.max)
                      },
                    },
                  }),
            ],
            1
          ),
      _vm.$slots.default
        ? _vm._t("default")
        : _c("span", { staticClass: "volume-slider__label" }, [
            _vm._v(" SFX "),
          ]),
      _c("v-slider", {
        key: _vm.count,
        attrs: { min: _vm.min, max: _vm.max },
        model: {
          value: _vm.volume,
          callback: function ($$v) {
            _vm.volume = $$v
          },
          expression: "volume",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }