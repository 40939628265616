import { store } from "@/store/index"
import { Navigation } from "@/helpers"

export default async function (to, from, next) {
  const { authorize } = to.meta
  const authorized = store.getters["auth/authorized"]
  const role = store.getters["auth/role"]

  if (authorize) {
    const { id: fromUrlID } = from.params
    const { id: toUrlID } = to.params
    const urlID = toUrlID || fromUrlID
    const { gameID, clientID } = Navigation.parseUrlID(urlID)
    const newUrlID = Navigation.createUrlID({ gameID, clientID })

    // TODO
    // revisit this reauth procedure

    // if (!redirect && from.name === null && to?.query?.auth !== "0") {
    //   let user
    //   try {
    //     user = await reauth()
    //   } catch (e) {
    //     console.log(e)
    //   }
    //   if (user) return next()
    // }

    if (!authorized || (authorize.length && !authorize.includes(role))) {
      console.warn("Not authorized")
      return next({ path: `/login/${newUrlID}`, query: to.query })
    }
  }

  next()
}
