<script>
import Vue from "vue"
import { throttle } from "lodash"
import { TweenMax, Power2 } from "gsap/TweenMax"

import MissionType from "@shared/enums/Mission"
import Mission from "@shared/Mission"

import Mode from "@shared/enums/Mode"
import useEnlargedUI from "@/use/useEnlargedUI"
import { getRectRelativeToParent } from "@/helpers/rect"

import { mission, mode } from "./common/props"

import { HostPosition } from "./types"

export default Vue.extend({
  name: "ModeMapperHostContainer",
  props: {
    tag: {
      type: String,
      default: "div"
    },
    height: {
      type: Number,
      default: 0
    },
    mission,
    mode,
    hasSubmittedVideoTeam: {
      default: false,
      type: Boolean
    }
  },
  setup() {
    const { enlargedUI } = useEnlargedUI()
    return { enlargedUI }
  },
  computed: {
    position() {
      return this.getPosition(this.mode)
    },
    rounded() {
      return (
        this.position === HostPosition.Rouned ||
        this.position === HostPosition.RondedBottom ||
        this.position === HostPosition.RondedBottom2
      )
    },
    badgeHidden() {
      return this.rounded
    },
    isDrawingMission() {
      return Mission.isDrawing(this.mission)
    },
    missionBehavior() {
      return this.mission?.behavior
    }
  },
  watch: {
    mode(_, oldVal) {
      this.previousMode = oldVal
    },
    position(newValue, oldValue) {
      if (
        oldValue === HostPosition.Rouned ||
        this.previousMode === Mode.Huddle ||
        this.mode === Mode.Huddle ||
        newValue === HostPosition.Default
      ) {
        this.animating = true
        this.animationTimeout = setTimeout(() => {
          this.animate({ delay: 0, duration: 0.5 })
          this.animationTimeout = null
        }, 800)
      } else if (
        this.previousMode === Mode.Welcome &&
        this.mode === Mode.Over
      ) {
        this.animate({ delay: 0 })
      } else {
        this.animate()
      }
    },
    height() {
      if (this.animating === false) {
        this.$nextTick(() => {
          this.animate({ duration: 0, delay: 0 })
        })
      }
    }
  },
  created() {
    this.windowResizeListener = throttle(this.windowResizeListener, 400)
    window.addEventListener("resize", this.windowResizeListener)
    // not reactive
    this.animationTimeout = null
    this.animating = false
  },
  mounted() {
    this.$nextTick(() => {
      this.animate({ duration: 0, delay: 0 })
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.windowResizeListener)
    clearTimeout(this.animationTimeout)
  },
  methods: {
    /** @param {Mode} mode */
    getPosition(mode) {
      if (
        [MissionType.JeopardAI, MissionType.MusicalKeys].includes(
          this.missionBehavior
        ) &&
        this.mode === Mode.Play
      )
        return HostPosition.Rouned

      if (
        (this.missionBehavior === MissionType.DiscussionGroup &&
          this.mode === Mode.Play) ||
        (this.mode === Mode.Theater &&
          this.$store.getters.actualGame?.autopilot)
      )
        return HostPosition.RondedBottom2

      if (mode === Mode.Welcome) {
        return HostPosition.Default
      } else if (
        (this.missionBehavior === MissionType.FactMatch &&
          (this.mode === Mode.Results || this.mode === Mode.Over)) ||
        (this.enlargedUI &&
          this.mode !== Mode.Results &&
          this.mode !== Mode.Over)
      ) {
        return HostPosition.RondedBottom
      } else if (
        (this.mode === Mode.Play &&
          this.missionBehavior === MissionType.SpiralDrawing) ||
        [Mode.Social, Mode.Voting, Mode.Over, Mode.Meeting].includes(mode) ||
        (this.isDrawingMission && this.mode !== Mode.Welcome) ||
        ([
          MissionType.FactMatch,
          MissionType.MatchGame,
          MissionType.OrderTheCards
        ].includes(this.missionBehavior) &&
          [Mode.Huddle, Mode.Results].includes(this.mode)) ||
        this.missionBehavior === MissionType.YouTube ||
        (this.missionBehavior === MissionType.TelephonePictionary &&
          [Mode.Play, Mode.Results].includes(this.mode)) ||
        (this.missionBehavior === MissionType.VideoTeam &&
          ([Mode.Huddle, Mode.Results].includes(this.mode) ||
            this.hasSubmittedVideoTeam))
      ) {
        return HostPosition.Rouned
      } else {
        return HostPosition.Minimized
      }
    },
    /** @param {{ duration?: number, delay?: number, ease?: unknown }} options */
    animate(options = {}) {
      const duration = options.duration ?? 0.75
      const delay = options.delay ?? 0.25
      const ease = options.ease ?? Power2.easeInOut
      const element = this.getHostPositionElement(this.position)
      if (element) {
        this.animating = true
        const {
          left: x,
          top: y,
          height
        } = getRectRelativeToParent(this.$el, element)

        this.tween = TweenMax.to(this.getRootElement(), duration, {
          x,
          y,
          height,
          width: height,
          ease,
          delay,
          onComplete: () => {
            this.animating = false
            this.tween = null
          }
        })
      }
    },
    /** @returns {HTMLElement} */
    getRootElement() {
      return this.$refs.root
    },
    /**
     * @param {HostPosition} position
     * @returns {HTMLElement}
     */
    getHostPositionElement(position) {
      return this.$refs[position]
    },
    /**
     * @param {HostPosition} position
     * @returns {string}
     */
    getPositionClass(position) {
      return `host-position host-position--${position}`
    },
    /**
     * @param {HostPosition} position
     * @returns {import('vue').VNode } }
     */
    renderHostPositionElement(position) {
      return this.$createElement("div", {
        ref: position,
        staticClass: this.getPositionClass(position)
      })
    },
    windowResizeListener() {
      if (this.animating === false) {
        this.animate({ duration: 0, delay: 0 })
      }
    }
  },
  /** @returns {import('vue').VNode } */
  render(h) {
    const content = this.$scopedSlots.default({
      rounded: this.rounded,
      badgeHidden: this.badgeHidden
    })

    return h(
      this.tag,
      {
        staticClass: "mode-mapper__host-container",
        style: `--container-height: ${this.height}px`,
        class: {
          [`--position--${this.position}`]: this.position
        }
      },
      [
        h("div", { ref: "root", staticClass: "__positioner" }, content),
        Object.values(HostPosition).map(this.renderHostPositionElement)
      ]
    )
  }
})
</script>

<style lang="scss">
@import "~vue-resize/dist/vue-resize.css";

.mode-mapper {
  &__host-container {
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    pointer-events: none;
    max-width: 82vw;
    transform: translateX(-50%);

    .__positioner {
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: auto;
    }
  }
}

.host-position {
  position: absolute;

  &--default {
    width: var(--container-height);
    max-width: 400px;
    max-height: 400px;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &--minimized {
    top: 50%;
    left: 75%;
    transform: translateY(-50%);
    width: 250px;
    height: 250px;
  }

  &--rounded {
    top: -20px;
    right: 0;
    width: 16vmin;
    height: 16vmin;
    min-width: 150px;
    min-height: 150px;
    transform: translateY(-50%);
  }

  &--rounded-bottom {
    top: 64px;
    right: 0;
    width: 16vmin;
    height: 16vmin;
    min-width: 150px;
    min-height: 150px;
    transform: translateY(-50%);
  }

  &--rounded-bottom-2 {
    top: 80px;
    right: 0;
    width: 16vmin;
    height: 16vmin;
    min-width: 150px;
    min-height: 150px;
    transform: translateY(-50%);
  }
}
</style>
