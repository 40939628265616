import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import axios from "./axios.service"

const getToken = () => firebase.auth().currentUser.getIdToken()
const getCurrentUser = () => firebase.auth().currentUser

const { Persistence } = firebase.auth.Auth

const persistence =
  Persistence[process.env.VUE_APP_AUTH_PESISTENCE] || Persistence.SESSION

const signInWithEmailAndPassword = async ({ email, password }) => {
  await firebase.auth().setPersistence(persistence)
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password)
  } catch (e) {
    console.log(e)
    throw new Error("The password is invalid or the user does not exist.")
  }
}

// TODO
// create users through API instead
const signUpSomeoneElse = async ({ email, password }) => {
  const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID
  }

  const app = firebase.initializeApp(config, "Secondary")

  try {
    const user = await app
      .auth()
      .createUserWithEmailAndPassword(email, password)

    app.auth().signOut()
    app.delete()

    return user
  } finally {
    app.delete()
  }
}

const signUpWithEmailAndPassword = async ({ email, password }) =>
  await firebase.auth().createUserWithEmailAndPassword(email, password)

const googleSingIn = async () => {
  await firebase.auth().setPersistence(persistence)
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

const sendResetPassword = ({ email, url }) =>
  firebase.auth().sendPasswordResetEmail(email, { url })

const verifyPasswordResetCode = actionCode =>
  firebase.auth().verifyPasswordResetCode(actionCode)

const confirmPasswordReset = (actionCode, newPassword) =>
  firebase.auth().confirmPasswordReset(actionCode, newPassword)

const applyActionCode = actionCode =>
  firebase.auth().applyActionCode(actionCode)

const sendEmailVerification = url =>
  firebase.auth().currentUser.sendEmailVerification({ url })

const reauth = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(data => {
      if (data) {
        unsubscribe()
        resolve(data)
      } else {
        unsubscribe()
        reject(new Error("Cannot reauthenticate"))
      }
    })
  })
}

const onAuthStateChanged = callback =>
  firebase.auth().onAuthStateChanged(callback)

const onIdTokenChanged = callback => firebase.auth().onIdTokenChanged(callback)

const signOut = () => firebase.auth().signOut()

const signInAnonymously = async () => {
  await firebase.auth().setPersistence(persistence)
  return firebase.auth().signInAnonymously()
}

export default class AuthService {
  static getCustomToken() {
    return axios({
      method: "get",
      url: `/auth/custom-token`
    })
  }
  static signInWithCustomToken(token) {
    return firebase.auth().signInWithCustomToken(token)
  }
  static async signOut() {
    await firebase.auth().signOut()
    window.location.reload(true)
  }
}

export {
  reauth,
  signOut,
  getToken,
  googleSingIn,
  sendResetPassword,
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode,
  getCurrentUser,
  signInAnonymously,
  signInWithEmailAndPassword,
  signUpWithEmailAndPassword,
  sendEmailVerification,
  onAuthStateChanged,
  onIdTokenChanged,
  signUpSomeoneElse
}
