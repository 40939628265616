var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M2 0h14a2 2 0 012 2v14a2 2 0 01-2 2H2a2 2 0 01-2-2v-4h2v4h14V2H2v4H0V2a2 2 0 012-2zm6.5 14l-1.41-1.41L9.67 10H0V8h9.67L7.09 5.41 8.5 4l5 5-5 5z",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }