export default [
  ["like", "heart", "laugh"],
  ["dislike", "plus", "happy"]
  // "sad",
  // "dead"
]

export const Reactions = {
  like: {
    icon: "like",
    color: "#ffd44c"
  },
  heart: {
    icon: "heart",
    color: "#ff4060"
  },
  laugh: {
    icon: "laugh",
    color: "#FFD1E1"
  },
  dislike: {
    icon: "dislike",
    color: "#ffd44c"
  },
  plus: {
    icon: "plus",
    color: "#6e69d1"
  },
  happy: {
    icon: "happy",
    color: "#ffd44c"
  }
}
