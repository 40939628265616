import { db } from "@/firebase"

export class OrgService {
  /** @param {string} id */
  static getOrg(id) {
    return db
      .ref(`orgs/${id}`)
      .once("value")
      .then(snapshot => {
        return snapshot.val()
      })
  }

  /**
   * @param {string} id
   * @returns {Promise<(import('../modules/theme/types').Theme | null)>}
   *  */
  static getOrgThemes(id) {
    return db
      .ref(`orgs/${id}/themes`)
      .once("value")
      .then(snapshot => {
        return snapshot.val()
      })
  }
}
