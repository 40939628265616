import { Constraints, ImageUploderError } from "./types"

export function getImageUrlBySize(url: string, size: number | string) {
  const arr = url.split(".")

  arr[arr.length - 2] = `${arr[arr.length - 2]}_${size}`

  return arr.join(".")
}

export function validateFile<T extends File | Blob>(
  file: T,
  { width, height, extensions, size }: Constraints
): Promise<T> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    if (file instanceof File) {
      const extension = file.name.split(".").pop()

      if (extensions.indexOf(extension) === -1) {
        reject(new ImageUploderError("", "extension"))
      }
    }

    if (file.size > size.max || file.size < size.min) {
      reject(new ImageUploderError("", "size"))
    }

    reader.readAsDataURL(file)

    reader.onload = () => {
      const img = new Image()

      img.onload = () => {
        if (
          img.width < width.min ||
          img.height < height.min ||
          img.width > width.max ||
          img.height > height.max
        ) {
          reject(new ImageUploderError("", "dimension"))
        } else {
          return resolve(file)
        }
      }

      if (typeof reader.result === "string") {
        img.src = reader.result
      } else {
        reject(new ImageUploderError("", "unknown"))
      }
    }
  })
}
