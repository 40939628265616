import { db } from "@/firebase"
import { Role } from "@/helpers"
import { chain, isEqual } from "lodash"
import MissionType from "@shared/enums/Mission"
import AudioOption from "@shared/enums/AudioOverride"
import MissionCollection from "@shared/MissionCollection"
import Mode from "@shared/enums/Mode"
import modes from "@shared/modes"

import ActionTypes from "./modules/MissionModule/action-types"

let audioSubscriptionRef = null

let gameStatusSubscriptionRef = null
let gameStatusSubscription = null

const DEFAULT_GAME_STATUS = {
  media: Role.Host,
  active: true,
  currentMode: Mode.Default
}

const MissionModule = {
  state: {
    modes,
    currentMission: null,
    currentMode: Mode.Default,
    missions: [],
    gameStatus: {},
    gameAudio: null,
    blocks: [],
  },
  mutations: {
    SET_CURRENT_MISSION(state, payload) {
      state.currentMission = payload
    },
    SET_MISSIONS(state, payload) {
      state.missions = payload
    },
    SET_GAME_STATUS(state, payload) {
      state.gameStatus = payload
    },
    setGameAudio(state, payload) {
      state.gameAudio = payload
    },
    UPDATE_MODE(state, mode) {
      state.currentMode = mode
    },
    UPDATE_GAMESTATUS_MODE(state, mode) {
      state.gameStatus.currentMode = mode
    },
    UPDATE_BLOCKS(state, blocks) {
      if (!blocks) {
        state.blocks = []
      } else {
        const array = Object.entries(blocks).map(([id, block]) => ({
          id,
          name: block.name,
          version: parseInt(block.version) || null
        }))
        state.blocks = chain(array)
          .sortBy("version")
          .sortBy(({ version }) => !version)
          .value()
      }
    },
    LOCAL_UPDATE_MEETING_MODE_ACTIVE_USER(state, userID) {
      state.gameStatus = {
        ...state.gameStatus,
        meetingActiveUser:
          state.gameStatus.meetingActiveUser === userID ? false : userID
      }
    }
  },
  getters: {
    missions(state) {
      return state.missions
    },
    blocks(state) {
      return state.blocks
    },
    getCurrentMission(state) {
      return state.currentMission
    },
    currentMission(state) {
      return state.currentMission ? state.currentMission.id : null
    },
    gameStatus(state) {
      return state.gameStatus
    },
    gameAudio(state) {
      return state.gameAudio
    },
    getCurrentMode(state) {
      return state.gameStatus?.currentMode || Mode.Default
    },
    everyoneCanHearEachOther(state) {
      return (
        state.gameStatus?.audioOption ===
        AudioOption.EVERYONE_CAN_HEAR_EACH_OTHER
      )
    },
    everyoneCanHearOnlyHost(state) {
      return (
        state.gameStatus?.audioOption ===
        AudioOption.EVERYONE_CAN_HEAR_ONLY_HOST
      )
    },
    everyoneCanHearOnlyTeammates(state) {
      return (
        state.gameStatus?.audioOption ===
        AudioOption.EVERYONE_CAN_HEAR_TEAMMATES
      )
    },
    getMeetingModeActiveUser(state) {
      return state.gameStatus.meetingActiveUser || false
    },
    getSelectedMeetingUsers(state) {
      return state?.gameStatus?.selectedMeetingUsers
    }
  },
  actions: {
    async updatePreGameMode({ commit }, payload) {
      commit("UPDATE_GAMESTATUS_MODE", payload)
    },
    async fetchBlocks({ rootState: { orgID }, commit }) {
      const snapshot = await db
        .ref(`org/${orgID}/games/`)
        .orderByChild("runStatus")
        .equalTo("Blocks")
        .once("value")

      commit("UPDATE_BLOCKS", snapshot.val())
    },
    setLocalCurrentMission({ commit }, payload) {
      commit("SET_CURRENT_MISSION", payload)
    },
    updateGameMedia({ rootState: { orgID, gameID } }, { media }) {
      return db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/gameStatus/media`)
        .set(media)
    },
    async setCurrentMission({ rootState }, payload) {
      console.log("SETTING CURRENT MISSION", payload)
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      await db
        .auxiliary()
        .ref(thePath + "/gameStatus")
        .update(payload)
    },
    subscribeToGameAudio({ rootState, commit }) {
      const path = "org/" + rootState.orgID + "/game/" + rootState.gameID
      if (audioSubscriptionRef) audioSubscriptionRef.off()
      audioSubscriptionRef = db.auxiliary().ref(path + "/audio")
      audioSubscriptionRef.on("value", snapshot => {
        commit("setGameAudio", snapshot.val())
      })
    },
    updateMission({ rootState }, payload) {
      console.log("PAYLOAD MISSION", payload)
      const id = payload.theKey
      delete payload.theKey
      const data = JSON.parse(JSON.stringify(payload))
      const path =
        "org/" +
        rootState.orgID +
        "/game/" +
        rootState.gameID +
        "/missions/" +
        id
      db.auxiliary().ref(path).update(data)
    },
    async updateSocialMissionStatus({ rootState }, { hidden, missionID }) {
      await db
        .auxiliary()
        .ref(
          `org/${rootState.orgID}/game/${rootState.gameID}/missions/${missionID}`
        )
        .update({ hidden })
    },
    async copyBlock({ rootState, dispatch }, { gameID, index, missionKey }) {
      const { orgID } = rootState
      const snapshot = await db
        .ref(`org/${orgID}/game/${gameID}/missions`)
        .once("value")
      const array = MissionCollection.normalize(snapshot.val()).forEach(
        mission => {
          mission.oldKey = mission.id
        }
      )
      await dispatch("addToMissions", { array, index, missionKey })
    },
    async addToMissions({ rootState }, payload) {
      if (typeof payload !== "object") throw new Error()

      const array = payload.array
      const orgID = payload.orgID || rootState.orgID
      const gameID = payload.gameID || rootState.gameID
      const missionKey = payload.missionKey ?? null

      if (!orgID) throw new Error()
      if (!gameID) throw new Error()
      if (!Array.isArray(array)) throw new Error()
      if (!array.length) throw new Error()

      const path = `org/${orgID}/game/${gameID}/missions`
      const ref = db.auxiliary().ref(path)

      if (array.length === 1 && missionKey == null) {
        console.log(`pushing just one mission`)
        return await ref.push(array[0])
      }

      const snapshot = await ref.once("value")

      const missions = MissionCollection.normalize(snapshot.val())

      // set new mission keys
      const keyed = array.map(mission => {
        const key = ref.push().key
        return { ...mission, id: key, theKey: key }
      })

      // perfomance
      const oldKeysMissionMap = keyed.reduce((acc, curr) => {
        if (curr?.oldKey) {
          acc[curr.oldKey] = curr
        }
        return acc
      }, {})

      const linked = keyed.map(mission => {
        const missionBehavior = mission?.behavior

        const linkableMissions = [
          MissionType.FactMatch,
          MissionType.TwoTruthsReveal
        ]

        if (linkableMissions.includes(missionBehavior)) {
          if (missionBehavior === MissionType.FactMatch) {
            mission.freeFormMissionID =
              oldKeysMissionMap?.[mission?.freeFormMissionID]?.id
          } else if (missionBehavior === MissionType.TwoTruthsReveal) {
            mission.twoTruthsMissionID =
              oldKeysMissionMap?.[mission?.twoTruthsMissionID]?.id
          }
        }

        return { ...mission }
      })

      let newSetOfMissions

      const missionKeyIndex = missions.findIndex(
        mission => mission.id == missionKey
      )

      if (missionKey == null || missionKeyIndex === -1) {
        console.log(`pushing with null index`)
        newSetOfMissions = [...missions, ...linked]
      } else {
        console.log(`pushing to the middle`)
        newSetOfMissions = [
          ...missions.slice(0, missionKeyIndex + 1),
          ...linked,
          ...missions.slice(missionKeyIndex + 1)
        ]
      }

      const update = newSetOfMissions.reduce((acc, mission, index) => {
        acc[mission.id] = { ...mission, pos: index }
        return acc
      }, {})

      console.log("new set", Object.values(update))

      await ref.set(update)
    },
    updateGameStatusAny({ rootState }, payload) {
      const gameID = payload?.gameID || rootState.gameID
      const path = `org/${rootState.orgID}/game/${gameID}/gameStatus`
      return db.auxiliary().ref(path).update(payload)
    },
    subscribeToGameStatus({ commit, state, rootState, rootGetters }) {
      if (gameStatusSubscriptionRef)
        gameStatusSubscriptionRef.off("value", gameStatusSubscription)

      gameStatusSubscriptionRef = db
        .auxiliary()
        .ref(`org/${rootState.orgID}/game/${rootState.gameID}/gameStatus`)

      return new Promise(resolve => {
        gameStatusSubscription = gameStatusSubscriptionRef.on(
          "value",
          snapshot => {
            const value = snapshot.val()
            if (value) {
              commit("SET_GAME_STATUS", value)

              if (!isEqual(state.currentMission, value.current_mission)) {
                commit("SET_CURRENT_MISSION", value.current_mission)
              }

              if (
                rootGetters["group/globalTeamID"] !== value.getCurrentGlobalTeam
              ) {
                commit(
                  "group/UPDATE_CURRENT_GLOBAL_TEAM",
                  value.getCurrentGlobalTeam,
                  { root: true }
                )
              }

              resolve()
            } else {
              gameStatusSubscriptionRef.update(DEFAULT_GAME_STATUS)
              commit("SET_GAME_STATUS", DEFAULT_GAME_STATUS)
              resolve()
            }
          }
        )
      })
    },
    async removeMission({ rootState }, payload) {
      const id = payload.id
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      await db
        .auxiliary()
        .ref(thePath + "/missions/" + id)
        .remove()
    },
    async fetchMissions({ commit, rootState }, gameID) {
      if (!gameID) gameID = rootState.gameID
      const path = "org/" + rootState.orgID + "/game/" + gameID + "/missions"
      const ref = db.auxiliary().ref(path)
      const snapshot = await ref.once("value")
      commit("SET_MISSIONS", snapshot.val())
    },
    updateMeetingModeActiveUser({ rootState, getters }, userID) {
      const thePath = "org/" + rootState.orgID + "/game/" + rootState.gameID
      db.auxiliary()
        .ref(thePath + "/gameStatus")
        .update({
          meetingActiveUser:
            getters.getMeetingModeActiveUser === userID ? false : userID
        })
    },
    [ActionTypes.UPDATE_SELECTED_MEETING_USER](
      { rootState: { orgID, gameID }, getters },
      { userID, value }
    ) {
      const selectedMeetingUsers = getters?.getSelectedMeetingUsers
      const dbPath = `org/${orgID}/game/${gameID}/gameStatus/selectedMeetingUsers`
      return db
        .auxiliary()
        .ref(dbPath)
        .update({
          [userID]: value ?? (!selectedMeetingUsers?.[userID] ? true : null)
        })
    },
  }
}

export default MissionModule
