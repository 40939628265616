
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "RTBCard",
  functional: true,
  props: {
    tag: {
      type: String,
      default: "div"
    }
  },
  render(h, { props, children, data }) {
    return h(props.tag, mergeData(data, { staticClass: "rtb-card" }), children)
  }
})
