
import type { PropType } from "vue"
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

import { colors, variants, sizes } from "./types"

export default Vue.extend({
  name: "RTBButton",
  functional: true,
  props: {
    color: {
      type: String as PropType<typeof colors[number]>,
      validator: val => colors.includes(val),
      default: "primary" as const
    },
    variant: {
      type: String as PropType<typeof variants[number]>,
      default: "default" as const,
      validator: val => variants.includes(val)
    },
    disabled: {
      type: Boolean
    },
    capitalize: {
      type: Boolean,
      default: undefined
    },
    size: {
      type: String as PropType<typeof sizes[number]>,
      default: "default" as const,
      validator: val => sizes.includes(val)
    },
    circle: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    animated: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props, children, data }) {
    return h(
      "button",
      mergeData(data, {
        attrs: {
          disabled: props.disabled
        },
        // TODO(Anrew): rename to 'rtb-button
        staticClass: "rtb-button-next",
        // TODO(Anrew): rename to 'rtb-button
        class: {
          [`rtb-button-next--color--${props.color}`]: props.color,
          [`rtb-button-next--variant--${props.variant}`]: props.variant,
          [`rtb-button-next--size--${props.size}`]: props.size !== "default",
          "rtb-button-next--disabled": props.disabled,
          "rtb-button-next--circle": props.circle,
          "rtb-button-next--outline": props.outline,
          "rtb-button-next--capitalize": props.capitalize,
          "rtb-button-next--animated": props.animated
        }
      }),
      children
    )
  }
})
