import Vue from "vue"
import VueCompositionAPI from "@vue/composition-api"
Vue.use(VueCompositionAPI)
import { db } from "@/firebase"
import Team from "@shared/Team"
import User from "@shared/User"
import { MULTI_TEAM_THRESHOLD } from "@shared/config"
import Mission from "@shared/enums/Mission"
import Mode from "@shared/enums/Mode"
import modes from "@shared/modes"
import {
  charadesStatus,
  getIsCompleted
} from "@/components/GroupTeams/Common/Games/Charades/useCharades" // charadesStatus
import { visibleUserIds } from "@/components/GroupTeams/Common/Games/MusicalKeys/useMusicalKeysState.ts"
import LoginCustomInput from "@shared/enums/LoginCustomInput"
import { Module } from "@/components/GroupTeams/Common/Games/JeopardAI/enums"
import { maxBy } from "lodash"
let missionVideoStateSubscription = null

// TODO - rework user mute logic so it's all defined by
// scope options e.g. ['team-members', 'scribes', 'hosts'] vs ['team-members', 'hosts']

const GroupModule = {
  namespaced: true,
  state: {
    modes,
    globalTeamID: null,
    votingOverride: false,
    isUsersLocallyMuted: false,
    socialOverride: false,
    media: null
  },
  mutations: {
    UPDATE_CURRENT_GLOBAL_TEAM(state, teamID) {
      state.globalTeamID = teamID
    },
    UPDATE_MISSION_VIDEO_STATE(state, payload) {
      state.media = payload
    },
    UPDATE_LOCAL_SOCIAL_MODE(state, payload) {
      state.socialOverride = payload
    },
    UPDATE_IS_USERS_LOCALLY_MUTED(state, value) {
      state.isUsersLocallyMuted = value
    }
  },
  actions: {
    updateSocialOverride({ commit }, payload) {
      commit("UPDATE_LOCAL_SOCIAL_MODE", payload)
    },
    autoScribeAndTeam({ rootGetters }) {
      db.auxiliary()
        .ref(
          `org/${rootGetters.orgID}/game/${rootGetters.gameID}/autoTeamRequestQueue`
        )
        .push(true)
    },
    async updateCurrentGlobalTeam({ commit, rootState, rootGetters }, id) {
      commit("UPDATE_CURRENT_GLOBAL_TEAM", id)
      const user = rootGetters["auth/user"]
      if (!User.isHost(user)) return
      await db
        .auxiliary()
        .ref(
          `org/${rootState.orgID}/game/${rootState.gameID}/gameStatus/getCurrentGlobalTeam`
        )
        .set(id)
    },
    updateMissionAudioState(
      { rootState, rootGetters },
      { paused, seekTime = 0 }
    ) {
      const { orgID, gameID } = rootState
      const missionID = rootGetters?.getCurrentMission?.id
      return db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/media/${missionID}`)
        .update({
          paused,
          currentTime: seekTime
        })
    },
    subscribeToMissionVideoState({ rootState, commit }) {
      const { orgID, gameID } = rootState
      if (missionVideoStateSubscription)
        missionVideoStateSubscription.off("value")
      missionVideoStateSubscription = db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/media`)
      missionVideoStateSubscription.on("value", snapshot => {
        commit("UPDATE_MISSION_VIDEO_STATE", snapshot.val())
      })
    },
    updateMissionMediaState({ rootState, rootGetters }, payload) {
      const { orgID, gameID } = rootState
      const missionID = payload.id || rootGetters.getCurrentMission?.id
      return db
        .auxiliary()
        .ref(`org/${orgID}/game/${gameID}/media/${missionID}`)
        .update({ ...payload, timestamp: Date.now() })
    }
  },
  getters: {
    isViewerAuditorLike(_, __, rootState) {
      return User.isAuditorLike(rootState.auth.user)
    },
    isViewerAuditor(_, __, rootState) {
      return User.isAuditor(rootState.auth.user)
    },
    isViewerHost(_, __, rootState) {
      return User.isHost(rootState.auth.user)
    },
    isViewerHostLike(_, getters, rootState) {
      return getters.isUserHostLike(rootState.auth.user)
    },
    isViewerPresenter(_, __, rootState) {
      return User.isPresenter(rootState.auth.user)
    },
    isUserHostLike(_, __, ___, rootGetters) {
      return user => User.isHost(user) || user?.id === rootGetters.moderatorID
    },
    isUserTeammate(_, getters, ___, rootGetters) {
      const viewer = rootGetters["auth/user"]
      const globalTeamID = getters.globalTeamID
      return user =>
        user.teamID === viewer.teamID ||
        (getters.isUserHostLike(user) && viewer.teamID === globalTeamID) ||
        (getters.isUserHostLike(viewer) && user.teamID === globalTeamID)
    },
    media(state, _, __, rootGetters) {
      return state.media?.[rootGetters.currentMission]
    },
    votingOverride(state) {
      return state.votingOverride
    },
    socialOverride(state) {
      return state.socialOverride
    },
    isMultiTeam(_, __, ___, { chats }) {
      return Object.keys(chats || {}).length > MULTI_TEAM_THRESHOLD
    },
    canViewerSelectGlobalTeam(_, __, ___, rootGetters) {
      const viewer = rootGetters["auth/user"]
      return (
        User.isHost(viewer) ||
        User.isPresenter(viewer) ||
        User.isAuditor(viewer) ||
        User.isSpectator(viewer)
      )
    },
    hasPinnedUser(_, getters, __, rootGetters) {
      return rootGetters.onlineUsersArray.some(getters.isPinnedUser)
    },
    isPinnedUser(_, __, ___, rootGetters) {
      return user => user.id === rootGetters.gameStatus?.pinedUserID
    },
    hasBreakouts(_, getters) {
      return getters.teams.some(({ show, breakout }) => breakout && show)
    },
    teams(_, __, ___, rootGetters) {
      return Team.normalize(rootGetters.chats || {}).sort(Team.sort)
    },
    globalTeamID(state, getters, __, rootGetters) {
      const teamId =
        state.globalTeamID ?? rootGetters.gameStatus?.getCurrentGlobalTeam
      const teams = getters.teams

      if (teams.some(team => team.id === teamId)) return teamId

      return teams[0]?.id
    },
    isOnePersonOverride(_, __, ___, rootGetters) {
      const isDemo = Boolean(rootGetters["auth/client"]?.demo)
      return !!(
        (rootGetters.game?.diy || rootGetters.game?.moderated || isDemo) &&
        rootGetters["GameUsers/isOneUserPerTeam"] &&
        rootGetters.gameStatus?.currentMode !== Mode.Meeting
      )
    },
    isUserMutedByRoomLogic(_, getters, rootState, rootGetters) {
      const missionType = rootGetters.getCurrentMission?.behavior
      const viewer = rootState.auth.user
      const mode = rootGetters.getCurrentMode

      return user => {
        if (!user) return false

        if (user.id === viewer.id) return false

        if (
          rootGetters.everyoneCanHearOnlyHost &&
          !getters.isUserHostLike(user)
        )
          return true

        if (rootGetters.everyoneCanHearOnlyTeammates) {
          return !getters.isUserTeammate(user) && !getters.isUserHostLike(user)
        }

        if (
          User.isPlayer(user) &&
          getters.isViewerAuditorLike &&
          viewer.muteAllPlayers
        )
          return true

        if (rootGetters.everyoneCanHearEachOther || getters.isOnePersonOverride)
          return false

        if (user.muted && !user.selected) return true

        if (mode === Mode.Play && missionType === Mission.MusicalKeys)
          return getters.isUserMutedInMusicalKeys(user)

        if (mode === Mode.Play && missionType === Mission.JeopardAI)
          return getters.isUserMutedInJeopardAi(user)

        if (mode === Mode.Play && missionType === Mission.SpiralDrawing)
          return getters.isUserMutedInSocialMode(user)

        if (
          mode === Mode.Play &&
          missionType === Mission.UnconditionalPositiveRegard
        )
          return getters.isUserMutedInSocialMode(user)

        if (mode === Mode.Welcome) return false

        if (mode === Mode.Explain) return getters.isUserMutedInExplainMode(user)

        if (mode === Mode.Huddle) return getters.isUserMutedInHuddleMode(user)

        if (
          mode === Mode.Meeting &&
          getters.hasBreakouts &&
          getters.hasPinnedUser
        )
          return (
            !getters.isUserHostLike(user) &&
            !getters.isPinnedUser(user) &&
            getters.isUserMutedInHuddleMode(user)
          )

        if (mode === Mode.Meeting && getters.hasBreakouts)
          return getters.isUserMutedInHuddleMode(user)

        if (mode === Mode.Play && missionType === Mission.DiscussionGroup)
          return getters.isUserMutedInHuddleMode(user)

        if (mode === Mode.Play) return getters.isUserMutedInPlayMode(user)

        if (mode === Mode.Social && missionType === Mission.Lipdub)
          return getters.isUserMutedInSocialMode(user)

        if (mode === Mode.Social && missionType === Mission.Charades)
          return getters.isUserMutedInCharades(user)

        if (
          mode === Mode.Social &&
          [Mission.OrderTheCards, Mission.MatchGame].includes(missionType)
        )
          return getters.isUserMutedInPlayMode(user)

        if (mode === Mode.Social && missionType === Mission.TwoTruthsReveal)
          return getters.isUserMutedInTwoTruthsMode(user)

        if (mode === Mode.Social && missionType === Mission.Award) return false

        if (mode === Mode.Social && missionType === Mission.TakePhoto)
          return getters.isUserMutedInPhotoAward(user)

        if (mode === Mode.Social) return getters.isUserMutedInSocialMode(user)

        if (mode === Mode.Voting && rootGetters.game?.diy) return false

        if (mode === Mode.Voting) return getters.isUserMutedInPlayMode(user)

        if (mode === Mode.Meeting) return getters.isUserMutedInMeetingMode(user)

        return false
      }
    },
    isUserMutedInPhotoAward(_, getters, __, rootGetters) {
      return user => {
        if (getters.isUserHostLike(user)) return false
        const play = maxBy(rootGetters.missionPlaysArray, "score")

        if (rootGetters.gameStatus.awardCandidates) return true

        if (user.id === play.userID) return false

        return true
      }
    },
    isUserMutedInExplainMode(_, getters) {
      return user => {
        if (getters.isUserHostLike(user)) return false

        return true
      }
    },
    isUserMutedInHuddleMode(_, getters, rootState, rootGetters) {
      return user => {
        const viewer = rootState.auth.user
        const globalTeamID = getters.globalTeamID
        const broadcastToAll = rootGetters.gameStatus?.broadcastToAll

        if (broadcastToAll && getters.isUserHostLike(user)) return false

        if (broadcastToAll) return true

        if (viewer.teamID === user.teamID) return false

        if (
          (getters.isViewerAuditorLike || getters.isViewerHostLike) &&
          user.teamID === globalTeamID
        )
          return false

        // hmmm what is it for?
        if (viewer.teamID === globalTeamID && getters.isUserHostLike(user))
          return false

        return true
      }
    },
    isUserMutedInTwoTruthsMode(_, getters, __, rootGetters) {
      return user => {
        if (user.id == rootGetters.gameStatus.twoTruthsID) return false

        if (getters.isUserHostLike(user)) return false

        if (getters.isViewerAuditorLike && user.teamID === getters.globalTeamID)
          return false

        if (getters.isViewerHostLike && user.selected) return false

        if (user.teamID === rootGetters.teamID) return false

        return true
      }
    },
    isUserMutedInJeopardAi(_, getters, rootState) {
      const viewer = rootState.auth.user
      const module = rootState.JeopardAi.module
      const isViewerHostLike = getters.isViewerHostLike
      const isViewerAuditorLike = getters.isViewerAuditorLike
      const viewerTeamId =
        isViewerHostLike || isViewerAuditorLike
          ? getters.globalTeamID
          : viewer.teamID

      const isUserJeopardAiScribe = user =>
        rootState.JeopardAi.scribes.some(u => u.id === user.id)

      const isUserJeopardAiBuzzer = user =>
        rootState.JeopardAi.users.some(u => u.id === user.id)

      if (module === Module.CATEGORIES && isViewerHostLike) {
        return user => {
          if (User.isScribe(user)) return false
          if (isUserJeopardAiScribe(user)) return false
          if (getters.isUserHostLike(user)) return false
          return true
        }
      }

      if (module === Module.CATEGORIES) {
        return user => {
          if (viewerTeamId === user.teamID) return false
          if (getters.isUserHostLike(user)) return false
          return true
        }
      }

      if (module === Module.QUESTIONS) {
        return user => {
          if (viewerTeamId === user.teamID) return false
          if (User.isScribe(user)) return false
          if (isUserJeopardAiScribe(user)) return false
          if (getters.isUserHostLike(user)) return false
          return true
        }
      }

      if (module === Module.ANSWERS && isViewerHostLike) {
        return user => {
          if (getters.isUserHostLike(user)) return false
          if (User.isScribe(user)) return false
          if (isUserJeopardAiScribe(user)) return false
          if (isUserJeopardAiBuzzer(user)) return false
          return true
        }
      }

      if (module === Module.ANSWERS) {
        return user => {
          if (viewerTeamId === user.teamID) return false
          if (getters.isUserHostLike(user)) return false
          if (isUserJeopardAiBuzzer(user)) return false
          return true
        }
      }

      if (module === Module.FINALE && isViewerHostLike) {
        return user => {
          if (getters.isUserHostLike(user)) return false
          if (User.isScribe(user)) return false
          if (isUserJeopardAiScribe(user)) return false
          if (isUserJeopardAiBuzzer(user)) return false
          return true
        }
      }

      return user => {
        if (viewerTeamId === user.teamID) return false
        if (getters.isUserHostLike(user)) return false
        return true
      }
    },
    isUserMutedInMusicalKeys(_, getters) {
      return user => {
        return (
          !visibleUserIds.value?.includes(user.id) &&
          !getters.isUserTeammate(user) &&
          !getters.isUserHostLike(user)
        )
      }
    },
    isUserMutedInSocialMode(_, getters, __, rootGetters) {
      return user => {
        if (getters.isUserHostLike(user)) return false

        if (getters.isViewerAuditorLike && getters.globalTeamID === user.teamID)
          return false

        if (user.selected) return false

        if (user.teamID === rootGetters.teamID) return false

        return true
      }
    },
    isUserMutedInPlayMode(_, getters, __, rootGetters) {
      return user => {
        if (getters.isUserHostLike(user)) return false

        if (getters.isViewerAuditorLike && getters.globalTeamID === user.teamID)
          return false

        if (getters.isViewerHostLike && user.selected) return false

        if (user.teamID === rootGetters.teamID) return false

        return true
      }
    },
    isUserMutedInMeetingMode(_, __, ___, rootGetters) {
      return user => !User.isHost(user) && rootGetters.everyoneCanHearOnlyHost
    },
    isUserMutedInCharades(_, getters, rootState) {
      const viewer = rootState.auth.user
      const isStarted = charadesStatus?.value?.started

      return user => {
        if (getters.isUserHostLike(user)) return false

        if (!isStarted) return false

        if (getters.isUserHostLike(viewer) && !User.isScribe(user)) return true

        if (
          User.isPlayer(viewer) &&
          User.isScribe(user) &&
          !getIsCompleted(viewer)
        )
          return true

        if (User.isPlayer(viewer) && viewer.teamID !== user.teamID) return true
      }
    },
    hasHybridLocations(_, __, rootState, rootGetters) {
      return Boolean(
        rootState.auth.client?.customInputType === LoginCustomInput.Location &&
          rootGetters["auth/isHybridRoom"]
      )
    },
    isUserIRLSpeaker(_, __, ___, rootGetters) {
      return user => {
        return Boolean(
          rootGetters["auth/isHybridRoom"] &&
            rootGetters.game?.speakers?.[user.id]
        )
      }
    }
  }
}

export default GroupModule
