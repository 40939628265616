/**
 * GameType
 * @readonly
 * @enum {string} GameType
 */
export const GameType = {
  Standard: "Standard",
  YouTube: "YouTube",
  Twitch: "Twitch",
  FlashCard: "Flash Cards",
  Image: "Image",
  GreenRoom: "Green Room",
  Scoreboard: "Scoreboard",
  OurVideo: "Our Video"
}
