import { db } from "@/firebase"

import { fetchGame } from "../services/game.service"

let userSubscription = null
let userSubscriptionRef = null

let userRoomSubscription = null
let userRoomSubscriptionRef = null

let userRoomIDSubscription = null
let userRoomIDSubscriptionRef = null

const Profile = {
  namespaced: true,
  state: {
    user: null,
    game: null,
    room: null,
    roomID: null
  },
  mutations: {
    UPDATE_USER(state, { user }) {
      state.user = user
    },
    UPDATE_GAME(state, { game }) {
      state.game = game
    },
    UPDATE_USER_ROOM(state, { room }) {
      state.room = room
    },
    UPDATE_USER_CURENT_ROOM_ID(state, { roomID }) {
      state.roomID = roomID
    },
    PURGE_DATA(state) {
      state.roomID = null
      state.user = null
      state.room = null
      state.game = null
    },
    PURGE_ROOM_DATA(state) {
      state.roomID = null
      state.room = null
    }
  },
  actions: {
    async fetchGame({ commit }, { gameID, orgID }) {
      const game = await fetchGame({ gameID, orgID })
      commit("UPDATE_GAME", { game })
    },
    async purgeRoomData({ commit }) {
      commit("PURGE_ROOM_DATA")
    },
    async purgeData({ commit }) {
      commit("PURGE_DATA")
    },
    async unsubscribeFromUserRoomID({ commit }) {
      if (userRoomIDSubscriptionRef)
        userRoomIDSubscriptionRef.off("value", userRoomIDSubscription)
      commit("UPDATE_USER_CURENT_ROOM_ID", { roomID: null })
    },
    async subscribeToUserRoomID({ commit, dispatch, rootGetters }, { userID }) {
      const clientID = rootGetters["auth/clientID"]
      await dispatch("unsubscribeFromUserRoomID")

      userRoomIDSubscriptionRef = db
        .auxiliary()
        .ref(`client/${clientID}/calls/users/${userID}/rooms`)
        .orderByChild("status")
        .equalTo("active")
        .limitToLast(1)

      return new Promise(resolve => {
        userRoomIDSubscription = userRoomIDSubscriptionRef.on(
          "value",
          snapshot => {
            const value = snapshot.val()
            const room = value ? value[Object.keys(value)[0]] : null
            const roomID = room ? room.roomID : null
            commit("UPDATE_USER_CURENT_ROOM_ID", { roomID })
            resolve()
          }
        )
      })
    },
    async unsubscribeFromUserRoom({ commit }) {
      if (userRoomSubscriptionRef)
        userRoomSubscriptionRef.off("value", userRoomSubscription)
      commit("UPDATE_USER_ROOM", { room: null })
    },
    async subscribeToUserRoom({ commit, dispatch, rootGetters }, { roomID }) {
      const clientID = rootGetters["auth/clientID"]
      await dispatch("unsubscribeFromUserRoom")
      userRoomSubscriptionRef = db
        .auxiliary()
        .ref(`client/${clientID}/calls/rooms/${roomID}`)

      return new Promise(resolve => {
        userRoomSubscription = userRoomSubscriptionRef.on("value", snapshot => {
          const value = snapshot.val()
          const room = value ? { id: roomID, ...value } : null
          commit("UPDATE_USER_ROOM", { room })
          resolve()
        })
      })
    },
    async subscribeToUser({ commit, dispatch }, { userID }) {
      await dispatch("unsubscribeFromUser")
      userSubscriptionRef = db.auxiliary().ref(`org/1/users/${userID}`)
      return new Promise(resolve => {
        userSubscription = userSubscriptionRef.on("value", snapshot => {
          commit("UPDATE_USER", { user: snapshot.val() })
          resolve()
        })
      })
    },
    unsubscribeFromUser({ commit }) {
      if (userSubscriptionRef)
        userSubscriptionRef.off("value", userSubscription)
      commit("UPDATE_USER", { user: null })
      commit("UPDATE_GAME", { game: null })
    }
  },
  getters: {
    room({ room }) {
      return room
    },
    user(state) {
      return state.user
    },
    game(state) {
      return state.game
    },
    roomID(state) {
      return state.roomID
    }
  }
}

export default Profile
