// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/star-text.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* These colors are supposed to by dynamyc during the runtime */\n/* Please, don't place static colors here, if they are not supposed to be changed in runtime */\n/** Please, use this mixin as the SSOT **/\n/** $theme: \"light\" | \"dark\" **/\n.rtb-border-radius {\n  border-radius: 8px;\n}\n.rtb-border {\n  border: solid 0px var(--primary-accent-color);\n}\n.rtb-box-shadow {\n  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);\n}\n.ring-stars-animation {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  left: 0;\n  pointer-events: none;\n  z-index: -1;\n}\n.ring-stars-animation::before {\n  content: \"\";\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  border-radius: var(--user-card-radius, 16px);\n  border-width: 5px;\n  border-style: solid;\n  border-color: var(--primary-accent-color);\n  transition: border-radius 0.3s;\n  opacity: 0;\n}\n.ring-stars-animation--circle::before {\n  border-radius: 50%;\n}\n.ring-stars-animation--animated::before {\n  z-index: 1;\n  animation: ringBounceOut 1s;\n}\n.ring-stars-animation--starred {\n  z-index: 1;\n}\n.ring-stars-animation--starred::before {\n  border-color: var(--secondary-accent-color);\n  border-width: 3px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center bottom 20px;\n  background-size: 80px;\n  background-repeat: no-repeat;\n  z-index: 1;\n  opacity: 1;\n}\n.ring-stars-animation .star-explosion {\n  pointer-events: none;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translateX(-50%) translateY(-50%);\n  background-color: transparent;\n}\n@keyframes ringBounceOut {\n0% {\n    opacity: 0;\n    transform: scale(1);\n}\n30% {\n    opacity: 0.1;\n    transform: scale(1.01);\n}\n50% {\n    opacity: 1;\n}\nto {\n    opacity: 0;\n    transform: scale(1.33);\n}\n}", ""]);
// Exports
module.exports = exports;
