import { db } from "@/firebase"

let twitchSubscription = null
let twitchSubscriptionRef = null

const ScreenshotModule = {
  namespaced: true,
  state: {
    twitchAccounts: []
  },
  mutations: {
    UPDATE_TWITCH_ACCOUNTS(state, accounts) {
      state.twitchAccounts = accounts
    }
  },
  actions: {
    async subscribeToTwitchAccounts({ commit, dispatch, rootState }) {
      const orgID = rootState.orgID
      await dispatch("unsubscribeToTwitchAccounts")
      twitchSubscriptionRef = db.ref(`orgs/${orgID}/twitchAccounts`)
      return new Promise(resolve => {
        twitchSubscription = twitchSubscriptionRef.on("value", snapshot => {
          commit("UPDATE_TWITCH_ACCOUNTS", snapshot.val())
          resolve()
        })
      })
    },
    unsubscribeToTwitchAccounts({ commit }) {
      if (twitchSubscriptionRef)
        twitchSubscriptionRef.off("value", twitchSubscription)
      commit("UPDATE_TWITCH_ACCOUNTS", {})
    },
    addTwitchAccount({ rootState }, twitch) {
      if (!twitch) throw new Error("Twitch data is empty")
      const orgID = rootState.orgID
      db.ref(`orgs/${orgID}/twitchAccounts`).push(twitch)
    },
    async updateTwitchAccount({ rootState }, payload) {
      const { key, obj } = payload
      if (key === -1) return
      if (!key || !obj) throw new Error("Twitch data is empty")
      await db
        .ref(`orgs/${rootState.orgID}/twitchAccounts/${payload.key}`)
        .update(payload.obj)
    },
    async removeTwitchAccount({ rootState }, key) {
      if (!key) throw new Error("Firebase twitch key is required")
      const orgID = rootState.orgID
      await db.ref(`orgs/${orgID}/twitchAccounts/${key}`).remove()
    }
  },
  getters: {
    getTwitchAccounts: state => {
      try {
        return Object.keys(state.twitchAccounts || {}).map(key => {
          return {
            key,
            ...state.twitchAccounts[key]
          }
        })
      } catch (e) {
        console.warn(e.message)
        return []
      }
    }
  }
}

export default ScreenshotModule
