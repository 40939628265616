var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "hide-overlay": "",
        width: "412px",
        "content-class": "settings-dialog",
        transition: "slide-x-reverse-transition",
      },
      model: {
        value: _vm.opened,
        callback: function ($$v) {
          _vm.opened = $$v
        },
        expression: "opened",
      },
    },
    [
      _vm.contentVisibility
        ? _c("AudioVideoSettings", { on: { onDone: _vm.done } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }