
import Vue, { PropType } from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "RTBAlert",
  functional: true,
  props: {
    title: {
      type: String as PropType<string | undefined>
    }
  },
  render(h, { props, children, data }) {
    return h(
      "div",
      mergeData(data, { attrs: { role: "alert" }, staticClass: "rtb-alert" }),
      [
        h("div", { staticClass: "rtb-alert__inner" }, [
          props.title
            ? h("div", { staticClass: "rtb-alert__title" }, props.title)
            : null,
          h("div", { staticClass: "rtb-alert__content" }, children)
        ])
      ]
    )
  }
})
