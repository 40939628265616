enum message {
  LOGOUT_ANON_CONFIRM = "Since you use an anonymous account, we won't recognize you when you come back. Are you sure you want to log out?",
  NO_SLEEP_REQUEST = "To continue, you have to allow us to keep your device awake.",
  AUTO_PLAY_REQUEST = "To continue, you have to allow us to auto-play media files.",
  ALL_TALK_CONFIRM = `Are you sure you would like to turn on "All participant talk"? Everyone in
  the room will be able to hear each other until you return to "Auto room
  audio"`,
  SUMMON_HOST_CONFIRM = "Are you sure you would like to call a pro host to answer questions or run a 5-10 min activity?",
  CHECK_SOUND_LABEL = "Click for the sound check",
  CHECK_MIC_LABEL = "You should see the bouncing bars if your mic is working",
  WE_CANT_DETECT_ANY_ISSUES = 'Sorry, we can’t detect any issues automatically. Try <a target="_blank" href="https://www.weve.co/support"> here </a> for sound support, and then refresh your browser.'
}

export default message
