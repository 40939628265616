const Collection = require("./Collection")
const Mission = require("./Mission")
const { v4: uuidv4 } = require("uuid")

class MissionCollection extends Collection {
  static normalize(obj = {}) {
    return Object.entries(obj || {})
      .filter(
        ([id, mission]) =>
          id &&
          id !== "undefined" &&
          id !== "null" &&
          mission &&
          mission.name &&
          mission.behavior
      )
      .map(([id, mission]) => {
        mission.id = id
        return mission
      })
      .sort((a, b) => a.pos - b.pos)
  }
  static denormalize(array = []) {
    return array
      .map((mission, index) => {
        mission.pos = index
        return mission
      })
      .reduce((acc, val) => {
        acc[val.id] = val
        return acc
      }, {})
  }
  static copy(array = []) {
    const missions = array.map(mission => ({
      ...mission,
      id: uuidv4(),
      TEMP: mission.id
    }))

    const keys = Object.values(Mission.LinkKey)

    missions.forEach(mission => {
      keys.forEach(key => {
        const missionID = mission[key]
        if (missionID) {
          const found = missions.find(mission => mission.TEMP === missionID)
          const foundID = found && found.id
          mission[key] = foundID || null
        }
      })
    })

    missions.forEach(mission => {
      delete mission.originID
      delete mission.TEMP
    })

    return missions
  }
}

module.exports = MissionCollection
