import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration
} from "amazon-chime-sdk-js"

import instance from "@/services/axios.service"
import { ref, computed, watch } from "@vue/composition-api"
import useStore from "@/use/useStore"
import Composer from "@/services/stream.service/Composer"
import { StreamType } from "./enums"

const { store } = useStore()
const logger = new ConsoleLogger("Logger", LogLevel.ERROR)
const deviceController = new DefaultDeviceController(logger)

type SessionMap = Record<string, DefaultMeetingSession>

const sessions = ref<SessionMap>({})

const viewer = computed(() => store.state.auth.user)
const muted = computed(() => viewer.value?.muted === true)

let defaultAudioDeviceId: string | undefined

watch(muted, value => {
  console.log(`defaultAudioDeviceId`, defaultAudioDeviceId)

  if (value) {
    Object.values(sessions.value).forEach(session =>
      session.audioVideo.stopAudioInput()
    )
    return
  }
  Object.values(sessions.value).forEach(session =>
    session.audioVideo.startAudioInput(defaultAudioDeviceId)
  )
})

async function setupAudioInput(
  session: DefaultMeetingSession,
  deviceId?: string
) {
  const audioInputDevices = await session.audioVideo.listAudioInputDevices()
  if (audioInputDevices.length === 0) throw new Error("No audio input devices")
  if (audioInputDevices.some(device => device.deviceId === deviceId)) {
    defaultAudioDeviceId = deviceId
    await session.audioVideo.startAudioInput(deviceId)
    return
  }
  const defaultAudioId = audioInputDevices[0].deviceId
  defaultAudioDeviceId = defaultAudioId
  await session.audioVideo.startAudioInput(defaultAudioId)
}

async function setupVideoInput(
  session: DefaultMeetingSession,
  deviceId?: string
) {
  const videoInputDevices = await session.audioVideo.listVideoInputDevices()
  if (videoInputDevices.length === 0) throw new Error("No video input devices")
  if (videoInputDevices.some(device => device.deviceId === deviceId)) {
    await session.audioVideo.startVideoInput(deviceId)
    return
  }
  const defaultVideoId = videoInputDevices[0].deviceId
  await session.audioVideo.startVideoInput(defaultVideoId)
}

export default function useChime() {
  const composer = ref<Composer | undefined>(undefined)

  async function abandon(roomId: string) {
    const session = sessions.value[roomId]
    if (session == null) return
    session.audioVideo.stop()
    await session.destroy()
    sessions.value = Object.entries(sessions.value).reduce((acc, val) => {
      if (val[0] !== roomId) {
        acc[val[0]] = val[1] as DefaultMeetingSession
      }
      return acc
    }, {} as SessionMap)
  }

  async function startStreaming(roomId: string, type = StreamType.ROOM) {
    await instance({
      method: "post",
      url: `/chime/${roomId}/stream`,
      data: {
        type
      }
    })
  }

  async function stopStreaming(roomId: string) {
    await instance({
      method: "delete",
      url: `/chime/${roomId}/stream`
    })
  }

  async function getSession(roomId: string): Promise<DefaultMeetingSession> {
    const response = await instance({
      method: "get",
      url: `/chime/${roomId}`
    })

    // @ts-expect-error bad service implementation
    const { meeting, attendee } = response

    const configuration = new MeetingSessionConfiguration(meeting, attendee)

    const session = new DefaultMeetingSession(
      configuration,
      logger,
      deviceController
    )

    return session
  }

  async function joinWithScreen(roomId: string): Promise<void> {
    const session = await getSession(roomId)

    composer.value = new Composer()

    await composer.value.allocate()

    const [videoTrack, audioTrack] = composer.value.start().getTracks()

    const stream = new MediaStream([videoTrack, audioTrack])

    await session.audioVideo.startContentShare(stream)

    session.audioVideo.start()
    session.audioVideo.startLocalVideoTile()

    sessions.value = { ...sessions.value, [roomId]: session }
  }

  async function joinWithCamera(roomId: string): Promise<void> {
    const session = await getSession(roomId)

    if (!muted.value) {
      await setupAudioInput(session)
    }

    await setupVideoInput(session)

    session.audioVideo.start()
    session.audioVideo.startLocalVideoTile()

    sessions.value = { ...sessions.value, [roomId]: session }
  }

  return {
    startStreaming,
    stopStreaming,
    joinWithCamera,
    joinWithScreen,
    abandon,
    sessions,
    composer
  }
}
