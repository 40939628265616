import StreamingService from "@/services/stream.service/Lobby"

export default {
  computed: {
    $_LiveChatMixin_volume: {
      get() {
        return this.$store.state.livechat.volume
      },
      set(value) {
        this.$store.commit("livechat/UPDATE_VOLUME", value)
      }
    },
    $_LiveChatMixin_volumeComputed() {
      return this.$_LiveChatMixin_volume / 100
    },
    $_LiveChatMixin_stream() {
      return this.$store.getters["auth/client"]?.stream
    },
    $_LiveChatMixin_streamRoomID() {
      return this.$_LiveChatMixin_stream?.roomID
    },
    $_LiveChatMixin_isUserBroadcasting() {
      return (
        this.$store.getters["livechat/roomID"] ===
        this.$_LiveChatMixin_streamRoomID
      )
    }
  },
  methods: {
    $_LiveChatMixin_isUserLocked(user = {}) {
      return (
        user.liveChatLocked === true ||
        (user.liveChatLocked === undefined &&
          this.$store.getters["auth/client"]?.disableLiveChatFtux)
      )
    },
    async $_LiveChatMixin_exitBroadcast() {
      if (
        this.$_LiveChatMixin_isUserBroadcasting &&
        Object.keys(this.$store.getters["livechat/room"]?.users || {}).length <=
          1
      ) {
        await this.$_LiveChatMixin_stopBroadcast()
      }
    },
    async $_LiveChatMixin_stopBroadcast() {
      await StreamingService.stop(this.$store.getters["auth/clientID"])
    },
    async $_LiveChatMixin_startBroadcast(roomID) {
      await StreamingService.start(this.$store.getters["auth/clientID"], roomID)
    }
  }
}
