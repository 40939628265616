












import Vue from "vue"
export default Vue.extend({
  name: "AVDialog",
  model: {
    prop: "isOpen",
    event: "input"
  },
  components: {
    AudioVideoSettings: () =>
      import(
        "@/components/GroupTeams/Common/AudioVideoSettings/AudioVideoSettings.vue"
      )
  },
  props: {
    isOpen: {
      type: Boolean,
      default: undefined
    }
  },
  data() {
    return { contentVisibility: false }
  },
  computed: {
    opened: {
      get() {
        return this.isOpen
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
  watch: {
    opened(val) {
      if (!val) {
        setTimeout(() => {
          this.contentVisibility = false
        }, 300)
      } else {
        this.contentVisibility = true
      }
    }
  },
  methods: {
    done() {
      this.opened = false
    }
  }
})
