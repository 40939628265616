<template>
  <div class="volume-slider">
    <slot name="icon" v-if="$slots.icon" />
    <div class="volume-slider__icon" v-else>
      <SvgIcon
        v-if="volume > min"
        @click="update(min, true)"
        name="speaker"
        width="20"
        height="20"
      />
      <SvgIcon
        v-else
        @click="update(max)"
        name="speaker-mute"
        width="18"
        height="18"
      />
    </div>
    <slot v-if="$slots.default" />
    <span class="volume-slider__label" v-else> SFX </span>
    <v-slider :key="count" :min="min" :max="max" v-model="volume" />
  </div>
</template>

<script>
export default {
  name: "VolumeSlider",
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    value: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      count: 0,
      prev: null
    }
  },
  computed: {
    icon() {
      return this.volume <= this.min ? "volume_off" : "volume_up"
    },
    volume: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      }
    }
  },
  methods: {
    update(value, memorize) {
      if (memorize) {
        this.prev = this.volume
        this.volume = value
      } else {
        this.volume = this.prev || value
      }
      this.count = this.count + 1
    }
  }
}
</script>

<style lang="scss">
.volume-slider {
  position: relative;

  max-width: 270px;
  min-width: 140px;

  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;

  &__icon {
    margin-right: 8px;
    width: 22px;
    min-width: 22px;
    flex-basis: 22px;
    flex-shrink: 0;
  }

  &__label {
    flex-shrink: 0;
  }

  .v-input {
    margin-left: 6px;

    &__prepend-outer {
      margin-top: 0;
      margin-right: 16px;
      margin-bottom: 0;
    }

    &__icon--prepend .v-icon {
      color: $color-white;
    }
  }

  .v-input--slider {
    margin-top: 0;

    .v-input__control {
      height: 25px;
      flex-direction: row;
      align-items: center;

      .v-input__slot {
        max-width: 2px 0;
        margin-bottom: 0;
      }

      .v-messages {
        display: none;
      }

      .v-slider {
        height: auto;
        input {
          cursor: pointer;
        }

        &__track__container {
          background-color: #44474b;
          border-radius: 20px;
          height: 5px;
          overflow: hidden;
        }

        &__track-fill {
          height: 100%;
          background-color: #c24bfe !important;
        }
        &__track {
          height: 100%;
          background-color: transparent;
        }

        &__thumb-container {
          &:before {
            display: none;
          }
        }
        &__thumb {
          width: 18px;
          height: 18px;
          background: #dededf !important;
          cursor: pointer;
        }
        &__thumb-label__container {
          display: none !important;
        }
      }
    }
  }
}
</style>
