var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "aspect-ratio",
          rawName: "v-aspect-ratio",
          value: _vm.ratio,
          expression: "ratio",
        },
      ],
      staticClass: "relative",
    },
    [
      _c(
        "AppUserCard",
        { staticClass: "absolute", attrs: { user: _vm.user } },
        [
          _vm.user.presenter === true
            ? _c(
                "div",
                { staticClass: "pick-teams-user-card__overlay" },
                [_c("MonitorIcon")],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }