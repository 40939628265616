import Vue from "vue"

const state = Vue.observable({
  open: false,
  working: false
})

export default state

export const open = () => {
  state.open = true
}

export const close = () => {
  state.open = false
}

export const toggle = () => {
  if (state.open) {
    close()
  } else {
    open()
  }
}
