<template>
  <div class="baner-container">
    <div class="baner-wrapper">
      <template v-if="!customImages">
        <img :src="rectangleImg" class="rectangle" alt="Rectangle image" />
        <img :src="circleImage" class="circle" alt="Circle image" />
      </template>
      <div class="slider-wrap">
        <transition
          name="image-transition"
          v-for="(url, key) in images"
          :key="`slide-${key}`"
        >
          <img
            class="carousel-image"
            v-show="counter === key"
            :src="url"
            alt="Slider Image"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
const RECTANGLE_URL = require("@/assets/login/rectangle.svg")
const CIRCLE_URL = require("@/assets/login/circle.svg")

export default {
  name: "SigninCarousel",
  data() {
    return {
      timeout: null,
      delay: 5000,
      counter: 0,
      rectangleImg: RECTANGLE_URL,
      circleImage: CIRCLE_URL
    }
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    customImages: Boolean
  },
  computed: {
    canStartCarousel() {
      return this.images.length > 1
    }
  },
  watch: {
    canStartCarousel: {
      handler(count, oldCount) {
        if (count) {
          this.startCarousel()
        } else if (oldCount) {
          this.stopCarousel()
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    this.stopCarousel()
  },
  methods: {
    startCarousel() {
      let self = this

      let timerId = setTimeout(function timerTick() {
        if (self.counter < self.images.length - 1) {
          self.counter++
        } else {
          self.counter = 0
        }

        timerId = setTimeout(timerTick, self.delay)
      }, self.delay)

      this.timeout = timerId
    },
    stopCarousel() {
      clearTimeout(this.timeout)
    }
  }
}
</script>

<style lang="scss">
.baner-container {
  @media (max-width: 1366px) {
    width: 50%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.baner-wrapper {
  @media (max-width: 1366px) {
    margin: 0 auto;
  }

  .image-transition-enter-active {
    transition: all ease-in-out 2s;
  }
  .image-transition-enter,
  .image-transition-leave-to {
    opacity: 0;
  }
}
</style>
