<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 18 18"
    :aria-labelledby="iconName"
    role="presentation"
    class="icon-base"
  >
    <title :id="iconName" lang="en">
      {{ iconDesc ? iconDesc : `${iconName} icon` }}
    </title>
    <g :fill="iconColor">
      <slot />

      <slot name="gradient"></slot>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: "box"
    },
    size: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: "currentColor"
    },
    iconDesc: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style>
.icon-base {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>
