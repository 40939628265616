import Vue from "vue"
import VueCompositionAPI from "@vue/composition-api"
Vue.use(VueCompositionAPI)
import useStore from "@/use/useStore"
import MissionType from "@shared/enums/Mission"
import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from "@vue/composition-api"
import { serialize } from "@/helpers"

import { db } from "@/firebase"

export const charadesStatus = ref({
  finished: false,
  started: false,
  teamIndex: {}
})

const missionPlays = ref([])
const correctAnswers = ref([])
const roomTeams = ref([])

function getTeamInput(user) {
  return missionPlays.value.reduce((acc, play) => {
    if (play.teamID === user.teamID) {
      acc.push(serialize(play.correct))
    }

    return acc
  }, [])
}

function getTeamAnswer(user) {
  const teamIndex = getTeamIndex(user.teamID)

  return correctAnswers.value[teamIndex]
}

function getTeamIndex(teamID) {
  const index = charadesStatus.value?.teamIndex?.[teamID]
  const result = roomTeams.value.findIndex(_teamID => _teamID === teamID)

  return index ?? result
}

function getTeamIndexes() {
  return roomTeams.value.map(getTeamIndex)
}

export function getIsCompleted(user) {
  return getTeamAnswer(user)?.variants?.some(variant =>
    getTeamInput(user).includes(variant)
  )
}

export default function useCharades() {
  const { store } = useStore()
  const gameID = computed<string | undefined>(() => store?.getters.game?.id)
  const mission = computed(() => store?.getters.getCurrentMission || {})
  const consumersCount = ref(0)

  const enabled = computed(() => consumersCount.value > 0)

  const databaseRef = computed(() => {
    if (enabled.value === false) return null

    return db
      .auxiliary()
      .ref(`_room/${gameID.value}/charades/${mission.value.id}`)
  })

  function onUpdate(snapshot) {
    charadesStatus.value = snapshot.val()
  }

  function setNextTeamIndex(teamId) {
    const numberOfAnswers = correctAnswers.value.length

    const currentIndex = getTeamIndex(teamId)

    const teamIndexes = getTeamIndexes()

    const maxIndex = numberOfAnswers - 1

    const availableIndexes = Array.from(
      { length: numberOfAnswers },
      (_, i) => i
    ).reduce((next, index) => {
      if (
        ((maxIndex !== currentIndex && index > currentIndex) ||
          (maxIndex === currentIndex && index < currentIndex)) &&
        !teamIndexes.includes(index)
      ) {
        next.push(index)
      }
      return next
    }, [])

    const newIndex = availableIndexes[0] || 0

    setTeamIndex(teamId, newIndex)
  }

  function setTeamIndex(teamId, index) {
    databaseRef.value.update({ [`teamIndex/${teamId}`]: index })
  }

  watch(
    databaseRef,
    (newRef, oldRef) => {
      if (oldRef !== null && oldRef !== undefined) {
        oldRef.off("value", onUpdate)
      }
      if (newRef !== null) {
        newRef.on("value", onUpdate)
      }
    },
    { immediate: true }
  )

  watch(
    computed(() => store.getters.chats),
    (value: []) => {
      if (value) {
        roomTeams.value = Object.keys(value).sort()
      }
    },
    { immediate: true }
  )

  watch(
    computed(() => store.getters.missionPlaysArray),
    (value: []) => {
      if (value) {
        missionPlays.value = value.filter(({ result }) => result)
      }
    },
    { immediate: true }
  )

  watch(
    computed(() => store.getters.missionCorrectAnswer),
    (answers: string | String[]) => {
      if (answers) {
        let result

        if (typeof answers === "string") {
          result = [answers]
        } else {
          result = answers
        }

        correctAnswers.value = result.reduce((acc, string, index) => {
          if (string) {
            const options = String(string).split(",")
            acc.push({
              title: String(options[0]).trim(),
              variants: options.map(string => serialize(string))
            })
          }

          return acc
        }, [])
      }
    },
    { immediate: true }
  )

  const isCharades = computed(
    () => mission.value.behavior === MissionType.Charades
  )

  const isStarted = computed(() => {
    return charadesStatus.value?.started
  })
  const isFinished = computed(() => {
    return charadesStatus.value?.finished
  })
  const viewer = computed(() => store?.getters["auth/user"])

  onMounted(() => {
    consumersCount.value++
  })

  onBeforeUnmount(() => {
    consumersCount.value--
  })

  return {
    setNextTeamIndex,
    viewer,
    isFinished,
    isStarted,
    charadesStatus,
    getTeamAnswer,
    getIsCompleted,
    getTeamInput,
    databaseRef,
    isCharades
  }
}
