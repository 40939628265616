export interface Client {
  id: string
  theKey: string
  orgID: string
  name: string
  category?: string
  customInputType?: ClientCustomInputType
  tournament?: boolean
  latecomerVideoCode?: string
  loginOnlyForEmails?: boolean
  tipsDisabled?: boolean
}

export enum ClientCustomInputType {
  Text = "text",
  Password = "password",
  Select = "select"
}

export type SessionId = string
