import axios from "axios"
import BASE_URL from "@/services/base-url"
import { store } from "@/store/index"
import Session from "@shared/Session"

class ApiError extends Error {
  constructor(error) {
    const message =
      error.response?.data?.message ||
      (typeof error.response === "string" ? error.response : false) ||
      error.message
    super(message)
    this.request = error.request
    this.response = error.response
  }
}

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
})

instance.interceptors.request.use(
  req => {
    const headers = req.headers || {}

    if (store.getters["auth/token"]) {
      headers["Authorization"] = `Bearer ${store.getters["auth/token"]}`
    }
    const sessionID = store.getters["auth/clientID"]
    if (sessionID) {
      const database = Session.getKey(sessionID)
      if (database) {
        headers["x-gogame-db"] = database
      }
    }

    req.headers = headers
    return req
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => response.data,
  error => {
    console.log(JSON.stringify(error))
    throw new ApiError(error)
  }
)

export default instance
