var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VolumeSlider",
    {
      model: {
        value: _vm.volume,
        callback: function ($$v) {
          _vm.volume = $$v
        },
        expression: "volume",
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }