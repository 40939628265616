<template>
  <div>
    <TipJar :data="gameHost" class="mode-mapper__tip-jar" />
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "ModeMapperTips",
  inheritAttrs: false,
  components: {
    TipJar: () => import("./TipJar.vue")
  },
  computed: {
    gameHost() {
      return this.$store.getters.gameHost
    }
  }
})
</script>

<style lang="scss">
.mode-mapper {
  $block: &;

  &__tip-jar {
    position: absolute;
    right: 0;
    width: 16vmin;
    height: 16vmin;
    min-width: 128px;
    min-height: 128px;
    transform: translateY(-50%) rotate(-16deg);
    z-index: 2;
  }
}
</style>
