import Vue from "vue"

export default Vue.extend({
  name: "MonitorIcon",
  functional: true,
  render(h) {
    return h("svg", { attrs: { viewBox: "0 0 24 24" } }, [
      h("path", {
        attrs: {
          d: "M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z"
        }
      })
    ])
  }
})
