import { computed } from "@vue/composition-api"

import useStore from "./useStore"

export default function useIdentities() {
  const { store } = useStore()

  const orgID = computed<string>(() => store.getters.orgID)
  const gameID = computed<string>(() => store.getters.gameID)
  const missionID = computed<string>(() => store.getters.currentMission)

  return {
    orgID,
    gameID,
    missionID
  }
}
