
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "RTBCol",
  functional: true,
  props: {
    tag: {
      type: String,
      default: "div"
    },
    // TODO(Andrew): `xs` for now
    xs: {
      type: [String, Number]
    }
  },
  render(h, { props, children, data }) {
    return h(
      props.tag,
      mergeData(data, {
        class: { [`rtb-col--xs--${props.xs}`]: props.xs },
        staticClass: "rtb-col"
      }),
      children
    )
  }
})
