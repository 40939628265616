class HubSpot {
  static push(array) {
    const _hsq = (window._hsq = window._hsq || [])
    console.log("_hsq.push(array)", array)
    _hsq.push(array)
  }
  static identify(user = {}) {
    this.push(["identify", { ...user, platform_source: "weve-login" }])
    this.push(["trackPageView"])
  }
  static track() {
    HubSpot.push([
      "trackEvent",
      {
        id: "000013722518"
      }
    ])
  }
}

HubSpot.EMAIL_PATTERN = /.+@.+\..+/

export default HubSpot
