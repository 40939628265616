import { db } from "@/firebase"

import { CHAT_CONTEXTS, CHAT_MESSAGES_FETCH_LIMIT } from "./constants"

export default class Chat {
  constructor(context, roomID) {
    this.db = db.auxiliary()
    this.context = context
    this.roomID = roomID
  }

  static getRefByContext({ context, orgID, gameID, clientID, teamID }) {
    switch (context) {
      case CHAT_CONTEXTS.LOBBY:
        return `client/${clientID}/messaging`

      case CHAT_CONTEXTS.LOBBY_HOST:
        return `client/${clientID}/host_messaging`

      case CHAT_CONTEXTS.GAME:
        return `org/${orgID}/game/${gameID}/messaging`

      case CHAT_CONTEXTS.TEAM:
        return `org/${orgID}/messaging/${gameID}/${teamID}`

      case CHAT_CONTEXTS.STAFF:
        return `client/${clientID}/staff_messaging`

      default:
        return ""
    }
  }

  static async updateActiveReadTimestamp({ contextRef, userID }) {
    await db
      .auxiliary()
      .ref(`${contextRef}/activeReadTimestamp/${userID}`)
      .set(Date.now())
  }

  static async fetchMessages({
    contextRef,
    limit = CHAT_MESSAGES_FETCH_LIMIT,
    messageId
  }) {
    let ref = db.auxiliary().ref(`${contextRef}/messages`).limitToLast(limit)

    if (messageId) {
      ref = ref.orderByKey().endAt(messageId)
    }

    const snap = await ref.once("value")

    const messages = snap.val() || {}
    delete messages[messageId]
    const messagesArray = Object.entries(messages).map(
      ([id, messagePayload]) => ({
        id,
        messagePayload,
        userDetail: {
          firstname: messagePayload?.firstname ?? "",
          lastname: messagePayload?.lastname ?? "",
          role: messagePayload?.role ?? ""
        }
      })
    )

    return messagesArray
  }

  static async addChatMessage({
    contextRef,
    message,
    userDetail: { firstname, lastname, userID, role },
    anonymous
  }) {
    await db
      .auxiliary()
      .ref(`${contextRef}/messages`)
      .push({
        message,
        fromID: !anonymous ? userID : "anonymous",
        timestamp: Date.now(),
        firstname: !anonymous ? firstname : "anonymous",
        lastname: !anonymous ? lastname : "anonymous",
        role: !anonymous ? role : "anonymous"
      })
  }

  static async getLastActiveChatTimestamp({ contextRef, userID }) {
    const snap = await db
      .auxiliary()
      .ref(`${contextRef}/activeReadTimestamp/${userID}`)
      .once("value")
    return snap?.val() ?? null
  }

  static getContextLabel(context) {
    if ([CHAT_CONTEXTS.LOBBY, CHAT_CONTEXTS.LOBBY_HOST].includes(context)) {
      return "Lobby Chat"
    }

    if (context === CHAT_CONTEXTS.TEAM) {
      return "Team Chat"
    }

    if (context === CHAT_CONTEXTS.GAME) {
      return "Room Chat"
    }

    return "Game Chat"
  }

  static async clearChat(contexts, data = {}) {
    contexts.forEach(async context => {
      const contextRef =
        context === CHAT_CONTEXTS.TEAM
          ? `org/${data.orgID}/messaging`
          : this.getRefByContext({ context, ...data })
      if (contextRef) await db.auxiliary().ref(`${contextRef}`).remove()
    })
  }

  static unreadRef(context, timestamp) {
    return db
      .auxiliary()
      .ref(`${context}/messages`)
      .orderByChild("timestamp")
      .startAt(timestamp)
      .limitToLast(10)
  }

  static isValidContext(context) {
    return Object.values(CHAT_CONTEXTS).includes(context)
  }

  static allChatContext() {
    return CHAT_CONTEXTS
  }
}
