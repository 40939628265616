
































import Vue from "vue"

const icons = {
  success: "checkcircle",
  warning: "warning",
  info: null
}

export default Vue.extend({
  name: "RtbSnackBar",
  model: {
    prop: "isVisible",
    event: "change"
  },
  props: {
    isVisible: {
      type: Boolean,
      default: undefined
    },
    timeout: {
      type: Number,
      default: 0
    },
    right: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "success"
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  computed: {
    visibility: {
      get() {
        return this.isVisible
      },
      set(value) {
        this.$emit("change", value)
      }
    },
    icon() {
      return icons[this.variant]
    },
    top() {
      return `${this.offset}px`
    }
  }
})
