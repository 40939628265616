import { MODULE_NAME } from "../store"
import MutationTypes from "../store/mutation-types"
import { ThemeScope } from "../store/constants"

/**
 * Options
 * @typedef {Object} Options
 * @property {import('vuex').Store<any>} store
 */

export class GuardFactory {
  /** @param {Options} options */
  static create(options) {
    /** @type {import('vue-router').NavigationGuard} */
    const guard = (to, _, next) => {
      const { meta } = to
      let scope
      if (meta) {
        const { theme } = meta

        if (theme) {
          scope = theme.scope
        }
      }

      if (scope) {
        options.store.commit(`${MODULE_NAME}/${MutationTypes.SET_SCOPE}`, scope)
      } else {
        options.store.commit(
          `${MODULE_NAME}/${MutationTypes.SET_SCOPE}`,
          ThemeScope.CLIENT
        )
      }

      next()
    }

    return guard
  }
}
