const AudioOverride = require("./enums/AudioOverride")
const MissionType = require("./enums/Mission")
const ModesSequenceByMission = require("./MissionModeSequence")
const PlayType = require("./enums/PlayType")

const MAX_NUMBER_OF_OPTIONS = 5
const DEFAULT_NUM_OF_TRIES = "Unlimited"

class Mission {
  name = ""
  title = ""
  points = 30
  instructions = ""
  behavior = null
  time = null
  numOfTries = DEFAULT_NUM_OF_TRIES
  answer = ""
  notes = null

  constructor(props) {
    for (const key in props) {
      this[key] = props[key]
    }

    // Set default playType if not provided
    this.playType = props.playType || PlayType.TEAM_SPEED_DOES_NOT_MATTER
  }

  // TODO
  // define default mission sequance for each mission type individually
  setDefaultModes() {
    ModesSequenceByMission[this.behavior].forEach(mode => {
      this[mode] = true
    })
  }

  static options(mission = {}) {
    const options = []
    for (let i = 0; i < MAX_NUMBER_OF_OPTIONS; i++) {
      const option = mission[`answer${i + 1}`]
      if (option) options.push(option)
      else break
    }
    return options
  }
  static isPausable(mission) {
    return Boolean(
      mission &&
        (mission.behavior === MissionType.MultipleChoice ||
          mission.behavior === MissionType.Text)
    )
  }
  static isTeamPlay(mission) {
    return !(
      mission && String(mission.playType).toLowerCase().includes("individual")
    )
  }
  static isWhiteElephant(mission) {
    return mission && mission.behavior === MissionType.WhiteElephant
  }
  static isLipdub = mission =>
    mission && mission.behavior === MissionType.Lipdub
  static isQuestionCards = mission =>
    mission && mission.behavior === MissionType.QuestionCards
  static isTelephonePictionary = mission =>
    mission && mission.behavior === MissionType.TelephonePictionary
  static isMusicalKeys = mission =>
    mission && mission.behavior === MissionType.MusicalKeys
  static isDrawing(mission) {
    return (
      mission &&
      (mission.behavior === MissionType.DrawingEachTeam ||
        mission.behavior === MissionType.DrawingPictionary)
    )
  }
  static isBuzzable(mission) {
    return Boolean(
      mission &&
        (mission.behavior === MissionType.BuzzIn ||
          mission.behavior === MissionType.FamilyFeud)
    )
  }
  static isTimeLimited(mission) {
    return [
      PlayType.TEAM_SPEED_MATTERS,
      PlayType.INDIVIDUAL_SPEED_MATTERS,
      undefined
    ].includes(mission.playType)
  }
}

Mission.AudioOverride = AudioOverride
Mission.LIPDUB_DURATION_PATTERN = /\[(.*?)\]/
Mission.LinkKey = {
  FREE_FORM_KEY: "freeFormMissionID",
  TWO_TRUTHS_KEY: "twoTruthsMissionID"
}

module.exports = Mission
