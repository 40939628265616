import canAutoPlay from "can-autoplay"
import { info } from "@/plugins/info"
import { Misc as DictionaryMisc } from "@/dictionary"
import { init } from "@/modules/smart-media"

let isAutoPlayAllowed = false
let isAutoPlayRequestInProgress = false
let next

// TODO
// come up with a reliable way to ensure auto play is allowed in all browsers

export default async function (to, from, _next) {
  if (isAutoPlayAllowed) return _next()
  next = _next
  if (isAutoPlayRequestInProgress) return _next()
  isAutoPlayRequestInProgress = true

  canAutoPlay.audio().then(async ({ result }) => {
    if (result) {
      init()
      next()
      return
    }
    try {
      await info(DictionaryMisc.AUTO_PLAY_REQUEST, "System", true)
      isAutoPlayAllowed = true
      init()
    } catch {}
    next()
    isAutoPlayRequestInProgress = false
  })
}
