
import type { PropType } from "vue"
import type { RawLocation } from "vue-router"
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

import SvgIcon from "@/components/base/SvgIcon.vue"

import { COLORS } from "./types"

export default Vue.extend({
  name: "RTBLink",
  functional: true,
  props: {
    color: {
      type: String as PropType<typeof COLORS[number]>,
      default: "white" as const,
      validator: val => COLORS.includes(val)
    },
    to: {
      type: [String, Object] as PropType<RawLocation>
    },
    href: {
      type: String
    }
  },
  render(h, { props, data, children }) {
    const tag = props.to ? "router-link" : "a"

    return h(
      tag,
      mergeData(data, {
        props: tag === "router-link" ? { to: props.to } : undefined,
        attrs: tag === "a" ? { href: props.href } : undefined,
        class: `rtb-link--color--${props.color}`,
        staticClass: "rtb-link"
      }),
      [
        children
        /* props.href && props.href !== "#"
          ? h(SvgIcon, {
              props: { name: "external-link-alt-regular" },
              staticClass: "rtb-link__icon"
            })
          : null */
      ]
    )
  }
})
