
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"
import { BTooltip } from "bootstrap-vue"
import shortid from "shortid"

import SvgIcon from "@/components/base/SvgIcon.vue"

export default Vue.extend({
  functional: true,
  props: {
    id: {
      type: String,
      default() {
        return shortid.generate()
      }
    }
  },
  render(h, { props, children, data }) {
    return h("span", mergeData(data, { staticClass: "rtb-inline-help" }), [
      h(SvgIcon, {
        props: { name: "question-circle-light" },
        attrs: { id: props.id },
        staticClass: "rtb-inline-help__icon"
      }),
      h(BTooltip, { props: { target: props.id } }, children)
    ])
  }
})
