
















































// @ts-nocheck
// TODO(Andrew): remove "ts-nocheck" when time comes
import type { PropType } from "vue"
import type { WithEvents } from "vue-typed-emit"
import type { WithRefs } from "vue-typed-refs"
import Vue from "vue"
import { directive } from "v-aspect-ratio"
import shortid from "shortid"

import FormControlLabel from "../FormControlLabel/FormControlLabel.vue"
import FormControlError from "../FormControlError/FormControlError.vue"
import TextInput from "../TextInput/TextInput.vue"
import InputButton from "../InputButton/InputButton.vue"
import RtbSpinner from "../Spinner/Spinner.vue"

import { Constraints, ImageUploderError } from "./types"

import { validateFile } from "./utils"

interface Uplaoder {
  upload: unknown
}

type Refs = {
  input: HTMLInputElement
}

interface Events {
  input: string
  remove: undefined
  error: ImageUploderError
}

export default (Vue as WithEvents<Events, WithRefs<Refs>>).extend({
  name: "RTBImageUploader",
  directives: {
    "aspect-ratio": directive
  },
  components: {
    FormControlLabel,
    FormControlError,
    TextInput,
    InputButton,
    RtbSpinner
  },
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      required: true
    },
    uploader: {
      type: Object as PropType<Uplaoder>,
      required: true
    },
    fileName: {
      type: Function as PropType<(file: File) => string>,
      required: true
    },
    constraints: {
      type: Object as PropType<Constraints>,
      required: true
    },
    aspectRatio: {
      type: String,
      default: "16:9"
    },
    inputId: {
      type: String,
      default() {
        return shortid.generate()
      }
    },
    removeable: {
      type: Boolean,
      default: false
    },
    error: {
      type: String
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit("input", value)
      }
    },
    accept(): string {
      return this.constraints.extensions
        .map(extension => `.${extension}`)
        .join(",")
    }
  },
  methods: {
    triggerInput() {
      this.$refs.input.click()
    },
    changeHandler(e: InputEvent) {
      const { target } = e
      if (target !== null && target instanceof HTMLInputElement) {
        if (target.files) {
          if (target.files.length) {
            this.file = target.files[0]
            this.handleFile()
          }
        }
      }
    },
    handleFile() {
      this.validateFile().then(valid => {
        if (valid) {
          this.uploadFile()
        }
      })
    },
    validateFile(): Promise<boolean> {
      const { file, constraints } = this

      if (file === null) {
        return Promise.resolve(false)
      } else {
        return validateFile(file, constraints)
          .then(file => true)
          .catch((e: ImageUploderError) => {
            console.error(e)
            this.$emit("error", e)
            return false
          })
      }
    },
    async uploadFile() {
      this.loading = true
      this.uploader
        .upload(this.file, { fileName: this.fileName(this.file) })
        .then(res => {
          this.$emit("input", res)
        })
        .finally(() => {
          this.loading = false
        })
    },
    onClear() {
      this.$emit("input", "")
    },
    onRemove() {
      this.$emit("remove")
    }
  }
})
