
import type { VNode } from "vue"
import Vue from "vue"
import mixins from "vue-typed-mixins"

import { IdentifiableMixin, InputMixin } from "../mixins"

import FormControlLabel from "../FormControlLabel/FormControlLabel.vue"

export default mixins(
  Vue.extend(IdentifiableMixin),
  Vue.extend(InputMixin)
).extend({
  name: "RTBTextarea",
  props: {
    value: {
      type: [String, Number]
    }
  },
  render(h): VNode {
    return h("div", { staticClass: "rtb-textarea" }, [
      this.$slots.help,
      this.label
        ? h(
            FormControlLabel,
            {
              attrs: { for: this.id },
              staticClass: "rtb-textarea__label"
            },
            this.label
          )
        : null,
      h("textarea", {
        attrs: { rows: 6, ...this.$attrs, id: this.id },
        domProps: { value: this.value },
        staticClass: "rtb-textarea__field",
        on: this.listeners
      })
    ])
  }
})
