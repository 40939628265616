import { isNil } from "lodash"
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount
} from "@vue/composition-api"

import { db } from "@/firebase"

import useStore from "./useStore"
import useIdentities from "./useIdentities"

const { store } = useStore()
const { orgID } = useIdentities()

const gameID = computed<string | undefined>(() => store.getters.game?.id)
const templateID = computed<string | undefined>(
  () => store.getters.game?.templateID
)
const started = computed<boolean>(() => store.getters.game?.started ?? false)
const missions = computed(() => store.getters.missions)

const consumersCount = ref(0)

const enabled = computed(() => consumersCount.value > 0)

const databaseRef = computed(() => {
  if (enabled.value === false) return null
  if (isNil(gameID.value)) return null

  if (started.value || isNil(templateID.value))
    return db
      .auxiliary()
      .ref(`org/${orgID.value}/game/${gameID.value}/missions`)

  return db.ref(`org/${orgID.value}/game/${templateID.value}/missions`)
})

function onUpdate(snapshot) {
  store.commit("SET_MISSIONS", snapshot.val())
}

watch(
  databaseRef,
  (newRef, oldRef) => {
    if (oldRef !== null && oldRef !== undefined) {
      oldRef.off("value", onUpdate)
    }
    if (newRef !== null) {
      newRef.on("value", onUpdate)
    }
  },
  { immediate: true }
)

export default function useMissions() {
  onMounted(() => {
    consumersCount.value++
  })

  onBeforeUnmount(() => {
    consumersCount.value--
  })

  return { missions }
}
