
import Vue, { PropType } from "vue"
import { BFormTimepicker } from "bootstrap-vue"
import type { WithRefs } from "vue-typed-refs"
import type { WithEvents } from "vue-typed-emit"
import { format } from "date-fns"

import SvgIcon from "@/components/base/SvgIcon.vue"

import TextInput from "../TextInput/TextInput.vue"

export type Refs = {
  timepicker: InstanceType<typeof BFormTimepicker>
}

export interface Events {
  input: string | undefined
}

export default (Vue as WithEvents<Events, WithRefs<Refs>>).extend({
  name: "RTBTimepicker",
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number] as PropType<string | number | undefined>
    },
    /**
     * Indicates whether or not `value` should be emitted as a timestamp
     */
    timestamp: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    normalizedValue(): string | undefined {
      if (!this.value) return undefined
      return typeof this.value === "number"
        ? format(new Date(this.value), "HH:mm:ss")
        : this.value
    },
    listeners(): Record<string, Function | Function[]> {
      return {
        ...this.$listeners,
        input: (value: string) => {
          if (this.timestamp) {
            const [hours, minutes, seconds] = value.split(":")
            const buffer = this.value ? new Date(this.value) : new Date()
            buffer.setHours(+hours)
            buffer.setMinutes(+minutes)
            buffer.setSeconds(+seconds)
            this.$emit("input", buffer.getTime())
          } else {
            this.$emit("input", value)
          }
        }
      }
    }
  },
  render(h) {
    return h(
      TextInput,
      {
        props: { value: this.normalizedValue },
        attrs: this.$attrs,
        on: {
          input: this.listeners.input
        }
      },
      [
        h("template", { slot: "help" }, this.$slots.help),
        h(BFormTimepicker, {
          ref: "timepicker",
          slot: "append",
          props: {
            value: this.normalizedValue,
            buttonOnly: true,
            right: true,
            hour12: false,
            "no-close-button": true
          },
          staticClass: "rtb-timepicker rtb-bootstrap-scope",
          on: this.listeners,
          scopedSlots: {
            "button-content"() {
              return h(SvgIcon, { props: { name: "clock-light" } })
            }
          }
        })
      ]
    )
  }
})
