import SnackBar from "./rtb-snackbar-wrapper.vue"

type propTypes = {
  message: string | string[]
  timeout?: number
  variant?: "success" | "warning" | "danger"
  offsetTop?: number
}

function snackbar(this: Vue, propsData: propTypes) {
  const div = document.createElement("div")

  document.body.append(div)

  const instance = new SnackBar({
    propsData
  }).$mount()

  div.appendChild(instance.$el)

  return (instance as any).show().finally(() => {
    instance.$destroy()

    div.remove()
  })
}

const SnackbarPlugin = {
  install(Vue) {
    Vue.prototype.$snackbar = snackbar
  }
}

export default SnackbarPlugin
