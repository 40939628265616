<template>
  <v-flex
    :style="{ visibility: isOpen ? 'visible' : 'hidden' }"
    d-flex
    justify-center
    class="popup-overlay"
    align-center
    @click.self="$emit('onClickOutside')"
  >
    <transition name="popup">
      <div
        v-if="isOpen"
        class="popup-content"
        :class="{ [`popup-content--${size}`]: size }"
      >
        <div
          v-if="closable"
          class="close-symbol"
          @click.stop="$emit('onClose')"
        >
          <SvgIcon name="times-regular" width="18" height="18" />
        </div>

        <h3 class="popup-header">
          Enter your information below to get a free month with 1 Open Weve and
          5 Auto Weve experiences.
        </h3>
        <strong>Open Weve</strong> is a room like this where you load in the
        mini games and is always open.Great for weekly allhands or rituals.
        <br /><br /><strong>Auto Weves</strong> are 30 minute games from our
        library that can be run one time. <br /><br />

        <div class="popup-text" style="height: 400px">
          <div id="hubspotForm"></div>
        </div>
      </div>
    </transition>
  </v-flex>
</template>

<script>
import { RtbButtonNext } from "@/components/ui"
export default {
  name: "SalesPopup",
  components: {
    RtbButtonNext
  },
  data() {
    return {
      script: null
    }
  },
  props: {
    size: String,
    isOpen: Boolean,
    header: String,
    mode: String,
    closable: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.script = document.createElement("script")
    this.script.src = "https://js.hsforms.net/forms/v2.js"
    document.body.appendChild(this.script)
    this.script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "2803895",
          formId: "eab8a54e-41f5-4b45-8b0c-e4c6e75d7ab6",
          target: "#hubspotForm"
        })
      }
    })
  },
  beforeDestroy() {
    document.body.removeChild(this.script)
    this.script = null
  },
  methods: {
    onResponse({ status }) {
      this.$emit("onResponse", { status })
    }
  }
}
</script>
