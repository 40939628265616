import firebase from "firebase/app"
import { db } from "@/firebase"
import type {
  MailMatch,
  MapDataResponse,
  Snapshot
} from "@/types/mail.matching"

import Papa from "papaparse"

function snapValues(snapshot, defaults = null) {
  const value: Snapshot = snapshot.val()
  return !value
    ? defaults
    : Object.entries(value).map(([id, item]) => ({
        ...item,
        id
      }))
}

export class MailMatcher {
  ref: any

  constructor(clientID) {
    this.ref = db.auxiliary().ref(`client/${clientID}/matching`)
  }

  getOrCreateID(item: MailMatch): string {
    return item.id || this.ref.push().key
  }

  async exists() {
    const res = await this.ref.limitToLast(1).once("value").then(snapValues)
    return !!res?.length
  }

  getMatchesByEmail(email: string): Promise<MailMatch[]> {
    return this.ref
      .orderByChild("email")
      .equalTo(email)
      .once("value")
      .then(snapshot => snapValues(snapshot, []))
  }

  async getMatchByGameID(gameID: string): Promise<MailMatch[]> {
    return await this.ref
      .orderByChild("gameID")
      .equalTo(gameID)
      .once("value")
      .then(snapshot => snapValues(snapshot, []))
  }

  getAllMatch(): Promise<MailMatch[]> {
    return this.ref
      .once("value")
      .then(snapshot => snapValues(snapshot, []).filter(({ email }) => email))
  }

  saveMailMatch(item: MailMatch): Promise<any> {
    const id = this.getOrCreateID(item)
    return this.ref.child(id).set({
      ...item,
      id
    })
  }

  setAllMailMatches(data: MailMatch[]): Promise<void> {
    return this.ref.set(
      data.reduce((acc, item) => {
        const id = this.getOrCreateID(item)
        acc[id] = {
          ...item,
          id
        }
        return acc
      }, {})
    )
  }

  setGameMailMatches(gameID: string, data: MailMatch[]): Promise<any[]> {
    const updates = data.reduce((acc, item) => {
      const id = this.getOrCreateID(item)
      acc[id] = {
        ...item,
        id,
        gameID
      }
      return acc
    }, {})
    return this.ref.update(updates)
  }

  importFromCSVFile(file): Promise<Object[]> {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        complete: results => {
          try {
            this.validateRequiredFields(results.meta.fields)
            const data = results.data
              .filter(({ Email }) => Email)
              .map(item => ({
                first_name: item["First Name"] || null,
                last_name: item["Last Name"] || null,
                email: item["Email"] || null,
                gameID: item["Room"] || null,
                teamID: item["Team"] || null
              }))
            resolve(data)
          } catch (e) {
            reject(e)
          }
        },
        error: e => {
          reject(e)
        }
      })
    })
  }

  async getFirstMatching(email, availableGames) {
    const matches = await this.getMatchesByEmail(email)
    const availableIDs = availableGames.map(({ theKey }) => theKey)
    return matches.find(({ gameID }) => availableIDs.includes(gameID))
  }

  /**
   *
   * @param rawData - data from csv file
   * @param games
   * @param teams
   * @param createTeamCallback
   */
  async mapData(
    rawData: MailMatch[],
    games: Array<any>,
    teams,
    createTeamCallback
  ): Promise<MapDataResponse> {
    const mappedData = []
    // { fileGameValue: gameKey }
    const mappedGames = {}
    const mappedTeams = {}
    // Find Games with exact external name
    games.forEach(game => {
      const externalName = game.externalName
      if (rawData.find(i => (i.gameID || "").trim() == externalName)) {
        mappedGames[game.externalName] = game?.theKey
        mappedTeams[game.externalName] = {}
      }
    })

    const filteredGames = Object.values(games).filter(
      game => !mappedGames[game.externalName]
    )

    // { fileGameValue: { fileTeamValue: teamKey }}
    for (const item of rawData) {
      let {
        first_name,
        last_name,
        email,
        gameID: rawGameID,
        teamID: rawTeamID
      } = item
      // Looking for the game for mapping
      email = email.toLowerCase()
      rawGameID = rawGameID ? rawGameID.trim() : null
      rawTeamID = rawTeamID ? rawTeamID.trim() : null
      let gameKey = null
      let teamKey = null
      if (rawGameID) {
        gameKey = mappedGames[rawGameID]
        if (!gameKey) {
          // Get First game for mapping
          gameKey = filteredGames.shift()?.theKey || null
          mappedGames[rawGameID] = gameKey
          mappedTeams[rawGameID] = {}
        }

        const gameMappedTeams = mappedTeams[rawGameID] || {}
        teamKey = gameMappedTeams[rawTeamID] || null

        if (rawTeamID && !teamKey) {
          teamKey = await MailMatcher.getTeamKey(
            gameKey,
            gameMappedTeams,
            teams[gameKey] || [],
            createTeamCallback
          )

          mappedTeams[rawGameID][rawTeamID] = teamKey
        }
      }
      mappedData.push({
        first_name,
        last_name,
        email,
        gameID: gameKey,
        teamID: teamKey
      })
    }

    return { mappedData, mappedGames, mappedTeams }
  }

  private validateRequiredFields(fileColumns) {
    const fields = ["Email"]
    if (!fields.every(val => fileColumns.includes(val))) {
      throw new Error(
        `CSV File should contain these columns: ${fields.join(", ")}`
      )
    }
  }

  private static async getTeamKey(
    gameKey: string,
    gameMappedTeams: Object,
    teams,
    createTeamCallback
  ) {
    const teamIdx = Object.keys(gameMappedTeams).length
    const team = teams[teamIdx]
    // Note: ID should be exists in team object
    // Create
    if (team) return team.id
    if (createTeamCallback) {
      return await createTeamCallback(gameKey, teamIdx + 1)
    }
    return null
  }
}
