import firebase from "firebase/compat/app"
import "firebase/compat/database"
import "firebase/compat/analytics"
import { store } from "@/store/index"
import Session from "@shared/Session"

const {
  VUE_APP_FIREBASE_API_KEY,
  VUE_APP_FIREBASE_AUTH_DOMAIN,
  VUE_APP_FIREBASE_DATABASE_URL,
  VUE_APP_FIREBASE_PROJECT_ID,
  VUE_APP_FIREBASE_STORAGE_BUCKET,
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  VUE_APP_FIREBASE_APP_ID,
  VUE_APP_FIREBASE_MEASUREMENT_ID
} = process.env

const firebaseConfig = {
  apiKey: VUE_APP_FIREBASE_API_KEY,
  authDomain: VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: VUE_APP_FIREBASE_DATABASE_URL,
  projectId: VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: VUE_APP_FIREBASE_STORAGE_BUCKET
}

const analyticsConfig =
  VUE_APP_FIREBASE_MESSAGING_SENDER_ID &&
  VUE_APP_FIREBASE_APP_ID &&
  VUE_APP_FIREBASE_MEASUREMENT_ID

if (analyticsConfig) {
  firebaseConfig.messagingSenderId = VUE_APP_FIREBASE_MESSAGING_SENDER_ID
  firebaseConfig.appId = VUE_APP_FIREBASE_APP_ID
  firebaseConfig.measurementId = VUE_APP_FIREBASE_MEASUREMENT_ID
}

const app = firebase.initializeApp(firebaseConfig)

const cache = {}

const db = app.database()

db.auxiliary = id => {
  const sessionID = id || store.getters["auth/clientID"]
  const database = Session.getKey(sessionID)
  if (!database) return db
  if (cache[database]) return cache[database]
  const databaseURL = `https://${VUE_APP_FIREBASE_PROJECT_ID}-${database}.firebaseio.com`
  cache[database] = app.database(databaseURL)

  return cache[database]
}

if (analyticsConfig) firebase.analytics()

const ServerValue = firebase.database.ServerValue

const Event = {
  VALUE: "value"
}

export { db, ServerValue, Event }
