const DrawingModule = {
  namespaced: true,
  state: {
    lineWidth: 4,
    lineColorRgb: "rgb(0, 0, 0)",
    alpha: 1,
    pen: true,
    // TODO - use composable when available
    assignedTeamId: null,
    assignedUserId: null
  },
  mutations: {
    SET_ASSIGNED_TEAM_ID(state, payload) {
      state.assignedTeamId = payload
    },
    SET_ASSIGNED_USER_ID(state, payload) {
      state.assignedUserId = payload
    },
    SET_PEN_STATE(state, payload) {
      state.pen = payload
    },
    SET_PEN_RGB_COLOR(state, { rgb }) {
      state.lineColorRgb = rgb
    },
    SET_PEN_ALPHA(state, payload) {
      state.alpha = payload
    },
    SET_LINE_WIDTH(state, { lineWidth }) {
      state.lineWidth = lineWidth
    }
  },
  actions: {
    setLineWidth({ commit }, payload) {
      commit("SET_LINE_WIDTH", payload)
    },
    setPenColor({ commit }, payload) {
      const { r, g, b, a } = payload.color.rgba
      const rgb = `rgb(${r},${g},${b})`
      const alpha = a
      commit("SET_PEN_RGB_COLOR", { rgb })
      commit("SET_PEN_ALPHA", alpha)
    },
    setPenState({ commit }, payload) {
      commit("SET_PEN_STATE", payload)
    }
  }
}

export default DrawingModule
