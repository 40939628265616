
/**
 * @see https://getbootstrap.com/docs/$VERSION/components/spinners
 */
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "RTBSpinner",
  functional: true,
  props: {
    tag: {
      type: String,
      default: "div"
    },
    centered: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    }
  },
  render(h, { props, data }) {
    return h(
      props.tag,
      mergeData(data, {
        attrs: { role: "status" },
        class: { "rtb-spinner--centered": props.centered },
        style: { color: props.color },
        staticClass: "rtb-spinner"
      }),
      [h("span", { staticClass: "sr-only" }, "Loading...")]
    )
  }
})
