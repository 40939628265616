
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "SvgIcon",
  functional: true,
  props: {
    name: {
      type: String,
      required: true
    },
    folder: {
      type: String
    },
    title: {
      type: String
    },
    size: {
      type: String,
      default: undefined,
      validator: val =>
        ["default", "xs", "small", "large", "xl"].indexOf(val) !== -1
    },
    color: {
      type: String,
      default: undefined
    }
  },
  render(h, { props, data }) {
    const { name, title, folder } = props
    let icon

    try {
      if (folder) {
        icon = require(`@/assets/icons/${folder}/${name}.svg`)
      } else {
        icon = require(`@/assets/icons/${name}.svg`)
      }
    } catch (e) {
      console.error(e)
    }

    if (icon !== null && icon !== undefined) {
      if (Object.prototype.hasOwnProperty.call(icon, "default")) {
        icon = icon.default
      }

      return h(
        "svg",
        mergeData(data, {
          attrs: {
            color: props.color
          },
          class: [
            "svg-icon svg-icon--" + name,
            {
              [`svg-icon--size--${props.size}`]: props.size,
              [`svg-icon--color--${props.color}`]: props.color !== undefined
            }
          ]
        }),
        [
          title !== undefined ? h("title", undefined, title) : null,
          h("use", {
            attrs: {
              "xlink:href": icon.url,
              "xmlns:xlink": "http://www.w3.org/1999/xlink"
            }
          })
        ]
      )
    } else {
      return null
    }
  }
})
