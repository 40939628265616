





















































import _ from "lodash"
import { mapGetters, mapActions } from "vuex"
import { TweenMax } from "gsap/TweenMax"
import { ActionTypes as OrgModuleActionTypes } from "@/store/OrgModule"
import HubspotChat from "@/components/Chat/Hubspot/HubspotChatWrapper.vue"

// enums
import EntryPage from "@/enums/EntryPage"

import Page from "@/router/Page"

// services
const LogRocketService = () => import("@/services/logrocket.service")

import { getCurrentUser } from "./services/auth.service"
import { onIdTokenChanged } from "@/services/auth.service"
import { removeItemsByPatterns } from "@/services/localstorage.service"
import UserService from "@/services/user.service"
import PingService from "@/services/ping.service"
import SwapPhoneService from "@/services/swap-phone.service"

// entities
import MissionCollection from "@shared/MissionCollection"
import User from "@shared/User"

import {
  Role,
  Navigation,
  GameHistoryStorage,
  InterimVideoStorage,
  PregameVideoStorage
} from "@/helpers"

import { LATECOMER_TIME } from "@/config"

import { GetterTypes } from "@/store"
import { GameType } from "@/entities/GameType"
import LatecomerHistoryStorage from "@/modules/latecomer/entities/LatecomerHistoryStorage"

// a global value for any TweenMax animation
const DEFAULT_FRAMES_PER_SECOND = 60
// 15 second to accept auto assign
const DASHBOARD_PAGES = [
  "dashboard.games",
  "dashboard.clients",
  "dashboard.client",
  "dashboard.game",
  "hosts",
  "twitch",
  "orgs",
  "system"
]

const USER_ASSIGN_MESSAGE_TEMPLATE = game =>
  `Your amazing host has put you on ${game}. Cheers 🍸`

const AUTO_ASSIGN_MESSAGE_TEMPLATE = game =>
  `You have been added to ${game}. Cheers 🍸`

import TwilioManager from "@/components/Twilio/TwilioManager.vue"

const intervals = []

export default {
  name: "WeveApp",
  components: {
    NoSleepObserver: () => import("@/components/Observers/NoSleepObserver.vue"),
    SmartMedia: () => import("@/components/GroupTeams/Common/SmartMedia.vue"),
    ActivityDrawer: () =>
      import("@/components/drawers/activity/ActivityDrawer.vue"),
    LegacyToolbar: () =>
      import("@/components/GroupTeams/Common/LegacyToolbar.vue"),
    HostToolBar: () => import("@/components/GroupTeams/Common/HostToolBar.vue"),
    UserEditor: () => import("@/components/UserEditor.vue"),
    Popup: () => import("@/components/Popups/Popup.vue"),
    LobbyMusic: () => import("@/components/GroupTeams/Misc/LobbyMusic.vue"),
    ScreenCaptureIntro: () =>
      import("@/components/ScreenCapture/ScreenCaptureIntro.vue"),
    ScreenShareIntro: () =>
      import("@/components/ScreenCapture/ScreenShareIntro.vue"),
    MobileObserver: () =>
      import("@/components/GroupTeams/Mobile/MobileObserver.vue"),
    VersionObserver: () => import("@/components/Observers/VersionObserver.vue"),
    HubspotChat, // I am not sure we need this component all the time
    TwilioManager
  },
  data() {
    return {
      isDevelopmentMode: process.env.VUE_APP_MODE === "development",
      isLockedToGame: false,
      isGameTime: false
    }
  },
  beforeDestroy() {
    intervals.forEach(interval => clearInterval(interval))
  },
  async created() {
    // Remove items from localStorage (it covers cases: first login or refresh)
    removeItemsByPatterns([/viewedKeynote/])

    // Update refreshed firebase token
    onIdTokenChanged(async user => {
      if (user) {
        const token = await user.getIdToken()
        this.$store.dispatch("auth/updateFirebaseToken", token)
      }
    })

    TweenMax.ticker.fps(DEFAULT_FRAMES_PER_SECOND)

    const { clientID, gameID } = Navigation.parseUrlID(
      this.$route.params.id || this.$router.history?.pending?.params?.id
    )

    const customToken =
      this.$route.query["custom-token"] ??
      this.$router.history?.pending?.query?.["custom-token"]

    const camera =
      this.$route.query.camera ?? this.$router.history?.pending?.query?.camera

    const onboarding =
      this.$route.query.onboarding ??
      this.$router.history?.pending?.query?.onboarding

    if (onboarding === "1") {
      this.$store.commit("auth/UPDATE_LAST_KNOWN_ONBOARDING", false)
    } else if (onboarding === "0" || camera === "0") {
      if (camera === "0") {
        this.$store.commit("auth/UPDATE_CAMERA_ENABLED", false)
      }
      this.$store.commit("auth/UPDATE_LAST_KNOWN_ONBOARDING", true)
    }

    const auth =
      this.$route.query.auth ?? this.$router.history?.pending?.query?.auth

    if (customToken) {
      try {
        const customTokenId =
          this.$route.query["custom-token-id"] ??
          this.$router.history?.pending?.query?.["custom-token-id"]

        if (customTokenId == null)
          throw new Error(`No way to complete phone swap.`)

        await this.$store.dispatch("auth/signInWithCustomToken", customToken)

        const unwatch = this.$watch(
          "isUserOnboarded",
          value => {
            if (value === false) return
            if (unwatch) unwatch()
            console.log(`completing the phone swap`)
            SwapPhoneService.completePhoneSwap(customTokenId)
          },
          { immediate: true }
        )
      } catch (e) {
        console.error(e)
      }
    } else if (auth === "1") {
      const pathname = window.location.pathname
      const params = new URLSearchParams(window.location.search)
      params.delete("auth")
      const string = params.toString()
      const url = string ? `${pathname}?${string}` : pathname
      window.history.replaceState(null, null, url)
    } else if (auth !== "0" && clientID) {
      const audit = this.$route.query.audit === "1" || null
      const vip = this.$route.query.vip === "1" || null
      const payload = { clientID, audit, vip, gameID: null }
      if (gameID) payload.gameID = gameID
      await this.reauthenticate(payload)
    }

    try {
      const data = await PingService.fetchTimestamp()
      // @ts-expect-error see axios.service.js line 47
      const timestamp = parseInt(data?.timestamp)
      if (isNaN(timestamp)) throw new Error(`No timestamp`)
      const diff = Date.now() - timestamp
      const OriginalDateNow = Date.now
      Date.now = function () {
        return OriginalDateNow() - diff
      }
    } catch (e) {
      console.error(e)
    }

    intervals.push(setInterval(this.onTick, 1000))
    intervals.push(setInterval(this.onHeartbeatTick, User.HEARTBEAT_INTERVAL))
    intervals.push(setInterval(this.onHeartbeatCheckTick, 10000))

    this.onTick()
    this.onHeartbeatCheckTick()
  },
  mounted() {
    this.$nextTick(() => {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.src = "//js.hs-scripts.com/2803895.js"
      script.id = "hs-script-loader"
      script.async = true
      script.defer = true
      document.body.appendChild(script)
    })
  },
  computed: {
    ...mapGetters([
      "showScreenCaptureDialog",
      "time",
      "onlineUsersArray",
      "game",
      "drawer",
      "orgID",
      "urlID",
      "missions"
    ]),
    ...mapGetters("auth", [
      "camera",
      "user",
      "role",
      "hasPreGame",
      "initialized",
      "initializing",
      "authorized",
      "clientID",
      "client",
      "isSuper",
      "isHost",
      "isAudit",
      "isModerator"
    ]),
    ...mapGetters({
      org: "theOrg",
      game: "actualGame",
      gameID: "actualGameID",
      mode: "getCurrentMode",
      mission: "currentMission"
    }),
    ...mapGetters("pregame", ["usersToMap", "chatTabActive"]),
    ...mapGetters("ScreenCapture", [
      "showScreenShareDialog",
      "isSharingScreen"
    ]),
    ...mapGetters("Mobile", ["mobile"]),
    isPresenter() {
      return User.isPresenter(this.$store.getters.user)
    },
    isSmartMediaAvailable() {
      return this.isRoomPage || this.isPickTeamsPage
    },
    isActivityDrawerAvailable() {
      return Boolean(
        this.authorized &&
          (this.isRoomPage || this.isDashboardPage) &&
          (this.isHost || this.isModerator || this.game?.moderated)
      )
    },
    userIdToUpdate() {
      return this.$store.getters["UserSettings/user"]?.id
    },
    showUserSettings() {
      return Boolean((this.isHost || this.isAudit) && this.userIdToUpdate)
    },
    isHybridRoom() {
      return this.$store.getters["auth/isHybridRoom"]
    },
    path() {
      return this.$route.path
    },
    isToolbarVisible() {
      return !this.game?.isToolbarHidden
    },
    showScreenCaptureDialogComputed: {
      get() {
        return this.showScreenCaptureDialog
      },
      set(value) {
        this.updateShowScreenCaptureDialog(value)
      }
    },
    canShowHubspotChat() {
      return (
        [Page.LOGIN, "welcome", Page.LOBBY, Page.ROOM].includes(this.route) &&
        !this.isHybridRoom
      )
    },
    hubspotChatPosition() {
      return this.route === Page.ROOM ? "left" : "right"
    },
    route() {
      return this.$route?.name
    },
    isPickTeamsPage() {
      return this.route === Page.SELECT_TEAM
    },
    isLobbyPage() {
      return this.route === Page.LOBBY
    },
    isOpenDemoPage() {
      return this.route === "open-session"
    },
    isRoomPage() {
      return this.route === Page.ROOM
    },
    isSessionSettings() {
      return this.route === Page.SESSION_SETTINGS
    },
    isRoomStarted() {
      return Boolean(this.user?.gameID && this.game?.started)
    },
    gameStartTime() {
      return this.user.gameID ? parseInt(this.game?.startTimestamp) : undefined
    },
    isGameRunning() {
      return this.isRoomStarted && this.isGameTime
    },
    isGameLocked() {
      return !!this.game?.locked
    },
    canEnterGame() {
      return this.initialized && (this.isHost || !this.isGameLocked)
    },
    isClientLocked() {
      return !!this.client?.locked
    },
    isGameEnded() {
      return !!(this.game?.endTimestamp || this.game?.deletedTimestamp)
    },
    entryPage() {
      return this.game?.diy || this.isHybridRoom
        ? EntryPage.GAME
        : this.game?.entryPage || EntryPage.PICKTEAMS
    },
    isUserOnboarded() {
      return this.$store.getters["auth/isUserOnboarded"]
    },
    preferredRoute() {
      if (!this.authorized || this.initializing) {
        return null
      } else if (!this.isUserOnboarded) {
        if (this.user?.image) {
          return "welcome-2"
        }
        return "welcome-1"
      } else if (this.hasPreGame) {
        if (this.isClientLocked && !this.isHost) {
          return "welcome-3"
        } else if (!this.isHost && this.isGameEnded) {
          return Page.LOBBY
        } else if (this.isGameRunning && this.initialized) {
          return Page.ROOM
        } else if (this.isGameTime && this.initialized) {
          if (this.game?.hostless2) {
            return Page.ROOM
          } else {
            return this.entryPage
          }
        } else {
          return Page.LOBBY
        }
      } else {
        if (this.canEnterGame && (this.isRoomStarted || this.isLockedToGame)) {
          if (!this.isHost) this.isLockedToGame = true
          return Page.ROOM
        } else if (this.game?.hostless2) {
          return Page.ROOM
        } else if (this.canEnterGame) {
          if (this.game?.hostless2) {
            return Page.ROOM
          } else {
            return this.entryPage
          }
        } else if (this.initialized) {
          return "welcome-3"
        } else {
          return null
        }
      }
    },
    canSubscribeToGodsHand() {
      return !!(this.authorized && this.clientID && this.user)
    },
    isDashboardPage() {
      return this.route ? DASHBOARD_PAGES.includes(this.route) : false
    },
    canSubscribeToDashboardData() {
      return !!this.authorized && !!this.isDashboardPage && !!this.isHost
    },
    hasInterimVideo() {
      return !!this.game?.interimVideoCode
    },
    hasPregameIntroVideo() {
      return !!this.client?.lobbyIntroVideo
    },
    getShowScreenShareDialog: {
      get() {
        return this.showScreenShareDialog
      },
      set(val) {
        this.$store.commit(
          "ScreenCapture/UPDATE_SHOW_SCREEN_SHARE_DIALOG",
          !!val
        )
      }
    },
    mobileState() {
      if (!this.user?.id) return null
      return {
        mobile: this.mobile,
        id: this.user.id
      }
    }
  },
  watch: {
    client(value) {
      // reload the page if something terrible happened
      if (!value) window.location.reload()
    },
    // TODO
    // fetch it somewhere else
    canSubscribeToDashboardData(newValue, oldValue) {
      if (newValue && newValue !== oldValue)
        this.$store.dispatch(OrgModuleActionTypes.FETCH_ORGS)
    },
    canSubscribeToGodsHand(newValue, oldValue) {
      if (newValue && newValue !== oldValue)
        this.$store.dispatch("pregame/subscribeToGodsHand")
    },
    gameStartTime: {
      handler(value) {
        const isGameTime = !!(value && this.time > value)
        if (this.isGameTime !== isGameTime) this.isGameTime = isGameTime
      },
      immediate: true
    },
    time: {
      handler(value) {
        const isGameTime = !!(this.gameStartTime && value > this.gameStartTime)
        if (this.isGameTime !== isGameTime) this.isGameTime = isGameTime
      },
      immediate: true
    },
    // TODO - remove this crappy code
    usersToMap: {
      async handler(value) {
        if (!value) return

        const { gameID, force } = value
        if ((!this.user.gameID || force) && !this.initializing) {
          try {
            if (gameID === 0) {
              await this.$store.dispatch("auth/deinitializeGame")
            } else if (gameID && this.user.gameID !== gameID) {
              await this.initializeToGame({
                gameID,
                clientID: this.clientID,
                force
              })
              const name = this.game?.externalName || this.game?.name
              const message =
                force && !this.isHost
                  ? USER_ASSIGN_MESSAGE_TEMPLATE(name)
                  : AUTO_ASSIGN_MESSAGE_TEMPLATE(name)
              this.$queue.enq({ action: () => this.$info(message) })
            }
          } catch (e) {
            console.log(e)
            console.warn(`Could not auto assing ${gameID} game`)
          }
        }
      },
      immediate: false
    },
    user: {
      async handler(newValue, oldValue) {
        if (
          newValue?.id &&
          !oldValue?.id &&
          process.env.VUE_APP_DISABLE_LOGROCKET !== "true"
        ) {
          const { clientID } = this
          const { email } = getCurrentUser()
          const { default: logger } = await LogRocketService()
          logger.initialize(this.$store)
          logger.identify({ ...newValue, email, clientID })
        }

        const name = User.getShortenedName(oldValue)

        const title = name ? `Weve - ${name}` : "Weve"

        if (document.title === title) return

        document.title = title
      },
      immediate: true,
      deep: true
    },
    preferredRoute: {
      handler(value) {
        this.onPreferredRouteChange(value)
      },
      immediate: true
    },
    isHost: {
      immediate: true,
      handler(value) {
        document.documentElement.style.setProperty(
          "--b-toaster-bottom",
          value ? "64px" : "16px"
        )
      }
    },
    mobileState: {
      handler(newValue, oldValue) {
        if (newValue == null) return
        if (
          newValue?.mobile === oldValue?.mobile &&
          newValue?.id === oldValue?.id
        )
          return
        UserService.updateMobileState(newValue.id, newValue.mobile)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions("auth", ["initializeToGame", "reauthenticate"]),
    ...mapActions(["updateShowScreenCaptureDialog"]),
    isLastMission() {
      const missions = MissionCollection.normalize(this.missions)
      return this.mission?.id === missions[missions.length - 1]?.id
    },
    onTick() {
      this.$store.commit("UPDATE_TIME", Date.now())
    },
    onHeartbeatCheckTick() {
      this.$store.commit("SET_HEARTBEAT_CHECK_TIME", Date.now())
    },
    onPreferredRouteChange: _.debounce(async function (value) {
      if (this.isOpenDemoPage) return
      if (this.isDashboardPage) return
      await this.$store.dispatch("bringBackActualGame")
      if (
        value &&
        value.indexOf("welcome") !== -1 &&
        this.route !== "welcome"
      ) {
        console.log("WELCOME ", value)
        await this.pushToWelcome(value)
      } else if (
        value === Page.LOBBY &&
        this.route !== Page.LOBBY &&
        this.route !== "games"
      ) {
        if (
          this.hasPregameIntroVideo &&
          this.role === Role.Player &&
          !PregameVideoStorage.has(this.clientID)
        ) {
          await this.$navigator.navigateTo(`/lobby/${this.clientID}/introvideo`)
        } else if (
          this.isGameEnded &&
          !this.isLastMission() &&
          this.game?.endedBy
        ) {
          await this.$navigator.navigateTo(`/end-early-video`)
        } else {
          await this.pushToLobby()
        }
      } else if (value === Page.ROOM && this.route !== Page.ROOM) {
        if (this.game && this.user) {
          const { theKey: gameID, startTimestamp, gameType } = this.game
          if (startTimestamp) {
            if (
              this.hasInterimVideo &&
              this.role === Role.Player &&
              this.gameStartTime > Date.now() &&
              !InterimVideoStorage.has(this.gameID)
            ) {
              await this.$navigator.navigateTo(
                `/interimvideo/${this.urlID}?route=${value}`
              )
              return
            }

            const video = this.$store.getters[GetterTypes.LATECOMER_VIDEO]
            if (
              (["default", "org"].includes(video.origin) &&
                !this.client?.allowLatecomersAutoAssign) ||
              LatecomerHistoryStorage.has(video.code) ||
              gameType === GameType.GreenRoom
            ) {
              await this.pushToGame()
              return
            }

            if (
              !this.game?.moderated &&
              startTimestamp + LATECOMER_TIME < Date.now() &&
              GameHistoryStorage.has(gameID) === false &&
              this.user.role !== Role.Host &&
              !this.game.skipLatecomerVideo
            ) {
              console.log("⌛️ Seems like you are latecomer...", video)
              LatecomerHistoryStorage.add(video.code)
              GameHistoryStorage.add(gameID)
              await this.$navigator.navigateTo(`/latecomer/${this.urlID}`)
              return
            } else {
              await this.pushToGame()
              return
            }
          }
        }
        await this.pushToGame()
      } else if (
        value === "pickteams" &&
        this.route !== "pickteams" &&
        this.route !== "games"
      ) {
        if (
          this.hasInterimVideo &&
          this.role === Role.Player &&
          !InterimVideoStorage.has(this.gameID)
        ) {
          await this.$navigator.navigateTo(
            `/interimvideo/${this.urlID}?path=${value}`
          )
          return
        }
        await this.pushToPickTeams()
      }
    }, 333),
    async pushToLobby() {
      await this.$navigator.navigateTo(`/lobby/${this.clientID}`)
    },
    async pushToGames() {
      await this.$navigator.navigateTo(`/games`)
    },
    async pushToWelcome(string) {
      const step = string.split("-")
      await this.$navigator.navigateTo(`/login/${this.urlID}?step=${step[1]}`)
    },
    async pushToGame() {
      await this.$navigator.navigateTo(`/game/${this.urlID}`)
    },
    async pushToPickTeams() {
      await this.$navigator.navigateTo(`/pickteams/${this.urlID}`)
    },
    async pushToGameSettings() {
      await this.$store.dispatch("auth/initializeToGame", {
        gameID: this.gameID,
        clientID: this.clientID
      })
      await this.$navigator.navigateTo(`/game/${this.urlID}/settings`)
    },
    onHeartbeatTick() {
      if (this.user?.id) UserService.keepAlive(this.user, this.path)
    }
  }
}
