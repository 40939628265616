import { ref, computed, watch, onBeforeUnmount } from "@vue/composition-api"

import { db } from "@/firebase"

import useStore from "./useStore"

export default function useUserScore(player) {
  const userID = player.value.id
  const { store } = useStore()
  const clientID = computed<string>(() => store.getters["auth/clientID"])
  const isUserScoreOn = computed<boolean>(
    () => store.getters["auth/client"]?.userscoreEnabled
  )

  const points = ref<number>(0)

  const dbRef = computed(() =>
    clientID.value && userID && isUserScoreOn.value
      ? db.auxiliary().ref(`client/${clientID.value}/userscore/${userID}`)
      : null
  )

  const score = computed<number>(() => {
    if (isUserScoreOn.value && points.value > 0) {
      return points.value
    }
  })
  function onSnapshot(snapshot) {
    points.value = parseInt(snapshot.val() || 0)
  }

  watch(
    dbRef,
    (newValue, oldValue) => {
      oldValue?.off("value", onSnapshot)
      newValue?.on("value", onSnapshot)
    },
    { immediate: true }
  )

  onBeforeUnmount(() => dbRef.value?.off("value", onSnapshot))

  return {
    score
  }
}
