const MAX_SOCIAL_SCRIBES = 6
const MAX_PLAYER_CAPACITY = 25
const MULTI_TEAM_THRESHOLD = 3
const MAX_N_OF_SCRIBES_IN_MILTI_TEAM = 2
const DEFAULT_MAX_N_OF_USERS_PER_TEAM = 10
const TEAM_ALLOCATION_MISSION_INDEX_THRESHOLD = 4

module.exports = {
  MAX_SOCIAL_SCRIBES,
  MAX_PLAYER_CAPACITY,
  MULTI_TEAM_THRESHOLD,
  MAX_N_OF_SCRIBES_IN_MILTI_TEAM,
  DEFAULT_MAX_N_OF_USERS_PER_TEAM,
  TEAM_ALLOCATION_MISSION_INDEX_THRESHOLD
}
