import { getCurrentInstance } from "@vue/composition-api"
import type { ComponentInternalInstance } from "@vue/composition-api"

export default function useConfirm() {
  const instance = getCurrentInstance() as ComponentInternalInstance & {
    proxy: {
      $confirm: (...args: any[]) => void
    }
  }

  async function c(...args) {
    try {
      await instance.proxy.$confirm(...args)
      return true
    } catch (_) {
      return false
    }
  }

  return { confirm: c }
}
