<template>
  <div
    class="rtb-game-team"
    :class="{
      'rtb-game-team--current': top,
      'rtb-game-team--huddle': isTopHuddle,
      'rtb-game-team--over': isTeamResults,
      'rtb-game-team--compressed': isHost,
      'rtb-game-team--crowded': hasManyPlayers
    }"
  >
    <div style="width: 100%" :style="countdown ? 'padding-bottom: 32px' : null">
      <TeamScrollerTeamLabel
        class="rtb-game-team__location-label"
        v-if="location"
        :value="location"
      />
      <transition-group
        tag="div"
        class="rtb-game-team__row"
        name="game-row-transition"
      >
        <div
          v-for="player in playersWithStatus"
          class="rtb-game-team__row-item"
          :class="{ 'rtb-game-team__row-item--wide': player.conference }"
          :key="`game-player-${player.id}-${player.teamID}`"
        >
          <GamePlayerCard
            v-if="player"
            :rounded="!isTopHuddle && !isTeamResults && !player.conference"
            :user="player"
            :teamPosition="teamPosition"
            @click.shift="onShiftClick(player)"
            @dblclick="selectTheUser(player)"
          />
        </div>
      </transition-group>

      <div class="rtb-game-team__timer" v-if="countdown">
        <PillCountdown :endTime="endTime" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Vue from "vue"
import { db } from "@/firebase"
import GamePlayerCard from "@/components/GroupTeams/Common/Player/GamePlayerCard/GamePlayerCard.vue"
import PillCountdown from "@/components/GroupTeams/Common/PillCountdown"
import User from "@shared/User"
import Mode from "@shared/enums/Mode"
import MissionType from "@shared/enums/Mission"
import Mission from "@shared/Mission"

import useTelephonePictionary from "@/components/GroupTeams/Common/Games/TelephonePictionary/useTelephonePictionary"
import { Module } from "./Games/JeopardAI/enums"
import useBuzzIn from "@/use/useBuzzIn"
import useMusicalKeysState from "@/components/GroupTeams/Common/Games/MusicalKeys/useMusicalKeysState.ts"

const SILENT_MISSION_TYPES = [
  MissionType.TeamName,
  MissionType.Categories,
  MissionType.FactMatch,
  MissionType.MatchGame
]

export default {
  name: "TeamView",
  components: {
    TeamScrollerTeamLabel: () =>
      import("@/components/Main/TeamScrollerTeamLabel"),
    GamePlayerCard,
    PillCountdown
  },
  props: {
    mode: String,
    endTime: Number,
    countdown: {
      type: Boolean,
      default: false
    },
    minified: {
      type: Boolean,
      default: false
    },
    potato: {
      type: Boolean,
      default: false
    },
    teamPosition: {
      type: Number,
      default: 0
    },
    top: {
      type: Boolean,
      default: false
    },
    starPlayerID: {
      type: String,
      default: null
    },
    teamID: {
      required: true
    }
  },

  setup() {
    const { isAnswered, answeredUserIds } = useMusicalKeysState()
    const { fakePlays } = useTelephonePictionary()

    const { swapBuzzUsers } = useBuzzIn()

    return {
      answeredUserIds,
      isAnswered,
      swapBuzzUsers,
      fakePlays
    }
  },

  data() {
    return {
      isTeamHidden: false,
      bus: new Vue()
    }
  },
  computed: {
    ...mapGetters({
      plays: "missionPlaysArray",
      missionID: "currentMission",
      mission: "getCurrentMission",
      teams: "chats"
    }),
    ...mapGetters([
      "onlineUsersGroupedByTeam",
      "isScribe",
      "getCurrentMode",
      "showOnboardingPlayers",
      "gameStatus",
      "game",
      "moderatorID"
    ]),
    ...mapGetters("auth", ["isHost", "isModerator", "isAudit", "client"]),
    isTeamResults() {
      return this.getCurrentMode === "over"
    },
    isTopHuddle() {
      return this.top && this.mode === "huddle"
    },
    location() {
      if (!this.$store.getters["group/hasHybridLocations"]) return null

      return this.players.find(user => user.identifier)?.identifier
    },
    hasManyPlayers() {
      return this.onlineUsersGroupedByThisTeam?.length > 4
    },
    playType() {
      return this.mission ? this.mission.playType : null
    },
    behavior() {
      return this.mission ? this.mission.behavior : null
    },
    canPassScribe() {
      return this.mission ? !!this.mission.passScribe : false
    },
    onlineUsersGroupedByThisTeam() {
      const mission = this.mission
      const teamId = this.teamID
      const users = this.onlineUsersGroupedByTeam[teamId] ?? []

      if (mission.behavior !== MissionType.JeopardAI) return users

      const scribes = this.$store.state.JeopardAi.scribes ?? []

      return users.map(user => {
        const selected = scribes.some(({ id }) => id === user.id)
        return {
          ...user,
          selected: user.selected,
          jeopardai: selected
        }
      })
    },
    predicates() {
      const isUserHostLike = this.$store.getters["group/isUserHostLike"]

      const { showOnboardingPlayers, isHost } = this

      const predicates = [user => !isUserHostLike(user), User.isPlayer]

      if (isHost && !showOnboardingPlayers) predicates.push(User.isPlaying)

      return predicates
    },
    players() {
      const { predicates, onlineUsersGroupedByThisTeam, minified } = this
      if (!Array.isArray(onlineUsersGroupedByThisTeam)) return []

      const canPassPredicates = user =>
        predicates.every(predicate => predicate(user))

      if (!minified)
        return onlineUsersGroupedByThisTeam.filter(canPassPredicates)

      const users = onlineUsersGroupedByThisTeam.filter(
        user =>
          canPassPredicates(user) &&
          (User.isScribe(user) || User.isSpecialPurposeUser(user))
      )

      if (users.length < 1)
        return [onlineUsersGroupedByThisTeam.find(canPassPredicates)]

      return users
    },
    isPaused() {
      return (
        this.$store.getters["auth/isHybridRoom"] &&
        Mission.isPausable(this.mission) &&
        this.gameStatus?.paused !== true
      )
    },
    playersWithStatus() {
      const isSilent = this.isSilent
      const isPaused = this.isPaused
      const plays = this.customMissionPlays

      const getStatusByUserID = userID => {
        const filtered = plays.filter(play => play.userID === userID)

        return {
          submitted: filtered.length > 0,
          winner:
            !isSilent && !isPaused && filtered.some(({ score }) => score > 0)
        }
      }

      return this.players
        .filter(user => user != null)
        .map(user => ({
          ...user,
          playStatus: getStatusByUserID(user.id),
          covered: this.isUserCovered(user)
        }))
    },
    customMissionPlays() {
      const teamID = this.teamID

      const twoTruthsID =
        this.mission?.behavior === Mission.TwoTruthsReveal
          ? this.gameStatus?.twoTruthsID
          : null

      if (twoTruthsID)
        return this.plays.filter(
          play => play.teamID === teamID && play.twoTruthsID === twoTruthsID
        )

      if (this.behavior === MissionType.TelephonePictionary)
        return this.fakePlays

      if (this.behavior === MissionType.MusicalKeys) {
        return this.answeredUserIds.map(userID => ({ userID }))
      }

      return this.plays.filter(play => play.teamID === teamID)
    },
    isBuzzIn() {
      return this.behavior === MissionType.BuzzIn
    },
    user() {
      return this.$store.state.auth.user
    },
    isIndividualMission() {
      return String(this.playType).toLowerCase().indexOf("individual") > -1
    },
    isSilent() {
      return (
        SILENT_MISSION_TYPES.includes(this.behavior) ||
        this.mode === Mode.Social ||
        this.isIndividualMission
      )
    }
  },
  methods: {
    ...mapActions("UserSettings", { openUpdateUser: "updateUser" }),
    onShiftClick(player) {
      if (this.isHost) {
        this.skipTheUser(player)
      }
      if (this.isAudit) {
        this.editUser(player)
      }
    },
    async selectTheUser(user) {
      const player = this.players.find(({ id }) => id === user.id)

      if (!player) throw new Error(`player ${user.id} is undefined`)

      const isTheSameTeam = this.user.teamID === player.teamID
      const isSelected = this.user.selected
      const canPassScribe =
        this.canPassScribe &&
        User.isPlayer(this.user) &&
        isSelected &&
        isTheSameTeam

      if (this.isHost || this.isAudit) {
        const selected = !player.selected
        const muted = selected ? false : !!player.muted
        const update = {
          [`org/1/users/${player.id}/selected`]: selected,
          [`org/1/users/${player.id}/muted`]: muted
        }

        if (this.isBuzzIn) {
          this.swapBuzzUsers(player)
        }
        await db.auxiliary().ref().update(update)
      } else if (canPassScribe) {
        // select your team mate
        const update = {
          [`org/1/users/${player.id}/selected`]: true,
          [`org/1/users/${player.id}/muted`]: false,
          [`org/1/users/${this.user.id}/selected`]: false
        }
        await db.auxiliary().ref().update(update)
      }
    },
    skipTheUser(user) {
      if (!user) return 0
      if (!this.isHost) return 0
      db.auxiliary().ref(`org/1/users/${user.id}/skip`).set(!user?.skip)
    },
    huddle() {
      this.bus.$emit("huddle")
    },
    unhuddle() {
      this.bus.$emit("unhuddle")
    },
    showTeam() {
      this.isTeamHidden = false
    },
    hideTeam() {
      this.isTeamHidden = true
    },
    togglePlayers() {
      this.isTeamHidden = !this.isTeamHidden
    },
    editUser(user) {
      this.openUpdateUser({ user })
    },
    isUserCovered(user) {
      if (
        this.mode === Mode.Play &&
        this.behavior === MissionType.JeopardAI &&
        this.$store.state.JeopardAi.module === Module.ANSWERS &&
        this.$store.state.JeopardAi.users.some(u => u.id === user.id)
      ) {
        return true
      }

      if (
        this.mode === Mode.Social &&
        user.selected &&
        ![
          MissionType.MatchGame,
          MissionType.TwoTruthsReveal,
          MissionType.OrderTheCards
        ].includes(this.behavior)
      ) {
        return true
      }

      if (!this.top && this.potato) {
        return !this.isIndividualMission ? !this.selected : true
      }

      if (
        this.mode === Mode.Play &&
        this.behavior === MissionType.MusicalKeys &&
        this.isAnswered(user)
      ) {
        return user.selected
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.rtb-game-team {
  $block: &;

  flex: 1 1 auto !important;
  align-items: center;
  display: flex;
  max-width: 100%;
  height: 100%;
  width: 100%;

  &__location-label {
    position: absolute;
  }

  &__timer {
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }

  &__row {
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    display: flex;
    justify-content: center;
    flex: 1 1 auto;
    flex-wrap: nowrap;
    min-width: 0;
  }

  &__row-item {
    --team-row-item-min-width: 72px;
    --team-row-item-max-width: 16vmin;
    margin-left: 16px;
    margin-right: 16px;
    min-width: var(--team-row-item-min-width);
    max-width: var(--team-row-item-max-width);
    transition: max-width 0.3s;
    font-size: 12px;
    flex: 1;

    #{$block}--compressed & {
      --team-row-item-max-width: 14vmin;
    }

    #{$block}--current & {
      margin: 4px 16px;
      --team-row-item-min-width: 120px;
      --team-row-item-max-width: 20vmin;
      font-size: 14px;
    }

    #{$block}--current#{$block}--compressed & {
      --team-row-item-max-width: 17vmin;
    }

    #{$block}--current#{$block}--huddle & {
      --team-row-item-max-width: calc(100vh * 0.45 - 130px);
      font-size: 16px;
    }

    #{$block}--over & {
      margin: 4px 0.75%;
      --team-row-item-max-width: 20vh;
    }

    #{$block}--crowded & {
      margin-left: 2px;
      margin-right: 2px;
    }

    &--wide {
      min-width: calc(var(--team-row-item-min-width) * 1.6);
      max-width: calc(var(--team-row-item-max-width) * 1.6);
    }
  }

  &__player-team-icon {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-8%) translateY(-8%);
    z-index: 10;
  }
  &__show-onboarding-switch {
    position: absolute;
    top: 5px;
    left: 9px;
    margin: 0 !important;
    padding: 0;
    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
    .v-input--selection-controls__input {
      width: 28px;
      margin-right: 0;
    }
    .v-input--selection-controls__ripple {
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
    }
    .v-input--switch__track {
      height: 10px;
      top: calc(50% - 5px);
      color: $color-primary-dark !important;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-black, 0.33);
      }
    }
    .v-input--switch__thumb {
      height: 16px;
      width: 16px;
      top: calc(50% - 8px);
      color: $primary_accent_color !important;
      caret-color: $primary_accent_color !important;
      box-shadow: none !important;
    }
    &.v-input--is-dirty .v-input--switch__thumb {
      transform: translate(12px, 0);
    }
  }
}

.game-row-transition-enter-active {
  transition: all 0.5s ease 0s;
}
.game-row-transition-leave-active {
  transition: all 0.5s ease 0.6s;
}
.game-row-transition-enter,
.game-row-transition-leave-to {
  opacity: 0;
  min-width: 0;
  max-width: 0;
}
</style>
