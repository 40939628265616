var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onCloseChatBody,
          expression: "onCloseChatBody",
        },
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isLoadingChat,
          expression: "!isLoadingChat",
        },
      ],
      staticClass: "hubspot-chat-wrapper",
      class:
        ((_obj = {
          "hubspot-chat-wrapper--mobile": _vm.mobile,
        }),
        (_obj["hubspot-chat-wrapper__" + _vm.position] = _vm.position),
        (_obj["margin-right"] =
          ["welcome", "pregame"].includes(_vm.$route.name) && !_vm.mobile),
        _obj),
      attrs: { column: "" },
    },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showChatBody,
              expression: "showChatBody",
            },
          ],
          staticClass: "hubspot-chat-wrapper__chat-body",
          class: { "chat-loading": _vm.isLoadingChat },
          attrs: { column: "" },
        },
        [
          _vm.showChatBody
            ? _c(
                "div",
                {
                  staticClass: "hubspot-chat-wrapper__close-chat",
                  on: { click: _vm.onCloseChatBody },
                },
                [
                  _c("svg-icon", {
                    attrs: { name: "times-regular", width: "24", height: "24" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isLoadingChat ? _c("rtb-spinner") : _vm._e(),
          _c("v-flex", { attrs: { grow: "", id: "__Hubspot_chat_wrapper__" } }),
        ],
        1
      ),
      !_vm.showChatBody && _vm.canShowChatHead
        ? _c(
            "v-layout",
            {
              staticClass: "hubspot-chat-wrapper__chat-head",
              class: {
                "hubspot-chat-wrapper__chat-head--minimize":
                  _vm.shouldMinimizeChatHead,
              },
              attrs: { "d-flex": "" },
              on: { click: _vm.onOpenChatBody },
            },
            [
              _c("v-flex", { staticClass: "chat-head__notice centered-item" }, [
                _c("div", [_vm._v("Need assistance?")]),
                _vm.unreadMessageCount
                  ? _c("div", { staticClass: "unread-message" }, [
                      _vm._v(" " + _vm._s(_vm.unreadMessageCount) + " "),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-flex",
                { staticClass: "chat-head__icon centered-item" },
                [
                  _c("svg-icon", {
                    attrs: {
                      width: "32",
                      height: "32",
                      name: "chatcirclefill",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }