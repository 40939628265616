export default class PregameVideoStorage {
  /**
   * @param {string} clientID
   * @returns {bolean}
   */
  static add(clientID) {
    try {
      const value = localStorage.getItem(PregameVideoStorage.STORAGE_PATH)
      if (value === null) {
        localStorage.setItem(
          PregameVideoStorage.STORAGE_PATH,
          JSON.stringify({ [clientID]: true })
        )
      } else {
        const newValue = JSON.parse(value) || {}
        localStorage.setItem(
          PregameVideoStorage.STORAGE_PATH,
          JSON.stringify({ ...newValue, [clientID]: true })
        )
      }

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  /**
   * @param {string} clientID
   * @returns {boolean}
   */
  static has(clientID) {
    try {
      const value = localStorage.getItem(PregameVideoStorage.STORAGE_PATH)

      if (value === null) {
        return false
      } else {
        const parsed = JSON.parse(value)
        return (
          parsed != null &&
          typeof parsed == "object" &&
          !Array.isArray(parsed) &&
          parsed?.[clientID]
        )
      }
    } catch (e) {
      console.log(e)
      return true
    }
  }

  static STORAGE_PATH = "PREGAME_VIDEO"
}
