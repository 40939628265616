/**
 * @readonly
 * @enum {string}
 */
const DEFAULT_STYLES = {
  "--primary-accent-color": "#6E69D1",
  "--secondary-accent-color": "#f5665d",
  "--tertiary-accent-color": "#9100ff",
  "--logo-color": "#f36523",
  "--banner-background-color": "#f36523",
  "--correct-color": "#5AAE59",
  "--wrong-color": "#FF4060",
  "--disabled-color": "#b1b1b1",
  "--primary-background-color": "#16111A",
  "--secondary-background-color": "#1e2025"
}
/**
 * @readonly
 * @enum {string}
 */
const DEFAULT_THEME_STYLES = {
  "--primary-accent-color": DEFAULT_STYLES["--primary-accent-color"],
  "--secondary-accent-color": DEFAULT_STYLES["--secondary-accent-color"],
  "--tertiary-accent-color": DEFAULT_STYLES["--tertiary-accent-color"],
  "--logo-color": DEFAULT_STYLES["--logo-color"],
  "--banner-background-color": DEFAULT_STYLES["--banner-background-color"],
  "--correct-color": DEFAULT_STYLES["--correct-color"],
  "--wrong-color": DEFAULT_STYLES["--wrong-color"],
  "--primary-background-color": DEFAULT_STYLES["--primary-background-color"],
  "--secondary-background-color": DEFAULT_STYLES["--secondary-background-color"]
}

/**
 * @readonly
 * @type {Record<DEFAULT_THEME_STYLES, string>}
 */
const Map = {
  "--primary-accent-color": "color-primary-accent",
  "--secondary-accent-color": "color-secondary-accent",
  "--tertiary-accent-color": "color-tertiary-accent",
  "--logo-color": "color-logo",
  "--banner-background-color": "color-banner-background",
  "--correct-color": "color-correct",
  "--wrong-color": "color-wrong",
  "--disabled-color": "color-disabled",
  "--primary-background-color": "color-primary-background",
  "--secondary-background-color": "color-secondary-background",
  "--tertiary-background-color": "color-tertiary-background"
}

const DEFAULT_IMAGES = {
  // logo: "/assets/img/weve-login-logo.svg",
  logo: "/assets/img/logo-orange.webp",
  banner: "/assets/img/default-branding.png",
  loginCarousel: [
    "/assets/img/login-carousel/1.jpg",
    "/assets/img/login-carousel/2.jpg",
    "/assets/img/login-carousel/3.jpg",
    "/assets/img/login-carousel/4.jpg",
    "/assets/img/login-carousel/5.jpg",
    "/assets/img/login-carousel/6.jpg"
  ]
}

/**
 * @readonly
 * @type {import('./types').ThemeMeta}
 */
const DEFAULT_META = {
  hideGoGame: false,
  tagline: "Formerly Go Remote"
}

const ImagesToClientMap = {
  logo: "loginLogo",
  banner: "logoImage",
  expoLogo: "customLogo",
  expoLogoLarge: "customLogoLarge",
  loginCarousel: "images"
}

const TAGS = [
  { value: "client", label: "Session", color: "#1867c0" },
  { value: "user", label: "User", color: "#5cbbf6" },
  { value: "tgg", label: "TGG", color: "#3f51b5" }
]

exports.DEFAULT_STYLES = DEFAULT_STYLES
exports.DEFAULT_THEME_STYLES = DEFAULT_THEME_STYLES
exports.DEFAULT_IMAGES = DEFAULT_IMAGES
exports.DEFAULT_META = DEFAULT_META
exports.Map = Map
exports.ImagesToClientMap = ImagesToClientMap
exports.TAGS = TAGS
