var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "fact-card",
        class: {
          "fact-card--selected": _vm.selected,
          "fact-card--disabled": _vm.disabled,
        },
      },
      _vm.$listeners
    ),
    [
      _vm.isImage
        ? _c(
            "v-layout",
            [
              _c("v-flex", { attrs: { shrink: "" } }, [
                _c("img", {
                  staticClass: "fact-card__user-image",
                  attrs: { src: _vm.image },
                }),
              ]),
              _c("v-flex", { attrs: { "d-flex": "", "align-center": "" } }, [
                _c("span", { staticClass: "fact-card__user-name" }, [
                  _vm._v(" " + _vm._s(_vm.firstName) + " "),
                ]),
              ]),
            ],
            1
          )
        : _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function (ref) {
                    var on = ref.on
                    return [
                      _c(
                        "span",
                        _vm._g({ staticStyle: { "line-height": "1" } }, on),
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("truncate")(_vm.correct, 50)) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "div",
                { staticStyle: { width: "300px", "line-height": "1" } },
                [_vm._v(" " + _vm._s(_vm.correct) + " ")]
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }