<template>
  <span />
</template>

<script>
import { mapGetters } from "vuex"
import MissionCollection from "@shared/MissionCollection"

export default {
  name: "LinkManager",
  data() {
    return {
      unwatch: null,
      headEl: null,
      headLinks: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.headEl = document.getElementsByTagName("head")[0]
      this.unwatch = this.$watch(
        "assets",
        (after = {}, before = {}) => {
          Object.keys(after)
            .filter(url => !before[url])
            .forEach(this.append)
          Object.keys(before)
            .filter(url => !after[url])
            .forEach(this.detach)
        },
        { immediate: true }
      )
    })
  },
  beforeDestroy() {
    this.unwatch && this.unwatch()
    const headEl = this.headEl
    Object.keys(this.headLinks).forEach(this.detach)
  },
  methods: {
    append(url) {
      const link = document.createElement("link")
      link.as = "video"
      link.rel = "preload"
      link.href = url
      this.$set(this.headLinks, url, link)
      this.headEl && this.headEl.appendChild(link)
    },
    detach(url) {
      const link = this.headLinks[url]
      this.$delete(this.headLinks, url)
      this.headEl && this.headEl.removeChild(link)
    }
  },
  computed: {
    ...mapGetters(["gameStatus", "missions"]),
    assets() {
      const missionID = this.gameStatus?.current_mission?.id
      const missions = MissionCollection.normalize(this.missions)
      const index = missionID
        ? missions.findIndex(mission => mission.id === missionID)
        : -1
      return [missions[index], missions[index + 1]].reduce((acc, val) => {
        Object.values(val?.["hostless-video"] || {}).forEach(data => {
          if (data && data.url) acc[data.url] = true
        })
        return acc
      }, {})
    }
  }
}
</script>
