import Video from "twilio-video"
import EventEmitter from "events"

const DEFAULT_VIDEO_BANDWIDTH_PROFILE = {
  trackSwitchOffMode: "detected",
  mode: "collaboration",
  dominantSpeakerPriority: "high"
}

export class UnknownError extends Error {
  constructor() {
    super("Unknown error")
  }
}

class TwilioRoom extends EventEmitter {
  constructor(token, identity, name) {
    super()
    this._token = token
    this._identity = identity
    this._name = name
    this._room = null
    this._users = {}

    this._onTrackSubscribed = this._onTrackSubscribed.bind(this)
    this._onTrackUnsubscribed = this._onTrackUnsubscribed.bind(this)
    this._onDisconnect = this._onDisconnect.bind(this)
    this._onDominantSpeakerChanged = this._onDominantSpeakerChanged.bind(this)
  }
  static isSupportedBrowser() {
    return /Edge/.test(navigator.userAgent) === false && Video.isSupported
  }
  static connect({ name, videoTrack, audioTrack, token, bandwidthProfile }) {
    const tracks = []

    if (videoTrack) tracks.push(videoTrack)
    if (audioTrack) tracks.push(audioTrack)

    return Video.connect(token, {
      name,
      tracks,
      preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
      dominantSpeaker: true,
      bandwidthProfile: {
        ...bandwidthProfile,
        video: {
          ...DEFAULT_VIDEO_BANDWIDTH_PROFILE,
          ...bandwidthProfile?.video
        }
      }
    })
  }
  async connect({ videoTrack, audioTrack }) {
    this._users[this._identity] = {
      video: videoTrack,
      audio: audioTrack
    }

    this.emit("users", this._users)

    this._room = await TwilioRoom.connect({
      name: this._name,
      token: this._token,
      videoTrack,
      audioTrack
    })

    this._room.on("disconnected", this._onDisconnect)
    this._room.on("trackSubscribed", this._onTrackSubscribed)
    this._room.on("trackUnsubscribed", this._onTrackUnsubscribed)
    this._room.on("dominantSpeakerChanged", this._onDominantSpeakerChanged)
  }
  _onDisconnect(_, e) {
    this._users = {}
    this.emit("users", this._users)
    this.emit("myerror", e ?? new UnknownError())
    this._room?.off("disconnected", this._onDisconnect)
    this._room?.off("trackSubscribed", this._onTrackSubscribed)
    this._room?.off("trackUnsubscribed", this._onTrackUnsubscribed)
    this._room?.off("dominantSpeakerChanged", this._onDominantSpeakerChanged)
    this._room?.localParticipant.tracks?.forEach(publication => {
      publication?.track?.stop()
      publication?.unpublish()
    })
  }
  disconnect() {
    this._room?.off("disconnected", this._onDisconnect)
    this._room?.off("trackSubscribed", this._onTrackSubscribed)
    this._room?.off("trackUnsubscribed", this._onTrackUnsubscribed)
    this._room?.off("dominantSpeakerChanged", this._onDominantSpeakerChanged)
    this._room?.localParticipant?.tracks?.forEach(publication => {
      publication?.track?.stop()
      publication?.unpublish()
    })
    this._users = {}
    this._room?.disconnect()
  }
  _onDominantSpeakerChanged = participant => {
    this.emit("dominantSpeakerIdChanged", participant?.identity)
  }
  _onTrackSubscribed(track, _, { identity }) {
    if (!this._users[identity]) this._users[identity] = {}
    if (track.kind === "video") {
      this._users[identity].video = track
      this.emit("users", this._users)
    } else if (track.kind === "audio") {
      this._users[identity].audio = track
      this.emit("users", this._users)
    }
  }
  _onTrackUnsubscribed(track, _, { identity }) {
    if (this._users[identity]) {
      if (track.kind === "video" && this._users[identity].video) {
        delete this._users[identity].video
        this.emit("users", this._users)
      } else if (track.kind === "audio" && this._users[identity].audio) {
        delete this._users[identity].audio
        this.emit("users", this._users)
      }
    }
  }
}

export { TwilioRoom }
