module.exports = {
  Welcome: "welcome",
  Default: "welcome",
  Explain: "explain",
  Play: "play",
  Huddle: "huddle",
  Social: "social",
  Voting: "voting",
  Results: "results",
  Over: "over",
  Meeting: "meeting",
  Theater: "theater"
}
