var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "RtbSnackBar",
    {
      attrs: {
        timeout: _vm.timeout,
        isVisible: _vm.isVisible,
        variant: _vm.variant,
        offset: _vm.offset,
        right: _vm.right,
      },
      on: { change: _vm.onChange },
    },
    _vm._l(_vm.text, function (item, index) {
      return _c("p", {
        key: item + index,
        domProps: { innerHTML: _vm._s(item) },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }