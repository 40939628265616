<template>
  <MatchGameResult :items="items" />
</template>

<script>
import { computed } from "@vue/composition-api"

import useMissions from "@/use/useMissions"

import MatchGameResult from "./MatchGameResult.vue"

import MissionCollection from "@shared/MissionCollection"

export default {
  name: "OrderTheCardsResult",
  components: {
    MatchGameResult
  },
  props: {
    teamID: String
  },
  setup() {
    const { missions } = useMissions()
    const array = computed(() => MissionCollection.normalize(missions.value))
    return { missions: array }
  },
  computed: {
    mission() {
      return this.missions.find(mission => mission.id === this.play.missionID)
    },
    play() {
      return this.$store.getters["missionPlaysArray"].find(
        ({ teamID }) => teamID === this.teamID
      )
    },
    answers() {
      return Array.isArray(this.play?.answer) ? this.play?.answer : []
    },
    topCards() {
      return (this.mission?.orderCardsTop || []).filter(Boolean)
    },
    bottomCards() {
      return (this.mission?.orderCardsBottom || []).filter(Boolean)
    },
    items() {
      return this.topCards.map((image, index) => ({
        question: image,
        answer: this.bottomCards?.[index] ?? "",
        correct: !!this.answers[index]?.result
      }))
    }
  }
}
</script>
