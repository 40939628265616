var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "weve-loading" }, [
    _c(
      "div",
      { staticClass: "weve-loading__container" },
      [
        _c("img", {
          staticClass: "weve-loading__logo",
          attrs: { src: _vm.logoUrl },
        }),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-progress-linear", {
                  attrs: { color: "blue", indeterminate: "" },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }