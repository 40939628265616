module.exports = {
  Login: "Login",
  FreeForm: "Freeform",
  TeamName: "Team Name",
  Giphy: "Giphy",
  Link: "Link",
  Info: "Info",
  YouTube: "YouTube",
  BuzzIn: "Buzz In",
  Text: "Text",
  Categories: "Categories",
  MultipleChoice: "Multiple Choice",
  RatingPoll: "Rating Poll",
  Poll: "Poll",
  FactMatch: "Fact Match",
  Photo: "Photo",
  TakePhoto: "Take Photo",
  GameOver: "Game Over",
  Lipdub: "Lipdub",
  DrawingEachTeam: "Draw: Each Team",
  DrawingPictionary: "Draw: Pictionary",
  Represent: "Represent",
  PhotoBooth: "Photo Booth",
  Slides: "Slides",
  MatchGame: "Match Game",
  OrderTheCards: "Order The Cards",
  TwoTruths: "Two Truths",
  TwoTruthsReveal: "Two Truths Reveal",
  Award: "Award",
  Unlock: "Unlock",
  RoyalRumble: "Royal Rumble",
  Video: "Video",
  VideoIndividual: "Video Individual",
  VideoTeam: "Video Team",
  StartButton: "Start Button",
  OpenWeveStart: "Open Weve Start",
  PriceIsRight: "Price Is Right",
  WhiteElephant: "White Elephant",
  HandMovement: "Hand Movement",
  URL: "URL",
  Raffle: "Raffle",
  PlayerRating: "Player Rating",
  TeamPhoto: "Team Photo",
  TelephonePictionary: "Telephone Pictionary",
  UnconditionalPositiveRegard: "Unconditional Positive Regard",
  WordCloud: "Word Cloud",
  Charades: "Charades",
  FamilyFeud: "Family Feud",
  SpiralDrawing: "Spiral Drawing",
  DiscussionGroup: "Discussion Group",
  QuestionCards: "Question Cards",
  QRCode: "QR Code",
  JeopardAI: "JeopardAI",
  MusicalKeys: "Musical Keys"
}
