export default class InterimVideoStorage {
  /**
   * @param {string} gameID
   * @returns {bolean}
   */
  static add(gameID) {
    try {
      const value = localStorage.getItem(InterimVideoStorage.STORAGE_PATH)
      if (value === null) {
        localStorage.setItem(
          InterimVideoStorage.STORAGE_PATH,
          JSON.stringify({ [gameID]: true })
        )
      } else {
        const newValue = JSON.parse(value) || {}
        localStorage.setItem(
          InterimVideoStorage.STORAGE_PATH,
          JSON.stringify({ ...newValue, [this.gameID]: true })
        )
      }

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  /**
   * @param {string} gameID
   * @returns {boolean}
   */
  static has(gameID) {
    try {
      const value = localStorage.getItem(InterimVideoStorage.STORAGE_PATH)

      if (value === null) {
        return false
      } else {
        const parsed = JSON.parse(value)
        return (
          parsed != null &&
          typeof parsed == "object" &&
          !Array.isArray(parsed) &&
          parsed?.[gameID]
        )
      }
    } catch (e) {
      console.log(e)
      return true
    }
  }

  static STORAGE_PATH = "INTERIM_VIDEO"
}
