const MAX_LENGTH = 10
const OFFSET = 3
export function getName(
  firstname?: string | null | undefined,
  lastname?: string | null | undefined
): string {
  let name = firstname ?? ""
  if (name.length > MAX_LENGTH) {
    name = name.substring(0, MAX_LENGTH)
  } else if (lastname && name.length + OFFSET <= MAX_LENGTH) {
    name = name + " " + lastname.charAt(0) + "."
  }
  return name
}
