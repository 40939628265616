<template>
  <span />
</template>

<script>
import _ from "lodash"
import { TwilioRoom } from "@/services/twilio.service"
import { ActionTypes as TwilioModuleActionTypes } from "@/store/TwilioModule"

export default {
  name: "TwilioRoom",
  props: {
    roomId: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    audio: {
      type: Boolean,
      default: true
    },
    video: {
      type: Boolean,
      default: true
    },
    videoTrack: {
      default: undefined
    },
    constraints: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      room: null
    }
  },
  methods: {
    onError(e) {
      this.$emit("onError", e)
    },
    onDominantSpeakerIdChanged(id) {
      this.$emit("onDominantSpeakerIdChanged", id)
    },
    onUsers(users) {
      this.$emit("onUsers", users)
    }
  },
  async created() {
    let videoTrack = null
    let audioTrack = null
    let token = null

    try {
      if (this.audio)
        audioTrack = await this.$store.dispatch(
          `twilio/${TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK}`
        )

      if (this.video && this.videoTrack) videoTrack = this.videoTrack
      else if (this.video)
        videoTrack = await this.$store.dispatch(
          `twilio/${TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK}`,
          this.constraints
        )
    } catch (e) {
      console.error(e)
      this.$info(e.message)
    }

    try {
      token = await this.$store.dispatch("twilio/GET_TOKEN", this.userId)
    } catch (e) {
      console.error(e)
      this.$info(e.message)
      return
    }

    this.room = new TwilioRoom(token, this.userId, this.roomId)
    this.room.on("users", this.onUsers)
    this.room.on("myerror", this.onError)
    this.room.on("dominantSpeakerIdChanged", this.onDominantSpeakerIdChanged)

    await this.room.connect({ videoTrack, audioTrack })
  },
  beforeDestroy() {
    this.room?.off("users", this.onUsers)
    this.room?.off("myerror", this.onError)
    this.room?.off("dominantSpeakerIdChanged", this.onDominantSpeakerIdChanged)
    this.room?.disconnect()
    this.onUsers(null)
    this.onDominantSpeakerIdChanged(null)
  }
}
</script>
