import { ref, computed, watch, onBeforeUnmount } from "@vue/composition-api"
import TeamService from "@/services/team.service"

import useIdentities from "./useIdentities"

export default function useTeams(roomId?: string) {
  const { orgID, gameID } = useIdentities()

  const teams = ref<Record<string, any>>({})

  const teamsRef = computed(() =>
    TeamService.chatsRef(orgID.value, roomId || gameID.value)
  )

  function onSnapshot(snapshot) {
    teams.value = (snapshot.val() || {}) as Record<string, any>
  }

  watch(
    teamsRef,
    (newValue, oldValue) => {
      oldValue?.off("value", onSnapshot)
      newValue.on("value", onSnapshot)
    },
    { immediate: true }
  )

  onBeforeUnmount(() => teamsRef.value.off("value", onSnapshot))

  return {
    teams
  }
}
