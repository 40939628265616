import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

const Module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Module

export const MODULE_NAME = "theme"
