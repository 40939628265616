var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SmartMedia",
    _vm._g(
      _vm._b(
        {
          ref: "media",
          attrs: { src: _vm.src, autoplay: "", paused: _vm.paused },
          on: { play: _vm.play, ready: _vm.setCurrentTime, pause: _vm.pause },
        },
        "SmartMedia",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }