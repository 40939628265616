var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "text-input",
    _vm._g(
      _vm._b(
        {
          staticClass: "rtb-color-picker",
          attrs: { value: _vm.value, "label-size": "small" },
          scopedSlots: _vm._u([
            {
              key: "append",
              fn: function () {
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", "close-on-content-click": false },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "input-button",
                                _vm._g(
                                  { attrs: { title: "Choose Color" } },
                                  on
                                ),
                                [
                                  _c("span", {
                                    staticClass: "rtb-color-picker__swatch",
                                    style: { backgroundColor: _vm.value },
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.menu,
                        callback: function ($$v) {
                          _vm.menu = $$v
                        },
                        expression: "menu",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("color-picker", {
                            model: {
                              value: _vm.model,
                              callback: function ($$v) {
                                _vm.model = $$v
                              },
                              expression: "model",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("constrast-preview", {
                    staticClass: "rtb-color-picker__contrast-preview",
                    attrs: { color: _vm.value },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        "text-input",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }