var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.error && _vm.roomId && _vm.userId && _vm.constraints
    ? _c("TwilioRoom", {
        key:
          _vm.roomId +
          "-" +
          _vm.userId +
          "-" +
          _vm.constraints.audio +
          "-" +
          _vm.constraints.video +
          "-" +
          _vm.constraints.type +
          "-" +
          _vm.hasVideoTrackOverride,
        attrs: {
          roomId: _vm.roomId,
          userId: _vm.userId,
          audio: _vm.constraints.audio,
          video: _vm.constraints.video,
          videoTrack: _vm.videoTrackOverride,
          constraints: _vm.constraints,
        },
        on: {
          onUsers: _vm.onUsers,
          onDominantSpeakerIdChanged: _vm.onDominantSpeakerId,
          onError: _vm.onError,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }