import type Vue from "vue"
import type { ThisTypedComponentOptionsWithRecordProps } from "vue/types/options"

const ValidateableMixin: ThisTypedComponentOptionsWithRecordProps<
  Vue,
  {},
  {},
  {},
  { rules: Record<string, any> }
> = {
  props: {
    rules: {
      type: Object
    }
  }
}

export default ValidateableMixin
