import instance from "../axios.service"

class Game {
  static start(roomId, resourceId) {
    return instance({
      method: "post",
      url: `/game/${roomId}/stream`,
      data: {
        resourceID: resourceId
      }
    })
  }
  static stop(roomId) {
    return instance({
      method: "delete",
      url: `/game/${roomId}/stream`
    })
  }
  static getToken(roomId) {
    return instance({
      method: "get",
      url: `/game/${roomId}/stream`
    })
  }
}

export default Game
