







import User from "@shared/User"

import WeveApp from "./WeveApp.vue"
import WeveLoading from "@/components/WeveLoading.vue"

const BROADCAST_MESSAGE = "new-instance"

export default {
  name: "App",
  components: {
    WeveApp,
    WeveLoading
  },
  data() {
    return {
      isAppVisible: true
    }
  },
  async created() {
    if (process.env.VUE_APP_ALLOW_MULTIPLE_INSTANCES === "true") return

    const channel = new BroadcastChannel("weve")
    channel.postMessage(BROADCAST_MESSAGE)
    const onChannelMessage = msg => {
      if (User.isHost(this.$store.state.auth.user)) return

      if (msg.data === BROADCAST_MESSAGE) {
        channel.removeEventListener("message", onChannelMessage)
        this.$info(
          "You are not allowed to use more than one app instance.",
          "System",
          true,
          () => {
            this.isAppVisible = false
            this.$nextTick(() => this.$destroy()) // destroy itself
          }
        )
      }
    }
    channel.addEventListener("message", onChannelMessage)
  }
}
