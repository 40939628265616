import type { Theme } from "../types"

import { ThemeScope } from "./constants"

const state = {
  scope: ThemeScope.CLIENT,
  theme: null as Theme | null
}

export default state

export type State = typeof state
