import type { PluginObject } from "vue"
import type { Store } from "vuex"

const mapping = {
  client: () => import("@/services/client"),
  game: () => import("@/services/game"),
  user: () => import("@/services/user"),
  theme: () => import("@/modules/theme/services"),
  audio: () => import("@/modules/audio/services"),
  export: () => import("@/services/export.service")
}

type Mapping = typeof mapping
type Services = {
  [Property in keyof Mapping]: InstanceType<
    Awaited<ReturnType<Mapping[Property]>>["default"]
  >
}

export class ServiceManager {
  private readonly cache: Partial<Services> = {}

  async get<K extends keyof Services>(name: K): Promise<Services[K]> {
    const service = this.cache[name] as Services[K]
    if (service !== undefined) {
      return service
    }
    const { default: Service } = await mapping[name]()
    const instance = new Service() as Services[K]
    this.cache[name] = instance
    return instance
  }
}

export const ServicePlugin: PluginObject<{ store: Store<unknown> }> = {
  install(Vue, options) {
    const manager = new ServiceManager()
    Vue.prototype.$services = manager
    options.store.$services = manager
  }
}
