var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rtb-game-team",
      class: {
        "rtb-game-team--current": _vm.top,
        "rtb-game-team--huddle": _vm.isTopHuddle,
        "rtb-game-team--over": _vm.isTeamResults,
        "rtb-game-team--compressed": _vm.isHost,
        "rtb-game-team--crowded": _vm.hasManyPlayers,
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: { width: "100%" },
          style: _vm.countdown ? "padding-bottom: 32px" : null,
        },
        [
          _vm.location
            ? _c("TeamScrollerTeamLabel", {
                staticClass: "rtb-game-team__location-label",
                attrs: { value: _vm.location },
              })
            : _vm._e(),
          _c(
            "transition-group",
            {
              staticClass: "rtb-game-team__row",
              attrs: { tag: "div", name: "game-row-transition" },
            },
            _vm._l(_vm.playersWithStatus, function (player) {
              return _c(
                "div",
                {
                  key: "game-player-" + player.id + "-" + player.teamID,
                  staticClass: "rtb-game-team__row-item",
                  class: { "rtb-game-team__row-item--wide": player.conference },
                },
                [
                  player
                    ? _c("GamePlayerCard", {
                        attrs: {
                          rounded:
                            !_vm.isTopHuddle &&
                            !_vm.isTeamResults &&
                            !player.conference,
                          user: player,
                          teamPosition: _vm.teamPosition,
                        },
                        on: {
                          click: function ($event) {
                            if (!$event.shiftKey) {
                              return null
                            }
                            return _vm.onShiftClick(player)
                          },
                          dblclick: function ($event) {
                            return _vm.selectTheUser(player)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _vm.countdown
            ? _c(
                "div",
                { staticClass: "rtb-game-team__timer" },
                [_c("PillCountdown", { attrs: { endTime: _vm.endTime } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }