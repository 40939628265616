const { nanoid } = require("nanoid")
const getRandomInt = require("./helpers/getRandomInt")
const databases = require("./databases")
const Collection = require("./Collection")

class Session extends Collection {
  constructor() {
    const database = databases[getRandomInt(0, databases.length - 1)]
    const id = database
      ? `${nanoid(15)}${database}`
      : nanoid(Session.IDENTIFIER_LENGTH)
    return {
      id,
      database
    }
  }
  static getKey(id) {
    const string = String(id).substr(15, Session.IDENTIFIER_LENGTH)
    return string.length > 4 && databases.includes(string) ? string : undefined
  }
  static getUrl(id) {
    return `${process.env.BASE_URL}/login/${id}?auth=0`
  }
  static isMobileHybrid(session) {
    return session && session.mobileHybrid
  }
}

Session.IDENTIFIER_LENGTH = 20

module.exports = Session
