var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "fact-match-result", attrs: { "d-flex": "" } },
    [
      _c(
        "transition-group",
        {
          staticClass: "flex d-flex justify-center",
          attrs: { name: "fact-match-result-transition", tag: "div" },
        },
        _vm._l(_vm.factData, function (obj) {
          return _c(
            "v-flex",
            {
              key: "fact-match-player-" + obj.userID,
              staticClass: "fact-match-col-wrap",
              attrs: { "d-flex": "" },
            },
            [
              _c(
                "v-layout",
                { staticClass: "fact-match-col", attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c("FactMatchPlayer", {
                        attrs: {
                          userInfo: _vm.getUserInfo(obj.userID),
                          imageURL: _vm.getUserImage(obj.userID),
                          teamIcon: _vm.getTeamIcon(
                            _vm.getTeamIdByUserId(obj.userID)
                          ),
                          userID: obj.userID,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", disabled: obj.correct.image },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-flex",
                                  _vm._g(
                                    {
                                      staticClass: "fact",
                                      class: {
                                        correct: _vm.isCorrectFactMatch(obj),
                                        fact__image: !!obj.correct.image,
                                      },
                                      style: {
                                        backgroundImage: obj.correct.image
                                          ? "url(" + obj.correct.image + ")"
                                          : null,
                                      },
                                      attrs: {
                                        shrink: "",
                                        "d-flex": "",
                                        "justify-center": "",
                                        "align-center": "",
                                      },
                                      on: {
                                        click: [
                                          function ($event) {
                                            if (
                                              $event.ctrlKey ||
                                              $event.shiftKey ||
                                              $event.altKey ||
                                              $event.metaKey
                                            ) {
                                              return null
                                            }
                                            return _vm.changeScore(obj, "up")
                                          },
                                          function ($event) {
                                            if (!$event.shiftKey) {
                                              return null
                                            }
                                            return _vm.changeScore(obj, "down")
                                          },
                                        ],
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    !obj.correct.image
                                      ? [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("truncate")(
                                                  obj.correct,
                                                  100
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.isCorrectFactMatch(obj)
                                                  ? "YES"
                                                  : "NO"
                                              ) +
                                              " "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("div", { staticStyle: { width: "300px" } }, [
                        _vm._v(" " + _vm._s(obj.correct) + " "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }