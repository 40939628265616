var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    {
      staticClass: "user-media-image",
      class: _vm.props.imageUrl ? null : "user-media-image--default",
      style: _vm.props.imageUrl
        ? "background-image: url(" + _vm.props.imageUrl + ")"
        : null,
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }