import { db } from "@/firebase"
import * as moment from "moment"
import TeamClass from "@shared/Team"
import { Team } from "@/helpers"
import Mode from "@shared/enums/Mode"
import Mission from "@shared/enums/Mission"
import MissionCollection from "@shared/MissionCollection"

const NO_ORIGIN_GAME_ERROR_MESSAGE = "There is no original game to copy from"

function getFirstMode(mission = {}) {
  return Object.values(Mode).find(mode => mission[mode]) || Mode.Default
}

const GamesModule = {
  namespaced: true,
  state: {
    gameTypes: [
      "Standard",
      "YouTube",
      "Twitch",
      "Flash Cards",
      "Green Room",
      "Image",
      "Our Video",
      "Scoreboard"
    ]
  },
  getters: {
    gameTypes(state) {
      return state.gameTypes
    },
    isMeetingMediaAvailable(_, __, ___, rootGetters) {
      const currentMission = rootGetters?.getCurrentMission
      const currentMode = rootGetters?.getCurrentMode

      const isSharingScreenOn = Boolean(
        rootGetters["twilio/screenshareVideoTrack"]?.mediaStreamTrack ||
          rootGetters["ScreenCapture/isSharingScreen"]
      )

      const isSlides = currentMission?.behavior === Mission.Slides
      const isTheater = currentMode === Mode.Theater
      const isMeeting = currentMode === Mode.Meeting

      return Boolean((isSlides && !isMeeting) || isTheater || isSharingScreenOn)
    },
    screenshareByUser(_, __, ___, rootGetters) {
      const userId = rootGetters.gameStatus?.shareRef?.screenShareBy
      return rootGetters["GameUsers/usersOnline"].find(
        user => user?.id === userId
      )
    },
    meetingPresenter(_, getters, __, rootGetters) {
      const onlineUsersArray = rootGetters?.onlineUsersArray || []
      const getMeetingModeActiveUser = rootGetters?.getMeetingModeActiveUser
      const getSelectedMeetingUsers = rootGetters?.getSelectedMeetingUsers ?? {}

      const activePresenter = getMeetingModeActiveUser
        ? onlineUsersArray.find(user => user.id === getMeetingModeActiveUser)
        : null
      const selectedUsers = onlineUsersArray.filter(
        user => getSelectedMeetingUsers[user.id]
      )

      if (activePresenter) {
        return activePresenter
      }

      return selectedUsers.length === 1
        ? selectedUsers[0]
        : getters?.screenshareByUser
    },
    disabledHandraise(_, getters, __, rootGetters) {
      const user = rootGetters["auth/user"]
      const meetingPresenter = getters?.meetingPresenter
      const isMeetingMediaAvailable = getters?.isMeetingMediaAvailable ?? false
      const isUserPresenting = Boolean(
        meetingPresenter?.id && user.id === meetingPresenter?.id
      )
      return Boolean(isMeetingMediaAvailable && isUserPresenting)
    }
  },
  actions: {
    async updateGameCapacity({ rootState }, { capacity, gameID }) {
      const { orgID } = rootState
      await db
        .auxiliary()
        .ref(`org/${orgID}/games/${gameID}/players`)
        .set(capacity)
    },
    async updateGame({ rootState }, payload) {
      const { theKey: gameID } = payload
      const { orgID } = rootState

      if (!orgID) throw new Error("Invalid input org ID")
      if (!gameID) throw new Error("Invalid input game ID")

      const game = { ...payload }
      // delete game.theKey

      const serialize = obj => JSON.parse(JSON.stringify(obj))

      await db
        .auxiliary()
        .ref(`org/${orgID}/games/${gameID}`)
        .set(serialize(game))

      const updatedGame = await this.$services
        .get("game")
        .then(service => service.getGameByID(orgID, gameID))

      return updatedGame
    },
    async updateGameAny({ rootState }, { theKey: id, ...update }) {
      await db
        .auxiliary()
        .ref("org/" + rootState.orgID + "/games/" + id)
        .update(update)
    },
    async addGame({ rootState }, payload) {
      var thePayload = JSON.parse(JSON.stringify(payload))
      thePayload.user = rootState.auth.user

      const ref = await db
        .auxiliary()
        .ref("org/" + rootState.orgID + "/games")
        .push(thePayload)

      const clientObj = {}
      clientObj.gameID = ref.key
      clientObj.games = {}
      clientObj.games[ref.key] = true
      clientObj.orgID = rootState.orgID
      clientObj.user = rootState.auth.user
      clientObj.name = payload.name

      const client = await db.auxiliary().ref("clients").push(clientObj)

      await db
        .auxiliary()
        .ref(`org/${rootState.orgID}/games/${ref.key}`)
        .update({ clientID: client.key })

      await db
        .auxiliary()
        .ref(`org/${rootState.orgID}/game/${ref.key}`)
        .update({
          clientID: client.key,
          gameStatus: {
            active: true,
            current_mission: "-LtzoCESdSn15NVpjVdY",
            endTime: 1574110360,
            media: "facilitator",
            startTime: 1574110360,
            teamPoints: true,
            twoteam: true,
            ...thePayload
          },
          teams: {
            "-LhCzJQ9Dmjy6oqg1234": {
              action: "local_activity",
              active: true,
              color: "#6d9c9f",
              icon: "favorite",
              id: "-LhCzJQ9Dmjy6oqg1234",
              muted: true,
              name: "Team 1",
              players: 0,
              rank: 1,
              show: true,
              slogan: "Manual",
              totalScore: 0,
              groupID: "LhCzJQ9Dmjy6oqg1234"
            },
            "-LhCzJQ9Dmjy6oqg3452": {
              action: "local_activity",
              active: true,
              id: "-LhCzJQ9Dmjy6oqg3452",
              muted: true,
              name: "Team 2",
              players: 0,
              rank: 1,
              color: "#6d9ccc",
              icon: "brightness_1",
              show: true,
              slogan: "Manual",
              totalScore: 0,
              groupID: "LhCzJQ9Dmjy6oqg1234"
            },
            "-LhCzJQ9Dmjy6oqgfYgN": {
              action: "local_activity",
              active: true,
              id: "-LhCzJQ9Dmjy6oqgfYgN",
              muted: true,
              name: "Team 3",
              players: 0,
              rank: 1,
              color: "#ff9c9f",
              icon: "grade",
              show: true,
              slogan: "Manual",
              totalScore: 0,
              groupID: "LhCzJQ9Dmjy6oqg1234"
            }
          }
        })

      const defaultMissionPayload = {
        "-LtzoCESdSn15NVpjVdY": {
          answer: "",
          behavior: "Team Name",
          explain: false,
          gameID: "-LtznczxIYK5FdJXtBPA",
          huddle: false,
          id: "-LtzoCESdSn15NVpjVdY",
          instructions: "Introduce yourself to your teammates, and choos...",
          multiCorrect: 1,
          name: "Welcome",
          numOfPlayers: "",
          numOfTries: "Unlimited",
          photo: "",
          play: true,
          points: 0,
          pos: 1,
          results: false,
          social: false,
          time: 0,
          title: "Welcome",
          video: "",
          voting: false,
          welcome: true
        }
      }

      await db
        .auxiliary()
        .ref(`org/${rootState.orgID}/game/${ref.key}/missions`)
        .update(defaultMissionPayload)

      const updates = {
        "groups/LhCzJQ9Dmjy6oqg1234/teams": {
          "-LhCzJQ9Dmjy6oqg1234": 1,
          "-LhCzJQ9Dmjy6oqg3452": 1,
          "-LhCzJQ9Dmjy6oqgfYgN": 1
        }
      }

      await db.ref(`org/${rootState.orgID}/game/${ref.key}`).update(updates)
    },
    async copyGame({ rootState }, { game, orgID, startIn, srcSessionID }) {
      const oldGameID = game.theKey || game.id

      if (!oldGameID) throw new Error("No game ID is given")

      const srcOrgID = rootState.orgID
      const destOrgID = orgID || rootState.orgID

      const srcDB = srcSessionID ? db.auxiliary(srcSessionID) : db

      const srcGameRef = srcDB.ref(`org/${srcOrgID}/game`)
      const destGamesRef = db.auxiliary().ref(`org/${destOrgID}/games`)
      const destGameRef = db.auxiliary().ref(`org/${destOrgID}/game`)

      // create new doc in /games
      const newGamesRef = await destGamesRef.push()

      // get new /games doc ID
      const newGamesID = newGamesRef.key
      let clientID = null

      if (!game.sameClientID) {
        // by default crate a new client doc
        const client = {}
        client.gameID = newGamesID
        client.dateAdded = new Date()
        client.orgID = destOrgID
        client.user = rootState.auth.user
        client.name = game.name
        const clientSnapshot = await db.auxiliary().ref("clients").push(client)

        clientID = clientSnapshot.key
      } else {
        clientID = game.clientID
      }

      if (!clientID) throw new Error("Something went wrong")

      const newGame = { ...game }

      delete newGame.sameClientID
      delete newGame.tipsDisabled

      await destGamesRef.child(newGamesID).update({
        ...newGame,
        clientID,
        id: newGamesID,
        theKey: newGamesID,
        endTimestamp: null,
        endedBy: null,
        startTimestamp: startIn || newGame.startTimestamp,
        deletedTimestamp: null,
        locked: false,
        started: false,
        showTipJar: false,
        isHostHidden: false,
        originalGameID: newGame.originalGameID || oldGameID,
        note: null,
        autopilot: null,
        templateID:
          newGame.templateID || newGame.contentToolID != null
            ? newGame.id
            : null
      })

      const oldGameSnapshot = await srcGameRef.child(oldGameID).once("value")
      // get val
      const oldGameVal = oldGameSnapshot.val()
      // update/create a new doc in /game with an ID we got from the new /games doc
      const firstMission = MissionCollection.normalize(oldGameVal.missions)[0]
      const firstMode = getFirstMode(firstMission)
      const { gameStatus } = oldGameVal
      await destGameRef.child(newGamesID).update({
        ...oldGameVal,
        clientID,
        id: newGamesID,
        theKey: newGamesID,
        play: null,
        giphies: null,
        teams: null,
        buzz: null,
        votes: null,
        autoTeamRequestQueue: null,
        gameStatus: {
          ...gameStatus,
          current_mission: firstMission,
          currentMode: firstMode,
          initialized: null,
          audioOptionLock: null
        }
      })

      const teams = TeamClass.normalize(oldGameVal.teams)

      const numOfTeams = teams.length || 3

      for (var i = 1; i <= numOfTeams; i++) {
        const obj = Team.getNewTeam(i)
        const promise = db
          .auxiliary()
          .ref(`org/${destOrgID}/game/${newGamesID}/teams`)
          .push()

        const teamID = promise.key
        await db
          .auxiliary()
          .ref(`org/${destOrgID}/game/${newGamesID}/teams/${teamID}`)
          .set({ ...obj, id: teamID })
      }
      return newGamesID
    },
    async copyFromOriginalGame(
      _,
      {
        originalGameID,
        orgID,
        clientID,
        startIn,
        startAt,
        hostID,
        tournamentID,
        teams,
        teamsPerGame,
        runStatus,
        gameType,
        name,
        assignable,
        round,
        deactivate,
        ondeck,
        expectedEndTime
      }
    ) {
      // Get refs
      const firebaseDB = db.auxiliary()
      const gameRef = firebaseDB.ref(`org/${orgID}/game`)
      const gamesRef = firebaseDB.ref(`org/${orgID}/games`)
      // Get snapshots of Original Game and Games objects
      let [originalGameSnap, originalGamesSnap] = await Promise.all([
        gameRef.child(originalGameID).once("value"),
        gamesRef.child(originalGameID).once("value")
      ])
      // Get value of original objects
      const originalGame = originalGameSnap.val()
      const originalGames = originalGamesSnap.val()

      console.log("originalGame", originalGame)

      if (!originalGame) throw new Error(NO_ORIGIN_GAME_ERROR_MESSAGE)
      if (!originalGames) throw new Error(NO_ORIGIN_GAME_ERROR_MESSAGE)

      //  Get Mission with pos == 1
      const firstMission = MissionCollection.normalize(originalGame.missions)[0]
      // Copy /games and get key of new object
      const newGames = {
        ...originalGames,
        originalGameID,
        round: !isNaN(parseInt(round)) ? round : null,
        assignable: assignable || null,
        externalName: name
          ? name
          : originalGames.externalName || originalGames.name,
        clientID: clientID,
        endTimestamp: 0,
        endedBy: null,
        startTimestamp: startIn
          ? moment().add(startIn, "minutes").toDate().getTime()
          : startAt,
        deletedTimestamp: null,
        deactivate: !!deactivate,
        ondeck: !!ondeck,
        locked: false,
        started: false,
        runStatus: runStatus || originalGames.runStatus || null,
        gameType: gameType || originalGames.gameType || null,
        hostUserID: hostID || originalGames.hostUserID || null,
        tournamentID: tournamentID || null,
        expectedEndTime:
          expectedEndTime || originalGames.expectedEndTime || null,
        showTipJar: false,
        note: null,
        autopilot: null,
        templateID:
          originalGames.templateID || originalGames.contentToolID != null
            ? originalGames.id
            : null
      }
      const newGamesSnap = gamesRef.push(newGames)
      const newGameID = newGamesSnap.key
      // Save new /games object
      await gamesRef
        .child(newGameID)
        .update({ theKey: newGameID, id: newGameID })
      if (!teams && teamsPerGame) {
        const ref = Team.getTeamsRef(firebaseDB, { orgID, gameID: newGameID })
        teams = Team.getNewTeams(ref, teamsPerGame)
      } else if (!teams) {
        const ref = Team.getTeamsRef(firebaseDB, { orgID, gameID: newGameID })
        let i = 1
        teams = Object.entries(originalGame.teams).reduce((acc, [key, val]) => {
          if (["undefined", "0"].includes(key)) return acc
          if (!val.show) {
            acc[key] = val
            return acc
          }
          val.totalScore = 0
          val.otherTeamFacts = []
          val.flippedState = "hidden"
          val.name = `Team ${i}`
          i++
          acc[ref.push().key] = val
          return acc
        }, {})
      }

      // Copy /game object
      const newGame = {
        ...originalGame,
        clientID: clientID,
        play: null,
        giphies: null,
        buzz: null,
        votes: null,
        TEMP: null,
        autoTeamRequestQueue: null,
        gameStatus: {
          ...originalGame.gameStatus,
          version: null,
          initialized: null,
          whiteElephantUserID: null,
          current_mission: firstMission,
          active: true,
          startTime: null,
          endTime: null,
          audioOptionLock: null,
          currentMode: getFirstMode(firstMission)
        },
        teams: teams
      }
      // Create new /game object (with same key as /games)
      await gameRef.child(newGameID).update(newGame)

      return newGameID
    },
    async removeGame({ rootState }, payload) {
      const { theKey: gameID } = payload
      const promises = []

      if (gameID) {
        promises.push(
          db
            .auxiliary()
            .ref("org/" + rootState.orgID + "/games/" + gameID)
            .remove()
        )
        promises.push(
          db
            .auxiliary()
            .ref("org/" + rootState.orgID + "/game/" + gameID)
            .remove()
        )
      } else {
        console.error("No game ID")
      }

      await Promise.all(promises)
    },
    async fetchGames({ commit }, { orgID }) {
      const snapshot = await db
        .auxiliary()
        .ref("org/" + orgID + "/games")
        .once("value")

      const obj = {}
      snapshot.forEach(item => {
        obj[item.key] = item.val()
      })
      return obj
    }
  }
}

export default GamesModule
