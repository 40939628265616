import {
  ref,
  computed,
  watch,
  onBeforeUnmount,
  onMounted
} from "@vue/composition-api"
import { throttle, chain, debounce } from "lodash"
import { db } from "@/firebase"
import useIdentities from "./useIdentities"
import Collection from "@shared/Collection"
import UserService from "@/services/user.service"
import MissionType from "@shared/enums/Mission"
import { Buzz } from "@/types/buzz"
import useViewer from "./useViewer"
import useStore from "./useStore"

export default function useBuzzIn() {
  const { orgID, gameID, missionID } = useIdentities()
  const { viewer } = useViewer()
  const { store } = useStore()

  const mission = computed(() => store.getters.getCurrentMission)
  const session = computed(() => store.getters["auth/client"])

  const isStaticScribeAssignment = computed(
    () =>
      session.value.staticScribeAssignment ||
      mission.value.staticScribeAssignment
  )
  const max = computed(() =>
    Math.max(parseInt(store.getters.game?.maxScribesInSocial) || 100, 1)
  )

  const buzz = ref<Buzz[]>([])

  const seed = ref(1)

  const usersRef = computed(() =>
    db
      .auxiliary()
      .ref(
        `org/${orgID.value}/game/${gameID.value}/buzz/${missionID.value}/users`
      )
  )

  const seedRef = computed(() =>
    db
      .auxiliary()
      .ref(
        `org/${orgID.value}/game/${gameID.value}/buzz/${missionID.value}/seed`
      )
  )

  const isBuzzed = computed(() =>
    buzz.value.some(buzz => buzz.teamID === viewer.value.teamID)
  )

  const onUsersSnapshot = throttle(function (snapshot) {
    buzz.value = chain(Collection.normalize(snapshot.val() ?? {}))
      .sortBy("timestamp")
      .uniqBy("teamID")
      .take(max.value + 1)
      .value()
  }, 500)

  function onSeedSnapshot(snapshot) {
    seed.value = parseInt(snapshot.val()) || 1
  }

  function incrementSeed() {
    return seedRef.value.transaction(value => (value || 1) + 1)
  }

  const watchables = [
    { ref: usersRef, handler: onUsersSnapshot },
    { ref: seedRef, handler: onSeedSnapshot }
  ]

  onMounted(() => {
    watchables.forEach(watchable => {
      watch(
        watchable.ref,
        (newValue, oldValue) => {
          oldValue?.off("value", watchable.handler)
          newValue?.on("value", watchable.handler)
        },
        { immediate: true }
      )
    })
  })

  const isViewerBuzzing = computed<boolean>(() =>
    buzz.value.some(({ userID }) => userID === viewer.value.id)
  )

  watch(
    isViewerBuzzing,
    debounce(function (value) {
      if (isStaticScribeAssignment.value) return
      if (mission.value?.behavior !== MissionType.BuzzIn) return
      if (value && viewer.value.selected) return
      if (!value && !viewer.value.selected) return
      if (value && !viewer.value.selected) {
        return UserService.updateScribe(viewer.value, true)
      }
      if (!value && viewer.value.selected) {
        return UserService.updateScribe(viewer.value, false)
      }
    }, 500),
    { immediate: true }
  )

  onBeforeUnmount(() => {
    watchables.forEach(watchable =>
      watchable.ref.value?.off("value", watchable.handler)
    )
  })

  function getIndex(userID: string) {
    return buzz.value.findIndex(buzz => buzz.userID === userID) + 1
  }

  function swapBuzzUsers(user) {
    const item = buzz.value.find(item => item.teamID === user.teamID)
    if (item == null) {
      store.dispatch("addBuzz", {
        missionID: store.getters.currentMission,
        userID: user.id,
        teamID: user.teamID
      })
    } else {
      usersRef.value.child(`${item.id}/userID`).set(user.id)
    }
  }

  return {
    max,
    isBuzzed,
    getIndex,
    incrementSeed,
    swapBuzzUsers,
    buzz,
    seed
  }
}
