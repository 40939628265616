import type { Maybe } from "@/types"

export enum Mutable {
  ByHost,
  ByEveryone
}

export interface UserForAppUserCard {
  id: string
  teamID: string
  firstname?: Maybe<string>
  lastname?: Maybe<string>
  image?: Maybe<string>
  muted?: Maybe<boolean>
  selected?: Maybe<boolean>
  skip?: Maybe<boolean>
  connections?: Maybe<Record<string, string>>
  jeopardai?: Maybe<boolean>
}
