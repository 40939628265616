import instance from "../axios.service"

class Lobby {
  static start(sessionId, roomId) {
    return instance({
      method: "post",
      url: `/session/${sessionId}/stream`,
      data: {
        roomID: roomId
      }
    })
  }
  static stop(sessionId) {
    return instance({
      method: "delete",
      url: `/session/${sessionId}/stream`
    })
  }
  static getToken(sessionId) {
    return instance({
      method: "get",
      url: `/session/${sessionId}/stream`
    })
  }
}

export default Lobby
