<template>
  <div
    class="login-page"
    :style="
      customLoginColor ? `background-color: ${customLoginColor} !important` : ''
    "
  >
    <div class="login-page-inner">
      <div class="login-card-wrapper">
        <div class="login-card">
          <v-layout
            row
            class="login-card__head shrink"
            :class="{ 'remote-logo-container': step === 2 }"
          >
            <v-flex d-flex class="login-card__logo">
              <img :src="$theme.getImage('logo')" />
            </v-flex>

            <v-flex
              shrink
              class="login-card__logout ml-2"
              @click="skip"
              id="onboarding-skip-btn"
              v-show="skippable"
            >
              Skip
            </v-flex>

            <v-flex
              shrink
              class="login-card__logout ml-2"
              @click="back"
              v-if="step > 0"
            >
              {{ step === 1 ? "Logout" : "Back" }}
            </v-flex>
          </v-layout>

          <LoginStepper
            class="login-card__nav"
            :setStep="setStep"
            :steps="stepsList"
            :currentStep="step"
          />

          <PermissionsStep
            v-if="isPermissionsStep && !isIRLSession"
            :session="{ name: stepTitle }"
          />
          <div v-else-if="!isLoadingSession" class="login-card__body">
            <!-- rework all this crap -->
            <h2
              class="login-card__title"
              :class="{
                'login-card__title--small': step > 0 && step != 3
              }"
            >
              {{ stepTitle }}
            </h2>

            <LoginFormsWrapper
              v-if="step === 0"
              :id="id"
              :sessionId="sessionId"
              :session="session"
              :setStep="setStep"
              :setTitle="setStepTitle"
              @ready="isLogInInitialized = true"
            />
            <AudioVideoSetupWrapper
              v-else-if="step === 1 || step === 2"
              :step="step"
              :setTitle="setStepTitle"
              :setStep="setStep"
            />
            <LockedCard v-else-if="step === 3" />
          </div>
        </div>
        <div class="login-page__footer login-page__footer-desktop">
          <PoweredBy />
          <AuditorBadge v-if="isAuditor" />
        </div>
      </div>

      <LoginSlider :images="images" :customImages="customImages" />
    </div>
  </div>
</template>

<script>
import LoginStepper from "./LoginStepper"
import LoginFormsWrapper from "./LoginFormsWrapper"
import LoginSlider from "./LoginSlider"
import PoweredBy from "./PoweredBy"
import { Client } from "@/helpers"
import { DEFAULT_IMAGES } from "@/modules/theme/constants"
import { mapGetters } from "vuex"
import LoginMixin from "@/components/User/LoginAndOnboarding/mixins/LoginMixin"

// services
import { fetchClient } from "@/services/client.service"
import { Navigation } from "@/helpers"

export default {
  name: "LoginOnboardOverlay",
  components: {
    LoginStepper,
    LoginFormsWrapper,
    LoginSlider,
    PoweredBy,
    AudioVideoSetupWrapper: () =>
      import("@/components/User/LoginAndOnboarding/AudioVideoSetupWrapper"),
    LockedCard: () => import("@/components/User/LoginAndOnboarding/LockedCard"),
    AuditorBadge: () =>
      import("@/components/User/LoginAndOnboarding/AuditorBadge"),
    PermissionsStep: () =>
      import("@/components/User/LoginAndOnboarding/PermissionsStep")
  },
  mixins: [LoginMixin],
  props: {
    id: {
      type: String,
      required: false
    }
  },
  async created() {
    this.sessionId = Navigation.parseUrlID(this.id).clientID
    if (this.sessionId) {
      this.isLoadingSession = true
      try {
        this.session = await fetchClient(this.sessionId)
      } finally {
        this.isLoadingSession = false
      }
    }
  },
  data() {
    return {
      stepTitle: "",
      stepsList: ["Identity", "Video Test", "Audio Test"],
      sessionId: null,
      session: null,
      isLoadingSession: false,
      isLogInInitialized: false
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.step && parseInt(to.query.step) !== 3) {
      delete to.query.step
      return next(to)
    }
    next()
  },
  computed: {
    ...mapGetters("auth", ["isHost", "client", "isAudit", "isIRLSession"]),
    ...mapGetters("Mobile", ["mobile"]),
    // jeez
    isPermissionsStep() {
      return (
        this.$store.getters["auth/authorized"] &&
        this.isLogInInitialized &&
        !this.$store.state.twilio.isPermissionGranted &&
        (this.isUserOnboardingExpired || !this.isUserOnboarded)
      )
    },
    customLoginColor() {
      return this.client?.customLoginColor
    },
    images() {
      const images = Client.getNormalizedImages(
        this.$theme.getCustomImage("loginCarousel")
      )
      if (images.length) return images
      else return this.session ? DEFAULT_IMAGES.loginCarousel : []
    },
    customImages() {
      return this.client?.removeLoginImagesBackgrounds
    },
    isSessionOnboardingSkippable() {
      return Boolean(this.client?.skippableOnboarding)
    },
    skippable() {
      return (
        (this.$store.getters["auth/isUserOnboarded"] ||
          this.isHost ||
          this.isAuditor ||
          this.isSessionOnboardingSkippable ||
          process.env.VUE_APP_MODE === "development" ||
          process.env.VUE_APP_MODE === "staging" ||
          process.env.NODE_ENV === "development") &&
        this.step > 0 &&
        this.step < 3
      )
    },
    step() {
      return parseInt(this.$route.query.step) || 0
    },
    isAuditor() {
      return this.isAudit || this.$_LoginMixin_audit
    }
  },

  methods: {
    skip() {
      this.$store.commit("auth/UPDATE_LAST_KNOWN_ONBOARDING", true)
    },
    setStepTitle(newTitle) {
      if (newTitle) this.stepTitle = newTitle
    },
    setStep(val) {
      this.$router.push({ query: { step: parseInt(val) || 0 } })
    },
    back() {
      const step = this.step
      if (step === 1) {
        this.logout()
      } else if (step === 2) {
        this.setStepTitle("Video Feed check")
        this.$router.push({ query: { step: 1 } })
      } else if (step === 3) {
        this.setStepTitle("Microphone Check")
        this.$router.push({ query: { step: 2 } })
      }
    },
    logout() {
      this.$navigator.navigateTo("/logout")
    }
  }
}
</script>

<style lang="scss">
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  background-color: $marine-background-color;
  font-family: "Sofia Pro";

  &__footer {
    margin-top: 14px;
    display: flex;
    align-items: center;

    @media (max-width: 520px) {
      position: unset;
    }
  }

  .login-page-inner {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 1100px;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1366px) {
      max-width: 100%;
    }

    @media (max-width: 500px) {
      .login-page-inner {
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 1366px) {
  .login-page {
    &__footer-desktop {
      position: absolute;
      top: 20px;
      right: 20px;
      margin-top: 0;
    }
  }
}

.baner-wrapper {
  position: relative;
  width: 45vh;
  height: 45vh;
  max-width: 420px;
  max-height: 420px;

  @media (max-width: 1024px) {
    max-height: 300px;
    max-width: 300px;
  }

  .rectangle {
    width: 19vh;
    height: 25vh;
    max-width: 141px;
    max-height: 190px;
    position: absolute;
    top: -6vh;
    left: -5vh;
    z-index: 1;

    @media (max-width: 1024px) {
      max-height: 113px;
      max-width: 152px;
      left: -8vh;
    }

    @media (max-width: 850px) {
      display: none;
    }
  }

  .circle {
    width: 24vh;
    height: 24vh;
    max-width: 198px;
    max-height: 198px;
    position: absolute;
    bottom: -8vh;
    right: -6vh;
    z-index: 1;

    @media (max-width: 1024px) {
      max-height: 160px;
      max-width: 160px;
      right: -8vh;
    }

    @media (max-width: 850px) {
      display: none;
    }
  }

  .slider-wrap {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
  }

  .carousel-image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    object-fit: cover;
    border-radius: 3px;
    transition: 0.7s;
  }
}

.login-card-wrapper {
  width: 50%;
  max-width: 542px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;

  @media (max-width: 1366px) {
    margin-right: 0;
    max-width: 100%;
    height: 100%;
    padding: 0;
  }

  @media (max-width: 520px) {
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
  }
}

.login-card {
  width: 100%;
  flex: 1 1 auto;
  padding: 35px 68px 32px 68px;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 3vw;
  padding-right: 3vw;
  background-color: $white-card-background-color;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  &__logo {
    max-height: 9vh;
    height: 200px;
    position: relative;
    align-items: center;

    & > img {
      position: absolute;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
  }

  &__head {
    max-height: 9vh !important;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }

  .login-card__logout {
    display: block;
    width: 100px;
    padding: 12px 10px;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    color: #fff;
    border: 2px solid $primary_accent_color;
    color: $primary_accent_color;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s;
    opacity: 0.85;

    &:hover {
      opacity: 1;
    }
  }

  &__title {
    margin-top: min(max(10px, 2vh), 30px);
    color: $main-card-text;
    font-size: min(6vh, 58px);
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
    font-family: "Right Grotesk";
    letter-spacing: -0.5px;
    word-spacing: 4px;
    word-wrap: break-word;
    &--small {
      font-size: 48px;
    }
  }

  @media (max-width: 500px) {
    &__title {
      font-size: 22px;
    }

    &__body {
      margin-top: 30px;
      flex-grow: 0;
    }

    .login-page-inner {
      overflow: hidden;
    }
  }
}
</style>
