import type { Client } from "@/types/client"
import type { Game } from "@/types/game"
import type { Host } from "@/types/user"

export function isTipJarEnabled(
  client: Client,
  game: Game,
  host: Host | undefined
) {
  if (game?.tipsDisabled === true) return false
  if (client?.tipsDisabled === true) return false
  if (
    host === undefined ||
    host?.venmo === undefined ||
    host?.paypal === undefined
  )
    return false
  return true
}
