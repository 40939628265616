<template>
  <div class="team-manager">
    <div class="team-manager__content" style="padding-right: 60px">
      <div class="team-manager__main">
        <div
          class="team-manager__left-col"
          :style="{
            flexBasis: `${100 - teamsWidth}%`
          }"
        >
          <div
            class="team-manager__host-wrap"
            :class="{
              'team-manager__host-wrap--column':
                !nOfPlayersWithoutTeam && isAnyUserAssigned
            }"
          >
            <div class="host-media-wrap">
              <RatioContainer>
                <HostMedia hideBadge />
              </RatioContainer>
            </div>
            <div class="team-manager__details">
              <div class="team-manager__details-img">
                <img
                  v-if="componentLogo"
                  :src="componentLogo"
                  :style="logoStyle"
                  class="team-manager__game-cover"
                />
              </div>
            </div>
          </div>

          <div class="team-manager__info">
            <div class="team-manager__info-scroll">
              <template v-if="nOfPlayersWithoutTeam || !isAnyUserAssigned">
                <p class="team-manager__text">
                  {{ infoText }}
                </p>
                <p v-if="!!startTime && isStandard" class="team-manager__text">
                  <span v-if="time > 0"> Starts at </span>
                  <span v-else> Started at </span>
                  <span class="actual-start-time">
                    {{ startTime }}
                  </span>
                </p>

                <h2 class="team-manager__headline mt-4 mb-3">
                  {{ numOfPlayersText }}
                </h2>
              </template>

              <div class="team-manager__unassiged-users-wrap">
                <RecycleScroller
                  class="team-manager__team-list"
                  :items="unassignedUsersRows"
                  :item-size="unassignedUserSize"
                  key-field="id"
                  v-slot="{ item }"
                  ref="unassigned-users-container"
                >
                  <ResizeObserver @notify="handleUnassignedUsersResize" />
                  <draggable
                    class="team-manager__unassigned-users-row"
                    handle=".handle"
                    :list="item.items"
                    v-bind="draggableContext"
                    :move="checkMove"
                    @start="onStartDragging(item)"
                    @end="onStopDragging"
                    @change="changeTeam($event, item.id)"
                  >
                    <template v-if="!item.placeholder">
                      <div
                        class="pick-player"
                        :key="`no-team-player-${i}-${player.id}`"
                        :data-uuid="player.id"
                        v-for="(player, i) in item.items"
                        :class="{
                          handle: canViewerDragAndDrop(player),
                          'pick-player--wide': isHighlightedUser(player)
                        }"
                      >
                        <PickTeamsUserCard :user="player" />
                      </div>
                    </template>
                  </draggable>
                </RecycleScroller>
              </div>

              <template v-if="isShuffleAvailable">
                <v-tooltip right v-if="!nOfPlayersWithoutTeam">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="secondary"
                      absolute
                      fab
                      :loading="randomizing"
                      class="team-manager__randomize"
                      @click="shufflePlayers"
                    >
                      <v-icon>shuffle</v-icon>
                    </v-btn>
                  </template>
                  <span>Shuffle players</span>
                </v-tooltip>
                <v-tooltip v-else right>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      color="primary"
                      absolute
                      fab
                      :loading="randomizing"
                      class="team-manager__randomize"
                      @click="randomizePlayers"
                    >
                      <v-icon>shuffle</v-icon>
                    </v-btn>
                  </template>
                  <span>Randomize players</span>
                </v-tooltip>
              </template>
            </div>
          </div>
        </div>

        <div
          class="team-manager__teams"
          :style="{
            flexBasis: `${teamsWidth}%`
          }"
        >
          <div class="mb-4">
            <v-layout class="mr-4" flex justify-space-between>
              <h2 class="team-manager__headline mt-0 mb-1">Teams</h2>
              <WeveButton
                v-if="isHost || isDIYGame"
                size="sm"
                @click="addOneTeam"
                variant="secondary"
              >
                <v-icon class="mr-2" color="white">add_circle</v-icon>
                ADD TEAM
              </WeveButton>
            </v-layout>
          </div>

          <div class="team-manager__scroller-wrap" v-if="nOfTeams > 0">
            <RecycleScroller
              class="team-manager__team-list"
              :items="allTeams"
              :item-size="204"
              key-field="id"
              v-slot="{ item }"
            >
              <div no-action class="team-manager__team-list-item handle-team">
                <div class="team-manager__title">
                  <h4 class="team-manager__team-header">
                    <v-icon class="mr-1 team-manager__team-icon">
                      {{ item.icon }}
                    </v-icon>
                    <span class="team-manager__team-name mr-1">
                      {{ item.name }}
                    </span>
                    <span class="mr-1" v-if="item.location">
                      -
                      <span class="team-manager__location-label">{{
                        item.location
                      }}</span>
                    </span>
                    <span class="mr-1" v-if="item.specialPurpose">
                      -
                      <span class="team-manager__special-purpose-label"
                        >Special Purpose</span
                      >
                    </span>
                    <v-icon
                      class="team-manager__team-edit-icon"
                      v-if="canViewerEditTeams"
                      @click="onEditTeam(item)"
                    >
                      settings
                    </v-icon>
                  </h4>
                </div>

                <div class="team-manager__row">
                  <div class="team-manager__fake-row">
                    <template v-if="!item.conference">
                      <div
                        class="team-manager__fake-player pick-player"
                        v-for="fakePlayer in 3"
                        :key="fakePlayer"
                      />
                    </template>
                  </div>
                  <draggable
                    class="players-wrapper"
                    handle=".handle"
                    :list="item.items"
                    :move="checkMove"
                    v-bind="draggableContext"
                    :disabled="isTeamDisabled(item)"
                    @start="onStartDragging(item)"
                    @end="onStopDragging"
                    @change="changeTeam($event, item.id)"
                  >
                    <div
                      class="pick-player"
                      :key="`team-player-${i}-${player.id}`"
                      :data-uuid="player.id"
                      v-for="(player, i) in item.items"
                      :class="{
                        handle: canViewerDragAndDrop(player),
                        'pick-player--wide': isHighlightedUser(player)
                      }"
                    >
                      <PickTeamsUserCard :user="player" />
                    </div>
                    <div
                      v-if="item.items.length < 2"
                      class="team-manager__fake-player pick-player"
                      style="opacity: 0"
                    />
                  </draggable>
                </div>
              </div>
            </RecycleScroller>
          </div>
          <v-flex v-else d-flex align-center justify-center>
            <h1 style="color: rgba(255, 255, 255, 0.3); text-align: center">
              NO TEAMS
            </h1>
          </v-flex>
        </div>
      </div>
    </div>
    <WeveDialog
      dialogClass="team-manger__team-dialog"
      title="Edit Team"
      v-model="isTeamDialogVisible"
    >
      <ValidationObserver v-slot="{ invalid }">
        <div class="px-4">
          <WeveTextFieldWithValidation
            placeholder="The bananas"
            label="Name"
            :maxlength="30"
            required
            characters-counter
            @keyup.enter="saveTeam()"
            v-model="team.name"
          />
        </div>
        <template v-if="isHost">
          <div class="px-4 mt-2">
            <WeveTextField v-model="teamScore" label="Score" />
          </div>
          <div class="px-4 mt-3">
            <WeveCheckbox
              v-model="team.specialPurpose"
              label="Special Purpose"
              class="mr-2"
            >
              <template #help>
                <rtb-inline-help> Special Purpose Team </rtb-inline-help>
              </template>
            </WeveCheckbox>
          </div>
        </template>
        <hr
          class="mt-4 mb-3"
          style="border-bottom: 1px; border-color: rgb(147, 147, 147)"
        />
        <div class="px-4">
          <p class="white--text">Selected Icon</p>
          <v-layout class="mb-4" flex flex-wrap>
            <div
              v-for="(item, index) in teamIcons"
              class="pa-2 team-manager__team-icon-option"
              :class="
                team && team.icon === item
                  ? 'team-manager__team-icon-option--selected'
                  : null
              "
              @click="selectIcon(item)"
              :key="index"
            >
              <v-icon color="white">
                {{ item }}
              </v-icon>
            </div>
          </v-layout>
        </div>
        <v-layout style="justify-content: flex-end" flex class="mt-2 mb-3 pr-3">
          <WeveButton
            class="mx-1"
            @click="isTeamDialogVisible = false"
            variant="tertiary"
            size="sm"
          >
            CANCEL
          </WeveButton>
          <WeveButton
            class="mx-1"
            @click="dismissTeam"
            variant="danger"
            size="sm"
          >
            DELETE TEAM
          </WeveButton>
          <WeveButton
            :disabled="invalid"
            class="mx-1"
            @click="saveTeam"
            variant="accent"
            size="sm"
          >
            SAVE
          </WeveButton>
        </v-layout>
      </ValidationObserver>
    </WeveDialog>
  </div>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import { toRefs } from "@vue/composition-api"
import { WeveDialog, WeveTextField, WeveButton } from "@weve/ui"
import { ValidationObserver } from "vee-validate"
import _ from "lodash"
import draggable from "vuedraggable"
import shuffle from "array-shuffle"
import { random } from "lodash"
import { RecycleScroller } from "vue-virtual-scroller"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
import { ResizeObserver } from "vue-resize"

import { WeveCheckbox } from "@weve/ui"
import { BreakoutType } from "@shared/enums"
import User from "@shared/User"
import Team from "@shared/Team"
import HostMedia from "@/components/GroupTeams/Common/HostMedia"
import PickTeamsUserCard from "@/components/GroupTeams/Common/Player/PickTeamsUserCard/PickTeamsUserCard.vue"
import { Role, Time } from "../helpers"
import UserService, { fetchUser } from "@/services/user.service"
import UserAudio from "@/components/GroupTeams/Common/User/UserAudio"
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import {
  RtbButton,
  RtbCard,
  RtbCardBody,
  RtbCardActions,
  RtbSelect
} from "@/components/ui"

import ModeratorMixin from "@/mixins/ModeratorMixin"
import WeveTextFieldWithValidation from "@/components/WeveTextFieldWithValidation"
import useTeams from "@/use/useTeams"

export default Vue.extend({
  name: "TeamManager",
  components: {
    ResizeObserver,
    RecycleScroller,
    RtbButton,
    RtbCard,
    RtbCardBody,
    RtbCardActions,
    RtbSelect,
    UserAudio,
    draggable,
    HostMedia,
    PickTeamsUserCard,
    RatioContainer,
    WeveDialog,
    WeveTextField,
    WeveButton,
    WeveTextFieldWithValidation,
    ValidationObserver,
    WeveCheckbox
  },
  mixins: [ModeratorMixin],
  props: {
    navigation: {
      type: Boolean,
      default: true
    },
    externalGameID: {
      type: [String, null],
      default: null
    },
    externalUsers: {
      type: Array,
      default: () => []
    }
  },

  setup(props) {
    const { externalGameID } = toRefs(props)
    const { teams } = useTeams(externalGameID.value)
    return {
      teams
    }
  },

  data() {
    return {
      team: {},
      draggingTeamID: null,
      time: null,
      startTime: null,
      assignedHost: null,
      randomizing: false,
      isTeamDialogVisible: false,
      minNumberOfUsersPerRow: 2,
      maxNumberOfUsersPerRow: 5,
      unassignedUserSize: 164,
      unassignedUsersContainerWidth: 0,
      draggableContext: {
        group: "people",
        animation: 150,
        ghostClass: "ghost-class"
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["client", "isHost"]),
    ...mapGetters([
      "orgID",
      "isDIYGame",
      "gameID",
      "teamIcons",
      "game",
      "urlID",
      "moderatorID"
    ]),
    teamScore: {
      get() {
        return this.team?.totalScore
      },
      set(value) {
        this.team = { ...this.team, totalScore: parseInt(value) || 0 }
      }
    },
    canViewerDragAndDrop() {
      return user =>
        this.isHost ||
        this.isDIYGame ||
        this.$store.state.auth.user?.id === user.id
    },
    logoStyle() {
      return this.game?.pickTeamLogoStyle || {}
    },
    numberOfUsersPerRow() {
      return Math.min(
        this.maxNumberOfUsersPerRow,
        Math.max(
          this.minNumberOfUsersPerRow,
          Math.floor(
            this.unassignedUsersContainerWidth / this.unassignedUserSize
          )
        )
      )
    },
    canViewerModerate() {
      return this.user && this.isModerated && !this.isHost
    },
    canViewerEditTeams() {
      return this.isHost || this.isDIYGame
    },
    onlineUsersArray() {
      return this.externalUsers && this.externalGameID
        ? this.externalUsers
        : this.$store.getters.onlineUsersArray
    },
    isHybridRoom() {
      return this.$store.getters["auth/isHybridRoom"]
    },
    isShuffleAvailable() {
      return (
        (this.isHost || this.isDIYGame) &&
        this.nOfPlayersOnline > 0 &&
        !this.isHybridRoom &&
        !this.isConferenceUsersOnTheList
      )
    },
    nOfTeams() {
      return this.allTeams?.length
    },
    gameStartTime() {
      return parseInt(_.get(this.game, "startTimestamp", 0))
    },
    actualHost() {
      const moderatorID = this.moderatorID
      return this.onlineUsersArray.find(
        ({ role, id }) => role === Role.Host || id === moderatorID
      )
    },
    host() {
      return this.actualHost || this.assignedHost
    },
    isStandard() {
      return !this.game.gameType || this.game.gameType == "Standard"
    },
    onlinePlayersArray() {
      const moderatorID = this.moderatorID
      return this.onlineUsersArray.filter(
        ({ role, id }) => role === Role.Player && id !== moderatorID
      )
    },
    isConferenceUsersOnTheList() {
      return this.onlinePlayersArray.some(this.isHighlightedUser)
    },
    nOfPlayersOnline() {
      return this.onlinePlayersArray.length
    },
    numOfPlayersText() {
      const nOfPlayersOnline = this.nOfPlayersOnline

      if (nOfPlayersOnline === 0) return "No players"

      if (nOfPlayersOnline === 1) return "1 player in this room"

      if (nOfPlayersOnline > 1)
        return nOfPlayersOnline + " players in this room"

      return ""
    },
    hasHybridLocations() {
      return this.$store.getters["group/hasHybridLocations"]
    },
    allTeams() {
      const playersGroupedByTeam = this.playersGroupedByTeam
      const hasHybridLocations = this.hasHybridLocations
      return _.chain(Team.normalize(this.teams ?? {}))
        .map(team => {
          const users = playersGroupedByTeam[team.id] || []
          return {
            icon: team.icon,
            name: team.name,
            id: team.id,
            items: users,
            specialPurpose: Boolean(team.specialPurpose),
            location:
              (hasHybridLocations &&
                users.find(user => user.identifier)?.identifier) ??
              "",
            conference: users.some(this.isHighlightedUser)
          }
        })
        .sortBy("name")
        .sortBy("location")
        .sortBy(team => !team.location)
        .sortBy(team => Team.isSpecialPurpose(team))
        .value()
    },
    nOfPlayersWithoutTeam() {
      return this.playersOffTeam.length
    },
    playersOffTeam() {
      return this.playersGroupedByTeam[0] || []
    },
    playersGroupedByTeam() {
      const teams = this.teams || {}
      return this.onlinePlayersArray.reduce((acc, val) => {
        const teamID = teams[val.teamID] ? val.teamID : 0
        if (acc[teamID]) {
          acc[teamID].push(val)
        } else {
          acc[teamID] = [val]
        }
        return acc
      }, {})
    },
    unassignedUsersRows() {
      if (this.playersOffTeam.length === 0)
        return [{ items: [], id: 0, placeholder: true }]

      return _.chunk(this.playersOffTeam, this.numberOfUsersPerRow).map(
        users => ({
          items: users,
          id: users.reduce((acc, val) => acc + val.id, "")
        })
      )
    },
    isAnyUserAssigned() {
      return !!this.getMaxPlayersInTeam
    },
    getMaxPlayersInTeam() {
      return _.max(this.allTeams.map(team => team.items.length))
    },
    teamsWidth() {
      if (
        (this.isAnyUserAssigned && !this.nOfPlayersWithoutTeam) ||
        this.getMaxPlayersInTeam >= 7
      ) {
        return 80
      }

      if (this.getMaxPlayersInTeam >= 5) {
        return (this.getMaxPlayersInTeam + 1) * 10
      }

      if (this.isAnyUserAssigned) {
        return 50
      }

      return 30
    },
    isSpeechToTextEnabled() {
      return this.game?.speechToText ?? false
    },
    maxPlayersPerTeam() {
      return this.game?.breakout?.size
    },
    isSelfSelect() {
      return this.game?.breakout?.type === BreakoutType.SELF_SELECT
    },
    componentLogo() {
      return this.client?.globalPickTeams || this.game?.pickTeamLogo
    },
    infoText() {
      return this.game?.pickTeamText
    },
    isModerated() {
      return this.game?.moderated
    }
  },
  async created() {
    try {
      if (this.game?.hostUserID) {
        this.assignedHost = await fetchUser({ userID: this.game.hostUserID })
      }
    } catch (e) {
      console.error(e)
    }

    if (this.gameStartTime) {
      const { time, startTime } = Time.getCountdownTimes(
        this.gameStartTime,
        Date.now()
      )
      this.time = time
      this.startTime = startTime
    }
  },
  mounted() {
    this.$nextTick(this.updateUnassignedUsersContinerWidth)
  },
  methods: {
    ...mapActions(["deleteTeam", "updateTeam", "createTeam"]),
    updateUnassignedUsersContinerWidth() {
      this.unassignedUsersContainerWidth =
        this.$refs["unassigned-users-container"]?.$el?.offsetWidth || 0
    },
    handleUnassignedUsersResize() {
      this.$nextTick(this.updateUnassignedUsersContinerWidth)
    },
    onStartDragging(item) {
      this.draggingTeamID = item?.id
    },
    onStopDragging() {
      this.draggingTeamID = null
    },
    isHighlightedUser(user) {
      return User.isConference(user)
    },
    returnToGame() {
      this.$navigator.navigateTo(`/game/${this.urlID}`)
    },
    async addOneTeam() {
      return this.createTeam({
        gameID: this.externalGameID,
        name: "Team " + (Object.values(this.teams).length + 1),
        icon: this.getRandomIcon(),
        show: true,
        active: true
      })
    },
    selectIcon(icon) {
      this.team = { ...this.team, icon }
    },
    async dismissTeam() {
      if (!this.team) return
      if (!this.team.id) return
      await this.deleteTeam({ id: this.team.id, gameID: this.externalGameID })
      this.isTeamDialogVisible = false
    },
    onEditTeam(team) {
      this.isTeamDialogVisible = true
      this.team = { ...this.teams[team.id] }
    },
    getRandomIcon() {
      if (this.teams) {
        const array = Object.values(this.teams)
        const pool = this.teamIcons.filter(
          string => !array.some(({ icon }) => icon === string)
        )
        return pool[random(0, pool.length - 1)] || this.teamIcons[0]
      } else {
        return this.teamIcons[random(0, this.teamIcons.length - 1)]
      }
    },
    async saveTeam() {
      if (this.team.id) {
        await this.updateTeam({ ...this.team, gameID: this.externalGameID })
      } else {
        await this.createTeam({
          gameID: this.externalGameID,
          name: this.team.name,
          icon: this.team.icon || this.getRandomIcon(),
          show: true,
          active: true
        })
      }
      this.team = {}
      this.isTeamDialogVisible = false
    },
    async changeTeam($event, teamID) {
      if ($event.added) {
        try {
          const user = $event.added.element
          await UserService.updateTeamId(user, teamID)
        } catch (e) {
          console.log(e.message)
        }
      }
    },
    playersOnTeam(teamID) {
      return this.playersGroupedByTeam[teamID] || []
    },
    checkMove(obj) {
      if (
        this.isHighlightedUser(obj.draggedContext.element) &&
        obj.relatedContext.component.list.length > 0 &&
        obj.relatedContext.component.$el.className !==
          "team-manager__unassigned-users-row"
      ) {
        return false
      }

      if (
        obj.relatedContext.component.$el.className !==
          "team-manager__unassigned-users-row" &&
        obj.relatedContext.component.list.some(this.isHighlightedUser)
      )
        return false

      return (
        this.isHost ||
        this.isDIYGame ||
        this.user.id === obj.dragged.dataset.uuid
      )
    },
    async randomizePlayers() {
      this.randomizing = true
      const teams = this.allTeams.map(team => ({
        id: team.id,
        count: team.items.length
      }))
      const users = []

      for (const user of this.playersOffTeam) {
        const team = _.minBy(teams, team => team.count)

        users.push({
          userID: user.id,
          collection: [{ key: "teamID", value: team.id }]
        })

        team.count++
      }

      await this.$store.dispatch("updateUsersInBulk", { array: users })

      this.randomizing = false
    },
    async shufflePlayers() {
      this.randomizing = true

      const teams = this.allTeams.map(team => team.id)
      const users = this.onlinePlayersArray
        .filter(({ teamID }) => teamID !== 0)
        .map(user => user.id)

      const shuffledUsersID = shuffle(users)

      const array = shuffledUsersID.map((userID, index) => ({
        userID,
        collection: [{ key: "teamID", value: teams[index % teams.length] }]
      }))

      await this.$store.dispatch("updateUsersInBulk", { array })

      this.randomizing = false
    },
    isTeamDisabled(team) {
      return (
        this.isSelfSelect &&
        this.draggingTeamID &&
        team.id !== this.draggingTeamID &&
        team.items.length === this.maxPlayersPerTeam
      )
    }
  }
})
</script>

<style lang="scss">
.team-manager__team-icon-option {
  border-radius: 3px;
  cursor: pointer;

  &--selected {
    background: #6e69d1;
  }
}

.team-manager {
  $host-size: 15vw;
  $left-col-offset: 33px;

  user-select: none;
  background-color: #17121b;
  color: #fff;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__special-purpose-label {
    color: $secondary_accent_color;
  }

  &__location-label {
    color: $primary_accent_color;
  }

  &__team-edit-icon {
    font-size: 1.5rem;
    margin: 0;
    margin-left: 0.5rem;
    color: inherit !important;
    cursor: pointer;
  }

  &__scroller-wrap {
    display: flex;
    flex: 1;
    position: relative;
  }

  &__team-icon {
    color: #fff !important;
  }

  &__team-dialog {
    font-family: "Sofia Pro", serif;
    width: 354px;

    .modal-header {
      border-bottom: none;
    }

    .modal-body {
      padding: 0;
    }
  }

  &__fake-player {
    padding: 2px;
    opacity: 0.1;

    &::before {
      border-radius: 14px;
      content: "";
      display: block;
      padding-top: 100%;
      background-color: #d8d8d8;
    }

    &:last-child::before {
      background: linear-gradient(270deg, #26292e -0.19%, #d8d8d8 97.39%),
        #d8d8d8;
    }
  }

  &__team-icon {
    color: #fff !important;
  }

  &__title {
    width: fit-content;
    background: #22222899;
    border-radius: 16px;
    padding: 4px 8px 4px 16px;
    margin-bottom: 5px;
  }

  .ghost-class {
    opacity: 0;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__headline {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.292;
  }

  &__team-name {
    white-space: nowrap;
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }

  &__team-header,
  &__text {
    font-size: 18px;
    line-height: 1.28;
    margin: 0;
  }

  &__team-header {
    display: flex;
    align-items: center;
    color: #f8f9fb;
  }

  &__randomize {
    right: 46px;
    bottom: 0px;
  }

  &__main {
    flex: 1;
    padding: {
      top: 24px;
      left: 40px;
      right: 0px;
    }
    max-height: 100%;
    display: flex;
  }

  &__left-col {
    border-radius: 32px;
    overflow: hidden;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    max-width: 70%;
    min-width: 430px;
  }

  &__host-wrap {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    padding: {
      left: $left-col-offset;
      right: $left-col-offset;
    }

    &--column {
      display: block;

      .team-manager__details {
        margin: 0;
        width: 100%;
        height: auto;
      }

      .team-manager__game-cover {
        position: static;
      }

      .host-media-wrap {
        max-width: 340% !important;
        width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }

  &__details {
    flex: 1 0 calc(60% - 80px);
    height: 100%;
    position: relative;
    margin-left: 34px;
    display: flex;
    align-items: center;
  }

  &__details-img {
    border-radius: 10px;
    overflow: hidden;
  }

  &__game-cover {
    width: 100%;
    height: 100%;
    max-height: 25vh;
    object-fit: contain;
  }

  &__info {
    position: relative;
    flex: 1;
    padding: {
      left: $left-col-offset;
      right: $left-col-offset;
    }
    display: flex;
    flex-direction: column;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 80px;
      pointer-events: none;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(#17121b, 0.9) 100%
      );
      border-radius: 0 0 32px 32px;
    }
  }

  &__info-scroll {
    overflow-y: auto;
    flex: 1 1 auto;
    height: 0px;
    padding-right: $left-col-offset - 8px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    @extend .team-manager-scroll;
  }

  &__teams {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    flex-grow: 2;
    flex-shrink: 0;
    flex-basis: 40%;
    max-width: calc(100% - 430px);

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 40px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(23, 18, 27, 0.9) 100%
      );
    }
  }

  &__unassiged-users-wrap {
    position: relative;
    display: flex;
    flex: 1;
  }

  &__team-list {
    position: absolute;
    height: 100%;
    width: 100%;

    @extend .team-manager-scroll;
  }

  &__team-list-item {
    overflow: hidden;
  }

  &__team-list-item:not(:first-child) {
    margin-top: 20px;
  }

  &__row {
    position: relative;
  }

  &__fake-row {
    position: absolute;
    left: -6px;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;

    .team-manager__fake-player {
      min-width: 150px;
      max-width: 150px;
      margin: 6px;
    }
  }

  .host-media {
    height: 100%;
    width: 100%;
  }

  .host-media-wrap {
    min-width: 150px;
    flex: 1 0 150px;
    max-width: 260px;
  }

  .pick-player.handle {
    cursor: move;
  }

  .team-icon {
    font-size: 1.5rem;
    margin-right: 0.1rem;
    color: currentColor;
    cursor: pointer;
  }

  .pick-player {
    min-width: 150px;
    max-width: 150px;
    margin: 6px;

    &--wide {
      min-width: calc(150px * 2);
      max-width: calc(150px * 2);
    }
  }

  &__unassigned-users-row {
    margin-left: -6px;
    margin-right: -6px;
    min-height: 150px;
    display: flex;
  }

  .players-wrapper {
    overflow: auto;
    position: relative;
    display: flex;
  }

  .players-drag-area {
    margin-left: 5px;
    margin-right: 5px;
    min-height: 90px;
  }
}

.team-manager-scroll {
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: transparentize(#181818, 0.6);
    background: linear-gradient(
        0deg,
        transparent 15%,
        rgba(41, 41, 50, 0.4) 25%,
        rgba(132, 132, 165, 0.4) 100%
      ),
      transparent;
    border-radius: 12px;
    opacity: 0.4;
  }

  &::-webkit-scrollbar-thumb {
    background: transparentize(#c6c6d7, 0.6);
    border-radius: 12px;
  }
}
</style>
