export interface Constraints {
  width: {
    min: number
    max: number
  }
  height: {
    min: number
    max: number
  }
  extensions: string[]
  size: { min: number; max: number }
}

export type ImageUploderErrorType =
  | "dimension"
  | "extension"
  | "size"
  | "unknown"

export class ImageUploderError extends Error {
  type: ImageUploderErrorType

  constructor(message: string, type: ImageUploderErrorType) {
    super(message)
    this.name = "ImageUploderError"
    this.type = type
  }
}
