var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "medal",
      class: ((_obj = {}), (_obj["medal--" + _vm.variant] = _vm.variant), _obj),
    },
    [
      _c("div", { staticClass: "medal__circle" }, [
        _vm.$slots.default
          ? _c("div", { staticClass: "medal__text" }, [_vm._t("default")], 2)
          : _vm._e(),
      ]),
      _c("div", { staticClass: "medal__ribbon" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }