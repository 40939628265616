















































import { computed, defineComponent, toRefs } from "@vue/composition-api"

import AppUserCard from "@/components/UserCard/AppUserCard.vue"
import RingStarsAnimation from "@/components/GroupTeams/Common/Player/Parts/RingStarsAnimation.vue"
import Medal from "@/components/GroupTeams/Common/Medal.vue"
import AccoutIcon from "@/components/UserCard/icons/AccoutIcon"
import UserCardButton from "@/components/UserCard/UserCardButton"
import Mode from "@shared/enums/Mode"
import MissionType from "@shared/enums/Mission"

import useUserScore from "@/use/useUserScore"
import useStore from "@/use/useStore"

export default defineComponent({
  name: "GamePlayerCard",
  components: {
    AccoutIcon,
    UserCardButton,
    RingStarsAnimation,
    Medal,
    UnconditionalPositiveRegardStage: () =>
      import(
        "@/components/GroupTeams/Common/Games/UnconditionalPositiveRegard/UnconditionalPositiveRegardStage.vue"
      ),
    AppUserCard
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    teamPosition: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { user } = toRefs(props)
    const { store } = useStore()
    const { score } = useUserScore(user)

    const isViewerModerator = computed(
      () => store.getters["auth/isModerator"] || false
    )
    const isHost = computed(() => store.getters["auth/isHost"])
    const game = computed(() => store.getters.game)

    const gameMode = computed(() => store.getters.getCurrentMode)
    const mission = computed(() => store.getters.getCurrentMission)
    const isSpiralDrawing = computed(
      () =>
        mission.value?.behavior === MissionType.SpiralDrawing &&
        gameMode.value === Mode.Play
    )

    const isSubmitted = computed(() => user.value.playStatus?.submitted)

    const viewer = computed(() => store.getters.user)
    const points = computed(() => {
      if (![Mode.Over, Mode.Huddle].includes(gameMode.value)) {
        return score.value
      }
    })

    const mutable = computed(() => {
      const { id, selected } = user.value
      const sameUser = id === viewer.value.id

      return !selected && !isHost.value && sameUser
    })

    const isUprPlayMode = computed(
      () =>
        gameMode.value === Mode.Play &&
        mission.value.behavior === MissionType.UnconditionalPositiveRegard
    )

    const mediaDisabled = computed(
      () => isUprPlayMode.value || user.value.covered
    )

    return {
      mission,
      isUprPlayMode,
      mediaDisabled,
      gameMode,
      viewer,
      points,
      isSubmitted,
      isHost,
      mutable,
      isViewerModerator,
      isSpiralDrawing
    }
  },
  computed: {
    isConference() {
      return this.user?.conference
    },
    isWinner() {
      if (this.mission?.dontShowCorrect === true) return false
      return this.user?.playStatus?.winner
    },
    isGameOver() {
      return this.gameMode === Mode.Over
    },
    isHuddle() {
      return this.gameMode === Mode.Huddle
    },
    positionSuffix() {
      switch (this.teamPosition) {
        case 1:
          return "ST"
        case 2:
          return "ND"
        case 3:
          return "RD"
        default:
          return "TH"
      }
    }
  }
})
