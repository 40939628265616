














import Vue from "vue"
import { KEY_LEFT, KEY_RIGHT, KEY_UP, KEY_DOWN } from "keycode-js"
import { mapGetters } from "vuex"

import SmartMedia from "./SmartMedia.vue"

export default Vue.extend({
  name: "SyncedMedia",
  model: {
    prop: "value",
    event: "change"
  },
  components: { SmartMedia },
  props: {
    value: Object,
    src: String,
    sync: Boolean,
    shortcuts: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ignoreUpdate: false,
      subscriptions: [],
      intervalRewind: null
    }
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    paused() {
      return this.value?.paused ?? false
    },
    startedAt() {
      return this.value?.startedAt ?? Date.now()
    },
    currentTime() {
      return this.value?.currentTime ?? 0
    },
    playbackRate() {
      return this.value.playbackRate
    },
    backwards() {
      return this.value.backwards
    }
  },
  created() {
    if (this.shortcuts) {
      window.addEventListener("keyup", this.onKeyUp)
      this.subscriptions.push(() =>
        window.removeEventListener("keyup", this.onKeyUp)
      )
    }
  },
  beforeDestroy() {
    this.subscriptions.forEach(f => f())
  },
  watch: {
    paused: {
      handler() {
        if (this.ignoreUpdate) {
          this.ignoreUpdate = false
          return
        }

        this.setCurrentTime()
      }
    },
    currentTime: {
      handler() {
        if (this.ignoreUpdate) {
          this.ignoreUpdate = false
          return
        }

        this.setCurrentTime()
      }
    },
    playbackRate: {
      handler(value) {
        const $el = this.$refs.media?.$el
        if (!$el) return

        $el.playbackRate = value
      },
      immediate: true
    },
    backwards: {
      handler(value) {
        if (value) {
          this.rewind()
        } else {
          clearInterval(this.intervalRewind)
        }
      }
    }
  },
  methods: {
    getCurrentTime(): number {
      return parseInt(this.$refs.media?.$el?.currentTime) || 0
    },
    setCurrentTime() {
      const $el = this.$refs.media?.$el
      if (!$el) return

      const offset = this.startedAt
        ? (Date.now() - this.startedAt) / 1000 || 0
        : 0
      const currentTime = this.currentTime + offset
      $el.currentTime = currentTime
    },
    change(payload) {
      if (!this.sync) return

      if (!this.paused && !payload.currentTime) {
        this.ignoreUpdate = true
      }

      this.$emit("change", {
        ...this.value,
        currentTime: this.getCurrentTime(),
        startedAt: Date.now(),
        ...payload
      })
    },
    onKeyUp(e) {
      if (
        ["text", "textarea", "search", "number"].includes(e?.target?.type) &&
        ![null, undefined, ""].includes(e?.target?.value)
      ) {
        return
      }
      if (!this.isHost) return

      if (e.shiftKey) {
        if (e.keyCode === KEY_RIGHT && e.altKey) {
          return this.playForward()
        }

        if (e.keyCode === KEY_LEFT && e.altKey) {
          return this.playBackwards()
        }
        if (e.keyCode === KEY_UP) {
          return this.doubleSpeed()
        }

        if (e.keyCode === KEY_DOWN) {
          return this.resetSpeed()
        }
      }

      if (e.keyCode === KEY_RIGHT) {
        return this.stepForward()
      }

      if (e.keyCode === KEY_LEFT) {
        return this.stepBack()
      }
    },
    stepForward() {
      this.change({ currentTime: this.getCurrentTime() + 1 })
    },
    stepBack() {
      this.change({ currentTime: this.getCurrentTime() - 1 })
    },
    playBackwards() {
      this.change({ backwards: true })
    },
    playForward() {
      this.change({ backwards: false, paused: false })
    },
    resetSpeed() {
      this.change({ playbackRate: 1 })
    },
    doubleSpeed() {
      this.change({ playbackRate: 2 })
    },
    play(event) {
      const { target } = event

      const update: Record<string, any> = {
        paused: false
      }

      if (this.backwards) {
        update.backwards = false
        update.paused = true

        target.pause()
      }

      this.change(update)
    },
    pause() {
      this.change({ paused: true })
    },
    rewind() {
      const $el = this.$refs.media?.$el
      if (!$el) return

      $el.pause()

      clearInterval(this.intervalRewind)
      let startSystemTime = Date.now()
      let startVideoTime = $el.currentTime

      this.intervalRewind = setInterval(() => {
        if ($el.currentTime == 0) {
          startSystemTime = Date.now()
          startVideoTime = $el.duration
          $el.currentTime = startVideoTime
        } else {
          const elapsed = Date.now() - startSystemTime

          $el.currentTime = Math.max(
            startVideoTime - (elapsed * this.playbackRate) / 1000.0,
            0
          )
        }
      }, 30)
    }
  }
})
