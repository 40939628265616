import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import HubSpot from "@/services/hubspot.service"
import User from "@shared/User"

import {
  NotificationMixin,
  NotificationType,
  NotificationScope
} from "@/mixins/NotificationMixin"

export default Vue.util.mergeOptions(NotificationMixin, {
  computed: {
    ...mapGetters(["gameHost", "user"]),
    ...mapGetters("auth", ["isModerator"])
  },
  methods: {
    ...mapActions(["setGameModeratorID"]),
    async $_ModeratorMixin_toggle() {
      try {
        let message = "Are you sure you would like to "
        if (this.isModerator) {
          message += "stop being a host?"
        } else {
          if (
            this.$store.getters.isUserAlive(this.gameHost) &&
            !User.isOnboarding(this.gameHost)
          ) {
            message += "remove the current host?"
          } else {
            message += "become the host?"
          }
        }

        await this.$confirm({ message })

        HubSpot.push([
          "trackCustomBehavioralEvent",
          {
            name: "pe2803895_app_weve_com___become_moderator"
          }
        ])

        this.setGameModeratorID(this.isModerator ? null : this.user.id)

        this.$_NotificationMixin_send({
          scope: NotificationScope.GLOBAL,
          type: this.isModerator
            ? NotificationType.BECOME_HOST
            : NotificationType.LEAVE_HOST
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
})
