import Vue from "vue"
import Vuetify, { VLayout } from "vuetify/lib"
import "vuetify/src/stylus/app.styl"
import "material-design-icons-iconfont/dist/material-design-icons.css"
import "@mdi/font/css/materialdesignicons.css"
import "@fortawesome/fontawesome-free/css/all.css"

import { DEFAULT_STYLES } from "../modules/theme/constants"

Vue.use(Vuetify, {
  iconfont: ["md", "mdi", "fa"],
  components: {
    VLayout
  },
  theme: {
    primary: DEFAULT_STYLES["--primary-accent-color"]
  }
})
