
import Vue, { PropType, VNode } from "vue"
import mixins from "vue-typed-mixins"
import { ValidationProvider } from "vee-validate"
import Clipboard from "clipboard"

import { IdentifiableMixin, InputMixin, ValidateableMixin } from "../mixins"

import FormControlLabel from "../FormControlLabel/FormControlLabel.vue"
import FormControlError from "../FormControlError/FormControlError.vue"
import RtbInputButton from "../InputButton/InputButton.vue"

import SvgIcon from "@/components/base/SvgIcon.vue"

export type Refs = {
  copy: HTMLButtonElement
}

export const SIZES = ["default", "sm"] as const

export default mixins(
  Vue.extend(IdentifiableMixin),
  Vue.extend(InputMixin),
  Vue.extend(ValidateableMixin)
).extend({
  name: "RTBTextInput",
  inheritAttrs: false,
  components: {
    ValidationProvider,
    FormControlLabel,
    FormControlError,
    RtbInputButton
  },
  props: {
    vid: {
      type: String
    },
    clipboard: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<typeof SIZES[number]>,
      default: "default",
      validator: (val: any) => SIZES.includes(val)
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      copied: false
    }
  },
  mounted() {
    if (this.clipboard) {
      const c = new Clipboard(this.$refs.copy, {
        text: () => {
          return this.value
        }
      })

      c.on("success", () => {
        this.copied = true
      })

      this._clipboard = c
    }
  },
  beforeDestroy() {
    if (this._clipboard) {
      this._clipboard.destroy()
    }
  },
  render(h): VNode {
    return h(ValidationProvider, {
      props: { tag: "div", vid: this.vid, rules: this.rules, name: this.label },
      class: {
        [`rtb-input--size--${this.size}`]: this.size !== "default",
        "rtb-input--disabled": this.disabled,
        "rtb-input--with-button": this.clipboard || this.$slots.append
      },
      staticClass: "rtb-input",
      scopedSlots: {
        default: ({ errors, invalid }) => {
          const [validationError] = errors
          return [
            this.label
              ? h("div", { staticClass: "rtb-input__top" }, [
                  this.$slots.help,
                  h(
                    FormControlLabel,
                    {
                      props: { size: this.labelSize },
                      attrs: { for: this.id },
                      class: { "sr-only": this.hideLabel },
                      staticClass: "rtb-input__label"
                    },
                    this.label
                  )
                ])
              : null,
            h("div", { staticClass: "rtb-input__inner" }, [
              h("input", {
                ref: "input",
                attrs: {
                  ...this.$attrs,
                  id: this.id,
                  readonly: this.readonly || this.clipboard,
                  disabled: this.disabled
                },
                domProps: {
                  value: this.lazy ? this.localValue : this.value
                },
                class: {
                  "rtb-input__field--invalid": invalid || this.invalid
                },
                staticClass: "rtb-input__field",
                on: this.listeners
              }),
              this.$slots.append,

              this.clipboard
                ? h(
                    RtbInputButton,
                    {
                      ref: "copy",
                      props: { title: "Copy" },
                      staticClass: "rtb-input__button"
                    },
                    [
                      h(SvgIcon, {
                        props: {
                          name: this.copied ? "check-regular" : "copy-regular"
                        }
                      })
                    ]
                  )
                : null
            ]),
            validationError
              ? h(FormControlError, undefined, validationError)
              : null,
            validationError
              ? h(SvgIcon, {
                  props: { name: "exclamation-solid" },
                  staticClass: "rtb-input__icon"
                })
              : null
          ]
        }
      }
    })
  }
})
