import { pickBy } from "lodash"
import { db } from "@/firebase"
import "./typedef"
import { FirebaseSubscription, FirebaseSubscriptionRef } from "@/helpers"
import { fetchGameUsers, fetchGameTeams } from "@/services/game.service"
import { Role } from "@/helpers"
import { Firebase } from "@/helpers"

export class TournamentService {
  /**
   *
   * @param {string} clientID
   * @param {string} tournamentID
   * @param {string} roundID
   */
  static async getRoundGames(orgID, tournamentID, roundID) {
    const snapshot = await db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("tournamentID")
      .equalTo(tournamentID)
      .once("value")

    const games = snapshot.val() || {}

    return pickBy(games, game => game.originalGameID === roundID)
  }

  static getTournamentSubscription(clientID, tournamentID) {
    return new FirebaseSubscription(
      `/client/${clientID}/tournaments/${tournamentID}`
    )
  }
  static getTournamentsSubscription(clientID) {
    return new FirebaseSubscription(`/client/${clientID}/tournaments`)
  }
  static getTournamentGamesSubscription(orgID, tournamentID) {
    return new FirebaseSubscriptionRef(
      db
        .auxiliary()
        .ref(`org/${orgID}/games`)
        .orderByChild("tournamentID")
        .equalTo(tournamentID)
    )
  }
  static startTournamentRound(clientID, tournamentID, roundID) {
    return db
      .auxiliary()
      .ref(
        `/client/${clientID}/tournaments/${tournamentID}/games/${roundID}/status`
      )
      .set("started")
  }
  static unstartTournamentRound(clientID, tournamentID, roundID) {
    return db
      .auxiliary()
      .ref(
        `/client/${clientID}/tournaments/${tournamentID}/games/${roundID}/status`
      )
      .set(null)
  }
  static updateTeamsPerRound(clientID, tournamentID, roundID, teamsPerGame) {
    return db
      .auxiliary()
      .ref(
        `/client/${clientID}/tournaments/${tournamentID}/games/${roundID}/teamsPerGame`
      )
      .set(teamsPerGame)
  }
  /**
   * @param {string} clientID
   * @returns {Promise<Record<string, Tournament>>}
   */
  static async fetchTournamentsByClientID(clientID) {
    const ref = await db.auxiliary().ref(`/client/${clientID}/tournaments`)
    const snapshot = await ref.once("value")
    return snapshot.val() || {}
  }

  /**
   * @param {string} clientID
   * @param {string} tournamentID
   * @param {Tournament} value
   */
  static async updateTournamentsByClientID(clientID, tournamentID, value) {
    return db
      .auxiliary()
      .ref(`/client/${clientID}/tournaments/${tournamentID}`)
      .update(value)
  }
  static async updateTournamentRoundStartTime(
    clientID,
    tournamentID,
    roundID,
    timestamp
  ) {
    return db
      .auxiliary()
      .ref(
        `/client/${clientID}/tournaments/${tournamentID}/games/${roundID}/timestamp`
      )
      .set(timestamp)
  }
  static async updateTournamentRoundEndTime(
    clientID,
    tournamentID,
    roundID,
    timestamp
  ) {
    return db
      .auxiliary()
      .ref(
        `/client/${clientID}/tournaments/${tournamentID}/games/${roundID}/expectedEndTime`
      )
      .set(timestamp)
  }
  static async fetchTournamentGameStatistics(
    clientID,
    tournamentID,
    originalGameID
  ) {
    const snapshot = await db
      .auxiliary()
      .ref(
        `/client/${clientID}/tournaments/${tournamentID}/games/${originalGameID}/teams`
      )
      .once("value")
    return snapshot.val()
  }
  static async setTournamentTeamsForGame(
    clientID,
    tournamentID,
    originalGameID,
    value
  ) {
    return await db
      .auxiliary()
      .ref(
        `/client/${clientID}/tournaments/${tournamentID}/games/${originalGameID}/teams`
      )
      .update(value)
  }

  /**
   * @param {string} clientID
   * @returns {string}
   */
  static async getNewID(clientID) {
    const ref = await db
      .auxiliary()
      .ref(`/client/${clientID}/tournaments`)
      .push()
    return ref.key
  }

  /**
   * @param {string} clientID
   * @param {string} tournamentID
   */
  static async deleteTournament(clientID, tournamentID, orgID) {
    if (!orgID) throw new Error("No orgID is given")
    if (!clientID) throw new Error("No clientID is given")
    if (!tournamentID) throw new Error("No tournamentID is given")

    await db
      .auxiliary()
      .ref(`/client/${clientID}/tournaments/${tournamentID}`)
      .remove()

    const snapshot = await db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("tournamentID")
      .equalTo(tournamentID)
      .once("value")

    const value = snapshot.val()

    const gameIDs = Object.keys(value || {})
    const update = {}

    gameIDs.forEach(gameID => {
      update[`games/${gameID}`] = null
      update[`game/${gameID}`] = null
    })

    await db.auxiliary().ref(`org/${orgID}`).update(update)
  }

  static async cleanUpTournamentGame(orgID, tournamentID, gameID) {
    const snapshot = await db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("tournamentID")
      .equalTo(tournamentID)
      .once("value")

    const value = snapshot.val()

    if (value) {
      const games = pickBy(
        snapshot.val(),
        game => game.originalGameID === gameID
      )

      const update = {}

      Object.keys(games || {}).forEach(gameID => {
        update[`games/${gameID}`] = null
        update[`game/${gameID}`] = null
      })

      await db.auxiliary().ref(`org/${orgID}`).update(update)

      return Object.keys(games || {}).length
    }

    return 0
  }

  static async changeTimestamps(
    orgID,
    tournamentID,
    gameID,
    timestamp,
    expectedEndTime = null
  ) {
    const snapshot = await db
      .auxiliary()
      .ref(`org/${orgID}/games`)
      .orderByChild("tournamentID")
      .equalTo(tournamentID)
      .once("value")

    const value = snapshot.val()

    if (value) {
      const games = pickBy(
        snapshot.val(),
        game => game.originalGameID === gameID
      )

      const update = {}

      for (const gameID in games) {
        update[`${gameID}/startTimestamp`] = timestamp
        if (expectedEndTime) {
          update[`${gameID}/expectedEndTime`] = expectedEndTime
        }
      }
      await db.auxiliary().ref(`org/${orgID}/games`).update(update)
    }
  }

  /**
   *
   * @param orgID
   * @param clientID
   * @param gameID
   * @param tournamentID
   * @param originalGameID
   * @return {Promise<*>}
   */
  static async setTeamsStatistic({
    orgID,
    clientID,
    gameID,
    tournamentID,
    originalGameID,
    hostID
  }) {
    if (!hostID) throw new Error("Cannot submit without a user ID")
    const users = await fetchGameUsers({ gameID })
    const host = users[hostID]
    if (!host) throw new Error(`User ${hostID} not found`)
    const teams = await fetchGameTeams({ orgID, gameID })
    const data = {}
    Object.entries(teams || {}).forEach(([key, team]) => {
      // Default values for teams
      if (key === "undefined" || key === "0" || !key) return
      // Don't save statistic for hidden teams
      if (!team.show) return
      data[key] = {
        name: team.name || null,
        icon: team.icon || null,
        score: team.totalScore || 0,
        gameID,
        users: {}
      }
      data[key].users[hostID] = {
        name: `${host.firstname} ${host.lastname}`,
        role: Role.Host,
        imageUrl: host.image || null
      }
    })

    Object.entries(users || {})
      .filter(
        entry =>
          entry[1].role === Role.Player &&
          entry[1].teamID &&
          data[entry[1].teamID]
      )
      .forEach(([userID, user]) => {
        data[user.teamID]["users"][userID] = {
          name: `${user.firstname} ${user.lastname}`,
          imageUrl: user.image || null
        }
      })

    return await TournamentService.setTournamentTeamsForGame(
      clientID,
      tournamentID,
      originalGameID,
      data
    )
  }

  static getRoundList(tournament) {
    return Object.entries(tournament.games || {})
      .sort((a, b) => a[1].timestamp - b[1].timestamp)
      .map(([key, value], i) => ({
        originalGameID: key,
        round: value,
        num: i
      }))
  }

  /**
   *
   * @param roundList - value from the getRoundList function
   */
  static getActiveRound(roundList) {
    const now = Date.now()
    const roundsLength = roundList.length
    for (let i = 0; i < roundsLength; i++) {
      const roundItem = roundList[i]
      const nextRound = i + 1 < roundsLength ? roundList[i + 1] : null
      if (i === 0 && roundItem.round.timestamp > now) return roundItem
      if (!nextRound) return roundItem
      if (roundItem.round.timestamp <= now && nextRound.round.timestamp > now)
        return roundItem
    }
    return null
  }

  /**
   *
   * @param roundList
   * @param roundID
   * @return {*}
   */
  static getNextRound(roundList, roundID) {
    const roundItem = roundList.filter(item => item.originalGameID === roundID)
    const roundItemIdx = roundList.indexOf(roundItem)
    if (roundItemIdx === 0) return null
    return roundItem[roundItemIdx - 1]
  }

  /**
   *
   * @param roundList
   * @param roundID
   * @return {*}
   */
  static getPrevRound(roundList, roundID) {
    const roundItem = roundList.filter(item => item.originalGameID === roundID)
    const roundItemIdx = roundList.indexOf(roundItem)
    if (roundItemIdx + 1 >= roundList.length) return null
    return roundItem[roundItemIdx + 1]
  }

  /**
   *
   * @param roundListItem
   * @param activeRoundListItem
   * @return {string}
   */
  static getRoundStatus(roundListItem, activeRoundListItem) {
    if (
      roundListItem &&
      activeRoundListItem &&
      roundListItem.num < activeRoundListItem.num
    ) {
      return "ended"
    } else if (
      roundListItem &&
      activeRoundListItem &&
      roundListItem.num === activeRoundListItem.num
    ) {
      return "active"
    } else {
      return "incoming"
    }
  }

  /**
   *
   * @param clientID
   * @param srcTargetID
   * @param timeOffset
   * @return {*}
   */
  static async copyTournaments(clientID, srcTargetID, timeOffset) {
    const tournamentsSnapshot =
      await TournamentService.fetchTournamentsByClientID(srcTargetID)
    const tournaments = Object.keys(tournamentsSnapshot || {})
      .filter(key => Firebase.isFirebaseKey(key))
      .map(key => tournamentsSnapshot[key])

    const tournamentsDict = {}

    await Promise.all(
      tournaments.map(async tournament => {
        Object.keys(tournament.games || {}).forEach(gameId => {
          const game = tournament.games[gameId]
          if (game?.timestamp) {
            game.timestamp = game.timestamp + timeOffset
          }
          if (game?.expectedEndTime) {
            game.expectedEndTime = game.expectedEndTime + timeOffset
          }
        })

        const tournamentID = await TournamentService.getNewID()
        tournamentsDict[tournament.id] = tournamentID
        await TournamentService.updateTournamentsByClientID(
          clientID,
          tournamentID,
          tournament
        )
      })
    )

    return tournamentsDict
  }
}
