var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "baner-container" }, [
    _c(
      "div",
      { staticClass: "baner-wrapper" },
      [
        !_vm.customImages
          ? [
              _c("img", {
                staticClass: "rectangle",
                attrs: { src: _vm.rectangleImg, alt: "Rectangle image" },
              }),
              _c("img", {
                staticClass: "circle",
                attrs: { src: _vm.circleImage, alt: "Circle image" },
              }),
            ]
          : _vm._e(),
        _c(
          "div",
          { staticClass: "slider-wrap" },
          _vm._l(_vm.images, function (url, key) {
            return _c(
              "transition",
              { key: "slide-" + key, attrs: { name: "image-transition" } },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.counter === key,
                      expression: "counter === key",
                    },
                  ],
                  staticClass: "carousel-image",
                  attrs: { src: url, alt: "Slider Image" },
                }),
              ]
            )
          }),
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }