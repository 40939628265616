var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-player-card",
      class:
        ((_obj = {}),
        (_obj["game-player-card--wide"] = _vm.isConference),
        (_obj["game-player-card--normalized-video"] = _vm.isSpiralDrawing),
        _obj),
    },
    [
      _c(
        "AppUserCard",
        _vm._g(
          _vm._b(
            {
              attrs: {
                selectable: !_vm.isGameOver,
                user: _vm.user,
                points: _vm.points,
                submitted: _vm.isSubmitted,
                mutable: _vm.mutable,
                mediaDisabled: _vm.mediaDisabled,
                skippable: "",
              },
              scopedSlots: _vm._u(
                [
                  _vm.isViewerModerator
                    ? {
                        key: "actions",
                        fn: function () {
                          return [
                            _c(
                              "UserCardButton",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("dblclick")
                                  },
                                },
                              },
                              [_c("AccoutIcon")],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            "AppUserCard",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.isUprPlayMode
            ? _c("UnconditionalPositiveRegardStage", {
                attrs: { userID: _vm.user.id, encrypted: "" },
              })
            : _vm._e(),
        ],
        1
      ),
      !_vm.isConference
        ? _c("RingStarsAnimation", {
            attrs: {
              animate: _vm.isWinner,
              circle: _vm.$attrs.rounded,
              starred: _vm.isGameOver && _vm.user.selected,
            },
          })
        : _vm._e(),
      !!_vm.teamPosition
        ? _c(
            "Medal",
            {
              staticClass: "game-player-card__medal",
              attrs: { variant: _vm.teamPosition },
            },
            [_vm._v(" " + _vm._s(_vm.teamPosition + _vm.positionSuffix) + " ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }