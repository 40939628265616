var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "team-manager" },
    [
      _c(
        "div",
        {
          staticClass: "team-manager__content",
          staticStyle: { "padding-right": "60px" },
        },
        [
          _c("div", { staticClass: "team-manager__main" }, [
            _c(
              "div",
              {
                staticClass: "team-manager__left-col",
                style: {
                  flexBasis: 100 - _vm.teamsWidth + "%",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "team-manager__host-wrap",
                    class: {
                      "team-manager__host-wrap--column":
                        !_vm.nOfPlayersWithoutTeam && _vm.isAnyUserAssigned,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "host-media-wrap" },
                      [
                        _c(
                          "RatioContainer",
                          [_c("HostMedia", { attrs: { hideBadge: "" } })],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "team-manager__details" }, [
                      _c("div", { staticClass: "team-manager__details-img" }, [
                        _vm.componentLogo
                          ? _c("img", {
                              staticClass: "team-manager__game-cover",
                              style: _vm.logoStyle,
                              attrs: { src: _vm.componentLogo },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "team-manager__info" }, [
                  _c(
                    "div",
                    { staticClass: "team-manager__info-scroll" },
                    [
                      _vm.nOfPlayersWithoutTeam || !_vm.isAnyUserAssigned
                        ? [
                            _c("p", { staticClass: "team-manager__text" }, [
                              _vm._v(" " + _vm._s(_vm.infoText) + " "),
                            ]),
                            !!_vm.startTime && _vm.isStandard
                              ? _c("p", { staticClass: "team-manager__text" }, [
                                  _vm.time > 0
                                    ? _c("span", [_vm._v(" Starts at ")])
                                    : _c("span", [_vm._v(" Started at ")]),
                                  _c(
                                    "span",
                                    { staticClass: "actual-start-time" },
                                    [_vm._v(" " + _vm._s(_vm.startTime) + " ")]
                                  ),
                                ])
                              : _vm._e(),
                            _c(
                              "h2",
                              {
                                staticClass: "team-manager__headline mt-4 mb-3",
                              },
                              [_vm._v(" " + _vm._s(_vm.numOfPlayersText) + " ")]
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "team-manager__unassiged-users-wrap" },
                        [
                          _c("RecycleScroller", {
                            ref: "unassigned-users-container",
                            staticClass: "team-manager__team-list",
                            attrs: {
                              items: _vm.unassignedUsersRows,
                              "item-size": _vm.unassignedUserSize,
                              "key-field": "id",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c("ResizeObserver", {
                                      on: {
                                        notify: _vm.handleUnassignedUsersResize,
                                      },
                                    }),
                                    _c(
                                      "draggable",
                                      _vm._b(
                                        {
                                          staticClass:
                                            "team-manager__unassigned-users-row",
                                          attrs: {
                                            handle: ".handle",
                                            list: item.items,
                                            move: _vm.checkMove,
                                          },
                                          on: {
                                            start: function ($event) {
                                              return _vm.onStartDragging(item)
                                            },
                                            end: _vm.onStopDragging,
                                            change: function ($event) {
                                              return _vm.changeTeam(
                                                $event,
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        "draggable",
                                        _vm.draggableContext,
                                        false
                                      ),
                                      [
                                        !item.placeholder
                                          ? _vm._l(
                                              item.items,
                                              function (player, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key:
                                                      "no-team-player-" +
                                                      i +
                                                      "-" +
                                                      player.id,
                                                    staticClass: "pick-player",
                                                    class: {
                                                      handle:
                                                        _vm.canViewerDragAndDrop(
                                                          player
                                                        ),
                                                      "pick-player--wide":
                                                        _vm.isHighlightedUser(
                                                          player
                                                        ),
                                                    },
                                                    attrs: {
                                                      "data-uuid": player.id,
                                                    },
                                                  },
                                                  [
                                                    _c("PickTeamsUserCard", {
                                                      attrs: { user: player },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _vm.isShuffleAvailable
                        ? [
                            !_vm.nOfPlayersWithoutTeam
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { right: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "team-manager__randomize",
                                                    attrs: {
                                                      color: "secondary",
                                                      absolute: "",
                                                      fab: "",
                                                      loading: _vm.randomizing,
                                                    },
                                                    on: {
                                                      click: _vm.shufflePlayers,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("shuffle"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1626563047
                                    ),
                                  },
                                  [_c("span", [_vm._v("Shuffle players")])]
                                )
                              : _c(
                                  "v-tooltip",
                                  {
                                    attrs: { right: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "team-manager__randomize",
                                                    attrs: {
                                                      color: "primary",
                                                      absolute: "",
                                                      fab: "",
                                                      loading: _vm.randomizing,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.randomizePlayers,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("shuffle"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      541773211
                                    ),
                                  },
                                  [_c("span", [_vm._v("Randomize players")])]
                                ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "team-manager__teams",
                style: {
                  flexBasis: _vm.teamsWidth + "%",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "mr-4",
                        attrs: { flex: "", "justify-space-between": "" },
                      },
                      [
                        _c(
                          "h2",
                          { staticClass: "team-manager__headline mt-0 mb-1" },
                          [_vm._v("Teams")]
                        ),
                        _vm.isHost || _vm.isDIYGame
                          ? _c(
                              "WeveButton",
                              {
                                attrs: { size: "sm", variant: "secondary" },
                                on: { click: _vm.addOneTeam },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("add_circle")]
                                ),
                                _vm._v(" ADD TEAM "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.nOfTeams > 0
                  ? _c(
                      "div",
                      { staticClass: "team-manager__scroller-wrap" },
                      [
                        _c("RecycleScroller", {
                          staticClass: "team-manager__team-list",
                          attrs: {
                            items: _vm.allTeams,
                            "item-size": 204,
                            "key-field": "id",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "team-manager__team-list-item handle-team",
                                        attrs: { "no-action": "" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "team-manager__title",
                                          },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "team-manager__team-header",
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "mr-1 team-manager__team-icon",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.icon) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "team-manager__team-name mr-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                item.location
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(" - "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "team-manager__location-label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.location
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                item.specialPurpose
                                                  ? _c(
                                                      "span",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _vm._v(" - "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "team-manager__special-purpose-label",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Special Purpose"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.canViewerEditTeams
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "team-manager__team-edit-icon",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onEditTeam(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" settings ")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "team-manager__row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "team-manager__fake-row",
                                              },
                                              [
                                                !item.conference
                                                  ? _vm._l(
                                                      3,
                                                      function (fakePlayer) {
                                                        return _c("div", {
                                                          key: fakePlayer,
                                                          staticClass:
                                                            "team-manager__fake-player pick-player",
                                                        })
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "draggable",
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "players-wrapper",
                                                  attrs: {
                                                    handle: ".handle",
                                                    list: item.items,
                                                    move: _vm.checkMove,
                                                    disabled:
                                                      _vm.isTeamDisabled(item),
                                                  },
                                                  on: {
                                                    start: function ($event) {
                                                      return _vm.onStartDragging(
                                                        item
                                                      )
                                                    },
                                                    end: _vm.onStopDragging,
                                                    change: function ($event) {
                                                      return _vm.changeTeam(
                                                        $event,
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                "draggable",
                                                _vm.draggableContext,
                                                false
                                              ),
                                              [
                                                _vm._l(
                                                  item.items,
                                                  function (player, i) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key:
                                                          "team-player-" +
                                                          i +
                                                          "-" +
                                                          player.id,
                                                        staticClass:
                                                          "pick-player",
                                                        class: {
                                                          handle:
                                                            _vm.canViewerDragAndDrop(
                                                              player
                                                            ),
                                                          "pick-player--wide":
                                                            _vm.isHighlightedUser(
                                                              player
                                                            ),
                                                        },
                                                        attrs: {
                                                          "data-uuid":
                                                            player.id,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "PickTeamsUserCard",
                                                          {
                                                            attrs: {
                                                              user: player,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                item.items.length < 2
                                                  ? _c("div", {
                                                      staticClass:
                                                        "team-manager__fake-player pick-player",
                                                      staticStyle: {
                                                        opacity: "0",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            989429983
                          ),
                        }),
                      ],
                      1
                    )
                  : _c(
                      "v-flex",
                      {
                        attrs: {
                          "d-flex": "",
                          "align-center": "",
                          "justify-center": "",
                        },
                      },
                      [
                        _c(
                          "h1",
                          {
                            staticStyle: {
                              color: "rgba(255, 255, 255, 0.3)",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(" NO TEAMS ")]
                        ),
                      ]
                    ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "WeveDialog",
        {
          attrs: {
            dialogClass: "team-manger__team-dialog",
            title: "Edit Team",
          },
          model: {
            value: _vm.isTeamDialogVisible,
            callback: function ($$v) {
              _vm.isTeamDialogVisible = $$v
            },
            expression: "isTeamDialogVisible",
          },
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var invalid = ref.invalid
                  return [
                    _c(
                      "div",
                      { staticClass: "px-4" },
                      [
                        _c("WeveTextFieldWithValidation", {
                          attrs: {
                            placeholder: "The bananas",
                            label: "Name",
                            maxlength: 30,
                            required: "",
                            "characters-counter": "",
                          },
                          on: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.saveTeam()
                            },
                          },
                          model: {
                            value: _vm.team.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.team, "name", $$v)
                            },
                            expression: "team.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isHost
                      ? [
                          _c(
                            "div",
                            { staticClass: "px-4 mt-2" },
                            [
                              _c("WeveTextField", {
                                attrs: { label: "Score" },
                                model: {
                                  value: _vm.teamScore,
                                  callback: function ($$v) {
                                    _vm.teamScore = $$v
                                  },
                                  expression: "teamScore",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "px-4 mt-3" },
                            [
                              _c("WeveCheckbox", {
                                staticClass: "mr-2",
                                attrs: { label: "Special Purpose" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "help",
                                      fn: function () {
                                        return [
                                          _c("rtb-inline-help", [
                                            _vm._v(" Special Purpose Team "),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.team.specialPurpose,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.team, "specialPurpose", $$v)
                                  },
                                  expression: "team.specialPurpose",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _c("hr", {
                      staticClass: "mt-4 mb-3",
                      staticStyle: {
                        "border-bottom": "1px",
                        "border-color": "rgb(147, 147, 147)",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "px-4" },
                      [
                        _c("p", { staticClass: "white--text" }, [
                          _vm._v("Selected Icon"),
                        ]),
                        _c(
                          "v-layout",
                          {
                            staticClass: "mb-4",
                            attrs: { flex: "", "flex-wrap": "" },
                          },
                          _vm._l(_vm.teamIcons, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass:
                                  "pa-2 team-manager__team-icon-option",
                                class:
                                  _vm.team && _vm.team.icon === item
                                    ? "team-manager__team-icon-option--selected"
                                    : null,
                                on: {
                                  click: function ($event) {
                                    return _vm.selectIcon(item)
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { color: "white" } }, [
                                  _vm._v(" " + _vm._s(item) + " "),
                                ]),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-layout",
                      {
                        staticClass: "mt-2 mb-3 pr-3",
                        staticStyle: { "justify-content": "flex-end" },
                        attrs: { flex: "" },
                      },
                      [
                        _c(
                          "WeveButton",
                          {
                            staticClass: "mx-1",
                            attrs: { variant: "tertiary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.isTeamDialogVisible = false
                              },
                            },
                          },
                          [_vm._v(" CANCEL ")]
                        ),
                        _c(
                          "WeveButton",
                          {
                            staticClass: "mx-1",
                            attrs: { variant: "danger", size: "sm" },
                            on: { click: _vm.dismissTeam },
                          },
                          [_vm._v(" DELETE TEAM ")]
                        ),
                        _c(
                          "WeveButton",
                          {
                            staticClass: "mx-1",
                            attrs: {
                              disabled: invalid,
                              variant: "accent",
                              size: "sm",
                            },
                            on: { click: _vm.saveTeam },
                          },
                          [_vm._v(" SAVE ")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }