import AuthService from "@/services/auth.service"
import { db } from "@/firebase"

export default class SwapPhoneService {
  static async requestQR(callback) {
    const {
      data: { url, tokenId }
    } = await AuthService.getCustomToken()
    const ref = db.ref(`swapPhoneQR/${tokenId}`)
    const onSnapshot = snapshot => {
      if (snapshot.val()) return
      unsubscribe()
      callback()
    }
    const unsubscribe = () => ref.off("value", onSnapshot)
    ref.on("value", onSnapshot)
    return { url, unsubscribe }
  }
  static completePhoneSwap(tokenId) {
    db.ref(`swapPhoneQR/${tokenId}`).remove()
  }
}
