
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "ContrastPreview",
  functional: true,
  props: {
    color: {
      type: String,
      required: true
    }
  },
  render(h, { props, children, data }) {
    return h(
      "div",
      mergeData(data, {
        style: { backgroundColor: props.color, color: props.color },
        staticClass: "contrast-preview"
      }),
      [h("span", { staticClass: "contrast-text-color" }, "A")]
    )
  }
})
