var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "video",
    _vm._g(
      _vm._b(
        {
          attrs: {
            controlslist: "nodownload",
            disablePictureInPicture: "",
            playsInline: "",
          },
        },
        "video",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.sources, function (item) {
      return _c("source", {
        key: item.src,
        attrs: { src: item.src, type: item.type },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }