import { db } from "@/firebase"
import _ from "lodash"

export const MAX_RECORDING_TIME = 30 * 60 * 1000 // 30 min
export const TARGET_GAME = "game"
export const TARGET_CLIENT = "client"

export const ACTION_TYPES = {
  UPDATE_RECORDING_TITLE: "UPDATE_RECORDING_TITLE",
  RE_PUBLISH_RECORDING: "RE_PUBLISH_RECORDING"
}

const RecordingModule = {
  namespaced: true,
  state: {
    subscriptions: {},
    announcementClient: null,
    announcementClients: null,
    announcementGame: null
  },
  mutations: {
    UPDATE_ANNOUNCEMENT_CLIENT(state, data) {
      state.announcementClient = data
    },
    UPDATE_ANNOUNCEMENT_CLIENTS(state, data) {
      state.announcementClients = data
    },
    UPDATE_ANNOUNCEMENT_GAME(state, data) {
      state.announcementGame = data
    },
    ADD_SUBSCRIPTION(state, { targetID, ref }) {
      state.subscriptions[targetID] = ref
    },
    REMOVE_SUBSCRIPTION(state, targetID) {
      state.subscriptions[targetID] = null
    }
  },
  actions: {
    unsubscribeAnnouncementVideos({ commit, state }, targetID) {
      if (state.subscriptions[targetID]) {
        state.subscriptions[targetID].off("value")
        let announcementClientsList = []
        if (state.announcementClients) {
          announcementClientsList = Object.keys(state.announcementClients).map(
            announcementClient => state.announcementClients[announcementClient]
          )
        }

        if (
          state.announcementClients &&
          announcementClientsList.some(
            announcementClient => announcementClient.targetID === targetID
          )
        ) {
          commit("UPDATE_ANNOUNCEMENT_CLIENTS", null)
        } else if (
          state.announcementGame &&
          state.announcementGame.targetID === targetID
        ) {
          commit("UPDATE_ANNOUNCEMENT_GAME", null)
        }
        commit("REMOVE_SUBSCRIPTION", targetID)
      }
    },
    subscribeAnnouncementVideos({ commit, state }, { clientID, targetID }) {
      targetID = targetID || clientID
      const onSnapshot = snapshot => {
        let payload = snapshot.val()
        if (payload) {
          const entityIds = Object.keys(payload)
          const target = payload[entityIds[0]].target
          if (target === TARGET_CLIENT) {
            commit("UPDATE_ANNOUNCEMENT_CLIENTS", payload)
          } else {
            commit(
              "UPDATE_ANNOUNCEMENT_GAME",
              payload[entityIds[entityIds.length - 1]]
            )
          }
        }
      }

      const ref = db
        .auxiliary()
        .ref(`client/${clientID}/recordings`)
        .orderByChild("targetID")
        .equalTo(targetID)

      if (state.subscriptions[targetID]) {
        state.subscriptions[targetID].off("value")
      }

      ref.on("value", onSnapshot)
      commit("ADD_SUBSCRIPTION", { targetID, ref })
    },
    async getAnnouncementVideos(_, { clientID, targetID }) {
      const snapshot = await db
        .auxiliary()
        .ref(`client/${clientID}/recordings`)
        .orderByChild("targetID")
        .equalTo(targetID || clientID)
        .once("value")
      return snapshot.val()
    },
    addAnnouncementVideo(_, { clientID, ...rest }) {
      const ref = db.auxiliary().ref(`client/${clientID}/recordings`).push()
      ref.set({ ...rest, timestamp: Date.now() })
      return ref.key
    },
    deleteAnnouncementVideo(_, { recordingID, clientID }) {
      return db
        .auxiliary()
        .ref(`client/${clientID}/recordings/${recordingID}`)
        .remove()
    },
    async deleteAnnouncementVideos(context, { clientID, targetID }) {
      const ref = await db.auxiliary().ref(`client/${clientID}/recordings`)
      const snapshot = await ref.once("value")

      const val = snapshot.val()

      const filteredRecordings = _.omitBy(
        val,
        recording => recording.targetID === targetID
      )

      await ref.set(filteredRecordings)
    },
    async copyAnnouncementVideo(
      store,
      { clientID, srcTargetID, distTargetID }
    ) {
      const ref = db.auxiliary().ref(`client/${clientID}/recordings`)
      let oldValueRef = await ref
        .orderByChild("targetID")
        .equalTo(srcTargetID)
        .limitToLast(1) // only get the last child
        .once("value")
      let oldValue = oldValueRef.val()
      if (oldValue) {
        oldValue = Object.values(oldValue)[0]
        ref.push({
          ...oldValue,
          targetID: distTargetID
        })
      }
    },
    /**
     * Copies recordings from one clinet to another.
     * It takes into account `targetID` of each recording and compares it to `srcTargetId`
     */
    async copyRecordingsByClient(
      context,
      { clientID, srcTargetID, timeOffset }
    ) {
      const snapshot = await db
        .ref(`client/${srcTargetID}/recordings`)
        .once("value")

      const value = snapshot.val()

      if (value) {
        const recordings = _.pickBy(
          value,
          ({ targetID }) => targetID === srcTargetID
        )
        for (const key in recordings) {
          recordings[key].targetID = clientID
          recordings[key].playingTimestamp =
            recordings[key].playingTimestamp + timeOffset
        }

        await db
          .auxiliary()
          .ref(`client/${clientID}/recordings`)
          .set(recordings)
      }
    },
    [ACTION_TYPES.UPDATE_RECORDING_TITLE](
      _,
      { clientID, recordingID, title, playingTimestamp }
    ) {
      return db
        .auxiliary()
        .ref(`client/${clientID}/recordings/${recordingID}`)
        .update({ title, playingTimestamp })
    },
    async [ACTION_TYPES.RE_PUBLISH_RECORDING](_, { clientID, recordingID }) {
      const snapshot = await db
        .auxiliary()
        .ref(`client/${clientID}/recordings/${recordingID}`)
        .once("value")
      const value = snapshot.val()

      await db
        .auxiliary()
        .ref(`client/${clientID}/recordings`)
        .push({
          ...value,
          playingTimestamp: Date.now(),
          timestamp: Date.now()
        })
    },
    async setFeaturedRecording(
      { rootState },
      { clientID, gameID, recordingID }
    ) {
      const { orgID } = rootState
      const snapshot = await db
        .auxiliary()
        .ref(`client/${clientID}/recordings/${recordingID}`)
        .once("value")
      const value = snapshot.val()

      await db
        .auxiliary()
        .ref(`org/${orgID}/games/${gameID}/featuredRecording`)
        .set({ ...value, recordingID })
    },
    async addHostlessVideo(_, { clientID, ...rest }) {
      const ref = db
        .auxiliary()
        .ref(`client/${clientID}/HostlessRecordings`)
        .push()
      ref.set({ ...rest, timestamp: Date.now() })
      return ref.key
    },
    async getHostlessVideo(_, { clientID }) {
      const snapshot = await db
        .auxiliary()
        .ref(`client/${clientID}/HostlessRecordings`)
        .once("value")
      return snapshot.val()
    },
    deleteHostlessVideo(_, { recordingID, clientID }) {
      return db
        .auxiliary()
        .ref(`client/${clientID}/HostlessRecordings/${recordingID}`)
        .remove()
    },
    async deleteHostlessVideos(context, { clientID, targetID }) {
      return await db
        .auxiliary()
        .ref(`client/${clientID}/HostlessRecordings`)
        .set(null)
    },
    updateHostlessVideo(_, { clientID, recordingID, title, playingTimestamp }) {
      return db
        .ref(`client/${clientID}/HostlessRecordings/${recordingID}`)
        .update({ title, playingTimestamp })
    }
  },
  getters: {
    subscriptions: state => state.subscriptions,
    announcementVideo: state =>
      state.annoucementPayload && state.annoucementPayload.url,
    announcementClientURL: state =>
      state.announcementClient && state.announcementClient.url,
    announcementClient: state => state.announcementClient,
    announcementGameURL: state =>
      state.announcementGame && state.announcementGame.url,
    announcementClients: state => state.announcementClients
  }
}

export default RecordingModule
