import type { Ref } from "@vue/composition-api"

import useStore from "./useStore"

export default function useUser(user: Ref<unknown>) {
  const { store } = useStore()

  function update() {
    store.dispatch("UserSettings/updateUser", { user: user.value })
  }

  return {
    update
  }
}
