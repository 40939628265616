<template>
  <canvas ref="canvas" />
</template>

<script>
import Vue from "vue"
// import { TweenMax } from "gsap/TweenMax"

export default Vue.extend({
  name: "CanvasBackground",
  props: {
    shapes: {
      type: Array,
      required: false
    },
    width: {
      type: Number,
      default: 1000
    },
    height: {
      type: Number,
      default: 800
    }
  },
  data() {
    return {
      el: null,
      ctx: null,
      shape1: {
        x: 700,
        y: 500,
        opacity: 0.8,
        r1: 80,
        r2: 150,
        blur: 60,
        angle: 25
      },
      shape2: {
        x: 700,
        y: 330,
        opacity: 0.8,
        r1: 110,
        r2: 160,
        blur: 65,
        angle: -10
      },
      shape3: {
        x: 300,
        y: 360,
        opacity: 0.6,
        r1: 130,
        r2: 160,
        blur: 65,
        angle: 0
      },
      shape4: {
        x: 330,
        y: 500,
        opacity: 0.5,
        r1: 90,
        r2: 160,
        blur: 55,
        angle: -30
      }
    }
  },
  watch: {
    shapesComputed: {
      handler() {
        this.ctx && this.draw()
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    shapesComputed() {
      const $theme = this.$theme
      const color1 = $theme.get("--tertiary-accent-color")
      const color2 = $theme.get("--secondary-accent-color")
      const color3 = $theme.get("--primary-accent-color")
      return [
        {
          ...this.shape1,
          color: color1
        },
        {
          ...this.shape2,
          color: color2
        },
        {
          ...this.shape3,
          color: color3
        },
        {
          ...this.shape4,
          color: color3
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.el = this.$refs.canvas
      this.el.width = this.width
      this.el.height = this.height
      this.ctx = this.el.getContext("2d")
      this.draw()

      // TweenMax.staggerFrom(
      //   [this.shape2, this.shape4],
      //   1.8,
      //   { blur: 70, yoyo: true, repeat: -1 },
      //   0.5
      // )

      // TweenMax.staggerFrom(
      //   [this.shape1, this.shape3],
      //   1.8,
      //   { blur: 70, yoyo: true, repeat: -1 },
      //   0.5
      // )
    })
  },
  methods: {
    draw() {
      const ctx = this.ctx
      ctx.clearRect(0, 0, this.width, this.height)
      this.shapesComputed.forEach(obj => {
        ctx.save()
        ctx.globalCompositeOperation = "overlay"
        ctx.filter = `blur(${obj.blur}px)`
        ctx.globalAlpha = obj.opacity
        ctx.fillStyle = obj.color
        ctx.beginPath()
        ctx.ellipse(
          obj.x,
          obj.y,
          obj.r1,
          obj.r2,
          obj.angle * (Math.PI / 180),
          0,
          2 * Math.PI
        )
        ctx.fill()
        ctx.restore()
      })
    }
  }
})
</script>
