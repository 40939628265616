export const colors = [
  "primary",
  "secondary",
  "grey",
  "success",
  "danger",
  "dark",
  "transparent-light",
  "transparent-dark"
] as const
export const variants = ["default", "icon", "outline"] as const
export const sizes = ["default", "sm", "lg", "xl"] as const
