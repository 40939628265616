import type { PropType } from "vue"
import Vue from "vue"

import { UserCardState } from "./UserCard.types"
import MicrophoneOffIcon from "./icons/MicrophoneOffIcon"
import CellphoneIcon from "./icons/CellphoneIcon"
import StarOffOutlineIcon from "./icons/StarOffOutlineIcon"

const STATE_TO_ICON: Record<UserCardState, unknown> = {
  [UserCardState.Muted]: MicrophoneOffIcon,
  [UserCardState.Mobile]: CellphoneIcon,
  [UserCardState.Skip]: StarOffOutlineIcon
}

export default Vue.extend({
  name: "UserCardIcon",
  functional: true,
  props: {
    icon: {
      type: String as PropType<UserCardState>,
      required: true
    }
  },
  render(h, { props }) {
    return h(
      "span",
      {
        staticClass: "weve-user-card__icon",
        class: {
          "weve-user-card__icon--accent": props.icon === UserCardState.Muted
        }
      },
      [h(STATE_TO_ICON[props.icon])]
    )
  }
})
