import _ from "lodash"
import { db } from "@/firebase"

import UserService, { getGameUsersRef } from "../services/user.service.js"

import GetterTypes from "./modules/GameUsers/getter-types"
import ActionTypes from "./modules/GameUsers/action-types"
import Navigation from "@/helpers/navigation"
import User from "@shared/User.js"

let gameUsersSubscription = null

const GameUsers = {
  namespaced: true,
  state: {
    users: {}
  },
  mutations: {
    UPDATE_USERS(state, payload) {
      state.users = payload ?? {}
    }
  },
  actions: {
    async [ActionTypes.PUSH_USERS_TO_GAME]({ rootGetters }, { users, gameID }) {
      const clientID = rootGetters["auth/clientID"]
      const update = {}

      users.forEach(userID => {
        update[userID] = { gameID, force: true }
      })

      await db.auxiliary().ref(`/client/${clientID}/playersToMap/`).set(update)
    },
    subscribeToRoomUsers({ commit }, roomId) {
      gameUsersSubscription?.off()
      gameUsersSubscription = getGameUsersRef({ gameID: roomId })
      gameUsersSubscription.on(
        "value",
        _.throttle(snapshot => commit("UPDATE_USERS", snapshot.val()), 1000)
      )
    },
    async addTestUser({ rootGetters }, team) {
      const clientID = rootGetters["auth/clientID"]
      const { orgID, gameID } = rootGetters
      const path = `/game/${Navigation.createUrlID({ clientID, gameID })}`
      await UserService.create({
        headless: true,
        sessionID: clientID,
        clientID,
        orgID,
        gameID,
        teamID: team?.id,
        path
      })
    },
    async removeFirstTestUserFromTeam({ rootGetters, dispatch }, team) {
      const users = rootGetters.onlineUsersGroupedByTeam?.[team?.id] ?? []
      const user = users.find(User.isHeadless)
      if (!user) return
      await dispatch("removeTestUsers", { users: [user] })
    },
    async removeTestUsers(_, { users = [] }) {
      const update = users.reduce((acc, user) => {
        if (User.isHeadless(user)) acc[user.id] = null

        return acc
      }, {})

      if (Object.keys(update).length) await db.ref(`org/1/users`).update(update)
    }
  },
  getters: {
    users(state) {
      return state.users ?? {}
    },
    usersArray(state) {
      return User.normalize(state.users ?? {})
    },
    usersOnline(_, getters, __, rootGetters) {
      return getters.usersArray.filter(rootGetters.isUserAlive)
    },
    [GetterTypes.ONLINE_USERS](_, getters) {
      return getters.usersOnline
    },
    onlineUsersNumberOfTeamPlayersArray(
      state,
      getters,
      rootState,
      rootGetters
    ) {
      const moderatorID = rootGetters.moderatorID
      const isUserAlive = rootGetters.isUserAlive
      return _.chain(getters.usersOnline)
        .filter(
          user =>
            user &&
            user.id !== moderatorID &&
            !User.isHeadless(user) &&
            User.isPlaying(user) &&
            isUserAlive(user)
        )
        .groupBy("teamID")
        .reduce((acc, items, id) => {
          if (id && id !== "null" && id !== "undefined" && id !== "0") {
            acc.push(items.length)
          }
          return acc
        }, [])
        .value()
    },
    isOneUserPerTeam(_, getters) {
      return getters.onlineUsersNumberOfTeamPlayersArray.every(n => n < 2)
    }
  }
}

export default GameUsers
