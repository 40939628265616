class Collection {
  static normalize(obj) {
    return Object.entries(obj || {}).map(([id, _obj]) => {
      const obj = typeof _obj === "object" ? _obj : {}
      obj.id = id
      return obj
    })
  }
  static denormalize(array = []) {
    return array.reduce((acc, val) => {
      acc[val.id] = val
      return acc
    }, {})
  }
}

module.exports = Collection
