<template>
  <UserImage :imageUrl="imageUrl">
    <UserVideo
      v-if="track && !disable"
      :key="track.id || track.sid"
      :track="track"
      ref="video"
      @ready="$emit('ready')"
    />
  </UserImage>
</template>

<script>
import { mapGetters } from "vuex"

import UserImage from "./UserImage"
import UserVideo from "./UserVideo"

export default {
  name: "UserMedia",
  components: {
    UserVideo,
    UserImage
  },
  props: {
    identity: {
      type: [String, undefined],
      required: true
    },
    disable: Boolean,
    imageUrl: [String, Boolean],
    videoTrack: Object
  },
  computed: {
    ...mapGetters("twilio", ["users"]),
    ...mapGetters(["user"]),
    track() {
      return this.videoTrack || this?.users?.[this.identity]?.videoTrack
    }
  }
}
</script>
