import type { PluginObject } from "vue"

import { ConfirmMixin } from "@/mixins"

import DefaultInfo from "./components/DefaultInfo.vue"

export function info(
  this: Vue,
  message: string,
  title: string,
  forced: Boolean,
  exec: Function
) {
  const div = document.createElement("div")

  document.body.append(div)

  const instance = new DefaultInfo({
    propsData: { message, title, forced, exec }
  }).$mount() as InstanceType<typeof ConfirmMixin>

  div.appendChild(instance.$el)

  return instance.confirm().finally(() => {
    instance.$destroy()

    div.remove()
  })
}

const ConfirmPlugin: PluginObject<never> = {
  install(Vue) {
    const ConfirmMixin = Vue.extend({
      created() {
        this.$info = info
      }
    })

    Vue.mixin(ConfirmMixin)
  }
}

export default ConfirmPlugin
