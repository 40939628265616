import type { PluginObject } from "vue"
import type { Store } from "vuex"

import invariant from "invariant"

import Module, { MODULE_NAME } from "@/modules/theme/store"
import GetterTypes from "@/modules/theme/store/getter-types"
import { DEFAULT_STYLES, DEFAULT_IMAGES, DEFAULT_META } from "../constants"
import { StyleManager } from "../entities/StyleManager"
import { isColorDark } from "../utils"

enum Brightness {
  Dark,
  Light
}

export const ThemePlugin: PluginObject<{ store: Store<any> }> = {
  install(Vue, { store }) {
    store.registerModule(MODULE_NAME, Module)

    const instance = new Vue({
      computed: {
        styles() {
          return store.getters[`${MODULE_NAME}/${GetterTypes.STYLES}`]
        },
        brightness() {
          const styles = this.styles ?? DEFAULT_STYLES
          const result = Object.entries(styles).reduce((prev, [key, value]) => {
            // TODO(Andrew)
            // @ts-ignore
            prev[key] = isColorDark(value) ? Brightness.Dark : Brightness.Light
            return prev
          }, {} as Record<string, Brightness>)
          return result
        },
        scope() {
          return store.state.theme.scope
        }
      },
      watch: {
        styles: {
          handler(value) {
            console.log("🎨 Applying the styles...")
            StyleManager.set(value || DEFAULT_STYLES)
          },
          immediate: true
        },
        scope: {
          handler(value) {
            document.documentElement.setAttribute(
              "data-theme-scope",
              value.toLowerCase()
            )
          },
          immediate: true
        }
      }
    })

    Vue.prototype.__ThemePlugin__ = {
      instance
    }

    Vue.prototype.$theme = {
      get(color: string): string | undefined {
        const styles = store.getters[`${MODULE_NAME}/${GetterTypes.STYLES}`]
        if (styles && color in styles) {
          return styles[color]
        }

        return DEFAULT_STYLES[color]
      },
      getImage(image): string | undefined {
        const path =
          store.getters[`${MODULE_NAME}/${GetterTypes.IMAGES}`](image)

        return path ? path : DEFAULT_IMAGES[image]
      },
      getCustomImage(image: string | null | undefined): string[] {
        return (
          store.getters[`${MODULE_NAME}/${GetterTypes.IMAGES}`](image) ?? []
        )
      },
      /**
       * @type { <K extends keyof import('../types').ThemeMeta>(key: K) => (import('../types').ThemeMeta)[K]>}
       * @returns {T}
       */
      getMeta(key) {
        let value = store.getters[`${MODULE_NAME}/${GetterTypes.META}`](key)
        if (!value) {
          value = DEFAULT_META[key]
        }
        invariant(value !== undefined, `Unable to get theme meta. Key: ${key}`)
        return value
      },
      getBrightnessClass(key: string): "--dark" | "--light" {
        return instance.brightness[key] === Brightness.Dark
          ? "--dark"
          : "--light"
      }
    }
  }
}
