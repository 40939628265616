import type { Module } from "vuex"
import ORIENTATIONS from "@/components/GroupTeams/Mobile/orientations.js"

type state = {
  mobile: Boolean | null
  orientation: ORIENTATIONS | null
}

export type State = state

const Mobile: Module<State, unknown> = {
  namespaced: true,
  state: {
    mobile: null,
    orientation: null
  },
  mutations: {
    UPDATE_IS_MOBILE(state, payload) {
      state.mobile = payload
    },
    UPDATE_ORIENTATION(state, payload) {
      state.orientation = payload
    }
  },
  getters: {
    mobile: state => state.mobile,
    orientation: state => state.orientation
  }
}

export default Mobile
