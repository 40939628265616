<template>
  <div
    ref="container"
    class="resizable-text-container"
    :class="{ 'resizable-text-container--scrollbar': scrollbar }"
  >
    <h1
      class="resizable-text-container__resizable-text"
      :style="{ fontSize }"
      ref="text"
      v-html="localText"
    />
    <ResizeObserver v-if="update" @notify="fit" />
  </div>
</template>

<script>
import { TweenMax } from "gsap/TweenMax"
import { ResizeObserver } from "vue-resize"
import useEnlargedUI from "@/use/useEnlargedUI"

export default {
  name: "ResizableText",
  components: { ResizeObserver },
  data() {
    return {
      fontSize: 24,
      minFontSize: 16,
      scrollbar: false,
      localText: ""
    }
  },
  props: {
    message: String,
    // onmount animation on and off
    transition: {
      type: Boolean,
      default: true
    },
    // onmount animation delay
    delay: {
      type: Number,
      default: 0.8
    },
    // false makes it resize just once
    // on onmount event
    update: {
      type: Boolean,
      default: true
    },
    maxFont: {
      type: Number,
      default: 36
    }
  },
  setup() {
    const { enlargedUI, isViewerPresenter } = useEnlargedUI()
    return { enlargedUI, isViewerPresenter }
  },
  methods: {
    fit: _.debounce(function () {
      const {
        $refs: { text, container },
        minFontSize,
        maxFontSize,
        num
      } = this
      if (text && container) {
        let i = 0
        // num is a diff between min and max e.g. how many possible iterations
        // we need
        for (i = 0; i < num; i++) {
          if (
            text.scrollHeight <= container.clientHeight &&
            this.fontSize < maxFontSize
          ) {
            this.fontSize = this.fontSize + 1
            text.style.fontSize = this.fontSize + "px"
          } else {
            // if it's a match, stop iteration
            break
          }
        }
        // always try to do a step back
        for (i = 0; i < num; i++) {
          if (
            text.scrollHeight >= container.clientHeight ||
            (text.scrollWidth - 2 >= container.clientWidth &&
              this.fontSize > minFontSize)
          ) {
            this.fontSize = this.fontSize - 1
            text.style.fontSize = this.fontSize + "px"
          } else {
            if (text.scrollHeight > container.clientHeight + 2) {
              this.scrollbar = true
            } else {
              this.scrollbar = false
            }
            break
          }
        }
      }
    }, 500)
  },
  computed: {
    maxFontSize() {
      return this.isViewerPresenter
        ? this.enlargedUI
          ? 100
          : 46
        : this.maxFont
    },
    num() {
      return this.maxFontSize - this.minFontSize
    }
  },
  watch: {
    localText(newValue, oldValue) {
      if (newValue === oldValue) return
      this.fit()
    },
    message(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        if (this.transition) {
          try {
            TweenMax.killTweensOf(this.$refs.text)
            TweenMax.to(this.$refs.text, 0.5, {
              opacity: 0,
              onComplete: () => {
                this.localText = newValue
                TweenMax.to(this.$refs.text, 0.5, { opacity: 1, delay: 0.25 })
              }
            })
          } catch (e) {
            console.warn(e.message)
            this.localText = newValue
          }
        } else {
          this.localText = newValue
        }
      }
    }
  },
  mounted() {
    this.localText = this.message

    if (this.transition) {
      this.$nextTick(() => {
        TweenMax.from(this.$refs.text, 0.3, {
          opacity: 0,
          delay: this.delay
        })
      })
    }

    this.$nextTick(() => this.fit())
  },
  beforeDestroy() {
    TweenMax.killTweensOf(this.$refs.text)
  }
}
</script>

<style lang="scss">
.resizable-text-container {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  &--scrollbar {
    overflow: auto;
    align-items: flex-start;
    min-height: 1.2em;
  }

  &__resizable-text {
    line-height: 1.2em;
    user-select: none;
  }

  ol,
  ul {
    padding-left: 30px;
    li {
      text-align: left;
      margin-bottom: 2px;
    }
  }
}
</style>
