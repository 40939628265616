var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LoginBadge", { staticClass: "powered-by" }, [
    _c("span", { staticClass: "powered-by__powered-by" }, [
      _vm._v("Powered by"),
    ]),
    _c("img", {
      staticClass: "powered-by__weve-logo ml-3",
      attrs: {
        src: require("@/assets/login/weve-logo-full.svg"),
        alt: "Weve Logo",
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }