import Vue from "vue"
import Vuex from "vuex"
import { db } from "@/firebase"
import _ from "lodash"
import { vuexfireMutations } from "vuexfire"
import { Role } from "@/helpers"

import { OrgService } from "@/services/org.service"

import missionModeSequence from "@shared/MissionModeSequence"

import ScreenCapture from "./ScreenCapture"
import GameUsers from "./GameUsers"
import LiveChat from "./LiveChat"
import Profile from "./Profile"
import PreGame from "./PreGame"
import UserSearch from "./UserSearch"
import AuthModule from "./AuthModule"
import ChatModule from "./ChatModule"
import MissionModule from "./MissionModule"
import GamesModule from "./GamesModule"
import OrgModule from "./OrgModule"
import ClientModule from "./ClientModule"
import PlayModule from "./PlayModule"
import DrawingModule from "./DrawingModule"
import GroupModule from "./GroupModule"
import SoundEffectModule from "./SoundEffectModule"
import TwilioModule from "./TwilioModule"
import RecordingModule from "./RecordingModule"
import ScreenshotModule from "./ScreenshotModule"
import UserSettings from "./UserSettings"
import TournamentModule from "./TournamentModule"
import { DEFAULT_LATERCOMER_YOUTUBE_CODE } from "@/config"
import TwilioStream from "./TwilioStream"
import Mobile from "./Mobile.ts"
import JeopardAi from "./JeopardAi"

import Mode from "@shared/enums/Mode"
import User from "@shared/User"
import TEAM_ICONS from "@shared/team-icons"
import PlayType from "@shared/enums/PlayType"
import { isIRLUser } from "@shared/helpers/isIRL"

let gameSubscriptionRef = null

Vue.use(Vuex)

const plugins = []

export const GetterTypes = {
  LATECOMER_VIDEO: "LATECOMER_VIDEO",
  END_EARLY_VIDEO: "END_EARLY_VIDEO"
}

export const MutationTypes = {
  SET_ORG: "SET_ORG"
}

export const ActionTypes = {
  GET_CURRENT_ORG: "GET_CURRENT_ORG"
}

const store = new Vuex.Store({
  plugins: plugins,
  modules: {
    JeopardAi,
    TwilioStream,
    Mobile,
    ScreenCapture: ScreenCapture,
    GameUsers: GameUsers,
    livechat: LiveChat,
    profile: Profile,
    pregame: PreGame,
    allusers: UserSearch,
    auth: AuthModule,
    chat: ChatModule,
    mission: MissionModule,
    Games: GamesModule,
    org: OrgModule,
    client: ClientModule,
    play: PlayModule,
    drawing: DrawingModule,
    group: GroupModule,
    soundeffect: SoundEffectModule,
    twilio: TwilioModule,
    recording: RecordingModule,
    screenshot: ScreenshotModule,
    UserSettings: UserSettings,
    tournament: TournamentModule
  },
  state: {
    IRLSpeakers: [],
    showScreenCaptureDialog: false,
    origin: window.location.origin || "",
    time: 0,
    loading: false,
    error: null,
    drawer: false,
    audioDrawer: false,
    gameFtuxStep: false,
    settingsBar: false,
    hotkeysBar: false,
    user: {},
    typicalModes: missionModeSequence,
    playTypes: Object.values(PlayType),
    vimeoUrl: "https://player.vimeo.com/video/",
    teamIcons: TEAM_ICONS,
    gameID: 0,
    orgID: "-LakAFwa9dpR59e8YlON",
    game: null,
    actualGame: null,
    actualGameID: null,
    teamID: 0,
    developer: false,
    theOrg: null,
    showOnboardingPlayers: false,
    isNoteDialogVisible: false,
    isProModerator: false,
    showProToolbarForModerators: false,
    heartbeatCheckTime: 0
  },
  mutations: {
    SET_IRL_SPEAKERS(state, payload) {
      state.IRLSpeakers = payload ?? []
    },
    SET_IS_NOTE_DIALOG_VISIBLE(state, payload) {
      state.isNoteDialogVisible = payload
    },
    SET_SHOW_PRO_TOOLBAR_FOR_MODERATORS(state, payload) {
      state.showProToolbarForModerators = payload
    },
    UPDATE_SHOW_ONBOARDING_PLAYERS(state, payload) {
      state.showOnboardingPlayers = payload
    },
    UPDATE_SHOW_SCREEN_CAPTURE_DIALOG(state, payload) {
      state.showScreenCaptureDialog = payload
    },
    UPDATE_TIME(state, payload) {
      state.time = payload
    },
    SET_HEARTBEAT_CHECK_TIME(state, payload) {
      state.heartbeatCheckTime = payload
    },
    SET_USER_GAME_ID(state, gameID) {
      state.gameID = gameID
    },
    SET_GAME(state, payload) {
      state.game = payload
    },
    SET_USER_ACTUAL_GAME_ID(state, gameID) {
      state.actualGameID = gameID
    },
    SET_ACTUAL_GAME(state, payload) {
      state.actualGame = payload
    },
    SET_ORG_ID(state, payload) {
      state.orgID = payload
    },
    SET_HOTKEYS_BAR(state, value) {
      state.hotkeysBar = value
    },
    [MutationTypes.SET_ORG](state, payload) {
      state.theOrg = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    clearError(state) {
      state.error = null
    },
    setDrawer(state, payload) {
      state.drawer = payload
    },
    setAudioDrawer(state, payload) {
      state.audioDrawer = payload
    },
    setSettingsBar(state, payload) {
      state.settingsBar = payload
    },
    UPDATE_DEVELOPER_MODE(state, payload) {
      state.developer = payload
    },
    setGameFtuxStep(state, payload) {
      state.gameFtuxStep = payload
    },
    ...vuexfireMutations
  },
  actions: {
    updateShowScreenCaptureDialog({ commit }, payload) {
      commit("UPDATE_SHOW_SCREEN_CAPTURE_DIALOG", payload)
    },
    updateShowOnboardingPlayers({ commit }, payload) {
      commit("UPDATE_SHOW_ONBOARDING_PLAYERS", payload)
    },
    toggleDeveloperMode({ commit, state }) {
      commit("UPDATE_DEVELOPER_MODE", !state.developer)
    },
    async bringBackActualGame({ commit, state }) {
      commit("SET_GAME", state.actualGame)
      commit("SET_USER_GAME_ID", state.actualGameID)
    },
    async setLocalGame({ commit }, payload) {
      commit("SET_GAME", payload)
      commit("SET_USER_GAME_ID", payload?.id)
    },
    async setLocalGameID({ commit }, payload) {
      commit("SET_USER_GAME_ID", payload)
    },
    setDrawer({ commit }, payload) {
      commit("setDrawer", payload)
    },
    setAudioDrawer({ commit }, payload) {
      commit("setAudioDrawer", payload)
    },
    setSettingsBar({ commit }, payload) {
      commit("setSettingsBar", payload)
    },
    async setOrgID({ commit, dispatch }, orgID) {
      await dispatch("setOrg", orgID)
      commit("SET_ORG_ID", orgID)
    },
    async setOrg({ commit }, orgID) {
      const snapshot = await db.ref("orgs/" + orgID).once("value")

      commit(MutationTypes.SET_ORG, snapshot.val())
    },
    [ActionTypes.GET_CURRENT_ORG]({ state, commit }) {
      return OrgService.getOrg(state.orgID).then(org => {
        commit(MutationTypes.SET_ORG, org)
      })
    },
    subscribeToTheGameID({ commit }, { gameID, orgID }) {
      if (gameSubscriptionRef) gameSubscriptionRef.off("value")

      gameSubscriptionRef = db.auxiliary().ref(`org/${orgID}/games/${gameID}`)

      commit("SET_USER_GAME_ID", gameID)
      commit("SET_USER_ACTUAL_GAME_ID", gameID)

      return new Promise(resolve => {
        gameSubscriptionRef.on("value", snapshot => {
          const value = snapshot.val() || {}
          value.id = gameID
          value.theKey = gameID
          commit("SET_GAME", value)
          commit("SET_ACTUAL_GAME", value)
          resolve()
        })
      })
    },
    updateHost(_, payload) {
      const serialized = JSON.parse(JSON.stringify(payload))
      const { id: userID } = serialized
      return db.ref(`org/1/users/${userID}`).update(serialized)
    },
    clearError({ commit }) {
      commit("clearError")
    },
    toggleAutopilot({ rootState, rootGetters }) {
      const { id, firstname, lastname } = rootGetters["auth/user"]
      const isHost = rootGetters["auth/isHost"]
      const { game, orgID, gameID } = rootState
      const data = { autopilot: !game.autopilot }

      if (!isHost) {
        data.autopilotUpdatedBy = {
          id,
          name: `${firstname} ${lastname}`,
          createdAt: new Date().toISOString()
        }
      }

      if (isHost && !data.autopilot) {
        data.hostless2 = false
      }

      db.auxiliary().ref(`org/${orgID}/games/${gameID}`).update(data)
    },
    setGameModeratorID({ rootState, rootGetters }, userID = null) {
      const { orgID, gameID } = rootState

      const update = {
        [`games/${gameID}/moderatorID`]: userID
      }

      if (userID && rootGetters.getCurrentMode === Mode.Meeting) {
        update[`game/${gameID}/gameStatus/currentMode`] = Mode.Welcome
      }

      return db.auxiliary().ref(`org/${orgID}`).update(update)
    },
    toggleGameModeratorID({ rootState }, userID = null) {
      const { orgID, gameID } = rootState
      return db
        .auxiliary()
        .ref(`org/${orgID}/games/${gameID}/moderatorID`)
        .transaction(val => (val === userID ? null : userID))
    },
    updateUser(_, { userID, obj }) {
      return db.ref(`org/1/users/${userID}`).update(obj)
    },
    async updateUsersInBulk(_, { array }) {
      const ref = db.auxiliary().ref(`org/1/users`)
      const update = {}

      array.forEach(({ userID, collection }) => {
        if (!userID) return console.warn("Cannot update without a userID")
        if (!Array.isArray(collection))
          return console.warn("Got no data to update")
        collection.forEach(({ key, value }) => {
          if (!key || value === undefined)
            return console.warn("Got corrupt data to update")
          update[`/${userID}/${key}`] = value
        })
      })

      await ref.update(update)
    },
    async deletePlayer({ dispatch }, { userID }) {
      await dispatch("updateUser", { userID, obj: { gameID: null } })
    },
    updateGameFtuxStep({ commit }, payload) {
      commit("setGameFtuxStep", payload)
    }
  },
  getters: {
    moderatorID: state => state.actualGame?.moderatorID,
    showScreenCaptureDialog: state => state.showScreenCaptureDialog,
    showOnboardingPlayers: state => state.showOnboardingPlayers,
    isHostToolsVisible(state, getters) {
      return (
        getters.user.role === Role.Host ||
        state.isProModerator ||
        (getters["auth/isModerator"] && state.showProToolbarForModerators)
      )
    },
    [GetterTypes.LATECOMER_VIDEO](state, _, rootState, rootGetters) {
      const key = "latecomerVideoCode"

      const { game } = rootState
      if (game?.[key]) return { origin: "game", code: game[key] }

      const client = rootGetters["auth/client"]
      if (client?.[key]) return { origin: "client", code: client[key] }

      const { theOrg: org } = state
      if (org && org[key]) return { origin: "org", value: org[key] }

      return { origin: "default", code: DEFAULT_LATERCOMER_YOUTUBE_CODE }
    },
    [GetterTypes.END_EARLY_VIDEO](state, _, rootState, rootGetters) {
      const key = "earlyEndVideo"

      const { game } = rootState
      if (game?.[key]) return { origin: "game", code: game[key] }

      const client = rootGetters["auth/client"]
      if (client?.[key]) return { origin: "client", code: client[key] }

      return false
    },
    time(state) {
      return state.time
    },
    urlID(state, { actualGameID: gameID }, rootState, rootGetters) {
      const hasPreGame = rootGetters["auth/hasPreGame"]
      const clientID = rootGetters["auth/clientID"]
      if (hasPreGame && gameID) {
        return `${clientID}${gameID}`
      } else {
        return `${clientID}`
      }
    },
    user(state, getters, rootState, rootGetters) {
      return rootGetters["auth/user"]
    },
    teamID(staet, { user }) {
      return user ? user.teamID : null
    },
    team(state, { teamID }, rootState, { chats }) {
      return chats ? chats[teamID] : null
    },
    isScribe(state, { user }, rootState) {
      if (rootState.livechat.roomID) return true
      return user ? user.selected : false
    },
    drawer(state) {
      return state.drawer
    },
    audioDrawer(state) {
      return state.audioDrawer
    },
    settingsBar(state) {
      return state.settingsBar
    },
    loading(state) {
      return state.loading
    },
    error(state) {
      return state.error
    },
    typicalModes(state) {
      return state.typicalModes
    },
    playTypes(state) {
      return state.playTypes
    },
    gameID(state) {
      return state.gameID
    },
    actualGameID(state) {
      return state.actualGameID
    },
    orgID(state) {
      return state.orgID
    },
    theOrg(state) {
      return state.theOrg
    },
    game(state) {
      return state.game
    },
    isAutopilotOn(state) {
      return state?.game?.autopilot
    },
    autopilotUpdatedBy(state) {
      return state?.game?.autopilotUpdatedBy
    },
    isHostless(state) {
      return state?.game?.hostless2
    },
    isDIYGame(state) {
      return state?.game?.diy
    },
    actualGame(state) {
      return state.actualGame
    },
    teamIcons(state) {
      return state.teamIcons
    },
    gameHost(_, getters) {
      return getters.gameHosts[0]
    },
    isUserMuted(state, _, __, rootGetters) {
      const isUserMutedByRoomLogic = rootGetters["group/isUserMutedByRoomLogic"]
      const IRLSpeakers = state.IRLSpeakers ?? []
      const isMutedIRLUser = user =>
        isIRLUser(user, rootGetters.game) &&
        IRLSpeakers.every(({ id }) => id !== user.id)

      if (!rootGetters["auth/isHybridRoom"])
        return user => User.isMuted(user) || isUserMutedByRoomLogic(user)

      return user =>
        User.isMuted(user) ||
        isUserMutedByRoomLogic(user) ||
        isMutedIRLUser(user)
    },
    gameHosts(state, getters, rootState, rootGetters) {
      const users = rootGetters["GameUsers/usersArray"]
      const moderatorID = getters.moderatorID
      const gameHostID = state.actualGame?.hostUserID
      const isUserModerator = user => user?.id === moderatorID
      const isGameHost = user => user?.id === gameHostID
      const isHostLike = user => User.isHost(user) || isUserModerator(user)
      const isUserAlive = rootGetters.isUserAlive
      return _.chain(users)
        .filter(isHostLike)
        .orderBy("loginTimestamp", ["desc"])
        .sortBy(user => !isGameHost(user))
        .sortBy(user => !User.isSelectingTeam(user))
        .sortBy(user => !User.isPlaying(user))
        .sortBy(user => !isUserModerator(user))
        .sortBy(user => !isUserAlive(user))
        .value()
    },
    onlineUsersGroupedByTeam(_, __, ___, rootGetters) {
      return rootGetters["GameUsers/usersOnlineArray"].reduce((acc, val) => {
        if (acc[val.teamID]) {
          acc[val.teamID].push(val)
        } else {
          acc[val.teamID] = [val]
        }
        return acc
      }, {})
    },
    onlineUsersArray(_, __, ___, rootGetters) {
      return rootGetters["GameUsers/usersOnlineArray"]
    },
    getGameFtuxStep(state) {
      return state.gameFtuxStep
    },
    isUserAlive(state) {
      return user => User.isAlive(user, state.heartbeatCheckTime)
    }
  }
})

if (module.hot) {
  module.hot.accept(
    [
      "./JeopardAi",
      "./ScreenCapture",
      "./AuthModule",
      "./ChatModule",
      "./MissionModule",
      "./PlayModule",
      "./DrawingModule",
      "./GroupModule",
      "./SoundEffectModule",
      "./GamesModule",
      "./TwilioModule",
      "./UserSearch",
      "./PreGame",
      "./Profile",
      "./LiveChat",
      "./RecordingModule",
      "./GameUsers",
      "./ScreenshotModule",
      "./UserSettings",
      "./TwilioStream"
    ],
    () => {
      store.hotUpdate({
        modules: {
          JeopardAi: require("./JeopardAi").default,
          ScreenCapture: require("./ScreenCapture").default,
          GameUsers: require("./GameUsers").default,
          livechat: require("./LiveChat").default,
          profile: require("./Profile").default,
          pregame: require("./PreGame").default,
          allusers: require("./UserSearch").default,
          auth: require("./AuthModule").default,
          chat: require("./ChatModule").default,
          mission: require("./MissionModule").default,
          play: require("./PlayModule").default,
          drawing: require("./DrawingModule").default,
          group: require("./GroupModule").default,
          soundeffect: require("./SoundEffectModule").default,
          recording: require("./RecordingModule").default,
          Games: require("./GamesModule").default,
          twilio: require("./TwilioModule").default,
          screenshot: require("./ScreenshotModule").default,
          UserSettings: require("./UserSettings").default,
          TwilioStream: require("./TwilioStream").default
        }
      })
    }
  )
}

export { store }
