import Vue from "vue"

const isOpen = Vue.observable({ value: false })

export default isOpen

export const open = () => {
  isOpen.value = true
}

export const close = () => {
  isOpen.value = false
}
