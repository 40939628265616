
/**
 * Wrapper over `vue-select`
 * See `vue-select` for avalilable props
 */

import Vue, { PropType, VNode } from "vue"
import mixins from "vue-typed-mixins"
import VueSelect from "vue-select"
import { ValidationProvider } from "vee-validate"
import { uniqBy } from "lodash"

import SvgIcon from "../../base/SvgIcon.vue"

import { IdentifiableMixin, ValidateableMixin } from "../mixins"

import FormControlLabel from "../FormControlLabel/FormControlLabel.vue"
import FormControlError from "../FormControlError/FormControlError.vue"

import { SIZES, VARIANTS } from "./types"

import { SELECTED_OPTION_SELECTOR } from "./constants"

export type Refs = {
  select: InstanceType<typeof VueSelect>
}

export default mixins(
  Vue.extend(IdentifiableMixin),
  Vue.extend(ValidateableMixin)
).extend({
  name: "RTBSelect",
  inheritAttrs: false,
  props: {
    value: {},
    options: {
      type: Array,
      required: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    identity: {
      type: String,
      default: "id"
    },
    optionText: {
      type: String,
      default: "label"
    },
    size: {
      type: String as PropType<typeof SIZES[number]>,
      default: "default" as const,
      validator: val => SIZES.includes(val)
    },
    variant: {
      type: String as PropType<typeof VARIANTS[number]>,
      default: "default" as const,
      validator: val => VARIANTS.includes(val)
    },
    autoscroll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    normalizedOptions(): any[] {
      return uniqBy(this.options, option =>
        typeof option === "object" && option !== null
          ? option[this.identity]
          : option
      )
    }
  },
  methods: {
    onSearchFocus(...args) {
      this.$listeners["search:focus"]?.(...args)
      this.$nextTick(() => {
        const { dropdownMenu } = this.$refs.select.$refs
        if (dropdownMenu !== undefined) {
          const selectedOption = dropdownMenu.querySelector(
            SELECTED_OPTION_SELECTOR
          )
          if (selectedOption !== null) {
            dropdownMenu.scrollTop = selectedOption.offsetTop - 8
          }
        }
      })
    }
  },
  render(h): VNode {
    return h(ValidationProvider, {
      props: { tag: "div", rules: this.rules, name: this.label },
      class: {
        [`rtb-select--size--${this.size}`]: this.size !== "default",
        [`rtb-select--variant--${this.variant}`]: true,
        "rtb-select--with-append-slot": this.$slots.append
      },
      staticClass: "rtb-select",
      scopedSlots: {
        default: ({ errors, invalid }) => {
          const [validationError] = errors

          return [
            h(
              "div",
              {
                staticClass: "rtb-select__top",
                class: { "sr-only": this.hideLabel }
              },
              [
                this.$slots.help,
                h(
                  FormControlLabel,
                  {
                    attrs: { for: this.id },
                    staticClass: "rtb-select__label"
                  },
                  this.label
                )
              ]
            ),
            h("div", { staticClass: "rtb-select__inner" }, [
              // @ts-ignore-error
              h(VueSelect, {
                ref: "select",
                props: {
                  ...this.$attrs,
                  value: this.value,
                  options: this.normalizedOptions,
                  clearable: this.clearable,
                  reduce: option => {
                    if (typeof option === "object" && option !== null) {
                      return option[this.identity]
                    }
                    return option
                  },
                  inputId: this.id,
                  label: this.optionText,
                  autoscroll: this.autoscroll
                },
                class: {
                  "rtb-select__field--invalid": invalid
                },
                scopedSlots: {
                  ...this.$scopedSlots,
                  "selected-option": option => {
                    return h("span", { staticClass: "d-block rtb-truncate" }, [
                      this.$scopedSlots["selected-option"]
                        ? this.$scopedSlots["selected-option"]({ item: option })
                        : option[this.optionText]
                    ])
                  },
                  "open-indicator": ({ attributes }) => {
                    return h(SvgIcon, {
                      props: { name: "caret-down-solid" },
                      staticClass: "rtb-select__open-indicator"
                    })
                  }
                },
                on: {
                  ...this.$listeners,
                  "search:focus": this.onSearchFocus
                }
              }),
              this.$slots.append
                ? h(
                    "div",
                    { staticClass: "rtb-select__append" },
                    this.$slots.append
                  )
                : null
            ]),
            validationError
              ? h(FormControlError, undefined, validationError)
              : null
          ]
        }
      }
    })
  }
})
