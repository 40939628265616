import { db } from "@/firebase"
import User from "@shared/User"
import _ from "lodash"
import { UserFilter } from "../helpers"
import instance from "./axios.service"

const fetchUser = async ({ userID }) => {
  const snapshot = await getUserRef({ userID }).once("value")
  return snapshot.val()
}

const fetchUserIDByEmail = async email => {
  const snapshot = await db
    .ref(`users/private`)
    .orderByChild("email")
    .equalTo(email)
    .limitToFirst(1)
    .once("value")
  return Object.keys(snapshot.val() || {})[0]
}

const fetchUserEmail = async userID => {
  const snapshot = await db.ref(`users/private/${userID}/email`).once("value")
  return snapshot.val()
}

const fetchUsersByRole = async role => {
  const snapshot = await db
    .ref(`org/1/users`)
    .orderByChild("role")
    .equalTo(role)
    .once("value")
  return snapshot.val()
}

const updateUser = async ({ userID, obj }) => getUserRef({ userID }).update(obj)

const getGameUsersRef = ({ gameID }) => {
  return db
    .auxiliary()
    .ref(`org/1/users`)
    .orderByChild("gameID")
    .equalTo(gameID)
}

const getUserRef = ({ userID }) => db.ref(`org/1/users/${userID}`)

const getOnlineUsersCountByClientID = async clientID => {
  const ref = db
    .auxiliary(clientID)
    .ref("org/1/users")
    .orderByChild("clientID")
    .equalTo(clientID)

  const snapshot = await ref.once("value")
  const users = snapshot.val() || {}
  // TODO:
  // same how to count user online users here
  const array = Object.values(users).filter(
    user => !UserFilter.hostPredicate(user) && User.isAlive(user)
  )

  return array.length
}
export default class UserService {
  static updateScribe(user, value) {
    if (user?.id == null) return

    if (value === true)
      return db
        .auxiliary()
        .ref(`org/1/users/${user.id}`)
        .update({ selected: true, muted: false })

    return db.auxiliary().ref(`org/1/users/${user.id}/selected`).set(value)
  }
  static updateHeadphones(user, value) {
    return db.ref(`org/1/users/${user?.id}/headphones`).set(value)
  }
  static updateMonitoring(user, value) {
    return db.ref(`org/1/users/${user?.id}/monitoring`).set(value)
  }
  static updateTeamId(user, teamId) {
    return db
      .auxiliary()
      .ref(`org/1/users/${user?.id}/teamID`)
      .set(teamId ?? null)
  }
  static keepAlive(user, path) {
    return db
      .auxiliary()
      .ref(`org/1/users/${user?.id}`)
      .update({
        heartbeat: Date.now(),
        path: path ?? null
      })
  }
  static updateMobileState(id, value) {
    return db.ref(`org/1/users/${id}/mobile`).set(Boolean(value))
  }
  static remove(...args) {
    const promises = []

    for (const arg of args) {
      promises.push(
        instance({
          method: "delete",
          url: `/user/${arg}`
        })
      )
    }

    return Promise.all(promises)
  }
  static async create(data) {
    if (data.headless) {
      const res = await instance({
        method: "post",
        url: `/headless-user`,
        data
      })
      return res?.data?.user
    }
  }
}

export {
  fetchUser,
  updateUser,
  getUserRef,
  getGameUsersRef,
  getOnlineUsersCountByClientID,
  fetchUsersByRole,
  fetchUserEmail,
  fetchUserIDByEmail
}
