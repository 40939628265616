import HubSpot from "@/services/hubspot.service"

export default {
  computed: {
    $_LoginMixin_audit() {
      if (this.$route?.query.audit === "1") return true
      if (this.$route?.query.audit === "0") return false
      return null
    },
    $_LoginMixin_firstname() {
      return this.$route?.query.firstname || this.$route?.query.FN
    },
    $_LoginMixin_lastname() {
      return this.$route?.query.lastname || this.$route?.query.LN
    },
    $_LoginMixin_imageUrl() {
      return this.$route?.query.image
    }
  },
  methods: {
    $_LoginMixin_maybeTriggerHubspotDemoEvent(accountType = "") {
      const client = this.$store.state.auth?.client
      if (client?.demo) {
        HubSpot.push([
          "trackCustomBehavioralEvent",
          {
            name: "pe2803895_app_weve_com___demo",
            properties: {
              account_type: accountType
            }
          }
        ])
      }
    }
  }
}
