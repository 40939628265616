




































import type { PropType } from "@vue/composition-api"
import { defineComponent, toRefs, computed } from "@vue/composition-api"

import { UserRole } from "@/types"
import User from "@shared/User"
import { useViewer, useUser, useDominantSpeaker } from "@/use"
import useStore from "@/use/useStore"
import UserSoundService from "@/services/user-sound.service"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia.vue"
import { getName } from "@/components/GroupTeams/Common/User/User.util"

import { UserCardColor, UserCardState } from "./UserCard.types"
import UserCard from "./UserCard"
import type { UserForAppUserCard } from "./AppUserCard.types"
import { Mutable } from "./AppUserCard.types"
import MissionType from "@shared/enums/Mission"

export default defineComponent({
  name: "AppUserCard",
  components: {
    UserMedia,
    UserCard
  },
  props: {
    user: {
      type: Object as PropType<UserForAppUserCard>,
      required: true
    },
    dominantable: {
      type: Boolean,
      default: false
    },
    notifiable: {
      type: Boolean,
      default: false
    },
    mutable: {
      type: Boolean,
      default: undefined
    },
    skippable: {
      type: Boolean,
      default: undefined
    },
    selectable: {
      type: Boolean,
      default: false
    },
    submitted: {
      type: Boolean,
      default: false
    },
    points: {
      type: Number,
      default: undefined
    },
    mediaHidden: {
      type: Boolean,
      default: false
    },
    mediaDisabled: {
      type: Boolean,
      default: false
    },
    mutableBy: {
      type: Number as PropType<Mutable>,
      default: Mutable.ByHost,
      validator: (val: Mutable) => Object.values(Mutable).includes(val)
    },
    videoTrack: Object
  },
  setup(props) {
    const { user, dominantable, selectable, mutable, skippable } = toRefs(props)

    const { store } = useStore()
    const { viewer } = useViewer()
    const { update } = useUser(user)
    const speaker = useDominantSpeaker()

    const isMobileHybrid = computed(() => store.getters.game?.mobileHybrid)
    const isHost = computed(() => store.getters["auth/isHost"])
    const isModerator = computed(() => store.getters["auth/isModerator"])
    const isViewerMobile = computed(() => User.isMobile(viewer.value))

    const mobile = computed(() => User.isMobile(user.value))

    const teamName = computed(
      () => store.getters.chats?.[user.value.teamID]?.name
    )

    const name = computed(() => {
      if (mobile.value && isMobileHybrid.value) {
        return teamName.value
      }

      return getName(user.value.firstname, user.value.lastname)
    })

    const current = computed(() => viewer.value.id === user.value.id)

    const muted = computed(() => User.isMuted(user.value))

    const mutedGlobally = computed(() => store.getters.isUserMuted(user.value))

    const selected = computed(() => selectable.value && user.value?.selected)

    const jeopardai = computed(() => selectable.value && user.value?.jeopardai)

    const dominant = computed(() => {
      if (dominantable.value === false) return false
      return user.value.id === speaker.id.value
    })

    const color = computed(() => {
      if (mutedGlobally.value) return UserCardColor.Gray

      if (dominant.value) {
        // todo
        // until we have consistency with users cards within the room
        return UserCardColor.Success
      }

      if (store.getters.getCurrentMission?.behavior === MissionType.JeopardAI) {
        if (selected.value) return UserCardColor.JeopardAi
        if (jeopardai.value) return UserCardColor.Primary
      } else {
        if (selected.value) return UserCardColor.Primary
      }

      return UserCardColor.Default
    })

    const onboarding = computed(() => User.isOnboarding(user.value))

    const canMute = computed(() =>
      mutable.value === undefined
        ? // user.value.id === viewer.value.id ||
          viewer.value.role === UserRole.Host || isModerator.value
        : mutable.value
    )

    const configurable = computed(() => viewer.value.role === UserRole.Host)

    function toggleMuted() {
      UserSoundService.toggle({ id: user.value.id })
    }

    const states = computed(() => {
      const res: Set<UserCardState> = new Set()
      if (muted.value) {
        res.add(UserCardState.Muted)
      }
      if (mobile.value && !isViewerMobile.value) {
        res.add(UserCardState.Mobile)
      }
      if (skippable.value && isHost.value && user.value.skip) {
        res.add(UserCardState.Skip)
      }
      return res
    })

    const imageUrl = computed(() => {
      if (!user.value.image) return false

      return user.value.image.includes("http")
        ? user.value.image
        : require(`@/assets/avatars/${user.value.image}`)
    })

    return {
      jeopardai,
      imageUrl,
      speaker,
      name,
      current,
      color,
      states,
      onboarding,
      selected,
      canMute,
      configurable,
      toggleMuted,
      configure: update
    }
  }
})
