<template>
  <video preload="none" ref="video" @canplay="$emit('ready')" />
</template>

<script>
export default {
  name: "UserVideo",
  props: {
    track: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.track.attach(this.$refs.video)
  },
  beforeDestroy() {
    this.track.detach(this.$refs.video)
    this.$refs.video && this.$kill(this.$refs.video)
  }
}
</script>
<style scoped>
video {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  object-fit: cover;
  margin: -1px;
}
</style>
