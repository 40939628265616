var render = function () {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.team
    ? _c(
        "div",
        {
          staticClass: "team-tag",
          class: _vm.computedClass,
          on: { mouseenter: _vm.onMouseEnter, mouseleave: _vm.onMouseLeave },
        },
        [
          _c(
            "div",
            {
              staticClass: "team-tag__icon mr-2",
              class:
                ((_obj = {}),
                (_obj["team-tag__icon--special-purpose"] =
                  _vm.isSpecialPurpose),
                _obj),
            },
            [
              _vm.isViewerHostLike && _vm.pinnable
                ? [
                    _vm.hovered || _vm.isPinnedTeam
                      ? [
                          _vm.isPinnedTeam
                            ? _c("SvgIcon", {
                                staticClass:
                                  "team-tag__pin-icon team-tag__pin-icon--unpin",
                                attrs: {
                                  width: "16",
                                  height: "16",
                                  name: "unpin",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.unpin()
                                  },
                                },
                              })
                            : _c("SvgIcon", {
                                staticClass:
                                  "team-tag__pin-icon team-tag__pin-icon--pin",
                                attrs: {
                                  width: "16",
                                  height: "16",
                                  name: "pin",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.pin()
                                  },
                                },
                              }),
                        ]
                      : _vm._e(),
                    _c(
                      "v-icon",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            if (
                              $event.ctrlKey ||
                              $event.shiftKey ||
                              $event.altKey ||
                              $event.metaKey
                            ) {
                              return null
                            }
                            return _vm.resetMissionTeam()
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.team.icon) + " ")]
                    ),
                  ]
                : [_c("v-icon", [_vm._v(" " + _vm._s(_vm.team.icon) + " ")])],
            ],
            2
          ),
          _vm.isTeamSelectable
            ? [
                _c(
                  "div",
                  {
                    staticClass: "team-tag__title mr-2",
                    class:
                      ((_obj$1 = {}),
                      (_obj$1["team-tag__title--special-purpose"] =
                        _vm.isSpecialPurpose),
                      _obj$1),
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.select()
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.team.name) + " ")]
                ),
              ]
            : [
                _c(
                  "div",
                  {
                    staticClass: "team-tag__title mr-2",
                    class:
                      ((_obj$2 = {}),
                      (_obj$2["team-tag__title--special-purpose"] =
                        _vm.isSpecialPurpose),
                      _obj$2),
                  },
                  [_vm._v(" " + _vm._s(_vm.team.name) + " ")]
                ),
              ],
          _c(
            "transition",
            {
              attrs: { name: "team-tag__scale-in-transition", mode: "out-in" },
            },
            [
              !!_vm.nOfStrikes
                ? _c(
                    "div",
                    {
                      key: "strike-" + _vm.nOfStrikes,
                      staticClass: "team-tag__tries",
                    },
                    _vm._l(_vm.strikes, function (strike) {
                      return _c("SvgIcon", {
                        key: strike.key,
                        style: strike.color ? "color: " + strike.color : null,
                        attrs: { name: strike.name, width: "13", height: "13" },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ]
          ),
          !_vm.isSpecialPurpose && _vm.score
            ? [
                _vm.isViewerHostLike
                  ? _c(
                      "div",
                      {
                        staticClass: "team-tag__points",
                        on: {
                          click: [
                            function ($event) {
                              if (
                                $event.ctrlKey ||
                                $event.shiftKey ||
                                $event.altKey ||
                                $event.metaKey
                              ) {
                                return null
                              }
                              return _vm.increaseScore()
                            },
                            function ($event) {
                              if (!$event.altKey) {
                                return null
                              }
                              return _vm.decreaseScore()
                            },
                            function ($event) {
                              if (!$event.ctrlKey) {
                                return null
                              }
                              return _vm.decreaseScore()
                            },
                            function ($event) {
                              if (!$event.shiftKey) {
                                return null
                              }
                              return _vm.toggleFineTunePoints()
                            },
                          ],
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.teamScore)),
                        _c("span", { staticClass: "team-tag__points__pts" }, [
                          _vm._v("PTS"),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.fineTunePoints
                  ? [
                      _c("span", {
                        staticClass: "team-tag__minus team-tag__control",
                        on: {
                          click: function ($event) {
                            return _vm.decreaseScore(1)
                          },
                        },
                      }),
                      _c("span", {
                        staticClass: "team-tag__plus team-tag__control",
                        on: {
                          click: function ($event) {
                            return _vm.increaseScore(1)
                          },
                        },
                      }),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.submittedIndictor && _vm.isTeamSubmitted
            ? _c(
                "span",
                { staticClass: "team-tag__submitted" },
                [_c("StarIcon")],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }