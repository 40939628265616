const { v4: uuidv4 } = require("uuid")

const Collection = require("./Collection")

class Answer extends Collection {
  constructor(args) {
    super()
    this.id = uuidv4()
    this.points = 0
    this.score = 0
    this.timestamp = Date.now()
    this.time = Math.round(Date.now() / 1000)

    for (const key in args) this[key] = args[key]
  }
}

module.exports = Answer
