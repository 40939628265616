var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "im-result", attrs: { "d-flex": "" } },
    [
      _c(
        "transition-group",
        {
          staticClass: "im-result__row flex d-flex justify-center",
          attrs: { name: "fact-match-result-transition", tag: "div" },
        },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "div",
            {
              key: "fact-match-player-" + index,
              staticClass: "im-result__column",
            },
            [
              _c("MatchGameCard", { attrs: { imageURL: item.question } }),
              _vm.textify
                ? _c(
                    "div",
                    { staticStyle: { position: "relative" } },
                    [
                      _c("FactCard", { attrs: { correct: item.answer } }),
                      _c("SvgIcon", {
                        staticClass: "im-result__icon",
                        staticStyle: { top: "-30px" },
                        attrs: {
                          size: "xl",
                          name: "match-" + (item.correct ? "correct" : "wrong"),
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "MatchGameCard",
                    { attrs: { imageURL: item.answer, bordered: false } },
                    [
                      _c("SvgIcon", {
                        staticClass: "im-result__icon",
                        attrs: {
                          size: "xl",
                          name: "match-" + (item.correct ? "correct" : "wrong"),
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }