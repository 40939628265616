import { db } from "@/firebase"
import { store } from "@/store"

export default class TeamService {
  static pin(teamID) {
    const orgID = store.getters.orgID
    const gameID = store.getters.gameID
    return db
      .auxiliary()
      .ref(`org/${orgID}/games/${gameID}/pinnedTeamID`)
      .set(teamID)
  }
  static unpin() {
    const orgID = store.getters.orgID
    const gameID = store.getters.gameID
    return db
      .auxiliary()
      .ref(`org/${orgID}/games/${gameID}/pinnedTeamID`)
      .set(null)
  }
  static teamsRef(orgID, gameID) {
    return db.auxiliary().ref(`org/${orgID}/game/${gameID}/teams`)
  }
  static teamRef(orgID, gameID, teamID) {
    return this.teamsRef(orgID, gameID).child(teamID)
  }
  static chatsRef(orgID, gameID) {
    return this.teamsRef(orgID, gameID).orderByChild("show").equalTo(true)
  }
}
