import Composer from "./Composer"
import { TwilioRoom } from "../twilio.service"
import { v4 as uuidv4 } from "uuid"
import { store } from "@/store/index"
import EventEmitter from "events"
import instance from "../axios.service"
class Room extends EventEmitter {
  constructor() {
    super()
    this.id = uuidv4()
    this._room = null
    this._composer = null
    return this
  }
  static async kick(roomID, userID) {
    return instance({
      method: "delete",
      url: `/twilio/${roomID}/user/${userID}`,
      headers: { "x-gogame-org": store.state.orgID }
    })
  }
  async start() {
    this._composer = new Composer()

    const unwatch = () => this.emit("ended")

    this._composer.once("ended", unwatch)
    await this._composer.allocate()
    const [videoTrack, audioTrack] = this._composer.start().getTracks()
    const user = store.getters["auth/user"]
    const identity = `${this.id}__broadcast__${user?.id}`
    const token = await store.dispatch("twilio/GET_TOKEN", identity)
    this._room = await TwilioRoom.connect({
      name: this.id,
      videoTrack,
      audioTrack,
      identity,
      token
    })

    return this
  }
  stop() {
    this._room?.disconnect()
    this._composer?.stop()
  }
}

export default Room
