
/**
 * For now it's just supposed to be used as a style provider
 */
import Vue from "vue"
export default Vue.extend({
  name: "RTBTable",
  render() {
    return null
  }
})
