
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "RTBInputButton",
  functional: true,
  // TODO(Andrew|someone): sync with Button color
  props: {
    color: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  render(h, { props, children, data }) {
    return h(
      "button",
      mergeData(data, {
        attrs: {
          type: "button",
          title: props.title,
          "aria-label": props.title,
          disabled: props.disabled
        },
        class: {
          [`rtb-input-button--color--${props.color}`]: props.color,
          "rtb-input-button--disabled": props.disabled
        },
        staticClass: "rtb-input-button"
      }),
      children
    )
  }
})
