<template functional>
  <div
    class="user-media-image"
    :style="props.imageUrl ? `background-image: url(${props.imageUrl})` : null"
    :class="props.imageUrl ? null : 'user-media-image--default'"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "UserImage",
  functional: true,
  props: {
    imageUrl: {
      type: [String, Boolean]
    }
  }
}
</script>

<style lang="scss">
.user-media-image {
  position: relative;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &--default {
    background-color: #c4c4c4;
    background-image: url("../../../../assets/profile-icon.svg");
    background-size: 90%;
    background-position: 50% 100%;
    background-repeat: no-repeat;
  }
}
</style>
