





























import Vue from "vue"
export default Vue.extend({
  name: "ToolbarDropdown",
  props: {
    title: {
      type: String,
      default: "Please set title"
    },
    value: {
      type: [String, Boolean],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    svgIcon: {
      type: String,
      default: undefined
    },
    vIcon: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isOpen() {
      return this.title === this.value
    }
  },
  methods: {
    handleClick() {
      this.$emit("input", this.isOpen ? false : this.title)
    }
  }
})
