
















import Vue from "vue"
export default Vue.extend({
  name: "Medal",
  props: {
    variant: {
      type: [String, Number],
      default: undefined
    }
  }
})
