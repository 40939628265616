import { db } from "@/firebase"
import { onBeforeUnmount, onMounted, ref } from "@vue/composition-api"

import { StreamType } from "@/Chime/use/enums"

type ChimeRoom = {
  meetingId: string
  channelPlaybackUrl?: string
  channelArn?: string
  mediaPipelineId?: string
  type: StreamType
}

export default function useChimeRoom(roomId: string, type: StreamType) {
  const meeting = ref<ChimeRoom | null>(null)

  function onSnapshot(snapshot) {
    const value = snapshot.val()
    if (value && value.type !== type) return
    meeting.value = snapshot.val()
  }

  const meetingRef = db.ref(`chime/${roomId}`)

  onMounted(() => {
    meetingRef.on("value", onSnapshot)
  })

  onBeforeUnmount(() => {
    meetingRef.off("value", onSnapshot)
  })

  return { meeting }
}
