
import Vue, { PropType } from "vue"
import { mergeData } from "vue-functional-data-merge"

import { colors } from "./types"

export default Vue.extend({
  name: "RTBControlButton",
  functional: true,
  props: {
    color: {
      type: String as PropType<typeof colors[number]>,
      default: undefined,
      validator: val => colors.includes(val)
    },
    danger: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: undefined
    },
    sm: {
      type: Boolean,
      default: false
    },
    xs: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
    unfocusable: {
      type: Boolean,
      default: true
    }
  },
  render(h, { props, children, data }) {
    return h(
      "button",
      mergeData(data, {
        attrs: { type: "button", disabled: props.disabled },
        class: {
          [`control-button--${props.color}`]: props.color,
          "control-button--active": props.active,
          "control-button--danger": props.danger,
          "control-button--rounded": props.rounded,
          "control-button--sm": props.sm,
          "control-button--xs": props.xs,
          "control-button--disabled": props.disabled,
          "control-button--hidden": props.hidden,
          [`control-button--${props.variant}`]: props.variant
        },
        staticClass: "control-button",
        on: {
          focus: (e: any) => props.unfocusable && e.target.blur()
        }
      }),
      children
    )
  }
})
