import Vue from "vue"
import Router from "vue-router"
import multiguard from "vue-router-multiguard"
import { ThemeScope } from "@/modules/theme/store/constants"
import { GuardFactory as ThemeGuardFactory } from "@/modules/theme/router/guard"
import { store } from "@/store/index"

// cannot lazy load this one as it breaks logic around this.$route.query in App.vue
import LoginOnboardOverlay from "@/components/User/LoginAndOnboarding/LoginOnboardOverlay"

const Admin = () => import("@/components/Game/Admin")
const Orgs = () => import("@/components/Game/Orgs")
const System = () => import("@/pages/System")
const FinnPricing = () => import("@/pages/FinnPricing")
const FinnPricingCode = () => import("@/pages/FinnPricingCode")
const Hosts = () => import("@/components/Game/Hosts")
const Scripts = () => import("@/pages/Scripts")
const AdminGames = () => import("@/components/Game/AdminGames")
const Twitch = () => import("@/components/Game/TwitchPage")
const GameEdit = () => import("@/components/Game/Edit")
const ClientSettings = () => import("@/components/Game/ClientSettings")
const Games = () => import("@/components/Game/Index")
const Clients = () => import("@/components/Game/Clients")
const GameUsers = () => import("@/components/User/GameUsers")
const OpenSession = () => import("@/pages/OpenSession")
const SessionSettings = () => import("@/pages/SessionSettings")

// pages that require can-autoplay
const Lobby = () => import("@/pages/Lobby/Lobby")
const PickTeams = () => import("@/pages/PickTeams")
const Room = () => import("@/pages/Room")

// enums
import Page from "./Page"
import Role from "@shared/enums/Role"

// hooks
import authorize from "./hooks/authorize"
import autoplay from "./hooks/autoplay"
import logout from "./hooks/logout"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      beforeEnter: authorize,
      meta: { authorize: [Role.HOST] },
      children: [
        {
          path: "/",
          redirect: "/admin/orgs",
          beforeEnter: authorize,
          meta: { authorize: [Role.HOST] }
        },
        {
          path: "system",
          name: "system",
          component: System,
          beforeEnter: authorize,
          meta: { authorize: [Role.HOST] }
        },
        {
          path: "orgs",
          name: "orgs",
          component: Orgs,
          beforeEnter: authorize,
          meta: { authorize: [Role.HOST] }
        },
        {
          path: "hosts",
          name: "hosts",
          component: Hosts,
          beforeEnter: authorize,
          meta: { authorize: [Role.HOST] }
        },
        {
          path: "scripts",
          name: "scripts",
          component: Scripts,
          props: true,
          beforeEnter: authorize,
          meta: { authorize: [Role.HOST] }
        },
        {
          path: "games",
          name: "games",
          component: AdminGames,
          props: route => ({ time: route.query.time }),
          beforeEnter: authorize,
          meta: { authorize: [Role.HOST] }
        },
        {
          path: "twitch",
          name: "twitch",
          component: Twitch,
          props: route => ({ time: route.query.time }),
          beforeEnter: authorize,
          meta: { authorize: [Role.HOST] }
        }
      ]
    },
    {
      path: "/lobby/:id?",
      name: Page.LOBBY,
      component: Lobby,
      props: true,
      beforeEnter: multiguard([authorize, autoplay]),
      meta: { authorize: [] }
    },
    {
      path: "/lobby/:id/settings",
      name: Page.SESSION_SETTINGS,
      component: SessionSettings,
      props: true,
      beforeEnter: authorize,
      meta: { authorize: [Role.HOST] }
    },
    {
      path: "/open-demo/:id?",
      name: "open-session",
      component: OpenSession,
      props: true
    },
    {
      path: "/login/:id?",
      name: Page.LOGIN,
      component: LoginOnboardOverlay,
      beforeEnter: multiguard([autoplay]),
      props: true
    },
    {
      path: "/pricing",
      name: "pricing",
      component: FinnPricing,
      props: true
    },
    {
      path: "/pricingCode",
      name: "pricingCode",
      component: FinnPricingCode,
      props: true
    },
    {
      path: "/",
      redirect: to => ({ path: "/login", query: to.query })
    },
    {
      path: "/gamelogin/:id",
      redirect: to => {
        const { params, query } = to
        const path = params.id ? "/login:id?" : "/login"
        return { path, query }
      }
    },
    {
      path: "/pickteams/:id",
      name: Page.SELECT_TEAM,
      component: PickTeams,
      beforeEnter: multiguard([authorize, autoplay]),
      props: true,
      meta: { theme: { scope: ThemeScope.GAME }, authorize: [] }
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: logout,
      meta: { authorize: [] }
    },
    {
      path: "/game/:id",
      beforeEnter: multiguard([authorize, autoplay]),
      props: true,
      name: Page.ROOM,
      component: Room,
      meta: {
        authorize: [],
        theme: { scope: ThemeScope.GAME },
        redirect: false
      }
    },
    {
      path: "/latecomer/:id",
      beforeEnter: authorize,
      props: true,
      name: "GameLatecomer",
      component: () => import("@/components/Game/GameLatecomer.vue"),
      meta: { authorize: [], applyGameStyles: true }
    },
    {
      path: "/end-early-video",
      beforeEnter: authorize,
      name: "GameEndEarly",
      component: () => import("@/components/Game/GameEndEarly.vue"),
      meta: { authorize: [], applyGameStyles: true }
    },
    {
      path: "/game/:id/settings",
      name: "dashboard.game",
      component: GameEdit,
      beforeEnter: authorize,
      meta: { authorize: [Role.HOST] }
    },
    {
      path: "/games",
      name: "dashboard.games",
      component: Games,
      beforeEnter: authorize,
      meta: { authorize: [Role.HOST] }
    },
    {
      path: "/clients",
      name: "dashboard.clients",
      component: Clients,
      beforeEnter: authorize,
      meta: { authorize: [Role.HOST] }
    },
    {
      path: "/clients/:id/settings",
      name: "dashboard.client",
      component: ClientSettings,
      beforeEnter: authorize,
      meta: { authorize: [Role.HOST] }
    },
    {
      path: "/game/:id/users",
      name: "GameUsers",
      component: GameUsers,
      beforeEnter: authorize,
      meta: { authorize: [Role.HOST] }
    },
    {
      path: "/interimvideo/:id",
      beforeEnter: authorize,
      props: true,
      name: "interimvideo",
      component: () => import("@/components/Game/InterimVideo.vue"),
      meta: { authorize: [], applyGameStyles: true }
    },
    {
      path: "/lobby/:id/introvideo",
      beforeEnter: authorize,
      props: true,
      name: "pregameintrovideo",
      component: () => import("@/components/PregameIntroVideo.vue"),
      meta: { authorize: [], applyGameStyles: true }
    },
    {
      path: "*",
      redirect: { name: "login" }
    }
  ],

  mode: "history"
})

router.beforeEach(ThemeGuardFactory.create({ store }))

export default router
