<template>
  <div
    class="fact-match-player"
    :class="{ 'fact-match-player--muted': isMuted }"
  >
    <v-flex class="fact-match-player__cnt">
      <RatioContainer class="fact-match-player__ratio-container">
        <!-- Team-Icon -->
        <v-icon v-if="!!teamIcon" dark class="fact-match-player__team-icon">
          {{ teamIcon || "radio_button_unchecked" }}
        </v-icon>

        <!-- Player -->
        <UserImage v-if="imageOnly" :imageUrl="imageURL" />

        <template v-else>
          <UserMedia :identity="userID" :imageUrl="imageURL" />
          <!-- Name -->
          <div class="fact-match-player__name">
            <UserName :firstname="firstname" />
          </div>
        </template>
        <div v-if="isHost" class="fact-match-player__controls">
          <div
            class="fact-match-player__control"
            @click.stop.exact="toggleAudio"
          >
            <SvgIcon
              :name="isMuted ? 'mute-mic' : 'mic'"
              :title="isMuted ? 'umnute' : 'mute'"
            />
          </div>
        </div>
      </RatioContainer>
    </v-flex>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import UserMedia from "@/components/GroupTeams/Common/User/UserMedia"
import UserImage from "@/components/GroupTeams/Common/User/UserImage"
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import UserSoundService from "@/services/user-sound.service"

export default {
  name: "FactMatchPlayer",
  components: {
    RatioContainer,
    UserImage,
    UserMedia,
    UserName
  },
  props: {
    imageURL: String,
    src: MediaStream,
    teamIcon: String,
    userID: String,
    userInfo: Object,
    imageOnly: Boolean,
    muted: Boolean
  },
  computed: {
    ...mapGetters("GameUsers", ["users"]),
    ...mapGetters("auth", ["isHost"]),
    user() {
      return this.users[this.userID]
    },
    isMuted() {
      if (this.imageOnly) return false

      return this.$store.getters.isUserMuted(this.user)
    },
    firstname() {
      return String(this.userInfo?.firstname)
    },
    lastname() {
      return String(this.userInfo?.lastname)
    }
  },
  methods: {
    toggleAudio() {
      UserSoundService.toggle({ id: this.user?.id })
    }
  }
}
</script>

<style lang="scss" scoped>
.fact-match-player {
  $block: &;
  position: relative;

  &:hover #{$block}__controls {
    opacity: 1;
    visibility: visible;
  }

  &__controls {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    visibility: hidden;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s;
  }
  &__control {
    color: $color-white;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__cnt {
    position: relative;
    border: 3px solid $color-tertiary-dark;
    border-width: 3px;
    border-style: solid;
    border-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    transform: translateZ(0); // safari overfolw fix

    #{$block}--muted & {
      border-color: $color-tertiary-dark;
    }
  }

  &__ratio-container {
    background-color: $secondary-background-color;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }

  .user-media-image {
    width: 100%;
  }

  &__team-icon {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;

    width: 30px;
    height: 30px;
    border-radius: 0 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    color: #f8f9fb;
    background-color: rgba(#16111a, 0.8);
  }

  &__name {
    line-height: 1;
    font-size: 14px;
    color: #f8f9fb;
    white-space: nowrap;
    font-weight: 400;

    position: absolute;
    left: 50%;
    bottom: 8px;
    transform: translateX(-50%);
    z-index: 2;

    min-width: 100px;
    border-radius: 4px;
    padding: 4px 8px;

    background-color: rgba(#16111a, 0.8);
  }
}
</style>
