export default class LatecomerHistoryStorage {
  static add(videoID: string) {
    try {
      const value = localStorage.getItem(
        LatecomerHistoryStorage.LOCAL_STORAGE_KEY
      )

      if (value === null) {
        localStorage.setItem(
          LatecomerHistoryStorage.LOCAL_STORAGE_KEY,
          JSON.stringify([videoID])
        )
      } else {
        const parsed = JSON.parse(value)

        if (Array.isArray(parsed)) {
          if (parsed.includes(videoID) === false) {
            parsed.push(videoID)
            localStorage.setItem(
              LatecomerHistoryStorage.LOCAL_STORAGE_KEY,
              JSON.stringify(parsed)
            )
          }
        } else {
          localStorage.setItem(
            LatecomerHistoryStorage.LOCAL_STORAGE_KEY,
            JSON.stringify([videoID])
          )
        }
      }

      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }

  static has(videoID: string): boolean {
    try {
      const value = localStorage.getItem(
        LatecomerHistoryStorage.LOCAL_STORAGE_KEY
      )

      if (value === null) {
        return false
      } else {
        const parsed: string[] = JSON.parse(value)

        if (Array.isArray(parsed)) {
          return parsed.includes(videoID)
        } else {
          return false
        }
      }
    } catch (e) {
      console.log(e)
      return true
    }
  }

  static LOCAL_STORAGE_KEY = "LATECOMER_HISTORY"
}
