







































import { ConfirmMixin } from "@/mixins"
import isHTML from "@/helpers/is-html"

export default ConfirmMixin.extend({
  name: "DefaultConfirm",
  props: {
    title: {
      type: String,
      required: false,
      default: ""
    },
    message: {
      type: String,
      required: true
    },
    forced: {
      type: Boolean,
      required: false,
      default: false
    },
    exec: {
      type: Function,
      required: false,
      default: undefined
    }
  },
  computed: {
    isHTML() {
      return isHTML(this.message)
    }
  }
})
