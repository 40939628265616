<template>
  <div class="video-team-result">
    <div class="video-team-result__results">
      <VideoComponent
        v-for="result in results"
        :key="result.id"
        :src="result.src"
        autoplay
        muted
        loop
        class="video-team-result__video"
      />
    </div>

    <div class="video-team-result__controls mt-2">
      <StarRating
        v-if="hasVotingStars"
        class="mr-2"
        :increment="0.5"
        :padding="3"
        :rating="rating"
        :star-size="15"
        :show-rating="false"
        :border-width="2"
        :inactive-color="''"
        active-color="#fff"
        border-color="#fff"
        @rating-selected="onStarred($event)"
      />

      <button class="video-team-result__dowmload-btn" @click="download">
        <svg-icon name="cloud-download-regular" size="small" class="mr-1" />
        {{ downloading ? "Processing..." : "Download" }}
      </button>
    </div>
    <SyncedMedia
      v-if="missionAudioSrc"
      :src="missionAudioSrc"
      :autoplay="false"
      :loop="true"
      :key="teamID"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import * as moment from "moment"
import { db } from "@/firebase"
import { downloadMissonMedia } from "@/services/api.service"
import StarRating from "vue-star-rating"
import SyncedMedia from "@/modules/smart-media/components/SyncedMedia.vue"
import VideoComponent from "@/components/ui/VideoComponent/VideoComponent.vue"

function getPlaySource(play) {
  return Array.isArray(play?.correct)
    ? play.correct[0]?.video
    : play?.correct?.video
}

export default {
  name: "VideoTeamResult",
  components: {
    VideoComponent,
    StarRating,
    SyncedMedia
  },
  data() {
    return {
      rating: 0,
      downloading: false
    }
  },
  watch: {
    userVoteValue: {
      handler(newValue, oldValue) {
        if (newValue === oldValue) return
        this.rating = newValue
      },
      immediate: true
    }
  },
  props: {
    teamID: String,
    votes: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  computed: {
    ...mapGetters(["missionPlaysArray", "gameStatus", "gameID", "orgID"]),
    ...mapGetters("auth", ["clientID", "user", "token"]),
    ...mapGetters({
      mission: "getCurrentMission"
    }),
    hasVotingStars() {
      return (
        this.results.length && !this.isVotingUserTeam && this.hasVideoTeamVoting
      )
    },
    results() {
      const teamID = this.teamID
      return this.missionPlaysArray.reduce((acc, play) => {
        if (play.teamID !== teamID) return acc
        const src = getPlaySource(play)
        if (src) acc.push({ src, id: play.id })
        return acc
      }, [])
    },
    userVote() {
      return (this.votes ?? [])
        .reverse()
        .find(
          ({ teamID, userID }) =>
            teamID === this.teamID && userID === this.user.id
        )
    },
    userVoteValue() {
      return this.userVote?.vote ?? 0
    },
    missionAudioSrc() {
      return this.mission?.audio
    },
    isVotingUserTeam() {
      return this.teamID === this.user?.teamID
    },
    hasVideoTeamVoting() {
      return this.gameStatus.hasVideoTeamVoting !== false
    }
  },
  methods: {
    async onStarred(rate) {
      const mission = this.mission

      if (mission == null) return

      const points = parseInt(mission.points) || 0
      const score = (rate / 5) * points
      const sTime = new Date()

      db.auxiliary()
        .ref(
          `org/${this.orgID}/game/${this.gameID}/gameStatus/hasVideoTeamVoting`
        )
        .transaction(value => {
          if (value === false) return
          return true
        })

      this.$store.dispatch("addVote", {
        id: this.userVote?.id || null,
        teamID: this.teamID,
        missionID: mission.id,
        mission: mission.name,
        points,
        time: moment(sTime).unix(),
        show: false,
        vote: rate,
        userID: this.user.id,
        score
      })
    },
    async download() {
      this.downloading = true
      const response = await downloadMissonMedia({
        orgID: this.mission.orgID,
        gameID: this.gameID,
        missionId: this.$store.getters.currentMission,
        token: this.token
      }).finally(() => {
        this.downloading = false
      })
      console.log(response)
      if (response.status !== "success") throw new Error(response.message)
      const a = document.createElement("a")
      a.setAttribute("href", response.data.url)
      a.setAttribute("target", "_blank")
      a.click()
    }
  }
}
</script>

<style lang="scss">
.video-team-result {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  &__controls {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  &__results {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
  }

  &__video {
    width: 200px;
    height: 200px;
    transform: translateZ(1);
  }

  &__dowmload-btn {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 15px;
    color: #fff;
    background: $primary_accent_color;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    transition: opacity 0.5s ease-out;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
    }
  }
}
</style>
