// source https://gist.github.com/sagittaros/14ebd9fc61c68deddebe4039eedb1124

export default class Loopback {
  constructor() {
    this._rtcConnection = null
    this._rtcLoopbackConnection = null
    this._loopbackStream = new MediaStream()
  }
  async init(stream) {
    const offerOptions = {
      offerVideo: true,
      offerAudio: true,
      offerToReceiveAudio: false,
      offerToReceiveVideo: false
    }

    let offer, answer

    // initialize the RTC connections
    this._rtcConnection = new RTCPeerConnection()
    this._rtcLoopbackConnection = new RTCPeerConnection()

    this._rtcConnection.onicecandidate = e =>
      e.candidate &&
      this._rtcLoopbackConnection.addIceCandidate(
        new RTCIceCandidate(e.candidate)
      )
    this._rtcLoopbackConnection.onicecandidate = e =>
      e.candidate &&
      this._rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate))

    this._rtcLoopbackConnection.ontrack = e =>
      e.streams[0]
        .getTracks()
        .forEach(track => this._loopbackStream.addTrack(track))

    // setup the loopback
    // this stream would be the processed stream coming out of Web Audio API destination node
    this._rtcConnection.addStream(stream)

    offer = await this._rtcConnection.createOffer(offerOptions)
    await this._rtcConnection.setLocalDescription(offer)

    await this._rtcLoopbackConnection.setRemoteDescription(offer)
    answer = await this._rtcLoopbackConnection.createAnswer()
    await this._rtcLoopbackConnection.setLocalDescription(answer)

    await this._rtcConnection.setRemoteDescription(answer)

    return this._loopbackStream
  }
}
