var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("video", {
    ref: "video",
    attrs: { preload: "none" },
    on: {
      canplay: function ($event) {
        return _vm.$emit("ready")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }