import { render, staticRenderFns } from "./GamePlayerCard.vue?vue&type=template&id=371c729d&"
import script from "./GamePlayerCard.vue?vue&type=script&lang=ts&"
export * from "./GamePlayerCard.vue?vue&type=script&lang=ts&"
import style0 from "./GamePlayerCard.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/dkurbatov/Documents/TGG/go-game-platform/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('371c729d')) {
      api.createRecord('371c729d', component.options)
    } else {
      api.reload('371c729d', component.options)
    }
    module.hot.accept("./GamePlayerCard.vue?vue&type=template&id=371c729d&", function () {
      api.rerender('371c729d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GroupTeams/Common/Player/GamePlayerCard/GamePlayerCard.vue"
export default component.exports