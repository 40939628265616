<template>
  <div class="login-form">
    <p class="login-form__title">
      {{ enterText }}
    </p>

    <v-form @submit.prevent="signInUserAnonymously" ref="form">
      <v-checkbox
        v-if="conferenceLogin"
        v-model="conference"
        color="success"
        hide-details
        class="anonymous-form__conference-checkbox"
        label="Multiple players at one computer"
      />

      <div :class="{ 'two-col-form': customInputType && !conference }">
        <div :class="{ 'two-col-input': customInputType && !conference }">
          <p>{{ conference ? `Conference Name` : `First name` }}</p>
          <v-text-field
            type="text"
            required
            :rules="[rules.required]"
            :placeholder="conference ? `Name` : `Jane`"
            v-model="firstname"
            id="anonymouse-login-form-first-name-input"
          />
        </div>

        <div :class="{ 'two-col-input': customInputType }" v-if="!conference">
          <p>Last name</p>
          <v-text-field
            type="text"
            required
            :rules="[rules.required]"
            placeholder="Smith"
            v-model="lastname"
            id="anonymouse-login-form-last-name-input"
          />
        </div>
      </div>

      <p v-if="customInputLabelComputed">{{ customInputLabelComputed }}</p>
      <v-select
        v-if="hasCustomInputSelect"
        v-model="customInput"
        :items="customInputOptions"
        dense
        single-line
        loader-height="40"
        color="#413d3c"
        required
        :rules="[rules.required]"
      />
      <v-text-field
        v-if="customInputType === 'text'"
        type="text"
        required
        :rules="[rules.required]"
        :placeholder="customInputLabel"
        v-model="customInput"
      />
      <v-text-field
        v-if="customInputType === 'password'"
        type="password"
        required
        :rules="[rules.required, rules.min]"
        placeholder="******"
        v-model="customInput"
      />

      <div class="submit-wrap flex d-flex">
        <button
          class="login-form__submit"
          type="submit"
          :disabled="!isFormValid"
          value="Log in"
          id="anonymouse-login-form-login-btn"
        >
          <SvgIcon
            width="24"
            height="24"
            class="login-form__submit-icon"
            name="binoculars"
            v-if="$_LoginMixin_audit"
          />
          Log in

          <template v-if="$_LoginMixin_audit"> as Auditor </template>
        </button>
      </div>

      <div class="terms-and-conditions mt-3">
        By logging in you agree to our
        <a @click.stop :href="privacyLink" target="_blank">Terms & Conditions</a
        >.
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { Navigation } from "@/helpers"
import HubSpot from "@/services/hubspot.service"
import LoginMixin from "@/components/User/LoginAndOnboarding/mixins/LoginMixin"
import LoginCustomInput from "@shared/enums/LoginCustomInput"

const PRIVACY_LINK = "https://www.thegogame.com/team-building/privacy-policy/"

export default {
  name: "AnonymousForm",
  props: [
    "id",
    "customInputOptions",
    "customInputLabel",
    "customInputType",
    "enterLoginText",
    "conferenceLogin"
  ],
  mixins: [LoginMixin],
  data() {
    return {
      conference: false,
      firstname: "",
      lastname: "",
      privacyLink: PRIVACY_LINK,
      rules: {
        required: value => !!value || "Field is required",
        min: value => value.length > 5 || "Min 6 characters"
      },
      customInput: ""
    }
  },
  computed: {
    customInputLabelComputed() {
      if (!this.customInputType) return null

      if (this.customInputType === LoginCustomInput.Location) return "Location"

      return this.customInputLabel ?? null
    },
    hasCustomInputSelect() {
      return (
        this.customInputType === LoginCustomInput.Select ||
        this.customInputType === LoginCustomInput.Location
      )
    },
    enterText() {
      if (this.enterLoginText) {
        return this.enterLoginText
      } else {
        return "Tell us who you are!"
      }
    },
    identifier() {
      return this.customInput
        ? String(this.customInput).trim()
        : this.customInputType &&
          this.customInputOptions &&
          this.customInputOptions.length
        ? // default to the last item on the list
          this.customInputOptions[this.customInputOptions.length - 1]
        : null
    },
    isFormValid() {
      if (this.conferenceLogin) {
        return !!this.firstname
      } else if (
        (this.customInputType && this.customInputType === "text") ||
        this.customInputType === "select" ||
        this.customInputType == "password"
      ) {
        return !!(this.firstname && this.lastname && this.customInput)
      } else {
        return !!(this.firstname && this.lastname)
      }
    }
  },
  async created() {
    if (this.$_LoginMixin_firstname && this.$_LoginMixin_lastname) {
      this.firstname = this.$_LoginMixin_firstname
      this.lastname = this.$_LoginMixin_lastname
      this.imageURL = this.$_LoginMixin_image

      try {
        await this.signInUserAnonymously()
        return
      } catch (e) {
        console.error(e)
      }
    }
  },

  methods: {
    ...mapActions("auth", [
      "signUserInAnonymously",
      "signUserInAnonymouslyWithPassword",
      "updateError"
    ]),

    async signInUserAnonymously() {
      if (this.customInputType === "password") {
        this.signInUserAnonymouslyWithPassword()
        return
      }

      HubSpot.push([
        "trackCustomBehavioralEvent",
        {
          name: "pe2803895_sign_in",
          properties: {
            account_type: "anon"
          }
        }
      ])

      this.$_LoginMixin_maybeTriggerHubspotDemoEvent("anon")

      HubSpot.track()

      await this.signUserInAnonymously({
        firstname: this.firstname || "",
        lastname: this.lastname || "",
        image: this.imageURL,
        audit: this.$_LoginMixin_audit,
        identifier: this.identifier,
        customInputType: this.customInputType,
        customInput: this.customInput,
        conference: this.conference,
        ...Navigation.parseUrlID(this.id)
      })
    },

    async signInUserAnonymouslyWithPassword() {
      HubSpot.push([
        "trackCustomBehavioralEvent",
        {
          name: "pe2803895_sign_in",
          properties: {
            account_type: "anon-with-password"
          }
        }
      ])

      await this.signUserInAnonymouslyWithPassword({
        firstname: this.firstname,
        lastname: this.lastname,
        audit: this.$_LoginMixin_audit,
        password: this.customInput,
        identifier: this.identifier,
        ...Navigation.parseUrlID(this.id)
      })
    }
  }
}
</script>

<style lang="scss">
.anonymous-form {
  &__conference-checkbox {
    margin: 0 !important;
    /* justify-content: end !important; */
    .v-label {
      color: #000 !important;
    }
    .v-input__slot {
      background-color: transparent !important;
    }
  }
}
</style>
