import { computed, ref, onMounted, watch } from "@vue/composition-api"

import useChime from "@/Chime/use/useChime"
import useChimeRoom from "@/Chime/use/useChimeRoom"
import useStore from "@/use/useStore"
import { StreamType } from "@/Chime/use/enums"

const { store } = useStore()

const isViewerHostLike = computed(() => store.getters["group/isViewerHostLike"])

export default function useChimeRoomStream(roomId: string) {
  const { meeting } = useChimeRoom(roomId, StreamType.ROOM)
  const { joinWithCamera, startStreaming, stopStreaming, abandon } = useChime()

  const available = computed(() => isViewerHostLike.value && roomId != null)
  const playbackUrl = computed(() => meeting.value?.channelPlaybackUrl)

  const working = ref(false)
  const mounted = ref(false)

  let joining = false
  let unwatch

  onMounted(() => {
    if (roomId == null) return
    mounted.value = true
  })

  async function startChimeStreaming() {
    if (joining) return
    if (unwatch) unwatch()
    await joinWithCamera(roomId)
    await startStreaming(roomId)
  }

  async function stopChimeStreaming() {
    await stopStreaming(roomId)
    await abandon(roomId)
  }

  const canStartStreamnig = computed(() => {
    return isViewerHostLike.value && mounted.value && streaming.value
  })

  const streaming = computed({
    get() {
      if (playbackUrl.value) return true
      if (meeting.value?.channelArn) return true
      if (meeting.value?.mediaPipelineId) return true

      return false
    },
    async set(value) {
      if (working.value) return
      working.value = true

      try {
        if (value === true) {
          await startChimeStreaming()
        } else {
          await stopChimeStreaming()
        }
      } catch (e) {
        console.error(e)
      }

      working.value = false
    }
  })

  unwatch = watch(
    canStartStreamnig,
    async value => {
      if (!value) return
      if (joining) return
      joining = true
      try {
        await joinWithCamera(roomId)
        if (unwatch) unwatch()
      } catch (e) {
        console.error(e)
      }
      joining = false
    },
    { immediate: true }
  )

  return { streaming, available, working, playbackUrl }
}
