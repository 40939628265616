
const truncate = (string: string, limit: number): string => {

  return string?.length > limit
    ? string.substring(0, limit - 1) + '…'
    : string;

}

export default truncate
