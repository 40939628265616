var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("validation-provider", {
    attrs: { name: _vm.$attrs.label, rules: _vm.rules },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          var valid = ref.valid
          return [
            _c(
              "weve-text-field",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      hint: _vm.charactersCounter
                        ? _vm.countCharacters(
                            _vm.innerValue,
                            +_vm.$attrs.maxlength
                          ) + " Characters Left"
                        : _vm.$attrs.hint,
                    },
                    model: {
                      value: _vm.innerValue,
                      callback: function ($$v) {
                        _vm.innerValue = $$v
                      },
                      expression: "innerValue",
                    },
                  },
                  "weve-text-field",
                  _vm.$attrs,
                  false
                ),
                _vm.$listeners
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }