import type { MutationTree } from "vuex"

import { Theme } from "../types"

import type { State } from "./state"
import type { ThemeScope } from "./constants"

import MutationTypes from "./mutation-types"

const mutations: MutationTree<State> = {
  [MutationTypes.SET_SCOPE](state, payload: ThemeScope) {
    state.scope = payload
  },
  [MutationTypes.SET_THEME](state, payload: Theme | null) {
    state.theme = payload
  }
}

export default mutations
