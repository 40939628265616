const breadcrumbMissionType = {
  text_answer: "text_answer",
  multiple_choice: "multiple_choice",
  code_standards: "code_standards",
  photo_and_text: "photo_and_text",
  camera: "camera",
  linked_head_to_head: "linked_head_to_head",
  photo: "photo",
  info: "info",
  profile_team_name: "profile_team_name",
  profile_photo: "profile_photo",
  profile_pair: "profile_pair",
  name_and_text: "name_and_text",
  endless_camera: "endless_camera",
  endless_photo: "endless_photo",
  endless_movie: "endless_movie",
  challenge: "challenge",
  redirect: "redirect",
  photo_pair: "photo_pair",
  photo_and_text_pair: "photo_and_text_pair",
  photo_sequence: "photo_sequence",
  photo_and_text_sequence: "photo_and_text_sequence",
  movie: "movie",
  movie_and_text: "movie_and_text",
  movie_pair: "movie_pair",
  living_art: "living_art",
  awkward_family_photos: "awkward_family_photos"
}

module.exports = breadcrumbMissionType
