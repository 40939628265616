var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ToolbarDropdown",
    {
      staticClass: "reactions",
      attrs: { vIcon: "mood", title: "React", value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.onInput($event)
        },
      },
    },
    [
      _vm._l(_vm.reactions, function (group, i) {
        return _c(
          "div",
          { key: i + "-group", staticClass: "reactions__list" },
          _vm._l(group, function (icon) {
            return _c(
              "div",
              { key: icon, staticClass: "reactions__item" },
              [
                _c(
                  "RtbControlButton",
                  {
                    staticClass:
                      "reactions__item-btn reactions__item-btn--square",
                    attrs: { unfocusable: false, sm: "" },
                    on: {
                      click: [
                        function ($event) {
                          return _vm.react(icon)
                        },
                        function ($event) {
                          if (!$event.shiftKey) {
                            return null
                          }
                          return _vm.react(icon, true)
                        },
                      ],
                    },
                  },
                  [
                    _c("SvgIcon", {
                      attrs: {
                        folder: "reactions",
                        name: icon,
                        width: "24",
                        height: "24",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        )
      }),
      !_vm.isLobbyPage
        ? _c(
            "RtbControlButton",
            {
              staticClass: "reactions__item-btn mx-auto",
              attrs: { sm: "", unfocusable: false },
              on: { click: _vm.toggleRaisedHand },
            },
            [
              _c("SvgIcon", {
                staticClass: "mr-2",
                attrs: {
                  folder: "reactions",
                  name: _vm.isRisedHand ? "lower-hand" : "raise-hand",
                  width: "30",
                  height: "30",
                },
              }),
              _vm._v(
                " " + _vm._s(_vm.isRisedHand ? "Lower " : "Raise ") + " hand "
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }