<template>
  <VolumeSlider v-model="volume">
    <slot />
  </VolumeSlider>
</template>

<script>
import { mapGetters } from "vuex"
import VolumeSlider from "@/components/GroupTeams/Misc/VolumeSlider.vue"
import { mapActions } from "vuex"
import _ from "lodash"

export default {
  name: "UserVolumeSlider",
  components: {
    VolumeSlider
  },
  computed: {
    ...mapGetters("auth", ["isHost", "user"]),
    volume: {
      get() {
        const volume = this.isHost
          ? this.user?.volume
          : this.$store.getters["auth/volume"]
        return (isNaN(volume) || volume < 0 ? 1 : volume) * 100
      },
      set: _.debounce(
        function (value) {
          const volume = Math.round((value / 100) * 100) / 100
          this.updateUserVolume({ userID: this.user?.id, volume })
        },
        1000,
        { leading: true }
      )
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateUserVolume"])
  }
}
</script>
