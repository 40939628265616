<template>
  <v-layout
    class="hubspot-chat-wrapper"
    :class="{
      'hubspot-chat-wrapper--mobile': mobile,
      [`hubspot-chat-wrapper__${position}`]: position,
      'margin-right': ['welcome', 'pregame'].includes($route.name) && !mobile
    }"
    column
    v-click-outside="onCloseChatBody"
    v-show="!isLoadingChat"
  >
    <v-layout
      column
      v-show="showChatBody"
      class="hubspot-chat-wrapper__chat-body"
      :class="{ 'chat-loading': isLoadingChat }"
    >
      <div
        class="hubspot-chat-wrapper__close-chat"
        @click="onCloseChatBody"
        v-if="showChatBody"
      >
        <svg-icon name="times-regular" width="24" height="24" />
      </div>
      <rtb-spinner v-if="isLoadingChat" />
      <v-flex grow id="__Hubspot_chat_wrapper__"> </v-flex>
    </v-layout>

    <v-layout
      d-flex
      class="hubspot-chat-wrapper__chat-head"
      :class="{
        'hubspot-chat-wrapper__chat-head--minimize': shouldMinimizeChatHead
      }"
      v-if="!showChatBody && canShowChatHead"
      @click="onOpenChatBody"
    >
      <v-flex class="chat-head__notice centered-item">
        <div>Need assistance?</div>

        <div class="unread-message" v-if="unreadMessageCount">
          {{ unreadMessageCount }}
        </div>
      </v-flex>
      <v-flex class="chat-head__icon centered-item">
        <svg-icon width="32" height="32" name="chatcirclefill" />
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { RtbSpinner } from "@/components/ui"
import { mapGetters } from "vuex"
import { Navigation } from "@/helpers"
import { fetchClient } from "@/services/client.service"
import vClickOutside from "v-click-outside"
import Packages from "@/entities/Package"
import PackagesEMD from "@/entities/PackageEMD"
import { cloneDeep, debounce } from "lodash"

const HubspotConversationsSettings = {
  loadImmediately: false,
  inlineEmbedSelector: "#__Hubspot_chat_wrapper__",
  enableWidgetCookieBanner: false,
  disableAttachment: false
}

const CHATS_CONTEXTS = {
  DEMO_CHAT: "xwdct",
  LIVE_SUPPORT_CHAT: "xwlv",
  TECH_SUPPORT_CHAT: "xwbt"
}
const CHAT_NAMESPACE = "ctx"
const MAX_CHECK_TIME = 3 // min

export default {
  name: "HubspotChatWrapper",
  components: {
    RtbSpinner
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      showChatBody: false,
      isLoadingChat: false,
      unreadMessageCount: 0,
      localClient: {},
      localGame: {},
      canShowChatHead: false,
      chatLoaded: false
    }
  },
  props: {
    canShowHubspotChat: {
      default: false,
      type: Boolean
    },
    position: {
      required: false,
      default: "left",
      validator: value => ["left", "right"].includes(value)
    }
  },
  watch: {
    client: {
      handler(client) {
        this.localClient = client
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters("auth", ["client", "isHost"]),
    ...mapGetters("Mobile", ["mobile"]),
    ...mapGetters(["game"]),
    isPremiumClient() {
      return (
        this.localClient?.packageLevel === Packages.PREMIUM ||
        this.localClient?.packageLevel === PackagesEMD.PREMIUM
      )
    },
    shouldMinimizeChatHead() {
      return this.isHost && this.isLobbyPage && this.position === "right"
    },
    isLobbyPage() {
      return this.$route.name === "pregame"
    },
    isGamePage() {
      return this.$route.name === "game"
    },
    isDemoChat() {
      return Boolean(this.localClient?.demo)
    },
    chatContext() {
      if (!this.canShowHubspotChat) return null
      if (!this.isGamePage) {
        return this.isPremiumClient
          ? CHATS_CONTEXTS.LIVE_SUPPORT_CHAT
          : CHATS_CONTEXTS.TECH_SUPPORT_CHAT
      } else if (this.isDemoChat) {
        return CHATS_CONTEXTS.DEMO_CHAT
      }
      return null
    },
    disposibleChat() {
      return [CHATS_CONTEXTS.TECH_SUPPORT_CHAT, CHATS_CONTEXTS.DEMO_CHAT]
    }
  },
  async created() {
    this.isLoadingChat = true
    window.hsConversationsSettings = cloneDeep(HubspotConversationsSettings)
    const { clientID } = Navigation.parseUrlID(this.$route.params.id)
    try {
      if (clientID && !this.client?.id) {
        this.localClient = await fetchClient(clientID)
      }
    } catch (e) {
      console.log(e.message)
    }

    this._unwatchChatContext = this.$watch(
      "chatContext",
      debounce(chatContext => {
        if (!chatContext) {
          clearInterval(this.loadedCheckInterval)
          window.HubSpotConversations?.clear()
          window.HubSpotConversations.widget.refresh()
          this.canShowChatHead = false
          return
        }

        if (window.HubSpotConversations) {
          this.onConversationsAPIReady()
        } else {
          window.hsConversationsOnReady = [this.onConversationsAPIReady]
        }
        this.checkIfLoaded(this.onChatReady)
      }, 400),
      { immediate: true }
    )
  },
  beforeDestroy() {
    window.HubSpotConversations?.widget?.remove()
    this.unsubscribeFromOnUnreadMessage()
    clearInterval(this.loadedCheckInterval)
    if (typeof this._unwatchChatContext === "function") {
      this._unwatchChatContext()
    }
  },
  methods: {
    onOpenChatBody() {
      this.showChatBody = true
      this.unreadMessageCount = 0
    },
    onCloseChatBody() {
      this.showChatBody = false
    },
    onConversationsAPIReady() {
      this.onChatTypeChange()
      if (this.getWidgetStatus().loaded) {
        window.HubSpotConversations.widget.refresh()
      } else {
        window.HubSpotConversations.widget.load({ widgetOpen: true })
      }
      this.subscribeToUnreadMessage()
    },
    subscribeToUnreadMessage() {
      this.unsubscribeFromOnUnreadMessage()
      window.HubSpotConversations.on(
        "unreadConversationCountChanged",
        this.onUnreadConversationCountChanged
      )
    },
    unsubscribeFromOnUnreadMessage() {
      window.HubSpotConversations.off(
        "unreadConversationCountChanged",
        this.onUnreadConversationCountChanged
      )
    },
    getWidgetStatus() {
      return window?.HubSpotConversations?.widget?.status()
    },
    onUnreadConversationCountChanged(payload) {
      const { unreadCount } = payload
      if (unreadCount) {
        this.unreadMessageCount += 1
      } else {
        this.unreadMessageCount = 0
      }
    },
    onChatTypeChange() {
      this.showChatBody = false

      window.HubSpotConversations?.clear()
      this.unreadMessageCount = 0

      window.HubSpotConversations.widget.refresh()

      const pathname = window.location.pathname
      const params = new URLSearchParams(window.location.search)

      if (!this.chatContext) {
        params.delete(CHAT_NAMESPACE)
      } else {
        params.set(CHAT_NAMESPACE, this.chatContext)
      }

      const paramString = params.toString()
      const url = paramString ? `${pathname}?${paramString}` : pathname

      window.history.replaceState(null, null, url)
    },
    checkIfLoaded() {
      clearInterval(this.loadedCheckInterval)
      this.checkIfLoadedStartTime = Date.now()
      this.isLoadingChat = true
      this.loadedCheckInterval = setInterval(() => {
        this.canShowChatHead = this.getWidgetStatus()?.loaded
        if (
          this.canShowChatHead ||
          (Date.now() - this.checkIfLoadedStartTime) / 1000 >=
            MAX_CHECK_TIME * 60
        ) {
          this.isLoadingChat = false
          clearInterval(this.loadedCheckInterval)
        }
        console.log(
          `still checking if ${this.chatContext} chat is loaded...`,
          this.canShowChatHead
        )
      }, 3000)
    }
  }
}
</script>

<style lang="scss">
.hubspot-chat-wrapper {
  $hcw: &;
  width: auto;
  height: auto;
  position: absolute;
  z-index: 3;

  #hubspot-conversations-inline-iframe {
    width: 370px;
    height: 450px;
    border: none;
    border-radius: 8px;
  }

  &--mobile #hubspot-conversations-inline-iframe {
    width: 100%;
  }

  &__left {
    bottom: 66px;
    left: 20px;

    #{$hcw}__chat-head {
      flex-direction: row-reverse;

      .chat-head__icon {
        margin-right: 5px;
      }
    }
  }

  &__right {
    bottom: 28px;
    right: 20px;
    &.margin-right {
      right: 70px;
    }
  }

  &__chat-body {
    animation: fade_in 0.5s;
    min-width: inherit;
    height: inherit;
    background: inherit;

    #{$hcw}--mobile & {
      width: 90vw;
    }

    &.chat-loading {
      min-width: 300px;
      min-height: 300px;
      background: white;
      border-radius: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__close-chat {
    position: absolute;
    top: -8px;
    right: -7px;
    background: $primary_accent_color;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    transition: transform 0.7s ease-in-out;
    &:hover {
      filter: brightness(1.1);
      transform: rotate(120deg);
    }
  }

  .centered-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__chat-head {
    $chat-head__notice-width: 161px;
    width: 228px;
    height: 42px;
    color: #36309e;
    font-weight: normal;
    font-size: 18px;

    &--minimize {
      width: unset;
      margin-right: -10px;
      overflow: hidden;
      .chat-head__notice {
        transform: translate(999px);
        z-index: 1;
        width: 0px !important;
      }
      .chat-head__icon {
        z-index: 2;
        transform: scale(0.5);
      }
      &:hover {
        margin-right: unset;
        .chat-head__icon {
          transform: scale(1);
        }
        .chat-head__notice {
          width: $chat-head__notice-width !important;
          transition: transform 0.78s;
          transform: translate(0);
        }
      }
    }

    .chat-head__notice {
      width: $chat-head__notice-width;
      background: #f7f3ff;
      border-radius: 4px;
      margin-right: 10px;
      cursor: pointer;
      & > div {
        &:hover {
          filter: brightness(1.2);
        }
      }

      & .unread-message {
        position: absolute;
        height: 27px;
        width: 27px;
        background: #c50909;
        color: white;
        align-items: center;
        border-radius: 50%;
        text-align: center;
        justify-content: center;
        right: 59px;
        top: -12px;
      }
    }

    .chat-head__icon {
      background: #f7f3ff;
      border-radius: 4px;
      width: 57px;
      cursor: pointer;
      &:hover {
        filter: brightness(1.2);
      }
    }
  }
}
@keyframes fade_in {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
