
import Vue, { VNode, PropType } from "vue"

import { Wormhole } from "portal-vue"
import { nanoid } from "nanoid"
import { Placement, createPopper } from "@popperjs/core"
import LeaderLine, { PathType, SocketType } from "leader-line-new"
import { debounce } from "lodash"

export const generateId = (): string => {
  return `weve-id-${nanoid()}`
}

export type Refs = {
  tip: HTMLElement
}

interface Point {
  x?: string | number
  y?: string | number
}

type AnimationType = "fade-up" | "fade-down"

export default Vue.extend({
  name: "GenericTip",
  props: {
    uid: {
      type: String,
      default() {
        return generateId()
      }
    },
    name: {
      type: String,
      default: "tips-target"
    },
    placement: {
      type: String as PropType<Placement>,
      default: "bottom" as Placement
    },
    offset: {
      type: Object as PropType<Record<string, string | number>>,
      default() {
        return { distance: 50, shift: 50 }
      }
    },
    arrow: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    preventOverflow: {
      type: Boolean,
      default: false
    },
    arrowOffsetEnd: {
      type: Object as PropType<Point | null>,
      default: null
    },
    arrowOffsetStart: {
      type: Object as PropType<Point | null>,
      default: null
    },
    tipAnimation: {
      type: [String] as PropType<AnimationType>,
      default: undefined
    },
    path: {
      type: String as PropType<PathType>,
      default: "arc" as PathType
    },
    endSocket: {
      type: String as PropType<SocketType>,
      default: "auto" as SocketType
    },
    startSocket: {
      type: String as PropType<SocketType>,
      default: "auto" as SocketType
    },
    preventOverflowMainAxis: {
      type: Boolean,
      default: false
    },
    preventOverflowAltAxis: {
      type: Boolean,
      default: false
    },
    showDelay: {
      type: [Number, String],
      default: 0
    },
    scrollElements: {
      type: Array as PropType<string[]>,
      default() {
        return ["grid"]
      }
    }
  },
  data() {
    return {
      showTimeout: undefined
    }
  },
  watch: {
    active: {
      handler(value: boolean) {
        if (value) {
          clearTimeout(this.showTimeout)
          this.showTimeout = setTimeout(() => {
            this.init()
          }, this.showDelay)
        } else {
          this.destroy()
        }
      },
      immediate: true
    },
    arrow() {
      this.destroy()
      this.init()
    }
  },
  created() {
    this.$_popperInstance = null
    this.$_leaderLineInstance = null
    this.debouncedUpdateLine = debounce(this.updateLine, 4)
  },
  mounted() {
    this.scrollElements.forEach(id => {
      const element = document.getElementById(id)
      element?.addEventListener("scroll", this.debouncedUpdateLine, {
        passive: true
      })
    })

    window.addEventListener("resize", this.debouncedUpdateLine)
  },
  beforeDestroy() {
    this.destroy()

    this.scrollElements.forEach(id => {
      const element = document.getElementById(id)
      element?.removeEventListener("scroll", this.debouncedUpdateLine)
    })

    window.removeEventListener("resize", this.debouncedUpdateLine)
  },
  methods: {
    updateWormhole() {
      const h = this.$createElement
      Wormhole.open({
        to: this.name,
        from: this.uid, // uid
        passengers: [
          h(
            "div",
            {
              ref: "tip",
              staticClass: "generic-tip",
              class: {
                [`generic-tip--${this.tipAnimation}`]: this.tipAnimation,
                "font-app-serif": this.$theme !== 1
              },
              key: this.uid
            },
            [
              h(
                "div",
                {
                  staticClass: "generic-tip__body"
                },
                this.$slots.content
              )
            ]
          )
        ]
      })
    },
    destroy() {
      const { $_popperInstance, $_leaderLineInstance } = this
      if ($_leaderLineInstance) {
        this.$_leaderLineInstance?.remove()

        this.$_leaderLineInstance = null
      }

      if ($_popperInstance) {
        $_popperInstance.destroy()
        this.$_popperInstance = null
      }

      Wormhole.close({ to: this.name, from: this.uid })
    },
    init() {
      this.$nextTick(() => {
        this.updateWormhole()

        this.$nextTick(() => {
          this.$_popperInstance = createPopper(this.$el, this.$refs.tip, {
            placement: this.placement,

            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [this.offset.shift, this.offset.distance]
                }
              },
              {
                name: "flip",
                options: {
                  fallbackPlacements: [this.placement]
                }
              },
              {
                name: "eventListeners",
                options: {
                  // resize: true
                }
              }
            ],
            onFirstUpdate: () => {
              if (this.arrow) {
                this.$_leaderLineInstance = new LeaderLine(
                  this.arrowOffsetStart
                    ? LeaderLine.pointAnchor(this.$refs.tip, {
                        x: this.arrowOffsetStart.x,
                        y: this.arrowOffsetStart.y
                      })
                    : this.$refs.tip,
                  this.arrowOffsetEnd
                    ? LeaderLine.pointAnchor(this.$el, {
                        x: this.arrowOffsetEnd.x,
                        y: this.arrowOffsetEnd.y
                      })
                    : this.$el,
                  {
                    color: "white",
                    size: 3,
                    endPlug: "arrow2",
                    endPlugSize: 1.2,
                    startSocket: this.startSocket,
                    endSocket: this.endSocket,
                    path: this.path,
                    hide: true
                  }
                )
                this.$_leaderLineInstance?.show("draw")
              }
            }
          })
        })
      })
    },
    updateLine() {
      if (this.active) {
        this.$_leaderLineInstance?.position()
      }
    }
  },
  render(): VNode {
    return this.$slots.default
  }
})
