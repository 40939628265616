


















import Vue from "vue"
import StarExplosion from "@/components/GroupTeams/Common/StarExplosion.vue"
export default Vue.extend({
  name: "RingStarsAnimation",
  components: { StarExplosion },
  props: {
    animate: {
      type: Boolean,
      default: false
    },
    starred: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      animated: false,
      radius: 50
    }
  },
  beforeDestroy() {
    this.removeListener()
  },
  mounted() {
    this.updateRadius()
  },
  watch: {
    animate(value) {
      if (value) {
        this.updateRadius()
        this.animated = true
        if (this.$refs.stars) {
          this.$refs.stars.animate()
        }
        this.$el.addEventListener("animationend", this.onAnimationEnd)
      }
    }
  },
  methods: {
    onAnimationEnd() {
      this.animated = false
      this.removeListener()
    },
    updateRadius() {
      const n = this.circle ? 1.5 : 1.33
      this.radius = (this.$el.clientWidth / 2) * n
    },
    removeListener() {
      this.$el.removeEventListener("animationend", this.onAnimationEnd)
    }
  }
})
