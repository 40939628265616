
import Vue, { PropType } from "vue"
import { BFormDatepicker, BFormTimepicker } from "bootstrap-vue"
import type { WithRefs } from "vue-typed-refs"
import type { WithEvents } from "vue-typed-emit"
import { format } from "date-fns"

import SvgIcon from "@/components/base/SvgIcon.vue"

import TextInput from "../TextInput/TextInput.vue"

type Refs = {
  datepicker: InstanceType<typeof BFormTimepicker>
}

export interface Events {
  input: Date | number
}

export default (Vue as WithEvents<Events, WithRefs<Refs>>).extend({
  name: "RTBDatepicker",
  inheritAttrs: false,
  props: {
    value: {
      type: [Date, Number] as PropType<Date | number | undefined>
    },
    /**
     * Indicates whether or not `value` should be emitted as a timestamp
     */
    timestamp: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    normalizedValue(): Date | undefined {
      if (!this.value) return undefined
      return typeof this.value === "number" ? new Date(this.value) : this.value
    },
    listeners(): Record<string, Function | Function[]> {
      return {
        ...this.$listeners,
        input: (date: Date) => {
          const buffer = this.value ? new Date(this.value) : new Date()
          buffer.setFullYear(date.getFullYear())
          buffer.setMonth(date.getMonth())
          buffer.setDate(date.getDate())

          if (this.timestamp) {
            this.$emit("input", buffer.getTime())
          } else {
            this.$emit("input", buffer)
          }
        }
      }
    }
  },
  render(h) {
    return h(
      TextInput,
      {
        props: {
          value: this.normalizedValue
            ? format(this.normalizedValue, "MM.dd.yyyy")
            : undefined,
          readonly: true
        },
        attrs: this.$attrs,
        on: {
          click: () => {
            this.$refs.datepicker.$refs.control.show()
          }
        }
      },
      [
        h("template", { slot: "help" }, this.$slots.help),
        h(BFormDatepicker, {
          ref: "datepicker",
          slot: "append",
          props: {
            value: this.normalizedValue,
            valueAsDate: true,
            buttonOnly: true,
            dropright: true
          },
          staticClass: "rtb-datepicker rtb-bootstrap-scope",
          on: this.listeners,
          scopedSlots: {
            "button-content"() {
              return h(SvgIcon, { props: { name: "calendar-alt-light" } })
            }
          }
        })
      ]
    )
  }
})
