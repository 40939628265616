


















import { ValidationProvider } from "vee-validate"
import { WeveTextField } from "@weve/ui"

import { countCharacters } from "@/utils/characters"

export default {
  name: "WeveTextFieldWithValidation",
  components: {
    ValidationProvider,
    WeveTextField
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    value: {
      type: null
    },
    charactersCounter: {
      type: Boolean
    }
  },
  data: () => ({
    innerValue: ""
  }),
  methods: {
    countCharacters
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal)
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
