import { rtdbOptions } from "vuexfire"

const previous = rtdbOptions.serialize

rtdbOptions.serialize = function (snapshot) {
  const res = previous(snapshot)

  if (typeof res === "object" && res !== null) {
    res.id = snapshot.key
  }

  return res
}
