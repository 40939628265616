<template>
  <div class="login-stepper">
    <div class="login-stepper-bar">
      <div class="progress" :style="{ width: currentWidth }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginStepper",
  props: ["steps", "currentStep", "setStep"],
  computed: {
    stepSize() {
      return 100 / this.steps.length
    },
    currentWidth() {
      if (this.currentStep <= this.steps.length) {
        return this.stepSize * (this.currentStep + 1) + "%"
      }
    }
  },
  methods: {
    navigate(key) {
      if (this.currentStep > key) {
        if (key === 0) {
          this.logout()
          return
        }

        this.setStep(key)
      }
    },
    logout() {
      this.$navigator.navigateTo("/logout")
    }
  }
}
</script>

<style lang="scss">
.login-stepper {
  @media (max-width: 1024px) {
    display: none;
  }

  .login-stepper-bar {
    width: 100%;
    height: 10px;
    margin: 0 auto;
    background-color: $card-form-inputs-bg;

    .progress {
      width: 0;
      max-width: 100%;
      height: 100%;
      background-color: $progress-bar-active;
      transition: 0.3s;
    }
  }

  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    .label {
      opacity: 0.4;
      text-decoration: none;

      &.active {
        cursor: pointer;
        opacity: 1;
      }

      &.current {
        opacity: 1;
        color: $card-submit-enabled;
      }
    }
  }
}
</style>
