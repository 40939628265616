import io from "socket.io-client"
import { db } from "@/firebase"

const TWITCH_RTMP = "rtmp://live.twitch.tv/app/"

export const PERFORMACE_OPTIONS = {
  LOW: "LOW",
  NORMAL: "NORMAL",
  HIGH: "HIGH"
}

const WEBSOCKET_STREAM_ENDPOINT = process.env.VUE_APP_WEBSOCKET_STREAM_ENDPOINT

const VIDEO_LOW_CONSTRAINT = {
  frameRate: 15,
  width: { max: 460 }
}

const VIDEO_NORMAL_CONSTRAINT = {
  frameRate: 15,
  width: { max: 640 }
}

const VIDEO_HIGH_CONSTRAINT = {
  frameRate: 15,
  width: { max: 900 }
}

const AUDIO_DEFAULT_CONSTRAINT = {
  sampleSize: 16,
  echoCancellation: false
}
const AUDIO_PERFORMANCE_CONSTRAINT = {
  sampleSize: 8,
  echoCancellation: false
}

const RECORD_DEFAULT_CONFIG = {
  mimeType: "video/webm;codecs=h264",
  videoBitsPerSecond: 1 * 1024 * 1024 // 1MB/sec
}

export class StreamCompositionError extends Error {
  constructor(message) {
    super(message)
    this.name = "StreamCompositionError"
  }
}

export class DisplayAllocationError extends Error {
  constructor(message) {
    super(message)
    this.name = "DisplayAllocationError"
  }
}

export class MicAllocationError extends Error {
  constructor(message) {
    super(message)
    this.name = "MicAllocationError"
  }
}

export class MediaServerError extends Error {
  constructor(message) {
    super(message)
    this.name = "MediaServerError"
  }
}

export class MediaRecorderError extends Error {
  constructor(message) {
    super(message)
    this.name = "MediaRecorderError"
  }
}

export class GameAllocationError extends Error {
  constructor(message) {
    super(message)
    this.name = "GameAllocationError"
  }
}

export class TwitchAllocationError extends Error {
  constructor(message) {
    super(message)
    this.name = "TwitchAllocationError"
  }
}

const mapPerformance = performance => {
  switch (performance) {
    case PERFORMACE_OPTIONS.LOW:
      return VIDEO_LOW_CONSTRAINT
    case PERFORMACE_OPTIONS.NORMAL:
      return VIDEO_NORMAL_CONSTRAINT
    case PERFORMACE_OPTIONS.HIGH:
      return VIDEO_HIGH_CONSTRAINT
    default:
      return VIDEO_NORMAL_CONSTRAINT
  }
}

const ScreenCapture = {
  namespaced: true,
  state: {
    error: null,
    display: null,
    mic: null,
    composition: null,
    unsubscribe: null,
    twitchID: null,
    gameID: null,
    interval: null,
    attempt: 0,
    performance: PERFORMACE_OPTIONS.NORMAL,
    isProcessingStream: null,
    isSharingScreen: false,
    isSharingOnlyAudio: false,
    showScreenShareDialog: false,
    isProcessingScreenSharing: false
  },
  mutations: {
    UPDATE_ATTEMPT(state, attempt) {
      state.attempt = attempt
    },
    UPDATE_INTERVAL(state, interval) {
      state.interval = interval
    },
    UPDATE_COMPOSED_STREAM(state, stream) {
      state.composition = stream
    },
    UPDATE_DISPLAY_STREAM(state, stream) {
      state.display = stream
    },
    UPDATE_MIC_STREAM(state, stream) {
      state.mic = stream
    },
    UPDATE_UNSUBSCRIBE(state, unsubscribe) {
      state.unsubscribe = unsubscribe
    },
    UPDATE_TWITCH_ID(state, twitchID) {
      state.twitchID = twitchID
    },
    UPDATE_GAME_ID(state, gameID) {
      state.gameID = gameID
    },
    UPDATE_ERROR(state, error) {
      state.error = error
    },
    SET_PERFORMANCE(state, payload) {
      state.performance = payload
    },
    UPDATE_GAME_PROCESSING(state, payload) {
      state.isProcessingStream = payload
    },
    UPDATE_IS_SHARING_SCREEN(state, payload) {
      state.isSharingScreen = payload
    },
    UPDATE_SHOW_SCREEN_SHARE_DIALOG(state, payload) {
      state.showScreenShareDialog = payload
    },
    UPDATE_IS_PROCESSING_SCREEN_SHARE(state, payload) {
      state.isProcessingScreenSharing = payload
    }
  },
  actions: {
    setPerformance({ commit }, payload) {
      return commit("SET_PERFORMANCE", payload)
    },
    async acquireGameID(
      { state, commit, rootGetters, dispatch },
      { gameID, streamUrl }
    ) {
      commit("UPDATE_GAME_ID", gameID)
      const ref = db.auxiliary().ref(`org/${rootGetters.orgID}/games/${gameID}`)
      try {
        ref
          .onDisconnect()
          .update({ streamUrl: null, processingStreaming: null })
        await ref.transaction(game => {
          if (!game) throw new Error(`Game ${gameID} is undefined`)
          game.streamUrl = streamUrl
          game.processingStreaming = null
          return game
        })
        await dispatch("setStartingStream", false)
      } catch (e) {
        ref.onDisconnect().cancel()
        commit("UPDATE_GAME_ID", null)
        throw new GameAllocationError(e.message)
      }
    },
    async disposeGameID({ state, commit, rootGetters }) {
      if (!state.gameID) throw new Error("Undefined game ID")
      const ref = db
        .auxiliary()
        .ref(`org/${rootGetters.orgID}/games/${state.gameID}`)
      await ref.update({ streamUrl: null })
      ref.onDisconnect().cancel()
      commit("UPDATE_GAME_ID", null)
    },
    async acquireTwitchID({ _, __, commit, rootGetters }, { twitchID }) {
      commit("UPDATE_TWITCH_ID", twitchID)
      const ref = db.ref(`orgs/${rootGetters.orgID}/twitchAccounts/${twitchID}`)
      try {
        ref.onDisconnect().update({ inuse: false })
        await ref.transaction(account => {
          if (!account || account?.inuse)
            throw new TwitchAllocationError("Twitch account allocation error")
          account.inuse = true
          return account
        })
      } catch (e) {
        ref.onDisconnect().cancel()
        commit("UPDATE_TWITCH_ID", null)
        throw new Error(e.message)
      }
    },
    async disposeTwitchID({ state, commit, rootGetters }) {
      if (!state.twitchID) throw new Error("Undefined Twitch ID")
      const ref = db.ref(
        `orgs/${rootGetters.orgID}/twitchAccounts/${state.twitchID}`
      )
      await ref.update({ inuse: false })
      ref.onDisconnect().cancel()
      commit("UPDATE_TWITCH_ID", null)
    },
    async setStartingStream({ _, rootGetters }, starting = false) {
      const ref = db
        .auxiliary()
        .ref(`org/${rootGetters.orgID}/games/${rootGetters.gameID}`)
      if (!starting) {
        await ref.update({ processingStreaming: false })
        ref.onDisconnect().cancel()
      } else {
        await ref.update({ processingStreaming: true })
        ref.onDisconnect().update({ processingStreaming: null })
      }
    },
    async startDisplayStream({ state, commit }, { performance }) {
      if (state.displayStream) return
      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          audio:
            performance === PERFORMACE_OPTIONS.LOW
              ? AUDIO_PERFORMANCE_CONSTRAINT
              : AUDIO_DEFAULT_CONSTRAINT,
          video: mapPerformance(performance),
          preferCurrentTab: true
        })
        commit("UPDATE_DISPLAY_STREAM", stream)
      } catch (e) {
        throw new DisplayAllocationError(e.message)
      }
    },
    async startMicStream({ state, commit, rootGetters }) {
      if (state.mic) return
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: performance
            ? AUDIO_PERFORMANCE_CONSTRAINT
            : AUDIO_DEFAULT_CONSTRAINT
        })
        const [track] = stream?.getAudioTracks() || []
        if (track) {
          track.enabled = !rootGetters["auth/user"]?.muted
        }
        commit("UPDATE_MIC_STREAM", stream)
      } catch (e) {
        throw new MicAllocationError(e.message)
      }
    },
    stopDisplayStream({ state, commit }) {
      state.display?.getTracks().forEach(track => track.stop())
      commit("UPDATE_DISPLAY_STREAM", null)
    },
    stopMicStream({ state, commit }) {
      state.mic?.getTracks().forEach(track => track.stop())
      commit("UPDATE_MIC_STREAM", null)
    },
    stopCompositionStream({ state, commit }) {
      state.composition?.getTracks().forEach(track => track.stop())
      commit("UPDATE_COMPOSED_STREAM", null)
    },
    async composeStream({ state, dispatch, commit }, payload) {
      if (state.composition) return
      await dispatch("startDisplayStream", payload)
      await dispatch("startMicStream", payload)
      try {
        const composedStream = new MediaStream()
        const audioContext = new AudioContext()
        const dest = audioContext.createMediaStreamDestination()

        // add the video stream from the screen
        const [screenVideoTrack] = state.display.getVideoTracks()
        const [screenAudioTrack] = state.display.getAudioTracks()

        if (state.mic) {
          const audioSource1 = audioContext.createMediaStreamSource(state.mic)
          audioSource1.connect(dest)
        }

        if (screenAudioTrack) {
          const screenAudioStream = new MediaStream()
          screenAudioStream.addTrack(screenAudioTrack)

          const audioSource2 =
            audioContext.createMediaStreamSource(screenAudioStream)
          audioSource2.connect(dest)
        }

        const [destAudioTrack] = dest.stream.getAudioTracks()

        if (destAudioTrack) {
          composedStream.addTrack(destAudioTrack)
        }

        composedStream.addTrack(screenVideoTrack)

        commit("UPDATE_COMPOSED_STREAM", composedStream)
      } catch (e) {
        throw new StreamCompositionError(e.message)
      }
    },
    stopStreaming({ state }) {
      if (state.unsubscribe) return state.unsubscribe()
    },
    async startStreaming(
      { state, commit, dispatch },
      { twitchKey, debug, performance, gameID, streamUrl, twitchID, uuid }
    ) {
      commit("UPDATE_ERROR", null)
      await dispatch("acquireTwitchID", { twitchID })

      try {
        await dispatch("composeStream", { performance })
        await dispatch("setStartingStream", true)
      } catch (e) {
        try {
          await dispatch("disposeTwitchID")
          await dispatch("setStartingStream", false)
        } catch (e) {
          console.log(e)
        }
        throw e
      }

      // update game object with a stream URL
      await dispatch("acquireGameID", { gameID, streamUrl })

      const recorder = new MediaRecorder(
        state.composition,
        RECORD_DEFAULT_CONFIG
      )

      const base = `rtmp=${TWITCH_RTMP}${twitchKey}`

      const query = debug ? `${base}&debug=true` : base

      console.log("streaming to ", WEBSOCKET_STREAM_ENDPOINT)

      const socket = io(WEBSOCKET_STREAM_ENDPOINT, {
        query,
        path: "/stream" /*, transports: ["websocket"]*/
      })

      const onRecorderData = e => {
        console.log("Sending data...")
        console.log(e.data)
        socket.binary(true).emit("data", e.data)
      }
      const onRecorderStop = () => socket.disconnect()
      const onRecorderError = e => {
        console.error(e)
        commit("UPDATE_ERROR", new MediaRecorderError(e.message))
        socket.disconnect()
      }

      const onSocketStderr = data => console.log(data)
      const onFfmpegError = message => {
        commit("UPDATE_ERROR", new MediaServerError(message))
      }
      const onSocketConnect = () => {
        console.log("connect")
        recorder.addEventListener("dataavailable", onRecorderData)
        recorder.addEventListener("stop", onRecorderStop)
        recorder.addEventListener("error", onRecorderError)
        // start recording and dump data every 2 sec
        recorder.start(2000)
      }

      const unsubscribe = async reason => {
        // release socket
        socket.off("connect", onSocketConnect)
        socket.off("disconnect", unsubscribe)
        socket.off("ffmpeg_error", onFfmpegError)
        if (debug) socket.off("stderr", onSocketStderr)
        // if the reason is given, it's been already disconnected
        if (reason === undefined) {
          console.log("disconnect by user")
          socket.disconnect()
        } else {
          commit("UPDATE_ERROR", new MediaServerError(reason))
          console.log(`disconnect due to "${reason}"`)
        }
        // release media recorder
        recorder.removeEventListener("dataavailable", onRecorderData)
        recorder.removeEventListener("stop", onRecorderStop)
        recorder.removeEventListener("error", onRecorderError)
        try {
          recorder.stop()
        } catch (e) {
          console.warn(e)
        }
        // stop media streams
        try {
          await dispatch("stopCompositionStream")
        } catch (e) {
          console.error(e)
        }
        try {
          await dispatch("stopMicStream")
        } catch (e) {
          console.error(e)
        }
        try {
          await dispatch("stopDisplayStream")
        } catch (e) {
          console.error(e)
        }
        // release global stream status signal
        try {
          dispatch("setStartingStream")
        } catch (e) {
          console.log(e)
        }
        // release Twitch DB resource
        try {
          await dispatch("disposeTwitchID")
        } catch (e) {
          console.log(e)
        }
        // release game DB resource
        try {
          await dispatch("disposeGameID")
        } catch (e) {
          console.log(e)
        }
        // destroy itself
        commit("UPDATE_UNSUBSCRIBE", null)
      }

      commit("UPDATE_UNSUBSCRIBE", unsubscribe)

      if (debug) socket.on("stderr", onSocketStderr)
      socket.on("disconnect", unsubscribe)
      socket.on("ffmpeg_error", onFfmpegError)
      socket.on("connect", onSocketConnect)
    },
    setIsSharingScreen({ commit }, value) {
      commit("UPDATE_IS_SHARING_SCREEN", value)
    }
  },
  getters: {
    streaming: state => !!state.unsubscribe,
    twitchID: state => state.twitchID,
    error: state => state.error,
    performance: state => state.performance,
    isProcessingStream: state => state.isProcessingStream,
    isSharingScreen: state => state.isSharingScreen,
    isSharingOnlyAudio: state => state.isSharingOnlyAudio,
    showScreenShareDialog: state => state.showScreenShareDialog,
    isProcessingScreenSharing: state => state.isProcessingScreenSharing
  }
}

export default ScreenCapture
