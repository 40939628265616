










































import { ConfirmMixin } from "@/mixins"
import { RtbButton, RtbControlButton } from "@/components/ui"
import isHTML from "@/helpers/is-html"

export default ConfirmMixin.extend({
  name: "DefaultConfirm",
  components: { RtbButton, RtbControlButton },
  props: {
    title: {
      type: String,
      required: false,
      default: "Are you sure?"
    },
    noText: {
      type: String,
      default: "No"
    },
    yesText: {
      type: String,
      default: "Yes"
    },
    buttonColor: {
      type: String,
      default: undefined
    },
    message: {
      type: String,
      required: true
    }
  },
  computed: {
    isHTML() {
      return isHTML(this.message)
    }
  }
})
