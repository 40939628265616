import { store } from "../store/index"
import { db, ServerValue } from "@/firebase"
import { getCurrentUser } from "@/services/auth.service"
import Mode from "@shared/enums/Mode"
import Mission from "@shared/Mission"
import MissionCollection from "@shared/MissionCollection"
import Sfx from "@shared/Sfx"
import User from "@shared/User"
import MissionType from "@shared/enums/Mission"
import normalizeEmail from "@shared/helpers/normalize-email"
import shuffle from "array-shuffle"
import * as moment from "moment"
import { mapState, mapActions, mapGetters } from "vuex"
import { serialize } from "@/helpers"

const initialState = () => ({
  right: false,
  wrong: false,
  finalwrong: false,
  received: false,
  theTime: "0",
  timesUp: false,
  timer: 0,
  test: 0,
  progressColor: "green darken-4",
  currentTry: 1,
  missionStatus: "active",
  teamPlayed: false,
  imageName: "",
  imageUrl: "",
  imageFile: "",
  loadingImage: false,
  print: false,
  hasImage: false,
  showPhotoModal: false,
  showPhoto: null,
  userPlayed: {},
  gifs: [],
  gifCounter: 1,
  gifSearch: null,
  answer: null,
  caption: null,
  multiAnswer: 0,
  gotItRight: false,
  submitted: false,
  checking: false
})

export const createPlay = ({
  user,
  mission,
  answer,
  game,
  teamID,
  points = 0,
  teamname = ""
}) => ({
  answer,
  behavior: mission?.behavior,
  mission: mission.name,
  missionID: mission?.id,
  missionOriginID: mission?.originID || null,
  userID: user.id,
  role: user.role,
  username: user.firstname,
  firstName: user.firstname,
  lastName: user.lastname,
  email: normalizeEmail(getCurrentUser().email, game.clientID) || null,
  game: game.name,
  gameID: game.theKey,
  points,
  show: true,
  teamID,
  teamname,
  time: moment(new Date()).unix()
})

export const GameMixin = {
  data: () => initialState(),
  watch: {
    currentMission(prevState, curState) {
      if (prevState && curState) {
        if (prevState.pos !== curState.pos) {
          this.missionStatus = "active"
          this.answer = null
          this.multiAnswer = null
          this.hasImage = null
          this.imageFile = null
          this.caption = null
          this.currentTry = 1
        }
      }
    }
  },
  computed: {
    ...mapState({
      gameStatus: state => state.mission.gameStatus,
      play: state => state.play.play,
      teams: state => state.chat.chats,
      typicalModes: state => state.typicalModes,
      assignedTeamId: state => state.drawing.assignedTeamId
    }),
    ...mapState("group", ["modes"]),
    ...mapGetters("livechat", ["roomID"]),
    ...mapGetters("GameUsers", ["usersOnlineArray"]),
    ...mapGetters({ missionID: "currentMission" }),
    ...mapGetters([
      "actualGameID",
      "orgID",
      "plays",
      "user",
      "teamID",
      "game",
      "getCurrentMode",
      "missionPlays",
      "nOfMissionTries",
      "missionPlaysArray",
      "missionTeamPlaysArray",
      "missionUserPlaysArray",
      "missionCompleted",
      "missionSuccess",
      "missionCorrectAnswer",
      "missionSuccessfulPlays",
      "anyTeamMissionCompletedPlays",
      "onlineUsersGroupedByTeam",
      "nOfAllCorrectMissionPlays"
    ]),
    ...mapGetters("auth", ["isPlayer", "isHost", "isAudit", "isSpectator"]),
    isHybridRoom() {
      return this.$store.getters["auth/isHybridRoom"]
    },
    team() {
      return this.teamID && this.teams ? this.teams[this.teamID] : null
    },
    currentTeamID() {
      return this.$store.getters["group/canViewerSelectGlobalTeam"]
        ? this.$store.getters["group/globalTeamID"]
        : this.teamID
    },
    teamUsers() {
      return this.onlineUsersGroupedByTeam[this.currentTeamID] || []
    },
    teamMission() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Team: Speed Does Not Matter" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    },
    speedMatters() {
      if (
        this.currentMission.playType == "Team: Speed Matters" ||
        this.currentMission.playType == "Individual: Speed Matters" ||
        this.currentMission.playType == undefined
      ) {
        return true
      }
      return false
    },
    currentMission() {
      return store.getters.getCurrentMission
    },
    isSocialVideoMission() {
      return this.currentMission?.behavior === MissionType.Video
    }
  },
  methods: {
    ...mapActions("soundeffect", ["updateTeamSoundEffect"]),
    ...mapActions(["updateUser"]),
    hasVoting() {
      const mission = this.$store.getters.getCurrentMission
      if (mission == null) return false
      if (mission.activityId == null) return Boolean(mission[Mode.Voting])
      const missions = MissionCollection.normalize(
        this.$store.getters.missions || {}
      )
      for (const m of missions) {
        if (m.activityId != mission.activityId) continue
        if (m[Mode.Voting] != null) return true
      }
      return false
    },
    sortedArray(arr, field) {
      return arr.sort((a, b) => b[field] - a[field])
    },
    userScore(userID) {
      if (!this.plays.length) return 0
      if (!userID) return 0
      return this.plays
        .filter(item => item && item.score && item.userID === userID)
        .reduce((acc, val) => acc + (parseInt(val.score) || 0), 0)
    },
    incorrectPlays() {
      let data = this.missionPlaysArray
      if (!data) return 0
      if (!data.length) return 0
      const teamID = this.currentTeamID
      if (this.teamMission) {
        data = data.filter(item => {
          // filter by correct and missionid
          return !item.result && item.teamID == teamID
        })
      } else {
        data = data.filter(item => {
          // filter by correct and missionid
          return !item.result && item.userID == this.user.id
        })
      }
    },
    correctPlays() {
      let data = this.missionPlaysArray
      if (!data) return 0
      if (!data.length) return 0
      const teamID = this.teamID
      if (this.teamMission) {
        data = data.filter(item => {
          // filter by correct answer
          return item.result && item.teamID == teamID
        })
      } else {
        data = data.filter(item => {
          // filter by correct answer
          return item.result && item.userID == this.user.id
        })
      }
      return data.length
    },
    correctMissionPlays() {
      let data = this.missionPlaysArray
      if (!data) return 0
      if (!data.length) return 0
      // filter by correct answer
      data = data.filter(item => item.result)
      return data.length
    },
    getInt(value) {
      return parseInt(value) || 0
    },
    computeScore({ isTimeLimited, nOfTeams, nOfCorrect, points }) {
      const pointsInt = this.getInt(points)
      if (isTimeLimited) {
        const nOfTeamsInt = this.getInt(nOfTeams)
        const nOfCorrectInt = this.getInt(nOfCorrect)
        // can't divide by zero
        if (nOfTeamsInt < 1) return 0
        const n = ((nOfTeamsInt - nOfCorrectInt) / nOfTeamsInt) * pointsInt
        return Math.round(n)
      } else {
        return pointsInt
      }
    },
    // the function must be refactored so that as computations happen, they fill
    // an array with promises and then resolve in bulk in the end of the function
    // that would speed submit time up and make it more predictable
    async checkAnswer({ user, sentMissionID } = {}) {
      if (this.checking) return console.warn("still checking...")
      this.checking = true
      user = user ?? this.user
      try {
        var teamname = null
        var teamID = null

        if (!this.team) {
          teamname = "Host"
          teamID = 0
        } else {
          teamID = this.currentTeamID
          teamname = this.team.name
        }

        var points = this.currentMission.points
        const behavior = this.currentMission.behavior

        if (
          !this.teamMission &&
          ![
            MissionType.FamilyFeud,
            MissionType.Categories,
            MissionType.Charades
          ].includes(behavior)
        ) {
          points = parseInt(points / Math.max(this.teamUsers.length, 1)) || 0
        }

        var pollAns = this.multiAnswer

        // create answer
        var payload = createPlay({
          user,
          mission: this.currentMission,
          answer: [this.answer],
          game: this.game,
          teamID,
          points,
          teamname
        })

        if (this.game.clientID) {
          payload.clientID = this.game.clientID
        }

        if (sentMissionID) {
          payload.missionID = sentMissionID
        }

        // got it right
        console.log("BAHVIOR ", behavior)
        if (behavior === MissionType.Photo && this.imageUrl) {
          this.gotItRight = true
          this.answer = { image: this.imageUrl, caption: this.caption }
        } else if (
          [
            MissionType.TakePhoto,
            MissionType.TeamPhoto,
            MissionType.PhotoBooth
          ].includes(behavior) &&
          this.imageUrl
        ) {
          this.gotItRight = true
          this.answer = { image: this.imageUrl }
          if (this.currentMission.useAsAvatar) {
            await this.$store.dispatch("updateUser", {
              userID: user.id,
              obj: { image: this.imageUrl }
            })
          }
        } else if (
          [
            MissionType.Video,
            MissionType.VideoIndividual,
            MissionType.VideoTeam
          ].includes(behavior)
        ) {
          this.gotItRight = true
          this.answer = { video: this.videoUrl }
          payload.pos = user?.pos ?? null
        } else if (
          (behavior == MissionType.Represent ||
            behavior == MissionType.RoyalRumble) &&
          this.imageUrl
        ) {
          this.gotItRight = true
          this.answer = { image: this.imageUrl }
        } else if (
          [MissionType.Text, MissionType.DrawingPictionary].includes(behavior)
        ) {
          var ansArr = this.currentMission.answer.split(",")
          for (var i in ansArr) {
            if (serialize(this.answer) === serialize(ansArr[i])) {
              this.gotItRight = true
            }
          }
        } else if (behavior === MissionType.Unlock) {
          var ansArr = this.currentMission.answer.split(",")
          for (var i in ansArr) {
            if (serialize(this.answer) === serialize(ansArr[i])) {
              this.gotItRight = true
            }
          }
          if (this.gotItRight) {
            const { currentMission, teamUsers, game } = this
            const {
              clientID,
              theKey: gameID,
              originalGameID,
              copiedFrom
            } = game
            const users = teamUsers || []
            const targetGameID = currentMission?.unlockGameID
            const now = moment().unix()

            const update = {}

            for (const user of users) {
              const id = user?.id
              if (!id) continue
              if (targetGameID)
                update[
                  `client/${clientID}/usersUnlockedGames/${id}/${targetGameID}`
                ] = now
              if (copiedFrom)
                update[`client/${clientID}/history/${id}/${copiedFrom}`] = now
              update[
                `client/${clientID}/history/${id}/${originalGameID || gameID}`
              ] = now
            }

            await db.auxiliary().ref().update(update)
          }
        } else if (
          [MissionType.FamilyFeud, MissionType.Categories].includes(behavior)
        ) {
          this.gotItRight = this.missionCorrectAnswer
            .reduce((acc, val) => {
              val
                .split(",")
                .filter(Boolean)
                .forEach(string => acc.push(serialize(string)))
              return acc
            }, [])
            .includes(serialize(this.answer))
        } else if (
          behavior === MissionType.FreeForm &&
          this.currentMission.highestNumber
        ) {
          this.gotItRight = true
          console.log("MissionPlays", this.missionPlaysArray)
          var obj = {}
          obj.id = 0
          obj.correct = this.answer
          var submissions = [obj]
          const points = this.currentMission.points
          console.log("Points", points)
          for (var i in this.missionPlaysArray) {
            submissions.push(this.missionPlaysArray[i])
          }
          submissions = submissions.sort((a, b) => a.correct - b.correct)
          console.log("SUBMISSIONS", submissions)
          const l = submissions.length
          var multiplier = 1
          var numerator = 0
          var baselineScore = 0
          for (var i in submissions) {
            // console.log("SUBMISSION #", i + 1)
            // console.log("SUBMISSION NAME", submissions.firstName)
            // console.log("LENGTH", l)
            numerator = parseInt(i) + 1
            multiplier = numerator / l
            if (submissions[i].id == 0) {
              payload.score = parseInt(points * multiplier)
            } else {
              // console.log("POINTS #", parseInt(points * multiplier))
              submissions[i].score = parseInt(points * multiplier)
              await store.dispatch("addPlay", submissions[i])
              // console.log("EXISTING SCORE", submissions[i].score)
              // console.log("CURRENT TEAM SCORE", this.teams[submissions[i].teamID].totalScore)
              baselineScore =
                this.teams[submissions[i].teamID].totalScore -
                parseInt(submissions[i].score)
              // console.log("BASELINE SCORE", baselineScore)
              submissions[i].teamScore = baselineScore + submissions[i].score
              // console.log("NEW TEAM SCORE", submissions[i].teamScore)
              await this.$store.dispatch("setTeamScore", submissions[i])
            }
          }
        } else if (
          [
            MissionType.FreeForm,
            MissionType.FactMatch,
            MissionType.TwoTruths,
            MissionType.RatingPoll,
            MissionType.DrawingEachTeam
          ].includes(behavior)
        ) {
          if (this.answer) {
            this.gotItRight = true
          }
          if (this.imageUrl) {
            this.gotItRight = true
            this.answer = {
              image: this.imageUrl,
              caption: this.caption
            }
          }
        } else if (behavior == "Giphy" && this.answer && this.caption) {
          this.gotItRight = true
        } else if (behavior == "URL") {
          if (this.answer) {
            if (this.answer.split("v=")[1]) this.gotItRight = true
          }
        } else if (behavior == "Team Name" && this.answer) {
          this.gotItRight = true
          let obj = {}
          obj.id = this.currentTeamID
          obj.name = this.answer
          await store.dispatch("changeTeamName", obj)
          if (User.isMobile(this.user) && this.isHybridRoom) {
            await this.$store.dispatch("updateUser", {
              userID: user.id,
              obj: { firstname: this.answer, lastname: "" }
            })
          }
        } else if (behavior == "Multiple Choice") {
          if (this.multiAnswer + 1 == this.currentMission.multiCorrect) {
            this.gotItRight = true
          }
          // console.log("MULITPLE CHOICE", this.multipleChoice[this.multiAnswer])
          this.answer = this.multipleChoice[this.multiAnswer]
        } else if (behavior == "Two Truths Reveal") {
          // >>>>>> TWO TRUTHS REVEAL >>>>>>
          const { gameStatus, currentMission: mission } = this
          const { twoTruthsMissionID } = mission
          const play = this.plays.find(
            ({ missionID, userID }) =>
              missionID === twoTruthsMissionID &&
              userID === gameStatus.twoTruthsID
          )
          // for now, let's assume that play.answer is the source of truth
          // and we can expect 2nd element to be the lie
          const options = play.correct || []
          const correct = options[2]
          if (correct === this.twoTruthsInput) {
            this.gotItRight = true
          }
          this.answer = this.twoTruthsInput
        } else if (behavior === "Poll") {
          if (
            (pollAns >= 0 && pollAns != null) ||
            this.currentMission.nominateScribe
          ) {
            this.gotItRight = true
          }
          this.answer = this.multipleChoice[pollAns]
        } else if (behavior === "Award") {
          if (pollAns >= 0 && pollAns != null) {
            this.gotItRight = true
          }
          payload.awardID = this.awardID
          this.answer = this.multipleChoice[pollAns]
        } else if (behavior === MissionType.HandMovement) {
          this.gotItRight = true
        }

        // add to the answer array on play
        if (this.play && this.play.answer) {
          payload.id = this.play.id
          if (this.play.answer) {
            var ans = Object.values(this.play.answer)
            ans.push(this.answer)
            payload.answer = ans
          }
        } else {
          payload.answer = [this.answer]
        }

        // god why
        if (behavior === "Two Truths Reveal") {
          const { gameStatus } = this
          payload.id = null
          payload.answer = [this.twoTruthsInput]
          payload.twoTruthsID = gameStatus.twoTruthsID
        }

        // Override whatever
        if (behavior === "Two Truths") {
          payload.id = null
          payload.answer = shuffle(this.answer || [])
          payload.correct = this.answer || []
        }

        let score

        if (this.gotItRight) {
          if (
            !Mission.isPausable(this.currentMission) &&
            !this.currentMission.disableActionSfx
          )
            this.updateTeamSoundEffect(Sfx.CORRECT)

          payload.result = true

          if (
            [
              MissionType.TakePhoto,
              MissionType.PhotoBooth,
              MissionType.Photo,
              MissionType.Poll,
              MissionType.FreeForm,
              MissionType.TeamName,
              MissionType.URL,
              MissionType.Giphy
            ].includes(behavior)
          ) {
            this.received = true
          } else {
            this.right = true
          }

          if (
            [
              MissionType.FamilyFeud,
              MissionType.Categories,
              MissionType.Charades
            ].includes(behavior)
          ) {
            this.received = true
            this.right = true
          }

          const correct = this.correctMissionPlays()

          let numTeams = 0

          if (this.teams) {
            if (this.teamMission) {
              numTeams = Object.keys(this.teams).length
            } else {
              numTeams = this.usersOnlineArray.length
            }
          }

          score = this.computeScore({
            isTimeLimited: this.speedMatters,
            nOfCorrect: correct,
            nOfTeams: numTeams,
            points
          })

          if (behavior === MissionType.Award) {
            score = 5
          }

          if (score <= 0) {
            score = 5
          }

          payload.score = behavior === MissionType.VideoTeam ? 0 : score

          console.log(`this.hasVoting()`, this.hasVoting())

          if (this.hasVoting() || behavior === MissionType.FamilyFeud) {
            payload.score = 0
          }

          if (behavior === MissionType.FamilyFeud) {
            payload.show = false
          }

          this.missionStatus = "completed"
          console.log(payload)
          if (
            [
              MissionType.FamilyFeud,
              MissionType.Categories,
              MissionType.Charades
            ].includes(behavior)
          ) {
            this.missionStatus = "active"
          }
          if ([MissionType.URL, MissionType.Giphy].includes(behavior)) {
            var obj = {}
            obj.image = this.answer
            if (this.caption) {
              obj.caption = this.caption
            }
            payload.correct = obj
          } else if (behavior === MissionType.HandMovement && this.team) {
            if (this.team?.handmovement?.value) {
              payload.score =
                (parseInt(this.team.handmovement.value) / 100) *
                  parseInt(this.currentMission?.points) || 0
            }
          } else {
            payload.correct = this.answer
          }
          payload.completed = true
          this.answer = null
        } else {
          if (
            !Mission.isPausable(this.currentMission) &&
            !this.currentMission.disableActionSfx
          )
            this.updateTeamSoundEffect(Sfx.WRONG)

          payload.result = false
          payload.score = 0
          payload.correct = this.answer // kinda a kludge to store the answer and show on submissions.
          this.currentTry++
          this.wrong = true
          if (
            this.incorrectPlays >= this.nOfMissionTries - 1 ||
            this.currentTry > this.numOfTriesActual ||
            this.nOfMissionTries == 1
          ) {
            this.wrong = false
            this.finalwrong = true
            payload.completed = true
            this.missionStatus = "completed"
          }

          const teamID = this.teamID
          const users = this.usersOnlineArray
          const numOfTries = this.numOfTriesActual
          const pts = parseInt(this.currentMission?.points) || 0

          let deduction = numOfTries > 0 ? pts / numOfTries : pts / 5
          if (
            [
              MissionType.FamilyFeud,
              MissionType.Categories,
              MissionType.Charades,
              MissionType.DrawingPictionary
            ].includes(behavior)
          ) {
            deduction = 0
          }

          const update = {}
          const value = ServerValue.increment(deduction * -1)
          const clientID = this.game?.clientID

          for (const user of users) {
            if (user?.id && user.teamID === teamID) {
              update[`client/${clientID}/userscore/${user.id}`] = value
            }
          }

          await db.auxiliary().ref().update(update)
        }

        if (behavior === MissionType.Categories && this.gotItRight) {
          delete payload.id
          this.gotItRight = false // reset for next one
        }

        // just give the one person the score cause this is individual
        // only let one submission for the drawing

        const updateTeamScore = async payload => {
          const teamID = this.isHost
            ? this.$store.getters["group/globalTeamID"]
            : payload.teamID
          const { actualGameID: gameID, orgID, teams } = this
          const team = teams[teamID]

          if (!team) return 0

          const clientID = this.game?.clientID

          if (!clientID) return 0

          const score = parseInt(payload.score) || 0

          if (score === 0) return 0

          const update = {}

          const value = ServerValue.increment(score)

          const users = this.usersOnlineArray

          update[`org/${orgID}/game/${gameID}/teams/${teamID}/totalScore`] =
            value

          for (const user of users) {
            if (user?.id && user.teamID === teamID) {
              update[`client/${clientID}/userscore/${user.id}`] = value
            }
          }

          await db.auxiliary().ref().update(update)
        }

        const promises = []

        if (behavior === MissionType.DrawingPictionary && payload.result) {
          const int = parseInt(this.currentMission.points)
          const pointsInt = isNaN(int) ? 10 : int
          if (this.nOfAllCorrectMissionPlays < 2) {
            payload.score = pointsInt
            console.log(`Giving ${payload.score} points to the drawing team`)
            // anonymous payload
            // if (!this.imageUrl) throw new Error("Got no image URL to submit")
            const answers = payload.answer.slice(payload.answer.length - 1)
            const drawingTeamPayload = {
              ...payload,
              // must be just one correct ansewer (to compute corret/wrong right)
              // the corret answer is always the last in the array
              answer: answers,
              // correct: { image: this.imageUrl, caption: answer },
              teamID: this.assignedTeamId,
              userID: 0,
              // the ID must be unique, let get it from firebase
              id: null
            }
            promises.push(store.dispatch("addPlay", payload))
            promises.push(store.dispatch("addPlay", drawingTeamPayload))
            promises.push(updateTeamScore(drawingTeamPayload))
            promises.push(updateTeamScore(payload))
          } else {
            // substract the drawing team correct play as well as
            // the the fist submited teams play
            const n = this.nOfAllCorrectMissionPlays - 1
            console.log("the multiplier is", n)
            const quarter = (pointsInt / 100) * 25
            const reducedScore = pointsInt - quarter * n
            payload.score = reducedScore > 0 ? reducedScore : quarter
            promises.push(store.dispatch("addPlay", payload))
            promises.push(updateTeamScore(payload))
          }
        } else {
          if (behavior === MissionType.DrawingEachTeam) {
            if (!this.submitted) {
              this.submitted = true
              promises.push(store.dispatch("addPlay", payload))
            }
          } else {
            promises.push(store.dispatch("addPlay", payload))
          }
          if (
            payload.result &&
            ![MissionType.VideoTeam, MissionType.HandMovement].includes(
              behavior
            )
          ) {
            promises.push(updateTeamScore(payload))
          }
        }

        await Promise.all(promises)
      } catch (e) {
        console.error(e)
      } finally {
        Object.assign(this.$data, initialState())
      }
    }
  }
}
