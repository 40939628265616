// @ts-nocheck

import Vue from "vue"
import VueCompositionAPI, { provide } from "@vue/composition-api"
import "./firebase"
import "./plugins/vuetify"
import "./plugins/vuexfire"
import App from "./App.vue"

import router from "./router"
import { store } from "./store"
import VueCookies from "vue-cookies"
import AlertComponent from "./components/Shared/Alert.vue"
import VueCountdown from "@chenfengyuan/vue-countdown"
import ImageUploader from "vue-image-upload-resize"
import VuePlyr from "vue-plyr"
import "vue-plyr/dist/vue-plyr.css"
import IdleVue from "idle-vue"
import { IDLE_TIMEOUT } from "@/config"
import VueInputAutowidth from "vue-input-autowidth"
import { sync } from "vuex-router-sync"

const unsync = sync(store, router)

import ConfirmPlugin from "./plugins/confirm"
import InfoPlugin from "./plugins/info"
import SnackbarPlugin from "./plugins/snackbar/snackbar.plugin.ts"
import { BaseComponentsPlugin } from "./plugins/base"
import { VersionPlugin } from "./plugins/version"
import { NavigatorPlugin } from "./plugins/navigator"
import { BusPlugin } from "./plugins/bus"
import { ServicePlugin } from "./plugins/services"
import AddToCalendar from "vue-add-to-calendar"
import "./scss/main.scss"
import "./components/ui/styles/index.scss"
import "./modules/theme/styles/_helpers.scss"
import Clipboard from "v-clipboard"
import { ThemePlugin } from "./modules/theme/plugin"
import { Window } from "./plugins/window"
import QueuePlugin from "./plugins/queue"
import { WevePlugin } from "@weve/ui"
import "@weve/ui/dist/style.css"
import RightToolbar from "@/components/GroupTeams/Common/RightToolbar"

// enable idle logout only during production
console.log("ENV", process.env.VUE_APP_MODE)

if (process.env.VUE_APP_MODE === "production") {
  Vue.use(IdleVue, {
    eventEmitter: new Vue(),
    idleTime: IDLE_TIMEOUT
  })
}

Vue.use(VueCompositionAPI)
Vue.use(WevePlugin)
Vue.use(Window)
Vue.use(ConfirmPlugin)
Vue.use(InfoPlugin)
Vue.use(SnackbarPlugin)
Vue.use(VueInputAutowidth)
Vue.use(NavigatorPlugin, { store, router })
Vue.use(AddToCalendar)
Vue.use(BaseComponentsPlugin)
Vue.use(BusPlugin)
Vue.use(QueuePlugin)
Vue.use({
  install(Vue) {
    Vue.prototype.$kill = media => {
      try {
        media.pause()
        media.srcObject = null
        media.src = ""
        media.remove()
        media.load()
      } catch (e) {
        console.log(e.message)
      }
    }
  }
})
Vue.use(VersionPlugin)
Vue.use(VueCookies)
Vue.use(ImageUploader)
Vue.use(VuePlyr)
Vue.use(ServicePlugin, { store })
Vue.use(ThemePlugin, { store })
Vue.use(Clipboard)

Vue.component(VueCountdown.name, VueCountdown)
Vue.component("app-alert", AlertComponent)
Vue.component("RightToolbar", RightToolbar)

Vue.config.productionTip = false

const __app__ = new Vue({
  onIdle() {
    this.$navigator.navigateTo("/logout")
  },
  setup() {
    provide("$theme", Vue.prototype.$theme)
  },
  render: h => h(App),
  router,
  store
}).$mount("#app")

window.__app__ = __app__
