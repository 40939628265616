













import Vue from "vue"
import { RtbSnackBar } from "@/components/ui"
export default Vue.extend({
  props: {
    message: {
      type: [String, Array]
    },
    timeout: {
      type: Number,
      default: 6000
    },
    variant: {
      type: String
    },
    right: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  components: {
    RtbSnackBar
  },
  data() {
    return { isVisible: false, promiseResolve: null }
  },
  computed: {
    text() {
      if (Array.isArray(this.message)) {
        return this.message
      }
      return [this.message]
    }
  },
  methods: {
    show() {
      this.isVisible = true
      return new Promise(resolve => (this.promiseResolve = resolve))
    },
    onChange() {
      this.promiseResolve()
    }
  }
})
