import Role from "./role"

export class UserSorter {
  constructor() {}

  static watchersLast(a) {
    if (a.role === Role.Audit || a.role === Role.Spectator) {
      return 1
    } else {
      return -1
    }
  }
}

export class UserFilter {
  /**
   * @param {{ teamID?: number }} user
   */
  static unassignedPredicate(user) {
    return user.gameID === undefined
  }

  /**
   * @param {{ teamID?: number }} user
   */
  static assignedPredicate(user) {
    return user.gameID !== undefined
  }

  /**
   * @param {{ role: string }} user
   */
  static playerPredicate(user) {
    return user.role === Role.Player
  }

  /**
   * @param {{ role: string }} user
   */
  static hostPredicate(user) {
    return user.role === Role.Host
  }

  /**
   * @param {(string|string[])} roles
   */
  static createRolePredicate(role) {
    /** @type {(user: { role: string }) => boolean} */
    const predicate = user => {
      if (Array.isArray(role)) {
        return role.includes(user.role)
      } else {
        return role === user.role
      }
    }

    return predicate
  }

  /**
   * @param {{ role: string }} user
   */
  static watchablePredicate(user) {
    return user.role === Role.Audit || user.role === Role.Spectator
  }
}
