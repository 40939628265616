var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "icon-base",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.size,
        height: _vm.size,
        viewBox: "0 0 18 18",
        "aria-labelledby": _vm.iconName,
        role: "presentation",
      },
    },
    [
      _c("title", { attrs: { id: _vm.iconName, lang: "en" } }, [
        _vm._v(
          " " +
            _vm._s(_vm.iconDesc ? _vm.iconDesc : _vm.iconName + " icon") +
            " "
        ),
      ]),
      _c(
        "g",
        { attrs: { fill: _vm.iconColor } },
        [_vm._t("default"), _vm._t("gradient")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }