var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rtb-file-uploader" },
    [
      _c("form-control-label", { attrs: { for: _vm.inputId } }, [
        _vm._v(_vm._s(_vm.label) + ":"),
      ]),
      _c(
        "div",
        { staticClass: "rtb-file-uploader__controls" },
        [
          _c("text-input", {
            attrs: {
              id: _vm.inputId,
              disabled: _vm.loading,
              invalid: _vm.invalid,
            },
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          }),
          _c(
            "input-button",
            {
              attrs: { title: "Upload", disabled: _vm.loading },
              on: { click: _vm.triggerInput },
            },
            [
              _vm.loading
                ? _c("app-spinner")
                : _c("svg-icon", { attrs: { name: "file-upload-regular" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.error
        ? _c("form-control-error", [_vm._v(_vm._s(_vm.error))])
        : _vm._e(),
      _c("input", {
        ref: "input",
        staticClass: "rtb-file-uploader__input",
        attrs: { type: "file", accept: _vm.accept.join(",") },
        on: {
          change: _vm.changeHandler,
          click: function ($event) {
            return $event.stopImmediatePropagation()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }