
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

export default Vue.extend({
  name: "FormControlError",
  functional: true,
  props: {
    tag: {
      type: String,
      default: "span"
    }
  },
  render(h, { props, children, data }) {
    return h(
      props.tag,
      mergeData(data, { staticClass: "rtb-form-control-error" }),
      children
    )
  }
})
