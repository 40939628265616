
import Vue from "vue"
import type { WithEvents } from "vue-typed-emit"
import type { WithRefs } from "vue-typed-refs"

import SvgIcon from "@/components/base/SvgIcon.vue"

export type Refs = {
  field: HTMLSpanElement
}

export interface Events {
  input: string
  change: string
}

export default (Vue as WithRefs<Refs, WithEvents<Events>>).extend({
  name: "RTBInlineEdit",
  props: {
    value: {
      type: [String, Number]
    }
  },
  /* data() {
    return {
      active: false
    }
  }, */
  methods: {
    emitValue() {
      const value = this.$refs.field.textContent
      if (value !== this.value) {
        this.$emit("input", value)
        this.$emit("change", value)
      }
    }
  },
  render(h) {
    return h(
      "span",
      {
        staticClass: "rtb-inline-edit"
        /* class: { "rtb-inline-edit--active": this.active } */
      },
      [
        h(
          "span",
          {
            ref: "field",
            attrs: {
              contenteditable: true /* this.active ? "true" : undefined */
            },
            staticClass: "rtb-inline-edit__field",
            on: {
              blur: () => {
                /* this.active = false */
                this.emitValue()
              }
            }
          },
          this.value
        )
        /* h(
          "button",
          {
            attrs: {
              type: "button"
            },
            staticClass: "rtb-inline-edit__button",
            on: {
              click: () => {
                if (this.active) {
                  this.emitValue()
                  this.active = false
                } else {
                  this.active = true

                  this.$nextTick(() => {
                    this.$refs.field.focus()
                  })
                }
              }
            }
          },
          [
            h(SvgIcon, {
              props: { name: this.active ? "check-regular" : "pen-regular" }
            })
          ]
        ) */
      ]
    )
  }
})
