var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            size: "sm",
            title: _vm.title,
            "title-size": "lg",
            "hide-overlay": !_vm.forced,
            persistent: _vm.forced,
            "content-class": "default-info",
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        },
        "v-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-flex",
        {
          staticClass: "popup-content",
          staticStyle: { "max-width": "initial" },
        },
        [
          !!_vm.title
            ? _c("v-layout", [
                _c("h3", { staticClass: "popup-header" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                ]),
              ])
            : _vm._e(),
          !!_vm.message
            ? _c(
                "v-layout",
                [
                  _vm.isHTML
                    ? _c("v-flex", {
                        staticClass: "popop-text",
                        domProps: { innerHTML: _vm._s(_vm.message) },
                      })
                    : _c("v-flex", { staticClass: "popup-text" }, [
                        _vm._v(" " + _vm._s(_vm.message) + " "),
                      ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-layout",
            { attrs: { "justify-center": "", "mt-4": "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    large: "",
                    dark: "",
                    color: "deep-purple lighten-1",
                    id: "default-info-primary-action-btn",
                  },
                  on: { click: _vm.accept },
                },
                [_vm._v(" OK ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }