<template>
  <RtbControlButton
    v-if="state.open"
    class="audio-drawer-button"
    sm
    @click="close"
  >
    <v-icon key="close" style="margin: -4px 0; color: white">close</v-icon>
  </RtbControlButton>
  <RtbControlButton v-else class="audio-drawer-button" sm @click="open">
    <svg-icon name="chevron-left-regular" size="small" class="mr-1" />
    <v-icon dark>music_note</v-icon>
  </RtbControlButton>
</template>
<script>
import { RtbControlButton } from "@/components/ui"

import state, { open, close, toggle } from "@/components/drawers/audio"
import { KEY_J } from "keycode-js"

export default {
  name: "AudioDrawerToggle",
  components: {
    RtbControlButton
  },
  data() {
    return {
      state
    }
  },
  mounted() {
    window.addEventListener("keyup", this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onKeyUp)
  },
  methods: {
    open,
    close,
    onKeyUp(e) {
      if (
        ["text", "textarea", "search", "number"].includes(e?.target?.type) &&
        ![null, undefined, ""].includes(e?.target?.value)
      )
        return

      if (e.keyCode === KEY_J && e.shiftKey) toggle()
    }
  }
}
</script>
<style>
.audio-drawer-button {
  width: 60px;
  margin-left: 8px;
  margin-right: 4px;
}
</style>
