const Role = require("./enums/Role")
const Collection = require("./Collection")

const isLobbyPattern = string => String(string).includes("/lobby/")
const isPlayingPettern = string => String(string).includes("/game/")
const isSelectingTeamPattern = string => String(string).includes("/pickteams/")
const isOnboardingPattern = string =>
  String(string).includes("/login/") || String(string).includes("/latecomer/")

const { v4: uuidv4 } = require("uuid")

class User extends Collection {
  constructor(args) {
    const userID = uuidv4()
    const sessionID = args?.sessionID
    let path = "/game/"

    if (sessionID) {
      path = `/game/${
        args.lobby ? `${sessionID}${args.gameID || null}` : sessionID
      }`
    }

    args.clientID = sessionID

    delete args[sessionID]

    return {
      id: userID,
      firstname: "Anonymous",
      lastname: "",
      loginTimestamp: 0,
      muted: false,
      role: Role.PLAYER,
      selected: false,
      verificationRequired: false,
      path,
      ...args
    }
  }
  static sortByWaitingTime(a, b) {
    return a.noGameTimestamp - b.noGameTimestamp
  }
  static isAlive(user, now) {
    return (
      (user && user.headless) ||
      (parseInt(user && user.heartbeat) || 0) >
        (now || Date.now()) - User.LIFETIME_DURATION
    )
  }
  static isConference(user) {
    return Boolean(user && user.conference)
  }
  static isMuted(user) {
    return Boolean(user && (user.muted || user.permanentMuted))
  }
  static isHeadless(user) {
    return Boolean(user && user.headless)
  }
  static isScribe(user) {
    return Boolean(user && user.selected)
  }
  static isSelectable(user) {
    return (
      User.isPlayer(user) &&
      User.isPlaying(user) &&
      !user.skip &&
      !User.isSpecialPurposeUser(user)
    )
  }
  static isPlayer(user) {
    return user && user.role === Role.PLAYER
  }
  static isAuditor(user) {
    return user && user.role === Role.AUDITOR
  }
  static isAuditorLike(user) {
    return User.isAuditor(user) || User.isSpectator(user)
  }
  static isSpectator(user) {
    return user && user.role === Role.SPECTATOR
  }
  static isHost(user) {
    return Boolean(user && user.role === Role.HOST)
  }
  static isIRL(user) {
    return (
      user &&
      (User.isMobile(user) || User.isObserver(user) || User.isPresenter(user))
    )
  }
  static isSelectingRoom(user) {
    return isLobbyPattern(user && user.path)
  }
  static isSelectingTeam(user) {
    return isSelectingTeamPattern(user && user.path)
  }
  static isAnon(user) {
    return Boolean(user && user.anonymous)
  }
  static isMobile(user) {
    return Boolean(user && user.mobile)
  }
  static isSpecialPurposeUser(user) {
    return User.isObserver(user) || User.isPresenter(user)
  }
  static isObserver(user) {
    return Boolean(user && user.observer)
  }
  static isPresenter(user) {
    return Boolean(user && user.presenter)
  }
  static isPlaying(user) {
    return isPlayingPettern(user && user.path)
  }
  static isOnboarding(user) {
    return isOnboardingPattern(user && user.path)
  }
  static sort(a, b) {
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return 0
  }
  static scribe(user) {
    user.selected = true
    user.muted = false
  }
  static unscribe(user) {
    user.selected = false
  }
  static setTeamId(user, id) {
    user.teamID = id
  }
  static setOriginalTeamId(user, id) {
    user.originalTeamID = id
  }
  static getShortenedName(user) {
    const firstname = (user && user.firstname) || ""
    const lastname = (user && user.lastname) || ""
    const postfix = lastname[0] ? `${lastname[0]}.` : ""
    return `${firstname} ${postfix}`.trim()
  }
  static isJoinable(user) {
    return user.role !== Role.AUDITOR && user.role !== Role.SPECTATOR
  }
}

User.HEARTBEAT_INTERVAL = 10 * 1000 // 10 sec
User.LIFETIME_DURATION = 60 * 1000 * 1 // 1 min

module.exports = User
