



























// @ts-nocheck
// TODO(Andrew): remove "ts-nocheck" when time comes
import Vue, { PropType } from "vue"
import type { WithEvents } from "vue-typed-emit"
import type { WithRefs } from "vue-typed-refs"
import shortid from "shortid"

import FormControlLabel from "../FormControlLabel/FormControlLabel.vue"
import FormControlError from "../FormControlError/FormControlError.vue"
import TextInput from "../TextInput/TextInput.vue"
import InputButton from "../InputButton/InputButton.vue"
import AppSpinner from "../Spinner/Spinner.vue"

import { validateFile } from "./utils"

interface Uplaoder {
  upload: unknown
}

type Refs = {
  input: HTMLInputElement
}

interface Events {
  input: string
  file: File | null
}

export default (Vue as WithEvents<Events, WithRefs<Refs>>).extend({
  name: "RTBFileUploader",
  components: {
    FormControlLabel,
    FormControlError,
    TextInput,
    InputButton,
    AppSpinner
  },
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      required: true
    },
    uploader: {
      type: Object as PropType<Uplaoder>,
      required: true
    },
    inputId: {
      type: String,
      default() {
        return shortid.generate()
      }
    },
    fileName: {
      type: Function as PropType<(file: File) => string>,
      required: true
    },
    accept: {
      type: Array,
      required: true
    },
    error: {
      type: String
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      file: null as File | null
    }
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(value: string) {
        this.$emit("input", value)
      }
    }
  },
  watch: {
    file(value: File | null) {
      this.$emit("file", value)
    }
  },
  methods: {
    triggerInput() {
      this.$refs.input.click()
    },
    changeHandler(e: InputEvent) {
      const { target } = e
      if (target !== null && target instanceof HTMLInputElement) {
        if (target.files) {
          if (target.files.length) {
            this.file = target.files[0]
            this.$refs.input.value = ""
            this.handleFile()
          }
        }
      }
    },
    handleFile() {
      this.uploadFile()
    },
    async uploadFile() {
      this.loading = true
      this.uploader
        .upload(this.file, { fileName: this.fileName(this.file) })
        .then(res => {
          this.$emit("input", res)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})
