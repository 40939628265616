/**
 * Game typing
 * @typedef { { startTimestamp?: number, endTimestamp?: number } } Game
 */

export class GameFilter {
  /**
   * @param {Game.GAME_RUN_STATUS} status
   */
  static createStatusPredicate(status) {
    return game => game.runStatus === status
  }
}

export class Game {
  /**
   * @param { Game } game
   * @returns { number }
   */
  static getDuration(game) {
    const { startTimestamp, endTimestamp } = game

    if (startTimestamp && endTimestamp) {
      return endTimestamp - startTimestamp
    } else {
      return 0
    }
  }

  /**
   * Enum for status
   * @readonly
   * @enum {string}
   */
  static GAME_RUN_STATUS = {
    MASTERS: "Masters",
    USER_TEMPLATES: "User Templates"
  }
}
