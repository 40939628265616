import { getCurrentInstance } from "@vue/composition-api"
import type { ComponentInternalInstance } from "@vue/composition-api"

export default function useToast() {
  const instance = getCurrentInstance() as ComponentInternalInstance & {
    proxy: {
      $toast: (...args: any[]) => void
    }
  }

  async function toast(...args) {
    instance.proxy.$toast(...args)
  }

  return { toast }
}
