import { db } from "@/firebase"

const fetchClient = async id => {
  const snapshot = await db.auxiliary(id).ref(`clients/${id}`).once("value")

  return snapshot.val()
}

export { fetchClient }
export class ClientService {
  /**
   * @param {string} orgID
   * @param {string} themeID
   * @param {Record<string, object>} games
   */
  static async applyThemeToGames(orgID, themeID, games) {
    const ref = await db.auxiliary().ref(`org/${orgID}/games`)

    /** @type {Record<string, string>} */
    const update = {}

    for (const gameID in games) {
      update[`${gameID}/themeID`] = themeID
    }

    return ref.update(update)
  }
}
