



























































































































































































































































































































































































































































































































































// @ts-nocheck
import { mapState, mapActions, mapGetters } from "vuex"
import _, { debounce } from "lodash"
import { computed } from "@vue/composition-api"

import Slide from "@/services/slide.service"

import {
  WeveTextarea,
  WeveButton,
  WeveTooltip,
  WeveAnnouncementWidget
} from "@weve/ui"

import { db } from "@/firebase"
import useEnlargedUI from "@/use/useEnlargedUI"
import { PortalTarget } from "portal-vue"
import Role from "@shared/enums/Role"
import { Mode, Mission as MissionType } from "@shared/enums"
import Mission from "@shared/Mission"
import User from "@shared/User"
import isFinaleMission from "@shared/helpers/isGameOver"
import { RtbPillButton, RtbControlButton, RtbButton } from "@/components/ui"
import EventCountdown from "@/components/Lobby/EventCountdown"
import TeamView from "@/components/GroupTeams/Common/TeamView"
import ModeMapper from "@/components/GroupTeams/Common/ModeMapper"
import TeamTag from "@/components/GroupTeams/Common/TeamTag"
import FactMatchResult from "@/components/GroupTeams/Common/FactMatchResult"
import ImageMatchResult from "@/components/GroupTeams/Common/ImageMatchResult"
import OrderTheCardsGameResult from "@/components/GroupTeams/Common/OrderTheCardsGameResult.vue"
import VideoTeamGameResult from "@/components/GroupTeams/Common/GameModes/Results/VideoTeam.vue"
import RaffleConsumer from "@/modules/raffle/core/consumer"
import HostMiniTopBar from "@/components/GroupTeams/Common/HostMiniTopBar"
import CanvasBackground from "@/components/GroupTeams/Common/CanvasBackground"
import HubSpot from "@/services/hubspot.service"
import Popup from "@/components/Popups/Popup"
import SalesPopup from "@/components/Popups/SalesPopup"
import LinkManager from "@/components/GroupTeams/Common/LinkManager"

import IconBase from "@/components/Icon/IconBase"
import IconMic from "@/components/Icon/IconMic"
import IconMicOff from "@/components/Icon/IconMicOff"
import IconExit from "@/components/Icon/IconExit"

import useStore from "@/use/useStore"
import isUserSubmitMode from "@shared/helpers/isUserSubmitMode"

import activityState, {
  open as openActivtyPanel
} from "@/components/drawers/activity"

import quickState, {
  open as openQuickAddPanel
} from "@/components/drawers/quick"

import { open as openVirtualGameShowImageDrawer } from "@/components/drawers/virtual-game-show"

import User from "@shared/User"
import Team from "@shared/Team"

const LOCAL_MODE_RESET_MODES = [Mode.Results, Mode.Welcome, Mode.Explain]

const MAX_WINDOW_HEIGHT = 800 // Source: https://bit.ly/3pCNAS2

import { Misc as DictionaryMisc } from "@/dictionary"

import { Module } from "./Games/JeopardAI/enums"

import Vue from "vue"

export const gameHints = Vue.observable({
  Activity: null,
  ModeToggle: null
})

import useStore from "@/use/useStore"

const FIVE_MINUTES = 5 * 60 * 1000

export default RaffleConsumer.extend({
  name: "Main",
  components: {
    QrCodeActivityPopup: () => import("./QrCodeActivityPopup.vue"),
    JeopardAiTeamResultsText: () =>
      import("./Games/JeopardAI/JeopardAiTeamResultsText.vue"),
    JeopardAiTeamResults: () =>
      import("@/components/Main/JeopardAiTeamResults"),
    TeamResults: () => import("@/components/Main/TeamResults"),
    TeamScroller: () => import("@/components/Main/TeamScroller"),
    HearToggle: () => import("./HearToggle.vue"),
    BreakoutTypeDialog: () =>
      import("@/components/BreakoutTypeDialog/BreakoutTypeDialog.vue"),
    PickTeamsDialog: () => import("@/components/dialogs/PickTeamsDialog"),
    MainDrawer: () => import("@/components/drawers/main/MainDrawer"),
    QuickDrawer: () => import("@/components/drawers/quick/QuickDrawer"),
    VirtualGameShowImageDrawer: () =>
      import("@/components/drawers/virtual-game-show/Drawer.vue"),
    RecordingIndicator: () =>
      import("@/components/Recording/RecordingIndicator"),
    MeetingAnnouncement: () =>
      import("@/components/GroupTeams/Common/MeetingAnnouncement"),
    MeetingRating: () => import("@/components/GroupTeams/Common/MeetingRating"),
    Speech: () => import("@/components/GroupTeams/Common/Speech"),
    RaffleComponent: () =>
      import("@/modules/raffle/components/RaffleComponent.vue"),
    GameChatWrap: () => import("@/components/Chat/GameChatWrap"),
    AudioDrawer: () => import("@/components/drawers/audio/AudioDrawer.vue"),
    GameOverTeamImage: () =>
      import(
        "@/components/GroupTeams/Common/GameModes/Results/GameOverTeamImage.vue"
      ),
    WeveTooltip,
    PortalTarget,
    LinkManager,
    RtbPillButton,
    RtbButton,
    TeamView,
    ModeMapper,
    TeamTag,
    JeopardAiTeamTag: () => import("./JeopardAiTeamTag.vue"),
    FactMatchResult,
    OrderTheCardsGameResult,
    VideoTeamGameResult,
    ImageMatchResult,
    EventCountdown,
    CanvasBackground,
    IconBase,
    IconMic,
    IconExit,
    IconMicOff,
    RtbControlButton,
    Popup,
    SalesPopup,
    HostMiniTopBar,
    WeveTextarea,
    WeveButton,
    WeveAnnouncementWidget
  },
  data() {
    return {
      app: null,
      gameHints,
      activityState,
      quickState,
      zoomMissionAssetPhoto: {
        visible: false,
        title: null,
        photo: null
      },
      votes: [],
      isSummonHostDisabled: false,
      salesPopUp: false
    }
  },
  mounted() {
    const destroyAt = parseInt(this.client?.destroyAt)
    if (destroyAt) {
      this.$nextTick(() => {
        const date = new Date(destroyAt)
        const string = date.toTimeString()
        this.$snackbar({
          message: `This demo will expire at ${string}`,
          variant: "warning",
          timeout: 7000,
          offset: 40
        })
      })
    }
    setTimeout(() => {
      if (this.isDIYGame && !this.game.hasUsersOnline)
        this.setOpenWeveIsNotEmpty()
    }, _.random(0, 5000))
  },
  setup() {
    const { store } = useStore()
    const { enlargedUI, isViewerPresenter } = useEnlargedUI()
    const qrCodeActivity = computed(() => {
      if (!isViewerPresenter.value) return false
      return store.state.game?.qrCodeActivity != null
    })

    return {
      MissionType,
      Module,
      qrCodeActivity,
      enlargedUI,
      isViewerPresenter
    }
  },
  async created() {
    this.$bus.$on("zoomMissionAssetPhoto", this.onZoomMissionAssetPhoto)

    let cookieTutorialFlag = this.$cookies.get("ftuxStatus")

    if (cookieTutorialFlag === undefined || cookieTutorialFlag === null) {
      this.$cookies.set("ftuxStatus", "started")
      this.showFtux()
    } else if (cookieTutorialFlag === "started") {
      this.showFtux()
    }

    db.ref(`app`).on("value", this.onAppSnapshot)
  },
  beforeDestroy() {
    this.votingRef?.off("value", this.onSnapshot)
    db.ref(`app`).off("value", this.onAppSnapshot)
  },
  watch: {
    autopilotUpdatedBy: function (newValue, oldValue) {
      if (
        (this.isHostless || this.isDIYGame) &&
        !_.isEqual(newValue, oldValue) &&
        this.user.id !== newValue.id
      ) {
        const message: string = !this.isAutopilotOn
          ? `${newValue.name} turned off Auto Pilot`
          : `${newValue.name} turned on Auto Pilot`
        this.$toast(message, { autoHideDelay: 5000 })
      }
    },
    backgroundOpacity: {
      handler(value) {
        document.documentElement.style.setProperty(
          "--background-opacity",
          value
        )
      },
      immediate: true
    },
    isFinaleMode(value) {
      if (value) {
        HubSpot.push([
          "trackCustomBehavioralEvent",
          {
            name: "pe2803895_app_weve_com___game_over"
          }
        ])
      }
    },
    mutingAlert(value) {
      if (value) this.$toast(value)
    },
    canResetLocalMode(value) {
      if (this.socialOverride && value) this.updateSocialOverride(false)
    },
    mission() {
      if (this.socialOverride) this.updateSocialOverride(false)
    },
    isOpenWeveStart: {
      immediate: true,
      handler(nextValue, prevValue) {
        if (nextValue) {
          let OpenWeveStartVisited = this.$cookies.get("OpenWeveStartVisited")
          if (OpenWeveStartVisited !== "true") {
            this.gameHints.Activity = true

            this.modeHintTimeout = setTimeout(() => {
              this.gameHints.ModeToggle = true
            }, 10000)
          }
        } else {
          clearTimeout(this.modeHintTimeout)
          this.gameHints.Activity = false
          this.gameHints.ModeToggle = false
        }

        if (prevValue && !nextValue) {
          this.$cookies.set("OpenWeveStartVisited", "true")
        }
      }
    },
    "activityState.value"(value) {
      if (value && this.gameHints.Activity) {
        this.gameHints.Activity = !value
      }
    },
    "gameHints.Activity"(value) {
      if (!value && this.gameHints.ModeToggle === null) {
        this.gameHints.ModeToggle = true
      }
    },
    "gameHints.ModeToggle"() {
      clearTimeout(this.modeHintTimeout)
    },
    votingRef: {
      handler(newValue, oldValue) {
        oldValue?.off("value", this.onSnapshot)
        newValue?.on("value", this.onSnapshot)
      },
      immediate: true
    },
    summonHost: {
      immediate: true,
      handler(value) {
        if (value) {
          const { timestamp } = value
          const timeToUnlock = timestamp + FIVE_MINUTES - Date.now()
          if (timeToUnlock > 0) {
            this.isSummonHostDisabled = true
            setTimeout(() => {
              this.isSummonHostDisabled = false
            }, timeToUnlock)
          }
        } else {
          this.isSummonHostDisabled = false
        }
      }
    },
    zoomedAsset: {
      immediate: true,
      handler(value) {
        if (this.isViewerPresenter || this.isHost) {
          this.zoomMissionAssetPhoto = { ...value }
        }
      }
    }
  },
  computed: {
    ...mapState(["isNoteDialogVisible"]),
    ...mapGetters("Games", ["isMeetingMediaAvailable"]),
    ...mapState(["showProToolbarForModerators"]),
    ...mapGetters("group", ["votingOverride", "isMultiTeam", "socialOverride"]),
    ...mapGetters(["missionPlayType", "getCurrentMode"]),
    ...mapGetters("soundeffect", ["isAudioStop"]),
    ...mapGetters("auth", [
      "hasPreGame",
      "clientID",
      "client",
      "isHost",
      "isAudit",
      "isSuper",
      "isModerator",
      "isSpectator",
      "isPlayer"
    ]),
    ...mapGetters([
      "gameHost",
      "isHostToolsVisible",
      "game",
      "plays",
      "user",
      "isAutopilotOn",
      "missions",
      "autopilotUpdatedBy",
      "gameStatus",
      "orgID",
      "urlID",
      "moderatorID",
      "actualGameID",
      "actualGame",
      "everyoneCanHearEachOther",
      "showOnboardingPlayers",
      "isDIYGame",
      "getGameFtuxStep",
      "isHostless"
    ]),
    ...mapGetters({
      mission: "getCurrentMission",
      currentTeamID: "teamID",
      onlineUsers: "onlineUsersArray"
    }),
    ...mapGetters("ScreenCapture", [
      "streaming",
      "isSharingScreen",
      "isProcessingScreenSharing"
    ]),
    jeopardAiModule() {
      return this.$store.state.JeopardAi.module
    },
    showTeamResults() {
      return this.isFinaleMode || this.isFactMatchRelatedResult
    },
    isTopTeamScrollerVisible() {
      return this.isTopTeamVisible && this.isViewerPresenter
    },
    isTopTeamVisible() {
      return (
        !this.isFinaleMode && this.missionType !== MissionType.DiscussionGroup
      )
    },
    isTopTeamTagVisible() {
      return (
        this.isTopTeamVisible &&
        !this.showTeamResults &&
        !this.isWatchParty &&
        !this.isMatchGameResult &&
        !this.isOrderTheCardsGameResult &&
        !this.isViewerPresenter &&
        !this.isTagsHidden
      )
    },
    isBottomTeamScrollerOn() {
      return (
        this.bottomTeams.length > 5 ||
        this.isViewerPresenter ||
        this.hasHybridLocations
      )
    },
    teams() {
      return Team.normalize(this.$store.getters.chats)
    },
    isHearToggleVisible() {
      return !this.isHost && this.isDIYGame && !this.isMeetingMode
    },
    isSummonHostActive() {
      return this.app?.isSummonHostActive
    },
    note: {
      get() {
        return this.game?.note
      },
      set: debounce(function (val) {
        this.updateGameAny({
          theKey: this.actualGameID,
          note: val
        })
      }, 500)
    },
    isAudioDrawerAvailable() {
      return this.isHost || this.isModerator || this.isDIYGame
    },
    isActivityBtnVisible() {
      return (
        !this.isModerator &&
        this.user?.role === Role.PLAYER &&
        this.game?.moderated &&
        !activityState.value
      )
    },
    isQuickDrawerAvailable() {
      return (
        this.isHost || (this.isModerator && this.showProToolbarForModerators)
      )
    },
    isQuickDrawerBtnVisible() {
      return this.isQuickDrawerAvailable && !this.quickState.value
    },
    hasActivityPanel() {
      return !this.game.offActivitySets
    },
    isWhiteElephant() {
      return Mission.isWhiteElephant(
        this.$store.getters.gameStatus?.current_mission
      )
    },

    isGiphyCollapseCondition() {
      return (
        this.$window.height < MAX_WINDOW_HEIGHT &&
        !(this.isDIYGame || this.isHostless) &&
        this.missionType === MissionType.Giphy
      )
    },
    streamUrl() {
      return this.game?.streamUrl
    },
    showHostlessTimer() {
      return (
        (this.game?.hostless || this.game?.hostless2) && this.game?.duration
      )
    },
    bottomTeamWidthInPercent() {
      return 100 / (this.bottomTeams.length || 1)
    },
    showOnboardingPlayersComputed: {
      get() {
        return this.showOnboardingPlayers
      },
      set(value) {
        this.updateShowOnboardingPlayers(value)
      }
    },
    isSlides() {
      return (
        this.missionType === MissionType.Slides &&
        this.mode === Mode.Theater &&
        !Slide.hasPdf(this.mission)
      )
    },
    isVideo() {
      return this.missionType === MissionType.YouTube
    },
    isOpenWeveStart() {
      return this.missionType === MissionType.OpenWeveStart
    },
    rushThemUp() {
      return isUserSubmitMode(this.mode)
    },
    isDemo() {
      return Boolean(this.client?.demo)
    },
    assetPopupStyle() {
      return this.$store.getters["auth/isHybridRoom"] && this.isHost
        ? { opacity: 0.25 }
        : {}
    },
    isIRLSession() {
      return this.$store.getters["auth/isIRLSession"]
    },
    mutingAlert() {
      if (this.isDemo) return undefined
      if (this.isIRLSession) return undefined
      if (parseInt(this.mission?.pos) > 4) return undefined
      if (this.isHost) return undefined

      if (
        this.everyoneCanHearEachOther ||
        this.isWelcomeMode ||
        this.isMeetingMode
      )
        return "Everyone can be heard"

      if (this.isExplainMode) return "Everyone is muted except the host"

      if (this.isPlayMode)
        return "Players can only talk with their team. Only scribes can talk with the host"

      if (this.isHuddleMode && !this.isHost) {
        if (this.$store.getters["group/globalTeamID"] == this.user?.teamID) {
          return "Players can only talk with their team. Host is talking with YOUR team"
        }
        return "Players can only talk with their team. Host is talking with ANOTHER team"
      }

      if (this.isSocialMode)
        return "Players can talk with their team. Center players can be heard by all"

      if (this.isResultsMode) {
        if (!this.isFactMatchResult) {
          return "Everyone can be heard"
        }
        return "Flipped over participants can be heard"
      }

      return undefined
    },
    backgroundOpacity() {
      if (this.isPresentationLayout) return 1

      let int

      int = parseInt(this.mission?.backgroundOpacity)

      if (!isNaN(int)) return int / 100

      int = parseInt(this.game?.backgroundOpacity)

      if (!isNaN(int)) return int / 100

      return 0.3
    },
    backgroundImageUrl() {
      return this.backgroundImage
    },
    backgroundImage() {
      const slides = this.mission?.representImages || []

      if (this.isSlides && slides.length) {
        const index = this.gameStatus.slidesIndex ?? 0
        return decodeURI(slides[index]) ?? ""
      }

      if (this.game?.backgroundImageOver) return this.game.backgroundImageOver

      if (this.mission?.backgroundPhoto) return this.mission.backgroundPhoto

      if (this.game?.backgroundImage) return this.game.backgroundImage

      if (this.client?.gameBackground) return this.client.gameBackground

      return ""
    },
    isViewerPotato() {
      return Boolean(this.mode !== Mode.Over && this.user.potato)
    },
    eventEndTime() {
      return this.client?.endTimestamp || 0
    },
    canResetLocalMode() {
      return (
        LOCAL_MODE_RESET_MODES.includes(this.getCurrentMode) &&
        this.votingOverride
      )
    },
    viewerTeam() {
      return this.topTeams[0]
    },
    hasHybridLocations() {
      return this.$store.getters["group/hasHybridLocations"]
    },
    topTeamScrollerLabel() {
      if (this.hasHybridLocations && this.isViewerPresenter)
        return this.user?.identifier ?? DEFAULT_LOCATION

      return null
    },
    hasBreakouts() {
      return this.teams.some(({ show, breakout }) => breakout && show)
    },
    isTagsHidden() {
      return this.game?.hideTeamTags && !this.hasBreakouts
    },
    canRoundTopTeams() {
      return !this.hasBreakouts && !this.hasHybridLocations
    },
    teamRows() {
      const users = this.onlineUsers

      if (this.isViewerPresenter && (this.hasBreakouts || this.isIRLSession)) {
        return _.chain(this.teams)
          .filter(team => !Team.isSpecialPurpose(team))
          .sortBy("name")
          .reduce(
            (acc, team, index) => {
              if (index % 2) {
                acc[0].push(team)
              } else {
                acc[1].push(team)
              }

              return acc
            },
            [[], []]
          )
          .value()
      }

      if (this.isViewerPresenter && this.hasHybridLocations) {
        const DEFAULT_LOCATION = "default"
        const viewerLocation = this.user?.identifier ?? DEFAULT_LOCATION
        const value = _.chain(this.teams)
          .filter(team => !Team.isSpecialPurpose(team))
          .map(team => {
            const user = users.find(
              user => user.teamID === team.id && user.identifier
            )
            const location = user?.identifier ?? DEFAULT_LOCATION
            return { ...team, location }
          })
          .sortBy(team => team.id)
          .sortBy(team => team.location !== viewerLocation)
          .groupBy("location")
          .flatMap(items => [items])
          .value()
        return [
          value[0],
          value.slice(1).reduce((acc, val) => [...acc, ...val], [])
        ]
      }

      // if normal presenter
      if (this.isViewerPresenter) {
        const viewerTeamID =
          this.$store.getters["group/globalTeamID"] || this.currentTeamID
        return _.chain(this.teams)
          .filter(team => !Team.isSpecialPurpose(team))
          .sortBy(team => team.id)
          .sortBy(team => team.id !== viewerTeamID)
          .value()
          .reduce(
            (acc, val) => {
              if (
                users.some(
                  user => user.teamID === val.id && User.isMobile(user)
                )
              )
                acc[1].push(val)
              else acc[0].push(val)
              return acc
            },
            [[], []]
          )
      }

      // if host or audit
      if (
        this.$store.getters["group/canViewerSelectGlobalTeam"] &&
        this.hasHybridLocations
      ) {
        const viewerTeamID =
          this.$store.getters["group/globalTeamID"] ?? this.currentTeamID
        const DEFAULT_LOCATION = "default"
        const teams = _.chain(this.teams)
          .map(team => {
            const user = users.find(
              user => user.teamID === team.id && user.identifier
            )
            const location = user?.identifier ?? DEFAULT_LOCATION
            return { ...team, location }
          })
          .sortBy(team => team.id)
          .sortBy(team => Team.isSpecialPurpose(team))
          .sortBy(team => team.location)
          .sortBy(team => team.id !== viewerTeamID)
          .value()

        return [[teams[0]], teams.slice(1)]
      }

      // if host or audit
      if (this.$store.getters["group/canViewerSelectGlobalTeam"]) {
        const viewerTeamID =
          this.$store.getters["group/globalTeamID"] ?? this.currentTeamID
        const teams = _.chain(this.teams)
          .sortBy(team => team.id)
          .sortBy(team => Team.isSpecialPurpose(team))
          .sortBy(team => team.id !== viewerTeamID)
          .value()
        return [[teams[0]], teams.slice(1)]
      }

      // if everybody else
      const viewerTeamID = this.currentTeamID
      const teams = _.chain(this.teams)
        .filter(team => !Team.isSpecialPurpose(team))
        .sortBy(team => team.id)
        .sortBy(team => team.id !== viewerTeamID)
        .value()

      return [[teams[0]], teams.slice(1)]
    },
    topTeams() {
      return this.teamRows[0]
    },
    bottomTeams() {
      return this.teamRows[1]
    },
    isFactMatchRelatedResult() {
      return (
        [
          MissionType.OrderTheCards,
          MissionType.MatchGame,
          MissionType.FactMatch,
          MissionType.VideoTeam
        ].includes(this.missionType) && this.isResultsMode
      )
    },
    isFactMatch() {
      return this.missionType === MissionType.FactMatch
    },
    isMatchGame() {
      return this.missionType === MissionType.MatchGame
    },
    isLipdub() {
      return this.missionType === MissionType.Lipdub
    },
    countdown() {
      return (
        ((this.isFactMatch || this.isMatchGame) &&
          (this.isResultsMode || this.isHuddleMode)) ||
        (this.isSocialMode && !(this.isLipdub && this.isAutopilotOn))
      )
    },
    isFactMatchResult() {
      return this.isFactMatch && this.isResultsMode
    },
    isMatchGameResult() {
      return this.isMatchGame && this.isResultsMode
    },
    isOrderTheCardsGameResult() {
      return (
        this.missionType === MissionType.OrderTheCards && this.isResultsMode
      )
    },
    isVideoTeamResults() {
      return this.missionType === MissionType.VideoTeam && this.isResultsMode
    },
    isFinaleMode() {
      return this.mode === Mode.Over
    },
    isWatchParty() {
      return !!this.mission?.watchParty
    },
    isFinaleMode() {
      return this.mode === Mode.Over
    },
    isFinaleMission() {
      return isFinaleMission(this.mission)
    },
    isResultsMode() {
      return this.mode === Mode.Results
    },
    isSocialMode() {
      return this.mode === Mode.Social
    },
    isHuddleMode() {
      return this.mode === Mode.Huddle
    },
    isMeetingMode() {
      return this.mode === Mode.Meeting
    },
    isWelcomeMode() {
      return this.mode === Mode.Welcome
    },
    isPlayMode() {
      return this.mode === Mode.Play
    },
    isExplainMode() {
      return this.mode === Mode.Explain
    },
    isTheaterMode() {
      return this.mode === Mode.Theater
    },
    isVoting() {
      return this.mode === Mode.Voting
    },
    isTheaterVoting() {
      return this.isVoting && this.mission?.theaterVoting
    },
    isPresentationLayout() {
      return this.isTheaterMode || this.isSlides
    },
    backgroundClass() {
      return this.isPresentationLayout ? "--presentation" : null
    },
    collapseTopRow() {
      return (
        !this.isFactMatchResult &&
        !this.isMatchGameResult &&
        !this.isOrderTheCardsGameResult &&
        !this.isFinaleMode &&
        (this.isMeetingMode ||
          this.isTheaterMode ||
          this.isSocialVideoMission ||
          this.isTheaterVoting ||
          this.enlargedUI)
      )
    },
    isBottomTeamPanelHidden() {
      return (
        this.isFinaleMode ||
        this.isFactMatchRelatedResult ||
        this.isMeetingMode ||
        this.isTheaterMode ||
        this.isSocialVideoMission ||
        this.isTheaterVoting ||
        (this.game?.importedFromBreadcrumb && this.teams.length > 9) ||
        this.enlargedUI
      )
    },
    isBottomTeamVideoVisible() {
      if (this.isGiphyCollapseCondition) return false

      if (this.missionType === MissionType.TwoTruthsReveal) return true

      if (
        this.modeWithOverrides === Mode.Social &&
        this.isMultiTeam &&
        ![MissionType.OrderTheCards, MissionType.MatchGame].includes(
          this.missionType
        )
      ) {
        return false
      }

      return this.modeWithOverrides !== Mode.Huddle
    },
    modeWithOverrides() {
      if (this.isResultsMode && this.isFactMatch) return Mode.Huddle

      return this.mode
    },
    teamsSortedByTotalScore() {
      return this.teams
        .filter(team => !Team.isSpecialPurpose(team))
        .map(team => ({
          ...team,
          totalScore: parseInt(team?.totalScore) || 0
        }))
        .sort(Team.sortByScore)
        .reverse()
    },
    highlightedTeamID() {
      return this.gameStatus?.flippedTeamID
    },
    missionType() {
      return this.mission?.behavior
    },
    endTime() {
      const int = parseInt(this.gameStatus?.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    },
    mode() {
      if (this.votingOverride) return Mode.Voting

      if (this.socialOverride) return Mode.Social

      return this.getCurrentMode
    },

    shouldShowAnnouncement() {
      return this.isMeetingMode && this.game?.meetingAnnouncement
    },
    shouldShowMeetingRating() {
      return this.isMeetingMode && this.game?.meetingRating
    },
    isSpeechToTextEnabled() {
      return !!this.game?.speechToText
    },
    toggleAuditorMuteAllPlayers: {
      get() {
        return this.user?.muteAllPlayers ?? false
      },
      set(value) {
        this.updateUser({
          userID: this.user?.id,
          obj: { muteAllPlayers: !!value }
        })
      }
    },
    textChat() {
      return (
        this.$store.getters.isTextChatOn &&
        this.user.role !== Role.AUDITOR &&
        !this.isViewerPresenter
      )
    },
    raffleAvailable(): boolean {
      return this.getRaffleAvailable(this.game.gameType, this.mode)
    },
    raffleVisible(): boolean {
      return this.getRaffleVisible(this.game.gameType, this.mode)
    },
    isSocialVideoMission() {
      return this.missionType === MissionType.Video && this.isSocialMode
    },
    canShowShareScreen() {
      return [Mode.Theater, Mode.Meeting].includes(this.mode)
    },
    screenShareTooltip() {
      return this.isProcessingScreenSharing
        ? "Please wait..."
        : this.isSharingScreen
        ? "Stop Screen Share"
        : "Share Screen"
    },
    isProccessingStream() {
      return this.game?.processingStreaming ?? false
    },
    popupTitle() {
      return (
        this.zoomMissionAssetPhoto?.title ||
        this.mission?.instructions?.replace(/<\/br>/g, " ")
      )
    },
    votingRef() {
      return this.orgID &&
        this.actualGameID &&
        this.mission?.id &&
        this.isVideoTeamResults
        ? db
            .auxiliary()
            .ref(`org/${this.orgID}/game/${this.actualGameID}/votes`)
            .orderByChild("missionID")
            .equalTo(this.mission?.id)
        : null
    },
    summonHost() {
      return this.game?.summonHost
    },
    showShowVirtualAwardDrawer() {
      return Boolean(this.mission?.theaterVoting && this.isHost)
    },
    zoomedAsset() {
      return this.gameStatus?.zoomMissionAssetPhoto
    },
    toolbarVisibility() {
      if (this.isViewerPresenter) {
        return !this.game?.sidebarHidden
      }

      return true
    },
    isSvgActive() {
      return this.isHost && this.game?.qrCodeActivity
    }
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    ...mapActions("group", ["updateSocialOverride"]),
    ...mapActions([
      "updateGameStatusAny",
      "updateTeam",
      "updateUser",
      "updateShowOnboardingPlayers",
      "updateGameFtuxStep"
    ]),
    openQuickAddPanel,
    openVirtualGameShowImageDrawer,
    openActivtyPanel,
    talkToSales() {
      this.salesPopUp = true
    },
    onAppSnapshot(snapshot) {
      this.app = snapshot.val()
    },
    setOpenWeveIsNotEmpty() {
      db.auxiliary()
        .ref(`org/${this.orgID}/games/${this.actualGameID}/hasUsersOnline`)
        .set(true)
    },
    hideNoteDialog() {
      this.$store.commit("SET_IS_NOTE_DIALOG_VISIBLE", false)
    },
    pushToLobby() {
      this.$navigator.navigateTo(`/lobby/${this.clientID}`)
    },
    getTeamIndexByID(teamID) {
      return this.teamsSortedByTotalScore.findIndex(team => team.id === teamID)
    },
    onZoomMissionAssetPhoto({ visible = null, photo = null, title = null }) {
      if (this.isHost) {
        this.updateGameStatusAny({
          zoomMissionAssetPhoto: {
            visible,
            photo,
            title
          }
        })
      } else {
        this.zoomMissionAssetPhoto = { visible, photo, title }
      }
    },
    closePopup() {
      if (this.isHost) {
        this.updateGameStatusAny({
          zoomMissionAssetPhoto: { visible: false, title: false }
        })
      } else {
        this.zoomMissionAssetPhoto = { visible: false, title: false }
      }

      this.salesPopUp = false
    },
    hideFtux() {
      this.updateGameFtuxStep(false)
      this.$cookies.set("ftuxStatus", "ended")
    },
    nexFtuxStep() {
      const nextStep = this.getGameFtuxStep + 1
      this.updateGameFtuxStep(nextStep)
    },
    showFtux() {
      setTimeout(() => {
        this.updateGameFtuxStep(1)
      }, 3000)
    },
    onSnapshot(snapshot) {
      this.votes = Object.entries(snapshot.val() || {}).map(([id, vote]) => ({
        ...vote,
        id
      }))
    },
    async onSummonHost() {
      if (this.isHost)
        return this.updateGameAny({
          theKey: this.actualGameID,
          summonHost: null
        })

      await this.$confirm({ message: DictionaryMisc.SUMMON_HOST_CONFIRM })

      const summonHost = {
        userName: User.getShortenedName(this.user),
        timestamp: Date.now()
      }

      this.updateGameAny({
        theKey: this.actualGameID,
        summonHost
      })
    }
  }
})
