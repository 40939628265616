import { db } from "@/firebase"
import { sortBy } from "lodash"

import { AudioCollection } from "@/modules/audio/types"

import GetterTypes from "./modules/SoundEffectModule/getter-types"

export const MODULE_NAME = "soundeffect"

const SoundEffectModule = {
  namespaced: true,
  state: {
    gameAudios: [],
    userAudios: []
  },
  mutations: {
    SET_GAME_AUDIOS(state, payload) {
      state.gameAudios = payload
    },
    SET_USER_AUDIOS(state, payload) {
      state.userAudios = payload
    }
  },
  actions: {
    updateTeamSoundEffect({ rootGetters }, payload) {
      return db
        .auxiliary()
        .ref(
          `org/${rootGetters.orgID}/game/${rootGetters.gameID}/teams/${rootGetters.teamID}/sfx`
        )
        .set({
          ...payload,
          timestamp: Date.now()
        })
    },
    updateGameSoundEffect({ rootGetters }, payload) {
      return db
        .auxiliary()
        .ref(`org/${rootGetters.orgID}/games/${rootGetters.gameID}/sfx`)
        .set({
          ...payload,
          timestamp: Date.now()
        })
    },
    async fetchGameAudioTracks({ rootGetters, commit }) {
      const snapshot = await db
        .auxiliary()
        .ref(`org/${rootGetters.orgID}/game/${rootGetters.gameID}/audio`)
        .once("value")

      const array = Object.entries(snapshot.val() || {}).reduce(
        (acc, [key, value]) => {
          if (value && value.source && value.name) {
            acc.push({ ...value, key })
          }
          return acc
        },
        []
      )

      commit("SET_GAME_AUDIOS", array)
    },
    async fetchHostAudioTracks({ rootGetters, commit }) {
      const path = `org/${rootGetters.orgID}/`
      const game = rootGetters["game"]
      const actualHost = rootGetters["gameHost"]

      if (!game) return console.error("Game error")

      const actualHostID = actualHost ? actualHost.id : null
      const assignedHostID =
        typeof game.hostUserID === "string" ? game.hostUserID : undefined
      const hostID = actualHostID || assignedHostID

      if (!hostID) return console.error("Undefined user ID")

      const snapshot = await db
        .ref(`${path}/audio`)
        .orderByChild("userID")
        .equalTo(hostID)
        .once("value")

      const value = snapshot.val()

      const array = Object.entries(value || {}).reduce((acc, [key, value]) => {
        if (value && value.source && value.name) {
          acc.push({ ...value, key })
        }
        return acc
      }, [])

      commit("SET_USER_AUDIOS", array)
    },
    updateUserVolume(_, { userID, volume }) {
      return db.ref(`org/1/users/${userID}/volume`).set(volume)
    },
    toggleAudioLoop({ rootState }, audioLoop) {
      return db
        .auxiliary()
        .ref(`org/${rootState.orgID}/games/${rootState.gameID}/audioLoop`)
        .set(audioLoop)
    },
    updateGameAudioTrack({ rootGetters }, payload) {
      const trackType = payload?.overlay ? "overlay" : "audio"
      const updatedBy = {
        id: rootGetters.user.id,
        firstname: rootGetters.user.firstname
      }
      return db
        .auxiliary()
        .ref("org/" + rootGetters.orgID + "/games/" + rootGetters.gameID)
        .update({
          [`${trackType}Track`]: {
            name: payload.name || "",
            status: payload.status || "stop",
            key: payload.key || null,
            source: payload.source || null,
            playerSource: payload.playerSource || null,
            overlay: payload.overlay || null,
            volume: payload.volume || null,
            missionID: payload.missionID || null,
            updatedBy,
            timestamp: Date.now()
          }
        })
    }
  },
  getters: {
    [GetterTypes.VOLUME](state, getters, rootState, rootGetters) {
      return rootGetters["auth/volume"]
    },
    [GetterTypes.AUDIOS_GROUPED_BY_COLLECTION](_, getters) {
      return {
        [AudioCollection.Game]: getters[GetterTypes.GAME_AUDIOS],
        [AudioCollection.User]: getters[GetterTypes.USER_AUDIOS]
      }
    },
    currentAudioTrack(_, __, rootState) {
      const isTrackAvailable = track =>
        ["play", "pause"].includes(track?.status)

      if (isTrackAvailable(rootState.game?.audioTrack))
        return rootState.game.audioTrack

      if (isTrackAvailable(rootState.game?.overlayTrack))
        return rootState.game.overlayTrack

      return null
    },
    isAudioStop(_, getters) {
      return !getters.currentAudioTrack
    },
    getAllAudios(_, getters) {
      const overlaySounds = [
        ...getters[GetterTypes.USER_AUDIOS].filter(sound => sound.overlay),
        ...getters[GetterTypes.GAME_AUDIOS].filter(sound => sound.overlay)
      ]

      const userSounds = getters[GetterTypes.USER_AUDIOS].filter(
        sound => !sound.overlay
      )
      const gameSounds = getters[GetterTypes.GAME_AUDIOS].filter(
        sound => !sound.overlay
      )

      return overlaySounds.concat(userSounds).concat(gameSounds)
    },
    [GetterTypes.GAME_AUDIOS](state) {
      return sortBy(state.gameAudios, "name")
    },
    [GetterTypes.USER_AUDIOS](state) {
      return sortBy(state.userAudios, "name")
    },
    getAudioLoopStatus(_, __, ___, rootGetters) {
      const game = rootGetters["game"]
      return !!game && !!game.audioLoop
    }
  }
}

export default SoundEffectModule
