































import Vue from "vue"
import Pill from "../PillButton/PillButton.vue"

export default Vue.extend({
  name: "RtbButtonToggle",
  components: {
    Pill
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: undefined
    },
    size: {
      type: String,
      default: undefined
    },
    showName: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("change", value)
      }
    },
    itemWidth() {
      return 100 / this.items.length
    },
    buttonStyle() {
      return {
        minWidth: this.itemWidth + "%"
      }
    },
    sliderStyle() {
      return {
        minWidth: `calc(${this.itemWidth}% - ${this.sliderMargin * 2}px`,
        left: `${this.itemWidth * this.numberOfSelectedItem}%`
      }
    },
    sliderMargin() {
      switch (this.size) {
        case "sm":
          return 4
        case "xs":
          return 1
        default:
          return 2
      }
    },
    numberOfSelectedItem() {
      return this.items.reduce((acc, item, index) => {
        if (
          item.value === this.value ||
          (!item.value && item.name === this.value)
        ) {
          acc = index
        }
        return acc
      }, false)
    }
  }
})
