var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-team-result" },
    [
      _c(
        "div",
        { staticClass: "video-team-result__results" },
        _vm._l(_vm.results, function (result) {
          return _c("VideoComponent", {
            key: result.id,
            staticClass: "video-team-result__video",
            attrs: { src: result.src, autoplay: "", muted: "", loop: "" },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "video-team-result__controls mt-2" },
        [
          _vm.hasVotingStars
            ? _c("StarRating", {
                staticClass: "mr-2",
                attrs: {
                  increment: 0.5,
                  padding: 3,
                  rating: _vm.rating,
                  "star-size": 15,
                  "show-rating": false,
                  "border-width": 2,
                  "inactive-color": "",
                  "active-color": "#fff",
                  "border-color": "#fff",
                },
                on: {
                  "rating-selected": function ($event) {
                    return _vm.onStarred($event)
                  },
                },
              })
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "video-team-result__dowmload-btn",
              on: { click: _vm.download },
            },
            [
              _c("svg-icon", {
                staticClass: "mr-1",
                attrs: { name: "cloud-download-regular", size: "small" },
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.downloading ? "Processing..." : "Download") +
                  " "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.missionAudioSrc
        ? _c("SyncedMedia", {
            key: _vm.teamID,
            attrs: { src: _vm.missionAudioSrc, autoplay: false, loop: true },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }