
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

const SIZES = ["sm"]
const COLORS = ["success"]

export default Vue.extend({
  name: "ButtonNext",
  functional: true,
  props: {
    size: {
      type: String,
      default: undefined,
      validator: val => SIZES.includes(val)
    },
    color: {
      type: String,
      default: undefined,
      validator: val => COLORS.includes(val)
    }
  },
  render(h, { props, data, children }) {
    return h(
      "button",
      mergeData(data, {
        staticClass: "rtb-button-next-next",
        class: {
          [`rtb-button-next-next--size--${props.size}`]: props.size,
          [`rtb-button-next-next--color--${props.color}`]: props.color
        }
      }),
      [h("span", { staticClass: "contrast-text-color" }, children)]
    )
  }
})
