var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "lobby-snackbar",
      class:
        ((_obj = {}),
        (_obj["lobby-snackbar--" + _vm.variant] = _vm.variant),
        _obj),
      style: { top: _vm.top },
      attrs: {
        top: "",
        right: _vm.right,
        "multi-line": "",
        timeout: _vm.timeout,
        dark: "",
      },
      model: {
        value: _vm.visibility,
        callback: function ($$v) {
          _vm.visibility = $$v
        },
        expression: "visibility",
      },
    },
    [
      _vm.icon
        ? _c("SvgIcon", {
            staticClass: "lobby-snackbar__icon",
            staticStyle: { "margin-right": "20px" },
            attrs: { name: _vm.icon, width: "25", height: "25" },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "lobby-snackbar__text",
          style: !_vm.icon ? "margin-left: 25px" : null,
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "v-btn",
        {
          attrs: { color: "black", flat: "" },
          on: {
            click: function ($event) {
              _vm.visibility = false
            },
          },
        },
        [
          _c("SvgIcon", {
            attrs: { name: "times-regular", width: "18", height: "18" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }