
// TODO(Andrew|someone): help with a11y
import Vue, { VNode } from "vue"

export default Vue.extend({
  name: "Tabs",
  props: {
    value: {
      type: Number,
      required: true
    },
    display: {
      type: String,
      default: "block"
    },
    tabsClass: {
      required: false
    }
  },
  methods: {
    isActive(index: number) {
      return this.value === index
    },
    renderTabs() {
      return this.$createElement(
        "ul",
        { class: this.tabsClass, staticClass: "rtb-tabs__tabs" },
        this.$slots.default
          .filter(node => node.tag)
          .map((node, index) =>
            this.$createElement("li", { staticClass: "rtb-tabs__tabs-item" }, [
              this.$createElement(
                "a",
                {
                  staticClass: "rtb-tabs__tab",
                  class: { "rtb-tabs__tab--active": this.isActive(index) },
                  on: {
                    click: () => {
                      this.$emit("input", index)
                      this.$emit("tabKey", node?.key)
                    }
                  }
                },
                node.componentOptions.propsData.title
              )
            ])
          )
      )
    },
    renderItems() {
      return this.$slots.default
        .filter(node => node.tag !== undefined)
        .map((node, index) => {
          return this.$createElement(
            "div",
            {
              style: { display: this.isActive(index) ? this.display : "none" }
            },
            [node]
          )
        })
    }
  },
  render(h): VNode {
    return h("div", { staticClass: "rtb-tabs" }, [
      this.renderTabs(),
      this.renderItems()
    ])
  }
})
