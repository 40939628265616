<template>
  <v-flex
    :style="{ visibility: isOpen ? 'visible' : 'hidden' }"
    d-flex
    justify-center
    class="popup-overlay"
    align-center
    @click.self="$emit('onClickOutside')"
  >
    <transition name="popup">
      <div
        v-if="isOpen"
        class="popup-content"
        :class="{ [`popup-content--${size}`]: size }"
      >
        <div
          v-if="closable"
          class="close-symbol"
          @click.stop="$emit('onClose')"
        >
          <SvgIcon name="times-regular" width="18" height="18" />
        </div>

        <h3 v-if="!!header" class="popup-header">
          {{ header }}
        </h3>

        <div class="popup-text">
          <slot />
        </div>

        <div class="popup__controls" v-if="mode">
          <template v-if="mode === 'yes-no'">
            <rtb-button-next
              @click.stop="onResponse({ status: 'yes' })"
              class="mx-4"
            >
              YES
            </rtb-button-next>

            <rtb-button-next
              @click.stop="onResponse({ status: 'no' })"
              class="mx-4"
            >
              NO
            </rtb-button-next>
          </template>

          <rtb-button-next
            v-else-if="mode === 'okay'"
            @click.stop="onResponse({ status: 'okay' })"
          >
            OK
          </rtb-button-next>
        </div>
      </div>
    </transition>
  </v-flex>
</template>

<script>
import { RtbButtonNext } from "@/components/ui"
export default {
  name: "Popup",
  components: {
    RtbButtonNext
  },
  props: {
    size: String,
    isOpen: Boolean,
    header: String,
    mode: String,
    closable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onResponse({ status }) {
      this.$emit("onResponse", { status })
    }
  }
}
</script>
