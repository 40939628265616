










import { computed, defineComponent, ref, watch } from "@vue/composition-api"
import GameCountdown from "@/components/GroupTeams/Common/GameCountdown.vue"
import useStore from "@/use/useStore"
import useToast from "@/use/useToast"

export default defineComponent({
  name: "PillCountdown",
  components: { GameCountdown },
  props: {
    endTime: {
      type: Number,
      required: true
    }
  },
  setup() {
    const endClose = ref(false)
    const { store } = useStore()
    const { toast } = useToast()
    const isDIYGame = computed(() => store.getters.isDIYGame)
    const isAutopilotOn = computed(() => store.getters.isAutopilotOn)
    const isVisible = computed(() => !(isDIYGame && !isAutopilotOn))
    const isScribe = computed(() => store.getters.isScribe)

    function progress({ totalSeconds }) {
      if (totalSeconds <= 10) {
        endClose.value = totalSeconds > 0
      }
    }

    watch(endClose, value => {
      if (value && isScribe.value) {
        toast("10 Seconds left")
      }
    })

    return { isVisible, progress, endClose }
  }
})
