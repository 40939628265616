var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "game-countdown",
      class: { "game-countdown--host": _vm.isHost },
    },
    [
      _c(
        "div",
        { attrs: { "d-flex": "" }, on: { click: _vm.toggleInput } },
        [
          _c(
            "countdown",
            _vm._g(
              {
                ref: "countdown",
                staticClass: "game-countdown__time",
                class: {
                  medium: _vm.endGameSeconds >= 60 && _vm.endGameSeconds < 599,
                  small: _vm.endGameSeconds >= 3600 || _vm.endGameSeconds > 599,
                },
                attrs: { interval: 1000, time: _vm.endTime },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var hours = ref.hours
                      var minutes = ref.minutes
                      var seconds = ref.seconds
                      var totalSeconds = ref.totalSeconds
                      return [
                        _c(
                          "span",
                          {
                            class: {
                              late: totalSeconds === 0,
                              alert: totalSeconds <= 10 && totalSeconds > 0,
                            },
                          },
                          [
                            hours
                              ? [_vm._v(" " + _vm._s(hours) + ":")]
                              : _vm._e(),
                            minutes
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        10 > minutes ? "" + minutes : minutes
                                      ) +
                                      ":"
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(
                              _vm._s(10 > seconds ? "0" + seconds : seconds) +
                                " "
                            ),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              },
              _vm.$listeners
            )
          ),
        ],
        1
      ),
      _vm.showInput
        ? _c(
            "div",
            { staticClass: "game-countdown__input" },
            [
              _c("v-text-field", {
                ref: "additionalSeconds",
                attrs: { solo: "", placeholder: "+ secs", "hide-details": "" },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.updateTimerSeconds.apply(null, arguments)
                  },
                  blur: _vm.handleInputBlur,
                },
                model: {
                  value: _vm.additionalSeconds,
                  callback: function ($$v) {
                    _vm.additionalSeconds = $$v
                  },
                  expression: "additionalSeconds",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }