
import Vue from "vue"

export default Vue.extend({
  name: "RTBTab",
  props: {
    title: {
      type: String,
      required: true
    }
  },
  render(h) {
    return h("div", { staticClass: "rtb-tabs__pane" }, this.$slots.default)
  }
})
