<template>
  <v-hover
    :disabled="settingsBar || audioDrawer"
    :open-delay="OPEN_DELAY"
    v-model="visibility"
  >
    <v-navigation-drawer
      slot-scope="{ hover }"
      :fixed="fixed"
      :absolute="absolute"
      floating
      :right="!left"
      :left="left"
      :height="height"
      dark
      :mini-variant="mobile ? false : !hover"
      :mini-variant-width="miniVariantWidth"
      hide-overlay
      :width="width"
      :permanent="!mobile"
      :temporary="mobile"
      v-model="isOpen"
      class="rtb-right-toolbar"
      :class="{
        'rtb-right-toolbar--is-open': mobile || hover,
        'rtb-right-toolbar--with-mini-topbar':
          isMeeting && (isHost || isDIYGame),
        'rtb-right-toolbar--with-mini-topbar-auditor': isMeeting && isModerator
      }"
    >
      <div class="rtb-right-toolbar__cnt">
        <div
          class="rtb-right-toolbar__top"
          :style="isMeeting && { 'margin-top': '20px' }"
        >
          <div
            class="rtb-right-toolbar__logo"
            :class="{ 'is-open': mobile || hover }"
          >
            <div
              v-if="mobile || !hover"
              class="rtb-right-toolbar__logo-small"
              :class="{ 'rtb-right-toolbar__logo-small--custom': customLogo }"
            >
              <img :src="customLogo || weveLogo" alt="Logo" key="sm" />
            </div>
            <div
              v-if="hover && !mobile"
              class="rtb-right-toolbar__logo-large"
              :class="{
                'rtb-right-toolbar__logo-large--custom': customLogoLarge
              }"
            >
              <img :src="customLogoLarge || weveFullLogo" alt="Logo" key="lg" />
            </div>
          </div>

          <h1 class="rtb-right-toolbar__title" v-if="!isHost">
            <slot name="title" />
          </h1>

          <div class="rtb-right-toolbar__countdown" v-if="!isModerated">
            <slot name="countdown" />
          </div>

          <WeveTooltip
            v-if="!!isModerated && isGamePage"
            variant="light"
            placement="left"
            triggers="manual"
            closeable
            v-model="gameHints.ModeToggle"
          >
            <div class="text-sm-left">
              <b class="text-base">Meeting Mode</b>
              <p class="mb-0 mt-1 text-sm">
                Switch between Meeting and play mode from this toolbar toggle
              </p>
            </div>
            <template #activator="{ attrs }">
              <MeetToggle
                v-if="!mobile"
                v-bind="attrs"
                :size="hover ? 'sm' : 'xs'"
              />
            </template>
          </WeveTooltip>

          <div class="rtb-right-toolbar__autopilot" v-if="isAutopilotOn">
            <SvgIcon
              v-if="hover"
              name="autopilot-on-full"
              width="200"
              height="30"
              style="margin-left: 18px"
            />
            <SvgIcon v-else name="autopilot-on" width="30" height="30" />
          </div>
        </div>

        <div class="rtb-right-toolbar__mid">
          <div class="rtb-right-toolbar__list">
            <template v-if="!mobile">
              <div
                class="rtb-right-toolbar__list-item"
                v-if="!isLobby && (client.escapeRoom || game.helpBell)"
              >
                <div
                  class="rtb-right-toolbar__list-item-cnt"
                  :class="{
                    'rtb-right-toolbar__list-item-cnt--green': isHelpRequested
                  }"
                  @click="toggleHelpButton"
                >
                  <div class="rtb-right-toolbar__list-item-icon">
                    <SvgIcon name="bell" width="20" height="20" />
                  </div>
                  <div class="rtb-right-toolbar__list-item-title">
                    {{
                      isHelpRequested ? game.helpRequestedBy : "Request Help"
                    }}
                  </div>
                </div>
              </div>

              <div
                v-if="!isLobby && !isMeeting"
                class="rtb-right-toolbar__list-item"
              >
                <div
                  v-if="isHost || isDIYGame"
                  class="rtb-right-toolbar__list-item-cnt"
                  @click="toggleAutopilot()"
                >
                  <div class="rtb-right-toolbar__list-item-icon">
                    <SvgIcon
                      :name="isAutopilotOn ? 'pause-circle' : 'play-circle'"
                      width="20"
                      height="20"
                      class="rtb-right-toolbar__list-item-icon"
                    />
                  </div>
                  <div class="rtb-right-toolbar__list-item-title">
                    {{
                      isAutopilotOn ? `Pause Auto-Pilot` : `Start Auto-Pilot`
                    }}
                  </div>
                </div>
              </div>

              <div
                class="rtb-right-toolbar__list-item"
                v-if="isCopyLinkVisible"
              >
                <v-tooltip
                  :left="!left"
                  :right="left"
                  v-model="isCopyLinkTTVisible"
                  :open-delay="450"
                >
                  <template v-slot:activator="{ on }">
                    <div
                      class="rtb-right-toolbar__list-item-cnt"
                      v-on="on"
                      @click="copyLinkToGame"
                      @mouseover="isReadyToCopy = true"
                      @mouseleave="copyLinkLeave"
                    >
                      <div class="rtb-right-toolbar__list-item-icon">
                        <SvgIcon name="copy-link" width="24" height="24" />
                      </div>
                      <div class="rtb-right-toolbar__list-item-title">
                        Copy Room Link
                      </div>
                    </div>
                  </template>
                  <span :class="left ? 'right' : 'left'">
                    <template v-if="isReadyToCopy && isLinkCopied">
                      Link copied
                    </template>
                    <template v-else>
                      Copy link to invite other players
                    </template>
                  </span>
                </v-tooltip>
              </div>

              <div class="rtb-right-toolbar__list-item">
                <div class="rtb-right-toolbar__list-item-cnt">
                  <UserVolumeSlider class="rtb-right-toolbar__volum_sfx">
                    <div
                      class="rtb-right-toolbar__list-item-title"
                      style="margin-left: 0; margin-right: 3px"
                    >
                      SFX
                    </div>
                  </UserVolumeSlider>
                </div>
              </div>

              <ReactionsMenu
                v-if="isReactionsMenuVisible"
                v-model="currentMenu"
              />

              <div
                v-if="!!isModerated && isPlayer"
                class="rtb-right-toolbar__list-item-cnt"
                @click="openEditTeamsDialog"
              >
                <div class="rtb-right-toolbar__list-item-icon">
                  <SvgIcon
                    name="recent-actors-outlined"
                    width="20"
                    height="20"
                  />
                </div>
                <div class="rtb-right-toolbar__list-item-title">Edit teams</div>
              </div>

              <div class="rtb-right-toolbar__list-item">
                <div
                  class="rtb-right-toolbar__list-item-cnt"
                  :class="{
                    'rtb-right-toolbar__list-item-cnt--danger': isUserMuted
                  }"
                  @click="toggleUserMute"
                >
                  <div class="rtb-right-toolbar__list-item-icon">
                    <SvgIcon
                      :name="isUserMuted ? 'muted' : 'microphone'"
                      width="20"
                      height="20"
                    />
                  </div>
                  <div class="rtb-right-toolbar__list-item-title">
                    {{ isUserMuted ? "Unmute" : "Mute" }}
                  </div>
                </div>
              </div>

              <div
                class="rtb-right-toolbar__list-item"
                v-if="canShowShareScreen"
              >
                <div
                  class="rtb-right-toolbar__list-item-cnt"
                  :class="{
                    'rtb-right-toolbar__list-item-cnt--danger': isSharingScreen
                  }"
                  @click="onSetIsSharingScreen"
                >
                  <div class="rtb-right-toolbar__list-item-icon">
                    <SvgIcon
                      :name="
                        isSharingScreen ? 'sharescreenstop' : 'sharescreen'
                      "
                      width="20"
                      height="20"
                    />
                  </div>
                  <div class="rtb-right-toolbar__list-item-title">
                    {{ isSharingScreen ? "Stop Share Screen" : "Share Screen" }}
                  </div>
                </div>
              </div>

              <div
                v-if="isRemoveHostAvailable"
                class="rtb-right-toolbar__list-item-cnt rtb-right-toolbar__list-item-cnt--danger"
                @click="setGameModeratorID(null)"
              >
                <div class="rtb-right-toolbar__list-item-icon">
                  <SvgIcon
                    name="become-host-off"
                    color="danger"
                    width="20"
                    height="20"
                  />
                </div>
                <div class="rtb-right-toolbar__list-item-title">
                  Remove Host
                </div>
              </div>
            </template>

            <slot name="additional-items" />

            <template v-if="!mobile">
              <div class="rtb-right-toolbar__list-item">
                <div
                  class="rtb-right-toolbar__list-item-cnt"
                  @click="mediaSettings = true"
                >
                  <div class="rtb-right-toolbar__list-item-icon">
                    <SvgIcon
                      name="audio-video-source"
                      width="20"
                      height="20"
                      class="rtb-right-toolbar__list-item-icon"
                    />
                  </div>
                  <div class="rtb-right-toolbar__list-item-title">
                    Video & Sound
                  </div>
                </div>
              </div>

              <ToolbarDropdown
                svgIcon="gear-3"
                title="General Settings"
                v-model="currentMenu"
              >
                <div class="rtb-right-toolbar__list-item">
                  <div
                    class="rtb-right-toolbar__list-item-cnt"
                    :class="{
                      'rtb-right-toolbar__list-item-cnt--danger': isFullScreen
                    }"
                  >
                    <FullscreenToggle
                      :toggleFullSrceen="flag => toggleFullSrceen(flag)"
                    >
                      <template #icon>
                        <div class="rtb-right-toolbar__list-item-icon">
                          <SvgIcon
                            :name="
                              isFullScreen ? 'fullscreen-exit' : 'fullscreen'
                            "
                            width="20"
                            height="20"
                          />
                        </div>
                      </template>
                    </FullscreenToggle>
                  </div>
                </div>
                <div class="rtb-right-toolbar__list-item">
                  <v-tooltip :left="!left" :right="left" v-if="!isMeeting">
                    <template v-slot:activator="{ on }">
                      <div class="rtb-right-toolbar__list-item-cnt" v-on="on">
                        <SvgIcon
                          :name="isPotatoUser ? 'slow-mode-off' : 'slow-mode'"
                          width="20"
                          height="20"
                          class="rtb-right-toolbar__list-item-icon"
                        />
                        <div class="rtb-right-toolbar__list-item-title">
                          Slow Mode
                        </div>
                        <div class="rtb-right-toolbar__list-item-action">
                          <v-switch
                            hide-details
                            :input-value="isPotatoUser"
                            @change="toggleUserPotato"
                          />
                        </div>
                      </div>
                    </template>
                    <span :class="left ? 'right' : 'left'">
                      Slow mode is for poor wifi connections. <br />
                      It will hide videos of players on other teams
                    </span>
                  </v-tooltip>
                </div>
                <div
                  class="rtb-right-toolbar__list-item"
                  v-if="isSpeechToTextEnabled"
                >
                  <div class="rtb-right-toolbar__list-item-cnt">
                    <div class="rtb-right-toolbar__list-item-title">
                      Closed Captions
                    </div>
                    <div class="rtb-right-toolbar__list-item-action">
                      <v-switch hide-details v-model="toggleUserSpeechToText" />
                    </div>
                  </div>
                </div>
                <slot name="settings" />
                <slot name="logout" />
              </ToolbarDropdown>
            </template>
          </div>
        </div>

        <div class="rtb-right-toolbar__bottom">
          <slot name="bottom" :hover="hover" />
          <slot
            name="lobby"
            :hover="hover"
            :currentMenu="currentMenu"
            :setCurrentMenu="setCurrentMenu"
          />
        </div>
      </div>
      <AVDialog v-model="mediaSettings" />
    </v-navigation-drawer>
  </v-hover>
</template>

<script>
import Vue from "vue"
import { mapGetters, mapActions, mapState } from "vuex"
import { db } from "@/firebase"
import { WeveTooltip } from "@weve/ui"

import { copyToClipboard } from "@/helpers"
import FullscreenToggle from "@/components/FullscreenToggle.vue"
import UserVolumeSlider from "@/components/GroupTeams/Misc/UserVolumeSlider.vue"
import { Role, Mode, Mission } from "@shared/enums"

import MeetToggle from "@/components/GroupTeams/Common/MeetToggle"
import LiveChatMixin from "@/mixins/LiveChat"

import ToolbarDropdown from "./ToolbarDropdown.vue"
import ReactionsMenu from "./Reactions/ReactionsMenu.vue"

import AVDialog from "@/components/GroupTeams/Common/AudioVideoSettings/AVDialog"

import UserSoundService from "@/services/user-sound.service"

import { state as pickTeamState } from "@/components/dialogs/PickTeamsDialog"
import User from "@shared/User"

// TODO: Add margin top is the audio player is visible
const WEVE_LOGO_URL = require("@/assets/weve-logo.svg")
const WEVE_FULL_LOGO_URL = require("@/assets/weve-logo-big.svg")

const OPEN_DELAY = 200

import { gameHints } from "@/components/GroupTeams/Common/Main"
export default Vue.extend({
  name: "RightToolbar",
  mixins: [LiveChatMixin],
  components: {
    WeveTooltip,
    FullscreenToggle,
    UserVolumeSlider,
    ToolbarDropdown,
    ReactionsMenu,
    AVDialog,
    MeetToggle
  },
  props: {
    left: {
      type: Boolean,
      default: undefined
    },
    fixed: {
      type: Boolean,
      default: undefined
    },
    absolute: {
      type: Boolean,
      default: undefined
    },
    width: {
      type: String,
      default: "226"
    },
    height: {
      type: String,
      default: "100%"
    },
    miniVariantWidth: {
      type: String,
      default: "60"
    },
    isLobby: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: false
    },
    mobileIsOpen: {
      default: null
    }
  },
  data() {
    return {
      gameHints,
      OPEN_DELAY,
      mode: undefined,
      mediaSettings: false,
      visibility: false,
      variant: true,
      currentMenu: false,
      isFullScreen: false,
      isTip: false,
      weveLogo: WEVE_LOGO_URL,
      isCopyLinkTTVisible: false,
      isReadyToCopy: false,
      isLinkCopied: false,
      weveFullLogo: WEVE_FULL_LOGO_URL
    }
  },
  computed: {
    ...mapGetters([
      "user",
      "game",
      "settingsBar",
      "urlID",
      "audioDrawer",
      "isAutopilotOn",
      "isDIYGame",
      "actualGame"
    ]),
    ...mapState(["gameID", "orgID"]),
    ...mapGetters("auth", [
      "client",
      "clientID",
      "isHost",
      "isPlayer",
      "isModerator",
      "hasPreGame"
    ]),
    ...mapGetters("livechat", ["roomID", "room"]),
    ...mapGetters("ScreenCapture", [
      "isSharingScreen",
      "isProcessingScreenSharing"
    ]),
    ...mapGetters({
      currentMode: "getCurrentMode"
    }),
    ...mapGetters("Games", ["disabledHandraise"]),
    currentMissionID() {
      return this.$store.getters.currentMission
    },
    isRemoveHostAvailable() {
      return (
        !this.isLobby && this.game?.moderatorID && this.user?.role === Role.HOST
      )
    },
    customLogo() {
      return this.$theme.getImage("expoLogo")
    },
    customLogoLarge() {
      return this.$theme.getImage("expoLogoLarge")
    },
    canShowShareScreen() {
      return (
        [Mode.Theater, Mode.Meeting].includes(this.currentMode) && !this.isLobby
      )
    },
    customLogoStyle() {
      if (this.client)
        return this.client.customLogoStyle ? this.client.customLogoStyle : null
      if (this.game)
        return this.game.customLogoStyle ? this.game.customLogoStyle : null
      return null
    },
    isMeeting() {
      return this.currentMode === Mode.Meeting
    },
    isTheater() {
      return this.currentMode == Mode.Theater
    },
    isPlay() {
      return this.currentMode === Mode.Play
    },
    isChatMuted() {
      return !!this.room?.users?.[this.user?.id]?.muted
    },
    isPotatoUser() {
      return !!this.user.potato
    },
    isHelpRequested() {
      return this.game?.helpRequested
    },
    isUserMuted() {
      if (this.room) {
        return this.isChatMuted
      }
      return this.user.muted || this.user.permanentMuted
    },
    isModerated() {
      return this?.game?.moderated
    },
    isGamePage() {
      return this.$route.name === "game"
    },
    isPickTeamPage() {
      return this.$route.name === "pickteams"
    },
    isCopyLinkVisible() {
      return this.isGamePage && this.isDIYGame
    },
    isReactionsMenuVisible() {
      return Boolean(
        (this.isLobby && this.client?.stream?.roomID) ||
          (this.isGamePage &&
            (this.isMeeting || this.isTheater) &&
            !this.disabledHandraise)
      )
    },
    isSpeechToTextEnabled() {
      return this.isGamePage || this.isPickTeamPage
    },
    toggleUserSpeechToText: {
      get() {
        return this.user?.speechToText || this.user?.speechToText !== false
          ? true
          : false
      },
      set(value) {
        this.updateUser({
          userID: this.user?.id,
          obj: { speechToText: value ? true : false }
        })
      }
    },
    isOpen: {
      get() {
        return this.mobile ? this.mobileIsOpen : true
      },
      set(value) {
        if (this.mobile) {
          this.$emit("update:mobileIsOpen", value)
        }
      }
    },
    userTeam() {
      return this.teams[this.user.teamID]
    }
  },
  watch: {
    infoText(newValue, oldValue) {
      if (newValue && newValue !== oldValue && !this.isHost) {
        this.isTip = true
        setTimeout(() => {
          this.isTip = false
        }, 4000)
      }
    },
    visibility(value) {
      if (!value) {
        this.currentMenu = value
      } else {
        this.gameHints.ModeToggle = false
      }
    },
    isHelpRequested(value) {
      if (value && this.isHost) {
        this.$toast(value, {
          autoHideDelay: 10000,
          toaster: "b-toaster-top-center"
        })
      }
    }
  },
  mounted() {
    try {
      this.tryToShowCopyTooltip()
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    ...mapActions(["updateUser", "toggleAutopilot", "setGameModeratorID"]),
    ...mapActions("ScreenCapture", ["setIsSharingScreen"]),
    toggleHelpButton() {
      const userTeam = this.$store.getters.chats?.[this.user.teamID]
      const userName = this.getUserName(this.user)

      this.$store.dispatch("Games/updateGame", {
        ...this.game,
        theKey: this.game.theKey || this.game.id,
        helpRequested:
          !this.game.helpRequested && !this.isHost
            ? `${userName} from ${userTeam.name} has rung the bell`
            : null,
        helpRequestedBy: !this.game.helpRequested ? userName : null
      })
    },
    getUserName(user) {
      return User.getShortenedName(user)
    },
    async toggleUserPotato() {
      await this.updateUser({
        userID: this.user.id,
        obj: { potato: !this.user.potato }
      })
      this.$router.go()
    },
    toggleFullSrceen(flag) {
      this.isFullScreen = flag
    },
    toggleUserMute() {
      const { user } = this
      if (this.isLobby) {
        if (this.roomID && (user.id || this.isHost)) {
          db.auxiliary()
            .ref(
              `client/${this.clientID}/calls/rooms/${this.roomID}/users/${user?.id}/muted`
            )
            .set(!this.isChatMuted)
        }
      }

      UserSoundService.toggle(user)
    },
    async onSetIsSharingScreen() {
      if (this.isProcessingScreenSharing)
        return console.log("Please wait, still processing")
      if (!this.isSharingScreen) {
        this.$store.commit(
          "ScreenCapture/UPDATE_SHOW_SCREEN_SHARE_DIALOG",
          true
        )
      } else {
        this.setIsSharingScreen(false)
      }
    },
    copyLinkToGame() {
      this.isCopyLinkTTVisible = false
      copyToClipboard(
        `${window.location.origin}/gamelogin/${this.urlID}?auth=0`
      )
      setTimeout(() => {
        this.isCopyLinkTTVisible = true
        this.isLinkCopied = true
      }, 500)
    },
    copyLinkLeave() {
      setTimeout(() => {
        this.isReadyToCopy = false
        this.isLinkCopied = false
      }, 500)
    },
    tryToShowCopyTooltip() {
      const localStorageData =
        JSON.parse(localStorage.getItem(`user-${this.user.id}`)) || {}
      const { visitedGames = {} } = localStorageData
      if (
        this.$route.name === "game" &&
        this.isDIYGame &&
        !visitedGames[this.game?.id]
      ) {
        const update = JSON.stringify({
          ...localStorageData,
          visitedGames: { ...visitedGames, [`${this.game?.id}`]: 1 }
        })
        setTimeout(() => {
          this.isCopyLinkTTVisible = true

          localStorage.setItem(`user-${this.user.id}`, update)

          setTimeout(() => {
            this.isCopyLinkTTVisible = false
          }, 5000)
        }, 1000)
      }
    },
    setCurrentMenu(value) {
      this.currentMenu = value
    },
    openEditTeamsDialog() {
      pickTeamState.open = true
    }
  }
})
</script>

<style lang="scss">
.rtb-right-toolbar {
  $block: &;
  z-index: 99; //TODO: Testing correctly - Edge cases
  padding-top: 16px;
  padding-bottom: 24px;
  background-color: #292932 !important;
  color: #fff;
  transition: padding 0.3s;

  &--is-open {
    z-index: 1110;
  }

  &__cnt {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: padding 0.2s;

    #{$block}--with-mini-topbar & {
      padding-top: 30px;
    }

    #{$block}--with-mini-topbar-auditor & {
      padding-top: 42.5px;
    }
  }

  &__logo {
    position: relative;
    height: 54px;
    display: flex;
  }

  &__logo-small {
    height: 44px;
    width: 44px;
    position: relative;

    &--custom::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: 4px;
      background-color: #fff;
      z-index: -1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__logo-large {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;

    &--custom::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      border-radius: 4px;
      background-color: #fff;
      z-index: -1;
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    margin: 0;
    opacity: 0;
    transition: opacity 0.3s;
    padding-top: 24px;
    min-height: 56px;
    min-width: 180px;

    #{$block}--is-open & {
      opacity: 1;
    }
  }

  &__autopilot {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    text-align: center;
    transition: bottom 0.3s;

    #{$block}--is-open & {
      bottom: 0px;
    }
  }

  &.v-navigation-drawer--mini-variant {
    .rtb-right-toolbar__countdown {
      width: 60px;
      text-align: center;
      padding-left: 0;
      padding-top: 0;
    }
  }

  &__countdown {
    margin-left: -9px;
    text-align: center;
    transition: all 0.3s;
    text-align: left;
    padding-left: 5px;
    padding-top: 10px;

    .event-countdown {
      white-space: normal;
    }

    br {
      display: none;
    }

    #{$block}--is-open & {
      bottom: 25px;
      left: 4px;
    }

    .event-countdown {
      margin: 0;
    }

    .game-countdown {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .game-countdown__time {
      font-size: 20px;
      padding-left: 5px;
      padding-right: 5px;
      color: #00e599;

      &.medium {
        font-size: 18px;
      }

      &.small {
        font-size: 16px;
      }
    }

    .game-countdown__input {
      left: 0;
      top: 100%;
      margin-left: 0;
    }
  }

  &__top {
    height: 230px;
    padding-left: 8px;
    padding-right: 8px;
    transition: padding 0.3s;
    position: relative;

    .rtb-button-group {
      margin-top: 16px;
      width: 22px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
    }

    #{$block}--is-open & {
      padding-left: 14px;
      padding-right: 14px;

      .rtb-button-group {
        width: 182px;
      }
    }
  }

  &__mid {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 226px;
  }

  &__bottom {
    height: 222px;
    display: flex;
    flex-direction: column;
    min-width: 226px;
  }

  &__list {
    width: 100%;
  }

  &__list-item {
    position: relative;
  }

  &__list-item-cnt {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 20px;
    padding-right: 10px;
    cursor: pointer;

    &:hover {
      background-color: #586475;
    }

    &:active {
      background-color: #798294;
    }

    &--active {
      background-color: #393b42;
      &:hover {
        background-color: #393b42 !important;
      }
    }

    &--disabled {
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }

  &__list-item-icon {
    width: 22px;
    min-width: 22px;
    flex-basis: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    #{$block}__list-item-cnt--danger & {
      color: #ff4060;
    }
    #{$block}__list-item-cnt--green & {
      color: #00e599;
    }
  }

  &__list-item-title {
    flex: 1;
    margin-left: 8px;
    // TODO: Sofia Pro BUG
    margin-bottom: 2px;
    opacity: 0;

    #{$block}--is-open & {
      opacity: 1;
    }
  }

  &__list-item-action {
    margin-left: auto;

    .v-input,
    .v-input--selection-controls__input {
      margin: 0;
    }
  }

  &__sub-item {
    padding-top: 8px;
    padding-bottom: 8px;
    position: absolute;
    z-index: 1;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    background-color: #393b42;
    box-shadow: 0px 50px 40px rgba(15, 15, 17, 0.8);
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &__sub-item-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px;
    cursor: pointer;
  }

  &__info {
    min-height: 115px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    position: relative;
    &--visible {
      background-color: #23222a;
    }
  }

  &__info-icon {
    transition: opacity 0.3s;
  }

  &__info-title {
    margin: 19px 0 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    opacity: 0;
    transition: opacity 0.3s;
    #{$block}__info--visible & {
      opacity: 1;
    }
  }

  &__info-text {
    font-size: 14px;
    line-height: 20px;
    opacity: 0;
    transition: opacity 0.3s;
    span {
      color: #b6d6ff;
    }
    #{$block}__info--visible & {
      opacity: 1;
    }
  }

  &__volum_sfx {
    .v-slider input {
      width: 80px !important;
    }
  }
}
</style>
