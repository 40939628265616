var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "main-grid main-grid--default-bg",
      class: {
        "multi-team": _vm.isMultiTeam,
        "main-grid--with-sidebar": _vm.toolbarVisibility,
      },
      attrs: { fluid: "", "fill-height": "" },
    },
    [
      !_vm.isMeetingMediaAvailable
        ? _c(
            "div",
            { staticClass: "main-grid__sub-pbanel" },
            [
              _vm.showShowVirtualAwardDrawer
                ? _c(
                    "div",
                    { staticClass: "main-grid__virtual-award-show-img-btn" },
                    [
                      _c(
                        "RtbControlButton",
                        {
                          staticClass: "activities-float-btn",
                          staticStyle: { top: "0px" },
                          on: { click: _vm.openVirtualGameShowImageDrawer },
                        },
                        [
                          _c("SvgIcon", {
                            staticStyle: { "margin-right": "3px" },
                            attrs: {
                              name: "missions/photo",
                              width: "20",
                              height: "20",
                            },
                          }),
                          _c("SvgIcon", {
                            staticClass: "ml-1",
                            attrs: {
                              name: "chevron-right-regular",
                              size: "small",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isQuickDrawerBtnVisible || _vm.isActivityBtnVisible
                ? _c(
                    "div",
                    { staticClass: "main-grid__sub-pbanel-bg mt-3" },
                    [
                      _vm.isQuickDrawerBtnVisible
                        ? _c(
                            "RtbControlButton",
                            {
                              staticClass: "activities-float-btn",
                              on: { click: _vm.openQuickAddPanel },
                            },
                            [
                              _c("SvgIcon", {
                                attrs: {
                                  name: "quick-add",
                                  width: "20",
                                  height: "20",
                                },
                              }),
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { color: "white" },
                                },
                                [_vm._v("add_circle")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.hasActivityPanel
                        ? _c(
                            "WeveTooltip",
                            {
                              attrs: {
                                variant: "light",
                                placement: "bottomleft",
                                triggers: "manual",
                                closeable: "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "RtbControlButton",
                                          _vm._b(
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.isActivityBtnVisible,
                                                  expression:
                                                    "isActivityBtnVisible",
                                                },
                                              ],
                                              staticClass:
                                                "activities-float-btn",
                                              on: {
                                                click: _vm.openActivtyPanel,
                                              },
                                            },
                                            "RtbControlButton",
                                            attrs,
                                            false
                                          ),
                                          [
                                            _c("SvgIcon", {
                                              attrs: {
                                                name: "controller",
                                                width: "20",
                                                height: "20",
                                              },
                                            }),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-1",
                                                staticStyle: { color: "white" },
                                              },
                                              [_vm._v("add_circle")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2718833911
                              ),
                              model: {
                                value: _vm.gameHints.Activity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.gameHints, "Activity", $$v)
                                },
                                expression: "gameHints.Activity",
                              },
                            },
                            [
                              _c("div", { staticClass: "text-sm-left" }, [
                                _c("b", { staticClass: "text-base" }, [
                                  _vm._v("Activity Drawer"),
                                ]),
                                _c("p", { staticClass: "mb-0 mt-1 text-sm" }, [
                                  _vm._v(
                                    " Here is where you can load more activities "
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.isHearToggleVisible ? _c("HearToggle") : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isDIYGame && _vm.isSummonHostActive && !_vm.isFinaleMission
                ? _c(
                    "RtbButton",
                    {
                      staticClass: "mt-3 ml-2",
                      staticStyle: {
                        "font-weight": "bold",
                        "text-transform": "none",
                      },
                      attrs: {
                        disabled: _vm.isSummonHostDisabled && !_vm.isHost,
                      },
                      on: { click: _vm.onSummonHost },
                    },
                    [
                      _c("SvgIcon", {
                        staticClass: "mr-2",
                        attrs: {
                          name: "become-host4",
                          width: "20",
                          height: "20",
                        },
                      }),
                      _vm.isSummonHostDisabled
                        ? [_vm._v(" Pro Host Called ")]
                        : [_vm._v(" Call a Pro Host ")],
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isQuickDrawerAvailable ? _c("QuickDrawer") : _vm._e(),
      _vm.showShowVirtualAwardDrawer
        ? _c("VirtualGameShowImageDrawer")
        : _vm._e(),
      _vm.isSvgActive
        ? _c(
            "WeveTooltip",
            {
              attrs: { variant: "light", placement: "bottomleft" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var attrs = ref.attrs
                      return [
                        _c(
                          "SvgIcon",
                          _vm._b(
                            {
                              staticClass: "hey-ho",
                              style: {
                                position: "absolute",
                                top: "8px",
                                right: "72px",
                                fill: "white",
                                zIndex: 200,
                              },
                              attrs: {
                                fill: "white",
                                name: "qr-code",
                                width: "64",
                                height: "64",
                              },
                            },
                            "SvgIcon",
                            attrs,
                            false
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                986109989
              ),
            },
            [_c("div", [_vm._v("QR Code is Shown")])]
          )
        : _vm._e(),
      _c(
        "v-layout",
        { class: { "game-over": _vm.showTeamResults }, attrs: { column: "" } },
        [
          _vm.streamUrl || (_vm.game && _vm.game.stream)
            ? _c("RecordingIndicator", {
                staticClass: "main-grid__recording",
                class: {
                  "main-grid__recording--shifted": _vm.raffleVisible,
                },
                attrs: { startingStream: _vm.isProccessingStream },
              })
            : _vm._e(),
          !_vm.collapseTopRow
            ? _c(
                "v-flex",
                {
                  staticClass: "current-team-row",
                  class: _vm.game.hideTeamTags && "pt-3 mt-3",
                  attrs: { shrink: "", "d-flex": "" },
                },
                [
                  _vm.isTopTeamTagVisible &&
                  _vm.mission &&
                  _vm.mission.behavior === _vm.MissionType.JeopardAI
                    ? _c("JeopardAiTeamTag", {
                        staticClass: "mb-2 mt-2",
                        staticStyle: { height: "35px" },
                        attrs: { team: _vm.viewerTeam },
                      })
                    : _vm.isTopTeamTagVisible
                    ? _c("TeamTag", {
                        staticClass: "mb-2 mt-2",
                        staticStyle: { height: "35px" },
                        attrs: { mode: _vm.mode, team: _vm.viewerTeam },
                      })
                    : _vm._e(),
                  _c(
                    "transition",
                    {
                      attrs: {
                        name: "game-over-current-team-transition",
                        mode: "out-in",
                      },
                    },
                    [
                      _vm.isFactMatchRelatedResult && !_vm.highlightedTeamID
                        ? _c(
                            "v-flex",
                            {
                              key: "fact-match-result-text",
                              staticClass: "the-winners-text",
                            },
                            [_vm._v(" RESULTS ")]
                          )
                        : _vm.isFactMatchResult
                        ? _c("FactMatchResult", {
                            key: "fact-match-results",
                            attrs: { teamID: _vm.highlightedTeamID },
                          })
                        : _vm.isMatchGameResult
                        ? _c("ImageMatchResult", {
                            key: "fact-match-results",
                            attrs: { teamID: _vm.highlightedTeamID },
                          })
                        : _vm.isOrderTheCardsGameResult
                        ? _c("OrderTheCardsGameResult", {
                            key: "order-the-cards-results",
                            attrs: { teamID: _vm.highlightedTeamID },
                          })
                        : _vm.isVideoTeamResults
                        ? _c("VideoTeamGameResult", {
                            key: "order-the-cards-results",
                            attrs: {
                              teamID: _vm.highlightedTeamID,
                              votes: _vm.votes,
                            },
                          })
                        : _vm.isTopTeamScrollerVisible
                        ? _c("TeamScroller", {
                            key: "top-team-scroller",
                            staticClass: "main-grid__team-scroller--top",
                            attrs: {
                              maxUsersPerTeam: 1,
                              teams: _vm.topTeams,
                              mode: _vm.mode,
                              label: _vm.topTeamScrollerLabel,
                              condensed: _vm.isViewerPresenter,
                              round: _vm.canRoundTopTeams,
                              identifier: "top-teams-scroller",
                            },
                          })
                        : _vm.isFinaleMode &&
                          _vm.mission.behavior === _vm.MissionType.JeopardAI &&
                          _vm.jeopardAiModule === _vm.Module.WRAPUP
                        ? _c("JeopardAiTeamResultsText", {
                            key: "jeopardai-challange-text",
                            staticClass: "the-winners-text",
                          })
                        : _vm.isTopTeamVisible &&
                          _vm.viewerTeam &&
                          _vm.viewerTeam.id
                        ? _c("TeamView", {
                            key: "top-team-" + _vm.viewerTeam.id,
                            ref: "topTeam",
                            attrs: {
                              teamID: _vm.viewerTeam.id,
                              mode: _vm.modeWithOverrides,
                              countdown: _vm.countdown,
                              endTime: _vm.endTime,
                              potato: _vm.isViewerPotato,
                              top: "",
                            },
                          })
                        : _vm.isFinaleMode && !_vm.highlightedTeamID
                        ? _c(
                            "v-flex",
                            {
                              key: "winners-are-text",
                              staticClass: "the-winners-text",
                            },
                            [_vm._v(" AND THE WINNERS ARE... ")]
                          )
                        : _vm.isFinaleMode &&
                          _vm.highlightedTeamID === "location-standing"
                        ? _c(
                            "v-flex",
                            {
                              key: "location-standing",
                              staticClass: "the-winners-text",
                            },
                            [_vm._v(" LOCATION STANDINGS ")]
                          )
                        : _vm.game.importedFromBreadcrumb
                        ? _c("GameOverTeamImage", {
                            key: _vm.highlightedTeamID,
                            attrs: { teamID: _vm.highlightedTeamID },
                          })
                        : _vm.isFinaleMode && _vm.highlightedTeamID
                        ? _c("TeamView", {
                            key: "game-over-team-" + _vm.highlightedTeamID,
                            staticClass: "game-over-current-team",
                            attrs: {
                              teamID: _vm.highlightedTeamID,
                              potato: _vm.isViewerPotato,
                              mode: null,
                              teamPosition:
                                _vm.getTeamIndexByID(_vm.highlightedTeamID) + 1,
                              starPlayerID: null,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            {
              staticClass: "game-row bg-center",
              class: {
                "black-background": _vm.isSlides,
                "bg-cover": !_vm.isSlides,
                "pt-4": _vm.enlargedUI && !_vm.showTeamResults,
              },
              attrs: { grow: "" },
            },
            [
              _c(
                "transition",
                {
                  attrs: {
                    mode: "out-in",
                    name: "main-grid__background-transition",
                  },
                },
                [
                  _vm.backgroundImageUrl
                    ? _c("div", {
                        key: _vm.backgroundImageUrl,
                        staticClass: "main-grid__background translate-z",
                        class: _vm.backgroundClass,
                        style: {
                          backgroundImage:
                            "url(" + _vm.backgroundImageUrl + ")",
                        },
                      })
                    : _vm._e(),
                ]
              ),
              !_vm.isSlides
                ? _c("CanvasBackground", {
                    staticClass: "main-grid__canvas-bg",
                  })
                : _vm._e(),
              _vm.shouldShowAnnouncement
                ? _c("MeetingAnnouncement", {
                    staticClass: "flex shrink column",
                  })
                : _vm._e(),
              _vm.shouldShowMeetingRating
                ? _c("MeetingRating", { staticClass: "flex shrink column" })
                : _vm._e(),
              _vm.raffleAvailable ? _c("RaffleComponent") : _vm._e(),
              _c("ModeMapper", {
                staticClass: "grow",
                attrs: { mode: _vm.mode },
              }),
              _c(
                "transition",
                {
                  attrs: {
                    name: "game-over-middle-row-transition",
                    mode: "out-in",
                  },
                },
                [
                  _vm.showTeamResults &&
                  _vm.mission.behavior === _vm.MissionType.JeopardAI
                    ? _c("JeopardAiTeamResults", {
                        staticClass: "main-grid__team-results",
                      })
                    : _vm.showTeamResults
                    ? _c("TeamResults", {
                        staticClass: "main-grid__team-results",
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.textChat
                ? _c("GameChatWrap", {
                    staticClass: "chat-position",
                    class: {
                      "chat-position--theater": _vm.isTheaterMode,
                    },
                  })
                : _vm._e(),
              _vm.isSpeechToTextEnabled ? _c("Speech") : _vm._e(),
            ],
            1
          ),
          _vm.isBottomTeamScrollerOn
            ? [
                !_vm.isBottomTeamPanelHidden
                  ? _c("TeamScroller", {
                      attrs: {
                        maxUsersPerTeam: 1,
                        teams: _vm.bottomTeams,
                        mode: _vm.mode,
                        minified: !_vm.isBottomTeamVideoVisible,
                        identifier: "bottom-teams-scroller",
                      },
                    })
                  : _vm._e(),
              ]
            : [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "game-over-bottom-row-transition",
                      mode: "out-in",
                    },
                  },
                  [
                    !_vm.isBottomTeamPanelHidden
                      ? _c(
                          "v-flex",
                          {
                            staticClass: "two-teams-row translate-z",
                            attrs: { shrink: "" },
                          },
                          [
                            !_vm.isWatchParty
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass: "mb-2",
                                    staticStyle: {
                                      height: "32px",
                                      "margin-top": "-16px",
                                    },
                                    attrs: { row: "" },
                                  },
                                  _vm._l(_vm.bottomTeams, function (team) {
                                    return _c(
                                      "div",
                                      {
                                        key: "team-tag-" + team.id,
                                        staticClass:
                                          "rtb-d-flex justify-center main-grid__bottom-team-tag",
                                        style:
                                          "width: " +
                                          _vm.bottomTeamWidthInPercent +
                                          "%",
                                      },
                                      [
                                        !_vm.isTagsHidden &&
                                        _vm.mission &&
                                        _vm.mission.behavior ===
                                          _vm.MissionType.JeopardAI
                                          ? _c("JeopardAiTeamTag", {
                                              attrs: { team: team },
                                            })
                                          : !_vm.isTagsHidden
                                          ? _c("TeamTag", {
                                              attrs: {
                                                mode: _vm.mode,
                                                team: team,
                                                submittedIndictor:
                                                  !_vm.isBottomTeamVideoVisible,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _c(
                              "transition",
                              {
                                attrs: {
                                  name: "game-over-bottom-row-transition",
                                  mode: "out-in",
                                },
                              },
                              [
                                _vm.isBottomTeamVideoVisible
                                  ? _c(
                                      "v-layout",
                                      { attrs: { row: "" } },
                                      _vm._l(_vm.bottomTeams, function (team) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: "team-" + team.id,
                                            staticClass: "multi-team-col",
                                            style:
                                              "width: " +
                                              _vm.bottomTeamWidthInPercent +
                                              "%",
                                          },
                                          [
                                            _c("TeamView", {
                                              attrs: {
                                                mode: _vm.modeWithOverrides,
                                                teamID: team.id,
                                                potato: _vm.isViewerPotato,
                                                minified: _vm.isMultiTeam,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
          _vm.toolbarVisibility
            ? _c("RightToolbar", {
                attrs: { absolute: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.game.externalName || _vm.game.name) +
                              " "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "countdown",
                      fn: function () {
                        return [
                          (_vm.eventEndTime && _vm.isHost) ||
                          _vm.showHostlessTimer
                            ? _c("EventCountdown", {
                                ref: "countdown",
                                attrs: {
                                  endTime: _vm.eventEndTime,
                                  rushThemUp: _vm.rushThemUp,
                                  autopilot: _vm.isAutopilotOn,
                                  escapeRoom: _vm.game.escapeRoom,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "settings",
                      fn: function () {
                        return [
                          _vm.isSuper
                            ? _c(
                                "div",
                                { staticClass: "rtb-right-toolbar__list-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-cnt",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-title",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.showOnboardingPlayersComputed
                                                  ? "Only In-Game Players"
                                                  : "Show All Players"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-action",
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { "hide-details": "" },
                                            model: {
                                              value:
                                                _vm.showOnboardingPlayersComputed,
                                              callback: function ($$v) {
                                                _vm.showOnboardingPlayersComputed =
                                                  $$v
                                              },
                                              expression:
                                                "showOnboardingPlayersComputed",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isAudit
                            ? _c(
                                "div",
                                { staticClass: "rtb-right-toolbar__list-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-cnt",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-title",
                                        },
                                        [_vm._v(" Mute All Players ")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-action",
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { "hide-details": "" },
                                            model: {
                                              value:
                                                _vm.toggleAuditorMuteAllPlayers,
                                              callback: function ($$v) {
                                                _vm.toggleAuditorMuteAllPlayers =
                                                  $$v
                                              },
                                              expression:
                                                "toggleAuditorMuteAllPlayers",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "logout",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "rtb-right-toolbar__list-item mt-auto",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rtb-right-toolbar__list-item-cnt",
                                  on: {
                                    click: function ($event) {
                                      return _vm.$navigator.navigateTo(
                                        "/logout"
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("SvgIcon", {
                                    staticClass:
                                      "rtb-right-toolbar__list-item-icon",
                                    attrs: {
                                      name: "signout_24px",
                                      width: "20",
                                      height: "20",
                                      fill: "#FF4060",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-title",
                                    },
                                    [_vm._v("Logout")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    _vm.hasPreGame
                      ? {
                          key: "lobby",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "rtb-right-toolbar__list-item mt-auto",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__list-item-cnt",
                                      on: { click: _vm.pushToLobby },
                                    },
                                    [
                                      _c("SvgIcon", {
                                        staticClass:
                                          "rtb-right-toolbar__list-item-icon",
                                        attrs: {
                                          name: "sign-out",
                                          width: "20",
                                          height: "20",
                                          color: "danger",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "rtb-right-toolbar__list-item-title",
                                        },
                                        [_vm._v(" Exit to Lobby ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "bottom",
                      fn: function (ref) {
                        var hover = ref.hover
                        return [
                          _vm.game.moderated && _vm.everyoneCanHearEachOther
                            ? _c(
                                "div",
                                {
                                  staticClass: "rtb-right-toolbar__info",
                                  class: {
                                    "rtb-right-toolbar__info--visible": hover,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__info-title",
                                    },
                                    [_vm._v("Who can hear you:")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "rtb-right-toolbar__info-text",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("All participant talk is on,"),
                                      ]),
                                      _vm._v(" everyone can hear you "),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _vm.isHost || _vm.isDIYGame
            ? [
                _vm.isMeetingMode && !_vm.isWhiteElephant
                  ? _c("HostMiniTopBar")
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.isNoteDialogVisible
        ? _c(
            "WeveAnnouncementWidget",
            {
              staticClass: "note-dialog",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.hideNoteDialog.apply(null, arguments)
                },
                close: _vm.hideNoteDialog,
              },
            },
            [
              _c("div", { staticClass: "note-dialog__title" }, [
                _vm._v("Event Notes"),
              ]),
              _c("WeveTextarea", {
                staticClass: "note-dialog__text",
                attrs: {
                  disabled: !(_vm.user && _vm.user.super),
                  placeholder: "Type here...",
                },
                model: {
                  value: _vm.note,
                  callback: function ($$v) {
                    _vm.note = $$v
                  },
                  expression: "note",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isHost ? _c("BreakoutTypeDialog") : _vm._e(),
      _c(
        "Popup",
        {
          style: _vm.assetPopupStyle,
          attrs: {
            isOpen: _vm.zoomMissionAssetPhoto.visible,
            size: _vm.isViewerPresenter ? "xxl" : "",
          },
          on: {
            onClose: function ($event) {
              return _vm.closePopup()
            },
            onClickOutside: function ($event) {
              return _vm.closePopup()
            },
          },
        },
        [
          _c("v-layout", { attrs: { column: "", "justify-center": "" } }, [
            _c("div", {
              staticClass: "text-center mb-2",
              staticStyle: { "margin-top": "-16px" },
              domProps: { innerHTML: _vm._s(_vm.popupTitle) },
            }),
            _c("img", {
              staticStyle: {
                "max-width": "auto",
                "max-height": "80vh",
                transform: "translateZ(0)",
                "object-fit": "contain",
              },
              attrs: {
                src: _vm.zoomMissionAssetPhoto.photo,
                alt: "missions photo",
              },
            }),
          ]),
        ],
        1
      ),
      !!_vm.salesPopUp
        ? _c("SalesPopup", {
            attrs: { isOpen: _vm.salesPopUp },
            on: {
              onClose: function ($event) {
                return _vm.closePopup()
              },
              onClickOutside: function ($event) {
                return _vm.closePopup()
              },
            },
          })
        : _vm._e(),
      _vm.isModerator && !_vm.isMeetingMode
        ? _c(
            "div",
            {
              staticClass: "tutorial-overlay",
              class: { "tutorial-overlay--visible": _vm.getGameFtuxStep },
            },
            [
              _c(
                "div",
                { staticClass: "tutorial-overlay__controls" },
                [
                  _vm.getGameFtuxStep !== 3
                    ? [
                        _c(
                          "WeveButton",
                          {
                            attrs: { variant: "secondary" },
                            on: { click: _vm.hideFtux },
                          },
                          [_vm._v(" Skip ")]
                        ),
                        _c(
                          "WeveButton",
                          {
                            staticClass: "ml-3",
                            on: { click: _vm.nexFtuxStep },
                          },
                          [_vm._v(" Next ")]
                        ),
                      ]
                    : _c("WeveButton", { on: { click: _vm.hideFtux } }, [
                        _vm._v(" Start Hosting "),
                      ]),
                ],
                2
              ),
              _c("div", { staticClass: "tutorial-overlay__container" }, [
                _c("div", { staticClass: "tutorial-overlay__tutorial-title" }, [
                  _vm._v("YOU’RE THE HOST!"),
                ]),
                _c("div", { staticClass: "tutorial-overlay__subtitle" }, [
                  _vm._v("Here are a few tips"),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _c("LinkManager"),
      _vm.isDIYGame ? _c("PickTeamsDialog") : _vm._e(),
      _c("PortalTarget", { attrs: { name: "tips-target", multiple: "" } }),
      _vm.isHost ? _c("MainDrawer") : _vm._e(),
      _vm.isAudioDrawerAvailable
        ? _c("AudioDrawer", {
            attrs: { "full-height": _vm.isDIYGame && !_vm.isHost },
          })
        : _vm._e(),
      _vm.qrCodeActivity ? _c("QrCodeActivityPopup") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }