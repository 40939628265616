import { ClientService } from "@/services/client.service"

export const ActionTypes = {
  APPLY_CLINET_THEME_TO_GAMES: "APPLY_CLINET_THEME_TO_GAMES"
}

const ClientModule = {
  state: {
    currentClient: null
  },
  mutations: {
    setCurrentClient(state, payload) {
      state.currentClient = payload
    }
  },
  getters: {
    restriction(state) {
      return state.restriction
    },
    client(state) {
      return state.client
    }
  },
  actions: {
    /**
     * @param { { games: Record<string, object> } } payload
     */
    [ActionTypes.APPLY_CLINET_THEME_TO_GAMES]({ rootState }, payload) {
      return ClientService.applyThemeToGames(
        rootState.orgID,
        rootState.auth.client.themeID,
        payload.games
      )
    }
  }
}

export default ClientModule
