import { JeopardAiUser } from "@/components/GroupTeams/Common/Games/JeopardAI/types"
import { Module } from "@/components/GroupTeams/Common/Games/JeopardAI/enums"

type State = {
  module: undefined | Module
  users: JeopardAiUser[]
  scribes: JeopardAiUser[]
}

export default {
  namespaced: true,
  state: {
    module: undefined,
    users: [],
    scribes: []
  } as State,
  mutations: {
    UPDATE_MODULE(state: State, payload: Module) {
      state.module = payload
    },
    UPDATE_USERS(state: State, payload: JeopardAiUser[]) {
      state.users = payload
    },
    UPDATE_SCRIBES(state: State, payload: JeopardAiUser[]) {
      state.scribes = payload
    }
  }
}
