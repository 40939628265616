import ChatService from "@/components/Chat/index.js"
import TeamService from "@/services/team.service"
import Team from "@shared/Team"
import Mode from "@shared/enums/Mode"
import Mission from "@shared/enums/Mission"

let chatsSubscriptionRef = null
let chatsSubscription = null

const AVAILABLE_CHAT_CONTEXT = ChatService.allChatContext()

const ChatModule = {
  state: {
    chats: {},
    isLobbyHostChat: false
  },
  mutations: {
    SET_TEAMS(state, payload) {
      state.chats = Team.denormalize(Team.normalize(payload || {}))
    },
    UPDATE_IS_LOBBY_HOST_CHAT(state, status) {
      state.isLobbyHostChat = status
    }
  },
  actions: {
    subscribeToChats({ commit }, { orgID, gameID }) {
      if (chatsSubscriptionRef)
        chatsSubscriptionRef.off("value", chatsSubscription)

      chatsSubscriptionRef = TeamService.chatsRef(orgID, gameID)

      return new Promise(resolve => {
        chatsSubscription = chatsSubscriptionRef.on("value", snapshot => {
          commit("SET_TEAMS", snapshot.val())
          resolve()
        })
      })
    },
    async createTeam({ rootState }, payload) {
      if (!payload.name)
        throw new Error("Cannot create a new team without a name")
      if (!payload.icon)
        throw new Error("Cannot create a new team withoutan an icon")

      let gameID = rootState.gameID

      if (payload.gameID) {
        gameID = payload.gameID
        delete payload.gameID
      }

      const ref = TeamService.teamsRef(rootState.orgID, gameID)

      const teamID = ref.push().key

      const team = { ...payload, id: teamID, key: teamID }

      await ref.child(teamID).set(team)

      return team
    },
    async deleteTeam({ rootState, dispatch }, payload) {
      if (!payload.id) throw new Error("Cannot delete a team with an ID")
      let gameID = rootState.gameID

      if (payload.gameID) {
        gameID = payload.gameID
        delete payload.gameID
      }

      await TeamService.teamRef(rootState.orgID, gameID, payload.id).remove()
    },
    async changeTeamName({ rootState }, payload) {
      await TeamService.teamRef(
        rootState.orgID,
        rootState.gameID,
        payload.id
      ).update({ name: payload.name })
    },
    async updateTeam({ rootState }, payload) {
      let gameID = rootState.gameID

      if (payload.gameID) {
        gameID = payload.gameID
        delete payload.gameID
      }

      await TeamService.teamRef(rootState.orgID, gameID, payload.id).update({
        ...payload,
        color: payload.color || null
      })
    },
    updateIsLobbyHostChat({ commit }, status) {
      commit("UPDATE_IS_LOBBY_HOST_CHAT", status)
    }
  },
  getters: {
    messages: state => state.messages,
    chats: state => state.chats,
    allTeams: state => state.allTeams,
    getTeamDetail: (_, __, ___, rootGetters) => teamID =>
      rootGetters["chats"][teamID] || {},
    isLobbyHostChat: state => state.isLobbyHostChat,
    isTeamChatOn(_, __, ___, rootGetters) {
      return game => {
        game = game ?? rootGetters["game"]
        const mode = rootGetters["getCurrentMode"]
        const mission = rootGetters["getCurrentMission"]

        if (mode === Mode.Meeting || mission?.behavior === Mission.Charades)
          return false

        const openWeve = game?.moderated || game?.diy
        if (!openWeve) {
          return Boolean(game?.[AVAILABLE_CHAT_CONTEXT.TEAM])
        }

        return game?.[AVAILABLE_CHAT_CONTEXT.TEAM] === false ? false : true
      }
    },
    isRoomChatOn(_, __, ___, rootGetters) {
      return game => {
        game = game ?? rootGetters["game"]
        const openWeve = Boolean(game?.moderated || game?.diy)
        if (!openWeve) return Boolean(game?.[AVAILABLE_CHAT_CONTEXT.GAME])
        return game?.[AVAILABLE_CHAT_CONTEXT.GAME] === false ? false : true
      }
    },
    isLobbyChatOn(_, __, ___, rootGetters) {
      return game => {
        game = game ?? rootGetters["game"]
        return (
          rootGetters["auth/hasPreGame"] &&
          Boolean(game?.[AVAILABLE_CHAT_CONTEXT.LOBBY])
        )
      }
    },
    isTextChatOn(_, getters) {
      return Boolean(
        getters.isTeamChatOn() ||
          getters.isRoomChatOn() ||
          getters.isLobbyChatOn()
      )
    }
  }
}

export default ChatModule
