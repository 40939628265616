
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"

import mixins from "vue-typed-mixins"
import { TaggableMixinFactory } from "../mixins"

export default mixins(Vue.extend(TaggableMixinFactory.create("span"))).extend({
  name: "RTBBadge",
  functional: true,
  render(h, { props, children, data }) {
    // @ts-ignore
    return h(props.tag, mergeData(data, { staticClass: "rtb-badge" }), children)
  }
})
