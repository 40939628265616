const Mode = require("./enums/Mode")
const Mission = require("./enums/Mission")

module.exports = {
  [Mission.SpiralDrawing]: [Mode.Play, Mode.Voting],
  [Mission.Text]: [Mode.Explain, Mode.Play],
  [Mission.MultipleChoice]: [Mode.Explain, Mode.Play],
  [Mission.BuzzIn]: [Mode.Social],
  [Mission.Categories]: [Mode.Explain, Mode.Play],
  [Mission.FreeForm]: [Mode.Play],
  [Mission.Info]: [Mode.Welcome, Mode.Explain],
  [Mission.TeamName]: [Mode.Play],
  [Mission.Photo]: [Mode.Welcome, Mode.Play, Mode.Voting, Mode.Results],
  [Mission.TeamPhoto]: [Mode.Welcome, Mode.Play, Mode.Voting],
  [Mission.TakePhoto]: [Mode.Welcome, Mode.Play, Mode.Voting, Mode.Results],
  [Mission.PhotoBooth]: [Mode.Welcome, Mode.Play, Mode.Voting],
  [Mission.Video]: [Mode.Welcome, Mode.Play, Mode.Voting, Mode.Results],
  [Mission.VideoIndividual]: [
    Mode.Welcome,
    Mode.Play,
    Mode.Voting,
    Mode.Results
  ],
  [Mission.VideoTeam]: [Mode.Welcome, Mode.Huddle, Mode.Results],
  [Mission.Giphy]: [Mode.Explain, Mode.Play, Mode.Voting, Mode.Results],
  [Mission.RatingPoll]: [Mode.Explain, Mode.Play, Mode.Results],
  [Mission.Poll]: [Mode.Explain, Mode.Play, Mode.Results],
  [Mission.FactMatch]: [Mode.Explain, Mode.Huddle, Mode.Results],
  [Mission.MatchGame]: [Mode.Explain, Mode.Social, Mode.Results],
  [Mission.OrderTheCards]: [Mode.Explain, Mode.Social, Mode.Results],
  [Mission.StartButton]: [Mode.Play],
  [Mission.OpenWeveStart]: [Mode.Play],
  [Mission.HandMovement]: [Mode.Social],
  [Mission.Link]: [Mode.Play],
  [Mission.YouTube]: [Mode.Play],
  [Mission.Represent]: [Mode.Explain, Mode.Play],
  [Mission.Slides]: [Mode.Theater],
  [Mission.Raffle]: [Mode.Meeting],
  [Mission.URL]: [Mode.Welcome, Mode.Play, Mode.Voting, Mode.Results],
  [Mission.Award]: [Mode.Explain, Mode.Play, Mode.Social],
  [Mission.Unlock]: [Mode.Explain, Mode.Play],
  [Mission.Video]: [Mode.Welcome, Mode.Play, Mode.Voting, Mode.Results],
  [Mission.VideoIndividual]: [
    Mode.Welcome,
    Mode.Play,
    Mode.Voting,
    Mode.Results
  ],
  [Mission.VideoTeam]: [Mode.Huddle, Mode.Results],
  [Mission.DrawingEachTeam]: [Mode.Play, Mode.Voting],
  [Mission.DrawingPictionary]: [Mode.Play, Mode.Results],
  [Mission.PlayerRating]: [Mode.Explain, Mode.Play, Mode.Results],
  [Mission.TwoTruths]: [Mode.Explain, Mode.Play],
  [Mission.TwoTruthsReveal]: [Mode.Explain, Mode.Social],
  [Mission.RoyalRumble]: [Mode.Explain, Mode.Play, Mode.Huddle, Mode.Social],
  [Mission.PriceIsRight]: [Mode.Social],
  [Mission.WhiteElephant]: [Mode.Meeting],
  [Mission.TelephonePictionary]: [Mode.Play, Mode.Results],
  [Mission.UnconditionalPositiveRegard]: [Mode.Play, Mode.Voting],
  [Mission.WordCloud]: [Mode.Explain, Mode.Play, Mode.Results],
  [Mission.Charades]: [Mode.Social],
  [Mission.DiscussionGroup]: [Mode.Play],
  [Mission.QuestionCards]: [Mode.Social],
  [Mission.QRCode]: [Mode.Play],
  [Mission.FamilyFeud]: [Mode.Social],
  [Mission.JeopardAI]: [Mode.Play, Mode.Over]
}
