export enum UserCardColor {
  Default = "default",
  Primary = "primary",
  JeopardAi = "jeopardai",
  Success = "success",
  Wrong = "wrong",
  Gray = "gray"
}

export enum UserCardState {
  Muted = "muted",
  Mobile = "mobile",
  Skip = "skip"
}

export interface UserCardProps {
  color?: UserCardColor
  badge?: boolean
  name?: string
  current?: boolean
  points?: number
  submitted?: boolean
  onboarding?: boolean
  states?: Set<UserCardState>
  mutable?: boolean
  configurable?: boolean
}

export interface UserCardEvents {
  configure: undefined
  "update:muted": boolean
}
