var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mode-mapper", class: _vm.classes },
    [
      _c(
        "transition",
        { attrs: { name: "mode-mapper__transition", mode: "out-in" } },
        [
          _vm.placeholderText
            ? _c(
                "div",
                {
                  staticClass: "mode-mapper__placeholder",
                  class: {
                    "mode-mapper__placeholder--enlarged": _vm.enlargedUI,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mode-mapper__placeholder-text" },
                    [
                      _c("ResizableText", {
                        attrs: { message: _vm.placeholderText },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm.pinnedTeamID
            ? _c("MeetingSmartGrid", {
                key: _vm.pinnedTeamID,
                staticClass: "mode-mapper__pinned-users",
                attrs: { users: _vm.pinnedUsers },
              })
            : _vm._e(),
          _vm.behavior === _vm.Mission.JeopardAI && _vm.mode === _vm.Mode.Play
            ? _c("JeopardAI")
            : _vm.behavior === _vm.Mission.MusicalKeys &&
              _vm.mode === _vm.Mode.Play
            ? _c("MusicalKeys")
            : _vm.hasSubmitted &&
              _vm.isHuddleVideoTeamMission &&
              _vm.layout === _vm.Layout.PLAY
            ? _c("SocialVideo")
            : _vm.behavior === _vm.Mission.UnconditionalPositiveRegard &&
              _vm.layout === _vm.Layout.PLAY
            ? _c("UnconditionalPositiveRegard")
            : _vm.behavior === _vm.Mission.DiscussionGroup &&
              _vm.layout === _vm.Layout.PLAY
            ? _c("DiscussionGroup")
            : _vm.behavior === _vm.Mission.TelephonePictionary &&
              _vm.layout === _vm.Layout.PLAY
            ? _c("TelephonePictionary")
            : _vm.behavior === _vm.Mission.WordCloud &&
              _vm.layout === _vm.Layout.PLAY
            ? _c("WordCloud")
            : _vm.behavior === _vm.Mission.SpiralDrawing &&
              _vm.mode === _vm.Mode.Play
            ? _c("SpiralDrawing", { attrs: { mission: _vm.mission } })
            : _vm.layout === _vm.Layout.PLAY
            ? _c("PlayMode", { attrs: { mode: _vm.modeWithPresenterCondtion } })
            : _vm.layout === _vm.Layout.SOCIAL && _vm.isSlides
            ? _c("SlidesWrapper")
            : _vm.layout === _vm.Layout.SOCIAL && _vm.isTwoTruthsReveal
            ? _c("TwoTruthsReveal")
            : _vm.layout === _vm.Layout.SOCIAL &&
              (_vm.isAward || _vm.isPhotoAward)
            ? _c("Award")
            : _vm.layout === _vm.Layout.SOCIAL && _vm.isVideo
            ? _c("SocialVideo")
            : _vm.isSocialTeamUsers
            ? _c("SocialTeamUsers", {
                key: _vm.mission.id,
                attrs: { mode: _vm.modeWithOverrides },
              })
            : _vm.layout === _vm.Layout.SOCIAL && _vm.isMatchGame
            ? _c("ImageMatch")
            : _vm.layout === _vm.Layout.SOCIAL && _vm.isFactMatch
            ? _c("FactMatch")
            : _vm.layout === _vm.Layout.SOCIAL && _vm.isOrderTheCardsGame
            ? _c("OrderTheCardsGame")
            : _vm.isTheaterVoting
            ? _c("TheaterVoting")
            : _vm.isVoting
            ? _c("VotingMode")
            : _vm.layout === _vm.Layout.MEETING
            ? _c("MeetingMode", { attrs: { mode: _vm.modeWithOverrides } })
            : _vm._e(),
        ],
        1
      ),
      _vm.isHostMediaVisible
        ? _c("ModeMapperHostContainer", {
            attrs: {
              mission: _vm.mission,
              mode: _vm.modeWithOverrides,
              height: _vm.height,
              hasSubmittedVideoTeam: !!_vm.hasSubmitted,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var rounded = ref.rounded
                    var badgeHidden = ref.badgeHidden
                    return [
                      _c("HostMedia", {
                        attrs: {
                          rounded: rounded,
                          "hide-badge": badgeHidden,
                          mode: _vm.modeWithOverrides,
                          overrrideSource: _vm.overrrideSource,
                        },
                        on: { ended: _vm.onHostMediaEnded },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              1565090811
            ),
          })
        : _vm._e(),
      !!_vm.mission &&
      _vm.$_TipJarMixin_isTipJarVisible &&
      _vm.$_TipJarMixin_isGameOver &&
      !_vm.isMeeting
        ? _c("ModeMapperTips")
        : _vm._e(),
      _c("ResizeObserver", { on: { notify: _vm.onResize } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }