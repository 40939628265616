import Vue from "vue"

import { GameType } from "@/entities/GameType"
import Mode from "@shared/enums/Mode"
import { IN_GAME_RAfflE_MODES } from "../constants"

export default Vue.extend({
  name: "RaffleConsumer",
  methods: {
    getRaffleVisible(gameType: GameType, gameMode: string): boolean {
      return (
        this.getRaffleAvailable(gameType, gameMode) &&
        (this.gameStatus.raffle?.active ?? false)
      )
    },
    getRaffleAvailable(gameType: GameType, gameMode: string) {
      return (
        (gameType === GameType.GreenRoom && gameMode === Mode.Meeting) ||
        (this.isRaffleInGame && IN_GAME_RAfflE_MODES.includes(gameMode))
      )
    }
  },
  computed: {
    gameStatus() {
      return this.$store.getters?.gameStatus ?? {}
    },
    isRaffleInGame() {
      return this.gameStatus?.raffle?.raffleInGame ?? false
    }
  }
})
