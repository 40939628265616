import type { UserRole } from "@/types"
import { computed } from "@vue/composition-api"

import useStore from "./useStore"

export default function useViewer() {
  const { store } = useStore()
  const viewer = computed(() => store.state.auth.user)
  const id = computed<string>(() => viewer.value.id)
  const role = computed<UserRole>(() => viewer.value.role)
  return { viewer, id, role }
}
