
import Vue from "vue"
import { mergeData } from "vue-functional-data-merge"
export default Vue.extend({
  name: "RtbPill",
  functional: true,
  render(h, { props, data, children }) {
    return h(
      "span",
      mergeData(data, {
        staticClass: "rtb-pill"
      }),
      children
    )
  }
})
